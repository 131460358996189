import React from 'react';
import Layout from './Layout';
import { getThemeColor, mobileBreakPoint } from './BrandStyle';
import { isInspifyBrand } from '../utils/hubEntitlements';

interface ITwoColLayoutProps {
  children: React.ReactNode;
  className?: string;
  theme?: 'dark' | 'light' | 'transparent';
  brandId: string;
  showNav?: boolean;
  background: {
    mobile: string;
    tablet?: string;
    desktop?: string;
  };
}

const contentWidth = 1000;

const TwoColLayout = ({
  children,
  className = '',
  theme = 'dark',
  brandId,
  showNav = true,
  background
}: ITwoColLayoutProps) => {
  const color = getThemeColor(brandId);
  const isInviteImage = background.desktop.endsWith("/invite.jpg");

  return (
    <div className={`advisor-hub TwoColLayout ${className}`}>
      <div className="row">
        <div className="col-md-auto bg" >
          { isInviteImage && isInspifyBrand(brandId) && <div className='bg-logo' />}
        </div>
        <div className="col main text-center">
          <Layout theme={theme} showNav={showNav}>
            {children}
          </Layout>
        </div>
      </div>

      <style jsx>{`
        .main {
          background: ${color.light};
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          height:100%;
          overflow:auto;
          padding-bottom: 100px;
        }

        .main :global(.layout) {
          color: ${color.dark};
        }

        .row {
          margin: 0;
          height:100%;
          overflow:hidden;
        }

        .col {
          padding: 0;
        }
        .TwoColLayout {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .bg {
          width: calc(100vw - ${contentWidth}px);
          height: 100%;
          background: #000 url('${isInviteImage && isInspifyBrand(brandId) ? "/asset/invite-bg.jpg" : (background.desktop || background.mobile)}');
          background-position: center center;
          background-size: cover;
          padding: 15px;
          margin: 0;
          position: relative;
        }

        .bg .bg-logo{
          width: min(90%, 220px);
          background: url('https://storiez-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub/1bc913e3-faf3-47c3-a097-074905a292fd/logo-light.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 100%;
          margin: auto;
        }
        .TwoColLayout :global(.layout h1) {
          margin-bottom: 10px;
        }

        .TwoColLayout :global(.layout .page-body) {
          padding: 50px 20px 10px;
          max-width: 700px;
          width: 100%;
          margin: auto;
          height: auto;
          overflow: visible;
        }

        .TwoColLayout :global(.layout) {
          background: ${color.light};
        }

  

        .TwoColLayout :global(.layout .powered-by-inspify) {
          background: url(/asset/powered_by_inspify_dark_center.png) no-repeat
            center center;
          background-size: contain;
        }

        @media (min-width: ${mobileBreakPoint}px) {
          .TwoColLayout :global(.layout .content-wrapper) {
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .TwoColLayout :global(.layout .content-wrapper::-webkit-scrollbar) {
            display: none;
          }

          .TwoColLayout :global(.layout .btn-control) {
            right: 0;
            left: auto;
            bottom: 20px;
            width: 100%;
            max-width: ${contentWidth}px;
          }

          .TwoColLayout :global(.layout .header) {
            right: 0;
            left: 0;
            width: auto;
          }

          .TwoColLayout :global(.layout .btn-control .btn) {
            letter-spacing: 1px;
          }
        }
        @media (min-width: ${contentWidth}px) {
          .TwoColLayout :global(.layout h1) {
            margin-bottom: 10px;
          }
          .TwoColLayout :global(.layout .header) {
            left: calc(100vw - ${contentWidth}px);
          }
          :global(.home-nav) {
            left: calc(100vw - ${contentWidth}px + 10px) !important;
          }
          .TwoColLayout :global(.layout .page-body) {
            padding-top: 80px;
          }
          .TwoColLayout :global(.kbActive .page-body) {
            padding-top: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default TwoColLayout;
