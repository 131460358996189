import React from 'react';
import { Button } from 'react-bootstrap';
import { PageView } from '../../../utils/pageView';
import { hubNavTo } from '../../utils/hubPagesRoute';
import { ThankYouImage } from '../Common/HubSvgImage';

export const ThankYou: React.FC<{ ticketId: string; onBack?: () => void }> = ({
  ticketId,
  onBack
}) => {
  return (
    <div className="d-flex flex-column  justify-content-center h-100">
      <div className="img-container d-flex justify-content-center">
        <ThankYouImage />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h5 className="mb-3">You request has been sent</h5>
        <p>
          We have received your request and will be reviewed by our support
          staff.
        </p>
        <div className="text-muted">
          <span>ticket id: #</span>
          <span>{ticketId}</span>
        </div>
        <div className="mt-2">
          <Button
            onClick={() => {
              if (onBack) {
                onBack();
                return;
              }
              hubNavTo(PageView.HUB_HOME);
            }}
            variant="dark"
            className="rounded-pill"
          >
            Back to Home
          </Button>
        </div>
      </div>
      <style jsx>{`
        .img-container > :global(svg) {
          max-width: 360px;
        }
        h5 {
          font-size: 22px;
          font-weight: 600;
          line-height: 26.8px;
        }
      `}</style>
    </div>
  );
};
