import React from 'react';
import { Spinner } from 'react-bootstrap';

import { BsX } from 'react-icons/bs';
import { ISocialMedia } from '../../../../interfaces';
import FacebookProfile from './FacebookProfile';

const FacebookAccount = ({
  socialMedia,
  onRemove
}: {
  socialMedia: ISocialMedia;
  onRemove: (id: string) => Promise<void>;
}) => {
  const [removeAlert, setRemoveAlert] = React.useState(false);
  const [removing, setRemoving] = React.useState(false);
  const handleRemoval = () => {
    setRemoving(true);
    onRemove(socialMedia.id).finally(() => {
      setRemoveAlert(false);
      setRemoving(false);
    });
  };

  const handleError = () => {
    console.log('token expired');
    onRemove(socialMedia.id);
  };
  return (
    <>
      <div className="FacebookAccount d-flex justify-content-between align-items-center">
        <div className="profile">
          <FacebookProfile
            token={socialMedia.token}
            type={socialMedia.type}
            onError={handleError}
          />
        </div>
        <div className="status d-flex align-items-center">
          <div className="status-dot green"></div>
          <div className="disconnect">
            <button title="Remove" className="btn btn-light">
              <BsX
                size={30}
                onClick={() => {
                  setRemoveAlert(true);
                }}
              />
            </button>
          </div>
          {removeAlert && (
            <div className="remove-alert">
              <span> Are you sure you want to remove this account?</span>
              <div className="btns">
                <button
                  disabled={removing}
                  className="btn btn-round btn-light"
                  onClick={() => {
                    setRemoveAlert(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={removing}
                  className="btn btn-round btn-dark"
                  onClick={handleRemoval}
                >
                  {removing ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Confirm'
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr />
      <style jsx>{`
        .FacebookAccount {
          padding: 20px 10px;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          position: relative;
          max-width: 100%;
        }
        .profile {
          flex: 1;
          padding: 0 10px;
        }
        .remove-alert {
          width: 250px;
          position: absolute;
          bottom: 50%;
          right: 0;
          transform: translateY(50%);
          background: white;
          border-radius: 5px;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
          text-align: center;
          padding: 15px;
        }

        .remove-alert span {
          font-size: 1em;
          font-weight: 500;
        }
        .remove-alert .btns {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
        }
        .remove-alert .btns button {
          font-size: 0.8rem;
        }

        .status-dot {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        .green {
          background-color: #00c853;
        }
        .red {
          background-color: #d50000;
        }

        .disconnect button.btn {
          background-color: transparent;
          border: none;
          margin-left: 10px;
        }
      `}</style>
    </>
  );
};

export default FacebookAccount;
