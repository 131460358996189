import { capitalize } from 'lodash';
import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { AiOutlineDelete, AiOutlineUpload } from 'react-icons/ai';
import { logEvent } from '../../../analytics';
import {
  DID_CLICK_CHANGE_PASSWORD,
  DID_CLICK_EDIT_PROFILE
} from '../../../utils/constants';
import { getTwoLetterFromName } from '../../../utils/string';
import { isOnboardingBrand } from '../../../utils/window';
import { HubContext } from '../HubContext';
import EditProfile from './EditProfile';
import { HubUserContext } from './HubUserContext';
import ImageUploadModal from './ImageUploadModal';

const isUrl = (url) => {
  return url?.match(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  );
};

function SalePage() {
  const { user, isAdmin, userRoles } = React.useContext(HubContext);
  const [showUpload, setShowUpload] = React.useState(false);
  const [isAvatarError, setIsAvatarError] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const {
    changePwdView,
    setChangePwdView,
    setIsAdminPage,
    editMode,
    uploadAvatarTos3,
    deleteAvatar
  } = React.useContext(HubUserContext);

  React.useEffect(() => {
    setIsAdminPage(false);
  }, []);

  React.useEffect(() => {
    setIsAvatarError(false);
  }, [user?.avatar_picture]);

  const handleDeleteAvatar = () => {
    setIsDeleting(true);
    deleteAvatar()
      .then(() => {
        setShowDelete(false);
      })
      .catch()
      .finally(() => setIsDeleting(false));
  };

  const AvatarEditingOverlay = () => (
    <div className="upload-icon">
      {isUrl(user.avatar_picture) && (
        <button
          title="delete avatar"
          className="btn btn-dark btn-sm btn-delete"
          onClick={() => setShowDelete(true)}
        >
          <AiOutlineDelete color="red" size={20} />
        </button>
      )}
      <button
        title="upload avatar"
        onClick={() => setShowUpload(true)}
        className="btn btn-dark btn-sm btn-upload"
      >
        <AiOutlineUpload size={20} />
      </button>
    </div>
  );

  const userRole = React.useMemo(() => {
    if (isOnboardingBrand()) {
      return userRoles
        ?.map((role) => capitalize(role?.name?.replace(/STUDIO_|STORIEZ_/, '')))
        .join(' ');
    }
    return isAdmin ? 'Admin' : 'Sales advisor';
  }, [isAdmin, userRoles]);

  if (!user) return <div className="text-center">Loading....</div>;
  return (
    <>
      <div className="sales-page">
        <div className="row gutters">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="left-card card h-100">
              <div className="card-body">
                <div className="account-settings row">
                  <div className="user-profile col-12">
                    <div className="d-flex align-items-center">
                      <div className="user-avatar">
                        {user?.avatar_picture && !isAvatarError ? (
                          <div className="avatar-cover">
                            <img
                              src={user.avatar_picture}
                              alt="Avatar photo"
                              onError={() => setIsAvatarError(true)}
                            />
                          </div>
                        ) : (
                          <span>{getTwoLetterFromName(user.alias)}</span>
                        )}
                        {editMode && <AvatarEditingOverlay />}
                      </div>
                      <div className="user-info">
                        <div className="user-alias">
                          <h5 className="user-name">{user.alias}</h5>
                          <h6 className="user-email">{user.email}</h6>
                        </div>

                        <div className="about">
                          <h5 className="user-role">Role</h5>
                          {<h6>{userRole}</h6>}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="setting-btn col-12 d-flex align-items-center ml-auto">
                    <button
                      onClick={() => {
                        logEvent(
                          !changePwdView
                            ? DID_CLICK_CHANGE_PASSWORD
                            : DID_CLICK_EDIT_PROFILE
                        );
                        setChangePwdView(!changePwdView);
                      }}
                      className="btn btn-dark"
                    >
                      {!changePwdView ? 'Change Password' : 'Edit Profile'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card h-100">
              <div className="card-body">
                <EditProfile user={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageUploadModal
        defaultImg={user.avatar_picture}
        show={showUpload}
        onToggle={setShowUpload}
        onUpload={uploadAvatarTos3}
      />
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-delete">
          Are you sure to delete your avatar?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-sm"
            onClick={() => setShowDelete(false)}
          >
            Close
          </button>
          <button
            onClick={handleDeleteAvatar}
            className="btn btn-danger btn-sm"
          >
            {isDeleting ? <Spinner animation="border" size="sm" /> : 'Delete'}
          </button>
        </Modal.Footer>
      </Modal>
      <style jsx>
        {`
          .account-settings {
            margin: 0 0 1rem 0;
            padding-bottom: 1rem;
          }
          .account-settings .user-profile{
            padding-left: 0px;
          }
          .account-settings .user-profile .user-avatar {
            margin: 0;
            position: relative;
            overflow: hidden;
            width: 100px;
            min-width: 100px;
            height: 100px;
          }
          .account-settings .user-profile .user-avatar span {
            display: block;
            background: #000;
            color: white;
            border-radius: 50%;
            font-weight: 500;
            font-size: 2rem;
            line-height: 100px;
            text-align: center;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
          }
          .account-settings .user-profile .user-avatar img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;           
          }
          .account-settings .user-profile h5.user-name {
            text-align: left;
            font-weight: 500;
          }
          .account-settings .user-profile h6.user-email {
            margin: 0;
            font-size: 0.8rem;
            font-weight: 400;
            color: #9fa8b9;
          }
          .user-info {
            margin-left: 20px;
            flex: 1;
          }
          .user-role {
            margin-top: 12px;
          }
          .account-settings .about {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .account-settings .about h5 {
            font-size: 0.9rem;
            color: #9fa8b9;
          }
          .account-settings .about h6 {
            font-size: 1.1rem;
            font-weight: 500;
          }
          .form-control {
            border: 1px solid #000;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            font-size: 0.825rem;
            background: #ffffff;
            color: #2e323c;
          }
          .card {
            background: transparent;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            border: 0;
            margin-bottom: 1rem;
          }
          .card-body {
            padding: 0;
          }
          .setting-btn{
            padding-right: 0px;
          }
          .setting-btn>button{
            width: 100%;
          }
          :global(.modal-delete) {
            padding: 10px;
          }
          :global(.upload-icon) {
            color: white;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 50%;
            right: 0;
            background: rgba(0, 0,0, 0.5);
          }
          :global(.upload-icon .btn) {
            padding: 5px;
            min-width: unset;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 50%;
            height: 50%;
          }
          :global(.upload-icon .btn-delete){
            left: 0;
          }
          }
          @media (max-width: 767px) {
            .setting-btn{
              margin-top: 1rem;
              padding-left: 0px;
            }
          }
        `}
      </style>
    </>
  );
}

export default SalePage;
