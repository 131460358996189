import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { staticBaseUrl } from '../../../config';
import { HubChatContext } from './HubChatContext';

const TransferDropdown = () => {
  const {
    listSA,
    transferConversationTo,
    listAvailableSA,
    fetchAvailableSaleAdvisors
  } = React.useContext(HubChatContext);

  const fetchAdvisors = (isOpen: boolean) => {
    if (isOpen) {
      fetchAvailableSaleAdvisors();
    }
  };
  const mapListAvailableAdvisors = () => {
    return listSA.filter((sa) =>
      listAvailableSA.find((available) => available.userId === sa.id)
    );
  };

  const availables = mapListAvailableAdvisors();

  return (
    <Dropdown bsPrefix="TransferDropdown" onToggle={fetchAdvisors}>
      <Dropdown.Toggle
        bsPrefix="btn transfer-btn btn-dark"
        variant="success"
        id="dropdown-basic"
      >
        <img
          src={`${staticBaseUrl}/connect/assets/transfer.svg`}
          alt="Transfer"
        />
        <span>Transfer</span>
      </Dropdown.Toggle>

      <Dropdown.Menu alignRight>
        {availables?.length ? (
          availables.map((sa) => (
            <Dropdown.Item
              key={sa.id}
              onClick={() => transferConversationTo(sa?.id)}
            >
              {sa?.alias || sa?.first_name || 'SA'}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item>No available advisors</Dropdown.Item>
        )}
      </Dropdown.Menu>
      <style jsx>{`
        :global(.TransferDropdown) {
          padding: 0;
          display: inline-block;
        }

        :global(.transfer-btn) {
          margin-left: 5px;
          border-radius: 50%;
          height: 37px;
          width: 37px;
          justify-content: center;
        }
        :global(.transfer-btn img) {
          margin-right: 0;
        }
        :global(.join-btn) {
          padding: 0;
        }

        :global(.transfer-btn span) {
          display: none;
        }

        :global(.transfer-btn) {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          display: inline-flex;
          align-items: center;
          padding: 10px 15px;
        }

        :global(.transfer-btn:focus) {
          box-shadow: none;
        }

        :global(.TransferDropdown .dropdown-item) {
          background: #efefef;
          border: 1px solid #c4c4c4;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 17px;
          color: #010101;
          background: #efefef;
          padding: 12px 16px;
          border-bottom: none;
          width: 230px;
        }
        :global(.TransferDropdown .dropdown-item:last-child) {
          border-bottom: 1px solid #c4c4c4;
        }
        :global(.TransferDropdown .dropdown-menu) {
          padding: 0;
          border: none;
        }

        @media (min-width: 1024px) {
          :global(.transfer-btn) {
            margin-left: 6px;
            border-radius: 60px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: inline-flex;
            align-items: center;
            padding: 10px 15px;
            height: auto;
            width: auto;
          }

          :global(.transfer-btn span) {
            display: initial;
          }

          :global(.transfer-btn:focus) {
            box-shadow: none;
          }

          :global(.transfer-btn img) {
            margin-right: 5px;
          }
        }
      `}</style>
    </Dropdown>
  );
};

export default TransferDropdown;
