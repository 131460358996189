import React from 'react';
import DropZone from '../../Common/FileUploader/DropZone';
import AssetThumbnail from '../../AssetLibrary/AssetThumbnail';
import { generateV4UUID } from '../../../../utils/identityGenerator';
import Mime from 'mime';
import { removeFileNameExtension } from '../../../../utils/file';
import { IAsset } from '../../../../interfaces';
import { FaRegTrashAlt } from 'react-icons/fa';
import { ILocalAsset } from './ShowCasedVideoPopup';

function ShowCasedVideoDropzone({
  onChange,
  uploadAssets,
  deviceId
}: {
  onChange: React.Dispatch<React.SetStateAction<ILocalAsset[]>>;
  uploadAssets: ILocalAsset[];
  deviceId: string;
}) {
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      onChange((prev) => {
        const newFiles = acceptedFiles
          .filter((f) => !prev.some((file) => file.file?.name === f.name))
          .map(mapToAssetLib);
        return [...prev, ...newFiles];
      });
    },
    [deviceId]
  );

  const mapToAssetLib = React.useCallback(
    (f: File): IAsset & { key: string; file: File } => {
      const objectUrl = URL.createObjectURL(f);
      const id = generateV4UUID();
      return {
        id: id,
        title: removeFileNameExtension(f.name),
        originalURL: `${objectUrl}/${f.name}`,
        assetPreviewURL: objectUrl,
        assetType: Mime.getType(f.name),
        sizeKB: f.size,
        key: `showcased_${deviceId}/${f.name}`,
        file: f
      };
    },
    [deviceId]
  );

  const onRemove = (file: File) => {
    onChange((prev) => prev.filter((f) => f.file?.name !== file.name));
  };

  return (
    <div className="showcased-video-upload d-flex flex-column">
      <DropZone
        onDrop={onDrop}
        fileTypes="video/*"
        showFileListWhileDragging
        multiple
      />
      {uploadAssets?.length > 0 && (
        <div className="d-flex flex-wrap w-100 uploaded justify-content-center">
          {uploadAssets.map((asset, index) => (
            <div key={asset.id} className="uploaded-file">
              <AssetThumbnail
                key={index}
                item={asset}
                local={true}
                selected={false}
              />
              <button
                className="btn btn-outline"
                onClick={() => onRemove(asset.file)}
              >
                <FaRegTrashAlt />
              </button>
            </div>
          ))}
        </div>
      )}
      <style jsx>{`
        .showcased-video-upload ::global(.dropzone) {
          height: ${uploadAssets?.length ? '20%' : '100%'};
        }
        .uploaded {
          margin: 10px auto;
          overflow-y: auto;
          gap: 10px;
        }
        .uploaded-file {
          position: relative;
          padding: 5px;
          border: 3px solid #8f6599;
          border-radius: 5px;
        }
        .uploaded-file .btn {
          position: absolute;
          top: 0;
          right: 0;
          background-color: #fff;
        }
      `}</style>
    </div>
  );
}

export default ShowCasedVideoDropzone;
