import React from 'react';
import Layout from '../Layout';
import SearchInput from '../Common/SearchInput';
import { ITableColumn, SortTable } from '../Common/Table';
import { IDeviceStatus } from '../../../interfaces';
import {
  getDevicesStatus,
  sendRebootCommand,
  updateDeviceStatus
} from '../../clientSideServices/device';
import moment from 'moment';
import ToolTipWrapper from '../../../components/Common/ToolTipWrapper';
import { logEvent } from '../../../analytics';
import {
  actionHubAlertError,
  actionHubAlertSuccess
} from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { BiCheck, BiX } from 'react-icons/bi';
import DeviceComment from './DeviceComment';
import { Spinner } from 'react-bootstrap';

function DeviceMonitoring() {
  const [searchValue, setSearchValue] = React.useState('');
  const [devices, setDevices] = React.useState<IDeviceStatus[]>([]);
  const [selectedDevice, setSelectedDevice] =
    React.useState<IDeviceStatus | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rebooting, setRebooting] = React.useState<boolean>(false);
  const [changeNameTooltip, setChangeNameTooltip] =
    React.useState<IDeviceStatus | null>(null);
  const [tab, setTab] = React.useState<'online' | 'offline'>('offline');
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [rebootAlert, setRebootAlert] = React.useState<IDeviceStatus | null>(
    null
  );
  const [commentDevice, setCommentDevice] =
    React.useState<IDeviceStatus | null>(null);

  const deviceNameRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const loadDevices = () => {
    setLoading(true);
    getDevicesStatus()
      .then((res) => {
        setDevices(res);
      })
      .catch((err) => console.error(err))
      .then(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    loadDevices();
  }, []);

  const isOnline = React.useCallback((device: IDeviceStatus) => {
    if (device.status !== 'ONLINE') {
      return false;
    }

    return moment().diff(moment(device.modifiedAt), 'minutes') < 1;
  }, []);

  const listDevices = React.useMemo(() => {
    return devices.reduce(
      (acc, device) => {
        if (isOnline(device)) {
          acc.online.push({
            ...device,
            status: device.status === 'ONLINE' ? 'Online' : 'Available',
            id: `device-${device.deviceId}`
          });
        } else {
          let lastSeenBy = '';
          if (device.modifiedAt)
            lastSeenBy = `since ${moment(device.modifiedAt).fromNow()}`;
          acc.offline.push({
            ...device,
            status: `Offline ${lastSeenBy}`.trim(),
            id: `device-${device.deviceId}`
          });
        }
        return acc;
      },
      { online: [], offline: [] }
    );
  }, [devices]);

  const searchFields = [
    'name',
    'deviceId',
    'storeName',
    'storeCountry',
    'storeCity'
  ];
  const filter = (device: IDeviceStatus) => {
    return searchFields.some((field) =>
      device[field]?.toLowerCase?.()?.includes?.(searchValue.toLowerCase())
    );
  };
  const searchResult = React.useMemo(() => {
    return {
      online: listDevices.online.filter(filter),
      offline: listDevices.offline.filter(filter)
    };
  }, [searchValue, listDevices]);

  const dataColumns: ITableColumn[] = [
    {
      key: 'name',
      title: 'Name',
      align: 'left',
      width: '20%'
    },
    {
      key: 'deviceId',
      title: 'Device ID',
      align: 'left',
      width: '15%'
    },
    {
      key: 'storeName',
      title: 'Store',
      align: 'left',
      width: '20%'
    },
    {
      key: 'storeCountry',
      title: 'Country',
      align: 'left',
      width: '10%'
    },
    {
      key: 'storeCity',
      title: 'City',
      align: 'left',
      width: '10%'
    },
    {
      key: 'status',
      title: 'Status',
      align: 'left',
      width: '15%'
    }
  ];

  const handleChangeDeviceName = () => {
    const oldName = changeNameTooltip.name;
    const newName = deviceNameRef.current?.value?.trim();
    if (!newName || oldName === newName) {
      setChangeNameTooltip(null);
      return;
    }
    setUpdating(true);
    delete changeNameTooltip.comments;
    updateDeviceStatus({
      ...changeNameTooltip,
      name: newName
    })
      .then(() => {
        dispatch(actionHubAlertSuccess('Device name updated successfully!'));
        setDevices((prev) => {
          const index = prev.findIndex(
            (device) => device.deviceId === changeNameTooltip.deviceId
          );
          if (index > -1) {
            prev[index].name = newName;
          }
          return [...prev];
        });
      })
      .catch((error) => {
        dispatch(actionHubAlertError('Failed to update device name!'));
        console.error(error);
      })
      .finally(() => {
        setUpdating(false);
        setChangeNameTooltip(null);
      });
  };

  const handleReboot = (device: IDeviceStatus) => {
    setRebooting(true);
    logEvent('HUB_DEVICE', 'HUB_DEVICE_REBOOT', {
      deviceId: device.deviceId,
      deviceName: device.name
    });
    sendRebootCommand(device.deviceId)
      .then(() => {
        logEvent('HUB_DEVICE', 'HUB_DEVICE_REBOOT_SUCCESS', {
          deviceId: device.deviceId,
          deviceName: device.name
        });
        dispatch(actionHubAlertSuccess('Device reboot command sent'));
      })
      .catch((error) => {
        logEvent('HUB_DEVICE', 'HUB_DEVICE_REBOOT_FAILED', {
          deviceId: device.deviceId,
          deviceName: device.name,
          error
        });
        dispatch(
          actionHubAlertError(
            'Send command failed! Please try again or contact support.'
          )
        );
      })
      .finally(() => {
        setRebooting(false);
        setSelectedDevice(null);
        setRebootAlert(null);
      });
  };

  return (
    <Layout showNav={false} theme="dark-light" className="devices-monitor">
      <div className="device-monitoring">
        <div className="header">
          <div className="title">
            <h5>Device Monitoring</h5>
          </div>
          <div className="action">
            <button
              className="btn btn-round btn-dark btn-refresh"
              onClick={loadDevices}
              disabled={loading}
            >
              Refresh
            </button>
            <SearchInput
              onSearch={setSearchValue}
              searchOnTyping={true}
              placeHolder="Search"
              className="search-devices"
            />
          </div>
        </div>
        <div className="tab-header">
          <button
            className="btn tab-btn"
            data-active={tab === 'offline'}
            onClick={() => setTab('offline')}
          >
            Offline
          </button>
          <button
            className="btn tab-btn"
            data-active={tab === 'online'}
            onClick={() => setTab('online')}
          >
            Online
          </button>
        </div>
        <div className="tab-content">
          <div className="tab-content">
            <div className="table-content">
              {tab === 'online' && (
                <SortTable
                  columns={dataColumns}
                  data={searchResult.online}
                  stripped
                  noData={{
                    text: 'No devices',
                    align: 'center',
                    verticalAlign: 'middle',
                    padding: '60px 0'
                  }}
                  sorts={[
                    'name',
                    'deviceId',
                    'storeName',
                    'storeCountry',
                    'storeCity',
                    'status'
                  ]}
                  onlyActiveSortBy
                  optionBtn
                  onOptionBtnClick={setSelectedDevice}
                  loading={loading}
                />
              )}
              {tab === 'offline' && (
                <SortTable
                  columns={dataColumns}
                  data={searchResult.offline}
                  stripped
                  noData={{
                    text: 'No devices',
                    align: 'center',
                    verticalAlign: 'middle',
                    padding: '60px 0'
                  }}
                  sorts={[
                    'name',
                    'deviceId',
                    'storeName',
                    'storeCountry',
                    'storeCity',
                    'status'
                  ]}
                  onlyActiveSortBy
                  optionBtn
                  onOptionBtnClick={setSelectedDevice}
                  loading={loading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedDevice?.deviceId && (
        <ToolTipWrapper
          onClose={() => setSelectedDevice(null)}
          parentId={`device-${selectedDevice.deviceId}`}
          width={250}
          distanceFromParent={40}
          xPosition="left"
          className="device-menu"
        >
          <div className="control-menu text-left">
            <button
              className="list-group-item list-group-item-action"
              onClick={() => {
                setSelectedDevice(null);
                setCommentDevice(selectedDevice);
              }}
            >
              View Comments
            </button>
            <button
              className="list-group-item list-group-item-action"
              onClick={() => {
                setSelectedDevice(null);
                setChangeNameTooltip(selectedDevice);
              }}
            >
              Change Name
            </button>
            <button
              className="list-group-item list-group-item-action"
              onClick={() => {
                setSelectedDevice(null);
                setRebootAlert(selectedDevice);
              }}
            >
              {rebooting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                'Reboot Device'
              )}
            </button>
          </div>
        </ToolTipWrapper>
      )}
      {changeNameTooltip?.deviceId && (
        <ToolTipWrapper
          onClose={() => setChangeNameTooltip(null)}
          parentId={`device-${changeNameTooltip.deviceId}`}
          width={250}
          closeButton={false}
          distanceFromParent={40}
          xPosition="left"
          className="device-menu"
        >
          <div className="form-group change-device-form">
            <input
              type="text"
              autoFocus
              ref={deviceNameRef}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleChangeDeviceName();
                }
              }}
              placeholder={changeNameTooltip.name || ''}
              className="form-control"
            />

            <div className="btn-group">
              <button
                className="btn btn-outline-dark text-success"
                onClick={handleChangeDeviceName}
              >
                {updating ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <BiCheck />
                )}
              </button>

              <button
                className="btn btn-outline-dark text-danger"
                onClick={() => setChangeNameTooltip(null)}
              >
                <BiX />
              </button>
            </div>
          </div>
        </ToolTipWrapper>
      )}
      {Boolean(commentDevice?.deviceId) && (
        <DeviceComment
          device={commentDevice}
          onClose={() => setCommentDevice(null)}
        />
      )}

      {rebootAlert?.deviceId && (
        <ToolTipWrapper
          onClose={() => setRebootAlert(null)}
          parentId={`device-${rebootAlert?.deviceId}`}
          width={250}
          closeButton={false}
          distanceFromParent={40}
          xPosition="left"
          className="device-menu"
        >
          <div className="form-group change-device-form">
            <p className="text-white text-center">
              Are you sure you want to reboot this device?
            </p>
            <div className="btn-group">
              <button
                className="btn btn-outline-dark text-success"
                onClick={() => {
                  handleReboot(rebootAlert);
                }}
              >
                {rebooting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <BiCheck />
                )}
              </button>

              <button
                className="btn btn-outline-dark text-danger"
                onClick={() => setRebootAlert(null)}
              >
                <BiX />
              </button>
            </div>
          </div>
        </ToolTipWrapper>
      )}
      <style jsx>{`
        .device-monitoring {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .change-device-form {
          background-color: rgba(0, 0, 0, 0.9);
          padding: 10px;
        }
        .change-device-form .btn-group {
          width: 100%;
          margin-top: 3px;
        }
        .tab-header {
          display: flex;
          gap: 20px;
          padding: 10px 10px;
          border-bottom: 1px solid #e0e0e0;
        }
        .tab-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 10px;
        }
        .tab-header .tab-btn {
          font-size: 20px;
          font-weight: 600;
          position: relative;
          text-align: center;
        }
        .tab-btn[data-active='true']::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          left: 0;
          bottom: -11px;
          background-color: #8f6599;
        }
        .header {
          display: flex;
          align-items: center;
          padding: 0px 20px 15px 20px;
          justify-content: space-between;
          border-bottom: 1px solid #e0e0e0;
        }
        .content {
          padding: 20px;
        }
        h5,
        h6 {
          font-weight: bold;
        }
        .header .action {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .table-wrapper {
          flex: 50%;
          display: flex;
          flex-direction: column;
        }
        .table-content {
          position: relative;
          flex: 1;
          overflow-y: auto;
        }
        .table-content ::global(.table-responsive) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .header ::global(.search-devices) {
          height: 30px;
        }
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .btn-refresh {
          width: 90px;
          height: 30px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
        }
        ::global(.content-wrapper) {
          display: flex;
          flex-direction: column;
          height: calc(100vh - 70px);
        }
        .table-wrapper ::global(.table-responsive tbody td) {
          font-size: 14px;
        }
        .table-wrapper.online-table
          ::global(.table-responsive tbody td:nth-child(6)) {
          color: #1f9639;
          font-weight: bold;
        }
        .table-wrapper.offline-table
          ::global(.table-responsive tbody td:nth-child(6)) {
          color: #929292;
          font-weight: bold;
        }
      `}</style>
    </Layout>
  );
}

export default DeviceMonitoring;
