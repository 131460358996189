import React from 'react';
import { HubContext } from '../HubContext';
import { ImageUpload } from '../Common/FileUploader/MediaUpload';
import { useDispatch } from 'react-redux';
import {
  actionHubAlertError,
  actionSetPopupAssets
} from '../../../redux/actions';
import {
  deleteBrandVirtualBackground,
  getBrandVirtualBackground,
  uploadBrandVirtualBackground
} from '../../../clientSideServices/brand';
import { assetBaseUrl } from '../../../config';
import { useStateRef } from '../../../components/hooks/common';

const AcceptTypes = ['image/png', 'image/jpg', 'image/jpeg'];
function BrandVirtualBackgrounds() {
  const [virtualBackgrounds, setVirtualBackgrounds, vbRef] = useStateRef([]);
  const [status, setStatus] = React.useState('idle');
  const [uploading, setUploading] = React.useState(false);
  const [newBackground, setNewBackground] = React.useState(null);
  const [newBackgroundSelectedAsset, setNewBackgroundSelectedAsset] =
    React.useState(null);
  const [deleting, setDeleting] = React.useState<string>('');
  const { brandId } = React.useContext(HubContext);

  const fetchVirtualBackgrounds = async () => {
    try {
      setStatus('loading');
      const result = await getBrandVirtualBackground(brandId);
      const bgs = result.data || [];
      setStatus('success');
      setVirtualBackgrounds(bgs);
    } catch (error) {
      setStatus('error');
      dispatch(actionHubAlertError('Failed to fetch virtual backgrounds.'));
      console.log('failed to fetch virtual backgrounds', error);
    }
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    fetchVirtualBackgrounds();
  }, []);

  const renderTitle = () => {
    return (
      <div className="header">
        <h5>Virtual backgrounds</h5>
        <p>
          <span className="text-sm">
            Upload your own meeting virtual backgrounds for your brand.
          </span>
        </p>
      </div>
    );
  };

  const uploadVirtualBackground = async (_file: File) => {
    setUploading(true);
    const formatedFileName = _file.name.replace(/[^A-Z0-9]/gi, '_');
    const file = new File([_file], formatedFileName, {
      type: _file.type || 'image/png'
    });
    return uploadBrandVirtualBackground({
      file,
      brandId
    })
      .then((res) => {
        const { data } = res;
        const currentBgs = vbRef.current || [];
        setVirtualBackgrounds([...currentBgs, data]);
        dispatch(actionHubAlertError('Virtual background uploaded.'));
      })
      .catch((err) => {
        dispatch(actionHubAlertError('Failed to upload virtual background.'));
        console.log(err);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const onSaveAssetUrl = async (url) => {
    const fileName = new Date().toDateString();
    const blob = await fetch(url).then((res) => res.blob());
    const formatedFileName = fileName.replace(/[^A-Z0-9]/gi, '_');
    const file = new File([blob], formatedFileName, { type: blob.type });
    uploadVirtualBackground(file)
      .then(() => {
        setNewBackground(null);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const renderEmpty = () => {
    return (
      <div
        data-loading={uploading}
        className="uploader d-flex flex-column align-items-center justify-content-center"
      >
        <ImageUpload
          theme="dark"
          src={newBackground}
          alt="new virtual background"
          acceptTypes={AcceptTypes.join(', ')}
          maxSize={1 * 1024 * 1024}
          crop={false}
          onRemove={async () => {
            setNewBackground(null);
          }}
          onUpload={(file) => {
            return uploadVirtualBackground(file)
              .then(() => {
                setNewBackground(null);
              })
              .catch((error) => {
                console.log('error', error);
              });
          }}
          onClick={() => {
            dispatch(
              actionSetPopupAssets({
                popupContext: 'branding',
                popupTitle: 'Brand virtual backgrounds',
                acceptTypes: AcceptTypes.join(', '),
                onSelectAsset(asset) {
                  console.log(asset.assetType);
                  if (!AcceptTypes.includes(asset.assetType)) {
                    dispatch(actionHubAlertError('Invalid asset type.'));
                    return;
                  }
                  setNewBackgroundSelectedAsset(asset);
                  dispatch(actionSetPopupAssets({ popupContext: '' }));
                },
                onAddFileToUpload: (file) => {
                  setNewBackground(file);
                  dispatch(actionSetPopupAssets({ popupContext: '' }));
                }
              })
            );
          }}
          libraryAsset={newBackgroundSelectedAsset}
          file={newBackground}
          onSaveAssetUrl={onSaveAssetUrl}
        />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <p className="text-center">Loading virtual backgrounds...</p>
      </div>
    );
  };

  const getFileNameFromUrl = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  const getFileUrl = (key) => {
    return `${assetBaseUrl}/${key}`;
  };

  const onDelete = async (key: string) => {
    setDeleting(key);
    deleteBrandVirtualBackground(key)
      .then(() => {
        setVirtualBackgrounds(
          virtualBackgrounds.filter((item) => item.Key !== key)
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setDeleting(null);
      });
  };
  return (
    <div>
      {renderTitle()}
      <div className="content">
        {status === 'loading' && renderLoading()}
        <div className="list">
          {renderEmpty()}
          {virtualBackgrounds.length > 0 &&
            virtualBackgrounds.map((item, index) => {
              return (
                <div
                  data-deleting={deleting === item.Key}
                  className="list-item"
                  key={index}
                >
                  <div className="image">
                    <img src={getFileUrl(item.Key)} alt="virtual background" />
                  </div>
                  <div className="overlay">
                    <div className="action">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => onDelete(item.Key)}
                      >
                        Delete
                      </button>
                    </div>
                    <span>{getFileNameFromUrl(item.Key)}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <style jsx>{`
        .list {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 20px;
          padding: 15px;
        }
        @media (max-width: 992px) {
          .list {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        @media (max-width: 768px) {
          .list {
            grid-template-columns: 1fr;
          }
        }

        .list-item {
          position: relative;
        }

        .list-item .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .overlay span {
          color: #fff;
          font-size: 12px;
          margin-top: 10px;
          text-align: center;
        }

        .image {
          width: 100%;
          height: 100%;
          aspect-ratio: 16/9;
        }

        .list-item:hover .overlay {
          opacity: 1;
        }
        .image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        .list :global(.media-upload-main.drop-zone),
        .list :global(.media-upload-container) {
          width: 100%;
          height: 100%;
        }
        .uploader[data-loading='true'] {
          opacity: 0.5;
        }

        .list-item[data-deleting='true'] {
          opacity: 0.2;
          user-select: none;
        }
      `}</style>
    </div>
  );
}

export default BrandVirtualBackgrounds;
