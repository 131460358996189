import React from 'react';
import DeviceRough from './DeviceRough';
import {
  IDeviceConfiguration,
  IProduct,
  ISceneDetail
} from '../../../../interfaces';
import { FaChevronLeft } from 'react-icons/fa';
import ShowCasedProduct from './ShowCasedProduct';
import ProductSearch from './ProductSearch';
import { getProductsByIds } from '../../../../clientSideServices/products';
import {
  saveDeviceConfiguration,
  sendUpdateConfigurationCommand
} from '../../../clientSideServices/device';
import { useDispatch } from 'react-redux';
import {
  actionHubAlertError,
  actionHubAlertSuccess
} from '../../../../redux/actions';
import { Spinner } from 'react-bootstrap';
import { getActivatedSceneById } from '../../../clientSideServices/library';
import { cloneDeep } from 'lodash';

type SavingStatus = 'initial' | 'saving' | 'sending_command' | 'error' | 'save';

const StatusMessagesMapper = {
  initial: 'Push to device',
  save: 'Save',
  saving: 'Saving...',
  sending_command: 'Sending Command...'
};

function DeviceConfiguration({
  device,
  onBack,
  onSave
}: {
  device: IDeviceConfiguration;
  onBack: () => void;
  onSave: (config: IDeviceConfiguration) => void;
}) {
  const dispatch = useDispatch();
  const [editedDevice, setEditedDevice] =
    React.useState<IDeviceConfiguration | null>(device);
  const [productsOrder, setProductsOrder] = React.useState<IProduct[]>([]);
  const [scenesOrder, setScenesOrder] = React.useState<ISceneDetail[]>([]);
  const [loadingScenes, setLoadingScenes] = React.useState(false);
  const [loadingProduct, setLoadingProduct] = React.useState(false);
  const [savingStatus, setSavingStatus] = React.useState<SavingStatus>('save');
  const [pushingStatus, setPushingStatus] =
    React.useState<SavingStatus>('initial');

  const productSlots = device?.productSlots || [];
  const deviceScenes = device?.scenes || [];
  const onEdit = (key: string, value: any) => {
    setEditedDevice((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const hasChange = React.useMemo(() => {
    return JSON.stringify(editedDevice) !== JSON.stringify(device);
  }, [editedDevice, device]);

  React.useEffect(() => {
    if (productSlots?.length > 0) {
      setLoadingProduct(true);
      getProductsByIds(productSlots.map((slot) => slot.productId))
        .then((res) => {
          setLoadingProduct(false);
          const sortedProducts = productSlots.map((slot) => {
            const product = res.data.find((p) => p.id === slot.productId);
            return product;
          });
          setProductsOrder(sortedProducts);
        })
        .catch((err) => {
          console.error(err);
          setLoadingProduct(false);
        });
    }
  }, [productSlots]);

  React.useEffect(() => {
    if (deviceScenes?.length > 0) {
      setLoadingScenes(true);
      const scenes = deviceScenes.map((scene) => {
        const sceneId = scene.sceneId;
        return getActivatedSceneById(sceneId).catch(() => {
          return {
            id: sceneId,
            content: {
              name: 'Scene not found',
              thumbnail: ''
            }
          };
        });
      });
      Promise.all(scenes)
        .then((res) => {
          setLoadingScenes(false);
          const sortedScenes = deviceScenes.map((slot) => {
            const sceneId = slot.sceneId;
            const scene = res.find((p) => p.id === sceneId);
            return {
              ...scene,
              isDefaultScene: slot.isDefaultScene,
              durationInSec: slot.durationInSec,
              rule: slot.rule || 'NA'
            };
          });
          setScenesOrder(sortedScenes);
        })
        .catch((err) => {
          console.error(err);
          setLoadingScenes(false);
        });
    }
  }, [deviceScenes]);

  const handleProductOrderChange = (products: IProduct[]) => {
    setProductsOrder(products);
    onEdit(
      'productSlots',
      products.map((p, index) => ({ productId: p.id, slot: index + 1 }))
    );
  };

  const handleSceneOrderChange = (scenes: ISceneDetail[]) => {
    setScenesOrder(scenes);
    onEdit(
      'scenes',
      scenes.map((s) => ({
        sceneId: s.id,
        durationInSec: s.durationInSec || '0',
        isDefaultScene: s.isDefaultScene || false,
        rule: s.rule || 'NA'
      }))
    );
  };

  const addedProductIds = React.useMemo(() => {
    return productsOrder.map((p) => p.id);
  }, [productsOrder]);

  const handleSave = () => {
    setSavingStatus('saving');
    const deviceConfiguration = cloneDeep(editedDevice);
    const hasDefaultScene = scenesOrder.some((scene) => scene.isDefaultScene);
    const hasInvalidDuration = scenesOrder.some(
      (scene) => parseInt(scene.durationInSec) < 0
    );
    if (!hasDefaultScene && scenesOrder.length > 0) {
      (deviceConfiguration.scenes[0] as ISceneDetail).isDefaultScene = true;
      onEdit('scenes', deviceConfiguration.scenes);
    }

    if (hasInvalidDuration && scenesOrder.length > 0) {
      dispatch(
        actionHubAlertError(
          'Save failed! Please set a duration of at least 0 seconds.'
        )
      );
      setSavingStatus('save');
      return;
    }

    saveDeviceConfiguration(deviceConfiguration)
      .then(() => {
        onSave(deviceConfiguration);
        dispatch(actionHubAlertSuccess('Save successful!'));
      })
      .catch((err) => {
        dispatch(
          actionHubAlertError(
            'Save failed! Please try again or contact support.'
          )
        );
        console.error(err);
      })
      .finally(() => {
        setSavingStatus('save');
      });
  };

  const handlePush = () => {
    setPushingStatus('sending_command');
    sendUpdateConfigurationCommand(editedDevice.deviceId)
      .then(() => {
        dispatch(actionHubAlertSuccess('Device Configuration Updated'));
        onBack();
      })
      .catch((err) => {
        dispatch(
          actionHubAlertSuccess(
            'Changes saved! Updates will be downloaded to this display once it has an internet connection.'
          )
        );
      })
      .finally(() => {
        setPushingStatus('initial');
      });
  };

  const isSaving = React.useMemo(() => {
    return savingStatus === 'saving';
  }, [savingStatus]);

  const isPushing = React.useMemo(() => {
    return pushingStatus === 'sending_command';
  }, [pushingStatus]);

  return (
    <>
      <div className="DeviceConfiguration">
        <div className="heading">
          <button className="btn btn-back" onClick={onBack}>
            <FaChevronLeft size={20} />
            <span>Back</span>
          </button>
          <div className="actions">
            <button className="btn btn-round btn-outline d-none">
              Show Preview
            </button>
            <button
              className="btn btn-round btn-dark btn-save"
              disabled={!hasChange || isSaving || isPushing}
              onClick={handleSave}
            >
              {isSaving && <Spinner animation="border" size="sm" />}
              {StatusMessagesMapper[savingStatus]}
            </button>
            <button
              className="btn btn-round btn-dark btn-save"
              disabled={isSaving || isPushing}
              onClick={handlePush}
            >
              {isPushing && <Spinner animation="border" size="sm" />}
              {StatusMessagesMapper[pushingStatus]}
            </button>
          </div>
        </div>
        <hr className="w-100 m-0" />
        <div className="d-grid">
          <DeviceRough
            config={editedDevice}
            onChange={onEdit}
            scenes={{
              data: scenesOrder || [],
              loading: loadingScenes,
              onChange: (scenes: ISceneDetail[]) => {
                handleSceneOrderChange(scenes);
              },
              onAdd: (scenes: ISceneDetail[]) => {
                const newScenes = scenes.map((s) => ({
                  ...s,
                  isDefaultScene: false,
                  durationInSec: '30',
                  rule: 'NA'
                }));
                const updatedScenes = [...scenesOrder, ...newScenes];
                const isHasDefaultScene = updatedScenes.some(
                  (scene) => scene.isDefaultScene
                );
                if (!isHasDefaultScene && updatedScenes.length > 0) {
                  updatedScenes[0].isDefaultScene = true;
                }
                handleSceneOrderChange(updatedScenes);
              }
            }}
          />
          <div className="showcased d-flex flex-grow-1">
            <div className="product-search showcased-item">
              <ProductSearch
                onAdd={(product) => {
                  handleProductOrderChange([...productsOrder, product]);
                }}
                addedProductIds={addedProductIds || []}
              />
            </div>
            <div className="product-show showcased-item">
              <ShowCasedProduct
                onChange={handleProductOrderChange}
                loading={loadingProduct}
                products={productsOrder}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        ::global(.content-wrapper) {
          padding-top: 0 !important;
        }
        .DeviceConfiguration {
          display: flex;
          flex-direction: column;
          flex: 1;
          height: calc(100vh - 70px);
        }
        .btn-save {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
        }
        .heading {
          display: flex;
          padding: 0 20px;
          justify-content: space-between;
          align-items: center;
          height: 60px;
        }
        .btn.btn-back {
          display: flex;
          align-items: center;
          font-size: 1.3rem;
          font-weight: 500;
        }
        .actions {
          display: flex;
          gap: 10px;
        }
        .showcased {
          display: flex;
          gap: 20px;
          align-items: stretch;
        }
        .showcased-item {
          flex: 1;
          position: relative;
        }
        .showcased-item > :global(div) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .d-grid {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      `}</style>
    </>
  );
}

export default DeviceConfiguration;
