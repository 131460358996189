import React from 'react';
import { logEvent } from '../../../analytics';
import { IInspiration, LoadingStatus } from '../../../interfaces';
import { DID_CLICK_CANCEL, DID_CLICK_SAVE } from '../../../utils/constants';
import {
  saveUpdatedGalleryItem,
  Visibility
} from '../../clientSideServices/gallery';
import { RecordingEntitlement } from '../../utils/user';
import { galleryItemLegend } from './HubGalleryDetails';

interface FormData {
  title: string | null;
  description: string | null;
  visibility: Visibility | null;
}
const HubGalleryItemEdit = ({
  galleryItem,
  onClose,
  onUpdate,
  userId,
  recordingEntitlement
}: {
  galleryItem: IInspiration;
  onClose: () => void;
  onUpdate: (galleryItem: IInspiration) => void;
  userId: string;
  recordingEntitlement: RecordingEntitlement;
}) => {
  const [data, setData] = React.useState<FormData>({
    title: galleryItem.title,
    description: galleryItem.description,
    visibility: galleryItem.visibility as Visibility
  });
  const [loading, setLoading] = React.useState(LoadingStatus.INITIAL);
  const [error, setError] = React.useState('');

  const isLoading = loading === LoadingStatus.LOADING;
  const isFailed = loading === LoadingStatus.FAILED;

  const dataHasChange =
    galleryItem.title !== data.title ||
    galleryItem.description !== data.description ||
    galleryItem.visibility !== data.visibility;

  const disabledSaveButton = isLoading;

  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setData({ ...data, title: value });
  };

  const handleDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setData({ ...data, description: value });
  };

  const handleVisibility = (value: Visibility) => {
    setData({ ...data, visibility: value });
  };

  const saveGalleryItemStory = () => {
    setLoading(LoadingStatus.LOADING);
    saveUpdatedGalleryItem({ ...data, id: galleryItem.id }, userId)
      .then(() => {
        onUpdate({ ...galleryItem, ...data });
        return;
      })
      .catch((e) => {
        console.log('error update galleryItem', e);
        setLoading(LoadingStatus.FAILED);
      });
  };

  const handleSave = () => {
    logEvent(DID_CLICK_SAVE, DID_CLICK_SAVE, { payload: data });
    if (!dataHasChange) {
      return onClose();
    }

    if (!data.title?.trim()) {
      setError('Please fill empty fields.');
    } else {
      saveGalleryItemStory();
    }
  };

  const isActive = (visibility: Visibility) =>
    data.visibility === visibility ? 'btn-secondary' : 'btn-outline-secondary';

  const actionsButton = (
    <div className="action">
      <button
        className="btn btn-round btn-sm ssp-ignore btn-outline-dark"
        onClick={() => {
          onClose();
          logEvent(DID_CLICK_CANCEL, 'Gallery Item Edit Form', {
            storyId: galleryItem.id
          });
        }}
        disabled={isLoading}
      >
        Cancel
      </button>
      <button
        className="btn btn-round btn-sm ssp-ignore btn-dark"
        id="shareButton"
        onClick={handleSave}
        disabled={disabledSaveButton}
      >
        {isLoading ? 'Saving...' : isFailed ? 'Try Again' : 'Save'}
      </button>
      <style jsx>{`
        .action {
          width: 240px;
          display: flex;
          justify-content: space-between;
          margin: auto;
        }
        .action .btn {
          width: 48%;
        }
      `}</style>
    </div>
  );
  return (
    <div>
      <div className="header-container">
        <div className="form-group">
          <input
            type="text"
            value={data.title}
            className={`form-control galleryItem-title ssp-ignore ${
              error && !data.title ? 'is-invalid' : ''
            }`}
            placeholder="Enter title"
            onChange={handleTitle}
          />
          <div className="legend">
            {galleryItemLegend(
              galleryItem.createdBy,
              galleryItem.submittedAt,
              galleryItem.visibility
            )}
          </div>
        </div>
        <div className="action-container for-desktop">{actionsButton}</div>
      </div>
      <div className="form-group">
        <textarea
          className={`form-control ssp-ignore`}
          rows={4}
          value={data.description}
          onChange={handleDescription}
          placeholder="Write description"
        />
      </div>
      {recordingEntitlement !== 'PRIVATE' && (
        <div className="form-group row align-items-center galleryItem-visibility">
          <label className="col col-auto mb-0">Visibility:</label>
          <div className="btn-group col col-md-auto">
            <button
              className={`btn btn-sm ssp-ignore ${isActive('private')}`}
              onClick={() => handleVisibility('private')}
            >
              Private
            </button>
            <button
              className={`btn btn-sm ssp-ignore ${isActive('site')}`}
              onClick={() => handleVisibility('site')}
            >
              Site
            </button>
            {recordingEntitlement === 'BRAND' && (
              <button
                className={`btn btn-sm ssp-ignore ${isActive('brand')}`}
                onClick={() => handleVisibility('brand')}
              >
                Brand
              </button>
            )}
          </div>
        </div>
      )}

      {error && <p className="error text-center">{error}</p>}

      <div className="action-container for-mobile">{actionsButton}</div>
      <style jsx>{`
        .legend {
          padding: 0 15px;
        }
        .galleryItem-title {
          font-weight: 500;
          font-size: 1.2em;
        }
        .action-container {
          margin-top: 30px;
        }
        .form-group {
          margin: 0 -10px;
        }
        .galleryItem-visibility {
          margin-top: 10px;
        }
        .error {
          color: red;
        }
        .for-desktop {
          display: none;
        }
        @media (min-width: 1140px) {
          .for-mobile {
            display: none;
          }
          .for-desktop {
            display: block;
          }
          .action-container {
            margin-top: 0;
            margin-right: -10px;
          }
          .header-container {
            display: flex;
            justify-content: space-between;
          }
          .header-container .form-group {
            width: calc(100% - 280px);
          }
        }
      `}</style>
    </div>
  );
};

export default HubGalleryItemEdit;
