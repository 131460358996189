import React from 'react';
import { Spinner } from 'react-bootstrap';

const OverlayLoading = () => {
  return (
    <div>
      <div className="overlay-loading">
        <div className="overlay position-fixed vw-100 vh-100" />
        <div className="spinner-wrapper">
          <Spinner animation="border" />
        </div>
      </div>

      <style jsx>{`
        .overlay-loading .spinner-wrapper {
          position: fixed;
          left: 50%;
          top: 30%;
          transform: translate(-50%, -50%);
          z-index: 12;
        }
        .overlay-loading .overlay {
          position: fixed;
          left: 0;
          top: 0;
          z-index: 11;
          background-color: #000;
          opacity: 0.5;
        }
      `}</style>
    </div>
  );
};

export default OverlayLoading;
