import React from 'react';
import { useDispatch } from 'react-redux';
import { useLibraryAction } from '../../../../components/hooks/library';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import { useUndoRedo } from '../../../../components/hooks/undoRedo';
import {
  ILibraryScene,
  LibrarySceneType,
  LoadingStatus
} from '../../../../interfaces';
import {
  mapSceneToStorybook,
  mapStorybookSettingsStringToObject
} from '../../../../mappers/storybook';
import { actionHubAlertError } from '../../../../redux/actions';
import { isVideo } from '../../../../utils/file';
import { removeUnusedFontsInScene } from '../../../../utils/polotno';
import DeviceViewToggle, { TDeviceView } from '../../Common/DeviceViewToggle';
import UndoRedoButton from '../../Common/UndoRedoButton';

import SBPreview from '../../StoryBook/Preview';
import SBPreviewMicrosite from '../../StoryBook/Preview/SBPreviewMicrosite';
import SLBuilderActions from './SLBuilderActions';
import SLDescriptionPopup from './SLDescriptionPopup';
import SLDesignerPanel from './SLDesignerPanel';

let saveTimeout;

enum DescriptionPopupType {
  INIT = 'INIT',
  ACTIVATE_SCENE = 'ACTIVATE_SCENE',
  CLOSE_PAGE = 'CLOSE_PAGE',
  GENERATE = 'GENERATE'
}

const SLDesignerBasic = ({
  onClose,
  userId,
  initialScene,
  onSaveCallback,
  context
}: {
  onClose: () => void;
  userId: string;
  initialScene?: ILibraryScene;
  onSaveCallback?: (scenes: ILibraryScene) => void;
  context?: LibrarySceneType;
}) => {
  const dispatch = useDispatch();
  const isTemplate = context === 'template';
  const [previewScene, setPreviewScene] = React.useState<ILibraryScene>(null);
  const [deviceView, setDeviceView] = React.useState<TDeviceView>('desktop');
  const [selectedView, setSelectedView] =
    React.useState<TDeviceView>('desktop');

  const { isLoading, setLoadingStatus } = useLoadingStatus();

  const { activateScene, saveScene, getSceneById, editScene } =
    useLibraryAction({ userId, context });

  const [dataHasChanged, setDataHasChanged] = React.useState(false);

  const [currentScene, setCurrentScene] =
    React.useState<ILibraryScene>(initialScene);

  const [descriptionPopup, setDescriptionPopup] =
    React.useState<DescriptionPopupType>(null);

  const hasContent = !!currentScene.content.url;

  const isVideoScene =
    isVideo(currentScene.content.url) ||
    mapStorybookSettingsStringToObject(currentScene.content.settings)?.type ===
      'video';

  const { undo, redo, updateHistory, resetUndoRedo } = useUndoRedo(
    currentScene?.status === 'draft' && hasContent
      ? {
          dataToListen: currentScene,
          onUndoRedo: (data) => {
            setCurrentScene(data);
            setDataHasChanged(false);
          },
          shouldAddNewHistory: !dataHasChanged
        }
      : {}
  );

  const onUpdateScene = (scene: ILibraryScene, dontMarkChange?: boolean) => {
    setCurrentScene(scene);
    setDataHasChanged(!dontMarkChange);
    if (scene.status === 'draft') {
      updateHistory(scene);
    } else {
      resetUndoRedo?.();
    }
  };

  const handleCallback = (scene: ILibraryScene) => {
    onSaveCallback?.({
      ...scene,
      createdBy: scene.createdBy || userId,
      modifiedBy: userId,
      createdAt: scene.createdAt || new Date().toISOString(),
      modifiedAt: new Date().toISOString()
    });
  };

  const onSaveScene = () => {
    if (!currentScene) return;
    saveScene({
      scene: removeUnusedFontsInScene(currentScene),
      onSuccess: () => {
        setDataHasChanged(false);
        handleCallback(currentScene);
      }
    });
  };

  const onEditScene = () => {
    editScene({
      id: currentScene.id,
      version: currentScene.version,
      onSuccess: (res) => {
        onUpdateScene(res, true);
      }
    });
  };

  const onActivate = async () => {
    if (!currentScene) return;

    if (!currentScene?.content?.name?.trim()) {
      setDescriptionPopup(DescriptionPopupType.ACTIVATE_SCENE);
    } else {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }

      setLoadingStatus(`activating-${currentScene.id}`, LoadingStatus.LOADING);

      saveScene({
        scene: removeUnusedFontsInScene(currentScene),
        dontReloadAfterSave: true,
        onSuccess: () => {
          activateScene({
            id: currentScene.id,
            delay: 2700,
            onSuccess: (res) => {
              onUpdateScene(res, true);
              handleCallback(res);
            }
          });
        }
      });
    }
  };

  const handleBack = () => {
    const isActive = currentScene.status === 'activated';
    const hasTitle = !!currentScene?.content?.name?.trim();

    if (isActive || (!dataHasChanged && hasTitle)) {
      onClose();
      return;
    }

    if (!hasTitle) {
      setDescriptionPopup(DescriptionPopupType.CLOSE_PAGE);
      return;
    }

    const handleSuccess = (scene: ILibraryScene) => {
      handleCallback(scene);
      onClose();
    };

    setLoadingStatus(`closing`, LoadingStatus.LOADING);
    saveScene({
      scene: removeUnusedFontsInScene(currentScene),
      dontReloadAfterSave: true,
      onSuccess: () => {
        handleSuccess(currentScene);
        setLoadingStatus(`closing`, LoadingStatus.INITIAL);
      },
      onError: () => {
        onClose();
        setLoadingStatus(`closing`, LoadingStatus.INITIAL);
      }
    });
  };

  const getActionFromDescriptionPopup = (actionType: DescriptionPopupType) => {
    switch (actionType) {
      case DescriptionPopupType.ACTIVATE_SCENE:
        return onActivate();
      case DescriptionPopupType.CLOSE_PAGE:
        return handleBack();
      case DescriptionPopupType.GENERATE:
        return onSaveScene();
    }
  };

  const onSelectVersion = (version: number) => {
    if (!currentScene) return;
    getSceneById({
      id: currentScene.id,
      version,
      onSuccess: onUpdateScene
    });
  };

  const shouldAutoSave = currentScene?.status === 'draft' && dataHasChanged;

  React.useEffect(() => {
    const clearSaveTimeout = () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
    if (shouldAutoSave) {
      clearSaveTimeout();
      saveTimeout = setTimeout(() => {
        onSaveScene();
      }, 3000);
    }

    return () => {
      clearSaveTimeout();
    };
  }, [currentScene, shouldAutoSave]);

  const onTogglePreview = () => {
    if (previewScene) {
      setPreviewScene(null);
    } else {
      if (!currentScene.content.url) {
        dispatch(
          actionHubAlertError(
            'Landscape page cannot be blank. Please add some content to it.'
          )
        );
        return;
      }
      setPreviewScene(currentScene);
    }
  };

  const disableEditor =
    isLoading(`activating-${currentScene?.id}`) || isLoading(`closing`);

  const deviceViewer = (
    <div
      className={`deviceViewer w-100 h-100 d-flex ${deviceView}`}
      style={{
        backgroundColor:
          mapStorybookSettingsStringToObject(currentScene.content.settings)
            ?.background || '#000'
      }}
    >
      {(deviceView === 'compare' || deviceView === 'desktop') && (
        <div
          className={`desktopViewer viewer ${
            selectedView === 'desktop' ? 'active' : ''
          }`}
          onClick={() => setSelectedView('desktop')}
        >
          <SBPreviewMicrosite
            storybook={mapSceneToStorybook(currentScene)}
            deviceView="desktop"
          />
        </div>
      )}
      {(deviceView === 'compare' || deviceView === 'mobile') && (
        <div
          className={`mobileViewer viewer ${
            selectedView === 'mobile' ? 'active' : ''
          }`}
          onClick={() => setSelectedView('mobile')}
        >
          <SBPreviewMicrosite
            storybook={mapSceneToStorybook(currentScene)}
            deviceView="mobile"
          />
        </div>
      )}
      <style jsx>{`
        .viewer {
          width: 100%;
          height: 100%;
          border: 5px solid transparent;
        }
        .active {
          border-color: #ccc;
        }
        .compare .desktopViewer {
          width: 65%;
        }
        .compare .mobileViewer {
          width: 35%;
        }
      `}</style>
    </div>
  );

  const pageViewer = currentScene && (
    <>
      {previewScene ? (
        <SBPreview
          onClose={() => setPreviewScene(null)}
          storybook={mapSceneToStorybook(previewScene)}
          isForScene={true}
          previewMode="microsite"
        />
      ) : (
        deviceViewer
      )}
    </>
  );

  const uploadMessage = (
    <div>
      <h2>
        Select {isVideoScene ? 'a video' : 'an image'} from Assets Library or
        Content Manager to start.
      </h2>
      <style jsx>{`
        p {
          margin: 0;
        }
        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          max-width: 400px;
          text-align: center;
          color: #ccc;
        }
      `}</style>
    </div>
  );

  return (
    <div className={`SLDesignerBasic ${disableEditor ? 'activating' : ''}`}>
      {!previewScene && (
        <SLBuilderActions
          onBack={handleBack}
          scene={currentScene}
          onSave={(fresh) => {
            if (fresh && !currentScene?.content?.name?.trim()) {
              setDescriptionPopup(DescriptionPopupType.GENERATE);
            } else {
              onSaveScene();
            }
          }}
          onEdit={onEditScene}
          onActivate={hasContent ? onActivate : undefined}
          onSelectVersion={onSelectVersion}
          preview={
            hasContent
              ? {
                  onToggle: onTogglePreview
                }
              : undefined
          }
          isTemplate={isTemplate}
        />
      )}

      <div className="col-container">
        {!previewScene && (
          <div className="content-col side-col">
            <SLDesignerPanel
              scene={currentScene}
              onUpdate={onUpdateScene}
              context={context}
              deviceView={selectedView}
            />
          </div>
        )}

        <div className="content-col main-col flex-grow-1">
          {hasContent && !previewScene && (
            <div className="toolbar">
              <div>
                {currentScene.status === 'draft' && (undo || redo) && (
                  <UndoRedoButton
                    onRedo={redo}
                    onUndo={undo}
                    disableUndo={!undo}
                    disableRedo={!redo}
                  />
                )}
              </div>
              <DeviceViewToggle
                activeView={deviceView}
                onSelectView={(view) => {
                  setDeviceView(view);
                  setSelectedView(view === 'compare' ? 'desktop' : view);
                }}
              />
            </div>
          )}
          <div className="viewer-col flex-grow-1">
            {currentScene && hasContent && pageViewer}

            {!hasContent && uploadMessage}
          </div>
        </div>
      </div>

      {descriptionPopup && currentScene && (
        <SLDescriptionPopup
          popupTitle={`Please enter ${
            isTemplate ? 'Template' : 'Scene'
          } Details`}
          name={currentScene?.content?.name || ''}
          description={currentScene?.content?.description || ''}
          onClose={() => setDescriptionPopup(null)}
          onUpdate={(data) =>
            onUpdateScene({
              ...currentScene,
              content: { ...currentScene.content, ...data }
            })
          }
          loading={isLoading(currentScene.id)}
          onSave={() => {
            getActionFromDescriptionPopup(descriptionPopup);
            setDescriptionPopup(null);
          }}
        />
      )}

      <style jsx global>{`
        .SceneLibrary .content-wrapper {
          padding: 0 !important;
        }

        .header > *:not(.logo-container) {
          visibility: hidden !important;
        }
        .SBPreview {
          z-index: 20;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: #fff;
        }
      `}</style>

      <style jsx>{`
        .toolbar {
          display: flex;
          padding: 10px 20px;
          border-bottom: 1px solid #ccc;
          justify-content: space-between;
        }

        .SLDesignerBasic {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-content: stretch;
        }
        .SLDesignerBasic :global(.panel-label) {
          font-weight: bold;
          font-size: 14px;
        }

        .SLDesignerBasic :global(label) {
          font-weight: bold;
          font-size: 12px;
        }

        .SLDesignerBasic :global(.panel-header .form-control) {
          margin-top: 7px;
        }

        .col-container {
          height: 100%;
          overflow: hidden;
          display: flex;
        }
        .content-col {
          height: 100%;
          overflow-y: auto;
          position: relative;
          overflow-x: hidden;
        }
        .main-col {
          height: 100%;
          display: flex;
          justify-content: flex-start;
          position: relative;
          flex-direction: column;
        }

        .activating .col-container {
          opacity: 0.5;
          pointer-events: none;
        }

        .viewer-col {
          overflow: hidden;
          position: relative;
        }

        .thumbnail-col {
          height: 170px;
          min-height: 170px;
          overflow: hidden;
          border-top: 1px solid #ccc;
        }

        .side-col {
          border-right: 1px solid #ccc;
          position: relative;
          overflow: visible;
          z-index: 10;
        }

        .disable {
          opacity: 0.8;
          background: #fff;
          position: absolute;
          inset: 0;
        }
        .hide {
          display: none !important;
        }

        .close-container {
          position: absolute;
          right: 5px;
          top: 16px;
        }
        .open-container {
          position: absolute;
          inset: 0;
          background: #ccc;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          opacity: 0.3;
        }
        .open-container > :global(*) {
          margin: auto;
        }
        .open-container:hover {
          opacity: 1;
        }
      `}</style>
    </div>
  );
};

export default SLDesignerBasic;
