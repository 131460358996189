import { ILibraryScene } from '../../../../interfaces';
import { TLanguage } from '../../../../mappers/polotno';
import {
  mapSceneToStorybook,
  mapStorybookSettingsStringToObject
} from '../../../../mappers/storybook';
import { isVideo } from '../../../../utils/file';
import { getStorybookPageType } from '../../../utils/storybook';
import PageLabel from '../../StoryBook/Common/PageLabel';
import {
  SBImagePage,
  SBLayerPage,
  SBVideoPage
} from '../../StoryBook/Mirosite/SBMicrositePageType';

const SLSceneViewer = ({
  scene,
  noPadding,
  action,
  activateLanguage
}: {
  scene: ILibraryScene;
  noPadding?: boolean;
  action?: { label: string; action: () => void };
  activateLanguage?: TLanguage;
}) => {
  const settings =
    mapStorybookSettingsStringToObject(scene.content.settings) || {};

  const { color, background, text } = settings;
  const isVideoScene = isVideo(scene.content.url);

  const page = mapSceneToStorybook(scene).pages[0];

  const type = getStorybookPageType(page, settings);
  return (
    <div
      className={`SLSceneViewer h-100 w-100 ${noPadding ? 'noPadding' : ''}`}
    >
      <div
        className="media-container shadow-box h-100 w-100"
        style={{ backgroundColor: background || '#000' }}
      >
        {type === 'video' && (
          <SBVideoPage
            page={page}
            setting={settings}
            canPlay={true}
            controls={true}
          />
        )}
        {type === 'image' && <SBImagePage page={page} setting={settings} />}
        {type === 'layer' && (
          <SBLayerPage
            page={page}
            setting={settings}
            canPlay={true}
            portrait={false}
            activateLanguage={activateLanguage}
          />
        )}

        {(scene?.content.title || scene?.content.subtitle) && (
          <div className="label-container">
            <PageLabel
              title={scene?.content.title}
              subtitle={scene?.content.subtitle}
              background={color}
              textColor={text}
            />
          </div>
        )}
      </div>
      {action && (
        <button
          className="btn btn-sm btn-round btn-light btn-viewer"
          onClick={action.action}
        >
          {action.label}
        </button>
      )}
      <style jsx>{`
        .btn-viewer {
          opacity: 0.3;
          position: absolute;
          right: 5px;
          top: 5px;
        }
        img {
          position: absolute;
          top: 50%;
          width: 100%;
          height: 100%;
          object-fit: contain;
          transform: translateY(-50%);
        }
        .SLSceneViewer {
          padding: 10px;
          z-index: 20;
          background: #fff;
          position: absolute;
          inset: 0;
        }
        .SLSceneViewer:hover .btn-viewer {
          opacity: 0.8;
        }
        .noPadding {
          padding: 0;
        }
        .media-container :global(video) {
          // border-radius: 5px;
        }
        .media-container {
          border-radius: 5px;
          overflow: hidden;
          position: relative;
          display: flex;
          flex-direction: column;
        }
        .noPadding .media-container :global(video),
        .noPadding .media-container {
          border-radius: 0;
        }
        .label-container {
          position: absolute;
          pointer-events: none;
          left: 0;
          bottom: 0;
        }
        .overlay {
          pointer-events: none;
        }
        .media-container:hover .label-container {
          display: ${isVideoScene ? 'none' : 'block'};
        }
      `}</style>
    </div>
  );
};

export default SLSceneViewer;
