import dynamic from 'next/dynamic';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FaFirstdraft } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import {
  AssetLibraryPopupState,
  IAsset,
  ILibraryScene,
  IStorybookPage,
  LibrarySceneType
} from '../../../../interfaces';
import { appendNewValueIntoSetting } from '../../../../mappers/storybook';
import {
  actionHubAlertError,
  actionSetPopupAssets
} from '../../../../redux/actions';
import { isVideo } from '../../../../utils/file';
import { PageConfigContext } from '../../../clientSideServices/pageConfig';
import { getSceneContentType } from '../../../utils/library';
import { TDeviceView } from '../../Common/DeviceViewToggle';
import { VideoIcon } from '../../Common/HubIcons';
import SideMenuPanel from '../../Common/SideMenuPanel';
import ContentManager from '../../StoryBook/ContentManager';
import SLSceneDetails from './SLSceneDetails';

const VideosPanel = dynamic(() => import('../../Polotno/VideoSection/VideosPanel'), {
  loading: () => <div className='spinner-container'><Spinner animation="border" size="sm" /></div>,
  ssr: false
})

const SLDesignerPanel = ({
  scene,
  onUpdate,
  context,
  deviceView
}: {
  scene: ILibraryScene;
  onUpdate: (p: ILibraryScene) => void;
  context: LibrarySceneType;
  deviceView: TDeviceView;
}) => {
  const dispatch = useDispatch();

  const sceneType = getSceneContentType(scene);
  const isVideoType = sceneType === 'video';
  const isImageType = sceneType === 'image';
  const isActive = scene.status === 'activated';

  const handleSelectItem = (item: IStorybookPage) => {
    if (isVideoType && !isVideo(item.url)) {
      dispatch(
        actionHubAlertError(
          'Selected content type is not supported. Please select a video content.'
        )
      );
      return;
    }

    if (isImageType && isVideo(item.url)) {
      dispatch(
        actionHubAlertError(
          'Selected content type is not supported. Please select an image content.'
        )
      );
      return;
    }

    let updatedScene = { ...scene };

    if (deviceView === 'desktop') {
      updatedScene = {
        ...scene,
        content: {
          ...scene.content,
          url: item.url,
          thumbnail: item.thumbnail
        }
      };
    }

    if (deviceView === 'mobile') {
      updatedScene = {
        ...scene,
        content: {
          ...scene.content,
          url: scene.content.url || item.url,
          thumbnail: scene.content.thumbnail || item.thumbnail,
          settings: appendNewValueIntoSetting(scene.content.settings, {
            portraitUrl: item.url,
            portraitThumbnail: item.thumbnail
          })
        }
      };
    }

    if (deviceView === 'compare') {
      updatedScene = {
        ...scene,
        content: {
          ...scene.content,
          url: item.url,
          thumbnail: item.thumbnail,
          settings: appendNewValueIntoSetting(scene.content.settings, {
            portraitUrl: undefined,
            portraitThumbnail: undefined
          })
        }
      };
    }
    setPopupAssets(undefined);
    onUpdate(updatedScene);
  };

  const handleSelectAsset = (asset: IAsset) => {
    const item: IStorybookPage = {
      url: asset.convertedURL,
      thumbnail: asset.assetPreviewURL
    };

    handleSelectItem(item);
  };

  const setPopupAssets = (payload: AssetLibraryPopupState) => {
    dispatch(actionSetPopupAssets(payload));
  };

  const handleOpenAssets = () => {
    setPopupAssets({
      popupTitle: 'Select a Video',
      popupContext: 'assetForVideo',
      onSelectAsset: handleSelectAsset,
      acceptTypes: '.mp4, .qt, .mov'
    });
  };

  const settingPanel = (
    <div className="h-100 w-100">
      <SLSceneDetails
        onUpdate={onUpdate}
        scene={scene}
        context={context}
        isVideo={isVideoType}
      />
      <style jsx>
        {`
          div {
            overflow: auto;
          }
        `}
      </style>
    </div>
  );

  const contentPanel = (
    <div className="h-100 w-100">
      <ContentManager
        contentId={scene.id}
        context={PageConfigContext.SCENE_DESIGNER}
        onSelectItems={(sb) => handleSelectItem(sb[0].pages[0])}
        inlineViewer={true}
        fileType={isVideoType ? ['video'] : ['image', 'video']}
      />
      <style jsx>
        {`
          div {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
          }
          div :global(.ContentManager) {
            height: 100%;
          }
          div :global(.panel-header),
          div :global(.ContentManager .panel-header) {
            padding: 12px 10px;
            height: 50px !important;
            border-bottom: 1px solid #e1e1e1;
          }
          div :global(.panel-content) {
            height: 50%;
            overflow: auto;
            height: calc(100% - 50px) !important;
          }
        `}
      </style>
    </div>
  );

  React.useEffect(() => {
    if (!scene.content.url) {
      handleOpenAssets();
    }
  }, [scene.id]);

  React.useEffect(() => {
    if (isActive) {
      document.getElementById('side-menu-item-settings').click();
    }
  }, [isActive]);
  return (
    <>
      <link href="/libs/polotno/blueprint-icons.css" rel="stylesheet" />
      <link href="/libs/polotno/blueprint.css" rel="stylesheet" />
      <link href="/libs/polotno/blueprint-popover2.css" rel="stylesheet" />
    <SideMenuPanel
      defaultMenu={!scene?.content?.name ? 'settings' : ''}
      menuItems={[
        {
          label: 'Settings',
          id: 'settings',
          icon: <MdSettings size="18" />,    
          panel: settingPanel
        },
        {
          label: 'Videos',
          id: 'videos',
          icon: <VideoIcon />,
          panel: <VideosPanel handleClick={(item:IAsset) => {
            handleSelectAsset(item)
          }}/>,
          disabled: isActive
        },
        {
          label: 'Content',
          id: 'content',
          icon: <FaFirstdraft size="15" />,
          panel: contentPanel,
          disabled: isActive
        }
      ]}
    />
      <style jsx>
        {`
          :global(.videos-section) {
            padding: 10px;
          }
          :global(.spinner-container) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        `}
      </style>
    </>
  );
};

export default SLDesignerPanel;
