import React from 'react';
import { GoChevronLeft } from 'react-icons/go';
import { staticBaseUrl } from '../../../config';
import { PageView } from '../../../utils/pageView';
import { hubNavTo } from '../../utils/hubPagesRoute';
import ChatActions from './ChatActions';
import ChatAvailabilityButton from './ChatAvailabilityButton';
import ChatContainer from './ChatContainer';
import { HubChatContext } from './HubChatContext';
import MobileChatDetails from './MobileChatDetails';

const HubChatDetails = () => {
  const {
    conversations,
    currentConversation,
    chatUsers,
    setCurrentConversation
  } = React.useContext(HubChatContext);
  const details = conversations.find(
    (conversation) => conversation?.conversationId === currentConversation
  );
  const [isActionDropdownOpen, setActionDropdownOpen] = React.useState(false);

  const customerName =
    chatUsers?.[details?.conversationCreatedBy]?.alias || 'Customer';
  return (
    <ChatContainer>
      <div className={`top row  ${isActionDropdownOpen && `border-bottom`}`}>
        <div className="col-12 chat-details-header">
          <div className="chat-details-header-left">
            <button
              className="btn header-back-button"
              onClick={() => {
                setCurrentConversation(null);
                hubNavTo(PageView.HUB_CONTACT_CENTER);
              }}
            >
              <GoChevronLeft size="1.5em" />
            </button>
            <div className="customer-name">{customerName}</div>
          </div>
          <button
            className="actions-dropdown-button"
            onClick={() => {
              setActionDropdownOpen(!isActionDropdownOpen);
            }}
          >
            <img
              src={`${staticBaseUrl}/connect/assets/${
                isActionDropdownOpen ? 'menu-grey.svg' : 'menu.svg'
              }`}
              alt="Menu"
            />
          </button>
        </div>
        <div
          className={`col-12 chat-dropdown-actions ${
            isActionDropdownOpen && `isOpen`
          }`}
        >
          <ChatActions />
          <ChatAvailabilityButton theme="light" />
        </div>
      </div>
      <MobileChatDetails />
      <style jsx>{`
        :global(.top) {
          padding: 0;
        }
        .border-bottom {
          border-bottom: 1px solid #c4c4c4;
        }
        .chat-details-header {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0;
          padding-right: 15px;
          margin-bottom: 0;
        }
        .customer-name {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-transform: uppercase;
        }
        .chat-details-header-left {
          display: flex;
          align-items: center;
          max-width: 60%;
        }
        .header-back-button {
          padding: 0 5px;
        }
        .customer-status-indicator {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: #828282;
        }

        .customer-status-indicator.online {
          background: #008700;
        }
        .actions-dropdown-button {
          border: none;
          background: transparent;
        }

        .chat-dropdown-actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          padding-right: 15px;
          display: none;
        }

        .chat-dropdown-actions.isOpen {
          display: flex;
        }

        :global(.transfer-btn) {
          margin-left: 5px;
          border-radius: 50%;
          height: 37px;
          width: 37px;
          justify-content: center;
        }
        :global(.transfer-btn img) {
          margin-right: 0;
        }
        :global(.join-btn) {
          padding: 0;
        }
      `}</style>
    </ChatContainer>
  );
};

export default HubChatDetails;
