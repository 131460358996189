import React from 'react';
import { ILibraryScene } from '../../../../interfaces';
import { dateFormat } from '../../../../utils/clock';
import { getFileType } from '../../../../utils/file';
import {
  formatDurationFromMiliSeconds,
  formattedNumber
} from '../../../../utils/number';
import { getLanguageJSON } from '../../../utils/storybook';
import UserLabel from '../../Common/UserLabel';
import ImageWithWebp from '../../Common/ImageWithWebp';
import SBThumbnailCover from '../../StoryBook/Common/SBThumbnailCover';

function SBAnalyticsRough({
  scene,
  selectedItem,
  setSelectedItem,
  listItemTab,
  setListItemTab,
  data
}: {
  scene: ILibraryScene;
  selectedItem: any;
  setSelectedItem: any;
  listItemTab: any;
  setListItemTab: any;
  data: any;
}) {
  const language = 'en';
  const content = scene.content || {};
  const thumbnailObj = getLanguageJSON(content.thumbnail || content.url);
  return (
    <>
      <div className="analytics-rough">
        {listItemTab.length > 0 && selectedItem ? (
          <>
            <div
              className="back-icon"
              onClick={() => {
                setListItemTab([]);
                setSelectedItem(null);
              }}
            >
              <svg
                width="12"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 2L3 9.5L10 17" stroke="#7F7F7F" strokeWidth="3" />
              </svg>
              <span>Back</span>
            </div>
            <div className="item-list__container">
              {listItemTab.map((l) => (
                <div
                  className={
                    'item-container ' +
                    (selectedItem?.id === l.id ? 'selected-image' : '')
                  }
                  onClick={() =>
                    setSelectedItem({ ...l, type: selectedItem?.type })
                  }
                  key={l.id}
                >
                  <div className="img-container">
                    <ImageWithWebp
                      src={l.thumbnail}
                      alt={l.name}
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <b>
                    <span>{l.name}</span>
                  </b>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="analytics-rough__main">
            <div className="analytics-rough__basic">
              <SBThumbnailCover
                type={getFileType(content.url)}
                url={thumbnailObj[language]}
              />
              <div className="basic-info">
                By: <UserLabel id={scene.createdBy} />
              </div>
              <div className="basic-info">
                Created: {dateFormat(scene?.createdAt, 'MMM dd, yyyy')}
              </div>
              <div className="basic-info">
                Modified: {dateFormat(scene?.modifiedAt, 'MMM dd, yyyy')}
              </div>
            </div>
            <div className="cards">
              <div className="card-basic">
                <h3>Shared</h3>
                <div>{formattedNumber(data?.shared)}</div>
              </div>
              <div className="card-basic">
                <h3>Watch Time</h3>
                <div>
                  {formatDurationFromMiliSeconds(data?.totalEyeballTime || 0)}
                </div>
              </div>
              <div className="card-basic">
                <h3>Viewers</h3>
                <div>{formattedNumber(data?.uniqueUsers || 0)}</div>
              </div>
              <div className="card-basic">
                <h3>Returning Viewers</h3>
                <div>{formattedNumber(data?.returningUsers || 0)}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .back-icon {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px;
          font-size: 22px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 5px;
          cursor: pointer;
          border-bottom: 1px solid #ccc;
        }

        .back-icon span {
          font-size: 18px;
          font-weight: 600;
        }
        .analytics-rough__main {
          padding: 20px;
          overflow: auto;
          max-height: calc(100vh - 150px);
        }
        .item-list__container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 15px;
          max-height: calc(100vh - 185px);
          overflow-x: scroll;
        }
        .item-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          border-radius: 5px;
          text-align: center;
        }
        .item-container b {
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        .img-container {
          width: 240px;
          max-height: 180px;
          padding: 7px;
        }

        .selected-image {
          border: 3px solid #8f6599;
        }

        .basic-info {
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 10px 0;
        }
        .cards {
          margin-top: 30px;
        }
        .card-basic {
          border-radius: 7.043px;
          background: #fff;
          box-shadow: 3.521px 3.521px 7.043px 0px rgba(0, 0, 0, 0.2);
          padding: 15px 20px;
          margin: 20px 0;
        }

        .card-basic h3 {
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .card-basic > div {
          color: #252525;
          text-align: right;
          font-size: 25.354px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      `}</style>
    </>
  );
}

export default SBAnalyticsRough;
