/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import BrandThemePreviewer from './BrandThemePreviewer';
import UploadButton from '../Common/FileUploader/UploadButton';
import ColorInput from '../Common/ColorInput';
import {
  removeBrandAssets,
  updateBrand,
  uploadBrandAsset
} from '../../../clientSideServices/brand';
import {
  mapAccountBrandToUpdatePayload,
  mapBrandSetupRes
} from '../../utils/mapper';
import { useBreakPointDetector } from '../hooks/viewport';
import { isHexColor, lightOrDarkbyHSL } from '../../../utils/color';
import {
  INSPIFY_DARK_LOGO,
  INSPIFY_LIGHT_LOGO,
  isResourceFromAssetLib
} from '../../../config';
import { HubContext } from '../HubContext';
import { IconPaint, IconUploadImage } from '../Common/HubIcons';
import PopupContainer from '../Common/PopupContainer';
import { logClickButton, logEvent } from '../../../analytics';
import {
  DID_CLICK_BRANDING_CHANGE_FILE,
  DID_CLICK_BRANDING_DELETE_FILE,
  DID_CLICK_BRANDING_PREVIEW_THEME_BUTTON,
  DID_CLICK_BUTTON,
  DID_UPDATE_BRAND_THEME_FAILED,
  DID_UPDATE_BRAND_THEME_SUCCESSFUL,
  HUB_BRANDING_EVENT
} from '../../../utils/constants';
import { ImageUpload } from '../Common/FileUploader/MediaUpload';
import { IAsset } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import {
  actionHubAlertError,
  actionSetPopupAssets
} from '../../../redux/actions';
import { replaceAssetLink } from '../../utils/common';

type BrandProfileFormValues = {
  primaryColor: string;
  secondaryColor: string;
  headerFont: string;
  headerFontUrl: string;
  bodyFont: string;
  bodyFontUrl: string;
  darkLogo: string;
  lightLogo: string;
  lightLogoFile?: File;
  lightLogoSelectedAsset?: IAsset;
  darkLogoFile?: File;
  darkLogoSelectedAsset?: IAsset;
  headerFontFile?: File;
  bodyFontFile?: File;
};

type BrandProfileForm = {
  values: BrandProfileFormValues;
  state?: {
    submitted?: boolean;
    submitting?: boolean;
  };
  errors?: Partial<BrandProfileFormValues>;
};

const BrandTheme: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { brandSetup, setBrandSetup } = useContext(HubContext);

  const brandFormInitialValues = useMemo<BrandProfileFormValues>(() => {
    return {
      primaryColor: brandSetup?.theme?.color?.primary || '#000000',
      secondaryColor: brandSetup?.theme?.color?.secondary || '#4B2953',
      darkLogo:
        brandSetup?.theme?.logo?.dark !== INSPIFY_DARK_LOGO
          ? brandSetup?.theme?.logo?.dark
          : '',
      lightLogo:
        brandSetup?.theme?.logo?.light !== INSPIFY_LIGHT_LOGO
          ? brandSetup?.theme?.logo?.light
          : '',
      headerFont: brandSetup?.theme?.font?.headerFont || 'bold',
      headerFontUrl: brandSetup?.theme?.font?.headerFontUrl || '',
      bodyFont: brandSetup?.theme?.font?.bodyFont || 'normal',
      bodyFontUrl: brandSetup?.theme?.font?.bodyFontUrl || ''
    };
  }, [brandSetup]);

  const { screenSize } = useBreakPointDetector();

  const [form, setFormState] = useState<BrandProfileForm>({
    values: brandFormInitialValues
  });

  const [activeMobileSection, setActionSectionOnMobile] = useState<
    'logo' | 'style'
  >('logo');

  const [, setLoading] = useState(false);
  const [showBrandingPreviewer, setShowBrandingPreviewer] = useState(false);


  useEffect(() => {
    setFormState((prev) => ({
      ...(prev || {}),
      values: brandFormInitialValues
    }));
  }, [brandFormInitialValues]);

  const debouncedChangePrimaryColor = useMemo(() => {
    return debounce(async (value) => {
      await setFieldValue('primaryColor', value);
      if (isHexColor(value)) {
        saveChanges('primaryColor');
      }
    }, 200);
  }, []);

  const debouncedChangeSecondaryColor = useMemo(() => {
    return debounce(async (value) => {
      await setFieldValue('secondaryColor', value);
      if (isHexColor(value)) {
        saveChanges('secondaryColor');
      }
    }, 200);
  }, []);

  const setFieldValue = (
    name: keyof BrandProfileFormValues,
    val: string | File
  ) => {
    setFormState((prev) => ({
      ...(prev || {}),
      values: {
        ...(prev?.values || ({} as any)),
        [name]: val
      }
    }));
  };

  const setFieldsValue = (values: Partial<BrandProfileFormValues>) => {
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...values
      }
    }));
  };

  const logBrandThemClickEvent = (
    target: 'LIGHT_LOGO' | 'DARK_LOGO' | 'HEADER_FONT' | 'BODY_FONT',
    button: 'CHANGE' | 'DELETE',
    assetUrl = ''
  ) => {
    const clickAction =
      button === 'CHANGE'
        ? DID_CLICK_BRANDING_CHANGE_FILE
        : DID_CLICK_BRANDING_DELETE_FILE;
    logEvent(DID_CLICK_BUTTON, clickAction, { target, assetUrl });
  };

  const saveChanges = (target: keyof BrandProfileForm['values']) => {
    setFormState((form) => {
      if (!isEmpty(Object.values(form?.errors || '').join())) {
        return;
      }
      if (form?.state?.submitting) {
        return form;
      }
      const updateBrandSetup = cloneDeep(brandSetup);

      updateBrandSetup.theme.logo.dark =
        form?.values?.darkLogo !== INSPIFY_DARK_LOGO
          ? form?.values?.darkLogo
          : '';
      updateBrandSetup.theme.logo.light =
        form?.values?.lightLogo !== INSPIFY_LIGHT_LOGO
          ? form?.values?.lightLogo
          : '';
      updateBrandSetup.theme.color.secondary = form?.values?.secondaryColor;
      updateBrandSetup.theme.color.primary = form?.values?.primaryColor;
      updateBrandSetup.theme.font.headerFont = form?.values?.headerFont;
      updateBrandSetup.theme.font.headerFontUrl = form?.values?.headerFontUrl;
      updateBrandSetup.theme.font.bodyFont = form?.values?.bodyFont;
      updateBrandSetup.theme.font.bodyFontUrl = form?.values?.bodyFontUrl;

      const { brand } = mapAccountBrandToUpdatePayload(
        undefined,
        updateBrandSetup
      );
      const { brand: initialBranding } = mapAccountBrandToUpdatePayload(
        undefined,
        brandSetup
      );
      const brandingChanges = Object.entries(brand || {}).reduce(
        (accChanges, [key, val]) => {
          if (brand[key] != initialBranding[key]) {
            return Object.assign(accChanges || {}, { [key]: val });
          }
          return accChanges;
        },
        {}
      );

      updateBrand(brandSetup.id, brandSetup.brandUrl, brandingChanges)
        .then(({ data }) => {
          const updatedBrandSetup = mapBrandSetupRes(data);
          Object.assign(brandSetup || {}, updatedBrandSetup);
          setBrandSetup((prev) => ({ ...prev, brandSetup: brandSetup }));
          logEvent(HUB_BRANDING_EVENT, DID_UPDATE_BRAND_THEME_SUCCESSFUL, {
            data: form.values,
            brandUrl: brandSetup.brandUrl,
            brandId: brandSetup.id,
            target
          });
        })
        .catch((e) => {
          logEvent(HUB_BRANDING_EVENT, DID_UPDATE_BRAND_THEME_FAILED, {
            data: form.values,
            brandUrl: brandSetup.brandUrl,
            brandId: brandSetup.id,
            errorMessage: e?.message,
            target
          });
        })
        .finally(() => {
          setFormState((form) => ({
            ...form,
            state: { ...form?.state, submitting: false }
          }));
        });
      return {
        ...form,
        state: { ...form?.state, submitting: true, submitted: true }
      };
    });
  };

  const renderBrandStyleInput = () => (
    <>
      {(screenSize !== 'xs' || activeMobileSection === 'logo') && (
        <Row>
          <Col md={{ span: 12 }} xl={{ span: 6 }} className="logo-inputs">
            <Form.Group className="field-light-logo" id="light-logo">
              <Form.Label className="font-weight-bold">Light logo</Form.Label>
              <p className="text-muted">
                Displayed on Dark Background <br />
                *.SVG/PNG - Max Size 1MB
              </p>
              <ImageUpload
                theme="dark"
                src={replaceAssetLink(form.values.lightLogo)}
                alt="light logo"
                acceptTypes="image/png, image/svg+xml"
                maxSize={1 * 1024 * 1024}
                maxResolution={{
                  width: 700
                }}
                crop={false}
                roundedInlinePreview
                onRemove={async () => {
                  logBrandThemClickEvent(
                    'LIGHT_LOGO',
                    'DELETE',
                    form.values?.lightLogo
                  );
                  if (!form.values.lightLogo) {
                    return;
                  }

                  setFieldsValue({
                    lightLogo: '',
                    lightLogoFile: undefined
                  });
                  if (!isResourceFromAssetLib(form.values.lightLogo)) {
                    removeBrandAssets(form.values.lightLogo);
                  }
                  saveChanges('lightLogo');
                }}
                onUpload={(file) => {
                  return uploadBrandAsset({
                    file,
                    path: 'logo/light',
                    brand: brandSetup?.id
                  })
                    .then(({ data }) => {
                      if (
                        form?.values?.lightLogo &&
                        !isResourceFromAssetLib(form.values.lightLogo)
                      ) {
                        removeBrandAssets(form?.values?.lightLogo);
                      }

                      if (form?.values) {
                        setFieldsValue({
                          lightLogo: data.url,
                          lightLogoFile: undefined
                        });
                        saveChanges('lightLogo');
                      }
                      return;
                    })
                    .catch((error) => {
                      console.log('error', error);
                    });
                }}
                onClick={() => {
                  dispatch(
                    actionSetPopupAssets({
                      popupContext: 'branding',
                      popupTitle: 'Asset Library',
                      acceptTypes: 'image/png, image/svg+xml',
                      onSelectAsset(asset) {
                        if (
                          !['image/png', 'image/svg+xml'].includes(
                            asset.assetType
                          )
                        ) {
                          dispatch(actionHubAlertError('Invalid asset type.'));
                          return;
                        }
                        setFieldsValue({
                          lightLogoSelectedAsset: asset
                        });
                        dispatch(actionSetPopupAssets({ popupContext: '' }));
                      },
                      onAddFileToUpload: (file) => {
                        setFieldValue('lightLogoFile', file);
                        dispatch(actionSetPopupAssets({ popupContext: '' }));
                      }
                    })
                  );
                }}
                libraryAsset={form.values?.lightLogoSelectedAsset}
                file={form.values?.lightLogoFile}
                onSaveAssetUrl={async (url) => {
                  setFieldsValue({
                    lightLogo: url
                  });
                  saveChanges('lightLogo');
                }}
              />
            </Form.Group>
          </Col>
          <Col md={{ span: 12 }} xl={{ span: 6 }} className="logo-inputs">
            <Form.Group className="field-light-logo" id="dark-logo">
              <Form.Label className="font-weight-bold">Dark logo</Form.Label>
              <p className="text-muted">
                Displayed on Light Background <br />
                *.SVG/PNG - Max Size 1MB
              </p>

              <ImageUpload
                theme="light"
                src={replaceAssetLink(form.values.darkLogo)}
                alt="dark logo"
                acceptTypes="image/png, image/svg+xml"
                maxSize={1 * 1024 * 1024}
                maxResolution={{
                  width: 700
                }}
                crop={false}
                roundedInlinePreview
                onRemove={async () => {
                  logBrandThemClickEvent(
                    'LIGHT_LOGO',
                    'DELETE',
                    form.values?.darkLogo
                  );
                  if (!form.values.darkLogo) {
                    return;
                  }
                  setFieldsValue({
                    darkLogo: '',
                    darkLogoFile: undefined
                  });
                  if (!isResourceFromAssetLib(form?.values?.darkLogo)) {
                    removeBrandAssets(form?.values?.darkLogo);
                  }
                  saveChanges('darkLogo');
                }}
                onUpload={(file) => {
                  return uploadBrandAsset({
                    file,
                    path: 'logo/dark',
                    brand: brandSetup?.id
                  })
                    .then(({ data }) => {
                      if (form?.values?.darkLogo) {
                        removeBrandAssets(form?.values?.darkLogo);
                      }
                      if (form?.values) {
                        setFieldsValue({
                          darkLogo: data.url,
                          darkLogoFile: undefined
                        });
                        saveChanges('darkLogo');
                      }
                      return;
                    })
                    .catch((error) => {
                      console.log('error', error);
                    });
                }}
                onClick={() => {
                  dispatch(
                    actionSetPopupAssets({
                      popupContext: 'branding',
                      popupTitle: 'Asset Library',
                      acceptTypes: 'image/png, image/svg+xml',
                      onSelectAsset(asset) {
                        if (
                          !['image/png', 'image/svg+xml'].includes(
                            asset.assetType
                          )
                        ) {
                          dispatch(actionHubAlertError('Invalid asset type.'));
                          return;
                        }
                        setFieldsValue({
                          darkLogoSelectedAsset: asset
                        });
                        dispatch(actionSetPopupAssets({ popupContext: '' }));
                      },
                      onAddFileToUpload: (file) => {
                        setFieldValue('darkLogoFile', file);
                        dispatch(actionSetPopupAssets({ popupContext: '' }));
                      }
                    })
                  );
                }}
                libraryAsset={form.values?.darkLogoSelectedAsset}
                file={form.values?.darkLogoFile}
                onSaveAssetUrl={(url) => {
                  setFieldsValue({
                    darkLogo: url
                  });
                  saveChanges('darkLogo');
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {(screenSize !== 'xs' || activeMobileSection === 'style') && (
        <>
          <div>
            <h5 className="font-weight-bold mb-1 mt-2">Brand Colors</h5>
            <Form.Group className="mb-2">
              <div className="d-sm-flex d-block">
                <div className="md-mr-4 mb-3 pr-2">
                  <label className="font-weight-normal">Primary Color</label>
                  <ColorInput
                    value={form?.values?.primaryColor}
                    onChange={debouncedChangePrimaryColor}
                  />
                </div>

                <div className="md-mr-4 mb-1 pr-2">
                  <label className="font-weight-normal">Secondary Color</label>
                  <ColorInput
                    value={form?.values?.secondaryColor}
                    onChange={(value) => {
                      if (lightOrDarkbyHSL(value) === 'light') {
                        setFormState((prev) => ({
                          ...prev,
                          values: {
                            ...prev.values,
                            secondaryColor: prev.values.secondaryColor
                          },
                          errors: {
                            secondaryColor:
                              'Please choose a dark secondary color.'
                          }
                        }));
                        return;
                      }
                      setFormState((prev) => ({
                        ...prev,
                        errors: {
                          ...(prev.errors || {}),
                          secondaryColor: ''
                        }
                      }));
                      debouncedChangeSecondaryColor(value);
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: 'block', minHeight: 19 }}
                  >
                    {form?.errors?.secondaryColor}
                  </Form.Control.Feedback>
                </div>
              </div>
            </Form.Group>
          </div>
          <div>
            <h5 className="font-weight-bold mb-3 mt-2">Brand Fonts</h5>
            <Row className="mb-2 header-font">
              <Col>
                <label className="font-weight-normal">Header Font</label>
              </Col>
            </Row>
            <Row className="align-items-center header-font">
              <Col xs={{ span: 12 }}>
                <div className="custom-font d-flex justify-content-between flex-sm-row flex-column">
                  <UploadButton
                    className={`btn-upload-font font-weight-bold`}
                    wrapperClassName={`w-100 btn-upload-wrapper  ${
                      form?.values?.headerFont || 'lighter'
                    }`}
                    fallback="Default - Montserrat"
                    accept=".ttf,.woff,.woff2"
                    file={form?.values?.headerFontFile}
                    fileUrl={form?.values?.headerFontUrl}
                    onDelete={() => {
                      logBrandThemClickEvent(
                        'HEADER_FONT',
                        'DELETE',
                        form.values?.headerFontUrl
                      );
                      setLoading(true);
                      setFieldValue('headerFontUrl', '');
                      saveChanges('headerFontUrl');

                      if (isResourceFromAssetLib(form?.values?.headerFontUrl)) {
                        return Promise.resolve();
                      }
                      return removeBrandAssets(
                        form?.values?.headerFontUrl
                      ).finally(() => {
                        setLoading(false);
                      });
                    }}
                    onUpload={(file) => {
                      setFieldValue('headerFontFile', file);
                      setLoading(true);
                      return uploadBrandAsset({
                        file,
                        path: 'font/header',
                        brand: brandSetup?.id
                      })
                        .then(({ data }) => {
                          setFieldValue('headerFontUrl', data.url);
                          saveChanges('headerFontUrl');
                        })
                        .catch((err) => {
                          console.log('error', err);
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                    integratedWithAssetLib
                    onClickUpload={() => {
                      logBrandThemClickEvent('HEADER_FONT', 'CHANGE');
                      dispatch(
                        actionSetPopupAssets({
                          popupContext: 'branding',
                          popupTitle: 'Asset Library',
                          acceptTypes: '.ttf,.woff,.woff2',
                          onSelectAsset(asset) {
                            if (
                              !['font/ttf', 'font/woff', 'font/woff2'].includes(
                                asset.assetType
                              )
                            ) {
                              dispatch(
                                actionHubAlertError('Invalid asset type.')
                              );
                              return;
                            }
                            setFieldsValue({
                              headerFontUrl: asset.convertedURL,
                              headerFontFile: undefined
                            });
                            saveChanges('headerFontUrl');
                            dispatch(
                              actionSetPopupAssets({ popupContext: '' })
                            );
                          },
                          onAddFileToUpload: (file) => {
                            setFieldValue('headerFontFile', file);
                            dispatch(
                              actionSetPopupAssets({ popupContext: '' })
                            );
                          }
                        })
                      );
                    }}
                  >
                    Upload
                  </UploadButton>
                </div>
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <label className="font-weight-normal">Body Font</label>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={{ span: 12 }}>
                <div className="custom-font d-flex justify-content-between flex-sm-row flex-column">
                  <UploadButton
                    className={`btn-upload-font font-weight-bold ${
                      form?.values?.bodyFont || 'lighter'
                    }`}
                    wrapperClassName={`w-100 btn-upload-wrapper  ${
                      form?.values?.bodyFont || 'lighter'
                    }`}
                    fallback="Default - Montserrat"
                    accept=".ttf,.woff,.woff2"
                    file={form?.values?.bodyFontFile}
                    fileUrl={form?.values?.bodyFontUrl}
                    onDelete={() => {
                      logBrandThemClickEvent(
                        'BODY_FONT',
                        'DELETE',
                        form.values?.bodyFontUrl
                      );
                      setFieldValue('bodyFontUrl', '');
                      saveChanges('bodyFontUrl');

                      if (isResourceFromAssetLib(form?.values?.bodyFontUrl)) {
                        return Promise.resolve();
                      }
                      setLoading(true);
                      return removeBrandAssets(form?.values?.bodyFontUrl)
                        .catch(() => {})
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                    onUpload={(file) => {
                      setFieldValue('bodyFontFile', file);
                      setLoading(true);
                      return uploadBrandAsset({
                        file,
                        path: 'font/body',
                        brand: brandSetup?.id
                      })
                        .then(({ data }) => {
                          setFieldValue('bodyFontUrl', data.url);
                          saveChanges('bodyFontUrl');
                        })
                        .catch(() => {})
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                    onClickUpload={() => {
                      logBrandThemClickEvent('BODY_FONT', 'CHANGE');
                      dispatch(
                        actionSetPopupAssets({
                          popupContext: 'branding',
                          popupTitle: 'Asset Library',
                          acceptTypes: '.ttf,.woff,.woff2',
                          onSelectAsset(asset) {
                            if (
                              !['font/ttf', 'font/woff', 'font/woff2'].includes(
                                asset.assetType
                              )
                            ) {
                              dispatch(
                                actionHubAlertError('Invalid asset type.')
                              );
                              return;
                            }
                            setFieldsValue({
                              bodyFontUrl: asset.convertedURL,
                              bodyFontFile: undefined
                            });
                            saveChanges('bodyFontUrl');
                            dispatch(
                              actionSetPopupAssets({ popupContext: '' })
                            );
                          },
                          onAddFileToUpload: (file) => {
                            setFieldValue('bodyFontFile', file);
                            dispatch(
                              actionSetPopupAssets({ popupContext: '' })
                            );
                          }
                        })
                      );
                    }}
                  >
                    Upload
                  </UploadButton>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );

  const renderBrandThemePreviewer = () => {
    const Previewer = (
      <div
        style={{ overflowY: 'auto', maxWidth: 405 }}
        className="mt-xl-0 mt-3"
      >
        {screenSize !== 'xs' && <h5 className="font-weight-bold">Preview</h5>}
        <BrandThemePreviewer
          dark
          colors={{
            link: form?.values?.primaryColor,
            accent: form?.values?.secondaryColor
          }}
          logoUrl={form?.values?.lightLogo}
          logoFile={form?.values?.lightLogoFile}
          font={{
            headerWeight: form?.values?.headerFont,
            headerFontUrl: form?.values?.headerFontUrl,
            bodyWeight: form?.values?.bodyFont,
            bodyFontUrl: form?.values?.bodyFontUrl
          }}
          brandUrl={brandSetup?.brandUrl}
        />
        <BrandThemePreviewer
          colors={{
            link: form?.values?.primaryColor,
            accent: form?.values?.secondaryColor
          }}
          logoUrl={form?.values?.darkLogo}
          logoFile={form?.values?.darkLogoFile}
          font={{
            headerWeight: form?.values?.headerFont,
            headerFontUrl: form?.values?.headerFontUrl,
            bodyWeight: form?.values?.bodyFont,
            bodyFontUrl: form?.values?.bodyFontUrl
          }}
          brandUrl={brandSetup?.brandUrl}
        />
      </div>
    );
    return screenSize === 'xs' ? (
      showBrandingPreviewer ? (
        <PopupContainer onClose={() => setShowBrandingPreviewer(false)}>
          <h6 className="text-white backdrop-bg text-center text-base py-0">
            Preview
          </h6>

          {Previewer}
        </PopupContainer>
      ) : null
    ) : (
      Previewer
    );
  };

  return (
    <div className="brand-theme">
      <div className="mobile-navigator d-flex justify-content-center d-sm-none mb-4 px-2">
        <div
          className="nav-item logo py-2 px-4 mr-3"
          onClick={() => setActionSectionOnMobile('logo')}
        >
          <IconUploadImage
            color={activeMobileSection === 'logo' ? '#000000' : '#D8D8D8'}
          />
        </div>
        <div
          className="nav-item style py-2 px-4 ml-3"
          onClick={() => setActionSectionOnMobile('style')}
        >
          <IconPaint
            color={activeMobileSection === 'style' ? '#000000' : '#D8D8D8'}
          />
        </div>
      </div>
      <form className="brand-form d-flex">
        <Container fluid>
          <Row className="inputs">
            <Col
              className="brand-custom-style pb-4 px-4"
              xs={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 7 }}
            >
              {renderBrandStyleInput()}
            </Col>
            <Col
              className="brand-custom-style-preview pb-4 px-4"
              xs={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 5 }}
            >
              {renderBrandThemePreviewer()}
            </Col>
          </Row>
          <Row>
            {screenSize === 'xs' && (
              <Col md={{ span: 3 }} sm={{ span: 12 }}>
                <Button
                  variant="dark"
                  className="w-100 rounded-pill mt-3 mt-sm-2"
                  onClick={() => {
                    setShowBrandingPreviewer(true);
                    logClickButton(DID_CLICK_BRANDING_PREVIEW_THEME_BUTTON);
                  }}
                >
                  Preview Branding
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </form>
      <style jsx>{`
        .nav-item {
          cursor: pointer;
        }
        .brand-theme {
          max-width: 1200px;
        }
      `}</style>
      <style jsx global>{`
        .btn-upload-wrapper.lighter .filename {
          font-weight: lighter;
        }
        .btn-upload-wrapper.normal .filename {
          font-weight: normal;
        }
        .btn-upload-wrapper.bold .filename {
          font-weight: bold;
        }

        .modal-content {
          border-radius: none;
          background-color: unset;
        }
        .modal-header {
          border-radius: none !important;
        }
        .modal-header .modal-title {
          font-size: 1.1rem !important;
        }
        .modal-backdrop {
          background-color: #000000 !important;
          opacity: 0.8 !important;
        }
        input[type='color'] {
          border-radius: 5px !important;
          overflow: hidden;
          margin-right: 0.5rem;
        }
        input[type='color'] {
          -webkit-appearance: none;
          padding: 0;
        }
        input[type='color']::-webkit-color-swatch-wrapper {
          padding: 0;
        }
        .preview-image-wrapper {
          min-width: 200px;
        }
        :global(.media-upload-container .dropzone) {
          max-width: 305px !important;
        }
        .brand-image-preview .spinner-container {
          width: 305px !important;
        }
        .preview-image-wrapper .preview-icon {
          display: none;
        }
        .media-upload-container {
          max-width: 305px;
        }
        .media-upload-container .media-upload-main {
          height: 110px;
        }
        @media (max-width: 1380px) {
          .logo-inputs {
            flex-basis: 100% !important;
            max-width: unset;
          }
        }

        @media only screen and (max-width: 326.8px) {
          .custom-font .btn-upload-wrapper {
            flex-direction: column !important;
          }
          .custom-font .btn-upload-wrapper .filename {
            margin-top: 8px;
          }
        }
      `}</style>
    </div>
  );
};

export default BrandTheme;
