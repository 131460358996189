import React from 'react';
import { allBrands } from '../../../config';
import { HUB_USER_PERMISSION } from '../../../interfaces';
import { PageView } from '../../../utils/pageView';
import { HubContext } from '../HubContext';
import Layout from '../Layout';
import AdminPage from './AdminPage';
import Profile from './Profile';
import TeamPage from './TeamPage';
import UsersManagement from './UsersManagement';
function HubUser() {
  const {
    page,
    isAdmin,
    checkUserPermissions,
    chooseCheckPermissionResult,
    brandId
  } = React.useContext(HubContext);
  const isNotPreConfigured = allBrands.find(
    (brand) => brandId === brand.id
  )?.isNotPreConfigured;
  const getPage = () => {
    switch (page) {
      case PageView.HUB_ACCOUNTSETTINGS:
        return <Profile />;
      case PageView.HUB_USERMANAGEMENT: {
        const hasPermission = chooseCheckPermissionResult(
          isAdmin,
          checkUserPermissions(HUB_USER_PERMISSION.USER_MANAGEMENT)
        );
        return hasPermission ? (
          isNotPreConfigured ? (
            <UsersManagement />
          ) : (
            <AdminPage />
          )
        ) : (
          <Profile />
        );
      }
      case PageView.HUB_TEAMS:
        return <TeamPage />;
      default:
        return <Profile />;
    }
  };
  return (
    <Layout showNav={true} theme="dark-light" className="layoutSessions">
      {getPage()}
    </Layout>
  );
}

export default HubUser;
