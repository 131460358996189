import React from 'react';
import { getThemeColor } from '../BrandStyle';
import { IMainState } from '../../../interfaces';
import { ContentSortable } from './ContentSortable';
import { WizardButton } from '../Common/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionHubUpdateNudgeContent,
  actionHubUpdateNudgeRecipient,
  actionHubUpdateNudgeTitle,
  actionHubAlertInfo,
  actionHubUpdateNudgeDescription
} from '../../../redux/actions';
import { HubContext } from '../HubContext';
import { BsInfoCircleFill } from 'react-icons/bs';
import { NudgeCreationState } from './NudgeCurator';

interface Props {
  onBack: () => void;
  onNext: () => void;
  isTemplate?: boolean;
}

const NudgeItemReorder = ({ onBack, onNext, isTemplate }: Props) => {
  const { brandId } = React.useContext(HubContext);
  const dispatch = useDispatch();

  const nudgeState = useSelector(
    (state: IMainState) => state.clientState.hub?.nudge
  );

  const items = nudgeState?.content || [];
  const name = nudgeState?.name || '';
  const title = nudgeState?.title || '';
  const description = nudgeState?.description || '';

  const [error, setError] = React.useState(false);

  const color = getThemeColor(brandId);

  const handleNext = () => {
    if (
      (!isTemplate && name.trim() === '') ||
      (isTemplate && title.trim() === '')
    ) {
      setError(true);
    } else {
      onNext();
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setError(false);
    if (value) dispatch(actionHubUpdateNudgeRecipient(value));
  };

  const handleTitleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value;
    setError(isTemplate ? false : error);
    if (value) dispatch(actionHubUpdateNudgeTitle(value));
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    if (value) dispatch(actionHubUpdateNudgeDescription(value));
  };

  const recipientForm = (
    <div className="input-container recipient-form">
      <span
        className="info"
        onClick={() =>
          dispatch(
            actionHubAlertInfo(
              'This field is required to find your invitation in the Invite List of your Hub'
            )
          )
        }
      >
        <BsInfoCircleFill color="#ccc" />
      </span>
      <input
        className={`form-control ${error && 'input-error'}`}
        type="text"
        placeholder={error ? 'Recipient name required' : 'Enter recipient name'}
        defaultValue={name}
        onBlur={handleNameChange}
      />
    </div>
  );

  const templateForm = (
    <div className="input-container template-form">
      <input
        className={`form-control ${error && 'input-error'}`}
        type="text"
        placeholder={error ? 'Template name required' : 'Template name'}
        defaultValue={title}
        onBlur={handleTitleChange}
      />
      <textarea
        className="form-control"
        placeholder="Template description"
        defaultValue={description}
        onBlur={handleDescriptionChange}
      />
    </div>
  );

  return (
    <div className="NudgeItemReorder">
      <div className="content-container">
        <div className="sortable-container">
          <h1>
            {items.length === 1
              ? 'Your selected item'
              : 'Hold and drag to reorder'}
          </h1>
          <ContentSortable
            items={items}
            onUpdate={(items) => dispatch(actionHubUpdateNudgeContent(items))}
            darkMode={!color.nudgeLightMode}
          />
        </div>
      </div>

      {isTemplate ? templateForm : recipientForm}
      <WizardButton
        onNext={handleNext}
        onPrev={onBack}
        currentPage={NudgeCreationState.REORDER}
        nextPage={NudgeCreationState.PREVIEW}
        prevPage={NudgeCreationState.PICK_ITEMS}
      />

      <style jsx global>{`
        .input-error {
          background: #ffecec;
          border-color: red;
        }
        .input-error:focus {
          background: #fff;
        }
        .input-container {
          padding: 5px;
          background: #fff;
          position: fixed;
          bottom: 37px;
          width: 100%;
          left: 0;
        }

        .input-container input:first-child {
          border-radius: 3px 3px 0 0 !important;
        }
        .error,
        .error:focus,
        .error.active {
          border: 1px solid red;
          background: #ffdbdb;
        }

        textarea {
          border-radius: 3px 3px 0 0;
        }

        .ssp .content-container {
          max-width: 880px;
        }

        .info {
          right: 5px;
          display: block;
          width: 30px;
          height: 40px;
          line-height: 40px;
        }

        @media (max-width: 768px) {
          .info {
            position: fixed;
            bottom: 42px;
          }
        }

        @media (min-width: 768px) {
          .input-container {
            position: relative;
            width: 400px;
            margin: auto;
            background: transparent;
            padding: 10px 0;
            bottom: 0;
            margin-bottom: -30px;
          }
          .ssp .input-container {
            width: 800px;
          }
          .input-container .input {
            width: 100%;
          }
          .info {
            position: absolute;
            bottom: 11px;
          }
        }
      `}</style>

      <style jsx>{`
        h2 {
          font-size: 1em;
          letter-spacing: 1px;
          margin-bottom: 20px;
          font-weight: 300;
          text-transform: uppercase;
          color: ${color.accent};
        }

        .content-container {
          padding: 0 40px 100px;
          max-width: 600px;
          margin: auto;
          width: 100%;

          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        :global(.ssp) .content-container {
          max-width: 880px;
        }

        .NudgeItemReorder {
          background: ${color.nudgeLightMode ? color.light : color.dark};
          text-align: center;
          padding-bottom: 50px;
          align-items: center;
        }

        .btn-outline-secondary {
          background: #000;
          color: #fff;
        }

        span {
          text-decoration: underline;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          .page-body {
            padding: 74px 0 10px !important;
          }
        }

        @media (min-width: 768px) {
          h2 {
            font-size: 1.5em;
            margin-top: 20px;
          }
          .sortable-container {
            padding-bottom: 50px;
          }
          .content-container {
            padding-bottom: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default NudgeItemReorder;
