import { truncate } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  getStoriesByIds,
  performStorySearch
} from '../../../clientSideServices/inspirations';
import { getInspirationIcon } from '../../../components/Inspiration/common';
import {
  IInspiration,
  IMainState,
  INudgeItem,
  NudgeItemType
} from '../../../interfaces';
import { isInspiration } from '../../utils/nudge';
import { HubContext } from '../HubContext';

interface Props {
  onSelect?: (item: INudgeItem) => void;
  onSendToChat?: (item: INudgeItem) => void;
  keywords: string;
  brandId: string;
  selectedItems: INudgeItem[];
  onOpenPage: (path: string) => void;
  onSearchStart: () => void;
  onSearchLoaded: () => void;
  language: string;
  storyContext: string[];
  setShowStorySearch?: (c: boolean) => void;
  isContactPage?: boolean;
  embeddedComponent?: (item: INudgeItem) => React.ReactNode;
}
const batchSize = 24;

const StorySearch = ({
  onSelect,
  onSendToChat,
  keywords,
  brandId,
  selectedItems,
  onOpenPage,
  onSearchStart,
  onSearchLoaded,
  language,
  storyContext,
  setShowStorySearch,
  isContactPage,
  embeddedComponent
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const { storeId } = useContext(HubContext);
  const showingOnlyFavorites = useSelector(
    (state: IMainState) => state.clientState.hub?.showingOnlyFavorites
  );
  const favoriteStoreIds = useSelector((state: IMainState) =>
    state.clientState.hub?.favorites
      ?.filter((f) => f.itemType?.toLowerCase() === NudgeItemType.STORY)
      ?.map((f) => f.itemId) || []
  );

  const isSelected = (id: string) => {
    return !!selectedItems.find((s) => s.id === id);
  };

  const selectedOrderNumber = useMemo(() => {
    const items = [];
    selectedItems.map((s, i) => (items[s.id] = i + 1));
    return items;
  }, [selectedItems]);

  const handleSelect = (item: INudgeItem) => {
    if (onSelect) onSelect(item);
  };

  const sendToChat = (item: INudgeItem) => {
    if (onSendToChat) {
      onSendToChat(item);
      setShowStorySearch(false);
    }
  };

  const search = async (keepResult = false) => {
    if (keepResult) {
      setIsLoading(true);
    } else {
      onSearchStart();
      setIsEmpty(false);
    }
    try {
      const filters = {
        context: storyContext
      };
      const stories = await performStorySearch(
        keywords,
        brandId,
        language,
        filters,
        batchSize,
        keepResult ? startIndex : 0,
        storeId
      );
      if (keepResult) {
        setSearchResult([...searchResult, ...stories]);
      } else {
        setSearchResult(stories);
      }
      if (!stories.length) {
        setIsEmpty(true);
      }
      setHasNextPage(stories.length === batchSize);
      setStartIndex(keepResult ? startIndex + stories.length : stories.length);
    } catch (e) {
      console.log(e);
    }
    onSearchLoaded();
    setIsLoading(false);
  };

  useEffect(() => {
    if (showingOnlyFavorites) {
      setIsLoading(true);
      getStoriesByIds(favoriteStoreIds)
        .then((result) => {
          setSearchResult(result);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  }, [showingOnlyFavorites]);

  useEffect(() => {
    if (!showingOnlyFavorites) {
      search();
      return () => {
        setSearchResult([]);
      };
    }
  }, [keywords, language, storyContext, showingOnlyFavorites]);

  const itemComponent = (item: IInspiration) => (
    <div
      key={item.id}
      className={`item col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 ${
        sendToChat && 'no-cursor'
      }`}
    >
      {embeddedComponent?.(item)}
      <div
        className="content"
        onClick={() => handleSelect(item)}
        style={{
          backgroundImage: `url(${item.flipImageUrl})`
        }}
      />
      {isContactPage && (
        <span onClick={() => sendToChat(item)} className="send-chat-btn">
          Send to chat
        </span>
      )}

      <span
        onClick={() => onOpenPage(`/inspirations/${item.id}`)}
        className="desc"
      >
        {truncate(item.title, { length: 40 })}
      </span>
      <span
        className={`with-shadow selectIcon ${isSelected(item.id) && 'active'}`}
        onClick={() => handleSelect(item)}
      >
        {isSelected(item.id) && selectedOrderNumber[item.id]}
      </span>
      {getInspirationIcon(item)}
      <style jsx>{`
        .item {
          position: relative;
          margin-bottom: 30px;
        }

        .content {
          background-size: cover;
          padding-top: 140%;
          border-radius: 10px;
        }
        .selectIcon {
          position: absolute;
          top: 5px;
          right: 20px;
          font-size: 25px;
          min-width: 25px;
          height: 25px;
          line-height: 23px;
          font-size: 12px;
          font-family: Arial;
          border-radius: 100%;
          border: 2px solid #fff;
        }

        .item :global(svg.video-icon) {
          position: absolute;
          top: 50%;
          left: -50%;
          -webkit-filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.7));
          opacity: 0.7;
          font-size: 4em;
          transform: translate(-50%, -50%);
        }

        .selectIcon.active {
          background: #fff;
          color: #000;
        }
        img {
          width: 100%;
        }

        .desc {
          position: absolute;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          left: 15px;
          right: 15px;
          font-size: 0.7em;
          text-transform: uppercase;
          padding: 10px;
          color: #fff;
          display: block;
          text-align: left;
          cursor: pointer;
          border-radius: 0 0 10px 10px;
        }

        .send-chat-btn {
          position: absolute;
          top: 0;
          left: 15px;
          right: 15px;
          border-radius: 10px 10px 0 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
          background: #fff;
          padding: 10px;
          font-size: 0.7em;
          color: #666;
          text-transform: uppercase;
          text-align: center;
          cursor: pointer;
        }
        @media (max-width: 480px) {
          .send-chat-btn {
            font-size: 0.5em;
          }
        }
      `}</style>
    </div>
  );

  const buttonMore = (
    <div style={{ width: '100%', padding: '10px 0 50px', textAlign: 'center' }}>
      <button className="load-more btn-dark" onClick={() => search(true)}>
        Load More
      </button>
    </div>
  );

  const loadingMore = (
    <div style={{ width: '100%', padding: '10px 0 50px', textAlign: 'center' }}>
      <Spinner animation="border" />
    </div>
  );

  return (
    <div className="StorySearch">
      <div className="items">
        <div className="container">
          <div className="row">
            {searchResult.length !== 0 &&
              searchResult.map(
                (item) => isInspiration(item) && itemComponent(item)
              )}
            {isEmpty && (
              <div className="search-empty">
                <span>Story not found.</span>
              </div>
            )}
            {searchResult.length !== 0 &&
              hasNextPage &&
              !isLoading &&
              !showingOnlyFavorites &&
              buttonMore}
            {isLoading && loadingMore}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorySearch;
