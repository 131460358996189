import { format } from 'date-fns';
import React from 'react';
import { FiEye } from 'react-icons/fi';
import { staticBaseUrl } from '../../../../config';
import { IBrowsingHistory } from '../../../../interfaces';
import ProductViewActivity from './ProductViewActivity';

const InsightItem = ({ history }: { history: IBrowsingHistory }) => {
  const source = 'iwc.com';
  return (
    <div className="insight-item">
      <div className="insight-connector">
        <div className="activity-logo">
          <FiEye color="white" size="1.2em" />
        </div>
        <div className="line" />
      </div>
      <div>
        <div className="item-source">
          <img
            src={`${staticBaseUrl}/connect/assets/location.svg`}
            alt="Source icon"
          />
          <span>{source}</span>
        </div>
        <ProductViewActivity history={history} />
        <div className="item-timestamp">
          <img
            src={`${staticBaseUrl}/connect/assets/calendar.svg`}
            alt="calendar icon"
          />
          <span className="item-timestamp__date">
            {format(new Date(history?.event_datetime), 'LLLL d, uuuu')}
          </span>
          <img
            src={`${staticBaseUrl}/connect/assets/time.svg`}
            alt="time icon"
          />
          <span className="item-timestamp__time">
            {format(new Date(history?.event_datetime), 'hh:mm aaa')}
          </span>
        </div>
      </div>
      <style jsx>
        {`
          .insight-item {
            padding-left: 60px;
            position: relative;
            margin: 28px auto;
            z-index: 1;
          }
          .insight-connector {
            position: absolute;
            left: 0;
            width: 60px;
            height: 1px;
            background: #c4c4c4;
            top: 50%;
            display: flex;
            align-items: center;
          }
          .item-timestamp {
            font-size: 8px;
            line-height: 10px;
            color: #828282;
            margin-top: 5px;
            display: flex;
            align-items: center;
          }
          .item-source {
            color: #828282;
            margin-bottom: 5px;
            font-size: 8px;
            line-height: 10px;
            display: flex;
            align-items: center;
          }
          .item-source img,
          .item-timestamp img {
            width: 8px;
            height: auto;
          }
          .item-source span,
          .item-timestamp span {
            margin: 0 5px;
          }

          :global(.chat-activity) {
            background: #f9f9f9;
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            width: 100%;
            padding: 12px 10px;
            color: #c4c4c4;
            text-align: center;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
          }

          .activity-logo {
            width: 35px;
            height: 35px;
            background: #000;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            .insight-item {
              padding-left: 50px;
            }
            .insight-connector {
              width: 50px;
            }
          }

          @media (max-width: 480px) {
            .insight-item {
              padding-left: 50px;
            }
            .insight-connector {
              width: 50px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default InsightItem;
