import React from 'react';
import SearchInput from '../Common/SearchInput';
import {
  getDeviceConfiguration,
  getDevicesFromStoreId
} from '../../clientSideServices/device';
import { IDevice, IDeviceConfiguration, IMainState } from '../../../interfaces';
import { useSelector } from 'react-redux';
import { BsThreeDots } from 'react-icons/bs';
import { Spinner, Table } from 'react-bootstrap';
import ToolTipWrapper from '../../../components/Common/ToolTipWrapper';
import { getUserIdFromDevice } from './DeviceInsight/service';

function DeviceList({
  onEdit,
  onInsight,
  onPreview,
  onInsightsSpace
}: {
  onEdit: (device: IDeviceConfiguration) => void;
  onInsight: (device: IDevice, userId?: string) => void;
  onPreview: (device: IDevice) => void;
  onInsightsSpace: () => void;
}) {
  const { storeId, brandName } =
    useSelector((state: IMainState) => state.clientState?.hub?.user) || {};
  const [searchValue, setSearchValue] = React.useState('');
  const [devices, setDevices] = React.useState<IDevice[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedDevice, setSelectedDevice] = React.useState<IDevice | null>(
    null
  );
  const [loadConfiguration, setLoadConfiguration] = React.useState(false);
  const [loadUserId, setLoadUserId] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    getDevicesFromStoreId(storeId)
      .then((res) => {
        const lenseDevice = res.filter(
          (device) => device.deviceType === 'Lense'
        );
        setDevices(lenseDevice);
      })
      .catch((err) => {
        console.error(err);
        setDevices([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [storeId]);

  const noDevice = !loading && devices.length === 0;
  const filteredDevices = React.useMemo(
    () =>
      devices.filter((device) => {
        return device.name.toLowerCase().includes(searchValue.toLowerCase());
      }),
    [devices, searchValue]
  );

  const handleOnEdit = () => {
    setLoadConfiguration(true);
    getDeviceConfiguration(selectedDevice).then((config) => {
      onEdit(config);
      setLoadConfiguration(false);
    });
  };

  const handleOnInsight = () => {
    setLoadUserId(true);
    getUserIdFromDevice(selectedDevice?.deviceId).then((res) => {
      onInsight(selectedDevice, res[0]?.id);
      setLoadUserId(false);
    });
  };

  return (
    <div className="DeviceOverview">
      <div className="header">
        <div className="title">
          <h5>Devices</h5>
        </div>
        <div className="action">
          <SearchInput
            onSearch={setSearchValue}
            searchOnTyping={true}
            placeHolder="Search"
            className="search-devices"
          />
          <button className="btn btn-round btn-dark" onClick={onInsightsSpace}>
            Insight
          </button>
        </div>
      </div>
      <hr className="w-100" />
      <div className="content">
        <h6>{brandName}</h6>
        <div className="main-content" data-center-content={loading || noDevice}>
          {loading && <Spinner animation="border" />}
          {noDevice && <span>No Devices Registered</span>}
          {!loading && devices.length > 0 && (
            <Table striped borderless hover>
              <thead>
                <tr>
                  <th>Device Name</th>
                  <th>Device ID</th>
                  <th className="text-center">Number of products</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredDevices.map((device) => (
                  <tr key={device.deviceId}>
                    <td>{device.name}</td>
                    <td>{device.deviceId}</td>
                    <td className="text-center">{device.productCount || 0}</td>
                    <td>
                      <button
                        id={`device-${device.deviceId}`}
                        onClick={() => setSelectedDevice(device)}
                        className="btn-options btn btn-round btn-dark"
                      >
                        <BsThreeDots size={16} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      {selectedDevice?.deviceId && (
        <ToolTipWrapper
          onClose={() => setSelectedDevice(null)}
          parentId={`device-${selectedDevice.deviceId}`}
          width={200}
          distanceFromParent={40}
          xPosition="left"
          className="device-menu"
        >
          <div className="control-menu text-left">
            <button
              className="list-group-item list-group-item-action d-none"
              onClick={() => onPreview(selectedDevice)}
            >
              Preview
            </button>
            <button
              className="list-group-item list-group-item-action"
              onClick={handleOnEdit}
            >
              {loadConfiguration ? (
                <Spinner animation="border" size="sm" />
              ) : (
                'Manage Content'
              )}
            </button>
            <button
              className="list-group-item list-group-item-action"
              onClick={handleOnInsight}
            >
              {loadUserId ? (
                <Spinner animation="border" size="sm" />
              ) : (
                'Insight'
              )}
            </button>
          </div>
        </ToolTipWrapper>
      )}
      <style jsx>{`
        .DeviceOverview {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        h5,
        h6 {
          font-weight: bold;
        }
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .main-content[data-center-content='true'] {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .main-content {
          flex: 1;
        }
        .content {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .DeviceOverview .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 25px;
        }
        .DeviceOverview .content {
          padding: 0 25px;
        }
        .header .action {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .header .action .btn {
          min-width: 130px;
          font-size: 14px;
        }
        .DeviceOverview .header ::global(.search-devices) {
          height: 30px;
        }
        .DeviceOverview .content ::global(.btn-options) {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          padding: 0;
        }
        .DeviceOverview .content tr td {
          background: transparent !important;
          font-size: 14px;
        }
        .DeviceOverview .content tr td:first-child {
          font-weight: bold;
        }
        .DeviceOverview .content tr td:last-child {
          width: 40%;
        }
        .content tr th {
          font-weight: 400;
        }
      `}</style>
    </div>
  );
}

export default DeviceList;
