import { size } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { getUserByIdentityId } from '../../../clientSideServices/users';
import {
  ChatConnectionContext,
  ChatMessageType,
  IChatMessage,
  IChatMessageBody
} from '../../../components/ChatConnectionContext';
import { IMainState } from '../../../interfaces';
import { decryptMessage, getMessageDisplayText } from '../../../utils/chat';
import { DID_CLICK_ACCEPT_NEW_CONVERSATION } from '../../../utils/constants';
import { enc } from '../../../utils/crypt';
import { generateV4UUID } from '../../../utils/identityGenerator';
import ChatContactTimestamp from './ChatContactTimestamp';
import { HubChatContext } from './HubChatContext';

const IncomingChatContact = ({
  contact,
  index,
  keywords
}: {
  contact: any;
  index?: number;
  keywords: string;
}) => {
  const hubUser = useSelector(
    (state: IMainState) => state.clientState?.hub?.user
  );

  const { addIncomingChatUsers, incomingChatUsers, onSendHubMessage } =
    React.useContext(HubChatContext);

  const userName =
    incomingChatUsers?.[contact?.senderId]?.alias || `New customer`;

  const { subscribeToConversation } = React.useContext(ChatConnectionContext);

  const messageBody = decryptMessage(contact?.messageBody);
  const messageText = getMessageDisplayText(messageBody);

  const sendAcceptChatSilentMessage = (conversationId: string) => {
    const encryptionId = generateV4UUID();
    const message: IChatMessageBody = {
      type: ChatMessageType.JOINED_CHAT,
      content: hubUser?.name,
      timestamp: new Date()
    };
    const encrypted = {
      id: encryptionId,
      content: enc(JSON.stringify(message), encryptionId)
    };

    const payload: IChatMessage = {
      requestId: generateV4UUID(),
      userId: hubUser?.id,
      conversationId,
      messageBody: JSON.stringify(encrypted)
    };
    onSendHubMessage(payload);
  };

  const onAccept = () => {
    logEvent(
      DID_CLICK_ACCEPT_NEW_CONVERSATION,
      DID_CLICK_ACCEPT_NEW_CONVERSATION,
      {
        advisorId: hubUser?.id,
        conversationID: contact?.conversationId
      }
    );
    subscribeToConversation(hubUser?.id, contact?.conversationId);
    // FIXME: Need callback to be sure conversation is now subscribed
    setTimeout(() => {
      sendAcceptChatSilentMessage(contact?.conversationId);
    }, 1000);
  };

  const getUserDetails = () => {
    if (!incomingChatUsers?.[contact?.senderId]) {
      getUserByIdentityId(contact?.senderId)
        .then(({ data }) => {
          addIncomingChatUsers(contact?.senderId, {
            ...data
          });
        })
        .catch((error) => {
          const customerNumber = incomingChatUsers
            ? size(incomingChatUsers)
            : index;
          addIncomingChatUsers(contact?.senderId, {
            alias: `New customer ${customerNumber + 1}`
          });
          console.log('Error is', error);
        });
    }
  };

  React.useEffect(() => {
    if (contact?.senderId) {
      getUserDetails();
    }
  }, [contact?.senderId]);

  const timestamp = messageBody?.timestamp || contact?.createdAt || null;

  if (
    keywords &&
    !userName?.toLowerCase().includes(keywords.toLocaleLowerCase())
  ) {
    return null;
  }

  return (
    <div className="chat-item disabled">
      <span className="customer-name">{userName}</span>
      <div className="footer-wrapper">
        <div className="message">{messageText}</div>
        <div className="chat-timestamp">
          <ChatContactTimestamp timestamp={timestamp} />
        </div>
      </div>
      <div className="chat-actions">
        <button
          onClick={() => onAccept()}
          className="btn accept btn-sm chat-action-btn"
        >
          ACCEPT
        </button>
      </div>
      <style jsx>{`
        .chat-item {
          padding: 20px 20px 10px 20px;
          position: relative;
          border-bottom: 1px solid #c4c4c4;
          background: #fff;
          cursor: pointer;
        }
        .chat-item.disabled {
          cursor: default;
        }
        .customer-name {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;
        }
        .footer-wrapper {
          margin-top: 11px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .message {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 75%;
          color: #666363;
          font-size: 12px;
        }
        .chat-timestamp {
          color: #666363;
          font-size: 12px;
        }

        .message.no-bubble {
          max-width: 100%;
        }
        .new-message {
          position: absolute;
          right: 10px;
          bottom: 10px;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          margin-left: 10px;
          background: #828282;
          color: white;
          align-items: center;
          display: flex;
          justify-content: center;
        }

        .chat-actions {
          position: absolute;
          right: 10px;
          top: 20px;
        }

        .chat-action-btn {
          font-size: 12px;
          line-height: 15px;
          border-radius: 10px;
        }

        .chat-action-btn:focus {
          box-shadow: none;
        }

        .chat-action-btn.accept {
          background: #72cb64;
          color: #fff;
        }
        .customer-status-indicator {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: #828282;
        }

        .customer-status-indicator.online {
          background: #008700;
        }
      `}</style>
    </div>
  );
};

export default IncomingChatContact;
