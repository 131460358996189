import React, { useContext, useEffect, useMemo, useState } from 'react';

import { components, SingleValueProps } from 'react-select';
import { IFolderKey } from '../../../interfaces';
import {
  getLabelOfFolderKey,
  getLevelOfFolderKey,
  sortFolderKeys,
  TRASH_FOLDER,
  UNCATEGORIZED_FOLDER
} from '../../utils/folders';
import { HubContext } from '../HubContext';
import { useAssetLibraryFolder } from '../AssetLibrary/hook';
import { InsSelect } from '../Common/Select';

const genderValue = (value:string[]) => {
  if (value.length === 0) return ;
  else if (value.length === 1) return <span style={{ fontWeight: 'bold' }}>{value[0]}</span>
  else if (value.length === 2) return <>
  <span style={{ fontWeight: 'bold' }}>{value[0]}</span>
  <span> \ </span>
  <span style={{ fontWeight: 'bold' }}>{value[1]}</span>
  </>
  else return <>
  <span style={{ fontWeight: 'bold' }}>{value[0]}</span>
  <span> \... \ </span>
  <span style={{ fontWeight: 'bold' }}>{value[value.length - 1]}</span>
  </>
}
const SingleValue = (props: SingleValueProps<IFolderKey, false>) => {
  const key = props.data.key;  
  const value = key.replace(/^\/|\/$/, '').split('/').filter(v => v !=='');
  const menuIsOpen = props.selectProps.menuIsOpen;
  return (
    <components.SingleValue {...props}>
      {menuIsOpen ? (
        <div className='placeholder-text'>Search Category</div>
      ) : (
        <>
          <div className="flex text-truncate">
              {props.hasValue ? genderValue(value) : props.children}
          </div>
        </>
      )}
    </components.SingleValue>
  );
};

const LevelOption = (props) => {
  const level = getLevelOfFolderKey(props.data.key);
  return (
    <components.Option {...props}>
      <div>
        <span
          className={`${level === 1 ? 'font-weight-bold' : ''}`}
          style={{ paddingLeft: `${8 * (level - 1)}px` }}
        >
          {props.label}
        </span>
      </div>
    </components.Option>
  );
};

export const AllCategoryOption = { id: 'ALL', key: '/ALL' };

export const AssetCategoriesDropdown: React.FC<{
  onChange?: (category: IFolderKey) => void;
  value?: string;
}> = ({ onChange, value }) => {
  const { brandId, user } = useContext(HubContext);
  const { folder: assetLibraryCategoriesFolder } = useAssetLibraryFolder(
    brandId,
    user?.id,
    true
  );
  const [selectedCategory, setSelectedCategory] =
    useState<IFolderKey>(AllCategoryOption);
  useEffect(() => {
    if (!value) {
      return;
    }
    setSelectedCategory((prev) => {
      if (prev?.id !== value) {
        return assetLibraryCategoriesFolder?.keys?.find((a) => a.id === value);
      }
      return prev;
    });
  }, [value]);

  const categoryOptions = useMemo(() => {
    return [AllCategoryOption].concat(
      sortFolderKeys(
        assetLibraryCategoriesFolder?.keys?.filter(
          (fKey) => ![TRASH_FOLDER, UNCATEGORIZED_FOLDER].includes(fKey.key)
        )
      )
    );
  }, [assetLibraryCategoriesFolder]);

  return (
    <div className="py-3 mb-3" style={{ borderBottom: '1px solid #D8D8D8' }}>
      <InsSelect<IFolderKey>
        id="sortDropdown"
        name="sortDropdown"
        isMulti={false}
        isClearable={false}
        options={categoryOptions}
        value={selectedCategory}
        onChange={(val) => {
          setSelectedCategory(val);
          onChange?.(val);
        }}
        getOptionLabel={(op) => getLabelOfFolderKey(op.key)}
        getOptionValue={(op) => op.id}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
            float: 'right'
          }),
          control: (provided, state) => ({
            ...provided,
            border: `solid 1px ${
              state.isFocused && state.menuIsOpen ? '#000000' : '#A8A8A8'
            }`,
            borderBottom: state.isFocused && state.menuIsOpen ? 'none' : '',
            borderRadius: 14,
            borderBottomLeftRadius: state.isFocused && state.menuIsOpen ? 0 : 14,
            borderBottomRightRadius: state.isFocused && state.menuIsOpen ? 0 : 14,
            height: 30,
            fontSize: 12,
            minHeight: 30,
            transition: 'none',
            boxShadow: 'none',
            ':hover': {
              borderColor: '#000000'
            }
          }),
          menu: (provided) => {
            return {
              ...provided,
              marginTop: 0,
              zIndex: 10,
              border: 'solid 1px #000000',
              borderTop: 'none',
              borderRadius: 0,
              borderBottomLeftRadius: 14,
              borderBottomRightRadius: 14,
              boxShadow: 'none'
            };
          },
          indicatorSeparator: () => {
            return {
              display: 'none'
            };
          },
          indicatorsContainer: (base) => ({
            ...base,
            marginTop: -4,
            marginRight: 4
          }),
          valueContainer: (styles) => {
            return {
              ...styles,
              maxHeight: 100,
              overflowY: 'auto',
              padding: '2px 5px 2px 10px',
              fontWeight: 400,
              marginTop: '-4px'
            };
          },
          option: (provided, state) => {
            delete provided.backgroundColor;
            return {
              ...provided,
              color: '#000000',
              fontSize: 12,
              fontWeight: state.isSelected ? 'bold' : 'normal',
              padding: '7px 15px;',
              ':active': {
                backgroundColor: '#8a8888',
                color: '#ffffff'
              }
            };
          }
        }}
        components={{
          SingleValue: SingleValue,
          Option: LevelOption
        }}
      />
       <style jsx>{`
         :global(.placeholder-text) {
          color: #ccc;
        }
      `}</style>
    </div>
  );
};
