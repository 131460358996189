import React, { useContext, useState } from 'react';
import { getCurrentUserInfo } from '../../../../clientSideServices/users';
import { PageView } from '../../../../utils/pageView';
import { HubContext } from '../../HubContext';
import ALDesignerTour from './ALDesignerTour';

const AssetLibraryTour = () => {
  const [showDesignerTour, setShowDesignerTour] = useState<boolean>();
  const { setGuidedTour } = useContext(HubContext) || {};

  React.useEffect(() => {
    getCurrentUserInfo().then((userInfo) => {
      if (userInfo?.attributes?.['custom:gt_asset_library'] !== 'skipped') {
        setShowDesignerTour(true);
        setGuidedTour(PageView.HUB_ASSET_LIBRARY);
      }
    });
  }, []);

  return showDesignerTour ? (
    <ALDesignerTour onClose={() => setGuidedTour('')} />
  ) : null;
};

export default AssetLibraryTour;
