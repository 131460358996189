import { orderBy, some } from 'lodash';
import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import { logEvent } from '../../../analytics';
import { updateUserRole } from '../../../clientSideServices/users';
import { InvitationStatus, IUser, RoleEntitlement } from '../../../interfaces';
import { dateFormat } from '../../../utils/clock';
import { DID_CLICK_CREATE_USER } from '../../../utils/constants';
import { isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { isImage } from '../../../utils/file';
import { getObjectFromS3 } from '../../clientSideServices/aws';
import { defaultAvatarUrl } from '../../config';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import UserLabel from '../Common/UserLabel';
import { HubContext } from '../HubContext';
import { MessageDisplay } from './HubTeams';
import { HubUserContext } from './HubUserContext';
import InviteUsersForm from './InviteUsersForm';
import UsersRoleHelp, { IRoleUserPermission } from './UsersRoleHelp';
type SortingType = 'name' | 'date' | 'role';
const UsersManagement = () => {
  const isMobile = isUserOnMobileOnly();
  const { fetchUsersByAdmin, users, setIsAdminPage, deleteUser } =
    React.useContext(HubUserContext);
  const { brandId, user, isStudioOwnerUser } = React.useContext(HubContext);
  const [rolePermissions, setRolePermissions] =
    React.useState<IRoleUserPermission[]>();
  const [filterUser, setFilterUser] = React.useState<IUser[]>(undefined);
  const [state, setState] = React.useState({
    type: 'name' as SortingType,
    by: 'asc' as 'asc' | 'desc',
    invite: false,
    keyword: '',
    deleteUser: undefined as IUser,
    loading: undefined,
    roleHelpPopup: undefined,
    failUpdateRole: undefined
  });
  React.useEffect(() => {
    fetchUsersByAdmin();
    setIsAdminPage(true);
    const fileName = isStudioOwnerUser() ? 'studio' : 'storiez';
    getObjectFromS3('storiez-config', `hub/rolePermissions/${fileName}.json`)
      .then((data) => {
        setRolePermissions(JSON.parse(data.Body.toString()));
      })
      .catch((e) => console.error(e));
  }, []);
  React.useEffect(() => {
    if (!users) return;
    const forPendingUser = [...users]?.map((usr) => {
      return {
        ...usr,
        user_name: usr.email,
        createdAt:
          usr.statusText !== InvitationStatus.INVITATION_PENDING
            ? usr.createdAt
            : 'zzzz'
      };
    });
    setFilterUser(forPendingUser);
    userSorting(forPendingUser, state.type, state.by);
  }, [users]);
  const onFailUpdateRole = (role, userId: string) => {
    const index = filterUser.findIndex((item) => item.id == userId);
    const _user = [...filterUser];
    _user[index].role = role;
    setFilterUser(_user);
    setState({ ...state, failUpdateRole: true });
  };
  const isUserOwner = (userId: string): boolean => {
    return some(
      users,
      (usr) => userId === usr.id && usr.role.includes('OWNER')
    );
  };
  const onChangesRole = (newRole, userId: string) => {
    const index = filterUser.findIndex((item) => item.id == userId);
    const _filterUser = [...filterUser];
    const _role = _filterUser[index].role;
    _filterUser[index].role = `${_role.split('_')?.[0]}_${newRole}`;
    setFilterUser(_filterUser);
    updateUserRole({ userId: userId, brandId: brandId, role: newRole })
      .then((res) => {
        if (res.data?.status === 'FAIL') {
          onFailUpdateRole(_role, userId);
        }
      })
      .catch(() => {
        onFailUpdateRole(_role, userId);
      });
  };
  const userSorting = (
    filterUsers: IUser[],
    type: SortingType,
    sortingBy?: 'asc' | 'desc'
  ) => {
    const _state = {
      ...state,
      type: type,
      by:
        sortingBy ||
        ((type === state.type
          ? state.by === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc') as 'asc' | 'desc')
    };
    setState(_state);
    if (!filterUsers) return;
    let _users = [...filterUsers];
    if (filterUsers?.length > 1) {
      switch (_state.type) {
        case 'date':
          _users = orderBy(
            filterUsers,
            [(user) => user.createdAt?.trim()],
            [_state.by]
          );
          break;
        case 'role':
          _users = orderBy(
            filterUsers,
            [(user) => user.role?.toLowerCase().trim()],
            [_state.by]
          );
          break;
        case 'name':
        default:
          _users = orderBy(
            filterUsers,
            [
              (user) => user.first_name?.toLowerCase().trim(),
              (user) => user.last_name?.toLowerCase().trim()
            ],
            [_state.by, _state.by]
          );
          break;
      }

      setFilterUser(_users);
    }
  };

  const getUrl = (memberId) => {
    const url = filterUser?.find((user) => user.id === memberId);
    return isImage(url?.avatar_picture) ? url.avatar_picture : defaultAvatarUrl;
  };
  const onError = (ev) => {
    if (ev?.target?.src) ev.target.src = defaultAvatarUrl;
  };
  const usersDetail = (user: IUser) => (
    <div className="user-detail" key={user.id}>
      <hr className="solid" />
      <div className="user-row">
        <div className="user-title">
          <div className="user-image">
            <img
              className="member-img rounded-circle"
              src={getUrl(user.id)}
              onError={onError}
            />
          </div>
          {user.user_name ? (
            <div>
              <h6>
                <UserLabel
                  id={user.id}
                  emptyName={`${user?.first_name || ''} ${user?.last_name || ''}`}
                />
              </h6>
              <label className="email-text">{user.email}</label>
            </div>
          ) : (
            <div>
              <label style={{ marginTop: '15px' }} className="email-text">
                {user.email}
              </label>
            </div>
          )}
        </div>
        <div className="join-date">
          <label>
            {user.statusText === InvitationStatus.INVITATION_PENDING
              ? 'Pending'
              : dateFormat(user.createdAt, 'MMM dd, yyyy')}
          </label>
        </div>
        <div className="user-role">
          <Form.Control
            as="select"
            name="headerFont"
            className="role-select float-right"
            value={user?.role.split('_')?.[1]?.toLocaleLowerCase()}
            disabled={isUserOwner(user.id)}
            onChange={(e) => {
              onChangesRole(e.target?.value, user.id);
            }}
          >
            <option value={RoleEntitlement.Admin?.toLocaleLowerCase()}>
              Admin
            </option>
            <option value={RoleEntitlement.Designer?.toLocaleLowerCase()}>
              Designer
            </option>
            <option value={RoleEntitlement.Marketing?.toLocaleLowerCase()}>
              Marketing
            </option>
            {isUserOwner(user.id) && (
              <option value={RoleEntitlement.Owner?.toLocaleLowerCase()}>
                Owner
              </option>
            )}
            <option value={RoleEntitlement.User?.toLocaleLowerCase()}>
              User
            </option>
          </Form.Control>
        </div>
        <div className="user-role">
          <button
            type="button"
            name="submit"
            className="btn border border-dark btn-round float-right"
            disabled={isUserOwner(user.id)}
            onClick={() => {
              setState({
                ...state,
                deleteUser: user
              });
            }}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
  if (isMobile) {
    return <MessageDisplay msg="Please use the desktop for user management." />;
  }
  return (
    <>
      <div className="teams-page h-100">
        <div className="container h-100">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 team-title">
            <h1 className="mb-4">User Management</h1>
          </div>
          <div className="search-bar pt-2 pb-2 row">
            <div className="search-user col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 d-flex align-items-center">
              <label htmlFor="search-user" className="mr-2 mb-0">
                <MdSearch size={24} color={'#A8A8A8'} />
              </label>
              <input
                value={state.keyword}
                id="search-user"
                className="form-control"
                placeholder="Search user"
                onChange={(e) =>
                  setState({ ...state, keyword: e.target.value })
                }
              />
            </div>
            <div className="d-flex justify-content-end col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <button
                className="btn btn-dark btn-round"
                onClick={() => {
                  setState({ ...state, invite: true });
                  logEvent(DID_CLICK_CREATE_USER);
                }}
              >
                Invite
              </button>
            </div>
          </div>
          <div className="user-row">
            <div className="user-title">
              <h6
                className="row-name-title"
                onClick={() => userSorting(filterUser, 'name')}
              >
                Name
                {state.type === 'name' &&
                  (state.by === 'asc' ? <FaCaretDown /> : <FaCaretUp />)}
              </h6>
            </div>
            <div className="join-date">
              <h6
                style={{ cursor: 'pointer' }}
                onClick={() => userSorting(filterUser, 'date')}
              >
                Joining Date
                {state.type === 'date' &&
                  (state.by === 'asc' ? <FaCaretDown /> : <FaCaretUp />)}
              </h6>
            </div>
            <div className="user-role">
              <h6 className="float-right title-role">
                <span onClick={() => userSorting(filterUser, 'role')}>
                  Role{' '}
                  {state.type === 'role' &&
                    (state.by === 'asc' ? <FaCaretDown /> : <FaCaretUp />)}
                </span>
                <BsQuestionCircleFill
                  onClick={() => setState({ ...state, roleHelpPopup: true })}
                />
              </h6>
            </div>
          </div>
          {!users && (
            <div style={{ textAlign: 'center' }}>
              <Spinner className="spinner" animation="border" />
            </div>
          )}
          <div className="overflow-auto team-user-list">
            {filterUser
              ?.filter((user) => {
                const { first_name, last_name, user_name, alias, storeName } =
                  user || {};
                const keyword = state.keyword?.toLowerCase();
                return (
                  `${first_name} ${last_name}`
                    ?.toLowerCase()
                    .includes(keyword) ||
                  user_name?.toLowerCase().includes(keyword) ||
                  storeName?.toLowerCase().includes(keyword) ||
                  alias?.toLowerCase().includes(keyword)
                );
              })
              .map((user) => usersDetail(user))}
          </div>
        </div>
      </div>
      {state.invite && (
        <InviteUsersForm
          onClose={() => setState({ ...state, invite: false })}
        />
      )}
      {state.roleHelpPopup && (
        <UsersRoleHelp
          onClose={() => setState({ ...state, roleHelpPopup: false })}
          rolePermissions={rolePermissions}
        />
      )}
      {state.deleteUser && (
        <ConfirmationPopup
          okLabel="Delete"
          closeLabel="Cancel"
          loading={state.loading}
          onOk={() => {
            setState({
              ...state,
              loading: true
            });
            deleteUser(state.deleteUser.id)
              .then(() => {
                setTimeout(() => {
                  setState({
                    ...state,
                    deleteUser: undefined,
                    loading: undefined
                  });
                }, 10);
              })
              .catch((e) => {
                setState({
                  ...state,
                  deleteUser: undefined,
                  loading: undefined
                });
                console.error('user error ', e);
              });
          }}
          onClose={() => {
            setState({
              ...state,
              deleteUser: undefined
            });
          }}
        >
          <p className="text-semibold">
            Are you sure want to delete
            {user.id === state.deleteUser.id ? ' your self' : ' this user'}?
          </p>
        </ConfirmationPopup>
      )}
      {state.failUpdateRole && (
        <ConfirmationPopup
          closeLabel="Ok"
          onClose={() => {
            setState({
              ...state,
              failUpdateRole: undefined
            });
          }}
        >
          <p className="text-semibold">Fail to change role.</p>
        </ConfirmationPopup>
      )}
      <style jsx global>{`
        .content-wrapper {
          height: 100% !important;
          min-height: auto !important;
          display: flex;
          flex-direction: column;
          align-content: stretch;
          overflow: hidden;
          padding-top: 0 !important;
        }
        .layout {
          min-height: 100vh !important;
        }
        .user-row {
          display: flex;
          align-items: center;
        }
        .user-image {
          margin-right: 10px;
          margin-bottom: 10px;
        }
        .user-title {
          display: flex;
          width: 31%;
        }
        .join-date {
          width: 23%;
          text-align: center;
        }
        .user-role {
          width: 23%;
          padding-right: 10px;
        }
        .role-select {
          max-width: 150px;
          border-radius: 100px;
        }
        .solid {
          margin: 0px;
          transform: translateY(-12px);
        }
        .storeName-text {
          line-height: 14px;
        }
        .email-text {
          color: gray;
          font-size: 0.85rem;
        }
        .member-img {
          width: 48px;
          height: 48px;
        }

        .user-detail {
          background-color: #fefefe;
          padding: 5px;
        }
      `}</style>
      <style jsx>
        {`
          .search-user {
            position: relative;
          }
          .search-user label {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
          .search-user input {
            border-radius: 50px;
            padding-left: 40px;
          }
          .title-role {
            padding-right: 40px;
            cursor: pointer;
          }
          .row-name-title {
            margin-left: 15px;
            cursor: pointer;
          }
          .search-bar {
            height: 50px;
            margin-bottom: 20px;
          }
          .team-title {
            height: 80px;
            padding-top: 20px;
          }
          .team-user-list {
            margin-top: 16px;
            height: calc(100% - 200px);
            overflow: auto;
          }
          hr.solid {
            border-top: 1px solid #bbb;
          }
          .team-search .form-control {
            border: #c4c4c4 solid 0.3px;
            border-radius: 50px;
          }
          .team-search .btn {
            border-radius: 50px;
            min-width: 80px;
          }
          .teams-page {
          }
          .btn-search,
          .btn-clear {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .btn-search {
            left: 0;
          }
          .btn-clear {
            right: 0px;
          }
          input.form-control {
            padding: 0 40px 0 40px;
          }
          .form-control {
            border-radius: 50px;
            border: #ccc solid 1px;
            max-width: 100%;
            padding: 0 5px;
            font-size: 1rem;
          }
          .create-btn {
            padding-right: 0px;
          }
          .create-btn > button {
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default UsersManagement;
