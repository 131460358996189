import React from 'react';
import { IDeviceConfiguration, ISceneDetail } from '../../../../interfaces';
import ShowCasedVideo from './ShowCasedVideo';
import ShowCasedVideoPopup from './ShowCasedVideoPopup';
import ShowCasedVideoPreview from './ShowCasedVideoPreview';
import ShowcasedScene from './ShowcasedScene';
import ShowcasedScenePopup from './ShowcasedScenePopup';
import ShowcasedScenePreview from './ShowcasedScenePreview';

type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

function DeviceRough({
  config,
  onChange,
  scenes
}: {
  config: IDeviceConfiguration;
  onChange: (key: string, value: any) => void;
  scenes: {
    loading: boolean;
    data: ISceneDetail[];
    onChange: (scenes: ISceneDetail[]) => void;
    onAdd: (scene: ISceneDetail[]) => void;
  };
}) {
  if (!config) return null;

  const [showUploadPopup, setShowUploadPopup] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [tab, setTab] = React.useState('scenes');
  const [showScenePreview, setShowScenePreview] = React.useState(false);
  const [showScenePopup, setShowScenePopup] = React.useState(false);
  const handleChange = (e: React.ChangeEvent<InputElement>) => {
    let value: number | string = e.target?.value;
    if (e.target?.type === 'number') {
      value = parseInt(value);
    }
    onChange(e.target.id, value);
  };

  const onVideoChange = (videoUrls: string[]) => {
    onChange('showcaseVideos', videoUrls);
  };

  const onUpload = (videoUrls: string[]) => {
    const currentVideos = config.showcaseVideos || [];
    onChange('showcaseVideos', [...currentVideos, ...videoUrls]);
  };

  const noVideos = (config?.showcaseVideos || []).length === 0;
  const noScenes = (scenes?.data || []).length === 0;
  return (
    <>
      <div className="DeviceRough">
        <div className="metadata-card card">
          <div className="left-col card-item">
            <div className="d-name form-group">
              <label htmlFor="name">Name</label>
              <input
                className="form-control-sm"
                id="name"
                value={config.name}
                onChange={handleChange}
              />
            </div>
            <div className="d-id mb-1">
              <span>LENS Device:</span>
              <span className="ml-1">{config.name}</span>
            </div>
          </div>
          <div className="right-col card-item">
            <span className="text-sm font-semibold mb-2 d-inline-block">
              Interactive Display Settings
            </span>
            <div className="form-group one-line">
              <label htmlFor="colourScheme">Theme</label>
              <select
                className="form-control-sm"
                id="colourScheme"
                value={config?.colourScheme}
                onChange={handleChange}
              >
                <option value="LIGHT">Light</option>
                <option value="DARK">Dark</option>
              </select>
            </div>
            <div className="form-group one-line mb-0">
              <label htmlFor="entertainmentModeIdleTime">Idle time</label>
              <input
                className="form-control-sm"
                type="number"
                id="entertainmentModeIdleTime"
                value={config.entertainmentModeIdleTime}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-description form-group">
            <label htmlFor="description">Description</label>
            <textarea
              className="form-control"
              id="description"
              value={config.description}
              rows={3}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="videos-card card">
          <div className="video-display-header">
            <div
              className="scene-display-head display-head"
              data-active={tab === 'scenes'}
              onClick={() => setTab('scenes')}
            >
              <span className="text-sm font-semibold">Scene</span>
            </div>
            <div
              className="video-display-head display-head"
              data-active={tab === 'videos'}
              onClick={() => setTab('videos')}
            >
              <span className="text-sm font-semibold">Video</span>
            </div>
          </div>
          {tab === 'videos' && (
            <div className="actions-wrapper" data-no-video={noVideos}>
              <div className="list-wrapper position-relative">
                <ShowCasedVideo
                  videoUrls={config?.showcaseVideos}
                  onChange={onVideoChange}
                />
              </div>
              <div className="action-buttons">
                {noVideos && (
                  <span className="novideo-title">No videos found</span>
                )}
                <button
                  className="btn btn-round btn-dark w-100 btn-upload"
                  onClick={() => setShowUploadPopup(true)}
                >
                  Upload
                </button>
                {Boolean(!noVideos) && (
                  <button
                    className="w-100 btn btn-round btn-outline btn-preview"
                    onClick={() => {
                      setShowPreview(true);
                    }}
                  >
                    Show Preview
                  </button>
                )}
              </div>
            </div>
          )}
          {tab === 'scenes' && (
            <div className="actions-wrapper" data-no-video={noScenes}>
              <div className="list-wrapper position-relative">
                <ShowcasedScene
                  scenes={scenes.data}
                  onChange={scenes.onChange}
                  loading={scenes.loading}
                />
              </div>
              <div className="action-buttons">
                {noScenes && (
                  <span className="novideo-title">No scenes found</span>
                )}
                <button
                  className="btn btn-round btn-dark w-100 btn-upload"
                  onClick={() => setShowScenePopup(true)}
                >
                  Add scene
                </button>
                {Boolean(!noScenes) && (
                  <button
                    className="w-100 btn btn-round btn-outline btn-preview"
                    onClick={() => {
                      setShowScenePreview(true);
                    }}
                  >
                    Show Preview
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {showUploadPopup && (
        <ShowCasedVideoPopup
          onClose={() => setShowUploadPopup(false)}
          deviceId={config.deviceId}
          onChange={onUpload}
        />
      )}
      {showPreview && (
        <ShowCasedVideoPreview
          urls={config.showcaseVideos}
          onClose={() => setShowPreview(false)}
        />
      )}
      {showScenePreview && (
        <ShowcasedScenePreview
          onClose={() => setShowScenePreview(false)}
          scenes={scenes.data}
        />
      )}
      {showScenePopup && (
        <ShowcasedScenePopup
          onClose={() => setShowScenePopup(false)}
          onAdd={scenes.onAdd}
          addedScenes={scenes.data}
        />
      )}
      <style jsx>{`
        .card {
          padding: 20px;
          border: 1px solid #e0e0e0;
          border-radius: 20px;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
        }
        .metadata-card {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 5px 15px;
          flex: 2;
        }
        .video-display-header {
          margin-bottom: 20px;
          padding-bottom: 10px;
          display: flex;
          gap: 20px;
          border-bottom: 1px solid #e0e0e0;
        }
        .display-head {
          cursor: pointer;
        }
        .display-head[data-active='true'] {
          color: #8f6599;
          font-weight: 700;
          position: relative;
        }
        .display-head[data-active='true']::after {
          content: '';
          position: absolute;
          bottom: -10px;
          right: 0;
          left: 0;
          height: 3px;
          background: #8f6599;
        }
        .d-description {
          grid-column: 1 / span 2;
        }
        .metadata-card .card-item {
          flex: 1;
        }
        .videos-card {
          flex: 3;
        }
        .DeviceRough {
          padding: 20px;
          border-right: 1px solid #e0e0e0;
          display: flex;
          flex-direction: row;
          gap: 25px;
          height: 350px;
        }
        .actions-wrapper {
          flex: 1;
          display: flex;
          gap: 15px;
        }
        .novideo-title {
          color: #615e83;
          font-weight: 400;
        }
        .actions-wrapper[data-no-video='true'] .list-wrapper {
          display: none;
        }
        .actions-wrapper[data-no-video='true'] {
          align-items: center;
          justify-content: center;
        }
        .actions-wrapper[data-no-video='true'] .action-buttons {
          align-items: center;
        }
        .list-wrapper {
          flex: 9;
        }
        .action-buttons {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          flex: 3;
        }
        .action-buttons button.btn {
          height: 40px;
          max-width: 160px;
        }
        .d-id {
          font-size: 14px;
        }
        .video-display {
          display: flex;
          flex-direction: column;
          gap: 10px;
          flex: 1;
          height: 350px;
        }
        .video-display-body {
          flex: 1;
          height: 100%;
          overflow-y: auto;
        }
        .video-display-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .form-group {
          display: flex;
          flex-direction: column;
        }
        .form-group.one-line {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .form-group.one-line input,
        .form-group.one-line select {
          width: 50%;
          text-align: center;
        }
        .form-group label {
          font-weight: 500;
        }
        .font-semibold {
          font-weight: 500;
        }
        .DeviceRough input,
        .DeviceRough select,
        .DeviceRough textarea {
          border: 1px solid #777777;
          border-radius: 5px;
          padding: 5px;
        }
        .btn-upload,
        .btn-preview {
          font-size: 12px;
        }
      `}</style>
    </>
  );
}

export default DeviceRough;
