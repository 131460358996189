import React from 'react';
import { Spinner } from 'react-bootstrap';
import { BiChevronDown } from 'react-icons/bi';
import { logEvent } from '../../../analytics';
import { DID_TOGGLE_GALLERY_ITEM_FILTER } from '../../../utils/constants';
import {
  GalleryFilter,
  Visibility,
  Duration,
  Context
} from '../../clientSideServices/gallery';
import SearchInput from '../Common/SearchInput';
import { defaultFilter } from './HubGallery';

const GalleryItemFilters = ({
  filter,
  onFilter,
  onClose,
  loading
}: {
  filter?: GalleryFilter;
  onFilter: (filter: GalleryFilter) => void;
  onClose: () => void;
  loading?: boolean;
}) => {
  const onToggleVisibility = (visibility?: Visibility) => {
    const currentVisibility = filter?.visibility || [];
    let updatedVisibility = [...currentVisibility, visibility].sort();
    if (currentVisibility.includes(visibility)) {
      updatedVisibility = currentVisibility.filter((vis) => vis !== visibility);
    }
    onFilter({ ...filter, visibility: updatedVisibility });
  };

  const onToggleContext = (context?: Context) => {
    const currentContext = filter?.context || [];
    let updatedContext = [...currentContext, context].sort();
    if (currentContext.includes(context)) {
      updatedContext = currentContext.filter((vis) => vis !== context);
    }
    onFilter({ ...filter, context: updatedContext });
  };

  const onToggleDuration = (duration: Duration | undefined) => {
    onFilter({ ...filter, duration });
  };

  const showDuration =
    (filter.context?.[0] !== 'stillshot' && filter.context?.length === 1) ||
    filter.context?.length > 1 ||
    !filter.context?.length;

  return (
    <div className="GalleryItemFilters">
      <div className="filter-items text-left">
        <div className="filter-group">
          <h4>Type:</h4>
          {(
            [
              ['recording', 'Video'],
              ['stillshot', 'Photo']
            ] as [Context, string][]
          ).map((context) => (
            <button
              key={context[0]}
              className={`btn btn-sm btn-filter ssp-ignore ${
                filter?.context?.includes(context[0])
                  ? 'btn-success'
                  : 'btn-outline-secondary '
              }`}
              onClick={() => onToggleContext(context[0])}
            >
              {context[1]}
            </button>
          ))}
        </div>

        <div className="filter-group">
          <h4>Visibility:</h4>
          {(
            [
              ['brand', 'Brand'],
              ['site', 'Site'],
              ['private', 'Private']
            ] as [Visibility, string][]
          ).map((visibility) => (
            <button
              key={visibility[0]}
              className={`btn btn-sm btn-filter ssp-ignore  ${
                filter?.visibility?.includes(visibility[0])
                  ? 'btn-success'
                  : 'btn-outline-secondary '
              }`}
              onClick={() => onToggleVisibility(visibility[0])}
            >
              {visibility[1]}
            </button>
          ))}
        </div>

        {showDuration && (
          <div className="filter-group">
            <h4>Video Duration:</h4>
            <button
              className={`btn btn-sm btn-filter ssp-ignore  ${
                !filter?.duration ? 'btn-success' : 'btn-outline-secondary'
              }`}
              onClick={() => onToggleDuration(undefined)}
            >
              Any
            </button>
            <button
              className={`btn btn-sm btn-filter ssp-ignore  ${
                filter?.duration === 'short'
                  ? 'btn-success'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => onToggleDuration('short')}
            >
              Under 4 minutes
            </button>
            <button
              className={`btn btn-sm btn-filter ssp-ignore ${
                filter?.duration === 'medium'
                  ? 'btn-success'
                  : 'btn-outline-secondary '
              }`}
              onClick={() => onToggleDuration('medium')}
            >
              4 - 20 minutes
            </button>
            <button
              className={`btn btn-sm btn-filter ssp-ignore ${
                filter?.duration === 'long'
                  ? 'btn-success'
                  : 'btn-outline-secondary '
              }`}
              onClick={() => onToggleDuration('long')}
            >
              Over 20 minutes
            </button>
          </div>
        )}

        {loading && (
          <div className="text-center load-container">
            <Spinner animation="border" />
          </div>
        )}
      </div>
      <div className="control">
        <button
          className="btn btn-sm ssp-ignore"
          onClick={() => {
            onFilter(defaultFilter);
          }}
        >
          RESET FILTER
        </button>
        <button className="btn btn-sm ssp-ignore" onClick={onClose}>
          CLOSE
        </button>
      </div>
      <style jsx>{`
        .GalleryItemFilters {
          color: #fff;
        }
        h4 {
          font-size: 0.8em;
          letter-spacing: 1px;
          text-transform: uppercase;
          opacity: 0.5;
        }
        .filter-items {
          padding: 20px;
          max-height: 280px;
          overflow: auto;
          position: relative;
        }
        .filter-items button {
          margin: 0 5px 5px 0;
          text-transform: capitalize;
        }

        .control {
          border-top: 1px solid #444;
          text-align: right;
          padding: 5px;
        }
        .control button {
          color: #fff;
          margin-left: 5px;
        }
        .btn-filter {
          color: #fff;
        }

        .btn:not(.btn-success):focus,
        .btn:not(.btn-success):hover {
          background: transparent !important;
        }

        .filter-group {
          margin-bottom: 10px;
        }
        .filter-group:last-child {
          margin-bottom: 0;
        }
        .load-container {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
        }
        .load-container > :global(*) {
          margin: auto;
        }
        @media (min-width: 768px) {
          .filter-items {
            max-height: none;
          }
        }
      `}</style>
    </div>
  );
};

const HubGalleryItemsFilterControls = ({
  onSearch,
  filter,
  onFilter,
  total,
  hasFilter,
  loading
}: {
  onSearch: (keyword?: string) => void;
  filter: GalleryFilter;
  onFilter: (type: GalleryFilter) => void;
  total?: number;
  hasFilter?: boolean;
  loading?: boolean;
}) => {
  const [showFilter, setShowFilter] = React.useState(false);
  const handleSearch = (word: string) => {
    onSearch(word);
  };
  return (
    <div className="HubGalleryItemsFilterControls">
      <div className="inner">
        <h2>Gallery {!!total && <span>({total})</span>}</h2>
        <div className="filter-controls row">
          <div className="col-8 position-relative p-0 pr-2">
            <SearchInput
              rounded={true}
              onSearch={handleSearch}
              loading={loading}
              searchOnTyping={true}
            />
          </div>
          <div className="col-4 p-0">
            <button
              className={`btn form-control filter-btn ${
                showFilter
                  ? 'btn-dark'
                  : hasFilter
                  ? 'btn-success'
                  : 'btn-outline-dark'
              }`}
              onClick={() => {
                setShowFilter(!showFilter);
                logEvent(
                  DID_TOGGLE_GALLERY_ITEM_FILTER,
                  DID_TOGGLE_GALLERY_ITEM_FILTER,
                  {
                    open: !showFilter
                  }
                );
              }}
            >
              Filter <BiChevronDown />
            </button>
          </div>
          <div className="filter-wrapper">
            {showFilter && (
              <div className="filter-container">
                <GalleryItemFilters
                  onClose={() => setShowFilter(false)}
                  onFilter={onFilter}
                  filter={filter}
                  loading={loading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .HubGalleryItemsFilterControls {
          padding: 15px 20px;
          border-bottom: 1px solid #efefef;
        }
        .filter-controls {
          padding: 0 10px;
        }
        .filter-wrapper {
          width: 100%;
          position: relative;
        }
        h2 {
          font-size: 1.5em;
        }
        :global(.hasSelected) h2 {
          font-size: 1.2em;
        }
        h2 span {
          font-size: 0.7em;
          color: #999;
          font-weight: normal;
        }
        .form-control {
          border-radius: 50px;
          border: #000 solid 1px;
          max-width: 100%;
          padding: 0 5px;
          font-size: 1rem;
        }
        input.form-control {
          padding: 0 30px 0 40px;
        }
        .btn-search,
        .btn-clear {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .btn-search {
          left: 0;
        }
        .btn-clear {
          right: 10px;
        }
        .inner {
          padding-top: 5px;
        }
        .filter-container {
          position: absolute;
          top: 10px;
          left: 0;
          right: 0;
          background: #000;
          z-index: 2;
          border-radius: 5px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
        @media (max-width: 768px) and (min-width: 640px) {
          .HubGalleryItemsFilterControls {
            display: flex;
            align-items: flex-end;
          }
          .filter-controls {
            width: 380px;
          }
          .inner {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }

        @media (min-width: 768px) {
          :global(.fullScreen) .HubGalleryItemsFilterControls {
            display: flex;
            align-items: flex-end;
          }
          :global(.fullScreen) .filter-controls {
            width: 500px;
          }
          :global(.fullScreen) .inner {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
          }
          :global(.fullScreen) h2 {
            margin: 0;
          }
        }
        @media (min-width: 1140px) {
          :global(.fullScreen) .HubGalleryItemsFilterControls {
            padding: 15px 0;
          }
          :global(.fullScreen) .filter-controls {
            margin-left: 0;
            margin-right: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default HubGalleryItemsFilterControls;
