import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useSelector } from 'react-redux';
import { performProductSearch } from '../../../../clientSideServices/products';
import { IMainState, IProduct } from '../../../../interfaces';
import { getProductsFilterByBlackAndWhitelist } from '../../../../utils/product';
import { isProduct } from '../../../utils/nudge';
import SearchInput from '../../Common/SearchInput';
import { HubContext } from '../../HubContext';
import ProductEntry from './ProductEntry';

const batchSize = 24;
function ProductSearch({
  onAdd,
  addedProductIds,
  single = false
}: {
  onAdd: (product: IProduct) => void;
  addedProductIds: string[];
  single?: boolean;
}) {
  const [keywords, setKeywords] = React.useState<string>('');
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [startIndex, setStartIndex] = React.useState(0);
  const [searchResult, setSearchResult] = React.useState<IProduct[]>([]);
  const { productsFilterList } = useContext(HubContext);
  const { brandSetup } = React.useContext(HubContext);
  const parentBrandId = brandSetup?.parentBrandId;
  

  const { storeId, brandId } = useSelector(
    (state: IMainState) => state.clientState?.hub?.user
  );

  const search = async (keepResult = false, _start = 0) => {
    if (keepResult) {
      setIsLoadingMore(true);
    } else {
      setIsEmpty(false);
    }

      // Use parentBrandId if available, otherwise use brandId
      const _bId = parentBrandId || brandId;
      console.log('start search for brand', {
        brandId: _bId,
        keepResult,
        _start
      });

    try {
      const start = keepResult ? _start || startIndex : _start || 0;

      const result = await performProductSearch(
        keywords,
        start,
        batchSize,
        _bId,
        [],
        [],
        storeId
      );
      if (!result.data.length) {
        setIsEmpty(true);
      }
      const hasMore = result.data.length === batchSize;
      const nextIndex = start + result.data.length;
      setHasNextPage(hasMore);
      setStartIndex(nextIndex);
      const productsFilter = getProductsFilterByBlackAndWhitelist(
        productsFilterList?.productBlacklist,
        productsFilterList?.productWhitelist
      );
      const newProducts = productsFilter(result.data || []);
      if (newProducts.length === 0 && hasMore) {
        return search(keepResult, nextIndex);
      }
      if (keepResult) {
        setSearchResult([...searchResult, ...newProducts]);
      } else {
        setSearchResult(newProducts);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoadingMore(false);
  };

  React.useEffect(() => {
    search();
    return () => {
      setSearchResult([]);
    };
  }, [keywords, storeId, brandId]);
  const infiniteRef: React.MutableRefObject<any> = useInfiniteScroll({
    loading: isLoadingMore,
    hasNextPage,
    onLoadMore: () => search(true, startIndex)
  });

  const buttonMore = (
    <div style={{ width: '100%', padding: '10px 0 50px', textAlign: 'center' }}>
      <button className="load-more btn-dark" onClick={() => search(true)}>
        Load More
      </button>
    </div>
  );

  const loadingMore = (
    <div style={{ width: '100%', padding: '10px 0 50px', textAlign: 'center' }}>
      <Spinner animation="border" />
    </div>
  );

  return (
    <div className="ProductSearch">
      <div className="heading">
        <h5 className="font-weight-bold mb-4">Search Products</h5>
        <SearchInput onSearch={setKeywords} />
        <div className="metadata mt-2 d-flex justify-content-between">
          <span>All avalaible products</span>
          <span>{searchResult?.length} product(s) found</span>
        </div>
      </div>

      <ul className="products" ref={infiniteRef}>
        {searchResult.length !== 0 &&
          searchResult.map((item) => {
            if (!isProduct(item)) return null;
            const isAdded = addedProductIds?.includes(item.id);
            return (
              <li key={item.id}>
                <ProductEntry
                  product={item}
                  actionBtn={
                    <button
                      className={`btn ${
                        !isAdded ? 'btn-round btn-outline' : 'font-italic'
                      } mr-2`}
                      onClick={() => onAdd(item)}
                      disabled={isAdded}
                    >
                      {isAdded ? 'Added' : single ? 'Select' : 'Add'}
                    </button>
                  }
                />
              </li>
            );
          })}
        {isEmpty && (
          <div className="search-empty">
            <span>Product not found.</span>
          </div>
        )}
        {searchResult.length !== 0 &&
          hasNextPage &&
          !isLoadingMore &&
          buttonMore}
        {isLoadingMore && loadingMore}
      </ul>
      <style jsx>{`
        .ProductSearch {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding: 20px;
        }
        .heading {
          position: sticky;
          top: 0;
          background: #fff;
          padding-bottom: 20px;
        }
        .ProductSearch ul {
          padding: 0;
          margin: 0;
          flex: 1;
          overflow-y: auto;
        }
        .metadata {
          font-size: 12px;
          font-weight: 400;
        }
        .ProductSearch ul li {
          list-style: none;
        }
        .ProductSearch ul li:nth-child(odd) {
          background-color: #f5f5f5;
        }
      `}</style>
    </div>
  );
}

export default ProductSearch;
