import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import { RiCloseFill } from 'react-icons/ri';
import { useQuery } from 'react-query';
import { logEvent } from '../../../analytics';
import { DID_CLICK_BUTTON } from '../../../utils/constants';
import { getActivatedStorybookById } from '../../clientSideServices/storybook';

export const StorybookItemInSession = ({
  id,
  onView,
  onDelete
}: {
  id: string;
  onView: () => void;
  onDelete: () => void;
}) => {
  const { isLoading, isError, data } = useQuery(`sb-${id}`, () =>
    getActivatedStorybookById(id)
  );

  const title = data?.title;

  if (isLoading) return <Spinner animation="border" size="sm" />;

  return (
    <>
      <div className="file-title">
        <span className={`file-title-text ${!title ? 'deleted' : ''}`}>
          {title || 'This Storybook no longer available'}
        </span>
      </div>
      <div className="file-controls">
        {!isError && (
          <button
            onClick={() => {
              onView();
              logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                button: 'View Icon',
                storybookId: id
              });
            }}
            className="btn btn-sb-preview"
          >
            <FiSearch />
          </button>
        )}

        <button
          onClick={() => {
            onDelete();
            logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
              button: 'Delete Icon',
              storybookId: id
            });
          }}
          className="btn btn-sb-delete"
        >
          <RiCloseFill />
        </button>
      </div>
      <style jsx>{`
        .file-title {
          display: flex;
          flex: 0.9;
          width: 100%;
          overflow: hidden;
          align-items: center;
        }

        .file-title-text {
          max-width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .deleted {
          color: #b50000;
        }
        .file-controls {
          display: flex;
          flex: 0.1;
          justify-content: center;
        }
      `}</style>
    </>
  );
};

export default StorybookItemInSession;
