import React, { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { ITableColumn, SortTable } from '../../Common/Table';
import { ApexOptions } from 'apexcharts';
import Tabs, { ITab } from '../../Common/Tabs';
import { InfoIcon } from '../../Common/HubIcons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { max, sortBy, uniq } from 'lodash';
import {
  CHART_TOTAL_COLOR,
  flattenData,
  getAreaChartTooltip,
  getChartColorByIndex,
  mapAndSortViewsDensity
} from './helper';
import {
  formatDurationFromMiliSeconds,
  formattedNumber
} from '../../../../utils/number';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

export interface IStatistic {
  name: string;
  value1: string | number;
  value2: string | number;
  percent: number;
  border: boolean;
  increase: number;
  sub?: string;
  conversion?: boolean;
}

type DeviceInsightOverviewProps = {
  startDate: string;
  endDate: string;
  range: number;
  data: any;
  loading: boolean;
  getOverview: ({ startDate, endDate }, refresh?: boolean) => void;
};

const overviewTabs = [
  { id: 'viewed', label: 'Viewed' },
  { id: 'shared', label: 'Shared' }
];

const DeviceAnalyticsOverview: React.FC<DeviceInsightOverviewProps> = (
  props
) => {
  const { data, startDate, endDate, range, loading, getOverview } = props;
  const [currentOverviewTab, setCurrentOverviewTab] = useState<ITab>(
    overviewTabs[0]
  );

  const loadData = (refresh = false) => {
    const fromDate =
      range > 0
        ? moment().subtract(range, 'days').format('YYYY-MM-DD')
        : startDate;
    const toDate = range > 0 ? moment().format('YYYY-MM-DD') : endDate;

    getOverview({ startDate: fromDate, endDate: toDate }, refresh);
  };

  const dataChart = useMemo(() => {
    const newData = {};

    ['viewed', 'shared'].forEach((key) => {
      newData[key] =
        key === 'viewed'
          ? {
              Products: { ...data?.dataByDateAndCountry?.productViewed },
              Story: { ...data?.dataByDateAndCountry?.storyViewed }
            }
          : {
              Products: { ...data?.dataByDateAndCountry?.productShared },
              Story: { ...data?.dataByDateAndCountry?.storyShared }
            };
    });

    return flattenData(newData);
  }, [data?.dataByDateAndCountry]);

  const datetimeList = useMemo(
    () =>
      sortBy(
        uniq(
          Object.values(dataChart?.[currentOverviewTab.id] || {}).flatMap(
            (dateTime) => Object.keys(dateTime)
          )
        ),
        (d) => moment(d).unix()
      ),
    [dataChart, currentOverviewTab]
  );

  const chartAreaSeries = useMemo(
    () =>
      Object.entries(dataChart?.[currentOverviewTab.id] || {}).map(
        ([type, dataType], index) => {
          return {
            name: type,
            data: datetimeList.map((datetime) => {
              return dataType[datetime] || 0;
            }),
            color: getChartColorByIndex(index)
          };
        }
      ),
    [dataChart, currentOverviewTab, datetimeList]
  );

  const chartAreaTotalLine = useMemo(
    () => ({
      name: 'Total',
      data: datetimeList.map((_, index) => {
        const total = chartAreaSeries.reduce((acc, series) => {
          return acc + series.data[index];
        }, 0);
        return total;
      }),
      color: CHART_TOTAL_COLOR
    }),
    [chartAreaSeries, datetimeList]
  );

  const chartAreaOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        type: 'area',
        toolbar: {
          show: false
        }
      },
      stroke: {
        curve: 'smooth',
        width: 1
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: datetimeList.map((datetime) =>
          moment(datetime).format('DD MMM')
        )
      },
      yaxis: {
        min: 0,
        max: max(chartAreaTotalLine.data) + 1,
        labels: {
          formatter: (v) => {
            if (currentOverviewTab.id === 'totalEyeballTime')
              return formatDurationFromMiliSeconds(v);
            return formattedNumber(v, '0 a');
          }
        },
        forceNiceScale: currentOverviewTab.id === 'totalEyeballTime'
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        },
        custom: (params) => getAreaChartTooltip(params, currentOverviewTab)
      },
      noData: {
        text: 'No data to display',
        align: 'center',
        verticalAlign: 'middle'
      }
    }),
    [datetimeList]
  );

  const lineChartAreaSeries = [chartAreaTotalLine, ...chartAreaSeries];

  const hourlyViewSeries = useMemo(() => {
    const density = mapAndSortViewsDensity(
      data?.viewsDensity,
      startDate,
      endDate,
      range
    );
    return density;
  }, [data?.viewsDensity, startDate, endDate]);

  const maxHourlyView = useMemo(() => {
    const maxHour = max(
      hourlyViewSeries?.map((hour) => max(hour?.data)) || [0]
    );
    return maxHour + (10 - (maxHour % 10));
  }, [hourlyViewSeries]);

  const heatmapHeight = useMemo(() => {
    const min = 500;
    const range = hourlyViewSeries?.length || 1;
    return Math.max(min, range * 20);
  }, [hourlyViewSeries]);

  const hourlyViewOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: '#FAF1FD',
              name: 'low'
            },
            {
              from: maxHourlyView,
              to: maxHourlyView,
              color: '#502B5A',
              name: 'high'
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#AE3BCD'],
    xaxis: {
      position: 'top',
      labels: {
        formatter: (value) => `${parseInt(value) - 1}`,
        style: {
          colors: ['#615E83'],
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#615E83'],
          fontSize: '14px',
          cssClass: 'heatmap-yaxis-label'
        }
      }
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const time = `0${dataPointIndex}:00`.slice(-5);
        const label = `${w.globals.seriesNames[seriesIndex]}, ${time}`;
        return `
        <div class="custom-tooltip">
          <div class="tooltip-label">${label}</div>
          <div class="tooltip-value">${value}</div>
        </div>
      `;
      },
      style: {
        fontSize: '12px',
        fontFamily: undefined
      }
    }
  };

  const topProductColumns: ITableColumn[] = [
    {
      key: 'product',
      title: 'Top Performing Product',
      align: 'left',
      custom: (value, rowIndex) => {
        return (
          <div className="custom-topview">
            <span>{rowIndex + 1}</span>
            <div className="table-image">
              <img src={value?.image} alt={value?.title} />
            </div>
            <div>
              <div className="title">{value?.title}</div>
              <div className="subtitle">{value?.subtitle}</div>
            </div>
          </div>
        );
      }
    },
    {
      key: 'views',
      title: 'Views',
      align: 'center'
    },
    {
      key: 'ats',
      title: (
        <OverlayTrigger
          placement="top"
          delay={{ show: 200, hide: 200 }}
          overlay={(props) => (
            <Tooltip
              id="tooltip-info"
              {...props}
              style={{
                color: '#fff',
                fontSize: 12,
                borderRadius: 5,
                textAlign: 'center',
                ...props.style
              }}
            >
              Average Time Spent (per minute)
            </Tooltip>
          )}
        >
          <div className="title-info">
            ATS <InfoIcon />
          </div>
        </OverlayTrigger>
      ),
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    }
  ];

  const topProducts = React.useMemo(
    () =>
      data?.topProducts?.map((prod) => {
        return {
          views: prod.views,
          ats: prod.averageTimeSpent,
          product: {
            image: prod.product.previewImageUrl || '',
            title: prod.product.modelName || '',
            subtitle: prod.product.modelCode || ''
          }
        };
      }),
    [data?.topProducts]
  );

  const topStoriesColumns: ITableColumn[] = [
    {
      key: 'store',
      title: 'Top Stories',
      align: 'left',
      custom: (value, rowIndex) => {
        return (
          <div className="custom-interaction center">
            <span style={{ marginRight: '20px' }}>{rowIndex + 1}</span>
            <div className="table-image mr-2">
              <img src={value?.url} alt={value?.title} />
            </div>
            <div className="title">{value?.title}</div>
          </div>
        );
      }
    },
    {
      key: 'views',
      title: 'Views',
      align: 'center'
    },
    {
      key: 'ats',
      title: (
        <OverlayTrigger
          placement="top"
          delay={{ show: 200, hide: 200 }}
          overlay={(props) => (
            <Tooltip
              id="tooltip-info"
              {...props}
              style={{
                color: '#fff',
                fontSize: 12,
                borderRadius: 5,
                textAlign: 'center',
                ...props.style
              }}
            >
              Average Time Spent (per minute)
            </Tooltip>
          )}
        >
          <div className="title-info">
            ATS <InfoIcon />
          </div>
        </OverlayTrigger>
      ),
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    }
  ];

  const topStories = React.useMemo(
    () =>
      data?.topStories?.map((st) => {
        return {
          views: st.views,
          ats: st.averageTimeSpent,
          store: {
            url: st.store?.flipImageUrl || '',
            title: st.store?.title || ''
          }
        };
      }),
    [data?.topStories]
  );
  const legendNumbers = useMemo(() => {
    const numbers = [];
    for (let i = 0; i <= maxHourlyView; i += maxHourlyView / 5) {
      numbers.push(
        <span key={i} style={{ left: `${(i / maxHourlyView) * 100}` }}>
          {formattedNumber(i)}
        </span>
      );
    }
    return numbers.reverse();
  }, [maxHourlyView]);

  const xAxisNumbers = useMemo(() => {
    return new Array(24)
      .fill(0)
      .map((_, index) => <span key={index}>{index}</span>);
  }, []);

  return (
    <>
      <div className="analytics-main" data-isloading={loading}>
        <button
          className="btn-round btn btn-dark btn-refresh"
          onClick={() => loadData(true)}
        >
          Refresh
        </button>
        <div className="overview-linechart card-section">
          <Tabs
            dot={true}
            onSelectTab={setCurrentOverviewTab}
            tabs={overviewTabs}
            active={currentOverviewTab}
          />
          <Chart
            options={chartAreaOptions}
            series={lineChartAreaSeries}
            type="area"
            height="350"
          />
        </div>
        <div className="overview-heatmap">
          <div className="card-section">
            <div className="card-title">
              <label>Global Hourly Views Density (UTC +8)</label>
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={(props) => (
                  <Tooltip
                    id="tooltip"
                    {...props}
                    style={{
                      color: '#fff',
                      fontSize: 12,
                      borderRadius: 5,
                      textAlign: 'center',
                      ...props.style
                    }}
                  >
                    This heatmap represents hourly views from audiences base on
                    the LENS' localisation selection, aggregated and visualized
                    for each hour of the day. Please note that the data is
                    normalized and displayed in your defined timezone.
                  </Tooltip>
                )}
              >
                <div style={{ display: 'flex' }}>
                  <InfoIcon />
                </div>
              </OverlayTrigger>
            </div>
            <div className="heatmap-content">
              <div className="heatmap-chart">
                <div className="heatmap-wrapper">
                  <Chart
                    options={hourlyViewOptions}
                    series={hourlyViewSeries}
                    height={heatmapHeight}
                    type="heatmap"
                  />
                </div>
                <div className="custom-heatmap-xasis">{xAxisNumbers}</div>
              </div>
              <div className="custom-heatmap-legend">
                <div className="legend-bar"></div>
                <div className="legend-number">{legendNumbers}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="overview-top-view">
          <div className="card-section">
            <div className="overview__top-scene">
              <label>Top Product</label>
              <SortTable columns={topProductColumns} data={topProducts} />
            </div>
          </div>
          <div className="card-section">
            <div>
              <label>Top Stories</label>
              <SortTable columns={topStoriesColumns} data={topStories} />
            </div>
          </div>
        </div>
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>

      <style jsx>{`
        .analytics-main {
          padding: 10px;
        }
        .btn-refresh {
          position: absolute;
          top: 25px;
          right: 25px;
          width: 120px;
        }
        .overview-statistic {
          margin: 20px 0;
          display: flex;
          flex-wrap: wrap;
        }
        .analytics-main[data-isloading='true'] .btn-refresh {
          display: none;
        }
        :global(.title-info svg) {
          width: 12px;
          margin-left: 5px;
        }
        :global(.tooltip-inner) {
          background-color: #fff;
          box-shadow: 0px 2.679px 6.698px 0px rgba(0, 0, 0, 0.25);
          color: #484848;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          padding: 0.5rem 0.75rem;
          max-width: 230px;
        }
        :global(.arrow) {
          display: none !important;
        }
        :global(.title-info) {
          display: flex;
          align-items: center;
        }
        :global(.overview-linechart .tab) {
          border-bottom: none;
        }
        :global(.overview-linechart .tab) {
          font-size: 14px !important;
          padding: 7px 10px !important;
          text-transform: capitalize;
        }
        :global(.overview-linechart .active-tab) {
          color: rgba(0, 0, 0, 0.9);
        }
        :global(.overview-linechart .active-tab svg) {
          color: #4c2d5a;
        }
        .card-section {
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 0.98px 2.95px 0px #0d0a2c14;
          padding: 20px;
          margin-bottom: 20px;
        }
        .card-section label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          font-weight: 400;
        }
        .overview-bar,
        .overview-top-view {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
        }

        :global(.overview-heatmap .custom-tooltip) {
          padding: 10px;
          color: #4c2d5a;
        }
        .card-title {
          display: flex;
        }
        :global(.card-section .card-title svg) {
          width: 18px;
          margin-left: 10px;
        }
        :global(.overview-heatmap .tooltip-label) {
          font-size: 12px;
        }
        :global(.overview-heatmap .tooltip-value) {
          font-weight: 500;
          font-size: 18px;
        }
        :global(.custom-topview) {
          display: flex;
          gap: 15px;
        }
        :global(.custom-interaction .title),
        :global(.custom-topview .title) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 300px;
        }
        :global(.custom-topview .subtitle) {
          color: #484848;
          font-size: 12px;
          font-weight: normal;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 300px;
        }
        :global(.custom-interaction) {
          display: flex;
        }
        ::global(.custom-interaction.center) {
          align-items: center;
        }
        :global(.overview-top-view .sort-table thead tr) {
          border-bottom: none;
        }
        :global(.overview-top-view .sort-table tbody td) {
          height: 85px;
          font-weight: 500;
          color: #252525;
        }
        :global(
            .overview-heatmap
              .apexcharts-legend.apexcharts-align-center.apx-legend-position-top
          ) {
          display: none;
        }
        .overview__view-more {
          text-align: center;
          color: #4c2d5a;
          cursor: pointer;
        }
        ::global(.analytics-main .table-image img) {
          width: 50px;
          height: auto;
        }
        @media (max-width: 1080px) {
          .overview-top-view {
            display: grid;
            grid-template-columns: 1fr;
          }
          .overview-bar {
            display: grid;
            grid-template-columns: 1fr;
          }
        }
        .analytics-main[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }

        .analytics-main[data-isloading='true'] .overlay {
          display: flex;
        }

        .custom-heatmap-legend {
          padding-top: 30px;
          display: flex;
        }
        .custom-heatmap-legend .legend-bar {
          width: 20px;
          height: 90%;
          background: linear-gradient(
            180deg,
            #502b5a 0%,
            #ae3bcd 50%,
            #faf1fd 100%
          );
          flex-shrink: 0;
        }
        .heatmap-chart :global(.apexcharts-xaxis) {
          opacity: 0;
        }

        .heatmap-content {
          display: flex;
          width: 100%;
          overflow: hidden;
          gap: 15px;
        }
        .heatmap-chart {
          flex: 1;
        }
        .heatmap-content .heatmap-wrapper {
          height: 520px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 1;
          position: relative;
        }

        .custom-heatmap-xasis {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-size: 12px;
          width: 100%;
          padding: 0px 10px 0px 73px;
          font-weight: 500;
          margin-top: -20px;
          background: #fff;
          z-index: 2;
          position: relative;
        }

        .custom-heatmap-legend .legend-number {
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 10px;
          font-size: 12px;
        }

        .heatmap-content .heatmap-wrapper::-webkit-scrollbar {
          width: 5px;
        }

        .heatmap-content .heatmap-wrapper::-webkit-scrollbar-thumb {
          background: #502b5a;
          border: 3px solid #502b5a;
          border-radius: 5px;
        }
      `}</style>
    </>
  );
};

export default DeviceAnalyticsOverview;
