import React from 'react';
import { createPortal } from 'react-dom';
import { Container, Row, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { staticBaseUrl } from '../../../config';
import { IUser, Source } from '../../../interfaces';
import { actionOpenVBPopup } from '../../../redux/actions';
import { getCurrentHost } from '../../../utils/window';
import { HubContext } from '../HubContext';
import { useTranslation } from 'next-i18next';
import { Transition } from 'react-transition-group';

enum InsightTab {
  HISTORY,
  CASES,
  OPPORTUNITY
}

const ChatUserInsight = ({
  user,
  show,
  onClose
}: {
  user?: IUser;
  show: boolean;
  onClose: () => void;
}) => {
  const { i18n } = useTranslation();
  const language = `${i18n?.language || 'en'}`;
  const [selectedTab, setSelectedTab] = React.useState<InsightTab>(
    InsightTab.HISTORY
  );
  const { storeId } = React.useContext(HubContext);

  const dispatch = useDispatch();

  const showWatch = (productId: string) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/products/${productId}?source=${Source.Chat}${
          storeId ? `&store=${storeId}` : ''
        }&language=${language}`,
        true
      )
    );
  };

  const history = (
    <>
      <Table striped bordered hover className="history">
        <thead>
          <tr>
            <th>Date</th>
            <th>Item</th>
          </tr>
        </thead>
        <tbody>
          <tr
            onClick={() => {
              showWatch('b2214da0-cc17-4891-97cd-912f6b12d1c6');
            }}
          >
            <td>17 Jul 2021</td>
            <td>IW390703 - Portugieser Yacht Club Chronograph</td>
          </tr>
          <tr
            onClick={() => {
              showWatch('7b32acde-1a1b-4013-bf45-af21ed84941f');
            }}
          >
            <td>22 Feb 2020</td>
            <td>IW500714 - Portugieser Automatic</td>
          </tr>
          <tr
            onClick={() => {
              showWatch('25aa9f4d-0baf-4ea8-bbb7-eadbe0725aef');
            }}
          >
            <td>10 Jan 2017</td>
            <td>IW458101 - Portofino Automatic 37</td>
          </tr>
        </tbody>
      </Table>
      <style jsx>
        {`
          :global(.insights .form-container .table.table-hover td) {
            text-align: left;
            border: 1px solid #dee2e6;
          }

          :global(.insights .form-container .table.history tr) {
            cursor: pointer;
          }
        `}
      </style>
    </>
  );

  const cases = (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Subject</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
      <style jsx>
        {`
          :global(.insights .form-container .table.table-hover td) {
            text-align: left;
            border: 1px solid #dee2e6;
          }
          :global(.insights .form-container .table.table-hover td:first-child) {
            width: 30%;
          }

          :global(.insights .form-container .table.table-hover tr) {
            height: 45px;
          }
        `}
      </style>
    </>
  );

  const opportunity = (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Opportunity</th>
            <th>Amount</th>
            <th>Close Date</th>
            <th>Item</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
      <style jsx>
        {`
          :global(.insights .form-container .table.table-hover td) {
            text-align: left;
            border: 1px solid #dee2e6;
          }
          :global(.insights .form-container .table.table-hover td:first-child) {
            width: 30%;
          }

          :global(.insights .form-container .table.table-hover tr) {
            height: 45px;
          }
        `}
      </style>
    </>
  );

  const getTable = () => {
    switch (selectedTab) {
      case InsightTab.HISTORY:
        return history;
      case InsightTab.CASES:
        return cases;
      case InsightTab.OPPORTUNITY:
        return opportunity;
      default:
        return null;
    }
  };

  return createPortal(
    <div className="insights">
      <Transition in={show} timeout={300} mountOnEnter unmountOnExit>
        {(state) => (
          <div className={`popup-container fadein ${state}`} id="ap-popup">
            <div className="form-container">
              <button className="popup-close" type="button" onClick={onClose}>
                &times;
              </button>
              <Container>
                <Row>
                  <h2>
                    <img
                      src={`${staticBaseUrl}/connect/assets/insights_white.svg`}
                      alt="insight"
                      className="insights"
                    />{' '}
                    CUSTOMER INSIGHTS
                  </h2>
                </Row>
                <div className="contact">
                  <div className="two-col">
                    <div className="input-group">
                      <label>First Name:</label>
                      <div className="phone-number-input no-ltr">
                        <select className="salutation-select">
                          <option value="mr">Mr</option>
                          <option value="mrs">Mrs</option>
                          <option value="ms">Ms</option>
                        </select>
                        <input
                          type="text"
                          placeholder="Enter first name"
                          defaultValue={user?.alias}
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <label>Last Name:</label>
                      <input
                        type="text"
                        placeholder="Enter last name"
                        defaultValue={user?.last_name}
                      />
                    </div>
                  </div>

                  <div className="two-col">
                    <div className="input-group">
                      <label>Phone Number:</label>
                      <div className="phone-number-input">
                        <input
                          className="country-code"
                          id="inputcountrycode"
                          type="text"
                          defaultValue={
                            (user?.mobilePhoneCountryCode as string) || '+1'
                          }
                        />
                        <input
                          type="number"
                          placeholder="Enter phone number"
                          defaultValue={user?.mobileNumber}
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <label>Email:</label>
                      <input
                        type="text"
                        placeholder="Enter email"
                        defaultValue={user?.email}
                      />
                    </div>
                  </div>

                  <Row className="buttons">
                    <div className="select-buttons">
                      <button
                        className={
                          selectedTab === InsightTab.HISTORY ? 'selected' : ''
                        }
                        onClick={() => {
                          setSelectedTab(InsightTab.HISTORY);
                        }}
                      >
                        Purchase History
                      </button>
                      <button
                        className={
                          selectedTab === InsightTab.OPPORTUNITY
                            ? 'selected'
                            : ''
                        }
                        onClick={() => {
                          setSelectedTab(InsightTab.OPPORTUNITY);
                        }}
                      >
                        Opportunities
                      </button>
                      <button
                        className={
                          selectedTab === InsightTab.CASES ? 'selected' : ''
                        }
                        onClick={() => {
                          setSelectedTab(InsightTab.CASES);
                        }}
                      >
                        Cases
                      </button>
                    </div>
                  </Row>
                  <Row className="tables">{getTable()}</Row>
                </div>
              </Container>
            </div>
          </div>
        )}
      </Transition>
      <style jsx>
        {`
          .insights .input-group {
            position: relative;
          }
          .insights label {
            display: block;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 0.7em;
            margin-top: 15px;
            margin-bottom: 5px;
          }

          .insights input,
          .insights select,
          .insights textarea {
            border: none;
            border-bottom: 1px solid rgb(223, 223, 223);
            width: 100%;
            background: transparent;
            font-size: 1em;
            outline: none;
            border-radius: 0;
            box-sizing: border-box;
          }

          .insights .phone-number-input {
            display: flex;
            width: 100%;
          }

          .phone-number-input #inputcountrycode {
            width: 60px;
            margin-right: 15px;
          }

          .popup-container {
            height: 100vh;
            z-index: 10;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.8);
            text-align: center;
            position: fixed;
          }
          .popup-close {
            border: none;
            position: absolute;
            right: 10px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            padding: 0;
            text-align: center;
            top: 10px;
            color: rgb(160, 160, 160);
            background: transparent;
            font-size: 2em;
            font-weight: 400;
          }

          .form-container {
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: left;
            padding: 50px 20px 20px 20px;
            overflow-y: auto;
            font-size: 1em;
          }

          h2 {
            margin-bottom: 30px;
          }

          img.insights {
            position: relative;
            top: -5px;
          }

          .salutation-select {
            margin-right: 10px;
            width: 60px !important;
          }

          .contact :global(.row) {
            display: flex;
            margin-bottom: 30px;
          }

          .contact :global(.row.buttons) {
            margin-bottom: 30px;
            margin-top: 50px;
          }

          .select-buttons {
            border-radius: 30px;
            background: #efefef;
          }

          .select-buttons button {
            width: 180px;
            border: none;
            border-radius: 30px;
            padding: 10px 20px;
          }

          .select-buttons button.selected {
            background: #000;
            color: #fff;
          }

          :global(
              .insights .form-container .table.table-hover tr:nth-child(odd) td
            ) {
            background: rgb(239, 239, 239);
          }

          @media (max-width: 480px) {
            h2 {
              font-size: 1.5rem;
            }
            .select-buttons button {
              width: 100%;
            }
          }

          @media (min-width: 1024px) {
            .form-container {
              font-size: 1em;
              margin: 100px 100px 0 100px;
              padding: 70px 100px 30px 100px;
              top: 30px;
              bottom: 30px;
            }

            .two-col {
              display: flex;
              justify-content: space-between;
            }
            .two-col .input-group {
              width: 46%;
            }
            .two-col .error {
              top: 18px;
            }
          }

          @media (min-width: 1600px) {
            .form-container {
              margin: 100px 300px 0 300px;
            }
          }

          .fadein {
            transition: all 0.3s ease-in-out;
          }

          .fadein.entering {
            opacity: 0;
          }
          .fadein.entered {
            opacity: 1;
          }
          .fadein.exiting {
            opacity: 0;
          }
          .fadein.exited {
            opacity: 0;
          }
        `}
      </style>
    </div>,
    document.getElementById('hub-layout')
  );
};

export default ChatUserInsight;
