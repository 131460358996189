import React from 'react';
import Layout from '../Layout';
import HubGalleryItemList from './HubGalleryList';
import HubGalleryItemDetails from './HubGalleryDetails';

import HubFilterControls from './HubGalleryItemsFilterControls';
import {
  getGalleryItems,
  GalleryFilter
} from '../../clientSideServices/gallery';
import { IInspiration, IUser } from '../../../interfaces';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { logEvent } from '../../../analytics';
import {
  DID_SEARCH_FOR_GALLERY,
  DID_UPDATE_GALLERY_ITEM_FILTER
} from '../../../utils/constants';
import {
  getUserEntitlementByType,
  RecordingEntitlement
} from '../../utils/user';
import { UserEntitlementType } from '../HubContext';
import { useDispatch } from 'react-redux';
import { addUserInfoAsync } from '../../../redux/advisorHubAsyncActions';

export const defaultFilter: GalleryFilter = {
  duration: undefined
};
const HubGallery = ({
  storeId,
  brandId,
  user
}: {
  storeId: string;
  brandId: string;
  user?: IUser;
}) => {
  const dispatch = useDispatch();
  const { id: userId, entitlements } = user || {};
  const brandEntitlement = (getUserEntitlementByType(
    entitlements,
    UserEntitlementType.BRAND_ID
  ) || [brandId]) as string[];
  const siteEntitlement = (getUserEntitlementByType(
    entitlements,
    UserEntitlementType.STORE_ID
  ) || [storeId]) as string[];
  const recordingEntitlement = getUserEntitlementByType(
    entitlements,
    UserEntitlementType.RECORDING_VISIBILITY
  ) as RecordingEntitlement;

  const [selectedGalleryItem, setSelectedGalleryItem] = React.useState<
    IInspiration | undefined
  >();
  const [keyword, setKeyword] = React.useState('');
  const [galleryItems, setGalleryItems] = React.useState<IInspiration[]>([]);
  const [startIndex, setStartIndex] = React.useState<number>();
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [filter, setFilter] = React.useState<GalleryFilter>(defaultFilter);

  const hasNextPage = galleryItems.length && totalCount !== galleryItems.length;
  const hasFilter =
    (filter?.visibility?.length !== 3 && !!filter?.visibility?.length) ||
    (filter?.context?.length !== 2 && !!filter?.context?.length) ||
    !!filter?.duration;
  const handleUpdateGalleryItem = (galleryItem: IInspiration) => {
    const copy = [...galleryItems];
    const updatedIndex = galleryItems.indexOf(
      galleryItems.find((vid) => vid.id === galleryItem.id)
    );
    copy[updatedIndex] = galleryItem;
    setGalleryItems(copy);
    setSelectedGalleryItem(galleryItem);
  };

  const search = (keyword = '', keepResult?: boolean) => {
    if (!user) return;

    const searchFilter: GalleryFilter = {
      //temporarily disabled site entitlement due data quality
      siteEntitlement,
      brandEntitlement,
      userId,
      ...filter
    };

    setKeyword(keyword);
    setLoading(true);
    getGalleryItems(
      keyword,
      keepResult ? startIndex : undefined,
      searchFilter
    )
      .then((response) => {
        const videoList = keepResult
          ? [...galleryItems, ...response.results]
          : response.results;
        setGalleryItems(videoList);
        setStartIndex(response.lastIndex);
        setTotalCount(response.totalCount);
        setLoading(false);
        dispatch(addUserInfoAsync(response.results?.map((item) => item.createdBy)));
        return;
      })
      .catch((e) => console.log('error getRecordedVideos', e));
  };

  const loadMore = () => {
    search(keyword, true);
  };

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore
  });

  React.useEffect(() => {
    search(keyword);
  }, [filter, user]);
  return (
    <Layout showNav={false} theme="dark-light" className="layoutGalleryItems">
      <div
        className={`galleryItems-container ${
          selectedGalleryItem ? 'hasSelected' : 'fullScreen'
        }`}
      >
        <div className="list-col">
          <HubFilterControls
            onSearch={(keyword) => {
              search(keyword);
              logEvent(DID_SEARCH_FOR_GALLERY, DID_SEARCH_FOR_GALLERY, {
                keyword
              });
            }}
            total={totalCount}
            filter={filter}
            onFilter={(filter) => {
              setFilter(filter);
              logEvent(
                DID_UPDATE_GALLERY_ITEM_FILTER,
                DID_UPDATE_GALLERY_ITEM_FILTER,
                {
                  filter
                }
              );
            }}
            hasFilter={hasFilter}
            loading={loading}
          />
          <HubGalleryItemList
            gallery={galleryItems}
            onSelectGalleryItem={setSelectedGalleryItem}
            selectedGalleryItem={selectedGalleryItem}
            loading={loading}
            reference={infiniteRef}
            onClearFilter={
              hasFilter ? () => setFilter(defaultFilter) : undefined
            }
          />
        </div>
        {selectedGalleryItem && (
          <div className="details-col">
            <HubGalleryItemDetails
              onBack={() => setSelectedGalleryItem(undefined)}
              galleryItem={selectedGalleryItem}
              storeId={storeId}
              onUpdate={handleUpdateGalleryItem}
              userId={user?.id}
              recordingEntitlement={recordingEntitlement}
            />
          </div>
        )}
      </div>
      <style jsx global>{`
        .content-wrapper {
          height: 100vh !important;
          min-height: auto !important;
          display: flex;
          flex-direction: column;
          align-content: stretch;
          overflow: hidden;
          padding-top:0 !important;
        }
        .layout {
          min-height: 100vh !important;
        }
      `}</style>
      <style jsx>{`
        .galleryItems-container {
          margin: 0 auto;
          width: 100%;
          height: 100%;
          font-size: 16px;
        }

        .hasSelected {
          display: flex;
          flex-wrap: wrap;
          max-width:1400px;
        }
        .list-col {
          display: flex;
          flex-direction: column;
          flex-flow: column;
          height: 100%;
        }
        .details-col {
          height: 100%;
          overflow: auto;
          flex: 1;
        }
        .hasSelected .list-col {
          display: none;
        }
        @media (min-width: 1140px) {
          .galleryItems-container {
            padding: 0 20px;
          }
          .hasSelected .list-col {
            display: flex;
            width: 350px;
            border-right: 1px solid #ccc;
          }
        }
      `}</style>
    </Layout>
  );
};

export default HubGallery;
