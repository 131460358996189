import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { logEvent } from '../../../analytics';
import { isValidEmailId } from '../../../components/VirtualBoutique/Appointment/inputValidator';
import { INewInviteUser, RoleEntitlement } from '../../../interfaces';
import { DID_CLICK_CREATE_USER } from '../../../utils/constants';
import { HubUserContext } from './HubUserContext';
import { HubContext } from '../HubContext';
import { some } from 'lodash';
import PopupContainer from '../Common/PopupContainer';
import { getDuplicateString } from '../../../utils/string';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import { allBrands } from '../../../config';
import { BsXCircle } from 'react-icons/bs';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
export interface IInviteUser {
  newInviteUser: INewInviteUser;
  alertMsg: string;
}
const InviteUsersForm = ({ onClose }: { onClose: () => void }) => {
  const { inviteUsers, users } = React.useContext(HubUserContext);
  const { storeId, brandId } = React.useContext(HubContext);
  const defaultInviteUser = (): IInviteUser => ({
    alertMsg: undefined,
    newInviteUser: {
      email: '',
      spaceId: storeId,
      brandId: brandId,
      role: RoleEntitlement.User,
      brandUrl: `https://${allBrands.find((b) => b.id === brandId)?.host}`
    }
  });
  const [newUsers, setNewUsers] = React.useState<IInviteUser[]>([
    defaultInviteUser()
  ]);
  const [state, setState] = React.useState({
    isLoading: false,
    isRecResponse: false,
    response: undefined
  });
  const handleInvites = () => {
    const _newUsers = [...newUsers];
    const duplicates = getDuplicateString(
      _newUsers
        .filter((_usr) => _usr.newInviteUser.email?.length > 0)
        .map((usr) => usr.newInviteUser.email)
    );
    const existedUsersEmail = users?.map((usr) => usr.email);
    _newUsers.forEach((user) => {
      user.alertMsg = undefined;
      if (user.newInviteUser.email.length === 0) {
        return;
      } else if (existedUsersEmail.includes(user.newInviteUser.email)) {
        user.alertMsg = 'Existed email';
      } else if (duplicates.has(user.newInviteUser.email)) {
        user.alertMsg = 'Duplicate Email';
      } else if (!isValidEmailId(user.newInviteUser.email)) {
        user.alertMsg = 'Incorrect format';
      }
    });

    setNewUsers(_newUsers);
    const notValid = some(newUsers, (user) => user.alertMsg);
    if (!notValid) {
      const _users = newUsers
        .filter((_usr) => _usr.newInviteUser.email?.length > 0)
        .map((user) => user.newInviteUser);
      setState({ ...state, isLoading: true });
      inviteUsers(_users)
        .then((_invUsers) => {
          const failUserEmail = _invUsers
            .filter((usr) => usr.status === 'FAIL')
            ?.map((usr) => usr.email);

          const _filterFailUsers = _newUsers
            .filter((user) => failUserEmail.includes(user.newInviteUser?.email))
            .map((usr) => {
              return { ...usr, alertMsg: 'User account already exists.' };
            });
          const _filterSuccessUsers = _newUsers.filter(
            (user) =>
              !failUserEmail.includes(user.newInviteUser?.email) &&
              user.newInviteUser?.email?.length > 0
          );
          setNewUsers([..._filterSuccessUsers, ..._filterFailUsers]);
          setState({ ...state, isLoading: false, isRecResponse: true });
        })
        .catch(() => {
          setState({
            ...state,
            isLoading: false,
            response: 'Please try later'
          });
        });
    }
  };
  const onInputUser = (index, e) => {
    const _user: IInviteUser = {
      ...newUsers[index],
      alertMsg: undefined,
      newInviteUser: { ...newUsers[index].newInviteUser, email: e.target.value }
    };
    const _newUsers = [...newUsers];
    _newUsers[index] = _user;
    setNewUsers(_newUsers);
  };
  const onRoleChange = (index, e) => {
    const _user: IInviteUser = {
      ...newUsers[index],
      newInviteUser: {
        ...newUsers[index].newInviteUser,
        role: e.target.value
      }
    };
    const _newUsers = [...newUsers];
    _newUsers[index] = _user;
    setNewUsers(_newUsers);
  };
  const onDeleteRow = (index) => {
    const _users = [...newUsers];
    _users.splice(index, 1);
    setNewUsers(_users);
  };
  const usersInviteComponent = () => (
    <div className="modalBody">
      <h3 className="modalTitle">Invite Your Team</h3>
      <div className="overflow-auto team-user-list">
        {newUsers.map((user, i) => (
          <div key={i} className="user-block">
            <div className="form-control-email">
              <input
                value={user.newInviteUser.email}
                id="user-email"
                className={`form-control ${
                  user.alertMsg ? ' input-error' : ''
                }`}
                placeholder="Enter Email Address"
                onChange={(e) => onInputUser(i, e)}
              />
              {user.alertMsg && (
                <span className="msg-error">{user.alertMsg}</span>
              )}
            </div>
            <Form.Control
              as="select"
              name="headerFont"
              className="role-select float-right"
              value={user.newInviteUser?.role}
              onChange={(e) => {
                onRoleChange(i, e);
                console.log('Form clicked');
              }}
            >
              <option value={RoleEntitlement.Admin}>Admin</option>
              <option value={RoleEntitlement.Designer}>Designer</option>
              <option value={RoleEntitlement.Marketing}>Marketing</option>
              <option value={RoleEntitlement.User}>User</option>
            </Form.Control>
            <button
              className="btn delete-row p-0 btn-sm"
              onClick={() => onDeleteRow(i)}
            >
              <BsXCircle size={18} />
            </button>
          </div>
        ))}
        <button
          type="button"
          name="submit"
          className="btn border border-dark btn-address"
          onClick={() => {
            setNewUsers([...newUsers, defaultInviteUser()]);
          }}
        >
          Add other team members
        </button>
      </div>
      <div className="model-footer">
        <button
          className="btn btn-dark btn-round btn-modal float-right"
          onClick={() => {
            handleInvites();
            logEvent(DID_CLICK_CREATE_USER);
          }}
        >
          {state.isLoading ? (
            <Spinner size="sm" animation="border" />
          ) : (
            'Invite'
          )}
        </button>
        <button
          type="button"
          name="submit"
          className="btn border border-dark btn-round btn-modal float-right"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
  const sendSuccessCount = newUsers.filter((usr) => !usr.alertMsg)?.length;
  const sendFailCount = newUsers.filter((usr) => usr.alertMsg)?.length;
  const responseInviteComponent = () => (
    <div className="modalBody">
      <h3 className="modalTitle">
        {sendSuccessCount > 0
          ? `Invite sent to ${sendSuccessCount} team member${
              sendSuccessCount === 1 ? '' : 's'
            }`
          : 'No invites sent.'}
      </h3>
      <p>
        {sendSuccessCount > 0 &&
          sendFailCount === 0 &&
          `Your invitations have been sent successfully.`}
        {sendFailCount > 0 &&
          `Failed to send to ${sendFailCount} email address${
            sendFailCount === 1 ? '' : 'es'
          }.`}
      </p>

      <div className="user-res-title">
        <div className="form-control-email">Email</div>
        <div className="role-select-title float-right">Role</div>
      </div>
      <div className="overflow-auto team-user-list">
        {newUsers.map((user, i) => (
          <div key={i} className="user-block">
            <div
              className={`form-control-email ${
                user.alertMsg ? 'gray-font' : ''
              }`}
            >
              {user.newInviteUser.email}
            </div>
            <div
              className={`role-select float-right ${
                user.alertMsg ? 'gray-font' : ''
              }`}
            >
              {user.newInviteUser.role}
            </div>
            <button className="btn delete-row p-0 btn-sm">
              {user.alertMsg ? (
                <FaTimesCircle size={20} color="#A8A8A8" />
              ) : (
                <FaCheckCircle size={20} color="#008800" />
              )}
            </button>
          </div>
        ))}
      </div>
      <div className="model-footer">
        <button
          type="button"
          name="submit"
          className="btn btn-dark btn-round btn-modal float-right"
          onClick={onClose}
        >
          Done
        </button>
      </div>
    </div>
  );
  return (
    <PopupContainer maxWidth="650px" className="team-modal">
      {!state.isRecResponse
        ? usersInviteComponent()
        : responseInviteComponent()}
      {state.response && (
        <ConfirmationPopup
          closeLabel="Ok"
          onClose={() =>
            setState({
              ...state,
              response: undefined
            })
          }
        >
          <p className="text-semibold">{state.response}</p>
        </ConfirmationPopup>
      )}
      <style jsx global>
        {`
          .gray-font {
            color: #a8a8a8;
          }
          .user-res-title {
            display: flex;
            width: 100%;
            color: #a8a8a8;
            font-weight: 500;
          }
          .role-select-title {
            margin-left: 0%;
          }
          .team-modal .content-container {
            padding: 40px !important;
          }
          .input-error {
            color: red;
            border: 1px solid #f00;
          }
          .msg-error {
            color: red;
            font-size: 14px;
          }
          .team-modal .user-block {
            display: flex;
            margin-bottom: 12px;
          }
          .team-modal .form-control-email {
            width: 70%;
          }

          .team-modal .role-select {
            width: 25%;
            margin-left: 4%;
          }
          .team-modal .modal-dialog {
            max-width: 650px;
            margin-top: 200px;
          }
          .team-modal .modal-content {
            padding: 50px;
          }
          .team-modal .btnShare:hover {
            background-color: #f1f1f1 !important;
          }
          .team-modal .btnShareSpan {
            padding-left: 8px;
          }
          .team-modal .btn-address {
            width: 100%;
            border-radius: 100px;
            margin-bottom: 12px;
          }
          .team-modal .btn-modal {
            border-radius: 100px;
            min-width: 80px;
            margin-left: 10px;
          }
          .team-modal .modalTitle {
            letter-spacing: 0;
            width: 100%;
          }
          .team-modal .team-user-list {
            margin-top: 16px;
            height: max(50vh, 500px);
            width: 100%;
            overflow: auto;
          }
          .team-modal .modalBody {
            display: flex;
            flex-wrap: wrap;
            padding: 0 !important;
          }
          .team-modal .model-footer {
            width: 100%;
          }
          .delete-row {
            margin-left: 10px;
          }
          @media (max-height: 820px) {
            .team-modal .team-user-list {
              height: 50vh;
            }
          }
          @media (max-height: 620px) {
            .team-modal .team-user-list {
              margin-top: 6px;
              height: 40vh;
            }
            .team-modal .content-container {
              padding: 20px !important;
            }
          }
        `}
      </style>
    </PopupContainer>
  );
};

export default InviteUsersForm;
