import React from 'react';
import { formattedNumber } from '../../../../utils/number';
import { deviceIconsMapper } from './icons';
import Image from 'next/image';
type Data = Record<string, number>;

const data: Record<string, Data> = {
  gender: {
    male: 3510,
    female: 3254
  },
  age: {
    kids: 12,
    tweens: 112,
    teenagers: 234,
    'young adults': 345,
    adults: 456,
    'mature adults': 567
  },
  distance: {
    far: 2641,
    mid: 2054,
    near: 2069
  },
  emotion: {
    happy: 4602,
    surprised: 2150,
    sad: 12
  }
};

const ageLabelMapper = {
  kids: '< 8',
  tweens: '8 - 12',
  teenagers: '13 - 19',
  'young adults': '20 - 35',
  adults: '36 - 50',
  'mature adults': '> 50'
};

function DeviceAnalyticsVisitor() {
  const getTotal = (key: string) => {
    return Object.values(data[key]).reduce((acc, cur) => acc + cur, 0);
  };

  const getPercentage = (key: string, value: number) => {
    return formattedNumber(value / getTotal(key), '0.0%');
  };

  return (
    <>
      <div className="device-visitor-wrapper">
        <div className="device-visitor">
          <div className="first-col data-column">
            <div className="device-card">
              <h5 className="title">Gender</h5>
              <div className="data-wrapper horizontal">
                {Object.entries(data.gender).map(([key, value]) => (
                  <div className="data-item vertical center" key={key}>
                    <div className="label">
                      <div className="label-image">
                        <Image
                          src={deviceIconsMapper[key]}
                          height={60}
                          alt={key}
                        />
                      </div>
                      {key}
                    </div>
                    <div className="value">
                      <span>{value}</span>
                      <span>{getPercentage('gender', value)}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="device-card">
              <h5 className="title">Distance</h5>
              <div className="data-wrapper vertical">
                {Object.entries(data.distance).map(([key, value]) => (
                  <div className="data-item horizontal" key={key}>
                    <div className="label center">
                      <div className="label-image ">
                        <Image
                          src={deviceIconsMapper[key]}
                          width={50}
                          alt={key}
                        />
                      </div>
                      {key}
                    </div>
                    <div className="value">
                      <span>{value}</span>
                      <span>{getPercentage('distance', value)}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="second-col data-column">
            <div className="device-card">
              <h5 className="title">Age Group</h5>
              <div className="data-wrapper horizontal-grid">
                {Object.entries(data.age).map(([key, value]) => (
                  <div className="data-item vertical center" key={key}>
                    <div className="label">
                      <div className="label-image">
                        <Image
                          src={deviceIconsMapper[key]}
                          height={70}
                          alt={key}
                        />
                      </div>
                      {key}
                    </div>
                    <span className="sub-label">
                      {ageLabelMapper[key] || 'NA'} Years Old
                    </span>
                    <div className="value lg">
                      <span>{value}</span>
                      <span>{getPercentage('age', value)}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="device-card">
              <h5 className="title">Emotion</h5>
              <div className="data-wrapper horizontal">
                {Object.entries(data.emotion).map(([key, value]) => (
                  <div className="data-item vertical center" key={key}>
                    <div className="label">
                      <div className="label-image">
                        <Image
                          src={deviceIconsMapper[key]}
                          height={50}
                          width={50}
                          alt={key}
                        />
                      </div>
                      {key}
                    </div>
                    <div className="value lg">
                      <span>{value}</span>
                      <span>{getPercentage('emotion', value)}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .device-visitor-wrapper {
          padding: 25px;
        }
        .device-visitor {
          display: grid;
          grid-template-columns: 300px 1fr;
          gap: 25px;
        }
        .device-card {
          border-radius: 10px;
          padding: 15px;
          box-shadow: 0px 2px 6px 0px #00000014;
          display: flex;
          flex-direction: column;
        }
        .title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 20px;
          color: #808080;
        }
        .data-wrapper {
          display: flex;
          flex: 1;
        }
        .data-wrapper.horizontal {
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        .data-wrapper.horizontal-grid {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 15px;
        }
        .data-wrapper.vertical {
          flex-direction: column;
          gap: 15px;
        }
        .data-item {
          display: flex;
        }
        .data-item.vertical {
          flex-direction: column;
        }
        .sub-label {
          font-size: 12px;
          color: #a4a4a4;
          font-weight: 500;
        }
        .data-item.vertical.center {
          align-items: center;
          justify-content: space-between;
        }
        .data-item.horizontal {
          justify-content: space-between;
          flex-direction: row;
        }
        .data-item .label {
          font-size: 18px;
          font-weight: 600;
          text-transform: capitalize;
        }
        .label {
          text-align: center;
          color: #484848;
        }
        .label.horizontal {
          display: flex;
          gap: 10px;
          align-items: center;
        }
        .label.center {
          display: flex;
          gap: 10px;
          align-items: center;
          flex-direction: column;
        }
        .value {
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-size: 12px;
          align-items: center;
          font-weight: 600;
          color: #a4a4a4;
        }
        .value.lg span:first-child {
          font-size: 30px;
        }
        .value span:first-child {
          font-size: 18px;
          color: #000;
        }
        .data-column {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .second-col .device-card:nth-child(2) {
          flex: 1;
        }
      `}</style>
    </>
  );
}

export default DeviceAnalyticsVisitor;
