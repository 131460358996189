import React from 'react';
import { Spinner } from 'react-bootstrap';
import { BsFillEyeFill, BsPencil, BsFiles } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
  IMainState,
  INudgeTemplate,
  ProgressStatus
} from '../../../interfaces';
import {
  actionGetNudgeTemplatesByUserIdAsync,
  actionGetNudgeTemplateFromPayloadAsync,
  actionCreateNudgeFromTemplateAsync,
  actionEditNudgeTemplateAsync
} from '../../../redux/advisorHubAsyncActions';
import {
  DID_CLICK_BUTTON,
  DID_CLICK_USE_NUDGE_TEMPLATE,
  DID_CLICK_VIEW_BUTTON
} from '../../../utils/constants';
import { hubNavTo } from '../../utils/hubPagesRoute';
import { getAssetPath, getCoverImg, getThemeColor } from '../BrandStyle';
import Pagination from '../Common/Pagination';
import { HubContext } from '../HubContext';
import TwoColLayout from '../TwoColLayout';
import { logEvent } from './../../../analytics/index';
import { PageView } from '../../../utils/pageView';
import { searchNudges } from '../../utils/nudge';
import { paginate } from '../../utils/common';

interface CellProps {
  template: INudgeTemplate;
  onSelect: (template: INudgeTemplate) => void;
  onView: (template: INudgeTemplate, manage: boolean) => void;
  onEdit: (template: INudgeTemplate) => void;
  onCopy: (template: INudgeTemplate) => void;
  selectMode?: boolean;
}

export const DataCell = ({
  template,
  onSelect,
  onView,
  onEdit,
  onCopy,
  selectMode
}: CellProps) => {
  return (
    <>
      <tr className="tr-item">
        <td className="td-main">
          {template.title}
          <div>
            <span className="text-sm">
              {template?.description} ({template?.content?.length} content item
              {template?.content?.length > 1 ? 's' : ''})
            </span>
          </div>
        </td>
        <td className="td-btn">
          <div className="mr-2 btn-group">
            {selectMode ? (
              <button
                className="btn btn-dark btn-join-nudge"
                onClick={() => onSelect(template)}
                title="Select this template"
              >
                SELECT
              </button>
            ) : (
              <>
                <button
                  className="btn btn-outline-dark btn-icon btn-view-nudge"
                  onClick={() => onView(template, onEdit ? true : false)}
                  title="View this template"
                >
                  <BsFillEyeFill title="View this template" />
                </button>
                <button
                  className="btn btn-outline-dark btn-join-nudge"
                  onClick={() => onEdit(template)}
                  title="Edit this template"
                >
                  <BsPencil title="Edit this template" />
                </button>
                <button
                  className="btn btn-outline-dark btn-join-nudge"
                  onClick={() => onCopy(template)}
                  title="Copy this template"
                >
                  <BsFiles title="Copy this template" />
                </button>
              </>
            )}
          </div>
        </td>
      </tr>
      <tr>
        <td className="td-space" />
      </tr>
    </>
  );
};

const NudgeTemplates = ({
  selectMode,
  onBack
}: {
  selectMode?: boolean;
  onBack?: () => void;
}) => {
  const dispatch = useDispatch();
  const { brandId, brandSetup } = React.useContext(HubContext);
  const coverImg = getCoverImg(brandId, brandSetup);
  const color = getThemeColor(brandId);
  const itemsPerPage = 10;
  const [keyword, setKeyword] = React.useState('');

  const hubState = useSelector((state: IMainState) => state.clientState.hub);
  const userState = hubState?.user;

  const progress = hubState?.progressStatus;

  const [currentPage, setCurrentPage] = React.useState(1);
  const templateList = hubState?.nudgeTemplateList;
  const filteredList = searchNudges(keyword, templateList || []);

  const listToRender = paginate(filteredList, currentPage, itemsPerPage);
  const onView = (template: INudgeTemplate, manageTemplate: boolean) => {
    dispatch(actionGetNudgeTemplateFromPayloadAsync(template, manageTemplate));
    logEvent(DID_CLICK_VIEW_BUTTON, DID_CLICK_VIEW_BUTTON, {
      templateId: template.id
    });
  };

  const onSelect = (template: INudgeTemplate) => {
    logEvent(DID_CLICK_USE_NUDGE_TEMPLATE, DID_CLICK_USE_NUDGE_TEMPLATE, {
      templateId: template.id
    });
    dispatch(actionCreateNudgeFromTemplateAsync(template));
  };

  const onEdit = (template: INudgeTemplate) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: 'EDIT NUDGE TEMPLATE',
      templateId: template.id
    });
    dispatch(actionEditNudgeTemplateAsync(template));
  };

  const onCopy = (template: INudgeTemplate) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: 'COPY NUDGE TEMPLATE',
      templateId: template.id
    });
    dispatch(actionEditNudgeTemplateAsync(template, true));
  };

  const handleFetchTemplates = () => {
    dispatch(actionGetNudgeTemplatesByUserIdAsync(userState.id));
  };

  const onPageChange = (index: number) => {
    setCurrentPage(index);
  };

  const createTemplateButton = !selectMode ? (
    <button
      className="btn btn-round btn-dark"
      onClick={() => {
        hubNavTo(PageView.HUB_NUDGES_TEMPLATE_CREATE);
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: 'CREATE A TEMPLATE'
        });
      }}
    >
      CREATE A TEMPLATE
    </button>
  ) : null;

  React.useEffect(() => {
    if (userState?.id) {
      handleFetchTemplates();
    }
  }, [userState]);
  return (
    <TwoColLayout
      theme="dark"
      background={{
        mobile: coverImg?.mobile || `${getAssetPath(brandId)}/invite.jpg`,
        desktop: coverImg?.desktop || `${getAssetPath(brandId)}/invite.jpg`
      }}
      brandId={brandId}
      showNav={false}
      className="Nudge"
    >
      <div className="page-body ">
        <h1>{selectMode ? 'SELECT TEMPLATES' : 'MANAGE TEMPLATES'}</h1>

        <div className="spinner-container">
          {progress === ProgressStatus.LOADING && (
            <Spinner animation="border" />
          )}
        </div>

        {templateList?.length ? (
          <div className="input-group input-search mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search template"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value || '');
                onPageChange(1);
              }}
            />
            {keyword && (
              <button className="btn" onClick={() => setKeyword('')}>
                &times;
              </button>
            )}
          </div>
        ) : null}

        {templateList?.length ? (
          <>
            {filteredList?.length ? (
              <>
                <div className="container list-container">
                  <table className="table">
                    <tbody>
                      {listToRender.map((template) => (
                        <DataCell
                          key={template.id}
                          template={template}
                          onSelect={onSelect}
                          onView={onView}
                          onEdit={onEdit}
                          onCopy={onCopy}
                          selectMode={selectMode}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  onPageChange={(index, _) => onPageChange(index)}
                  totalItems={filteredList.length}
                  itemsPerPage={itemsPerPage}
                  buttonsPerPage={5}
                  currentPage={currentPage}
                />
              </>
            ) : (
              <p className="text-accent">
                Templates with keyword "<strong>{keyword}</strong>" not found.
              </p>
            )}
          </>
        ) : (
          <p className="text-accent">Template is empty.</p>
        )}
        <div className="btn-control">
          {onBack && (
            <button
              className="btn btn-round btn-dark"
              onClick={() => {
                onBack();
                logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                  button: 'BACK'
                });
              }}
            >
              BACK
            </button>
          )}
          {createTemplateButton}
        </div>
      </div>
      <style jsx>{`
        :global(.Nudge .layout .content-wrapper) {
          display: block !important;
        }
        .page-body {
          padding-bottom: 90px !important;
        }
        .page-body :global(.btn-group) {
          position: relative !important;
          left: auto;
          top: auto;
          right: auto;
          bottom: auto;
        }
        :global(.Nudge .text-sm) {
          font-size: 0.8em;
          color: #999;
          line-height: 1.2;
          display: block;
        }
        .spinner-container {
          height: 40px;
        }
        .btn-filter {
          color: ${color.btnPrimary};
          border-bottom: 2px solid #efefef;
          box-shadow: none;
          outline: none;
        }
        :global(.ssp) .btn-filter {
          font-size: 1.2em;
        }
        .filter-active {
          color: ${color.btnPrimary};
          border-bottom: 2px solid ${color.btnPrimary};
          opacity: 1;
        }
        .nav-tabs {
          justify-content: center;
          font-size: 1.2em;
          margin-bottom: 20px;
        }
        .btn-control .btn {
          margin: 4px;
        }
        .input-search {
          padding: 20px 15px;
          max-width: 500px;
          margin: auto;
          position: relative;
        }
        .input-search .btn {
          outline: none;
          box-shadow: none;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 30px;
          z-index: 10;
        }
        @media (max-width: 490px) {
          .btn-control .btn {
            width: 100%;
            max-width: 300px;
          }
        }
      `}</style>
    </TwoColLayout>
  );
};

export default NudgeTemplates;
