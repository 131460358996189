import React from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { logEvent } from '../../../analytics';
import { isValidPasswordAWS } from '../../../components/VirtualBoutique/Appointment/inputValidator';
import {
  DID_CHANGE_PASSWORD_SUCCESSFULLY,
  DID_CLICK_BACK_BUTTON,
  DID_FAIL_TO_CHANGE_PASSWORD,
  DID_SUBMIT_CHANGE_PASSWORD_FORM,
  DID_TOGGLE_SHOW_HIDE_PASSWORD
} from '../../../utils/constants';
import LoaderButton from '../Common/LoaderButton';
import { HubUserContext } from './HubUserContext';

function ChangePwdForm() {
  const [formInput, setFormInput] = React.useState({
    current: '',
    newPwd: '',
    confirm: ''
  });
  const [status, setStatus] = React.useState({
    loading: false,
    error: '',
    success: ''
  });
  const [showInput, setShowInput] = React.useState({
    current: false,
    newPwd: false,
    confirm: false
  });
  const { changePassword, setChangePwdView } = React.useContext(HubUserContext);
  const handleFormChange = (e) => {
    const field = e.target.id;
    setFormInput({ ...formInput, [field]: e.target.value });
  };

  const handleBackToProfile = () => {
    logEvent(DID_CLICK_BACK_BUTTON);
    setChangePwdView(false);
  };
  const onChangePwd = () => {
    logEvent(DID_SUBMIT_CHANGE_PASSWORD_FORM);
    setStatus({ ...status, loading: true });
    const { current, newPwd } = formInput;
    changePassword(current, newPwd)
      .then(() => {
        setStatus({
          loading: false,
          success: 'Change password successfully',
          error: null
        });

        logEvent(
          DID_CHANGE_PASSWORD_SUCCESSFULLY,
          DID_CHANGE_PASSWORD_SUCCESSFULLY
        );
      })
      .catch((err) => {
        setStatus({
          loading: false,
          error: err.message,
          success: ''
        });
        logEvent(DID_FAIL_TO_CHANGE_PASSWORD, DID_FAIL_TO_CHANGE_PASSWORD, {
          error: err
        });
      });
  };
  const toggleShowHidePassword = (field) => {
    const currentStatus = showInput[field];
    setShowInput({ ...showInput, [field]: !currentStatus });
    logEvent(DID_TOGGLE_SHOW_HIDE_PASSWORD, DID_TOGGLE_SHOW_HIDE_PASSWORD, {
      field,
      status: !currentStatus
    });
  };
  const getEyeIcon = (open: boolean) => {
    return open ? (
      <AiOutlineEye size={18} />
    ) : (
      <AiOutlineEyeInvisible size={18} />
    );
  };
  const invalidConfirmPassword = formInput.confirm !== formInput.newPwd;
  const isDuplicateOldPassword = formInput.current === formInput.newPwd;
  const invalidPassword =
    isDuplicateOldPassword || !isValidPasswordAWS(formInput.newPwd);
  const invalidCurrentPassword = !isValidPasswordAWS(formInput.current);

  const isSaveable =
    !invalidConfirmPassword && !invalidPassword && !invalidCurrentPassword;
  return (
    <React.Fragment>
      <div className="col-12">
        {status.error && <span className="status-error">{status.error}</span>}
        {status.success && (
          <span className="status-success">{status.success}</span>
        )}
      </div>
      <div className="col-12">
        <div className="form-group">
          <label htmlFor="current">
            <span>Current password</span>
            {invalidCurrentPassword && (
              <span className="error">
                Password must contain at least 8 characters, 1 uppercase, 1
                lowercase, 1 number
              </span>
            )}
          </label>

          <div className="with-addon">
            <input
              type={`${showInput.current ? 'text' : 'password'}`}
              value={formInput.current}
              className="form-control"
              id="current"
              placeholder="Enter current password"
              onChange={handleFormChange}
            />
            <button
              className="btn password-addon"
              name="current"
              onClick={() => toggleShowHidePassword('current')}
            >
              {getEyeIcon(!showInput.current)}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="form-group">
          <label htmlFor="newPwd">
            <span>New password</span>
            {invalidPassword && (
              <span className="error">
                {isDuplicateOldPassword
                  ? 'The new password cannot be the same as the old password'
                  : 'Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number'}
              </span>
            )}
          </label>

          <div className="with-addon">
            <input
              value={formInput.newPwd}
              type={`${showInput.newPwd ? 'text' : 'password'}`}
              className="form-control"
              id="newPwd"
              placeholder="Enter new password"
              onChange={handleFormChange}
            />
            <button
              className="btn password-addon"
              name="newPwd"
              onClick={() => toggleShowHidePassword('newPwd')}
            >
              {getEyeIcon(!showInput.newPwd)}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="form-group">
          <label htmlFor="confirm">
            <span>Confirm new password</span>
            {invalidConfirmPassword && (
              <span className="error">
                The password confirmation does not match
              </span>
            )}
          </label>

          <div className="with-addon">
            <input
              value={formInput.confirm}
              type={`${showInput.confirm ? 'text' : 'password'}`}
              className="form-control"
              id="confirm"
              placeholder="Confirm new password"
              onChange={handleFormChange}
            />
            <button
              className="btn password-addon"
              name="confirm"
              onClick={() => toggleShowHidePassword('confirm')}
            >
              {getEyeIcon(!showInput.confirm)}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 text-right">
        <LoaderButton
          isLoading={false}
          className="btn btn-dark mr-3"
          onClick={handleBackToProfile}
        >
          BACK
        </LoaderButton>
        <LoaderButton
          disabled={!isSaveable}
          isLoading={status.loading}
          className="btn btn-dark"
          onClick={onChangePwd}
        >
          SAVE
        </LoaderButton>
      </div>
      <style jsx>
        {`
          .status-error {
            text-align: center;
            padding: 10px;
            background: #f5f5f5;
            color: red;
            display: block;
          }
          .status-success {
            text-align: center;
            padding: 10px;
            background: #f5f5f5;
            color: green;
            display: block;
          }
          .with-addon {
            display: flex;
            align-items: center;
          }
          .form-group label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 50px;
          }
          .error {
            font-size: 0.56em;
            color: rgb(177, 0, 0);
          }
          @media (max-width: ${768}px) {
            .form-group label {
              flex-direction: column;
              align-items: start;
              justify-content: unset;
              padding-right: 0px;
            }
          }
        `}
      </style>
    </React.Fragment>
  );
}

export default ChangePwdForm;
