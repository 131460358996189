import React from 'react';
import { Modal } from 'react-bootstrap';
import VideoPlayers from '../../../../components/Common/VideoPlayers';
import PopupContainer from '../../Common/PopupContainer';
import { BsX } from 'react-icons/bs';

function ShowCasedVideoPreview({
  onClose,
  urls
}: {
  onClose: () => void;
  urls: string[];
}) {
  return (
    <Modal show={true} onHide={onClose} size="lg" animation={false}>
      <PopupContainer
        maxWidth="1280px"
        maxHeight="calc(100vh - 90px)"
        zIndex={9999}
        className="showcased-preview-popup"
      >
        <div className="showcased-preview">
          <button className="btn btn-round btn-close" onClick={onClose}>
            <BsX size={30} />
          </button>
          <VideoPlayers videoUrls={urls} />
        </div>
      </PopupContainer>
      <style jsx global>{`
        .showcased-preview {
          width: 95vw;
          height: calc(100vh - 90px);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .showcased-preview-popup .content-container {
          background-color: transparent !important;
        }
        .showcased-preview-popup .btn-close {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          z-index: 9999;
          color: #fff;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.5);
          border: 1px solid #fff;
        }
        .showcased-preview-popup video {
          max-height: calc(100vh - 150px);
        }
      `}</style>
    </Modal>
  );
}

export default ShowCasedVideoPreview;
