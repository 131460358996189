import React from 'react';
import { Modal } from 'react-bootstrap';
import { IconClose } from '../../advisorHub/components/Common/HubIcons';
import { meetingColor } from '../../utils/meeting';

const ShareWrapper = ({
  onClose,
  children,
  meetingUrl
}: {
  onClose?: () => void;
  children: React.ReactNode;
  meetingUrl: string;
}) => {
  const triggerRef = React.useRef(null);

  React.useEffect(() => {
    const closeOnClickOutside = (event) => {
      const isClickInside = triggerRef?.current?.contains(event.target);
      console.log(isClickInside, ' isClickInside ->', triggerRef);
      if (!isClickInside && onClose) {
        onClose();
      }
    };
    const timeout = setTimeout(() => {
      document.addEventListener('click', closeOnClickOutside);
    }, 100);
    return () => {
      document.removeEventListener('click', closeOnClickOutside);
      clearTimeout(timeout);
    };
  }, []);
  return (
    <Modal backdrop="static" centered dialogClassName="modal-share" show={true}>
      <div ref={triggerRef}>
        <div id="container">
          <div className="title-close">&nbsp;</div>
          <div className="title-popup">Invite Participant</div>
          <div className="title-close">
            <button className="btn popup-close p-0 btn-sm" onClick={onClose}>
              <IconClose color="#fff" />
            </button>
          </div>
        </div>

        <Modal.Body>
          {children}
          <div className="row header">
            <div className="col-md-12 ml-auto text-center">
              <button
                onClick={() => {
                  window.open(meetingUrl, '_blank');
                }}
                className="btn btn-round btn-light start-now start-live-session"
              >
                <b>Start Live Session</b>
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
      <style jsx global>{`
        .popup-close{
          border:1px solid #fff;
          border-radius:50px;
          margin:10px 10px 0 0;
        }
        #simpleShareMenu .list-group-item {
          color: #ccc !important;
          font-weight: normal !important;
          font-size: 13px !important;
          letter-spacing: 0.3px !important;
          line-height: 1.4 !important;
          border: none !important;
          padding: 0.75rem 1rem !important;
          border-top: 0.5px solid ${meetingColor.border} !important;
        }
        #simpleShareMenu .list-group-item:first-child {
          border-top: none !important;
        }

        #simpleShareMenu .list-group-item:hover {
          color: #fff !important;
        }
        
        .header {
          padding-top: 20px;
        }
       .modal-content {
          background: #000;
        }
       .modal-body {
          background: ${meetingColor.transparentDark};
        }
       .title-popup {
          color: #ccc !important;
          font-size: 0.8em !important;
          letter-spacing: 1px !important;
          font-weight: bold !important;
          display: "flex",
          justifyContent: "center",
          background: #fff;
          padding-top: 10px;
          padding-left: 20px;
        }
       .title-close {
          padding-right: 5px;
        }
        #container {
          padding-top: 10px;
          display: grid;                   /* (1) a grid container */
          grid-auto-flow:column;           /* (2) column layout    */
          justify-content: space-between;  /* (3) align the columns*/
        }
       .modal-share{
          width: 360px;
          max-width: 100% !important;
          border-radius: 50px;
        }
       .start-live-session{
          border-radius: 100px;
          padding-left: 40px;
          padding-right: 40px;
          background:#fff;
          font-size: 0.8em !important;
        }
       
      `}</style>
    </Modal>
  );
};

export default ShareWrapper;
