import React from 'react';
import { logEvent } from '../../../analytics';
import {
  isAllowedForName,
  isValidEmail,
  isValidName,
  isValidPhoneNumber
} from '../../../components/VirtualBoutique/Appointment/inputValidator';
import { RecordingEntitlement } from '../../../interfaces';
import {
  DID_CREATE_USER_SUCCESSFULLY,
  DID_FAIL_TO_CREATE_USER,
  DID_SUBMIT_CREATE_USER_FORM
} from '../../../utils/constants';
import { checkIfNumber } from '../../../utils/string';
import { MEET_ADMIN_USER } from '../../utils/hubEntitlements';
import LoaderButton from '../Common/LoaderButton';
import { HubContext } from '../HubContext';
import { HubUserContext, NewUser } from './HubUserContext';
import SelectCountryDialCode from './SelectCountryDialCode';

const regexUserName = /^(?=.{4,20}$)(?:[a-zA-Z\d]+(?:(?:\.|-|_)[a-zA-Z\d])*)+$/;

function CreateUserForm({ onClose }: { onClose: () => void }) {
  const [newUser, setNewUser] = React.useState<NewUser>({
    first_name: '',
    last_name: '',
    email: '',
    mobileNumber: '',
    mobilePhoneCountryCode: '',
    user_name: '',
    alias: '',
    storeId: 'placeholder',
    recordingOption: RecordingEntitlement.PRIVATE
  });

  const { createUser, stores } = React.useContext(HubUserContext);
  const { user } = React.useContext(HubContext);
  const recordingOptions = [
    RecordingEntitlement.PRIVATE,
    RecordingEntitlement.SITE,
    RecordingEntitlement.BRAND
  ];
  const [status, setStatus] = React.useState({
    loading: false,
    error: '',
    success: ''
  });
  const handlePhoneNumberKeyPress = (e) => {
    if (!checkIfNumber(e.key)) {
      e.preventDefault();
      return;
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    if (value && !checkIfNumber(value)) {
      e.preventDefault();
      return;
    }

    setNewUser({ ...newUser, ['mobileNumber']: value });
  };

  const handleFormChange = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    setNewUser({ ...newUser, [field]: value });
  };
  const handleCreateUser = () => {
    logEvent(DID_SUBMIT_CREATE_USER_FORM);
    setStatus({ loading: true, error: null, success: null });
    const _newUser = { ...newUser };
    if (!_newUser.mobileNumber) _newUser.mobilePhoneCountryCode = '';
    createUser(_newUser)
      .then(() => {
        setStatus({ loading: false, error: null, success: 'Created' });
        logEvent(DID_CREATE_USER_SUCCESSFULLY);
      })
      .catch((e) => {
        setStatus({
          loading: false,
          error: e?.message || e?.errorMessage || 'Failed to create user',
          success: null
        });
        logEvent(DID_FAIL_TO_CREATE_USER, DID_FAIL_TO_CREATE_USER, {
          error: e
        });
      });
  };

  const invalidEmail = !isValidEmail(newUser?.email);
  const invalidAlias = newUser.alias && !isValidName(newUser.alias);
  const invalidPhone =
    newUser.mobileNumber && !isValidPhoneNumber(newUser?.mobileNumber);
  const invalidFirstName =
    !isValidName(newUser.first_name) || !isAllowedForName(newUser?.first_name);
  const invalidLastName =
    !isValidName(newUser.last_name) || !isAllowedForName(newUser?.last_name);
  const invalidUserName = !regexUserName.test(newUser.user_name);
  const invalidPhoneCountry =
    newUser.mobileNumber && !newUser.mobilePhoneCountryCode;
  const invalidStoreId = !newUser.storeId || newUser.storeId === 'placeholder';
  const isSaveAble =
    !invalidAlias &&
    !invalidEmail &&
    !invalidFirstName &&
    !invalidLastName &&
    !invalidPhone &&
    !invalidUserName &&
    !invalidPhoneCountry &&
    !invalidStoreId;
  const isSuccess = status.success === 'Created';
  return (
    <div className="create-user row">
      <div className="col-12 text-left pb-4">
        <h4>NEW ACCOUNT</h4>
      </div>
      <div className="col-12">
        {status.error && (
          <div className="alert alert-danger">{status.error}</div>
        )}
        {status.success && (
          <div className="alert alert-success">{status.success}</div>
        )}
      </div>
      <div className="col-12">
        <div className="form-group">
          <label htmlFor="email">
            Space
            {invalidStoreId && <span className="input-error">Required</span>}
          </label>
          <select
            className="form-control"
            id="storeId"
            onChange={handleFormChange}
            defaultValue="placeholder"
          >
            <option value="placeholder" disabled>
              SPACE
            </option>
            {stores.map((store, index) => (
              <option key={store.id + index} value={store.id}>
                {store.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {user.userType !== MEET_ADMIN_USER && (
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="recordingOption">Recording Visibility</label>
            <select
              className="form-control"
              id="recordingOption"
              onChange={handleFormChange}
            >
              {recordingOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="user_name">
            User name{' '}
            {invalidUserName && (
              <span className="input-error">Incorrect format</span>
            )}
          </label>
          <input
            type="text"
            value={newUser?.user_name}
            className="form-control"
            id="user_name"
            placeholder="User name"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="alias">
            Alias{' '}
            {invalidAlias && (
              <span className="input-error">Incorrect format</span>
            )}
          </label>
          <input
            type="text"
            value={newUser?.alias}
            className="form-control"
            id="alias"
            placeholder="Alias"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="first_name">
            First Name
            {invalidFirstName && <span className="input-error"> Required</span>}
          </label>
          <input
            type="text"
            value={newUser?.first_name}
            className="form-control"
            id="first_name"
            placeholder="First name"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="last_name">
            Last Name
            {invalidLastName && <span className="input-error"> Required</span>}
          </label>
          <input
            type="text"
            value={newUser?.last_name}
            className="form-control"
            id="last_name"
            placeholder="Last name"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="mobilePhoneCountryCode">
            Mobile Phone
            {(invalidPhoneCountry || invalidPhone) && (
              <span className="input-error">
                {' '}
                Incorrect format / Country code is missing
              </span>
            )}
          </label>
          <div className="row">
            <div className="pr-0 col-4">
              <SelectCountryDialCode
                initalValue=""
                onSelectChange={handleFormChange}
                selectId="mobilePhoneCountryCode"
              />
            </div>
            <div className=" col-8">
              <input
                type="text"
                value={newUser?.mobileNumber}
                className="form-control"
                id="mobileNumber"
                placeholder="Mobile number"
                onChange={handlePhoneNumberChange}
                onKeyPress={handlePhoneNumberKeyPress}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="email">
            Email{' '}
            {invalidEmail && (
              <span className="input-error"> Incorrect format</span>
            )}
          </label>
          <input
            type="email"
            value={newUser?.email}
            className="form-control"
            id="email"
            placeholder="Email"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className="col-12 text-center">
        <LoaderButton
          isLoading={status.loading}
          disabled={!isSaveAble && !isSuccess}
          className="btn btn-dark"
          onClick={isSuccess ? onClose : handleCreateUser}
        >
          {isSuccess ? 'CLOSE' : 'CREATE'}
        </LoaderButton>
      </div>
      <style jsx>
        {`
          .btn.btn-dark {
            background: #000;
            border-color: #000;
          }
          .input-error {
            color: red;
            font-size: 14px;
          }
          label {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          label span {
            font-size: 0.625rem;
          }
        `}
      </style>
    </div>
  );
}

export default CreateUserForm;
