import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState, LoginStatus } from '../../../interfaces';
import { actionHubUpdateLoginStatus } from '../../../redux/actions';
import { actionLoginAsync } from '../../../redux/advisorHubAsyncActions';
import { DID_CLICK_LOGIN_BUTTON } from '../../../utils/constants';
import { getThemeColor, mobileBreakPoint } from '../BrandStyle';
import { logEvent } from './../../../analytics/index';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { getSspControls, isOnboardingBrand } from '../../../utils/window';
import { clearCognitoTokens } from '../../clientSideServices/login';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';

interface Props {
  brandId: string;
  hideInvitationText?: boolean;
}

export enum SigninChallenge {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
}

const LoginForm = ({ brandId, hideInvitationText=false }: Props) => {
  const router = useRouter();
  const isInvitationLink = !hideInvitationText && router.asPath.includes('?invitation=true');

  const color = getThemeColor(brandId);
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [userNameError, setUserNameError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [isProvideCredentials, setIsProvideCredentials] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const sspControls = getSspControls();
    setIsProvideCredentials(sspControls && sspControls.provideCredentials?.());
    setIsLoading(false);
  }, []);

  const dispatch = useDispatch();
  const login = async (username: string, password: string) => {
    dispatch(actionLoginAsync(username, password));
  };

  const [typePwdInput, setTypePwdInput] = React.useState<'text' | 'password'>(
    'password'
  );
  const handleSubmit = async () => {
    if (userName && password) {
      const currenUser = await Auth.currentUserInfo().catch(() => {
        return null;
      });

      if (currenUser) {
        clearCognitoTokens();
      }
      login(userName, password);
    }

    if (!userName) {
      setUserNameError(true);
    } else {
      setUserNameError(false);
    }

    if (!password) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    logEvent(DID_CLICK_LOGIN_BUTTON, DID_CLICK_LOGIN_BUTTON);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserName(value);
    if (value === '') {
      setUserNameError(true);
    } else {
      setUserNameError(false);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    if (value === '') {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const hub = useSelector((state: IMainState) => state.clientState.hub) || {};
  const { loginStatus, loginErrorMessage } = hub;
  const handleNavToChangePwd = () => {
    dispatch(actionHubUpdateLoginStatus(LoginStatus.RESET_PASSWORD));
  };

  const userNameInput = React.useMemo(
    () =>
      isOnboardingBrand()
        ? {
            lable: 'Email',
            placeholder: 'Enter your email'
          }
        : {
            lable: 'User name',
            placeholder: 'Enter your user name'
          },
    []
  );

  const getEyeIcon = () => {
    if (!password.length) return null;
    return typePwdInput === 'text' ? (
      <AiOutlineEye
        cursor="pointer"
        fill="#343434"
        size={20}
        onClick={() => setTypePwdInput('password')}
      />
    ) : (
      <AiOutlineEyeInvisible
        cursor="pointer"
        fill="#343434"
        size={20}
        onClick={() => setTypePwdInput('text')}
      />
    );
  };
  if (isLoading || isProvideCredentials)
    return (
      <div className="loading-container h-100 d-flex align-items-center justify-content-center flex-column">
        <Spinner animation="border" />
        {loginStatus === LoginStatus.LOGIN_FAILED && (
          <div className="mt-2 alert alert-danger">{loginErrorMessage}</div>
        )}
      </div>
    );
  return (
    <div className="page-body LoginForm">
      <div className="container">
        <h1>
          WELCOME
          <i className="separator" />
        </h1>
        <p className="accent">
          {isInvitationLink ? (
            <>
              Please log in first using the temporary credentials you received,
              then proceed to set up your account.
            </>
          ) : (
            <>Please sign in to your account</>
          )}
        </p>
        <div className="form-container">
          {loginStatus === LoginStatus.LOGIN_FAILED && (
            <div className="alert alert-danger">{loginErrorMessage}</div>
          )}
          <form>
            <div className={`form-group text-group`}>
              <label htmlFor="username">
                {userNameInput.lable}
                {userNameError && (
                  <span className="input-error">- Required</span>
                )}
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                placeholder={userNameInput.placeholder}
                value={userName}
                onChange={handleNameChange}
              />
            </div>
            <div className={`form-group text-group pwd`}>
              <label htmlFor="password">
                {isInvitationLink ? 'Temporary Password' : 'Password'}
                {passwordError && (
                  <span className="input-error">- Required</span>
                )}
              </label>
              <div className="d-flex align-items-center mb-0">
                <input
                  type={typePwdInput}
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  autoComplete="userName"
                />
                {getEyeIcon()}
              </div>
            </div>
            <div className="text-right">
              <span className="reset-pwd" onClick={handleNavToChangePwd}>
                Reset Password
              </span>
            </div>
          </form>
          <div className="form-group">
            <button className="btn btn-dark" onClick={handleSubmit}>
              {loginStatus === LoginStatus.LOGGING_IN ? (
                <Spinner animation="border" size="sm" />
              ) : (
                'SIGN IN'
              )}
            </button>
          </div>
        </div>
      </div>
      <style jsx>{`
        h1 {
          color: #fff;
          text-align: left !important;
        }
        h1 .separator {
          margin: 10px 0 !important;
        }
        p {
          color: #fff;
        }
        .content {
          padding: 0 30px;
        }

        .accent {
          text-align: left;
        }
        .container {
          max-width: 500px;
        }

        .form-container {
          margin: auto;
        }
        :global(.ssp) .form-container {
          max-width: 700px;
        }
        .text-group {
          text-align: left;
          border: 1px solid #dedede;
          background: #fff;
          padding: 0 10px 5px;
          margin-bottom: 20px;
        }
        .pwd {
          margin-bottom: 0;
        }
        .pwd + .text-right {
          margin-bottom: 20px;
        }
        .reset-pwd {
          color: #fff;
          cursor: pointer;
        }
        .reset-pwd:hover {
          text-decoration: underline;
        }
        .btn {
          border-radius: 0;
          width: 100%;
          padding: 13px 0;
        }
        input#password:-webkit-autofill,
        input#password:-webkit-autofill:hover,
        input#password:-webkit-autofill:focus,
        input#password:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        :global(.ssp) .btn {
          font-size: 1.4em;
        }
        .text-group label {
          padding: 0;
          margin: 0;
          font-size: 0.6em;
          font-weight: 400;
          letter-spacing: 1px;
          color: ${color.accent};
        }
        :global(.ssp) .text-group label {
          font-size: 0.8em;
        }
        .text-group input {
          box-shadow: none;
          outline: none;
          border: none;
          padding: 0;
          margin: 0;
          height: auto;
        }

        .input-error {
          color: red;
        }
        :global(.kbActive) h1,
        :global(.kbActive) p {
          display: none;
        }
        @media (min-width: ${mobileBreakPoint}px) {
          h1 {
            color: #000;
          }
          p {
            color: ${color.accent};
            margin-bottom: 30px;
          }
          .reset-pwd {
            color: #000;
          }
        }
      `}</style>
    </div>
  );
};

export default LoginForm;
