import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { cloneDeep, max } from 'lodash';
import { ApexOptions } from 'apexcharts';
import { ITab } from '../../Common/Tabs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoIcon } from '../../Common/HubIcons';
import {
  getProductsInsightsFromDevice,
  getProductsInsightsFromSpace
} from './service';
import { formattedNumber } from '../../../../utils/number';
import { mapAndSortViewsDensity } from './helper';
import { IDevice } from '../../../../interfaces';
import { ITableColumn } from '../../Common/Table';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

interface Props {
  product: any;
  timeZone: string;
  startDate: string;
  endDate: string;
  storeId: string;
  range: number;
  device?: IDevice;
  isInsightsSpace?: boolean;
}

const DeviceAnalyticsProductsDetails = ({
  product,
  timeZone,
  startDate,
  endDate,
  storeId,
  range,
  device,
  isInsightsSpace
}: Props) => {
  const overviewTabs = ['views', 'average time spent (min)', 'QR Scans'];
  const [currentOverviewTab, setCurrentOverviewTab] = useState<ITab>({
    id: overviewTabs[0],
    label: overviewTabs[0]
  });
  const [data, setData] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    if (!product?.id) return;
    setLoading(true);
    if (device?.deviceId && !isInsightsSpace) {
      getProductsInsightsFromDevice({
        startDate,
        endDate,
        timeZone,
        productId: product.id,
        storeId,
        deviceId: device?.deviceId
      })
        .then((res) => {
          setData(res);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (isInsightsSpace) {
      getProductsInsightsFromSpace({
        startDate,
        endDate,
        timeZone,
        productId: product.id
      })
        .then((res) => {
          setData(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [startDate, endDate, device?.deviceId, product?.id, storeId]);

  const chartAreaOptions: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false
      }
    },
    colors: ['#B63B3B', '#E89B55', '#6AC773', '#203DA5', '#9039B7', '#878787'],
    stroke: {
      width: 1,
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [
        '2 Oct',
        '6 Oct',
        '10 Oct',
        '14 Oct',
        '18 Oct',
        '22 Oct',
        '26 Oct',
        '30 Oct'
      ]
    },
    yaxis: {
      opposite: true
    }
  };

  const chartAreaSeries = [
    {
      name: ' IWC Rodeo Drive, Beverly Hills',
      data: [31, 40, 28, 51, 42, 109, 100, 12]
    },
    {
      name: 'IWC Costa Mesa',
      data: [11, 32, 45, 32, 34, 52, 41, 34]
    },
    {
      name: 'IWC Washington',
      data: [50, 8, 12, 18, 16, 30, 33, 34]
    }
  ];

  const generateData = (count, yrange) => {
    let i = 0;
    const series = [];
    while (i < count) {
      const x = (i + 1).toString();
      const y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y
      });
      i++;
    }
    return series;
  };

  const dataColumns: ITableColumn[] = [
    {
      key: 'device',
      title: 'Devices',
      align: 'left'
    },
    {
      key: 'view',
      title: 'Views',
      align: 'center'
    },
    {
      key: 'ats',
      title: 'Average Time Spent (Min)',
      align: 'center'
    },
    {
      key: 'share',
      title: 'QR Scans',
      align: 'center'
    }
  ];

  const onRowCheck = (row) => {
    const index = countriesData.findIndex((r) => r.id === row.id);
    if (index !== -1) {
      const temp = cloneDeep(countriesData);
      temp[index].checked = !temp[index].checked;
      setCountriesData(temp);
    }
  };

  const hourlyViewSeries = useMemo(() => {
    const density = mapAndSortViewsDensity(
      data?.viewsDensity,
      startDate,
      endDate,
      range
    );
    return density;
  }, [data?.viewsDensity, startDate, endDate]);

  const maxHourlyView = useMemo(() => {
    const maxHour = max(
      hourlyViewSeries?.map((hour) => max(hour?.data)) || [0]
    );
    return maxHour + (10 - (maxHour % 10));
  }, [hourlyViewSeries]);

  const hourlyViewOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: '#FAF1FD',
              name: 'low'
            },
            {
              from: maxHourlyView,
              to: maxHourlyView,
              color: '#502B5A',
              name: 'high'
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#AE3BCD'],
    xaxis: {
      position: 'top',
      labels: {
        formatter: (value) => `${parseInt(value) - 1}`,
        style: {
          colors: ['#615E83'],
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#615E83'],
          fontSize: '14px',
          cssClass: 'heatmap-yaxis-label'
        }
      }
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const time = `0${dataPointIndex}:00`.slice(-5);
        const label = `${w.globals.seriesNames[seriesIndex]}, ${time}`;
        return `
        <div class="custom-tooltip">
          <div class="tooltip-label">${label}</div>
          <div class="tooltip-value">${value}</div>
        </div>
      `;
      },
      style: {
        fontSize: '12px',
        fontFamily: undefined
      }
    }
  };

  const heatmapHeight = useMemo(() => {
    const min = 500;
    const range = hourlyViewSeries?.length || 1;
    return Math.max(min, range * 20);
  }, [hourlyViewSeries]);

  const legendNumbers = useMemo(() => {
    const numbers = [];
    for (let i = 0; i <= maxHourlyView; i += maxHourlyView / 5) {
      numbers.push(
        <span key={i} style={{ left: `${(i / maxHourlyView) * 100}` }}>
          {formattedNumber(i)}
        </span>
      );
    }
    return numbers.reverse();
  }, [maxHourlyView]);

  const xAxisNumbers = useMemo(() => {
    return new Array(24)
      .fill(0)
      .map((_, index) => <span key={index}>{index}</span>);
  }, []);

  return (
    <>
      <div className="analytics-main" data-isloading={loading}>
        {/* <div className="overview-linechart card-section">
          <Tabs
            dot={true}
            onSelectTab={setCurrentOverviewTab}
            tabs={overviewTabs.map((t) => ({ id: t, label: t }))}
            active={currentOverviewTab}
          />
          <Chart
            options={chartAreaOptions}
            series={chartAreaSeries}
            type="area"
            height="350"
          />
        </div> */}
        {/* <div className="overview-table card-section">
          <SortTable
            rowCheckbox
            onCheckboxClick={onRowCheck}
            columns={dataColumns}
            sorts={['view', 'ats', 'share', 'device']}
            data={countriesData}
          />
        </div> */}
        <div className="overview-heatmap">
          <div className="card-section">
            <div className="card-title">
              <label>Global Hourly Views Density (UTC +8)</label>
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={(props) => (
                  <Tooltip
                    id="tooltip"
                    {...props}
                    style={{
                      color: '#fff',
                      fontSize: 12,
                      borderRadius: 5,
                      textAlign: 'center',
                      ...props.style
                    }}
                  >
                    This heatmap represents hourly views from audiences base on
                    the LENS' localisation selection, aggregated and visualized
                    for each hour of the day. Please note that the data is
                    normalized and displayed in your defined timezone.
                  </Tooltip>
                )}
              >
                <div style={{ display: 'flex' }}>
                  <InfoIcon />
                </div>
              </OverlayTrigger>
            </div>
            <div className="heatmap-content">
              <div className="heatmap-chart">
                <div className="heatmap-wrapper">
                  <Chart
                    options={hourlyViewOptions}
                    series={hourlyViewSeries}
                    height={heatmapHeight}
                    type="heatmap"
                  />
                </div>
                <div className="custom-heatmap-xasis">{xAxisNumbers}</div>
              </div>
              <div className="custom-heatmap-legend">
                <div className="legend-bar"></div>
                <div className="legend-number">{legendNumbers}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>

      <style jsx>{`
        .analytics-main {
          overflow: auto;
          max-height: calc(100vh - 200px);
          padding: 10px;
        }
        .analytics-main ::global(.device-visitor-wrapper) {
          padding: 0 !important;
        }
        .analytics-main[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }

        .analytics-main[data-isloading='true'] .overlay {
          display: flex;
        }
        :global(.overview-linechart .tab) {
          font-size: 14px !important;
          padding: 7px 10px !important;
          text-transform: capitalize;
        }
        :global(.overview-linechart .analytics-tabs) {
          border-bottom: none;
        }
        .card-section {
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
          padding: 20px;
          margin-bottom: 20px;
        }
        .card-section label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          font-weight: 400;
        }
        :global(.overview-heatmap .custom-tooltip) {
          padding: 10px;
          color: #4c2d5a;
        }
        .card-title {
          display: flex;
        }
        :global(.card-section .card-title svg) {
          width: 18px;
          margin-left: 10px;
        }
        :global(.overview-heatmap .tooltip-label) {
          font-size: 12px;
        }
        :global(.overview-heatmap .tooltip-value) {
          font-weight: 500;
          font-size: 18px;
        }
        :global(.tooltip-inner) {
          background-color: #fff;
          box-shadow: 0px 2.679px 6.698px 0px rgba(0, 0, 0, 0.25);
          color: #484848;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          padding: 0.5rem 0.75rem;
          max-width: 230px;
        }
        :global(.arrow) {
          display: none !important;
        }
        .custom-heatmap-legend {
          padding-top: 30px;
          display: flex;
        }
        :global(
            .overview-heatmap
              .apexcharts-legend.apexcharts-align-center.apx-legend-position-top
          ) {
          display: none;
        }
        .custom-heatmap-legend .legend-bar {
          width: 20px;
          height: 90%;
          background: linear-gradient(
            180deg,
            #502b5a 0%,
            #ae3bcd 50%,
            #faf1fd 100%
          );
          flex-shrink: 0;
        }
        .heatmap-chart :global(.apexcharts-xaxis) {
          opacity: 0;
        }

        .heatmap-content {
          display: flex;
          width: 100%;
          overflow: hidden;
          gap: 15px;
        }
        .heatmap-chart {
          flex: 1;
        }
        .heatmap-content .heatmap-wrapper {
          height: 520px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 1;
          position: relative;
        }

        .custom-heatmap-xasis {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-size: 12px;
          width: 100%;
          padding: 0px 10px 0px 73px;
          font-weight: 500;
          margin-top: -20px;
          background: #fff;
          z-index: 2;
          position: relative;
        }

        .custom-heatmap-legend .legend-number {
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 10px;
          font-size: 12px;
        }

        .heatmap-content .heatmap-wrapper::-webkit-scrollbar {
          width: 5px;
        }

        .heatmap-content .heatmap-wrapper::-webkit-scrollbar-thumb {
          background: #502b5a;
          border: 3px solid #502b5a;
          border-radius: 5px;
        }
      `}</style>
    </>
  );
};

export default DeviceAnalyticsProductsDetails;
