import React from 'react';
import { IoIosMenu } from 'react-icons/io';
import {
  SortEnd,
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import { changeContentOrder } from '../../../utils/nudge';
import { FaRegTrashAlt } from 'react-icons/fa';
import { decodeS3FileName } from '../../../clientSideServices/aws';

function VideoItem({
  videoUrl,
  onRemove
}: {
  videoUrl: string;
  onRemove: (url: string) => void;
}) {
  const vidName = React.useMemo(() => {
    const urlComponent = new URL(videoUrl);
    const decoded = decodeS3FileName(
      videoUrl.replace(`${urlComponent.origin}/`, '')
    );
    return decoded.split('/').pop();
  }, [videoUrl]);

  return (
    <div className="flex-grow-1 d-flex align-items-center gap-1">
      <div className="video-wrapper">
        <video src={videoUrl} width="100" height="100" />
      </div>
      <span>{vidName || videoUrl}</span>
      <button className="btn btn-rounded" onClick={() => onRemove(videoUrl)}>
        <FaRegTrashAlt size={15} />
      </button>
      <style jsx>{`
        .video-wrapper {
          width: 100px;
          height: calc(100px * 0.5625);
          flex-basis: 100px;
          flex-shrink: 0;
        }
        .video-wrapper video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        span {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .gap-1 {
          gap: 1rem;
          overflow: hidden;
        }
      `}</style>
    </div>
  );
}

function ShowCasedVideo({
  videoUrls,
  onChange
}: {
  videoUrls: string[];
  onChange: (videoUrls: string[]) => void;
}) {
  const onRemove = (url: string) => {
    const newUrls = videoUrls.filter((v) => v !== url);
    onChange(newUrls);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newOrder = changeContentOrder(videoUrls, oldIndex, newIndex);
    onChange(newOrder);
  };

  const SortableItem = SortableElement(({ value }) => (
    <li className="d-flex align-items-center w-full showcased-video-item">
      <span className="showcased-video-index">{value + 1}.</span>
      <VideoItem videoUrl={videoUrls[value]} onRemove={onRemove} />
      <Handle />
    </li>
  ));
  const Handle = SortableHandle(() => (
    <button className="btn btn-handler">
      <IoIosMenu size={20} />
    </button>
  ));

  const SortableList = SortableContainer(({ items }: { items: string[] }) => {
    return (
      <ul className="showcased-video-list">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            {...({ value: index } as any)}
          />
        ))}
      </ul>
    );
  });

  return (
    <React.Fragment>
      <SortableList
        helperClass="showcased-video-list"
        onSortEnd={onSortEnd}
        lockAxis="y"
        useDragHandle
        {...{ items: videoUrls }}
      />
      <style jsx global>{`
        .showcased-video-list {
          list-style: none;
          padding: 0;
          margin: 0;
          flex: 1;
          padding: 0;
          margin: 0;
          overflow-y: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 99999;
        }

        ul.showcased-video-list li:nth-child(odd) {
          background-color: #f7f7f750;
        }
        .showcased-video-item {
          gap: 5px;
          font-size: 14px;
          font-weight: 500;
          padding: 5px;
        }
        .showcased-video-index {
          width: 20px;
        }
      `}</style>
    </React.Fragment>
  );
}

export default ShowCasedVideo;
