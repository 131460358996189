import React from 'react';
import { BsX } from 'react-icons/bs';
import { FacebookConnectionStatus } from '../../../utils/facebook';
import { FacebookIcon, SuccessIcon } from '../../Common/Icons';
import { AiOutlineUserSwitch } from 'react-icons/ai';
interface IProps {
  open: boolean;
  onClose: () => void;
  type: FacebookConnectionStatus;
  retryUrl: string;
}

const Oops = ({ color = '#8F6599' }) => {
  return (
    <>
      <div className="oops">
        <span className="oops-text">Oops!</span>
        <span>
          Something
          <br />
          went wrong
        </span>
      </div>
      <style jsx>
        {`
          .oops {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          span {
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 1.5rem;
            color: #636363;
          }
          span:nth-child(1) {
            color: ${color};
            -webkit-text-fill-color: ${color};
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: ${color};
            text-transform: uppercase;
            letter-spacing: 2px;
            text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
            font-size: 3rem;
          }
        `}
      </style>
    </>
  );
};

const mapStatusIcon = {
  [FacebookConnectionStatus.SUCCESS]: <SuccessIcon />,
  [FacebookConnectionStatus.ALREADY_CONNECTED]: <SuccessIcon />,
  [FacebookConnectionStatus.ERROR]: <Oops />,
  [FacebookConnectionStatus.NOT_AUTHORIZED]: <Oops />,
  [FacebookConnectionStatus.FORCE_SWITCH]: (
    <AiOutlineUserSwitch size={70} color="#3b5998" />
  )
};
const mapStatusText = {
  [FacebookConnectionStatus.SUCCESS]: {
    title: null,
    content: 'You have successfully connected your account'
  },
  [FacebookConnectionStatus.ALREADY_CONNECTED]: {
    title: null,
    content: 'You have already connected your account'
  },
  [FacebookConnectionStatus.ERROR]: {
    title: 'Connect Unsuccessful',
    content: 'There was a problem with your account.'
  },
  [FacebookConnectionStatus.NOT_AUTHORIZED]: {
    title: 'Connect Unsuccessful',
    content: 'There was some scopes are not authorized.'
  },
  [FacebookConnectionStatus.FORCE_SWITCH]: {
    title: 'You need to switch to your personal profile to continue.',
    content: 'Please help to click button try again after finish that process.'
  }
};
function FacebookNotification(props: IProps) {
  const isConnectError =
    props.type !== FacebookConnectionStatus.SUCCESS &&
    props.type !== FacebookConnectionStatus.ALREADY_CONNECTED;
  const isForceSwitch = props.type === FacebookConnectionStatus.FORCE_SWITCH;
  const statusText = mapStatusText[props.type] || {
    title: null,
    content: 'There was a problem.'
  };
  const [readyToRetry, setReadyToRetry] = React.useState(false);
  const statusIcon = mapStatusIcon[props.type];
  const tryAgainText = isForceSwitch ? 'Thank you!' : 'Please try again';
  return (
    <>
      <div className="FacebookNotification">
        <div className="backdrop"></div>

        <div className="notification">
          <div className="close">
            <button className="btn btn-close" onClick={props.onClose}>
              <BsX size={30} color="#000" />
            </button>
          </div>
          <div className="title">
            <span>
              <FacebookIcon />
            </span>
            <span className="text">Connect to your facebook account</span>
          </div>
          <div className="status-icon">{statusIcon}</div>
          <div className="status-text">
            {statusText?.title && (
              <span className="s-title">{statusText?.title}</span>
            )}
            <br />
            {statusText?.content && (
              <span className="s-content">{statusText?.content}</span>
            )}
            <br />
            {isConnectError && tryAgainText}
          </div>
          {isConnectError && (
            <div className="try-again">
              <iframe
                allow="web-share"
                src={props.retryUrl}
                onLoad={() => setReadyToRetry(true)}
              />
            </div>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .FacebookNotification {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            align-items: center;
            justify-content: center;
            display: ${props.open ? 'flex' : 'none'};
          }
          .backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background: rgba(0, 0, 0, 0.5);
          }
          .notification {
            position: relative;
            background: white;
            z-index: 11;
            padding: 60px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            text-align: center;
          }

          .title ::global(svg) {
            transform: scale(2);
          }

          .try-again {
            display: ${readyToRetry ? 'block' : 'none'};
          }
          .try-again iframe {
            border-radius: 50px;
            width: 110px;
            border-width: 0px;
            height: 35px;
          }
          .title {
            display: flex;
            gap: 20px;
          }
          .title .text {
            font-weight: 500;
          }
          .status-text {
            font-size: 0.9em;
            font-weight: 500;
          }
          .status-text .s-title {
            font-size: 1.2em;
            font-weight: 600;
          }
          .close {
            position: absolute;
            top: 0;
            right: 0;
          }
          .btn.btn-close {
            background-color: transparent;
            border: none;
          }
          .btn.btn-round {
            min-width: 100px;
          }
        `}
      </style>
    </>
  );
}

export default FacebookNotification;
