import React, { ReactNode } from 'react';
import { logEvent } from '../../../analytics';
import { DID_CHANGE_CHAT_TAB } from '../../../utils/constants';
import ChatFooter from './ChatFooter';
import { Tabs } from './ChatListWrapper';
import ChatMessages from './ChatMessages';
import { HubChatContext } from './HubChatContext';
import PageViewInsights from './PageInsights/PageViewInsights';

const MobileChatDetails = () => {
  const { conversations, currentConversation } =
    React.useContext(HubChatContext);
  const details = conversations.find(
    (conversation) => conversation?.conversationId === currentConversation
  );

  const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.CHAT);
  let tabContent: ReactNode = null;

  switch (activeTab) {
    case Tabs.CHAT:
      tabContent = (
        <>
          <ChatMessages conversationId={details?.conversationId} />
          <ChatFooter conversationId={details?.conversationId} />
        </>
      );
      break;
    case Tabs.INSIGHTS:
      tabContent = <PageViewInsights />;
  }

  return (
    <div className="chat-details-wrapper">
      {details ? (
        <>
          <div className="chat-header">
            <div className="tabs-wrapper">
              <div
                onClick={() => {
                  logEvent(DID_CHANGE_CHAT_TAB, DID_CHANGE_CHAT_TAB, {
                    activeTab
                  });
                  setActiveTab(Tabs.CHAT);
                }}
                className={`tab-item ${activeTab === Tabs.CHAT && 'active'}`}
              >
                Chat
              </div>
              <div
                onClick={() => {
                  logEvent(DID_CHANGE_CHAT_TAB, DID_CHANGE_CHAT_TAB, {
                    activeTab
                  });
                  setActiveTab(Tabs.INSIGHTS);
                }}
                className={`tab-item ${
                  activeTab === Tabs.INSIGHTS && 'active'
                }`}
              >
                Insights
              </div>
            </div>
          </div>
          {tabContent}
        </>
      ) : (
        <div className="empty-message">
          <h1>No messages</h1>
        </div>
      )}

      <style jsx>{`
        .chat-details-wrapper {
          border: 1px solid #c4c4c4;
          border-top: none;
          height: 70vh;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          position: relative;
        }

        :global(.chat-actions button) {
          border: none;
          background: transparent;
        }

        .tabs {
          padding: 0.844em 1em;
          border-bottom: 1px solid #c4c4c4;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .tab-item {
          width: 50%;
          padding: 10px 20px;
          text-align: center;
          color: #040404;
          cursor: pointer;
        }
        .tab-item.active {
          background: #000000;
          color: #ffffff;
          border-radius: 55px;
        }

        .empty-message {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .chat-header {
          padding: 15px;
          border-bottom: 1px solid #c4c4c4;
        }

        .tabs-wrapper {
          background: #efefef;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 55px;
        }

        .chat-header-left {
          display: flex;
          align-items: center;
          max-width: 80%;
        }

        .header-back-button {
          display: none;
        }

        .join-btn {
          padding-left: 20px;
          padding-right: 20px;
        }

        .customer-name {
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          text-transform: uppercase;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .header-back-button btn {
          display: none;
        }

        @media (max-width: 480px) {
          .chat-details-wrapper {
            border-right: none;
            border-left: none;
            border-bottom: none;
            height: 0;
          }

          .sessions-container {
            padding-top: 0;
            padding-bottom: 20px;
          }

          .customer-name {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
          }

          .header-back-button {
            display: initial;
          }

          .chat-header-left {
            width: 90%;
            max-width: 90%;
          }

          :global(.join-btn) {
            font-size: 12px;
            line-height: 15px;
            padding: 6px 8px;
          }

          .join-btn span {
            display: none;
          }

          :global(.btn-dark svg) {
            margin-right: 0;
          }
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          .chat-details-wrapper {
            height: 72vh;
          }
        }
      `}</style>
    </div>
  );
};

export default MobileChatDetails;
