import React, { useContext } from 'react';
import { getCurrentUserInfo } from '../../../../clientSideServices/users';
import { PageView } from '../../../../utils/pageView';
import { HubContext } from '../../HubContext';
import BrandingSettingTour from './BrandingSettingTour';

const BrandingTour = () => {
  const [showDesignerTour, setShowDesignerTour] = React.useState<boolean>();
  const { setGuidedTour } = useContext(HubContext) || {};

  React.useEffect(() => {
    getCurrentUserInfo().then((userInfo) => {
      if (userInfo?.attributes?.['custom:gt_branding'] !== 'skipped') {
        setShowDesignerTour(true);
        setGuidedTour(PageView.HUB_BRANDING);
      }
    });
  }, []);
  return showDesignerTour ? <BrandingSettingTour onClose={() => setGuidedTour('')}/> : null;
};

export default BrandingTour;
