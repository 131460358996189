import React from 'react';
import { FaRegImages } from 'react-icons/fa';
import { HubChatContext } from './HubChatContext';

const StoryShareButton = ({ onClick }) => {
  const { isMobile } = React.useContext(HubChatContext);
  return (
    <div className="action-btn-wrapper">
      <button className="btn action-btn" onClick={onClick}>
        <FaRegImages size={isMobile ? `1.2rem` : `1.4rem`} />
      </button>
      <span className="action-btn-label">Share Story</span>
    </div>
  );
};

export default StoryShareButton;
