import React from 'react';
import PopupContainer from '../../Common/PopupContainer';
import SBPreview from '../../StoryBook/Preview';
import { mapSceneToStorybookPage } from '../../../../mappers/storybook';
import { ILibraryScene, IStorybook } from '../../../../interfaces';
import { v4 as uuid } from 'uuid';
import { Modal } from 'react-bootstrap';
function ShowcasedScenePreview({
  onClose,
  scenes
}: {
  onClose: () => void;
  scenes: ILibraryScene[];
}) {
  const storybook: IStorybook = React.useMemo(
    () => ({
      id: uuid(),
      pages: scenes.map(mapSceneToStorybookPage),
      title: 'Showcased Scenes Preview'
    }),
    [scenes]
  );
  return (
    <Modal show={true} onHide={onClose} size="xl" centered>
      <PopupContainer
        width="95%"
        maxWidth="2400px"
        zIndex={99999}
        className="showcased-preview-popup"
      >
        <div className="popup-section  popup-body w-100">
          <SBPreview
            storybook={storybook}
            onClose={onClose}
            previewMode="microsite"
            hideInfo
          />
        </div>
        <style jsx global>
          {`
            .popup-body {
              height: calc(100vh - 80px);
            }
            #polonto-root > div > div + div > .bp5-navbar,
            .polotno-side-panel > div + div + div + div {
              display: none !important;
            }
            .btn-round {
              border-radius: 100px;
              border: 1px solid rgba(0, 0, 0, 0.5);
              font-weight: 500;
            }
            .btn-dark {
              background: #000;
            }
          `}
        </style>
      </PopupContainer>
    </Modal>
  );
}

export default ShowcasedScenePreview;
