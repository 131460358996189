import React from 'react';
import { defaultChatSnippet } from '../../config';

interface SnippetMessage {
  label: string;
  message: string;
}

const ChatSnippet = ({
  onSelect,
  language = 'en',
  onClose,
  currentMessage
}: {
  onSelect: (message: SnippetMessage) => void;
  language?: string;
  onClose?: () => void;
  currentMessage?: string;
}) => {
  //TODO:load snippet based on store
  const snippets = defaultChatSnippet[language];

  return (
    <div className="list-group text-left" onMouseLeave={onClose}>
      {snippets.map((s) => (
        <button
          type="button"
          className="list-group-item list-group-item-action"
          aria-current="true"
          key={s.label}
          onClick={() => {
            onSelect(s);
            onClose();
          }}
        >
          {s.label}
        </button>
      ))}
      <div className="btn-group">
        {currentMessage && (
          <button
            className="btn"
            onClick={() => {
              onSelect({ label: 'Clear', message: '' });
              onClose();
            }}
          >
            <span style={{ color: 'red' }}>Clear</span>
          </button>
        )}
        <button
          className="btn"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </button>
      </div>
      <style jsx>{`
        button {
          font-size: 0.9em;
        }
        .btn-group {
          background: #efefef;
        }
        .list-group {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          overflow: hidden;
        }
      `}</style>
    </div>
  );
};

export default ChatSnippet;
