import { useQuery } from 'react-query';
import { getMeetingRoomsByUserId } from '../../clientSideServices/session';

export const useUserMeetingRooms = (userId: string) => {
  return useQuery(
    ['user-meeting-rooms', userId],
    () => getMeetingRoomsByUserId(userId),
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true
    }
  );
};
