import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { logEvent } from '../../../analytics';
import { setupBrand } from '../../../clientSideServices/brand';
import {
  DID_CLICK_BRANDING_REMINDER,
  DID_CLICK_BUTTON
} from '../../../utils/constants';
import { PageView } from '../../../utils/pageView';
import { hubNavTo } from '../../utils/hubPagesRoute';
import { mapAccountBrandToUpdatePayload } from '../../utils/mapper';
import { HubContext } from '../HubContext';

const BrandSetupReminder = () => {
  const { account, brandSetup, setBrandSetup } = useContext(HubContext);
  const updateBrandSetupStep = () => {
    const updatePayload = mapAccountBrandToUpdatePayload(
      account,
      brandSetup,
      2
    );
    setBrandSetup((prev) => ({ ...prev, step: 2 }));
    setupBrand(updatePayload);
  };
  const logClickEvent = (button: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BRANDING_REMINDER, { button });
  };
  return (
    <div className="brand-setup-reminder">
      <AiOutlineClose
        onClick={() => {
          updateBrandSetupStep();
          logClickEvent('CLOSE_BUTTON');
        }}
        className="close-button"
        color="#ffffff"
      />
      <h5 className="title">Reminder</h5>
      <p className="message">
        You can start setting up your brand by going to Branding
      </p>
      <Button
        onClick={() => {
          hubNavTo(PageView.HUB_BRANDING);
          logClickEvent('LETS_START_BUTTON');
        }}
        className="btn-go-setup rounded-pill"
        variant="light"
      >
        LET'S GO
      </Button>
      <style jsx>{`
        @keyframes scale-out {
          from {
            width: 0;
          }
          to {
            width: 425;
          }
        }
        .brand-setup-reminder {
          position: fixed;
          bottom: 20px;
          right: 10px;
          width: 423px;
          height: 300px;
          z-index: 1010;
          color: #ffffff;
          background-color: #8f6599;
          padding: 16px;
          animation: scale-out 0.25s;
          animation-iteration-count: 1;
          animation-timing-function: ease-out;
        }
        .title {
          font-size: 22px;
          font-weight: 600;
          line-height: 26.82px;
          padding-left: 10px;
        }
        .message {
          margin-top: 30px;
          max-width: 193px;
          font-weight: 400;
          padding-left: 10px;
        }
        :global(.btn-go-setup) {
          position: absolute;
          bottom: 20px;
          right: 20px;
          color: #8f6599;
          font-weight: 800;
          font-size: 18px;
          width: 135px;
        }
        :global(.close-button) {
          position: absolute;
          top: 20px;
          right: 20px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default BrandSetupReminder;
