import adults from './adult.svg';
import kids from './kid.svg';
import male from './man.svg';
import sad from './sad.svg';
import tweens from './tween.svg';
import female from './woman.svg';
import matureAdult from './mature_adult.svg';
import happy from './happy.svg';
import surprised from './surprised.svg';
import teenagers from './teenager.svg';
import youngAdult from './young_adult.svg';
import far from './far.svg';
import mid from './mid.svg';
import near from './near.svg';

export const deviceIconsMapper = {
  adults,
  kids,
  male,
  sad,
  tweens,
  female,
  'mature adults': matureAdult,
  happy,
  surprised,
  teenagers,
  'young adults': youngAdult,
  far,
  mid,
  near
};
