import React from 'react';
import Layout from '../Layout';

const SessionContainer = ({
  children,
  title
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <Layout showNav={false} theme="dark-light" className="layoutSessions">
      <div className="sessions-container">
        <div className="top row">
          <h1 className="col-12">
            {title} <div className="separator" />
          </h1>
        </div>
        {children}
      </div>
      <style jsx>{`
        h1 {
          margin-bottom: 0 !important;
        }
        .sessions-container {
          padding: 0px 20px;
          width: 100%;
          max-width: 1200px;
          margin: auto;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .top.row {
          min-height: 60px;
        }
        .top {
          position: relative;
          text-align: center;
          overflow: hidden;
        }
        :global(.ssp) .back-btn :global(svg) {
          width: 40px;
          height: auto;
        }
        :global(.kbActive) h1 {
          display: none;
        }
      `}</style>
    </Layout>
  );
};

export default SessionContainer;
