export const ThankYouImage = () => (
    <svg
      width="406"
      height="193"
      viewBox="0 0 406 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_117_63)">
        <path
          d="M31.0484 45.4487H10.5484V27.1487H75.1484V45.4487H54.6484V97.1487H31.0484V45.4487ZM150.278 27.1487V97.1487H126.678V71.3487H104.878V97.1487H81.2781V27.1487H104.878V51.9487H126.678V27.1487H150.278ZM209.349 84.9487H182.749L178.049 97.1487H154.049L184.649 27.1487H207.849L238.449 97.1487H214.049L209.349 84.9487ZM202.849 67.9487L196.049 50.3487L189.249 67.9487H202.849ZM311.216 27.1487V97.1487H291.816L265.216 65.3487V97.1487H242.216V27.1487H261.616L288.216 58.9487V27.1487H311.216ZM351.087 73.9487L345.787 79.8487V97.1487H322.587V27.1487H345.787V52.5487L368.787 27.1487H394.487L366.487 57.9487L395.887 97.1487H368.587L351.087 73.9487Z"
          fill="#8F6599"
        />
      </g>
      <path
        d="M125.254 142.049V167.149H105.454V141.749L78.7539 97.1487H99.6539L116.254 124.949L132.854 97.1487H152.054L125.254 142.049ZM192.613 168.549C185.213 168.549 178.546 166.982 172.613 163.849C166.746 160.715 162.113 156.382 158.713 150.849C155.379 145.315 153.713 139.082 153.713 132.149C153.713 125.215 155.379 118.982 158.713 113.449C162.113 107.915 166.746 103.582 172.613 100.449C178.546 97.3153 185.213 95.7487 192.613 95.7487C200.013 95.7487 206.646 97.3153 212.513 100.449C218.446 103.582 223.079 107.915 226.413 113.449C229.813 118.982 231.513 125.215 231.513 132.149C231.513 139.082 229.813 145.315 226.413 150.849C223.079 156.382 218.446 160.715 212.513 163.849C206.646 166.982 200.013 168.549 192.613 168.549ZM192.613 152.149C196.146 152.149 199.346 151.315 202.213 149.649C205.079 147.982 207.346 145.649 209.013 142.649C210.679 139.582 211.513 136.082 211.513 132.149C211.513 128.215 210.679 124.749 209.013 121.749C207.346 118.682 205.079 116.315 202.213 114.649C199.346 112.982 196.146 112.149 192.613 112.149C189.079 112.149 185.879 112.982 183.013 114.649C180.146 116.315 177.879 118.682 176.213 121.749C174.546 124.749 173.713 128.215 173.713 132.149C173.713 136.082 174.546 139.582 176.213 142.649C177.879 145.649 180.146 147.982 183.013 149.649C185.879 151.315 189.079 152.149 192.613 152.149ZM277.433 168.549C267.033 168.549 258.933 165.715 253.133 160.049C247.399 154.382 244.533 146.349 244.533 135.949V97.1487H264.333V135.349C264.333 146.549 268.766 152.149 277.633 152.149C286.433 152.149 290.833 146.549 290.833 135.349V97.1487H310.333V135.949C310.333 146.349 307.433 154.382 301.633 160.049C295.899 165.715 287.833 168.549 277.433 168.549Z"
        fill="#DEDEDE"
      />
      <defs>
        <filter
          id="filter0_d_117_63"
          x="0.548431"
          y="27.1487"
          width="405.338"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_117_63"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_117_63"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
  