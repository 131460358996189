import React from 'react';
import { useViewportInfo } from '../../../components/hooks/ux';
import { StreamingIcon, UserIcon } from '../Common/Icons';
import FacebookAccounts from './FacebookAccounts';
import SalePage from './SalePage';
function ProfileTab({ selected, onSelect }) {
  return (
    <div className="profile-tab">
      <span
        className="tab account-detail"
        title="Account Details"
        onClick={() => {
          onSelect(0);
        }}
      >
        <UserIcon />
      </span>
      <span
        className="tab streaming"
        title="Connected Accounts"
        onClick={() => {
          onSelect(1);
        }}
      >
        <StreamingIcon />
      </span>
      <style jsx>{`
        .profile-tab {
          display: flex;
          max-width: 350px;
          margin: 0 auto;
          align-items: center;
          justify-content: space-evenly;
          margin-bottom: 20px;
        }

        .tab:not(.tab:nth-child(${selected + 1})) {
          color: #d8d8d8;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
}
function Profile() {
  const { width } = useViewportInfo();
  const isUserOnSmallScreen = width <= 992;
  const [selectedTab, setSelectedTab] = React.useState(0);
  const shouldShowAccountDetails = !isUserOnSmallScreen || selectedTab === 0;
  const shouldShowLinkedAccount = !isUserOnSmallScreen || selectedTab === 1;
  return (
    <>
      <div className="profile container">
        <div className="text-center">
          <h4 className="title">ACCOUNT DETAILS</h4>
        </div>
        {isUserOnSmallScreen && (
          <ProfileTab selected={selectedTab} onSelect={setSelectedTab} />
        )}
        <div className="row content">
          {shouldShowAccountDetails && (
            <div className="col-12 col-lg-6">
              <div className="pr-3">
                <SalePage />
              </div>
            </div>
          )}
          {shouldShowLinkedAccount && (
            <div className="col-12 col-lg-6 streaming">
              <div className="pl-3">
                <FacebookAccounts />
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        @media (min-width: 1700px) {
          .profile.container {
            max-width: 1300px;
          }
        }
        .title {
          margin: 0.5rem 0;
        }
        .streaming {
          border-left: ${!isUserOnSmallScreen ? '1px solid #d8d8d8' : 'none'};
        }
      `}</style>
    </>
  );
}

export default Profile;
