import React from 'react';
import {
  SortEnd,
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import { IoIosMenu } from 'react-icons/io';
import { Spinner } from 'react-bootstrap';
import ProductEntry from './ProductEntry';
import { changeContentOrder } from '../../../utils/nudge';
import { IProduct } from '../../../../interfaces';
import SearchInput from '../../Common/SearchInput';

function ProductItem({
  product,
  onRemove
}: {
  product: IProduct;
  onRemove: (id: string) => void;
}) {
  return (
    <ProductEntry
      product={product}
      actionBtn={
        <button
          className="btn btn-round btn-outline"
          onClick={() => {
            onRemove(product.id);
          }}
        >
          Remove
        </button>
      }
    />
  );
}

function ShowCasedProducts({
  products,
  loading,
  onChange
}: {
  products: IProduct[];
  loading: boolean;
  onChange: (products: IProduct[]) => void;
}) {
  const [keywords, setKeywords] = React.useState<string>('');
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newOrder = changeContentOrder(products, oldIndex, newIndex);
    onChange(newOrder);
  };

  const onRemove = (id: string) => {
    const newProducts = products.filter((p) => p.id !== id);
    onChange(newProducts);
  };

  const SortableItem = SortableElement(({ value }) => (
    <li className="d-flex justify-content-between align-items-center w-full">
      <ProductItem product={products[value]} onRemove={onRemove} />
      <Handle />
    </li>
  ));
  const Handle = SortableHandle(() => (
    <button className="btn btn-handler">
      <IoIosMenu size={20} />
    </button>
  ));
  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className="list">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            disabled={searchResult.length < products.length}
            {...({ value: index } as any)}
          />
        ))}
      </ul>
    );
  });

  const searchResult = React.useMemo(() => {
    return products.filter((p) => {
      return (
        p.modelCode.toLowerCase().includes(keywords.toLowerCase()) ||
        p.modelName.toLowerCase().includes(keywords.toLowerCase())
      );
    });
  }, [keywords, products]);

  if (loading)
    return (
      <div className="d-flex justify-content-center flex-column align-items-center">
        <Spinner animation="border" />
        Loading products...
      </div>
    );

  return (
    <div className="ShowCasedProduct">
      <h5 className="font-weight-bold mb-4">Showcased Products</h5>
      <SearchInput
        onSearch={setKeywords}
        searchOnTyping
        searchOnTypingDelay={100}
      />
      <div className="metadata mt-2 d-flex justify-content-between">
        <span>All avalaible products</span>
        <span>{searchResult?.length} product(s) found</span>
      </div>
      {Boolean(searchResult.length) && (
        <SortableList
          helperClass="sortItem sortActive"
          onSortEnd={onSortEnd}
          lockAxis="y"
          useDragHandle
          {...({ items: searchResult } as any)}
        />
      )}
      <style jsx>{`
        .metadata {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 20px;
        }
      `}</style>
      <style jsx global>{`
        .ShowCasedProduct ul li:nth-child(odd) {
          background-color: #f5f5f5;
        }
        .ShowCasedProduct {
          padding: 20px;
          display: flex;
          flex-direction: column;
        }
        .btn-handler {
          cursor: grab;
        }
        .ShowCasedProduct .list {
          padding: 0;
        }
        .ShowCasedProduct ul {
          flex: 1;
          overflow-y: auto;
          margin: 0;
        }
      `}</style>
    </div>
  );
}

export default ShowCasedProducts;
