import React from 'react';
import { BsCheck, BsXCircle } from 'react-icons/bs';
import PopupContainer from '../Common/PopupContainer';
export interface IRoleUserPermission {
  functions: string;
  cluster: string;
  permissions: {
    admin: boolean;
    designer: boolean;
    marketing: boolean;
    user: boolean;
  };
}

const UsersRoleHelp = ({
  onClose,
  rolePermissions
}: {
  onClose: () => void;
  rolePermissions: IRoleUserPermission[];
}) => {
  return (
    <PopupContainer className="role-modal" maxWidth="900px">
      <div className="modalBody">
        <div className="role-header">
          <div className="role-modal-close">
            <BsXCircle size={18} onClick={onClose} />
          </div>
          <h4>User Roles</h4>
        </div>

        <table>
          <tr>
            <td className="td-fun">Functions</td>
            <td className="td-fun">Cluster</td>

            <td className="td-str td-fun-first">Admin</td>
            <td className="td-str">Designer</td>
            <td className="td-str">Marketing</td>
            <td className="td-str">User</td>
          </tr>
          {rolePermissions?.map((role) => (
            <tr>
              <td className="td-fun">{role.functions}</td>
              <td className="td-fun">{role.cluster}</td>
              <td className="td-str td-fun-first">
                {role.permissions.admin && <BsCheck />}
              </td>
              <td className="td-str">
                {role.permissions.designer && <BsCheck />}
              </td>
              <td className="td-str">
                {role.permissions.marketing && <BsCheck />}
              </td>
              <td className="td-str">{role.permissions.user && <BsCheck />}</td>
            </tr>
          ))}
        </table>
      </div>
      <style jsx global>
        {`
          .role-modal-close {
            text-align: right;
            transform: translate(15px, -15px);
          }
          .role-header {
            width: 100%;
          }
          .role-modal .modalBody {
            display: flex;
            flex-wrap: wrap;
            padding: 0 !important;
            margin: 20px;
            width: 100%;
          }
          .role-modal .modalBody .td-fun {
            width: 22%;
            text-align: left;
          }
          .role-modal .modalBody .td-str {
            width: 7%;
            text-align: center !important;
          }
          .role-modal .modalBody table {
            border: 1px solid #dddddd;
            font-size: 0.9rem;
            width: 100%;
            background: white;
          }
          .role-modal .modalBody tr {
            border: 1px solid #dddddd;
          }
          .role-modal .modalBody td {
            background: unset;
          }
          .role-modal .modalBody .td-fun-first {
            border-left: 1px solid #dddddd;
          }
          .role-modal .modalBody .title {
            text-align: center !important;
            font-weight: 600;
            font-size: 1rem;
          }
          @media (max-height: 720px) {
            .role-modal .modalBody table {
              font-size: .5rem;
            }
          }
          @media (max-height: 520px) {
            .role-modal .modalBody table {
              height: max(50vh, 300px);
              display: block;
              overflow: auto !important;
              font-size: .5rem;
            }
            .role-modal h4 {
              font-size: 1.0rem;
            }
          }
        `}
      </style>
    </PopupContainer>
  );
};

export default UsersRoleHelp;
