import React from 'react';
import { logEvent } from '../../../analytics';
import { IUser } from '../../../interfaces';
import {
  DID_CLICK_EDIT_USER,
  DID_CLOSE_EDIT_USER_FORM,
  DID_SUBMIT_EDIT_USER_FORM
} from '../../../utils/constants';
import LoaderButton from '../Common/LoaderButton';
import { HubUserContext } from './HubUserContext';

function UserEditButton({
  user,
  revertEdit,
  isSaveAble,
  closeEditor
}: {
  user: IUser;
  revertEdit?: () => void;
  isSaveAble?: boolean;
  closeEditor?: () => void;
}) {
  const [isOnDelete, setIsOnDelete] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(false);
  const {
    editMode,
    setEditMode,
    updateUser,
    updating,
    isAdminPage,
    deleteUser
  } = React.useContext(HubUserContext);
  const handleUpdateUser = () => {
    logEvent(DID_SUBMIT_EDIT_USER_FORM, DID_SUBMIT_EDIT_USER_FORM, {
      id: user?.id
    });
    updateUser(user.id, user);
  };
  const handleCancelEdit = () => {
    logEvent(DID_CLOSE_EDIT_USER_FORM);
    revertEdit();
    setEditMode(false);
  };
  const handleDeleteUser = () => {
    setIsDeleting(true);
    deleteUser(user.id)
      .then(() => {
        closeEditor();
      })
      .catch((err) => {
        setDeleteError(true);
        console.log(err);
      })
      .finally(() => setIsDeleting(false));
  };
  if (isOnDelete)
    return (
      <div className="edit-button text-right">
        <span style={{ fontWeight: 400 }}>
          {deleteError ? "Can't delete this user" : 'Delete this user?'}
        </span>
        <br />
        <LoaderButton
          onClick={handleDeleteUser}
          isLoading={isDeleting}
          className="btn btn-danger mr-2 ml-2"
        >
          {deleteError ? 'TRY AGAIN' : 'DELETE'}
        </LoaderButton>
        <button className="btn btn-dark" onClick={() => setIsOnDelete(false)}>
          Cancel
        </button>
      </div>
    );
  return (
    <>
      <div className="edit-button text-right">
        {!editMode && (
          <React.Fragment>
            {isAdminPage && (
              <button
                className="btn btn-danger mr-2"
                onClick={() => setIsOnDelete(true)}
              >
                DELETE THIS USER
              </button>
            )}
            <button
              type="button"
              id="submit"
              name="submit"
              className="btn btn-dark"
              onClick={() => {
                logEvent(DID_CLICK_EDIT_USER, DID_CLICK_EDIT_USER, {
                  id: user?.id
                });
                setEditMode(true);
              }}
            >
              Edit
            </button>
          </React.Fragment>
        )}

        {editMode && (
          <React.Fragment>
            <button
              type="button"
              name="submit"
              onClick={handleCancelEdit}
              className="btn border border-dark mr-2"
            >
              Cancel
            </button>
            <LoaderButton
              type="submit"
              className="btn btn-dark"
              onClick={handleUpdateUser}
              isLoading={updating}
              disabled={!isSaveAble}
            >
              Save
            </LoaderButton>
          </React.Fragment>
        )}
      </div>
      <style jsx>{`
        @media only screen and (max-width: 575px) {
          .edit-button {
            display: grid;
            grid-template-columns: repeat(${editMode ? '2' : '1'}, 1fr);
          }
        }
      `}</style>
    </>
  );
}

export default UserEditButton;
