import React from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import SessionLink from './SessionLink';

const SessionLinksWrapper = ({ sessionURL }) => {
  const [showMore, setShowMore] = React.useState(false);

  return (
    <>
      <SessionLink role="Guest" sessionURL={sessionURL?.guest} />
      {showMore && (
        <>
          <SessionLink role="Host" sessionURL={sessionURL?.host} />
          <SessionLink role="MC" sessionURL={sessionURL?.mc} />
        </>
      )}
      <button
        className="btn btn-round btn-dark btn-sm"
        onClick={() => setShowMore(!showMore)}
      >
        Show {!showMore ? `more` : `less`}
        {!showMore ? (
          <MdArrowDropDown size="1.3em" />
        ) : (
          <MdArrowDropUp size="1.3em" />
        )}
      </button>
      <style jsx>
        {`
          .btn {
            width: 100%;
            margin-top: 10px;
          }
        `}
      </style>
    </>
  );
};

export default SessionLinksWrapper;
