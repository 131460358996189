import TwoColLayout from '../TwoColLayout';
import LoginForm from './LoginForm';
import React from 'react';
import { HubContext } from '../HubContext';
import {
  mobileBreakPoint,
  getAssetPath,
  getThemeColor,
  isBrandNotPreConfigured
} from './../BrandStyle';
import Footer from '../Footer';
import { INSPIFY_DARK_LOGO, louboutinBrandId } from '../../../config';
import { useSelector } from 'react-redux';
import { IMainState, LoginStatus } from '../../../interfaces';
import VerifyForm from './VerifyForm';
import ResetPwdForm from './ResetPwdForm';
import CompletePwd from './CompletePwd';

const Login = () => {
  const { brandId, brandSetup } = React.useContext(HubContext);
  const isOnBoarding = isBrandNotPreConfigured(brandId);
  const color = getThemeColor(brandId);
  const assetPath = getAssetPath(brandId);
  const hub = useSelector((state: IMainState) => state.clientState.hub) || {};
  const { loginStatus } = hub;
  const logo =
    brandSetup?.theme?.logo?.dark ||
    (isOnBoarding
      ? INSPIFY_DARK_LOGO
      : `${assetPath}/logo.png?v3`);
  const getForm = () => {
    switch (loginStatus) {
      case LoginStatus.VERIFY:
        return <VerifyForm brandId={brandId} />;
      case LoginStatus.RESET_PASSWORD:
        return <ResetPwdForm brandId={brandId} />;
      case LoginStatus.LOG_IN:
        return <LoginForm brandId={brandId} />;
      case LoginStatus.CONFIRMED_PASSWORD:
        return <LoginForm brandId={brandId} hideInvitationText={true}/>;
      case LoginStatus.NEW_PASSWORD_REQUIRED:
        return <CompletePwd brandId={brandId} />;
      default:
        return <LoginForm brandId={brandId} />;
    }
  };
  return (
    <div className="Login">
      <TwoColLayout
        theme="transparent"
        background={{
          mobile: `${assetPath}/login-m.jpg?v3`,
          desktop: `${assetPath}/login.jpg?v3`
        }}
        brandId={brandId}
        showNav={false}
      >
        {getForm()}
        <Footer />
      </TwoColLayout>

      <style jsx>{`
        .Login {
          height: 100%;
        }
        .Login :global(.header) {
          background-color: #000;
          border: none;
        }
        .Login :global(.TwoColLayout .main) {
          background: transparent;
          background-size: cover;
        }
        .Login :global(.TwoColLayout .layout) {
          background: #000 url(${assetPath}/login-m.jpg);
          background-position: center center;
          background-size: cover;
        }
        .Login :global(.TwoColLayout .bg) {
          ${isOnBoarding ? 'width : 0' : ''};
        }
        .Login :global(.TwoColLayout .layout .powered-by-inspify) {
          background: url(/asset/powered_by_inspify_light_center.png) no-repeat
            center center;
          background-size: contain;
          height: 60px;
        }

        @media (min-width: ${mobileBreakPoint}px) {
          .Login :global(.TwoColLayout .layout) {
            background: none;
          }
          .Login :global(.header) {
            height: 180px;
            background-size: ${brandId === louboutinBrandId
              ? 'auto 70%'
              : 'auto 40%'};
            background-color: transparent !important;
            background-image: url(${logo});
          }
          .Login :global(.footer) {
            height: 80px;
          }
          .Login :global(.TwoColLayout .layout .powered-by-inspify) {
            background: url(/asset/powered_by_inspify_dark_center.png) no-repeat
              center center;
            background-size: contain;
            height: 60px;
          }

          .Login :global(.main) {
            background: ${color.light} !important;
            background-size: cover;
          }
        }
      `}</style>
    </div>
  );
};

export default Login;
