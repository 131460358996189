import React from 'react';
import PopupContainer from '../Common/PopupContainer';
import { IDeviceStatus, IHubComment } from '../../../interfaces';
import {
  addNewCommentToDevice,
  getDeviceComments
} from '../../clientSideServices/device';
import { HubContext } from '../HubContext';
import { BsX } from 'react-icons/bs';
import { v4 as uuid } from 'uuid';
import UserAvatar from '../Common/UserAvatar';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { actionHubAlertError } from '../../../redux/actions';
import { IoMdSend } from 'react-icons/io';

function CommentEntry({ comment }: { comment: IHubComment }) {
  const commentDate = React.useMemo(() => {
    const date = moment(comment.createdAt);
    if (date.diff(moment(), 'days') < 1) {
      return date.fromNow();
    } else return date.format('lll');
  }, [comment]);

  const commentElements = React.useMemo(() => {
    return comment.comment
      .split('\n')
      .map((line, index) => <span key={index}>{line}</span>);
  }, [comment.comment]);

  return (
    <div className="comment-entry">
      <UserAvatar
        name={comment.createdBy?.alias}
        url={comment.createdBy?.avatar_picture}
        size={50}
      />
      <div className="comment-body">
        <div className="metadata">
          <span className="comment-author mr-2">
            {comment.createdBy?.alias}
          </span>
          <span className="comment-date">{commentDate}</span>
        </div>
        <p>{commentElements}</p>
      </div>

      <style jsx>{`
        .comment-entry {
          display: flex;
          gap: 10px;
        }
        p {
          display: flex;
          flex-direction: column;
        }
        .metadata {
          display: flex;
          gap: 5px;
        }
        .metadata span {
          font-size: 14px;
        }
        .comment-author {
          font-weight: bold;
        }
        .comment-body {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      `}</style>
    </div>
  );
}
function DeviceComment({
  device,
  onClose
}: {
  device: IDeviceStatus;
  onClose: () => void;
}) {
  const [loading, setLoading] = React.useState(false);
  const [comments, setComments] = React.useState<IHubComment[]>([]);
  const [newComment, setNewComment] = React.useState('');
  const { user } = React.useContext(HubContext);
  const [sortDir, setSortDir] = React.useState<'asc' | 'desc'>('desc');
  const [commenting, setCommenting] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    // fetch data
    setLoading(true);
    getDeviceComments(device.deviceId)
      .then((data) => {
        setComments(data.comments);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSendComment = () => {
    if (!newComment.trim()?.length) return;
    const commentPayload = {
      comment: newComment,
      id: uuid(),
      referenceId: device.deviceId,
      type: 'DEVICE',
      createdBy: user.id
    };
    // send comment to server
    setCommenting(true);
    addNewCommentToDevice(commentPayload)
      .then((data) => {
        setComments((prev) => [...prev, { ...data, createdBy: user }]);
        setNewComment('');
      })
      .catch((e) => {
        console.log(e);
        dispatch(
          actionHubAlertError('Failed to send comment, please try again later')
        );
      })
      .finally(() => setCommenting(false));
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      handleSendComment();
    }
  };

  const sortedComments = React.useMemo(() => {
    if (!comments?.length) return [];
    return comments.sort((a, b) => {
      if (sortDir === 'asc') {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      } else {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
    });
  }, [sortDir, comments]);

  return (
    <PopupContainer
      zIndex={999999}
      width="90vw"
      height="90%"
      className="device-comments"
    >
      <div className="comment-container">
        <div className="comment-header">
          <span className="device-name">{device.name}</span>
          <p className="device-store">
            <span>{device.storeName}</span> - <span>{device.storeCity}</span>
          </p>
          <span className="device-id">{device.deviceId}</span>
        </div>
        <button className="btn btn-outline btn-close" onClick={onClose}>
          <BsX size={25} />
        </button>
        <hr className="w-100" />
        <div className="comment-body">
          <h5>
            <span>Activities</span>
            <button
              className="btn btn-sort"
              onClick={() =>
                setSortDir((cur) => (cur === 'asc' ? 'desc' : 'asc'))
              }
            >
              <span>
                {sortDir === 'desc' ? 'Newest first' : 'Oldest first'}
              </span>
              <span>
                {sortDir === 'desc' ? <FaSortAmountDown /> : <FaSortAmountUp />}
              </span>
            </button>
          </h5>
          <div className="my-comment">
            <UserAvatar name={user?.alias} url={user?.avatar_picture} />
            <textarea
              placeholder="Add a comment..."
              name="comment"
              id="comment"
              value={newComment}
              onKeyUp={handleKeyUp}
              onChange={(e) => setNewComment(e.target.value)}
              className="form-control"
            ></textarea>

            <button className="btn btn-send" onClick={handleSendComment}>
              {commenting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <IoMdSend
                  size={25}
                  color={newComment.trim() ? '#000' : '#ccc'}
                />
              )}
            </button>
          </div>
          {loading && (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" />
            </div>
          )}
          <div className="comment-section">
            <div className="comment-list">
              {sortedComments?.map((comment, index) => (
                <CommentEntry key={comment.id || index} comment={comment} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        ::global(.device-comments) {
          position: absolute;
        }
        .comment-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          flex: 1;
          padding: 10px 20px;
        }
        .comment-header {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
        .comment-header .device-name {
          font-size: 20px;
          font-weight: 500;
        }
        .device-store,
        .device-id {
          font-size: 14px;
          margin-bottom: 0;
        }
        .my-comment {
          position: relative;
        }
        .btn-send {
          position: absolute;
          right: 0;
          width: 50px;
          top: 0;
          bottom: 0;
        }
        h5 {
          font-size: 20px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .btn-sort {
          display: flex;
          gap: 5px;
          font-size: 14px;
          color: #42526e;
          background: transparent;
        }
        .comment-section {
          flex: 1;
          position: relative;
        }
        .comment-list {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow-y: auto;
        }
        .my-comment {
          display: flex;
          gap: 10px;
        }
        .input {
          flex: 1;
        }
        textarea {
          resize: none;
          flex: 1;
        }

        .btn-close {
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 50%;
          border: none;
        }
        .comment-body {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      `}</style>
    </PopupContainer>
  );
}

export default DeviceComment;
