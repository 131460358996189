import React from 'react';
import ReactPlayer from 'react-player';
import { IInspiration, LoadingStatus } from '../../../interfaces';
import {
  createNudgeFromGalleryItem,
  getVideoDownloadUrl
} from '../../clientSideServices/gallery';
import { Spinner } from 'react-bootstrap';
import { getSimpleShortenedUrl } from '../../../utils/shareUrl';
import { getCurrentHost, performNativeShareAsync } from '../../../utils/window';
import { logEvent } from '../../../analytics';
import {
  DID_CLICK_CANCEL,
  DID_CLICK_DOWNLOAD_BUTTON,
  DID_CLICK_EDIT,
  DID_SHARE_NUDGE
} from '../../../utils/constants';
import ToolTipWrapper from '../../../components/Common/ToolTipWrapper';
import SimpleShareModal from '../../../components/Sharing/SimpleShareModal';

import HubGalleryItemEdit from './HubGalleryEdit';
import { HubContext } from '../HubContext';
import { parsePlainTextToRichText } from '../../../utils/string';
import { GoChevronLeft } from 'react-icons/go';
import { RecordingEntitlement } from '../../utils/user';
import { last } from 'lodash';
import UserLabel from '../Common/UserLabel';
import { getFileNameFromUrl } from '../../../utils/file';

export const VisibilityLabel = ({ visibility }: { visibility?: string }) => {
  return (
    visibility && (
      <span style={{ textTransform: 'capitalize' }}>
        Visibility: {visibility}
      </span>
    )
  );
};

export const galleryItemLegend = (
  createdBy: string,
  submittedAt: string,
  visibility
) => (
  <p className="galleryItem-owner">
    <span>
      <UserLabel id={createdBy} />
    </span>
    {submittedAt && <span>{`  |  ${submittedAt.replace('|', '-')}`}</span>}
    {visibility && (
      <>
        {` | `}
        <VisibilityLabel visibility={visibility} />
      </>
    )}
    <style jsx>{`
      .galleryItem-owner {
        color: #999;
        font-size: 0.8em;
        font-weight: normal;
      }
    `}</style>
  </p>
);

const HubGalleryItemDetails = ({
  onBack,
  galleryItem,
  storeId,
  onUpdate,
  userId,
  recordingEntitlement = 'PRIVATE'
}: {
  onBack: () => void;
  galleryItem: IInspiration;
  storeId: string;
  onUpdate: (galleryItem: IInspiration) => void;
  userId?: string;
  recordingEntitlement?: RecordingEntitlement;
}) => {
  const { isEmbeddedInSsp } = React.useContext(HubContext);
  const [loading, setLoading] = React.useState(LoadingStatus.INITIAL);
  const isLoading = loading === LoadingStatus.LOADING;
  const isError = loading === LoadingStatus.FAILED;

  const [nudgeUrl, setNudgeUrl] = React.useState({
    original: '',
    shortened: '',
    galleryItemId: ''
  });
  const shareUrl = nudgeUrl.shortened || nudgeUrl.original;
  const [openDescPopup, setOpenDescPopup] = React.useState(undefined);
  const [shareDesc, setShareDesc] = React.useState({
    name: '',
    description: ''
  });
  const [error, setError] = React.useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState('');
  const [shareModal, setShareModal] = React.useState({
    open: undefined,
    nudgeId: ''
  });
  const [edit, setEdit] = React.useState(false);
  const canEdit = userId === galleryItem.createdBy;
  const shareGalleryLog = (detail) => {
    logEvent(DID_SHARE_NUDGE, DID_SHARE_NUDGE, {
      name: shareDesc.name,
      description: shareDesc.description,
      button: 'GalleryShareButton',
      detail
    });
  };
  const onShare = (url: string, channel: string, nudgeId: string) => {
    logEvent(DID_SHARE_NUDGE, channel, {
      nudgeId,
      shareUrl: url,
      message: galleryItem.title
    });
  };

  const performShare = async (url: string, nudgeId: string) => {
    shareGalleryLog({ url, nudgeId });
    const onNativeShare = () => {
      onShare(url, 'native', nudgeId);
    };
    const onFailedNativeShare = () => {
      const element = document?.getElementById('forMobile');
      const display = window?.getComputedStyle(element)?.display;
      setShareModal({
        open: display === 'block' ? 'shareButtonMobile' : 'shareButton',
        nudgeId
      });
      shareGalleryLog('onFailedNativeShare');
    };
    try {
      await performNativeShareAsync(
        {
          title: shareDesc.name,
          text: shareDesc.description,
          url
        },
        onNativeShare,
        onFailedNativeShare
      );
    } catch {
      onFailedNativeShare();
    }
  };

  const generateShareUrl = async (id: string) => {
    const nudgeUrl = `${getCurrentHost()}/nudge/${id}`;
    if (typeof getSimpleShortenedUrl === 'function') {
      return getSimpleShortenedUrl(nudgeUrl)
        .then((url) => ({
          original: nudgeUrl,
          shortened: url,
          galleryItemId: galleryItem.id
        }))
        .catch(() => ({
          original: nudgeUrl,
          shortened: '',
          galleryItemId: galleryItem.id
        }));
    } else {
      return {
        original: nudgeUrl,
        shortened: '',
        galleryItemId: galleryItem.id
      };
    }
  };

  const handleShare = () => {
    shareGalleryLog('click');
    setLoading(LoadingStatus.LOADING);
    createNudgeFromGalleryItem({
      galleryItem: galleryItem,
      storeId,
      userId,
      name: shareDesc.name,
      description: shareDesc.description
    })
      .then((result) => {
        const nudgeId = result.payload.id;
        if (shareUrl && nudgeUrl?.galleryItemId === galleryItem?.id) {
          performShare(shareUrl, nudgeId);
          setLoading(LoadingStatus.INITIAL);
        } else {
          generateShareUrl(nudgeId)
            .then((url) => {
              setNudgeUrl(url);
              performShare(url.shortened || url.original, nudgeId);
              setLoading(LoadingStatus.INITIAL);
              return;
            })
            .catch(() => {
              shareGalleryLog('generateShareUrl FAILED');
              setLoading(LoadingStatus.FAILED);
            });
        }
        return;
      })
      .catch(() => {
        shareGalleryLog('createNudgeFromGalleryItem FAILED');
        setLoading(LoadingStatus.FAILED);
      });
  };

  const sharePopup = (
    <ToolTipWrapper
      onClose={() => setShareModal({ ...shareModal, open: undefined })}
      parentId={shareModal.open}
      width={250}
    >
      <div className="control-menu text-left">
        <SimpleShareModal
          show={shareModal.open}
          position="bottom"
          url={shareUrl}
          onShare={(channel) => onShare(shareUrl, channel, shareModal.nudgeId)}
          title={shareDesc.description}
          type="menu"
          qrUrl={`${nudgeUrl.original}?sharable=true`}
          qrTitle="Scan to share from your phone"
        />
      </div>
      <style jsx global>{`
        .content-wrapper {
          z-index: 4 !important;
        }
      `}</style>
    </ToolTipWrapper>
  );
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (value) setShareDesc({ name: shareDesc.name, description: value });
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setError(false);
    setShareDesc({ name: value, description: shareDesc.description });
  };

  const shareDescriptionPopup = (
    <ToolTipWrapper
      parentId={openDescPopup}
      width={440}
      onClose={() => setOpenDescPopup(undefined)}
    >
      <div className="control-menu text-left">
        <div className="popup-share-desc">
          <div className="input-container">
            <textarea
              className="form-control"
              placeholder="Message"
              defaultValue={shareDesc.description}
              onBlur={handleDescriptionChange}
            />
            <input
              className={`form-control ${error && 'input-error'}`}
              type="text"
              defaultValue={shareDesc.name}
              onBlur={handleNameChange}
              placeholder={
                error ? 'Recipient name required' : 'Enter recipient name'
              }
            />
          </div>

          <div className="btn-group control-fix">
            <button
              className="btn btn-secondary btn-popup"
              type="button"
              onClick={() => {
                setOpenDescPopup(undefined);
              }}
            >
              CLOSE
            </button>
            <button
              className="btn btn-accent btn-popup"
              type="button"
              onClick={() => {
                if (shareDesc.name.length > 0) {
                  setOpenDescPopup(undefined);
                  handleShare();
                } else {
                  setError(true);
                }
              }}
            >
              SHARE
            </button>
          </div>
        </div>
        <div></div>
      </div>
      <style jsx global>{`
        .content-wrapper {
          z-index: 4 !important;
        }
        .popup-share-desc {
          background-color: rgb(0, 0, 0);
        }
        .input-container {
          padding-top: 5px;
        }
        .btn-group {
          width: 100%;
          padding-top: 15px;
          padding-bottom: 5px;
        }
        .input-error {
          background: #ffecec;
          border-color: red;
        }
      `}</style>
    </ToolTipWrapper>
  );

  const downloadImage = async () => {
    logEvent(DID_CLICK_DOWNLOAD_BUTTON, DID_CLICK_DOWNLOAD_BUTTON, {
      id: galleryItem?.id,
      downloadUrl: galleryItem.campaignImgUrl
    });

    const originalImage = galleryItem.campaignImgUrl;
    try {
      const image = await fetch(originalImage);

      //Split image name
      const nameSplit = last(originalImage.split('/'));
      const duplicateName = last(nameSplit.split('%2F'));
      const fileName = `${galleryItem.title}.${last(duplicateName.split('.'))}`;

      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = '' + fileName + '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    setEdit(false);
    if (!isEmbeddedInSsp) {
      if (galleryItem.context === 'stillshot') {
        setDownloadUrl(galleryItem.campaignImgUrl);
      } else {
        const fileName = getFileNameFromUrl(galleryItem.videoUrl);
        const fileExtension = last(
          galleryItem.videoUrl.replace('/' + fileName, '').split('.')
        );
        getVideoDownloadUrl(
          galleryItem.videoUrl,
          `${galleryItem.title}.${fileExtension}`
        ).then((url) => setDownloadUrl(url));
      }
    }
  }, [galleryItem.id, galleryItem.title]);

  const galleryItemActionsButton = (shareButtonId: string) => (
    <div className="galleryItem-actions">
      {canEdit && (
        <button
          className="btn btn-round btn-sm ssp-ignore btn-edit-galleryItem btn-outline-dark"
          onClick={() => {
            setEdit(true);
            logEvent(DID_CLICK_EDIT, DID_CLICK_EDIT, { id: galleryItem.id });
          }}
        >
          Edit
        </button>
      )}
      {!isEmbeddedInSsp && (
        <>
          <button
            className="btn btn-round btn-share-galleryItem btn-sm ssp-ignore btn-dark"
            id={shareButtonId}
            onClick={() => {
              setOpenDescPopup(shareButtonId);
              setShareDesc({ name: '', description: '' });
            }}
            disabled={isLoading || openDescPopup}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : isError ? (
              'Try Again'
            ) : (
              'Share'
            )}
          </button>

          {galleryItem.context === 'stillshot' && (
            <button
              className="btn btn-round btn-sm ssp-ignore btn-dark btn-download-galleryItem"
              id="downloadButton"
              onClick={downloadImage}
              disabled={isLoading || openDescPopup}
            >
              Download
            </button>
          )}
          {galleryItem.context === 'recording' && downloadUrl && (
            <a
              href={downloadUrl}
              className="btn btn-round btn-sm ssp-ignore btn-dark btn-download-galleryItem"
              onClick={() => {
                logEvent(DID_CLICK_DOWNLOAD_BUTTON, DID_CLICK_DOWNLOAD_BUTTON, {
                  id: galleryItem?.id,
                  downloadUrl
                });
              }}
            >
              Download
            </a>
          )}
        </>
      )}

      <style jsx>{`
        .galleryItem-actions .btn {
          width: ${canEdit ? (isEmbeddedInSsp ? '100%' : '32%') : '48%'};
        }
      `}</style>
    </div>
  );

  const contentDetail = (
    <>
      <div className="galleryItem-description">
        <div className="heading-container">
          <div className="title-container">
            <h2>{galleryItem.title}</h2>
          </div>
          <div className="action-container for-desktop">
            {galleryItemActionsButton('shareButton')}
          </div>
        </div>
        {galleryItemLegend(
          galleryItem.createdBy,
          galleryItem.submittedAt,
          galleryItem.visibility
        )}
        <p>{parsePlainTextToRichText(galleryItem.description)}</p>
      </div>
      <div id="forMobile" className="action-container for-mobile">
        {galleryItemActionsButton('shareButtonMobile')}
      </div>
      <style jsx>{`
        h2 {
          font-size: 1.3em;
        }

        .galleryItem-description {
          margin-bottom: 20px;
        }
        .for-desktop {
          display: none;
        }
        @media (min-width: 1140px) {
          .for-mobile {
            display: none;
          }
          .for-desktop {
            display: block;
          }
          .heading-container {
            display: flex;
            flex-wrap: wrap;
          }
          .action-container {
            width: 280px;
          }
          .title-container {
            flex: 1;
            padding-right: 20px;
          }
        }
      `}</style>
    </>
  );

  return (
    <>
      <div className="HubGalleryItemDetails">
        <div className="back-container">
          <button className="btn btn-back" onClick={onBack}>
            <GoChevronLeft /> Back
          </button>
        </div>
        <div className="detail">
          <div className="galleryItem-container">
            {galleryItem.context === 'recording' ? (
              <ReactPlayer
                key={galleryItem.id}
                autoPlay
                playing={true}
                url={galleryItem.videoUrl}
                width="100%"
                height="100%"
                controls
                playsinline
              />
            ) : (
              <img
                src={galleryItem.flipImageUrl}
                className="img-fit-to-box"
                alt=""
              />
            )}
          </div>
          <div className="content-container">
            {edit ? (
              <HubGalleryItemEdit
                onClose={() => {
                  setEdit(false);
                  logEvent(DID_CLICK_CANCEL, DID_CLICK_CANCEL, {
                    id: galleryItem.id
                  });
                }}
                galleryItem={galleryItem}
                onUpdate={(updatedGalleryItem) => {
                  onUpdate(updatedGalleryItem);
                  setEdit(false);
                }}
                userId={userId}
                recordingEntitlement={recordingEntitlement}
              />
            ) : (
              contentDetail
            )}
          </div>
        </div>
        <style jsx>{`
          .HubGalleryItemDetails {
            padding: 10px 20px;
            margin: 0 auto;
          }
          .btn-back {
            font-weight: bold;
            padding: 12px 30px 12px 0;
          }
          .img-fit-to-box {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 55vh;
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
          .galleryItem-container {
            border-radius: 10px;
            overflow: hidden;
            background: #000;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 55vh;
          }
          .galleryItem-container :global(galleryItem) {
            border-radius: 10px;
          }
          .btn-back {
            margin-bottom: 5px;
          }

          .HubGalleryItemDetails :global(.galleryItem-actions) {
            display: flex;
            justify-content: space-between;
          }

          .content-container {
            padding: 20px 10px;
          }

          .detail,
          .back-container {
            max-width: 900px;
            margin: 0 auto;
          }
          @media (orientation: landscape) and (max-width: 1140px) {
            .detail {
              display: flex;
              flex-wrap: wrap;
            }
            .detail > * {
              width: 50%;
            }
            .content-container {
              padding: 20px;
            }
          }
        `}</style>
      </div>
      {shareModal.open && sharePopup}
      {openDescPopup && shareDescriptionPopup}
    </>
  );
};

export default HubGalleryItemDetails;
