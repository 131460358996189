import { useContext, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import httpClient from '../../../clientSideServices/http';
import Layout from '../Layout';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { RiErrorWarningLine } from 'react-icons/ri';
import { HubContext } from '../HubContext';
import {
  getAccountBySpaceId,
  saveAccount
} from '../../../clientSideServices/users';
import { BILLING_URL } from '../../../utils/pageView';
import { useDispatch } from 'react-redux';
import {
  actionHubAlertError,
  actionHubAlertSuccess
} from '../../../redux/actions';
import { inspifyBrandId, inspifySpaceId } from '../../../config';

const specialCharacterPattern = /[!@#$%^&*()+{}\[\]:;<>,?~\\/]/;
type TField = 'spaceName' | 'hubspot' | 'apollo';
interface ErrorField {
  field: TField;
  msg: string;
}

type TConnection = 'ok' | 'error';

const AccountPage = () => {
  const dispatch = useDispatch();
  const { storeId, brandId } = useContext(HubContext);
  const [accountWithKey, setAccountWithKey] = useState(null);
  const [spaceName, setSpaceName] = useState('');
  const [hubspotKey, setHubspotKey] = useState('');
  const [apolloKey, setApolloKey] = useState('');
  const [errorValidate, setErrorValidate] = useState<ErrorField[]>([]);
  const [hubspotConnection, setHubspotConnection] =
    useState<TConnection | null>(null);
  const [apolloConnection, setApolloConnection] = useState<TConnection | null>(
    null
  );
  const [hubspotLoading, sethubspotLoading] = useState<boolean>(false);
  const [apolloLoading, setApolloLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!storeId || !brandId) return;
    let spaceId = storeId;
    if (brandId === inspifyBrandId) spaceId = inspifySpaceId;
    getAccountBySpaceId(spaceId).then((response) => {
      setAccountWithKey(response.data);
      if (response.data.hubspotKey) {
        setHubspotKey(response.data.hubspotKey);
      }
      if (response.data.apolloKey) {
        setApolloKey(response.data.apolloKey);
      }
      if (response.data.spaceName) {
        setSpaceName(response.data.spaceName);
      }
    });
  }, [storeId, brandId]);

  const isContainSpecialCharacter = (str) => {
    return specialCharacterPattern.test(str);
  };

  const validateField = (fieldName: TField, fieldValue: string) => {
    const msgs: ErrorField[] = [...errorValidate].filter(
      (v) => v.field !== fieldName
    );

    if (isContainSpecialCharacter(fieldValue)) {
      msgs.push({
        field: fieldName,
        msg: `Special characters detected.`
      });
    }
    if (fieldName === 'hubspot' || fieldName === 'apollo') {
      if (fieldValue.includes(' ')) {
        msgs.push({
          field: fieldName,
          msg: `Spaces detected.`
        });
      }
    }
    setErrorValidate([...msgs]);
  };

  const handleChange = (name, value) => {
    validateField(name, value);
    if (name === 'spaceName') {
      setSpaceName(value);
    } else if (name === 'hubspot') {
      setHubspotKey(value);
      setHubspotConnection(null);
    } else if (name === 'apollo') {
      setApolloKey(value);
      setApolloConnection(null);
    }
  };

  const onTestHubspot = async () => {
    sethubspotLoading(true);
    const connection = await httpClient.post('/api/connection/hubspot', {
      apiKey: hubspotKey
    });
    sethubspotLoading(false);
    if (connection.data.valid) {
      setHubspotConnection('ok');
    } else {
      setHubspotConnection('error');
    }
  };

  const onTestApollo = async () => {
    setApolloLoading(true);
    const connection = await httpClient.post('/api/connection/apollo', {
      apiKey: apolloKey
    });
    setApolloLoading(false);
    if (connection.data.valid) {
      setApolloConnection('ok');
    } else {
      setApolloConnection('error');
    }
  };

  const onSaveAccount = async () => {
    setSaveLoading(true);
    try {
      await saveAccount({
        ...accountWithKey,
        hubspotKey: hubspotKey.trim(),
        apolloKey: apolloKey.trim(),
        spaceName: spaceName.trim()
      });
    } catch (error) {
      if (error) dispatch(actionHubAlertError(`Error: Update Account failed!`));
      setSaveLoading(false);
    }
    dispatch(actionHubAlertSuccess(`Updated Account!`));
    setSaveLoading(false);
    setAccountWithKey({ ...accountWithKey, spaceName, hubspotKey, apolloKey });
  };

  const nameError = errorValidate.find((v) => v.field === 'spaceName');
  const hubspotError = errorValidate.find((v) => v.field === 'hubspot');
  const apolloError = errorValidate.find((v) => v.field === 'apollo');

  return (
    <Layout showNav={true} theme="dark-light" className="layoutSessions">
      <div className="account">
        <div className="heading border-bottom">
          <h2 className="text-left">Account</h2>
          <Button
            className="dark-btn save-btn"
            disabled={
              !!nameError ||
              !!hubspotError ||
              !!apolloError ||
              (accountWithKey?.spaceName === spaceName &&
                accountWithKey?.hubspotKey === hubspotKey &&
                accountWithKey?.apolloKey === apolloKey)
            }
            onClick={onSaveAccount}
          >
            {saveLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </div>
        <div className="main">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              value={spaceName}
              type="text"
              className="form-control"
              id="first_name"
              placeholder="Enter Name here"
              onChange={(e) => {
                handleChange('spaceName', e.target.value);
              }}
            />
            {nameError && <span className="error"> {nameError.msg}</span>}
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="hubspotkey">HubSpot API Key</label>
              {hubspotConnection &&
                (hubspotConnection === 'ok' ? (
                  <div className="success">
                    <IoIosCheckmarkCircle />
                  </div>
                ) : (
                  <div className="warning">
                    <RiErrorWarningLine /> Connection Error
                  </div>
                ))}
            </div>
            <div className="button-group">
              <div className="form-input">
                <input
                  value={hubspotKey}
                  type="text"
                  className="form-control"
                  id="first_name"
                  placeholder="Enter HubSpot API Key here"
                  onChange={(e) => {
                    handleChange('hubspot', e.target.value);
                  }}
                />
                {hubspotError && (
                  <span className="error"> {hubspotError.msg}</span>
                )}
              </div>
              <Button
                className="dark-btn"
                disabled={!hubspotKey}
                onClick={onTestHubspot}
              >
                {hubspotLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Test Connection'
                )}
              </Button>
            </div>
          </div>
          <div className="form-group">
            <div className="form-label">
              <label htmlFor="first_name">Apollo.io API Key</label>
              {apolloConnection &&
                (apolloConnection === 'ok' ? (
                  <div className="success">
                    <IoIosCheckmarkCircle />
                  </div>
                ) : (
                  <div className="warning">
                    <RiErrorWarningLine /> Connection Error
                  </div>
                ))}
            </div>
            <div className="button-group">
              <div className="form-input">
                <input
                  value={apolloKey}
                  type="text"
                  className="form-control"
                  id="first_name"
                  placeholder="Enter Apollo.io API Key here"
                  onChange={(e) => {
                    handleChange('apollo', e.target.value);
                  }}
                />
                {apolloError && (
                  <span className="error"> {apolloError.msg}</span>
                )}
              </div>
              <Button
                className="dark-btn"
                disabled={!apolloKey}
                onClick={onTestApollo}
              >
                {apolloLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Test Connection'
                )}
              </Button>
            </div>
          </div>
          <div
            className="form-group"
            style={{ width: '250px', marginTop: '40px' }}
          >
            <Button
              className="dark-btn"
              style={{ width: '100%' }}
              onClick={() => {
                window.open(BILLING_URL, '_blank');
              }}
            >
              Manage Your Subscription
            </Button>
            <div className="stripe-text">
              The management of the subscription will be done via
              <img src="/asset/stripe.png" />
            </div>
          </div>
        </div>
        <style jsx global>{`
          .content-wrapper {
            min-height: unset !important;
            padding-top: 0 !important;
          }
          .dark-btn {
            border: 1px solid rgba(255, 255, 255, 0.5);
            background: #000;
            border-radius: 20px;
            font-size: 16px;
          }
          .save-btn {
            min-width: 150px;
          }
          .dark-btn:hover,
          .dark-btn:focus {
            border: 1px solid rgba(255, 255, 255, 0.5);
            background: #000;
          }
          .dark-btn:disabled {
            background: rgba(99, 99, 99, 0.4);
            border-color: transparent;
          }
          .button-group button {
            font-size: 12px;
            height: 30px;
          }
          .error {
            color: #dd4141;
            font-size: 12px;
          }
          .stripe-text {
            text-align: center;
            width: 240px;
            font-size: 12px;
            margin-top: 10px;
          }
          .stripe-text img {
            width: 35px;
          }
          .warning svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          .success svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        `}</style>
        <style jsx>{`
          .main {
            padding: 30px;
          }
          .main input {
            width: 500px;
            max-width: 80vw;
          }
          .button-group {
            display: flex;
            align-items: center;
            gap: 20px;
          }
          .account .heading {
            display: flex;
            justify-content: space-between;
            padding: 15px 30px;
          }

          .account .heading h2 {
            font-size: 24px;
            font-weight: 600 !important;
            line-height: 29.26px;
            font-style: normal;
          }
          .form-label {
            display: flex;
            justify-content: space-between;
            width: 500px;
            max-width: 80vw;
          }
          .warning {
            display: flex;
            align-items: center;
            color: #dd4141;
            font-weight: 500;
            font-size: 14px;
          }

          .form-label label {
            font-weight: 600;
          }
          .success {
            color: #28aa2d;
          }
        `}</style>
      </div>
    </Layout>
  );
};

export default AccountPage;
