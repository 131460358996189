import React, { useEffect } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';

interface ProgressModalProps {
  show: boolean;
  progress: number;
  message: string;
  onCancel: () => void;
}

const ProgressModal: React.FC<ProgressModalProps> = ({ show, progress, message, onCancel }) => {
  useEffect(() => {
    if (progress === 0 && message === '') {
      console.warn('ProgressModal received empty values');
      console.trace();
    }
  }, [progress, message]);

  //console.log('ProgressModal rendering:', { show, progress, message });
  
  return (
    <Modal show={show} centered backdrop="static" keyboard={false}>
      <Modal.Body>
        <h4>{message}</h4>
        <ProgressBar now={progress} label={`${progress}%`} className="mb-3" />
        <div className="d-flex justify-content-end">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProgressModal;