import React, { useMemo } from 'react';
import { IconArrow } from '../../Common/HubIcons';
import { logClickButton } from '../../../../analytics';
import { AiFillCheckCircle } from 'react-icons/ai';
import { ILibraryScene, IMainState } from '../../../../interfaces';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import { getSceneVersionForDropdown } from '../../../utils/library';
import VersionsDropdown from '../../Common/VersionsDropdown';
import ButtonWithConfirmation from '../../Common/ButtonWithConfirmation';
import CollaboratorButton from '../../Common/Collaborators/CollaboratorButton';
import UndoRedoButton from '../../Common/UndoRedoButton';
import { TLanguage } from '../../../../mappers/polotno';
import LanguageSwitch from '../../Common/LanguageSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { actionHubAlertInfo } from '../../../../redux/actions';

const UPLOADING_MESSAGE =
  'Converting video from 3rd party site, stay on this page for a moment...';

const SLBuilderActions = ({
  onBack,
  scene,
  onSave,
  onActivate,
  onSelectVersion,
  preview,
  onEdit,
  isTemplate,
  onUndo,
  onRedo,
  activeLanguage = 'en',
  setActiveLanguage,
  listLanguages = []
}: {
  onBack: () => void;
  scene: ILibraryScene;
  onSave: (fresh?: boolean, loadEditor?: boolean) => void;
  onActivate?: () => void;
  onSelectVersion: (version: number) => void;
  preview?: {
    loading?: boolean;
    onToggle: () => void;
  };
  onEdit: () => void;
  isTemplate?: boolean;
  onUndo?: () => void;
  onRedo?: () => void;
  activeLanguage?: TLanguage;
  setActiveLanguage?: (lang: TLanguage) => void;
  listLanguages?: TLanguage[];
}) => {
  const { id, availableVersions, status } = scene || {};
  const { isLoading } = useLoadingStatus();
  const dispatch = useDispatch();
  const pexelVideoUploadStatus = useSelector(
    (state: IMainState) => state.clientState?.hub?.scene?.pexelsUploadStatus
  );

  const loadingSaving = isLoading(`saving-${id}`);

  const spinner = <Spinner animation="border" size="sm" />;

  const autoSaveStatus = (
    <div className={`btn text-success btn-sm btn-save`}>
      {loadingSaving ? (
        spinner
      ) : (
        <>
          <AiFillCheckCircle size={20} /> <strong>Auto Save</strong>
        </>
      )}

      <style jsx>{`
        .btn {
          cursor: initial;
          padding-right: 0 !important;
          width: 170px;
          margin-left: 0 !important;
          padding-left: 0 !important;
        }
      `}</style>
    </div>
  );

  const uploadingVideoIcon = (
    <div className="btn d-flex align-items-center position-relative">
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={
          <Popover id="upload-message-pop" className="p-2">
            {UPLOADING_MESSAGE}
          </Popover>
        }
      >
        <RiDownloadCloud2Line size={26} />
      </OverlayTrigger>
      <style jsx>{`
        .btn {
          width: auto !important;
          min-width: unset !important;
          padding: 0 !important;
          animation: fade 2s infinite;
        }
        @keyframes fade {
          0% {
            opacity: 0.2;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0.2;
          }
        }
      `}</style>
    </div>
  );

  const [loadingForEdit, setLoadingForEdit] = React.useState(false);

  const handleEdit = () => {
    onEdit();
    setLoadingForEdit(true);
  };

  const allVersions = getSceneVersionForDropdown(availableVersions);
  const hasDraftInVersion =
    allVersions.length > 1 &&
    availableVersions?.some((v) => v.status === 'draft');

  const isActivating = isLoading(`activating-${id}`);
  const isClosing = isLoading(`closing`);
  const disableActions = isActivating || isClosing || loadingForEdit;

  React.useEffect(() => {
    if (!loadingSaving) {
      setLoadingForEdit(false);
    }
  }, [loadingSaving]);

  const isUploadingVideo = useMemo(() => {
    return Object.values(pexelVideoUploadStatus || {}).some(
      (status) => status !== 'done'
    );
  }, [pexelVideoUploadStatus]);

  const onBackClick = (btnName: string) => {
    if (isUploadingVideo) {
      dispatch(actionHubAlertInfo(UPLOADING_MESSAGE));
    } else {
      logClickButton(btnName);
      onBack();
    }
  };

  const onActiveClick = () => {
    if (isUploadingVideo) {
      dispatch(actionHubAlertInfo(UPLOADING_MESSAGE));
    } else {
      logClickButton('Activate');
      onActivate();
    }
  };

  return (
    <div className="header-container">
      <div className="col-side d-flex">
        {isClosing ? (
          <>
            <div className="spin-container btn btn-sm">
              <Spinner animation="border" size="sm" />
            </div>
            <h2 />
          </>
        ) : (
          <>
            <button
              className="btn btn-sm btn-back"
              onClick={() => {
                onBackClick('Back as arrow');
              }}
            >
              <IconArrow direction="left" />
            </button>
            <h2
              onClick={() => {
                onBackClick('Back as title');
              }}
            >
              {isTemplate ? 'Template' : 'Scene'} Editor
            </h2>
          </>
        )}
      </div>
      {scene && !isClosing && (
        <div className="flex-grow-1 col-main d-flex">
          <div className="action-group action-title flex-grow-1">
            {status === 'draft' && !disableActions && (onUndo || onRedo) && (
              <UndoRedoButton
                onRedo={onRedo}
                onUndo={onUndo}
                disableUndo={!onUndo}
                disableRedo={!onRedo}
              />
            )}
            {status === 'activated' && (
              <div className="language-switch-container">
                <LanguageSwitch
                  activeLanguage={activeLanguage}
                  availableList={listLanguages}
                  onSelectLanguage={setActiveLanguage}
                />
              </div>
            )}
          </div>

          <div className="action-group action-btn d-flex">
            {!scene?.status && (
              <button
                onClick={() => {
                  onSave(true);
                  logClickButton('Generate');
                }}
                className="btn btn-dark btn-sm btn-round btn-generate"
              >
                {loadingSaving ? spinner : 'Generate'}
              </button>
            )}
            {isUploadingVideo && uploadingVideoIcon}
            {scene?.status && (
              <>
                {status === 'draft' && !disableActions && autoSaveStatus}
                {!disableActions && (
                  <CollaboratorButton
                    itemId={scene.id}
                    owner={scene.createdBy}
                    context="scene"
                  />
                )}

                {preview?.onToggle && !isActivating && (
                  <button
                    className="btn btn-sm btn-outline-dark btn-preview btn-round"
                    onClick={preview.onToggle}
                  >
                    {preview.loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      'Show Preview'
                    )}
                  </button>
                )}

                {!disableActions && (
                  <VersionsDropdown
                    versions={allVersions}
                    value={scene?.version}
                    onSelect={onSelectVersion}
                  />
                )}
                {onActivate && (
                  <>
                    {status === 'draft' ? (
                      <button
                        onClick={onActiveClick}
                        className="btn btn-round btn-dark btn-sm btn-activate"
                        disabled={isActivating}
                      >
                        {isActivating ? spinner : 'Activate'}
                      </button>
                    ) : (
                      <ButtonWithConfirmation
                        shouldShowConfirmation={hasDraftInVersion}
                        onClick={handleEdit}
                        minWidth="100px"
                        message="You have existing draft version of this scene Do you want to
                override?"
                        loading={loadingSaving}
                        label="Edit"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <style jsx>{`
        .btn-preview {
          width: 150px;
        }
        .col-side {
          align-items: center;
          width: 300px;
        }
        h2 {
          margin: 0;
          display: flex;
          align-items: end;
        }

        .btn-back,
        h2 {
          cursor: pointer;
        }

        .col-main {
          justify-content: space-between;
          padding-right: 20px;
        }

        .header-container {
          border-bottom: 1px solid #ccc;
          padding: 10px 0;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .action-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .action-btn :global(.btn:not(.btn-collaborator)) {
          margin-left: 10px;
          padding-left: 20px;
          padding-right: 20px;
          min-width: 120px;
        }
        .btn-design {
          border: 1px solid #005fc5 !important;
          margin-left: 20px;
        }
        .language-switch-container {
          float: right;
        }
        :global(.language-switch-container .language-switcher) {
          display: unset;
        }
      `}</style>
    </div>
  );
};

export default SLBuilderActions;
