export const getInviteContent = (name: string) => `${name} 様 

この度、5月20日より開催となりますFW21 デジタルトランクショー LOUBI AIRWAYSへ
ご招待させていただきたく思っております。 

デジタルトランクショーをご体験頂くにあたりまして、事前にブティックスタッフとのアポイントメントを承っております。アポイントメントは下記リンクより承ります！ 
※5月20日以降はいつでもご自身でデジタルトランクショーをご体験いただけます。

ご質問がございましたら、お気軽にお問い合わせください。 

ブティックスタッフ
`;

export const getInviteConfirmationContent = (
  name: string,
  date: string,
  time: string,
  url:string
) => `${name} 様

FW21 デジタルトランクショー LOUBI AIRWAYSへご参加ご希望いただきまして、誠にありがとうございます。 

下記内容でアポイントメントを承りました：
Date：${date}
Time：${time} 東京時間
URL: ${url}

シートベルトを締めて、離陸の準備をお願いいたします！

ブティックスタッフ`;


export const ecomStoreId = '71bc204e-f9a7-48da-9669-f43d9262fe6e';