import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { ITableColumn, SortTable } from '../../Common/Table';
import { cloneDeep } from 'lodash';
import { ApexOptions } from 'apexcharts';
import Tabs, { ITab } from '../../Common/Tabs';
import DeviceAnalyticsVisitor from './DeviceAnalyticsVisitor';
import { getStoriesInsightsFromDevice } from './service';
import { formatDurationFromMiliSeconds } from '../../../../utils/number';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

const DeviceAnalyticsStoriesDetails = ({
  store,
  startDate,
  endDate,
  userId,
  timeZone
}) => {
  const overviewTabs = ['views', 'average time spend (s)', 'QR Scans'];
  const [currentOverviewTab, setCurrentOverviewTab] = useState<ITab>({
    id: overviewTabs[0],
    label: overviewTabs[0]
  });
  const [data, setData] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!store.id) return;
    setLoading(true);
    getStoriesInsightsFromDevice({
      userId,
      startDate,
      endDate,
      timeZone,
      storeId: store?.id
    })
      .then((res) => {
        setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const chartAreaOptions: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false
      }
    },
    colors: ['#B63B3B', '#E89B55', '#6AC773', '#203DA5', '#9039B7', '#615E83'],
    stroke: {
      width: 1,
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep'
      ]
    },
    yaxis: {
      opposite: true
    }
  };

  const chartAreaSeries = [
    {
      name: 'IWC Rodeo Drive, Beverly Hills',
      data: [121, 40, 28, 51, 42, 109, 100, 12]
    },
    {
      name: 'IWC Costa Mesa',
      data: [113, 32, 4, 32, 34, 52, 41, 34]
    },
    {
      name: 'IWC Washington',
      data: [50, 8, 12, 18, 6, 30, 90, 34]
    },
    {
      name: 'Chopard Paris',
      data: [10, 32, 45, 32, 34, 52, 41, 34]
    },
    {
      name: 'IWC Geneva',
      data: [50, 8, 12, 18, 16, 30, 33, 34]
    },
    {
      name: 'IWC Brazil',
      data: [10, 32, 45, 32, 34, 52, 41, 34]
    }
  ];

  const dataColumns: ITableColumn[] = [
    {
      key: 'localisation',
      title: 'Stores',
      align: 'left'
    },
    {
      key: 'view',
      title: 'Views',
      align: 'center'
    },
    {
      key: 'ats',
      title: 'Average Time Spent (s)',
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    },
    {
      key: 'qr',
      title: 'QR Scans',
      align: 'center'
    }
  ];

  const fakeData = [
    {
      id: '1',
      localisation: 'Total',
      view: 254,
      ats: 44,
      qr: 12,
      styles: {
        color: '#615E83'
      },
      checked: true
    },
    {
      id: '2',
      localisation: 'IWC Rodeo Drive, Beverly Hills',
      view: 74,
      ats: 65,
      qr: 126,
      styles: {
        color: '#B63B3B'
      },
      stylesColumns: [0]
    },
    {
      id: '3',
      localisation: 'IWC Washington',
      view: 44,
      ats: 420,
      qr: 15,
      styles: {
        color: '#E89B55'
      },
      stylesColumns: [0]
    },
    {
      id: '4',
      localisation: 'Chopard Paris',
      view: 34,
      ats: 542,
      qr: 14,
      styles: {
        color: '#6AC773'
      },
      stylesColumns: [0]
    },
    {
      id: '5',
      localisation: 'IWC Geneva',
      view: 574,
      ats: 1423,
      qr: 121,
      styles: {
        color: '#203DA5'
      },
      stylesColumns: [0]
    },
    {
      id: '6',
      localisation: 'IWC Brazil',
      view: 57,
      ats: 142,
      qr: 11,
      styles: {
        color: '#9039B7'
      },
      stylesColumns: [0]
    },
    {
      id: '7',
      localisation: 'IWC Japan',
      view: 27,
      ats: 12,
      qr: 19,
      styles: {
        color: '#615E83'
      },
      stylesColumns: [0]
    }
  ];

  const [countriesData, setCountriesData] = useState(fakeData);

  const onRowCheck = (row) => {
    const index = countriesData.findIndex((r) => r.id === row.id);
    if (index !== -1) {
      const temp = cloneDeep(countriesData);
      temp[index].checked = !temp[index].checked;
      setCountriesData(temp);
    }
  };

  const hourlyViewOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#AE3BCD'],
    xaxis: {
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      labels: {
        formatter: (value) => `${parseInt(value) - 1}`,
        style: {
          colors: ['#615E83'],
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#615E83'],
          fontSize: '14px'
        }
      }
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const label = `${w.globals.seriesNames[seriesIndex]}, ${
          dataPointIndex === 12 ? 12 : dataPointIndex % 12
        }${dataPointIndex <= 12 ? 'am' : 'pm'}`;
        return `
        <div class="custom-tooltip">
          <div class="tooltip-label">${label}</div>
          <div class="tooltip-value">${value}</div>
        </div>
      `;
      },
      style: {
        fontSize: '12px',
        fontFamily: undefined
      }
    }
    // title: {
    //   text: 'HeatMap Chart (Single color)'
    // },
  };

  const generateData = (count, yrange) => {
    let i = 0;
    const series = [];
    while (i < count) {
      const x = (i + 1).toString();
      const y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y
      });
      i++;
    }
    return series;
  };

  const generateSeries = () => {
    const series = [];
    for (let index = 0; index < 29; index++) {
      series.push({
        name: `${index + 1} Oct`,
        data: generateData(24, {
          min: 0,
          max: 500
        })
      });
    }
    return series;
  };

  const hourlyViewSeries = generateSeries();

  return (
    <>
      <div className="analytics-main">
        <div className="overview-linechart card-section">
          <Tabs
            dot={true}
            onSelectTab={setCurrentOverviewTab}
            tabs={overviewTabs.map((t) => ({ id: t, label: t }))}
            active={currentOverviewTab}
          />
          <Chart
            options={chartAreaOptions}
            series={chartAreaSeries}
            type="area"
            height="350"
          />
        </div>
        <div className="overview-table card-section">
          <SortTable
            rowCheckbox
            onCheckboxClick={onRowCheck}
            columns={dataColumns}
            sorts={['view', 'qr', 'ats']}
            data={countriesData}
          />
        </div>
        <div className="overview-heatmap">
          <div className="card-section">
            <div>
              <label>Global Hourly Views Density (UTC +8)</label>
              <Chart
                options={hourlyViewOptions}
                series={hourlyViewSeries}
                height="600"
                width="100%"
                type="heatmap"
              />
            </div>
          </div>
        </div>
        <DeviceAnalyticsVisitor />
      </div>

      <style jsx>{`
        .link-type {
          display: flex;
          align-items: center;
        }
        .analytics-main {
          overflow: auto;
          max-height: calc(100vh - 200px);
          padding: 10px;
        }
        .analytics-main ::global(.device-visitor-wrapper) {
          padding: 0 !important;
        }
        :global(.overview-linechart .tab) {
          font-size: 14px !important;
          padding: 7px 10px !important;
          text-transform: capitalize;
        }
        :global(.overview-linechart .analytics-tabs) {
          border-bottom: none;
        }
        .card-section {
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
          padding: 20px;
          margin-bottom: 20px;
        }
        .card-section label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          font-weight: 400;
        }
        .overview-link {
          margin: 20px 0;
          display: grid;
          gap: 20px;
          grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        }
        .overview-link .card-section h4 {
          color: #484848;
          font-size: 15px;
          font-weight: 500;
        }
        .overview-link .card-section {
          color: #484848;
          font-size: 14px;
          font-weight: 400;
        }
        .badge-icon {
          background: purple;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          margin: 0 5px;
        }
        .overview-link span {
          color: #8f6599;
          font-weight: 500;
        }
      `}</style>
    </>
  );
};

export default DeviceAnalyticsStoriesDetails;
