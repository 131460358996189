import { last } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

const UploadButton = ({
  file,
  fileUrl,
  onUpload,
  onDelete,
  accept = '*',
  className = '',
  wrapperClassName = '',
  fallback = '',
  onClickUpload,
  integratedWithAssetLib
}: {
  file?: File;
  fileUrl?: string;
  onUpload?: (file: File) => Promise<void>;
  onDelete?: () => Promise<any>;
  onClickUpload?: () => void;
  children?: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  accept?: string;
  fallback?: string;
  removing?: boolean;
  integratedWithAssetLib?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [loading, setLoading] = useState(false);
  const handleClickUpload = () => {
    onClickUpload?.();
    // inputRef.current.click();
  };
  const fileName = file?.name || last(fileUrl?.split('/'));
  const handleDelete = () => {
    setLoading(true);
    onDelete().finally(() => setLoading(false));
  };
  useEffect(() => {
    if (integratedWithAssetLib && file) {
      onUpload(file).finally(() => setLoading(false));
    }
  }, [integratedWithAssetLib, file]);
  
  return (
    <div className={`d-flex ${wrapperClassName}`}>
      <input
        onChange={(e) => {
          const file = e.currentTarget.files[0];
          setLoading(true);
          onUpload(file).finally(() => setLoading(false));
        }}
        key={fileUrl}
        ref={inputRef}
        className="d-none"
        type="file"
        accept={accept}
      />
      <Button
        onClick={fileUrl ? handleDelete : handleClickUpload}
        variant="dark"
        className={`${className} rounded-pill d-inline-block`}
        style={{ minWidth: 100 }}
      >
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : fileUrl ? (
          'Remove'
        ) : (
          'Upload'
        )}
      </Button>
      {(fileName || fallback) && (
        <span className="ml-3 filename">{fileName || fallback}</span>
      )}

      <style jsx>{`
        .filename {
          border-bottom: 1px solid #d8d8d8;
          display: inline-block;
          padding-bottom: 0.3rem;
          min-width: 70%;
        }
        @media (max-width: 567.78px) {
          .filename {
            min-width: 60%;
          }
        }
      `}</style>
    </div>
  );
};

export default UploadButton;
