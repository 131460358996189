import { storyAssetBaseUrl } from '../../../../config';
import { IStorybook } from '../../../../interfaces';
import { mapResponseToStorybook } from '../../../../mappers/storybook';
import { mapBrowerLanguageToSBLanguage } from '../../../../utils/window';
import { apigClient } from '../../../clientSideServices/aws';
import { getLanguaImageFromJSON } from '../../../utils/storybook';

export type SLInsightQuery = {
  startDate: string;
  timeZone: string;
  sceneId: string;
  endDate: string;
  campaign: string;
};
const insightApiUrl =
  'https://vvi8sqrrbk.execute-api.ap-southeast-1.amazonaws.com/prod';

export const getSceneInsightOverview = async ({
  startDate,
  endDate,
  sceneId,
  timeZone,
  campaign
}: SLInsightQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = `/sceneAnalytics/v1/getSceneInsights`;
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    { queryParams: { startDate, endDate, sceneId, timeZone, campaign } },
    {}
  );
  const topSbs = result.data?.topStorybooks || [];
  const sbs = await mapSceneStorybookOverview(topSbs);
  return {
    ...result.data,
    topStorybooks: sbs
  };
};

export const getSceneInsightStorybookOverview = async ({
  startDate,
  endDate,
  sceneId,
  timeZone,
  campaign
}: SLInsightQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = `/sceneAnalytics/v1/getSceneInsightsStorybookOverview`;
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    { queryParams: { startDate, endDate, sceneId, timeZone, campaign } },
    {}
  );
  const sbs = result.data?.storybooks || [];
  return mapSceneStorybookOverview(sbs);
};

export const getStorybook = async (id: string): Promise<IStorybook> => {
  const client = await apigClient();
  const pathToOnlyActivated = `/storybooks/v1/activated/${id}`;
  const result = await client.invokeApi({}, pathToOnlyActivated, 'GET', {});
  const storybook = mapResponseToStorybook(result.data);
  const pages = storybook?.pages;
  const language = mapBrowerLanguageToSBLanguage(navigator.language);
  const featuredImage = getLanguaImageFromJSON(
    storybook.featuredImage,
    language
  );
  const firstPageThumbnail = getLanguaImageFromJSON(
    pages?.[0]?.thumbnail || pages?.[0]?.url,
    language
  );
  const thumbnailUrl = featuredImage
    ? `${storyAssetBaseUrl}/${featuredImage}`
    : firstPageThumbnail;

  return {
    ...storybook,
    featuredImage: thumbnailUrl
  };
};

export const mapSceneStorybookOverview = (data: any[]) => {
  const promises = data.map((d) => {
    const id = d.storybook || d.storybookId;
    if (!id) return Promise.resolve(d);
    return getStorybook(d.storybook || d.storybookId)
      .then((res) => ({
        ...d,
        storybook: res
      }))
      .catch(() => undefined);
  });
  return Promise.all(promises).then((sb) => sb.filter(Boolean));
};

export const getSceneInsightStorybookDetails = async ({
  startDate,
  endDate,
  sceneId,
  timeZone,
  campaign,
  storybookId
}: SLInsightQuery & { storybookId: string }) => {
  const client = await apigClient(insightApiUrl);
  const path = `/sceneAnalytics/v1/getSceneInsightsStorybookInsights`;
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        sceneId,
        timeZone,
        campaign,
        storybookId
      }
    },
    {}
  );
  return result.data;
};

export const getSceneInsightInteractionOverview = async ({
  startDate,
  endDate,
  sceneId,
  timeZone,
  campaign
}: SLInsightQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = `/sceneAnalytics/v1/getSceneInsightsInteractionsOverview`;
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        sceneId,
        timeZone,
        campaign
      }
    },
    {}
  );
  const interactions = result.data?.interactions || [];
  return mapSceneStorybookOverview(interactions);
};
