import { size } from 'lodash';
import React from 'react';
import { BsPencil } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { getUserByIdentityId } from '../../../clientSideServices/users';
import { IMainState } from '../../../interfaces';
import { decryptMessage, getMessageDisplayText } from '../../../utils/chat';
import {
  DID_CLICK_CHAT_CONVERSATION,
  DID_CLICK_EDIT_CLIENT_NAME
} from '../../../utils/constants';
import { PageView } from '../../../utils/pageView';
import { hubNavTo } from '../../utils/hubPagesRoute';
import ChatContactTimestamp from './ChatContactTimestamp';
import EditContactNameModal from './EditContactNameModal';
import { HubChatContext } from './HubChatContext';

const ChatContact = ({
  contact,
  index,
  isMobile,
  keywords
}: {
  contact: any;
  index?: number;
  isMobile: boolean;
  keywords: string;
}) => {
  const hubUser = useSelector(
    (state: IMainState) => state.clientState?.hub?.user
  );
  const [showEdit, setShowEdit] = React.useState<boolean>(false);

  const {
    currentConversation,
    setCurrentConversation,
    addChatUsers,
    chatUsers
  } = React.useContext(HubChatContext);

  const userName =
    chatUsers?.[contact?.conversationCreatedBy]?.alias || 'Customer';
  const user = chatUsers?.[contact?.conversationCreatedBy];

  const [contactName, setContactName] = React.useState<string>('');

  const messageBody = decryptMessage(contact?.lastMessage?.messageBody);
  const messageText = getMessageDisplayText(messageBody);

  const onClickConversation = () => {
    logEvent(DID_CLICK_CHAT_CONVERSATION, DID_CLICK_CHAT_CONVERSATION, {
      advisorId: hubUser?.id,
      conversationID: contact?.conversationId
    });
    setCurrentConversation(contact?.conversationId);
    if (isMobile) {
      hubNavTo(PageView.HUB_CHAT_DETAILS);
    }
  };

  const getUserDetails = () => {
    if (!chatUsers?.[contact?.conversationCreatedBy]) {
      getUserByIdentityId(contact?.conversationCreatedBy)
        .then(({ data }) => {
          addChatUsers(contact?.conversationCreatedBy, {
            ...data
          });
        })
        .catch((error) => {
          const customerNumber = chatUsers ? size(chatUsers) + 1 : index + 1;
          addChatUsers(contact?.conversationCreatedBy, {
            alias: `Customer ${customerNumber}`
          });
          console.log('Error is', error);
        });
    }
  };

  React.useEffect(() => {
    if (contact?.conversationCreatedBy) {
      getUserDetails();
    }
  }, [contact?.conversationCreatedBy]);

  const timestamp = messageBody?.timestamp || contact?.createdAt || null;
  if (
    keywords &&
    !userName?.toLowerCase().includes(keywords.toLocaleLowerCase())
  ) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => onClickConversation()}
        className={`chat-item ${
          currentConversation === contact?.conversationId && `active`
        }`}
      >
        <BsPencil
          className="mr-2"
          onClick={(e) => {
            e.stopPropagation();
            setContactName(userName);
            logEvent(DID_CLICK_EDIT_CLIENT_NAME);
            setShowEdit(true);
          }}
        />
        <span className="customer-name">{userName}</span>
        <div className="footer-wrapper">
          <div className={`message`}>{messageText}</div>
          <div className="chat-timestamp">
            <ChatContactTimestamp timestamp={timestamp} />
          </div>
        </div>
        <style jsx>{`
          .chat-item {
            padding: 20px 20px 10px 20px;
            position: relative;
            border-bottom: 1px solid #c4c4c4;
            background: #fff;
            cursor: pointer;
          }
          .chat-item.disabled {
            cursor: default;
          }
          .chat-item.active {
            background: #efefef;
          }
          .customer-name {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
          }
          .footer-wrapper {
            margin-top: 11px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .message {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 75%;
            color: #666363;
            font-size: 12px;
          }
          .chat-timestamp {
            color: #666363;
            font-size: 12px;
          }

          .message.no-bubble {
            max-width: 100%;
          }
          .new-message {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 22px;
            height: 22px;
            border-radius: 100%;
            margin-left: 10px;
            background: #828282;
            color: white;
            align-items: center;
            display: flex;
            justify-content: center;
          }

          .chat-actions {
            position: absolute;
            right: 10px;
            bottom: 10px;
          }

          .chat-action-btn {
            font-size: 12px;
            line-height: 15px;
            border-radius: 10px;
          }

          .chat-action-btn:focus {
            box-shadow: none;
          }

          .chat-action-btn.accept {
            background: #72cb64;
            color: #fff;
          }

          .chat-action-btn.decline {
            background: #dd3444;
            color: #fff;
          }

          .customer-status-indicator {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: #828282;
          }

          .customer-status-indicator.online {
            background: #008700;
          }
        `}</style>
      </div>
      <EditContactNameModal
        name={contactName}
        show={showEdit}
        onToggle={setShowEdit}
        user={user}
        identityId={contact?.conversationCreatedBy}
      />
    </>
  );
};

export default ChatContact;
