import addMinutes from 'date-fns/addMinutes';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFolder } from '../../../components/hooks/folder';
import { isValidName } from '../../../components/VirtualBoutique/Appointment/inputValidator';
import { getRegionForStore, getSessionUrl } from '../../../config';
import {
  HUB_USER_PERMISSION,
  IMainState,
  ISessionConvertedFile,
  ISessionFormData,
  ISessionParticipant,
  IStorybook,
  ProgressStatus,
  StoryBookLayoutPage
} from '../../../interfaces';
import {
  mapSessionConvertedFilePayloadToFileList,
  mapSessionFormDataToPayload
} from '../../../mappers/session';
import {
  actionHubSessionUpdateFileUploadStatus,
  actionHubUpdateSessionFormData
} from '../../../redux/actions';
import { actionSaveSessionsAsync } from '../../../redux/advisorHubAsyncActions';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { getFormattedFullName } from '../../../utils/shoppingCart';
import { shouldShowStorybooks } from '../../utils/hubEntitlements';
import { getCorrectHubHost } from '../../utils/hubPagesRoute';
import { HubContext } from '../HubContext';
import { SBActionTarget } from '../StoryBook/SBActionHandler';
import { StoryBookContext } from '../StoryBook/SBContextContainer';
import StoryBook from './../StoryBook';
import DateTimeRangeSelector from './DateTimeRangeSelector';
import SessionContainer from './SessionContainer';
import SessionFileUpload from './SessionFileUpload';
import SessionLinksWrapper from './SessionLinksWrapper';
import SessionParticipants from './SessionParticipants';
import SessionRegionSelector from './SessionRegionSelector';
import SessionStorybook from './SessionStorybook';
import SessionTimezone from './SessionTimezone';

interface IFormPristine {
  topic: boolean;
  description: boolean;
  participants: boolean;
  timezone: boolean;
  region: boolean;
}

const SessionCreateEdit = ({
  isEdit,
  isMeetingRoom
}: {
  isEdit?: boolean;
  isMeetingRoom?: boolean;
}) => {
  const dispatch = useDispatch();
  const {
    color,
    brandId,
    user: hubUser,
    meetUser,
    chooseCheckPermissionResult,
    checkUserPermissions
  } = React.useContext(HubContext);
  const { action } = React.useContext(StoryBookContext);
  const hubState = useSelector((state: IMainState) => state.clientState?.hub);
  const userState = hubState?.user;
  const storeId = hubState?.user?.storeId;
  const formData = hubState?.sessions?.formData;
  const isSubmitting =
    hubState?.sessions?.progressStatus === ProgressStatus.LOADING;
  const isUploadingFile = hubState?.sessions?.fileUploadStatus?.some(
    (status) => status.progressPercentage < 100
  );
  const date = hubState?.sessions?.date || new Date();
  const submitCTA = isSubmitting ? 'Saving...' : 'Save';

  const { selectFolderKey } = useFolder({
    uuid: hubUser?.id,
    userId: hubUser?.id,
    context: 'storybook'
  });

  const [pristine, setPristine] = React.useState<IFormPristine>({
    topic: true,
    description: true,
    participants: true,
    timezone: !isMeetingRoom,
    region: true
  });

  const [openStorybookPopup, setOpenStorybookPopup] = React.useState(false);
  const [openContentPreview, setOpenContentPreview] = React.useState(false);

  const mandatoryFields: (keyof ISessionFormData)[] = isMeetingRoom
    ? ['topic', 'participants', 'region']
    : [
        'topic',
        'startDate',
        'endDate',
        'startTime',
        'endTime',
        'timezone',
        'participants',
        'region'
      ];

  const handlePreviewStorybook = (id: string) => {
    action.read(SBActionTarget.ActivatedStorybook, id, () =>
      setOpenContentPreview(true)
    );
  };

  const handlePreviewFile = (file?: ISessionConvertedFile) => {
    if (!file) return;
    setOpenContentPreview(true);
    const iFile = mapSessionConvertedFilePayloadToFileList(undefined)(file);
    const fileAsStorybook: IStorybook = {
      title: iFile.title,
      pages: iFile.pages.map((url, index) => ({
        title: '',
        subtitle: '',
        url,
        thumbnail: iFile.thumbnails[index],
        pageType: 'file'
      }))
    };

    action.update(SBActionTarget.Storybook, { data: fileAsStorybook });
  };

  const invalidTopic = !pristine.topic && !isValidName(formData?.topic);
  const invalidParticipants =
    !pristine.participants &&
    (!formData?.participants || isEmpty(formData?.participants));
  const invalidDateTime =
    formData?.startDate > formData?.endDate ||
    formData?.startTime > formData?.endTime;
  const invalidTimezone =
    !pristine.timezone && !isValidName(formData?.timezone);
  const invalidRegion = !pristine.region && !isValidName(formData?.region);

  const removePristine = () => {
    const nonPristine: IFormPristine = {
      topic: false,
      description: false,
      participants: false,
      timezone: false,
      region: false
    };
    setPristine(nonPristine);
  };
  const sessionURL = getSessionUrl({
    storeId: storeId,
    sessionId: formData?.id,
    region: formData?.region,
    host: `https://${getCorrectHubHost(hubState?.user?.brandId)}`
  });

  const setFormData = (data: ISessionFormData) => {
    dispatch(actionHubUpdateSessionFormData(data));
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value, name } = event?.target;
    setPristine({ ...pristine, [name]: false });
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDescriptionChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value, name } = event?.target;
    setPristine({ ...pristine, [name]: false });
    const newLineRemoved = value.replace(/\n/g, ' ');

    setFormData({
      ...formData,
      [name]: newLineRemoved
    });
  };

  const handleTextareaKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleTimezone = (timezone: string) => {
    setFormData({
      ...formData,
      timezone
    });
  };

  const handleRegion = (region: string) => {
    setPristine({ ...pristine, region: false });
    setFormData({
      ...formData,
      region
    });
  };

  const handleCheckboxChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const handleMuteVideo = (key: string, val: boolean) => {
    setFormData({
      ...formData,
      [key]: val
    });
  };

  const handleAddStorybooks = (ids: string[]) => {
    setFormData({
      ...formData,
      storybooks: ids.length ? ids : undefined
    });
  };

  const onSave = () => {
    removePristine();

    if (mandatoryFields.some((field) => !formData?.[field])) {
      return;
    }

    if (
      !isValidName(formData?.topic) ||
      invalidDateTime ||
      invalidParticipants ||
      !isValidName(formData?.timezone) ||
      !isValidName(formData?.region)
    ) {
      return;
    }

    if (isSubmitting) {
      return;
    }

    const payload = mapSessionFormDataToPayload(
      formData,
      userState?.id,
      storeId,
      brandId,
      isEdit,
      isMeetingRoom
    );
    dispatch(actionSaveSessionsAsync(payload, isEdit));
  };

  const onResetForm = () => {
    dispatch(actionHubUpdateSessionFormData(null));
    dispatch(actionHubSessionUpdateFileUploadStatus(null));
  };

  React.useEffect(() => {
    if (isEdit) {
      removePristine();
      return;
    }

    const newTime = new Date();

    const startDate = formData?.startDate || date;
    const endDate = formData?.endDate || date;
    const startTime = formData?.startTime || newTime;
    const endTime = formData?.endTime || addMinutes(newTime, 60);

    const participants: ISessionParticipant[] = [
      {
        name:
          userState?.name ||
          getFormattedFullName(hubUser?.first_name, hubUser?.last_name) ||
          'User name',
        email: userState?.email,
        role: 'Host',
        noWaitingLounge: 1
      }
    ];

    setFormData({
      ...formData,
      id: generateV4UUID(),
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      joinAnytime: true,
      muteUponEntry: false,
      region: getRegionForStore(storeId),
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Singapore',
      participants,
      eventCountdownPage: true
    });
  }, []);

  const storybookPopup = (
    <StoryBook
      onClose={() => setOpenStorybookPopup(false)}
      onSelect={handleAddStorybooks}
      selectedStorybooks={formData?.storybooks || []}
      layoutMode={{
        popupMode: true,
        selectMode: true,
        previewMode: 'presentation'
      }}
    />
  );

  const contentPreviewPopup = (
    <StoryBook
      onClose={() => {
        setOpenContentPreview(false);
        action.update(SBActionTarget.Storybook, { data: {} });
      }}
      initPage={StoryBookLayoutPage.PREVIEW}
      layoutMode={{
        popupMode: true,
        viewOnlyMode: true,
        previewMode: 'presentation'
      }}
    />
  );

  const getTitle = () => {
    if (isMeetingRoom) {
      return isEdit ? 'EDIT MEETING ROOM' : 'CREATE MEETING ROOM';
    }
    return isEdit ? 'EDIT SESSION' : 'CREATE SESSION';
  };

  return (
    <SessionContainer title={getTitle()}>
      <div className="top">
        <div className="row content">
          <div className="col-md-12 col-lg-6">
            <div className="input-container">
              <label className="input-label">Topic</label>
              <input
                className="form-control"
                type="text"
                name="topic"
                value={formData?.topic || ''}
                placeholder="Session Topic"
                onChange={handleInputChange}
              />
              {invalidTopic && <span className="text-danger"> Required</span>}
            </div>
            {isMeetingRoom ? null : (
              <>
                <div className="input-container">
                  <label className="input-label">Date & Time</label>
                  <DateTimeRangeSelector
                    invalidDateTime={invalidDateTime}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
                <div className="timezone-select">
                  <label className="link-label">Timezone: </label>
                  <SessionTimezone
                    timezone={formData?.timezone}
                    onChange={handleTimezone}
                  />
                  {invalidTimezone && (
                    <span className="text-danger"> Required</span>
                  )}
                </div>
              </>
            )}
            <div className="timezone-select">
              <label className="link-label">Region: </label>
              <SessionRegionSelector
                storeId={storeId}
                region={formData?.region}
                onChange={handleRegion}
              />
              {invalidRegion && <span className="text-danger"> Required</span>}
            </div>
            <div className="input-container">
              <label className="input-label">Description</label>
              <textarea
                className="form-control"
                name="description"
                placeholder="Session Description"
                value={formData?.description || ''}
                onKeyDown={handleTextareaKeyDown}
                onChange={handleDescriptionChange}
                rows={5}
              />
            </div>

            {chooseCheckPermissionResult(
              shouldShowStorybooks({
                storeId,
                brandId,
                permissions: meetUser?.permissions,
                userType: hubUser?.userType
              }),
              checkUserPermissions(HUB_USER_PERMISSION.STORYBOOK)
            ) && (
              <div className="input-container">
                <SessionStorybook
                  storybooks={formData?.storybooks || []}
                  onOpenStorybook={() => {
                    setOpenStorybookPopup(true);
                    selectFolderKey('/');
                  }}
                  updateFormData={handleAddStorybooks}
                  onView={handlePreviewStorybook}
                />
              </div>
            )}

            <div className="input-container">
              <SessionFileUpload
                uploadedFiles={formData?.uploadedFiles || []}
                sessionID={formData?.id}
                isUploadingFile={isUploadingFile}
                onView={handlePreviewFile}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <SessionParticipants
              sessionFormData={formData}
              invalidParticipants={invalidParticipants}
            />
            <div className="input-container">
              <label className="input-label">Settings</label>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={!!formData?.hostMuteVideo || false}
                    onChange={() =>
                      handleMuteVideo('hostMuteVideo', !formData?.hostMuteVideo)
                    }
                    className="select-box"
                  />{' '}
                  Mute host video
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={!!formData?.participantMuteVideo || false}
                    onChange={() =>
                      handleMuteVideo(
                        'participantMuteVideo',
                        !formData?.participantMuteVideo
                      )
                    }
                    className="select-box"
                  />{' '}
                  Mute participants video
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={!!formData?.muteUponEntry || false}
                    onChange={(event) =>
                      handleCheckboxChange('muteUponEntry', event)
                    }
                    className="select-box"
                  />{' '}
                  Mute participants audio upon entry
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={!!formData?.joinAnytime || false}
                    onChange={(event) =>
                      handleCheckboxChange('joinAnytime', event)
                    }
                    className="select-box"
                  />{' '}
                  Auto admit participant to join
                </label>
              </div>

              {isMeetingRoom ? null : (
                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={!!formData?.eventCountdownPage || false}
                      onChange={(event) =>
                        handleCheckboxChange('eventCountdownPage', event)
                      }
                      className="select-box"
                    />
                    {` `}
                    Countdown Page
                  </label>
                </div>
              )}
            </div>
            <div className="input-container">
              <label className="input-label">Links</label>
              <div className="links-wrapper">
                <SessionLinksWrapper sessionURL={sessionURL} />
              </div>
            </div>
          </div>
        </div>
        <div className="footer text-right">
          <button
            className="btn footer-btn btn-round btn-lg"
            onClick={() => {
              onResetForm();
              window.history.back();
            }}
            onTouchStart={() => {
              onResetForm();
              window.history.back();
            }}
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting || isUploadingFile}
            onClick={() => onSave()}
            onTouchStart={() => onSave()}
            className="btn footer-btn save-btn btn-round btn-dark btn-lg ml-2"
          >
            {submitCTA}
          </button>
        </div>
      </div>
      <style jsx>
        {`
          .uploaded-files-viewer {
            position: relative;
          }
          .uploaded-files-viewer .files {
            display: none;
          }
          .uploaded-files-viewer:hover .files {
            display: block;
            position: absolute;
            top: 30px;
          }
          .sessions-container {
            padding: 50px 70px 50px;
          }
          .back-btn {
            padding-left: 0;
          }
          :global(.back-btn > svg) {
            width: 28px;
            height: auto;
          }
          :global(.btn:focus) {
            outline: none;
            box-shadow: none;
          }
          .overview-text {
            font-weight: 500;
          }
          .content {
            margin-top: 20px;
          }

          .pr-10 {
            padding-right: 10px;
          }

          .pl-10 {
            padding-left: 10px;
          }

          :global(.sessions-container .input-label) {
            font-weight: 500;
            margin-top: 15px;
            margin-bottom: 5px;
            text-transform: uppercase;
          }

          .sessions-container input,
          textarea {
            width: 100%;
          }

          .sessions-container input.select-box {
            width: auto;
            margin-left: 10px;
            font-weight: normal;
          }

          .flex-center-items {
            display: flex;
            align-items: center;
          }

          .countdown-checkbox {
            margin-top: 15px;
            margin-bottom: 5px;
          }

          .links-wrapper {
            width: 100%;
          }

          .input-container {
            margin-bottom: 20px;
          }
          .footer {
            border-top: 2px solid ${color?.accent};
            padding-top: 20px;
          }
          .footer-btn {
            border: 1px solid;
            padding: 5px 20px;
          }

          .save-btn {
            border: none;
          }

          .timezone-select {
            margin-top: 15px;
          }

          .video-mute-options {
            margin-right: 30px;
          }

          .radio-input {
            width: initial !important;
            margin: 0 5px;
          }

          @media (max-width: 480px) {
            .sessions-container {
              padding: 30px;
            }

            .content {
              margin-top: 10px;
            }

            .footer {
              position: initial;
              bottom: 0;
              margin-top: 40px;
            }
          }
        `}
      </style>
      {openStorybookPopup && storybookPopup}
      {openContentPreview && contentPreviewPopup}
    </SessionContainer>
  );
};

export default SessionCreateEdit;
