import React from 'react';
import PopupContainer from '../../Common/PopupContainer';
import SLOverview from '../../SceneLibrary/Overview/SLOverview';
import { HubContext } from '../../HubContext';
import { getIdentityId } from '../../../../utils/identity';
import { COLLABORATED_ITEMS, TRASH_FOLDER } from '../../../utils/folders';
import SearchInput from '../../Common/SearchInput';
import { ILibraryScene } from '../../../../interfaces';

function ShowcasedScenePopup({
  onClose,
  onAdd,
  addedScenes
}: {
  onClose: () => void;
  onAdd: (scene: ILibraryScene[]) => void;
  addedScenes: ILibraryScene[];
}) {
  const { user } = React.useContext(HubContext);
  const userId = getIdentityId() || user?.id;
  const [selectedScenes, setSelectedScenes] = React.useState<ILibraryScene[]>(
    []
  );
  const [keywords, setKeywords] = React.useState('');
  const onSelect = (scene: ILibraryScene) => {
    setSelectedScenes((prev) => {
      if (prev.find((s) => s.id === scene.id)) {
        return prev.filter((s) => s.id !== scene.id);
      }
      return [...prev, scene];
    });
  };

  const handleOnConfirm = () => {
    onAdd(selectedScenes);
    onClose();
  };

  const added = React.useMemo(() => {
    return [...addedScenes, ...selectedScenes].map((scene) => scene.id);
  }, [addedScenes, selectedScenes]);

  return (
    <PopupContainer
      maxWidth="1600px"
      maxHeight="calc(100vh - 90px)"
      zIndex={99999}
    >
      <div className="scene-container">
        <div className="popup-body">
          <div className="scene-header">
            <h5>Select Scenes</h5>
            <SearchInput onSearch={setKeywords} rounded searchOnTyping />
          </div>
          <div className="scene-list">
            <SLOverview
              onSelect={onSelect}
              userId={userId}
              keywords={keywords}
              selectedScenes={added}
              excludeFolders={[TRASH_FOLDER, COLLABORATED_ITEMS]}
            />
          </div>
        </div>
      </div>

      <div className="popup-footer d-flex w-100">
        <button className="btn btn-round btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn btn-round btn-dark" onClick={handleOnConfirm}>
          {/* {uploading ? <Spinner animation="border" size="sm" /> : 'Confirm'} */}
          Confirm
        </button>
      </div>
      <style jsx global>{`
        .SBSearch .header-container,
        .SBSearch .action-footer {
          display: none !important;
        }
      `}</style>
      <style jsx>{`
        .scene-container {
          gap: 10px;
          width: 100%;
        }
        .scene-header {
          padding: 0 10px 10px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #d8d8d8;
        }
        h5 {
          font-weight: bold;
        }
        .popup-body {
          padding: 15px 0;
          display: flex;
          height: calc(100vh - 223px);
          flex-direction: column;
        }
        .scene-list {
          flex: 1;
          position: relative;
        }
        .scene-list > ::global(div) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .popup-header {
          padding: 15px 10px 10px 20px;
        }
        .upload {
          width: 400px;
          display: flex;
          flex-direction: column;
        }
        .asset-lib {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .popup-footer {
          justify-content: flex-end;
          gap: 10px;
          padding: 10px 0;
        }
        .btn-round {
          border-radius: 100px;
          border: 1px solid rgba(0, 0, 0, 0.5);
          font-weight: 500;
        }
        .btn-dark {
          background: #000;
        }
      `}</style>
    </PopupContainer>
  );
}

export default ShowcasedScenePopup;
