import PoweredByInspify from './../../components/Legal/PoweredByInspify';


const Footer = ({ theme }: {
  theme?: 'dark' | 'light' | 'transparent';
}) => {
  return (
    <div className="footer">
      <PoweredByInspify
        color={theme === 'light' ? 'dark' : 'light'}
        position="center center"
      />
      <style jsx>{`
        .footer {
          height: auto;
          padding: 5px 0;
          margin: auto;
        }
        .footer :global(a) {
          margin-top: 0;
          height: 60px;
        }
      `}</style>
    </div>
  );
};

export default Footer;
