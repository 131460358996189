import React from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import Switch from 'react-switch';
import {
  ChatConnectionContext,
  ChatUserType,
  WSChatUserAvailability
} from '../../../components/ChatConnectionContext';
import { IMainState } from '../../../interfaces';
import { DID_CHANGE_ADVISOR_AVAILABILITY } from '../../../utils/constants';
import { HubChatContext } from './HubChatContext';

const ChatAvailabilityButton = ({
  theme = 'dark'
}: {
  theme?: 'dark' | 'light' | 'transparent';
}) => {
  const isDarkTheme = theme === 'dark';

  const hubUser = useSelector(
    (state: IMainState) => state.clientState?.hub?.user
  );

  const { setChatUserAvailability } = React.useContext(ChatConnectionContext);

  const { isOnline } = React.useContext(HubChatContext);

  const onChangeVisibility = (checked) => {
    const availability = checked
      ? WSChatUserAvailability.AVAILABLE
      : WSChatUserAvailability.UNAVAILABLE;
    logEvent(DID_CHANGE_ADVISOR_AVAILABILITY, DID_CHANGE_ADVISOR_AVAILABILITY, {
      advisorId: hubUser?.id,
      storeId: hubUser?.associatedStoreId || hubUser?.storeId
    });
    setChatUserAvailability(
      hubUser?.id,
      hubUser?.associatedStoreId || hubUser?.storeId,
      availability,
      ChatUserType.AGENT
    );
  };

  return (
    <div className="availability-wrapper">
      <span className="chat-availability-state">
        {isOnline
          ? WSChatUserAvailability.AVAILABLE
          : WSChatUserAvailability.UNAVAILABLE}
      </span>
      <Switch onChange={onChangeVisibility} checked={isOnline} />
      <style jsx>
        {`
          .availability-wrapper {
            margin-left: 100px;
            display: flex;
            align-items: center;
          }
          .indicator {
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background: #2faa59;
          }

          .indicator.offline {
            background: red;
          }

          .chat-availability-state {
            color: ${isDarkTheme ? '#C4C4C4' : '#000'};
            font-size: 16px;
            margin-right: 10px;
          }

          @media (max-width: 480px) {
            .availability-wrapper {
              margin-left: 0;
            }
            .chat-availability-state {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ChatAvailabilityButton;
