/* eslint-disable @typescript-eslint/no-empty-function */
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  removeBrandAssets,
  updateBrand,
  uploadBrandAsset
} from '../../../clientSideServices/brand';
import { isResourceFromAssetLib } from '../../../config';
import { IAccount, IAsset, IBrandSetup } from '../../../interfaces';
import {
  actionHubAlertError,
  actionSetPopupAssets
} from '../../../redux/actions';
import {
  DID_UPDATE_BRAND_ASSETS_FAILED,
  DID_UPDATE_BRAND_ASSETS_SUCCESSFUL,
  HUB_BRANDING_EVENT,
  DID_CLICK_BRANDING_DELETE_FILE,
  DID_CLICK_BRANDING_PREVIEW,
  DID_CLICK_BUTTON
} from '../../../utils/constants';
import { replaceAssetLink } from '../../utils/common';
import {
  mapAccountBrandToUpdatePayload,
  mapBrandSetupRes
} from '../../utils/mapper';
import { mobileBreakPoint } from '../BrandStyle';
import {
  ImageUpload,
  StreamingVideoUpload
} from '../Common/FileUploader/MediaUpload';
import {
  IconUploadImage,
  IconUploadPhotos,
  IconUploadVideoBold,
  IconUploadVideoGray
} from '../Common/HubIcons';
import { useBreakPointDetector } from '../hooks/viewport';
import { HubContext } from '../HubContext';

type BrandAssets = {
  ogImageUrl: string;
  ogImageFile?: File;
  ogImageSelectedAsset?: IAsset;
  introVideoUrl: string;
  introVideoFile?: File;
  introVideoSelectedAsset?: IAsset;
  outroVideoUrl: string;
  outroVideoFile?: File;
  outroVideoSelectedAsset?: IAsset;
  meetingBackgroundLandscapeUrl: string;
  meetingBackgroundLandscapeFile?: File;
  meetingBackgroundLandscapeSelectedAsset?: IAsset;
  meetingBackgroundPortraitUrl: string;
  meetingBackgroundPortraitFile?: File;
  meetingBackgroundPortraitSelectedAsset?: IAsset;
};

type BrandAssetsForm = {
  values: BrandAssets;
  state?: {
    submitted?: boolean;
    submitting?: boolean;
  };
  errors?: Partial<BrandAssets>;
};

const BrandAssets: React.FC<{
  brandSetup: IBrandSetup;
  account: IAccount;
}> = () => {
  const dispatch = useDispatch();
  const { brandSetup, setBrandSetup } = useContext(HubContext);

  const brandAssetsInitialValues = useMemo<BrandAssets>(() => {
    return {
      introVideoUrl: brandSetup?.video?.intro || '',
      outroVideoUrl: brandSetup?.video?.outro || '',
      ogImageUrl: brandSetup?.ogImage || '',
      meetingBackgroundLandscapeUrl:
        brandSetup?.meetingBackgroundLandscape || '',
      meetingBackgroundPortraitUrl: brandSetup?.meetingBackgroundPortrait || ''
    };
  }, [brandSetup]);

  const [form, setFormState] = useState<BrandAssetsForm>({
    values: brandAssetsInitialValues
  });
  const { screenSize } = useBreakPointDetector();
  const [activeSection, setActionSectionOnMobile] = useState<
    'og_image' | 'video' | 'bg_and_cover_photo'
  >('og_image');

  const logBrandAssetsClickEvent = (
    target:
      | 'THUMBNAIL'
      | 'MEET_LANDSCAPE'
      | 'MEET_PORTRAIT'
      | 'INTRO_VIDEO'
      | 'OUTRO_VIDEO',
    button: 'DELETE' | 'PREVIEW',
    assetUrl = ''
  ) => {
    const clickAction =
      button === 'DELETE'
        ? DID_CLICK_BRANDING_DELETE_FILE
        : DID_CLICK_BRANDING_PREVIEW;
    logEvent(DID_CLICK_BUTTON, clickAction, { target, assetUrl });
  };

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      values: brandAssetsInitialValues
    }));
  }, [brandAssetsInitialValues]);

  const setFieldValue = (name: keyof BrandAssets, val: string | File) => {
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [name]: val
      }
    }));
  };

  const setFieldsValue = (values: Partial<BrandAssets>) => {
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...values
      }
    }));
  };

  const saveChanges = (target: keyof BrandAssets) => {
    setFormState((form) => {
      const updateBrandSetup = cloneDeep(brandSetup);

      updateBrandSetup.ogImage = form.values.ogImageUrl;
      updateBrandSetup.video.intro = form.values.introVideoUrl;
      updateBrandSetup.video.outro = form.values.outroVideoUrl;
      updateBrandSetup.meetingBackgroundLandscape =
        form.values.meetingBackgroundLandscapeUrl;
      updateBrandSetup.meetingBackgroundPortrait =
        form.values.meetingBackgroundPortraitUrl;

      const { brand } = mapAccountBrandToUpdatePayload(
        undefined,
        updateBrandSetup
      );
      const { brand: initialBranding } = mapAccountBrandToUpdatePayload(
        undefined,
        brandSetup
      );

      const brandingChanges = Object.entries(brand || {}).reduce(
        (accChanges, [key, val]) => {
          if (brand[key] != initialBranding[key]) {
            return Object.assign(accChanges || {}, { [key]: val });
          }
          return accChanges;
        },
        {}
      );

      updateBrand(brandSetup.id, brandSetup.brandUrl, brandingChanges)
        .then(({ data }) => {
          const updatedBrandSetup = mapBrandSetupRes(data);
          Object.assign(brandSetup || {}, updatedBrandSetup);
          setBrandSetup((prev) => ({ ...prev, brandSetup: brandSetup }));
          logEvent(HUB_BRANDING_EVENT, DID_UPDATE_BRAND_ASSETS_SUCCESSFUL, {
            data: form.values,
            brandUrl: brandSetup.brandUrl,
            brandId: brandSetup.id,
            target
          });
        })
        .catch(() => {
          logEvent(HUB_BRANDING_EVENT, DID_UPDATE_BRAND_ASSETS_FAILED, {
            data: form.values,
            brandUrl: brandSetup.brandUrl,
            brandId: brandSetup.id,
            target
          });
        })
        .finally(() => {
          setFormState((form) => ({
            ...form,
            state: { ...form.state, submitting: false }
          }));
        });

      return {
        ...form,
        state: { ...form.state, submitting: true, submitted: true }
      };
    });
  };

  return (
    <div className="pt-0 pb-5 brand-assets">
      <div className="mobile-navigator d-flex justify-content-between d-sm-none mb-4 px-2">
        <div
          className="nav-item basic_info p-2"
          onClick={() => setActionSectionOnMobile('og_image')}
        >
          <IconUploadImage
            color={activeSection === 'og_image' ? '#000000' : '#D8D8D8'}
          />
        </div>
        <div
          className="nav-item address p-2"
          onClick={() => setActionSectionOnMobile('video')}
        >
          {activeSection === 'video' ? (
            <IconUploadVideoBold />
          ) : (
            <IconUploadVideoGray />
          )}
        </div>
        <div
          className="nav-item logo p-2"
          onClick={() => setActionSectionOnMobile('bg_and_cover_photo')}
        >
          <IconUploadPhotos
            color={
              activeSection === 'bg_and_cover_photo' ? '#000000' : '#D8D8D8'
            }
          />
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col lg={{ span: 6 }} xs={{ span: 12 }} xl={{ span: 4 }}>
            {(screenSize !== 'xs' || activeSection === 'og_image') && (
              <>
                <div className="input-section mt-2" id="og-image">
                  <h5>Thumbnail Image</h5>
                  <p className="text-muted mb-3 restrictions">
                    Used for sharing meetings on social network platforms <br />
                    *.JPEG - 1200 x 675px - Max size 2MB
                  </p>
                  <ImageUpload
                    src={replaceAssetLink(form.values.ogImageUrl)}
                    alt="thumbnail image"
                    acceptTypes="image/jpeg"
                    convertQuality={0.4}
                    convertType="image/jpeg"
                    aspectRatio={16 / 9}
                    forceToReduceSizeQuality={0.5}
                    maxResolution={{ width: 1200, height: 675 }}
                    maxSize={2 * 1024 * 1024}
                    popupPreview
                    onRemove={async () => {
                      logBrandAssetsClickEvent(
                        'THUMBNAIL',
                        'DELETE',
                        form.values?.ogImageUrl
                      );
                      setFieldsValue({
                        ogImageUrl: '',
                        ogImageFile: undefined
                      });
                      if (!form.values.ogImageUrl) {
                        return;
                      }
                      if (!isResourceFromAssetLib(form.values.ogImageUrl)) {
                        removeBrandAssets(form.values.ogImageUrl);
                      }
                      saveChanges('ogImageUrl');
                    }}
                    onUpload={(file) => {
                      return uploadBrandAsset({
                        file,
                        path: 'image/og',
                        brand: brandSetup?.id
                      })
                        .then(({ data }) => {
                          if (
                            form.values.ogImageUrl &&
                            !isResourceFromAssetLib(form.values.ogImageUrl)
                          ) {
                            removeBrandAssets(form.values.ogImageUrl);
                          }
                          setFieldsValue({
                            ogImageUrl: data.url,
                            ogImageFile: undefined
                          });
                          saveChanges('ogImageUrl');
                        })
                        .catch((error) => {
                          console.log('error', error);
                        });
                    }}
                    onClickPreview={async () => {
                      logBrandAssetsClickEvent('THUMBNAIL', 'PREVIEW');
                    }}
                    onClick={() => {
                      dispatch(
                        actionSetPopupAssets({
                          popupContext: 'branding',
                          popupTitle: 'Asset Library',
                          acceptTypes: 'image/jpeg',
                          onSelectAsset(asset) {
                            if (!['image/jpeg'].includes(asset.assetType)) {
                              dispatch(
                                actionHubAlertError('Invalid asset type.')
                              );
                              return;
                            }
                            setFieldsValue({
                              ogImageSelectedAsset: asset
                            });
                            dispatch(
                              actionSetPopupAssets({ popupContext: '' })
                            );
                          },
                          onAddFileToUpload: (file) => {
                            setFieldValue('ogImageFile', file);
                            dispatch(
                              actionSetPopupAssets({ popupContext: '' })
                            );
                          }
                        })
                      );
                    }}
                    libraryAsset={form.values?.ogImageSelectedAsset}
                    file={form.values?.ogImageFile}
                    onSaveAssetUrl={(url) => {
                      setFieldsValue({
                        ogImageUrl: url
                      });
                      saveChanges('ogImageUrl');
                    }}
                  />
                </div>
              </>
            )}
            {(screenSize !== 'xs' || activeSection === 'video') && (
              <div className="mt-4" id="intro-video">
                <h5>Meet Intro Video</h5>
                <p className="text-muted mb-3 restrictions">
                  Played when a meeting starts <br /> *.MP4 - 1920 x 1080px -
                  Max size 100MB
                </p>
                <StreamingVideoUpload
                  src={form.values.introVideoUrl}
                  acceptTypes="video/mp4"
                  maxSize={100 * 1024 * 1024}
                  expectedDimensions={{
                    width: 1920,
                    height: 1080
                  }}
                  onRemove={async () => {
                    setFieldsValue({
                      introVideoUrl: '',
                      introVideoFile: undefined
                    });

                    logBrandAssetsClickEvent(
                      'INTRO_VIDEO',
                      'DELETE',
                      form.values?.introVideoUrl
                    );

                    if (!form.values.introVideoUrl) {
                      return;
                    }
                    if (!isResourceFromAssetLib(form.values.introVideoUrl)) {
                      removeBrandAssets(
                        form.values.introVideoUrl,
                        'storiez-videos'
                      );
                    }

                    saveChanges('introVideoUrl');
                  }}
                  onComplete={async (fileUrl) => {
                    if (fileUrl) {
                      setFieldValue('introVideoUrl', fileUrl);
                      saveChanges('introVideoUrl');
                    }
                  }}
                  onClickPreview={async () => {
                    logBrandAssetsClickEvent('INTRO_VIDEO', 'PREVIEW');
                  }}
                  onClick={() => {
                    dispatch(
                      actionSetPopupAssets({
                        popupContext: 'branding',
                        popupTitle: 'Asset Library',
                        acceptTypes: 'video/mp4',
                        onSelectAsset(asset) {
                          if (!['video/mp4'].includes(asset.assetType)) {
                            dispatch(
                              actionHubAlertError('Invalid asset type.')
                            );
                            return;
                          }
                          setFieldsValue({
                            introVideoSelectedAsset: asset
                          });
                          dispatch(actionSetPopupAssets({ popupContext: '' }));
                        },
                        onAddFileToUpload: (file) => {
                          setFieldValue('introVideoFile', file);
                          dispatch(actionSetPopupAssets({ popupContext: '' }));
                        }
                      })
                    );
                  }}
                  libraryAsset={form.values?.introVideoSelectedAsset}
                  file={form.values?.introVideoFile}
                  onSaveAssetUrl={(url) => {
                    setFieldsValue({
                      introVideoUrl: url
                    });
                    saveChanges('ogImageUrl');
                  }}
                />
              </div>
            )}
          </Col>
          <Col
            lg={{ span: 6 }}
            xs={{ span: 12 }}
            xl={{ span: 4 }}
            className="mt-3 mt-md-0"
          >
            {(screenSize !== 'xs' ||
              activeSection === 'bg_and_cover_photo') && (
              <>
                <div className="input-section mt-2" id="meetingBgLandscape">
                  <h5>Meet Cover Photo Landscape</h5>
                  <p className="text-muted mb-3 restrictions">
                    Used as a background during meeting <br />
                    *.JPEG - 1920 x 1080px (Ratio 16:9) - Max size 5MB
                  </p>

                  <ImageUpload
                    src={replaceAssetLink(form.values.meetingBackgroundLandscapeUrl)}
                    alt="meet cover photo landscape"
                    acceptTypes="image/jpeg"
                    maxResolution={{
                      height: 1080,
                      width: 1920
                    }}
                    popupPreview
                    aspectRatio={16 / 9}
                    maxSize={5 * 1024 * 1024}
                    onRemove={async () => {
                      setFieldsValue({
                        meetingBackgroundLandscapeUrl: '',
                        meetingBackgroundLandscapeFile: undefined
                      });

                      logBrandAssetsClickEvent(
                        'MEET_LANDSCAPE',
                        'DELETE',
                        form.values?.meetingBackgroundLandscapeUrl
                      );

                      if (!form.values.meetingBackgroundLandscapeUrl) {
                        return;
                      }
                      if (
                        !isResourceFromAssetLib(
                          form.values.meetingBackgroundLandscapeUrl
                        )
                      ) {
                        removeBrandAssets(
                          form.values.meetingBackgroundLandscapeUrl
                        );
                      }

                      saveChanges('meetingBackgroundLandscapeUrl');
                    }}
                    onUpload={(file) => {
                      return uploadBrandAsset({
                        file,
                        path: 'image/cover_photo',
                        brand: brandSetup?.id
                      })
                        .then(({ data }) => {
                          if (
                            form.values.meetingBackgroundLandscapeUrl &&
                            !isResourceFromAssetLib(
                              form.values.meetingBackgroundLandscapeUrl
                            )
                          ) {
                            removeBrandAssets(
                              form.values.meetingBackgroundLandscapeUrl
                            );
                          }

                          setFieldsValue({
                            meetingBackgroundLandscapeUrl: data.url,
                            meetingBackgroundLandscapeFile: undefined
                          });
                          saveChanges('meetingBackgroundLandscapeUrl');
                        })
                        .catch((error) => {
                          console.log('error', error);
                        });
                    }}
                    onClickPreview={async () => {
                      logBrandAssetsClickEvent('MEET_LANDSCAPE', 'PREVIEW');
                    }}
                    onClick={() => {
                      dispatch(
                        actionSetPopupAssets({
                          popupContext: 'branding',
                          popupTitle: 'Asset Library',
                          acceptTypes: 'image/jpeg',
                          onSelectAsset(asset) {
                            if (!['image/jpeg'].includes(asset.assetType)) {
                              dispatch(
                                actionHubAlertError('Invalid asset type.')
                              );
                              return;
                            }
                            setFieldsValue({
                              meetingBackgroundLandscapeSelectedAsset: asset
                            });
                            dispatch(
                              actionSetPopupAssets({ popupContext: '' })
                            );
                          },
                          onAddFileToUpload: (file) => {
                            setFieldValue(
                              'meetingBackgroundLandscapeFile',
                              file
                            );
                            dispatch(
                              actionSetPopupAssets({ popupContext: '' })
                            );
                          }
                        })
                      );
                    }}
                    libraryAsset={
                      form.values?.meetingBackgroundLandscapeSelectedAsset
                    }
                    file={form.values?.meetingBackgroundLandscapeFile}
                    onSaveAssetUrl={(url) => {
                      setFieldsValue({
                        meetingBackgroundLandscapeUrl: url
                      });
                      saveChanges('meetingBackgroundLandscapeUrl');
                    }}
                  />
                </div>
              </>
            )}
            {(screenSize !== 'xs' || activeSection === 'video') && (
              <div className="input-section mt-4" id="outro-video">
                <h5>Meet Outro Video</h5>
                <p className="text-muted mb-3 restrictions">
                  Played at the end of a meeting <br />
                  *.MP4 - 1920 x 1080px - Max size 100MB
                </p>

                <StreamingVideoUpload
                  src={form.values.outroVideoUrl}
                  acceptTypes="video/mp4"
                  maxSize={100 * 1024 * 1024}
                  expectedDimensions={{
                    width: 1920,
                    height: 1080
                  }}
                  onRemove={async () => {
                    setFieldsValue({
                      outroVideoUrl: ''
                    });
                    logBrandAssetsClickEvent(
                      'MEET_LANDSCAPE',
                      'DELETE',
                      form.values?.outroVideoUrl
                    );
                    if (!form.values.outroVideoUrl) {
                      return;
                    }
                    if (!isResourceFromAssetLib(form.values.outroVideoUrl)) {
                      removeBrandAssets(
                        form.values.outroVideoUrl,
                        'storiez-videos'
                      );
                    }
                    saveChanges('outroVideoUrl');
                  }}
                  onComplete={async (fileUrl) => {
                    if (fileUrl) {
                      setFieldValue('outroVideoUrl', fileUrl);
                      saveChanges('outroVideoUrl');
                    }
                  }}
                  onClickPreview={async () => {
                    logBrandAssetsClickEvent('OUTRO_VIDEO', 'PREVIEW');
                  }}
                  onClick={() => {
                    dispatch(
                      actionSetPopupAssets({
                        popupContext: 'branding',
                        popupTitle: 'Asset Library',
                        acceptTypes: 'video/mp4',
                        onSelectAsset(asset) {
                          if (!['video/mp4'].includes(asset.assetType)) {
                            dispatch(
                              actionHubAlertError('Invalid asset type.')
                            );
                            return;
                          }
                          setFieldsValue({
                            outroVideoSelectedAsset: asset
                          });
                          dispatch(actionSetPopupAssets({ popupContext: '' }));
                        },
                        onAddFileToUpload: (file) => {
                          setFieldValue('outroVideoFile', file);
                          dispatch(actionSetPopupAssets({ popupContext: '' }));
                        }
                      })
                    );
                  }}
                  libraryAsset={form.values?.outroVideoSelectedAsset}
                  file={form.values?.outroVideoFile}
                  onSaveAssetUrl={(url) => {
                    setFieldsValue({
                      outroVideoUrl: url
                    });
                    saveChanges('outroVideoUrl');
                  }}
                />
              </div>
            )}
          </Col>
          <Col
            lg={{ span: 12 }}
            xs={{ span: 12 }}
            xl={{ span: 4 }}
            className="mt-3 mt-xl-0"
          >
            {(screenSize !== 'xs' ||
              activeSection === 'bg_and_cover_photo') && (
              <div
                className="input-section portrait-photo-input mt-2"
                id="meetingBgPortrait"
              >
                <h5>Meet Cover Photo Portrait</h5>
                <p className="text-muted mb-3 restrictions">
                  Used as background during meeting on mobile <br />
                  *.JPEG - 1080 x 1920px (Ratio 9:16) - Max size 5MB
                </p>

                <ImageUpload
                  mode="portrait"
                  alt="meet cover photo portrait"
                  acceptTypes="image/jpeg"
                  src={replaceAssetLink(form.values.meetingBackgroundPortraitUrl)}
                  popupPreview
                  maxResolution={{
                    height: 1920,
                    width: 1080
                  }}
                  aspectRatio={9 / 16}
                  maxSize={5 * 1024 * 1024}
                  onRemove={async () => {
                    logBrandAssetsClickEvent(
                      'MEET_PORTRAIT',
                      'DELETE',
                      form.values?.meetingBackgroundPortraitUrl
                    );
                    setFieldsValue({
                      meetingBackgroundPortraitUrl: '',
                      meetingBackgroundPortraitFile: undefined
                    });
                    if (!form.values.meetingBackgroundPortraitUrl) {
                      return;
                    }
                    if (
                      !isResourceFromAssetLib(
                        form.values.meetingBackgroundPortraitUrl
                      )
                    ) {
                      removeBrandAssets(
                        form.values.meetingBackgroundPortraitUrl
                      );
                    }
                    saveChanges('meetingBackgroundPortraitUrl');
                  }}
                  onUpload={(file) => {
                    return uploadBrandAsset({
                      file,
                      path: 'image/meet_cover_photo',
                      brand: brandSetup?.id
                    })
                      .then(({ data }) => {
                        if (
                          form.values.meetingBackgroundPortraitUrl &&
                          !isResourceFromAssetLib(
                            form.values.meetingBackgroundPortraitUrl
                          )
                        ) {
                          removeBrandAssets(
                            form.values.meetingBackgroundPortraitUrl
                          );
                        }
                        setFieldsValue({
                          meetingBackgroundPortraitUrl: data.url,
                          meetingBackgroundPortraitFile: undefined
                        });
                        saveChanges('meetingBackgroundPortraitUrl');
                      })
                      .catch((error) => {
                        console.log('error', error);
                      });
                  }}
                  onClickPreview={async () => {
                    logBrandAssetsClickEvent('MEET_PORTRAIT', 'PREVIEW');
                  }}
                  onClick={() => {
                    dispatch(
                      actionSetPopupAssets({
                        popupContext: 'branding',
                        popupTitle: 'Asset Library',
                        acceptTypes: 'image/jpeg',
                        onSelectAsset(asset) {
                          if (!['image/jpeg'].includes(asset.assetType)) {
                            dispatch(
                              actionHubAlertError('Invalid asset type.')
                            );
                            return;
                          }
                          setFieldsValue({
                            meetingBackgroundPortraitSelectedAsset: asset
                          });
                          dispatch(actionSetPopupAssets({ popupContext: '' }));
                        },
                        onAddFileToUpload: (file) => {
                          setFieldValue('meetingBackgroundPortraitFile', file);
                          dispatch(actionSetPopupAssets({ popupContext: '' }));
                        }
                      })
                    );
                  }}
                  libraryAsset={
                    form.values?.meetingBackgroundPortraitSelectedAsset
                  }
                  file={form.values?.meetingBackgroundPortraitFile}
                  onSaveAssetUrl={(url) => {
                    setFieldsValue({
                      meetingBackgroundPortraitUrl: url
                    });
                    saveChanges('meetingBackgroundPortraitUrl');
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <style jsx>{`
      :global(.popup-preview .PopupContainer) {
        height: calc(100vh - 50px);
        top: 50px;
      }
      @media (min-width: ${mobileBreakPoint}px) {
        :global(.popup-preview .PopupContainer) {
          height: calc(100vh - 70px);
          top: 70px;
        }
      }
        .nav-item {
          cursor: pointer;
        }
        .brand-assets {
          max-width: 1450px;
        }
        .restrictions {
          min-height: 72px;
        }
        @media (min-width: 1200px) {
          .restrictions {
            min-height: 90px;
            font-size: 15px;
            margin-bottom: 8px !important;
          }
        }

        @media (min-width: 1500px) {
          .restrictions {
            min-height: 68px;
            font-size: 16px;
          }
        }
      `}</style>

      <style jsx global>{`
        .media-upload-container.landscape {
          max-width: 320px;
        }
        .media-upload-container.landscape .media-upload-main {
          height: 180px;
        }
        .media-upload-container .inline-preview img {
          width: 100%;
          height: 100%;
        }
        .media-upload-container .inline-preview {
          padding: 0px !important;
        }

        .media-upload-container.portrait {
          width: 180px;
        }
        .media-upload-container.portrait .media-upload-main {
          height: 320px;
        }

        .preview-image-wrapper > img {
          width: 100%;
        }
        .nav-item {
          cursor: pointer;
        }

        .preview-image-wrapper,
        .preview-video-wrapper,
        .preview-image-wrapper .dropzone,
        .preview-video-wrapper .dropzone {
          height: 180px !important;
          max-width: 320px !important;
        }
        .preview-image-wrapper {
          padding: 0 !important;
        }

        p {
          margin-bottom: 0.2rem;
        }

        @media (max-width: 1200px) {
          .spinner-container {
            max-width: 300px !important;
          }
          .spinner-container {
            max-width: 300px !important;
          }
          .preview-image-wrapper,
          .preview-video-wrapper,
          .preview-image-wrapper .dropzone,
          .preview-video-wrapper .dropzone {
            height: 160px !important;
            max-width: 300px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default BrandAssets;
