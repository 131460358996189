import React from 'react';
import { HubContext } from '../HubContext';
import { getCorrectHubHost } from '../../utils/hubPagesRoute';
import { logEvent } from '../../../analytics';
import { assetBaseUrl, getSessionUrl } from '../../../config';
import { IHubUser, IMainState, ISessionDetails } from '../../../interfaces';
import { DID_CLICK_JOIN_SESSION } from '../../../utils/constants';
import {
  isEmbeddedInStreamingStudio,
  joinMeetingFromSsp
} from '../../../utils/streamingstudio';
import { deleteSession } from '../../clientSideServices/session';
import DeleteButton from '../Common/DeleteButton';
import { getSessionUploadFileStatusIcon } from '../componentFactory';
import { useSelector } from 'react-redux';

const MeetingRoomTile = ({
  session,
  storeId,
  didDeleteSession
}: {
  session: ISessionDetails;
  storeId?: string;
  didDeleteSession: (sessionId: string) => void;
}) => {
  const { brandId } = React.useContext(HubContext);
  const sessionUrl = getSessionUrl({
    storeId: storeId,
    sessionId: session.id,
    region: session.region,
    host: `https://${getCorrectHubHost(brandId)}`
  });
  const statusIcon = getSessionUploadFileStatusIcon(session);
  const user: IHubUser = useSelector(
    (state: IMainState) => state.clientState.hub?.user
  );

  return (
    <div className="MeetingRoomTile">
      <img src={`${assetBaseUrl}/hub/meeting-room.png`} alt="cover" />
      <div className="content-container">
        <div className="title">
          <strong>{session.topic || ' '}</strong>
        </div>
        <div className="subtitle">{session.description || ' '}</div>
        <button
          className="btn sb-edit btn-round btn-dark btn-sm btn-join"
          onClick={(event) => {
            event.stopPropagation();
            logEvent(DID_CLICK_JOIN_SESSION, DID_CLICK_JOIN_SESSION, {
              joinSessionId: session.id,
              joinSessionUrl: sessionUrl?.host
            });
            if (isEmbeddedInStreamingStudio()) {
              joinMeetingFromSsp(
                session,
                user?.name || 'Sales Associate',
                sessionUrl?.host
              );
            } else {
              window.open(sessionUrl?.host, '_blank');
            }
          }}
        >
          Join
        </button>
        <div className="delete-button shadow-box">
          <DeleteButton
            onClick={() => {
              deleteSession(session.id).then(() => {
                didDeleteSession(session.id);
              });
            }}
            yPosition="top"
            indent="5px"
            confirmation="Are you sure want to delete this meeting?"
          />
        </div>
        {statusIcon && <div className="status-icon">{statusIcon}</div>}
      </div>
      <style jsx>{`
        .btn-join {
          position: absolute;
          bottom: 15px;
          right: 15px;
        }
        .MeetingRoomTile {
          background: rgb(230, 124, 255);
          background: linear-gradient(
            135deg,
            rgba(230, 124, 255, 1) 0%,
            rgba(143, 101, 153, 1) 100%
          );
          border-radius: 15px;
          overflow: hidden;
          font-size: 13px;
          border: 2px solid #fff;
          box-shadow: 0 0 5px #efefef;
          position: relative;
          height: 330px;
        }
        .loading {
          position: absolute;
          bottom: 0;
          right: 0;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.5);
        }
        .loading > :global(*) {
          margin: auto;
        }
        .MeetingRoomTile:hover {
          box-shadow: 0 0 10px #ccc;
        }
        img {
          width: 50%;
          height: auto;
        }
        .btn {
          margin-right: 5px;
        }
        .content-container {
          padding: 20px;
          max-width: 100%;
        }
        .title {
          font-size: 1em;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          margin-bottom: 5px;
        }
        .subtitle {
          font-size: 0.9em;
          font-weight: 500;
        }
        .title,
        .subtitle {
          line-height: 1.2;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #fff;
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        }
        .actions {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: end;
        }

        .delete-button {
          display: none;
          position: absolute;
          top: 5px;
          right: 5px;
          background: #fff;
          border-radius: 100px;
        }
        .MeetingRoomTile:hover .delete-button {
          display: block;
        }
        .status-icon {
          position: absolute;
          left: 4px;
          top: 4px;
          text-align: center;
          border-radius: 100%;
        }
        @media (min-width: 480px) {
          .MeetingRoomTile {
            height: 320px;
          }
        }
        @media (min-width: 768px) {
          .MeetingRoomTile {
            height: 300px;
          }
        }
        @media (min-width: 1000px) {
          .MeetingRoomTile {
            height: 320px;
          }
        }
        @media (min-width: 1400px) {
          .MeetingRoomTile {
            height: 300px;
          }
        }
        @media (min-width: 1600px) {
          .MeetingRoomTile {
            height: 290px;
          }
        }
        @media (min-width: 2000px) {
          .MeetingRoomTile {
            aspect-ratio: 275/287;
          }
        }
      `}</style>
    </div>
  );
};

export default MeetingRoomTile;
