import React, { useEffect, useMemo, useState } from 'react';
import SBAnalyticsTabs, { ITab } from './SLAnalyticsTab';
import dynamic from 'next/dynamic';
import { ITableColumn, SortTable } from '../../Common/Table';
import { max, sortBy, uniq } from 'lodash';
import { ApexOptions } from 'apexcharts';
import {
  formatDurationFromMiliSeconds,
  formattedNumber
} from '../../../../utils/number';
import { tz } from 'moment-timezone';
import moment from 'moment';
import { Country } from 'country-state-city';
import {
  mapAndSortViewsDensity,
  getAreaChartTooltip,
  getChartColorByIndex,
  CHART_TOTAL_COLOR,
  interactionStyles
} from './helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoIcon } from '../../Common/HubIcons';
import { getSceneInsightStorybookDetails } from './service';
import { ISLSelectItem } from '.';
import { ILibraryScene } from '../../../../interfaces';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

const mapSceneDetailsDataByCountry = (data) => {
  const result = {
    totalViews: {},
    uniqueViews: {},
    averageEyeballTime: {},
    clicks: {},
    shares: {}
  };
  const fields = Object.keys(result);
  Object.entries(data).forEach(([country, countryData]) => {
    Object.entries(countryData).forEach(([date, dateValue]) => {
      fields.forEach((field) => {
        if (dateValue[field] !== 0) {
          result[field][country] = {
            ...result[field][country],
            [date]: dateValue[field]
          };
        }
      });
    });
  });
  return result;
};

const clientTimezone = tz.guess();

interface Props {
  storybook: ISLSelectItem;
  scene: ILibraryScene;
  startDate: string;
  endDate: string;
  campaign: string;
  range: number;
}

const sceneTab = [
  { id: 'totalViews', label: 'Views' },
  { id: 'uniqueViews', label: 'Viewers' },
  { id: 'averageEyeballTime', label: 'Watch time' },
  { id: 'clicks', label: 'Clicks' },
  { id: 'shares', label: 'Shares' }
];

const SLAnalyticsStorybookDetails = ({
  storybook,
  scene,
  startDate,
  endDate,
  campaign,
  range
}: Props) => {
  const [currentOverviewTab, setCurrentOverviewTab] = useState<ITab>({
    id: sceneTab[0].id,
    label: sceneTab[0].label
  });
  const [data, setData] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [filteredArea, setFilteredArea] = useState([]);

  const dateRange = useMemo(() => {
    const fromDate =
      range > 0
        ? moment().subtract(range, 'days').format('YYYY-MM-DD')
        : startDate;
    const toDate = range > 0 ? moment().format('YYYY-MM-DD') : endDate;
    return {
      fromDate,
      toDate
    };
  }, [startDate, endDate, range]);

  const datetimeList = useMemo(
    () =>
      sortBy(
        uniq(
          Object.values(data?.dataByDateAndCountry?.totalViews || {}).flatMap(
            (countryData) => Object.keys(countryData)
          )
        ),
        (d) => moment(d).unix()
      ),
    [data.dataByDateAndCountry]
  );

  const countriesWithColor = useMemo(() => {
    return sortBy(
      uniq(
        Object.values(data?.dataByDateAndCountry || {}).flatMap(
          (dataByDateAndCountry) => Object.keys(dataByDateAndCountry)
        )
      )
    ).reduce((colors, country, index) => {
      return {
        ...colors,
        [country]: getChartColorByIndex(index)
      };
    }, {});
  }, [data?.dataByDateAndCountry]);

  const countStatValue = (statKey, country) => {
    const countryData = data?.dataByDateAndCountry?.[statKey]?.[country];
    return datetimeList.reduce((acc, datetime) => {
      return acc + (countryData?.[datetime] || 0);
    }, 0);
  };

  useEffect(() => {
    if (!scene.id && !storybook?.id) return;
    setLoading(true);
    getSceneInsightStorybookDetails({
      sceneId: scene.id,
      storybookId: storybook?.id,
      campaign,
      timeZone: clientTimezone,
      startDate: dateRange.fromDate,
      endDate: dateRange.toDate
    })
      .then((res) => {
        const mapData = {
          ...res,
          dataByDateAndCountry: mapSceneDetailsDataByCountry(
            res.dataByDateAndCountry
          )
        };
        setData(mapData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [scene.id, storybook?.id, dateRange, campaign]);

  const aggregatedCountry = Object.entries(countriesWithColor).map(
    ([country, color]) => {
      return {
        id: country,
        localisation: Country.getCountryByCode(country)?.name || country,
        styles: {
          color,
          fontWeight: 600
        },
        stylesColumns: [0],
        totalViews: countStatValue('totalViews', country),
        uniqueViews: countStatValue('uniqueViews', country),
        averageEyeballTime: countStatValue('averageEyeballTime', country),
        clicks: countStatValue('clicks', country),
        shares: countStatValue('shares', country)
      };
    }
  );

  const totalRow = {
    id: 'total',
    localisation: 'Total',
    styles: {
      color: CHART_TOTAL_COLOR,
      fontWeight: 600
    },
    stylesColumns: [0],
    totalViews: aggregatedCountry.reduce((acc, country) => {
      return acc + country.totalViews;
    }, 0),
    uniqueViews: aggregatedCountry.reduce((acc, country) => {
      return acc + country.uniqueViews;
    }, 0),
    averageEyeballTime: aggregatedCountry.reduce((acc, country) => {
      return acc + country.averageEyeballTime;
    }, 0),
    clicks: aggregatedCountry.reduce((acc, country) => {
      return acc + country.clicks;
    }, 0),
    shares: aggregatedCountry.reduce((acc, country) => {
      return acc + country.shares;
    }, 0)
  };

  const aggregatedData = [totalRow, ...aggregatedCountry];

  const hourlyViewSeries = useMemo(() => {
    const density = mapAndSortViewsDensity(
      data?.viewsDensity,
      startDate,
      endDate,
      range
    );
    return density;
  }, [data?.viewsDensity, startDate, endDate]);

  const maxHourlyView = useMemo(() => {
    const maxHour = max(
      hourlyViewSeries?.map((hour) => max(hour?.data)) || [0]
    );
    return maxHour + (10 - (maxHour % 10));
  }, [hourlyViewSeries]);

  const chartAreaSeries = Object.entries(
    data?.dataByDateAndCountry?.[currentOverviewTab.id] || {}
  ).map(([country, countryData]) => {
    return {
      name: Country.getCountryByCode(country)?.name || country,
      data: datetimeList.map((datetime) => {
        return countryData[datetime] || 0;
      }),
      color: countriesWithColor[country]
    };
  });

  const chartAreaTotalLine = {
    name: 'Total',
    data: datetimeList.map((datetime, index) => {
      const total = chartAreaSeries.reduce((acc, series) => {
        return acc + series.data[index];
      }, 0);
      return total;
    }),
    color: CHART_TOTAL_COLOR
  };

  const chartAreaOptions: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: 1
    },
    fill: {
      type: 'gradient'
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: datetimeList.map((datetime) =>
        moment(datetime).format('DD MMM')
      )
    },
    yaxis: {
      min: 0,
      max: max(chartAreaTotalLine.data) + 1,
      labels: {
        formatter: (v) => {
          if (currentOverviewTab.id === 'averageEyeballTime')
            return formatDurationFromMiliSeconds(v);
          return formattedNumber(v, '0 a');
        }
      },
      forceNiceScale: currentOverviewTab.id === 'averageEyeballTime'
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      },
      custom: (params) => getAreaChartTooltip(params, currentOverviewTab)
    }
  };

  const dataColumns: ITableColumn[] = [
    {
      key: 'localisation',
      title: 'Localisation',
      align: 'left'
    },
    {
      key: 'totalViews',
      title: 'Views',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'uniqueViews',
      title: 'Viewers',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'averageEyeballTime',
      title: 'Watch Time',
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    },
    {
      key: 'clicks',
      title: 'Clicks',
      align: 'center'
    },
    {
      key: 'shares',
      title: 'Share',
      align: 'center'
    }
  ];

  const hourlyViewOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    legend: {
      show: false
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: '#FAF1FD',
              name: 'low'
            },
            {
              from: maxHourlyView,
              to: maxHourlyView,
              color: '#502B5A',
              name: 'high'
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#AE3BCD'],
    xaxis: {
      position: 'top',
      labels: {
        formatter: (value) => `${parseInt(value) - 1}`,
        style: {
          colors: ['#615E83'],
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#615E83'],
          fontSize: '14px'
        }
      }
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const time = `0${dataPointIndex}:00`.slice(-5);
        const label = `${w.globals.seriesNames[seriesIndex]}, ${time}`;
        return `
        <div class="custom-tooltip">
          <div class="tooltip-label">${label}</div>
          <div class="tooltip-value">${value}</div>
        </div>
      `;
      },
      style: {
        fontSize: '12px',
        fontFamily: undefined
      }
    }
  };

  const filteredChartAreaSeries = useMemo(() => {
    const includeTotal = [chartAreaTotalLine, ...chartAreaSeries];
    return includeTotal.filter((series) => {
      const countryName = series.name;
      return !filteredArea.includes(countryName);
    });
  }, [filteredArea, chartAreaSeries, chartAreaTotalLine]);
  const interactions = data.interactions || [];

  const totalSeen = totalRow.uniqueViews;

  const getClickRate = (clicks) => {
    return formattedNumber(clicks / totalSeen, '0.0%');
  };

  const toggleAreaSeries = (row) => {
    const isChecked = filteredArea.includes(row.localisation);
    if (isChecked) {
      setFilteredArea(filteredArea.filter((area) => area !== row.localisation));
    } else {
      setFilteredArea([...filteredArea, row.localisation]);
    }
  };

  const heatmapHeight = useMemo(() => {
    const min = 500;
    const timelineRange = range ? range : moment(endDate).diff(startDate, 'd');
    return Math.max(min, timelineRange * 20);
  }, [range, startDate, endDate]);

  const legendNumbers = useMemo(() => {
    const numbers = [];
    for (let i = 0; i <= maxHourlyView; i += maxHourlyView / 5) {
      numbers.push(
        <span key={i} style={{ left: `${(i / maxHourlyView) * 100}` }}>
          {formattedNumber(i)}
        </span>
      );
    }
    return numbers.reverse();
  }, [maxHourlyView]);

  const xAxisNumbers = useMemo(() => {
    return new Array(24)
      .fill(0)
      .map((_, index) => <span key={index}>{index}</span>);
  }, []);

  const interactionList = useMemo(() => {
    return interactions.map((interaction) => {
      const style =
        interactionStyles[interaction.type?.toLocaleLowerCase?.()] ||
        interactionStyles[0];
      return {
        title: interaction.title,
        type: interaction.type,
        totalClicks: interaction.totalClicks,
        clickRate: getClickRate(interaction.totalClicks),
        color: style.color
      };
    });
  }, [interactions]);

  return (
    <>
      <div className="analytics-main" data-isloading={loading}>
        <div className="overview-linechart card-section">
          <SBAnalyticsTabs
            dot={true}
            onSelectTab={setCurrentOverviewTab}
            tabs={sceneTab}
            active={currentOverviewTab}
          />
          <Chart
            options={chartAreaOptions}
            series={filteredChartAreaSeries}
            type="area"
            height="350"
          />
        </div>
        <div className="overview-table card-section">
          <SortTable
            rowCheckbox
            onCheckboxClick={toggleAreaSeries}
            columns={dataColumns}
            sorts={[
              'totalViews',
              'uniqueViews',
              'averageEyeballTime',
              'clicks',
              'shares'
            ]}
            data={aggregatedData}
            onlyActiveSortBy
            defaultChecked
          />
        </div>
        <div className="overview-link">
          {interactionList.map((interaction, index) => (
            <div className="card-section" key={index}>
              <h4>{interaction.title}</h4>
              <div className="link-type">
                Link type:
                <div
                  className="badge-icon"
                  style={{
                    backgroundColor: interaction.color
                  }}
                />
                <div
                  style={{
                    color: interaction.color,
                    fontWeight: 500,
                    textTransform: 'capitalize'
                  }}
                >
                  {interaction.type}
                </div>
              </div>
              <div>
                Number of Clicks: <span>{interaction.totalClicks || 0}</span>
              </div>
              <div>
                Click Through Rate: <span>{interaction.clickRate}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="overview-heatmap">
          <div className="card-section">
            <label>
              Global Hourly Views Density (UTC +8)
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={(props) => (
                  <Tooltip
                    id="tooltip"
                    {...props}
                    style={{
                      color: '#fff',
                      fontSize: 12,
                      borderRadius: 5,
                      textAlign: 'center',
                      ...props.style
                    }}
                  >
                    This heatmap represents hourly views from audiences base on
                    your localisation selection, aggregated and visualized for
                    each hour of the day. Please note that the data is
                    normalized and displayed in your defined browser timezone.
                  </Tooltip>
                )}
              >
                <div style={{ display: 'flex' }}>
                  <InfoIcon />
                </div>
              </OverlayTrigger>
            </label>
            <div className="heatmap-content">
              <div className="heatmap-chart">
                <div className="heatmap-wrapper">
                  <Chart
                    options={hourlyViewOptions}
                    series={hourlyViewSeries}
                    height={heatmapHeight}
                    type="heatmap"
                  />
                </div>
                <div className="custom-heatmap-xasis">{xAxisNumbers}</div>
              </div>
              <div className="custom-heatmap-legend">
                <div className="legend-bar"></div>
                <div className="legend-number">{legendNumbers}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>

      <style jsx>{`
        .link-type {
          display: flex;
          align-items: center;
        }
        :global(.overview-heatmap .tooltip-label) {
          font-size: 12px;
        }
        :global(.title-info svg) {
          width: 12px;
          margin-left: 5px;
        }

        :global(.title-info) {
          display: flex;
          align-items: center;
        }
        :global(.arrow) {
          display: none !important;
        }
        :global(.tooltip-inner) {
          background-color: #fff;
          box-shadow: 0px 2.679px 6.698px 0px rgba(0, 0, 0, 0.25);
          color: #484848;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          padding: 0.5rem 0.75rem;
          max-width: 230px;
        }
        .card-section label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          font-weight: 400;
          display: flex;
          gap: 10px;
        }
        .analytics-main {
          overflow: auto;
          max-height: calc(100vh - 200px);
        }
        .analytics-main[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }
        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }

        .analytics-main[data-isloading='true'] .overlay {
          display: flex;
        }
        :global(.overview-linechart .analytics-tab) {
          font-size: 14px !important;
          padding: 7px 10px !important;
          text-transform: capitalize;
        }
        :global(.overview-linechart .analytics-tabs) {
          border-bottom: none;
        }
        :global(.overview-linechart .active-tab) {
          color: rgba(0, 0, 0, 0.9);
        }
        :global(.overview-linechart .active-tab svg) {
          color: #4c2d5a;
        }
        :global(.overview-heatmap .custom-tooltip) {
          padding: 10px;
          color: #4c2d5a;
        }
        :global(.overview-heatmap .tooltip-label) {
          font-size: 12px;
        }
        :global(.overview-heatmap .tooltip-value) {
          font-weight: 500;
          font-size: 18px;
        }
        .card-section {
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
          padding: 20px;
          margin-bottom: 20px;
        }
        .card-section label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          font-weight: 400;
        }
        .overview-link {
          margin: 20px 0;
          display: grid;
          gap: 20px;
          grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        }
        .overview-link .card-section h4 {
          color: #484848;
          font-size: 15px;
          font-weight: 500;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 10px;
        }
        .overview-link .card-section {
          color: #484848;
          font-size: 14px;
          font-weight: 400;
        }
        .badge-icon {
          background: currentColor;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          margin: 0 5px;
        }
        .overview-link span {
          color: #8f6599;
          font-weight: 500;
        }

        .custom-heatmap-legend {
          padding-top: 30px;
          display: flex;
        }
        .custom-heatmap-legend .legend-bar {
          width: 20px;
          height: 90%;
          background: linear-gradient(
            180deg,
            #502b5a 0%,
            #ae3bcd 50%,
            #faf1fd 100%
          );
          flex-shrink: 0;
        }
        .heatmap-chart :global(.apexcharts-xaxis) {
          opacity: 0;
        }

        .heatmap-content {
          display: flex;
          width: 100%;
          overflow: hidden;
          gap: 15px;
        }
        .heatmap-chart {
          flex: 1;
        }
        .heatmap-content .heatmap-wrapper {
          height: 520px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 1;
          position: relative;
        }

        .custom-heatmap-xasis {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-size: 12px;
          width: 100%;
          padding: 0px 10px 0px 73px;
          font-weight: 500;
          margin-top: -20px;
          background: #fff;
          z-index: 2;
          position: relative;
        }

        .custom-heatmap-legend .legend-number {
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 10px;
          font-size: 12px;
        }

        .heatmap-content .heatmap-wrapper::-webkit-scrollbar {
          width: 5px;
        }

        .heatmap-content .heatmap-wrapper::-webkit-scrollbar-thumb {
          background: #502b5a;
          border: 3px solid #502b5a;
          border-radius: 5px;
        }
      `}</style>
    </>
  );
};

export default SLAnalyticsStorybookDetails;
