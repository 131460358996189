import { format } from 'date-fns';
import React from 'react';
import { RiAddCircleLine } from 'react-icons/ri';
import { ISessionDetails } from '../../../../interfaces';
import { getSessionStartEndTime } from '../../../../utils/session';
import { addEventToCalendar } from '../../../clientSideServices/session';
import FacebookProfile from './FacebookProfile';
function FacebookEvent({ event }: { event: ISessionDetails }) {
  const [parsedStartTime, parsedEndTime] = getSessionStartEndTime(event);
  const addToCalendar = () => {
    addEventToCalendar(event).then((res) => {
      const icalFile = res.data;
      const blob = new Blob([icalFile], { type: 'text/calendar' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${event.topic}.ics`;
      link.click();
    });
  };

  return (
    <>
      <div className="session-card">
        <div className="session-time-wrapper">
          {parsedStartTime && (
            <div className="session-time">
              {format(parsedStartTime, 'HH:mm')}
            </div>
          )}
          {parsedEndTime && (
            <div className="session-time">{format(parsedEndTime, 'HH:mm')}</div>
          )}
        </div>

        <div className="session-details">
          <div className="session-title">{event?.topic}</div>
          <div className="session-desc">{event?.description}</div>

          <button className="btn add-btn">
            <RiAddCircleLine />
            <a className="add-to-calendar-cta" onClick={addToCalendar}>
              Add to Calendar
            </a>
          </button>
        </div>
        <div className="owner-details">
          <FacebookProfile token={event.ownerToken} />
        </div>
      </div>
      <style jsx>{`
        .session-details {
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
        .session-card {
          display: flex;
        }
        .owner-details {
          width: 35%;
        }
        .owner-details :global(.profile-name) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.8rem;
        }
        .owner-details :global(.profile-picture) {
          width: 40px;
          height: 40px;
        }
      `}</style>
    </>
  );
}

export default FacebookEvent;
