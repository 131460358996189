import { IInspiration, INudge, ISearchResult } from '../../../interfaces';
import { mapStoryResponseToInspiration } from '../../../mappers/inspiration';
import { urlSigner } from '../../../utils/image';
import { apigClient, getObjectUrlFromS3 } from '../aws';
import { format } from 'date-fns';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { saveNudge } from '../nudge';
import { getFileNameFromUrl } from '../../../utils/file';

// prettier-ignore
type StoryType = 'simple' | 'video' | 'html';
// prettier-ignore
type Occasion = 'Discovery' | 'CustomerService' | 'Queuing' | 'ProductInspiration';
// prettier-ignore
type Channel = 'Boutiques' | 'Mobile' | 'Web' | 'Lense';
// prettier-ignore
type StoryStatus = 'submitted' | 'draft' | 'new' | 'deactivated' | 'published' | 'auto';
// prettier-ignore
export type Visibility = 'private' | 'site' | 'brand';
export type Context = 'recording' | 'stillshot';
export type Duration = 'short' | 'medium' | 'long';
// prettier-ignore
export type UploadTime = 'lastHour' | 'today' | 'thisWeek' | 'thisMonth' | 'thisYear';

export interface IStoryFilters {
  context?: string[];
  type?: StoryType[];
  occasions?: Occasion[];
  channel?: Channel[];
  status?: StoryStatus[];
  languagecode?: string[];
}

export interface GalleryFilter {
  id?: string;
  siteEntitlement?: string[];
  brandEntitlement?: string[];
  userId?: string;
  visibility?: Visibility[];
  context?: Context[];
  duration?: Duration;
  uploadTime?: UploadTime[];
  myVideoOnly?: boolean;
}
export type IVisibilityFilters = {
  [key in Visibility]?: string;
};

export const getVideoDownloadUrl = (
  streamingUrl: string,
  reqFileName: string
) => {
  const sspVideoPath = 'ssp/content/converted';
  const isSSPVideo = streamingUrl.includes(sspVideoPath);
  const fileName = getFileNameFromUrl(streamingUrl);
  const urlWithoutFileName = streamingUrl.replace('/' + fileName, '');

  if (isSSPVideo) {
    const videoKey = urlWithoutFileName.split(sspVideoPath)[1];
    return getObjectUrlFromS3(
      'storiez-data',
      `ssp/content/uploaded${videoKey}`,
      reqFileName
    ).then((url) => url);
  }
};

const getEntitlementQuery = (brandEntitlement: string[]) => {
  if (!brandEntitlement) {
    return '';
  }
  return brandEntitlement?.length > 1
    ? `(or ${brandEntitlement.map((brand) => `ownerid:'${brand}'`).join(' ')})`
    : `ownerid:'${brandEntitlement[0]}'`;
};

const getVisibilityQuery = (
  visibility: Visibility[] | undefined,
  siteEntitlement: string[] | undefined,
  userId: string
) => {
  if (!siteEntitlement) {
    return '';
  }
  const visibilities = {
    brand: `visibility:'brand'`,
    site: `(and visibility:'site' ${
      siteEntitlement?.length > 1
        ? `(or ${siteEntitlement.map((site) => `site:'${site}'`).join(' ')})`
        : `site:'${siteEntitlement[0]}'`
    })`,
    private: `(and visibility:'private' createdby:'${userId}')`
  };

  if (!visibility?.length) {
    return `(or ${['brand', 'site', 'private']
      .map((vis) => visibilities[vis])
      .join(' ')})`;
  }

  const selectedVisibilities = visibility
    .map((vis) => visibilities[vis])
    .join(' ');
  return visibility.length > 1
    ? `(or ${selectedVisibilities})`
    : selectedVisibilities;
};

const getContextQuery = (context?: Context[], duration?: Duration) => {
  const durationQuery = getDurationQuery(duration);
  const recordingQuery = durationQuery
    ? `(and context:'recording' ${durationQuery})`
    : `context:'recording'`;

  if (!context?.length || context?.length === 2) {
    return `(or ${recordingQuery} context:'stillshot')`;
  }

  if (context[0] === 'recording') {
    return recordingQuery;
  }

  return `context:'stillshot'`;
};

const getDurationQuery = (duration?: Duration) => {
  if (duration === 'short') return `duration:{,239]`; //< 4 minutes
  if (duration === 'medium') return `duration:[240,1199]`; // 4-20 minutes
  if (duration === 'long') return `duration:[1200,}`; // > 20 minutes
  return '';
};
export const getFilterQueryString = (filter?: GalleryFilter): string => {
  const { brandEntitlement, siteEntitlement, userId, visibility, duration } =
    filter;
  const entitlementQuery = `${getEntitlementQuery(brandEntitlement)}`;
  const contextQuery = getContextQuery(filter?.context, duration);
  const visibilityQuery = getVisibilityQuery(
    visibility,
    siteEntitlement,
    userId
  );
  const idQuery = filter?.id ? ` id:'${filter.id}'` : '';
  const query = `(and ${contextQuery} ${entitlementQuery} ${visibilityQuery}${idQuery})`;

  return query;
};

export const searchForGalleryItems = async (
  keywords: string,
  filter: GalleryFilter | undefined,
  start: number,
  size?: number
): Promise<ISearchResult<IInspiration>> => {
  const client = await apigClient();
  const signer = await urlSigner();
  const path = `/searchForCampaignsForVoice/v1`;
  const searchAttributes = {
    keywords,
    startIndex: start,
    size: size || 4,
    queryString: getFilterQueryString(filter)
  };
  const { data } = await client.invokeApi(
    {},
    path,
    'POST',
    {},
    searchAttributes
  );
  if (!data.campaigns) return Promise.reject();

  return {
    results: data.campaigns.map(mapStoryResponseToInspiration(signer)),
    originalResults: data.campaigns,
    totalCount: data.totalCount,
    lastIndex: data.lastIndex
  };
};

const saveStory = async (story) => {
  const client = await apigClient();
  const path = `/campaigns/v2`;
  return await client.invokeApi({}, path, 'PUT', {}, story);
};

export interface VideoSender {
  id: string;
  alias: string;
}

const mapGalleryStoryToItemList = async (
  galleryItem: IInspiration
): Promise<IInspiration> => {
  return {
    ...galleryItem,
    visibility: galleryItem.visibility || 'private',
    submittedAt: galleryItem.submittedAt
      ? format(new Date(galleryItem.submittedAt), 'MMM dd, yyyy | p')
      : ''
  };
};

export const getGalleryItems = async (
  keyword: string,
  startIndex = 0,
  filter: GalleryFilter
) => {
  const { results, lastIndex, totalCount } = await searchForGalleryItems(
    keyword,
    filter,
    startIndex,
    12
  );

  return {
    results: await Promise.all(
      results.map((galleryItem) => mapGalleryStoryToItemList(galleryItem))
    ),
    lastIndex,
    totalCount
  };
};

export const createNudgeFromGalleryItem = async ({
  galleryItem,
  storeId,
  userId,
  name,
  description
}: {
  galleryItem: IInspiration;
  storeId: string;
  userId: string;
  name: string;
  description?: string;
}) => {
  const payload: INudge = {
    id: generateV4UUID(),
    name,
    description,
    content: [galleryItem],
    storeId,
    createdBy: userId
  };
  return await saveNudge(payload, userId);
};

export interface UpdatedGalleryItemContent {
  id: string;
  title: string;
  description: string;
  visibility: Visibility;
}

export const saveUpdatedGalleryItem = async (
  updatedGalleryItem: UpdatedGalleryItemContent,
  userId: string
) => {
  const originalPayload = await searchForGalleryItems(
    '',
    { id: updatedGalleryItem.id, userId },
    0
  );
  const updatedPayload = {
    ...originalPayload.originalResults[0],
    ...updatedGalleryItem,
    modifiedBy: userId,
    modifiedAt: new Date()
  };

  return await saveStory(updatedPayload);
};
