import { uniqBy } from 'lodash';
import { AxiosPromise } from 'axios';
import {
  IDevice,
  IDeviceConfiguration,
  IDeviceStatus,
  IHubComment
} from '../../../interfaces';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { prodBaseUrlV1, prodBaseUrlV2 } from '../../config';
import { apigClient } from '../aws';
import httpClient from '../../../clientSideServices/http';
import { getUserByIdentityId } from '../../../clientSideServices/users';

export const getDevicesFromStoreId = async (
  storeId: string
): Promise<IDevice[]> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/devices/v1/byStoreId`;
  const additionalParams = {
    queryParams: {
      storeId
    }
  };
  const result = await client.invokeApi({}, path, 'GET', additionalParams);
  return result.data;
};

export const getDeviceConfiguration = async (
  device: IDevice
): Promise<IDeviceConfiguration> => {
  const configId = device.lensBarConfigurationId;
  const client = await apigClient();
  const path = `/lenseBarConfigurations/v2/${configId}`;
  const method = 'GET';
  const result = await client.invokeApi({}, path, method, {});
  const value = result.data;
  return {
    ...value,
    deviceId: device?.deviceId
  };
};

export const saveDeviceConfiguration = async (
  configuration: IDeviceConfiguration
) => {
  const client = await apigClient();
  const path = `/lenseBarConfigurations/v2`;
  const body = { ...configuration, modifiedAt: new Date().toISOString() };
  const result = await client.invokeApi({}, path, 'PUT', {}, body);
  return result.data;
};

export const getDevicesStatus = async (): Promise<IDeviceStatus[]> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = '/getDeviceStatusV1/v1';
  const method = 'GET';
  const result = await client.invokeApi({}, path, method, {});
  if (typeof result.data == 'string') {
    return JSON.parse(result.data);
  }
  return result.data;
};

export const getDeviceComments = async (
  deviceId: string
): Promise<{ comments: IHubComment[]; resultsPage: any }> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/comments/v1/byResourceIdAndType`;
  const method = 'GET';
  const params = {
    queryParams: {
      referenceId: deviceId,
      type: 'DEVICE',
      limit: 50
    }
  };
  const result = await client.invokeApi({}, path, method, params);
  const comments = result.data.comments || [];
  const userIds: string[] = uniqBy(
    comments.map((comment) => comment.createdBy),
    (i) => i
  );
  const users = await Promise.all(
    userIds.map((id) => {
      if (!id) return { id, alias: 'Not Available', avatar_picture: '' };
      return getUserByIdentityId(id)
        .then((res) => res.data)
        .catch((e) => {
          console.log('Error fetching user', e);
          return { id, alias: 'Not Available', avatar_picture: '' };
        });
    })
  );

  const commentResult = comments.map((comment) => {
    const user = users.find((u) => u.id === comment.createdBy);
    return {
      ...comment,
      createdBy: user
    };
  });
  return {
    comments: commentResult,
    resultsPage: result.data.resultsPage
  };
};

export const addNewCommentToDevice = async (payload) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/comments/v1`;
  const method = 'POST';
  const result = await client.invokeApi({}, path, method, {}, payload);
  return result.data;
};

export const updateDeviceStatus = async (
  device: IDeviceStatus
): Promise<void> => {
  const client = await apigClient();
  const path = '/storeDevices/v1';
  const method = 'POST';
  const result = await client.invokeApi({}, path, method, {}, device);
  return result.data;
};

export const sendRebootCommand = async (deviceId: string): Promise<any> => {
  const client = await apigClient(prodBaseUrlV1);
  const body = {
    requestId: generateV4UUID(),
    action: 'sendcommand',
    deviceId,
    command: 'REBOOT_SYSTEM',
    type: 'Bolt',
    args: {}
  };
  const path = '/commands/v2';
  const result = await client.invokeApi({}, path, 'POST', {}, body);
  return result.data;
};

export const sendUpdateConfigurationCommand = async (
  deviceId: string
): Promise<any[]> => {
  const client = await apigClient(prodBaseUrlV1);
  const body = {
    requestId: generateV4UUID(),
    action: 'sendcommand',
    deviceId,
    command: 'LENSE_CONFIGURATION_UPDATE',
    type: 'Lense',
    args: {}
  };
  const path = '/commands/v2';
  const result = await client.invokeApi({}, path, 'POST', {}, body);
  return result.data;
};

export const uploadDeviceVideo = (payload: {
  file: File;
  deviceId: string;
  brandId: string;
}): AxiosPromise<{ url: string }> => {
  const { file, brandId, deviceId } = payload;
  const form = new FormData();
  form.append('file', file);
  form.append('filename', file.name);
  form.append('type', file.type);
  form.append('brandId', brandId);
  form.append('deviceId', deviceId);
  return httpClient.post('/api/uploadDeviceVideo', form);
};
