import React from 'react';
import { FiCheck, FiSearch } from 'react-icons/fi';
import { RiCloseFill } from 'react-icons/ri';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { HubContext } from '../HubContext';
import UploadProgressIndicator from './UploadProgressIndicator';
import { useSelector } from 'react-redux';
import {
  IMainState,
  ISessionConvertedFile,
  ISessionFile
} from '../../../interfaces';
import { changeContentOrder } from '../../utils/nudge';
import { fileIsPrepared } from '../../../utils/session';

const SessionFileList = ({
  files,
  onRemove,
  onView,
  updateFormData
}: {
  files: string[];
  onRemove: (index: number) => void;
  updateFormData: (files: ISessionFile[]) => void;
  onView: (file: ISessionConvertedFile) => void;
}) => {
  const { color } = React.useContext(HubContext);
  const hubState = useSelector((state: IMainState) => state.clientState?.hub);
  const uploadedFiles = hubState?.sessions?.formData?.uploadedFiles || [];
  const fileUploadStatus = hubState?.sessions?.fileUploadStatus || [];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = changeContentOrder(uploadedFiles, oldIndex, newIndex);
    updateFormData(sorted);
  };

  const sortContent = (index: number) => {
    const fileName = files[Number(index)];
    const uploadProgress = fileUploadStatus.find(
      (status) => status.name === fileName
    )?.progressPercentage;
    const isUploaded = uploadedFiles.find(
      (uploaded) => uploaded?.title === fileName
    );

    return (
      <div className="uploaded-file">
        <div className="file-title">
          <span className="file-title-text">{fileName}</span>
          {isUploaded ? <FiCheck color={color.accent} /> : null}
        </div>
        <div className="file-controls">
          {fileIsPrepared(fileName, hubState?.sessions?.formData) && (
            <button
              onClick={() => {
                onView(
                  hubState?.sessions?.formData?.preparedFiles?.find(
                    (f) => f.title === fileName
                  )
                );
              }}
              className="btn btn-file-preview"
            >
              <FiSearch />
            </button>
          )}
          <button
            onClick={() => {
              onRemove(index);
            }}
            className="btn"
          >
            <RiCloseFill />
          </button>
        </div>
        <UploadProgressIndicator
          progressPercentage={uploadProgress}
          color={color.accent}
        />

        <style jsx>
          {`
            .uploaded-file {
              display: flex;
              padding: 0.275rem 0 0.275rem 0.75rem;
              margin: 0.275rem;
              align-items: center;
              background: #efefef;
              width: 100%;
              position: relative;
            }

            :global(.uploaded-file > div:last-child) {
              position: absolute;
              bottom: 0;
              left: 0;
            }

            .file-title {
              display: flex;
              flex: 0.9;
              width: 100%;
              overflow: hidden;
              align-items: center;
            }

            .file-title-text {
              max-width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .file-controls {
              display: flex;
              flex: 0.1;
              justify-content: center;
            }

            .uploaded-file:last-child {
              border-bottom: none;
            }
            .uploaded-file :global(svg) {
              pointer-events: none;
              margin-left: 10px;
            }
          `}
        </style>
      </div>
    );
  };

  const SortableItem = SortableElement(({ value }) => sortContent(value));

  const SortableList = SortableContainer(({ files }) => {
    return (
      <div>
        {files.map((fileName, index) => (
          <SortableItem
            key={`item-${fileName}-${index}`}
            index={index}
            {...({ value: index } as any)}
          />
        ))}
      </div>
    );
  });

  return (
    <div>
      <SortableList
        helperClass="sortItem sortActive"
        onSortEnd={onSortEnd}
        lockAxis="y"
        {...({ files: files } as any)}
      />
    </div>
  );
};

export default SessionFileList;
