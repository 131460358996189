/* eslint-disable @typescript-eslint/no-empty-function */
import { isEmpty } from 'lodash';
import qs from 'qs';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { logEvent } from '../../../analytics';
import { createContactSupportIssue } from '../../../clientSideServices/contactSupport';
import { SUPPORT_TOPICS } from '../../../config';
import {
  CONTACT_SUPPORT_EVENT,
  DID_CLICK_SUBMIT_CONTACT_SUPPORT,
  DID_SUBMIT_CONTACT_SUPPORT_FAILED,
  DID_SUBMIT_CONTACT_SUPPORT_SUCCESSFUL
} from '../../../utils/constants';
import FileUpload from '../Common/FileUploader/FileUpload';
import { HubContext } from '../HubContext';
import Layout from '../Layout';
import { ThankYou } from './ThankYou';

type ContactFormValues = {
  topic: string;
  question: string;
  questionDetail: string;
  attachments: Array<File>;
  giveLoginPermission: boolean;
};

const contactFormInitialValues: ContactFormValues = {
  topic: '',
  question: '',
  questionDetail: '',
  attachments: [],
  giveLoginPermission: false
};

type ContactForm = {
  values: ContactFormValues;
  state: {
    isSubmitting?: boolean;
    isSubmitted?: boolean;
    success?: boolean;
  };
  errors?: Partial<Record<keyof ContactFormValues, string>> & {
    backendError?: string;
  };
};

const contactFormValidator = {
  topic: (val: string) => {
    if (isEmpty(val?.trim())) {
      return 'Please select a topic.';
    }
    return '';
  },
  question: (val: string) => {
    if (isEmpty(val?.trim())) {
      return 'Please enter your question.';
    }
    return '';
  },
  questionDetail: (val: string) => {
    if (isEmpty(val?.trim())) {
      return 'Please enter your question detail.';
    }
    return '';
  },
  attachments: (files: File[]) => {
    if (files.length > 3) {
      return 'You can only upload max 3 files.';
    }
    if (files.some((file) => file.size > 30 * 1024 * 1024)) {
      return 'Upload file size can not exceed 30MB';
    }
    return '';
  }
};

export const ContactSupport = () => {
  const [form, setContactForm] = useState<ContactForm>({
    values: contactFormInitialValues,
    state: {}
  });
  const [createdTickId, setCreatedTicketId] = useState('');

  const { user, brandId, storeId } = useContext(HubContext);

  const setFieldValues = (values: Partial<ContactFormValues>) => {
    const errors = {};
    if (form.state?.isSubmitted) {
      Object.keys(values).forEach((key) => {
        const validateFn = contactFormValidator[key];
        if (validateFn) {
          const fieldError = validateFn(values[key]);
          errors[key] = fieldError;
        }
      });
    }
    setContactForm((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...values
      },
      errors: {
        ...(prev.errors || {}),
        ...errors
      }
    }));
  };
  useEffect(() => {
    const hubQueryString = window.location.hash.split('?')[1] || '';
    const query = qs.parse(hubQueryString);
    if (query?.topic) {
      const topic = SUPPORT_TOPICS.find((t) => t.val === query.topic);
      setFieldValues({ topic: topic?.label || '' });
    }
  }, []);

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    setContactForm((form) => {
      logEvent(
        CONTACT_SUPPORT_EVENT,
        DID_CLICK_SUBMIT_CONTACT_SUPPORT,
        form.values
      );
      if (form.state.isSubmitting) {
        return form;
      }
      const errors = {};
      Object.keys(form.values || {}).forEach((key) => {
        const validateFn = contactFormValidator[key];
        if (validateFn) {
          const fieldError = validateFn(form.values[key]);
          errors[key] = fieldError;
        }
      });

      if (!isEmpty(Object.values(errors).join(''))) {
        return {
          ...form,
          errors,
          state: {
            ...form,
            isSubmitted: true
          }
        };
      }

      createContactSupportIssue({
        topic: form.values.topic,
        question: form.values.question,
        questionDetail: form.values.questionDetail,
        author: user.email,
        spaceId: storeId,
        brandId,
        attachments: form.values.attachments,
        giveAccessPermission: form.values.giveLoginPermission ? 'Yes' : 'No'
      })
        .then(({ data }) => {
          logEvent(
            CONTACT_SUPPORT_EVENT,
            DID_SUBMIT_CONTACT_SUPPORT_SUCCESSFUL,
            {
              ...data,
              author: user.email,
              spaceId: storeId,
              brandId
            }
          );

          setCreatedTicketId(data.id);
          setContactForm((prev) => ({
            ...prev,
            state: {
              ...prev.state,
              isSubmitting: false,
              success: true
            },
            errors: {}
          }));
        })
        .catch((error) => {
          console.log('Create Support Issue Error', error.message);
          logEvent(CONTACT_SUPPORT_EVENT, DID_SUBMIT_CONTACT_SUPPORT_FAILED, {
            author: user.email,
            spaceId: storeId,
            brandId,
            reason: error.message
          });
          setContactForm((prev) => ({
            ...prev,
            state: {
              ...prev.state,
              isSubmitting: false
            },
            errors: {
              ...prev.errors,
              backendError: 'Create Support Issue Error'
            }
          }));
        });

      return {
        ...form,
        state: {
          ...form,
          isSubmitted: true,
          isSubmitting: true
        },
        errors
      };
    });
  };

  return (
    <Layout showNav={true} theme="dark-light" className="layoutSessions">
      {createdTickId && <ThankYou ticketId={createdTickId} />}
      {!createdTickId && (
        <>
          <h4 className="text-center">Contact Support</h4>
          <div className="m-auto contact-form-container px-3 pb-5">
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <InputGroup></InputGroup>
                <Form.Label>Select a Topic*</Form.Label>
                <Form.Control
                  as="select"
                  name="topic"
                  value={form.values.topic}
                  onChange={(e) => setFieldValues({ topic: e.target.value })}
                  disabled={form.state?.isSubmitting}
                >
                  <option value={''} disabled>
                    Choose a topic
                  </option>
                  {SUPPORT_TOPICS.map((topic) => (
                    <option key={topic.val} value={topic.label}>
                      {topic.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid" className="d-block">
                  {form?.errors?.topic}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>What is your question*</Form.Label>
                <Form.Control
                  type="text"
                  name="question"
                  onChange={(e) => setFieldValues({ question: e.target.value })}
                  value={form.values.question}
                  disabled={form.state?.isSubmitting}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {form?.errors?.question}
                </Form.Control.Feedback>
                <textarea
                  className="form-control w-100 mt-2"
                  rows={10}
                  value={form.values.questionDetail}
                  onChange={(e) =>
                    setFieldValues({ questionDetail: e.target.value })
                  }
                  placeholder="Enter Details"
                  disabled={form.state?.isSubmitting}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {form?.errors?.questionDetail}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <FileUpload
                  type="inline"
                  onClose={() => {}}
                  onUpload={() => {}}
                  onDeleteFile={(index) => {
                    const newFiles = [...form.values.attachments];
                    newFiles.splice(index, 1);
                    setFieldValues({
                      attachments: newFiles
                    });
                  }}
                  onUpdateFileList={(files) => {
                    setFieldValues({
                      attachments: files
                    });
                  }}
                  multipleUpload={true}
                  hideCloseButton={true}
                  dropZonePlaceholder="Only PDF, JPEG, PNG, and MP4 files are supported. (Max file size 30MB)"
                  disabled={form.state?.isSubmitting}
                  showFileListWhileDragging={true}
                  allowEditFileName={false}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {form?.errors?.attachments}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="give_login_permission">
                <Form.Check
                  name=""
                  type="checkbox"
                  label="I give permission for INSPIFY to access my account data, if this is necessary
                      to solve a technical issue, or bug I am experiencing."
                  onChange={(e) =>
                    setFieldValues({ giveLoginPermission: e.target.checked })
                  }
                  disabled={form.state?.isSubmitting}
                />
              </Form.Group>
              <Button
                variant="dark"
                className="d-block text-center w-100 rounded-pill"
                type="submit"
                disabled={form.state?.isSubmitting}
              >
                {form.state?.isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Submit'
                )}
              </Button>
            </Form>
          </div>
        </>
      )}

      <style jsx>{`
        .contact-form-container {
          max-width: 750px;
        }
        :global(.form-label) {
          font-weight: 600;
        }
        textarea {
          resize: none;
        }
        :global(.form-check .form-check-input) {
          width: 27px;
          height: 27px;
        }
        :global(.form-check label) {
          margin-left: 30px;
        }
        :global(.file-container .action) {
          display: none;
        }
        :global(.dropzone.active .file-container) {
          opacity: 0.7;
          margin-bottom: 4px;
        }
      `}</style>
    </Layout>
  );
};
