import { IInspiration, IStoryResponse } from '../interfaces';
import { toS3Url } from '../utils/image';
import { convertSecondsToDuration } from '../utils/string';

export const mapStoryResponseToInspiration =
  (urlSigner: (id: string) => string) =>
  (story: IStoryResponse): IInspiration => {
    const {
      id,
      ownerId,
      ownerAliasName,
      flipPosterUrl,
      title,
      context,
      description,
      type,
      s3VideoURL,
      htmlURL,
      products,
      priority,
      tagList,
      submittedAt,
      createdBy,
      visibility,
      duration,
      campaignImgUrl,
      ctaUUID
    } = story;
    return {
      id,
      sender: ownerId || null,
      senderName: ownerAliasName || null,
      senderImgUrl: urlSigner(ownerId),
      flipImageUrl: toS3Url(flipPosterUrl),
      title: title || null,
      context: context || null,
      description: description || null,
      type: type || null,
      videoUrl: s3VideoURL || null,
      htmlUrl: htmlURL || null,
      productIds: products || [],
      priority: priority || 0,
      tagList: tagList,
      submittedAt: submittedAt || null,
      createdBy: createdBy || null,
      visibility: visibility || null,
      duration: duration ? convertSecondsToDuration(duration) : null,
      campaignImgUrl: toS3Url(campaignImgUrl),
      sceneId: type === 'scene' ? ctaUUID || null : null
    };
  };
