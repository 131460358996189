import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Country, State, City } from 'country-state-city';
import { capitalize, cloneDeep, isEmpty, isEqual } from 'lodash';
import { updateBrand } from '../../../clientSideServices/brand';
import {
  mapAccountBrandToUpdatePayload,
  mapBrandSetupRes
} from '../../utils/mapper';
import OverlayLoading from '../Common/OverlayLoading';
import { IconDomain, IconLocation } from '../Common/HubIcons';
import { useBreakPointDetector } from '../hooks/viewport';
import { logEvent } from '../../../analytics';
import {
  DID_UPDATE_BRAND_PROFILE_SUCCESSFUL,
  HUB_BRANDING_EVENT
} from '../../../utils/constants';
import { HubContext } from '../HubContext';
import { JIRA_HELP_CENTER_LINK } from '../Header';

const allCountries = Country.getAllCountries();

type BrandProfileFormValues = {
  brandUrl: string;
  name: string;
  streetAddress: string;
  country: string;
  city: string;
  state: string;
  zip: string;
};

type BrandProfileForm = {
  values: BrandProfileFormValues;
  state?: {
    submitted?: boolean;
    submitting?: boolean;
  };
  errors?: Partial<BrandProfileFormValues>;
};

const BrandInfo: React.FC<{}> = () => {
  const { screenSize } = useBreakPointDetector();
  const { brandSetup, setBrandSetup } = useContext(HubContext);

  const brandFormInitialValues = useMemo<BrandProfileFormValues>(() => {
    return {
      brandUrl: brandSetup?.brandUrl || '',
      name: brandSetup?.brandName || '',
      streetAddress: brandSetup?.address || '',
      country: brandSetup?.country || '',
      city: brandSetup?.city || '',
      state: brandSetup?.state || '',
      zip: brandSetup?.zipCode || ''
    };
  }, [brandSetup]);

  const [form, setFormState] = useState<BrandProfileForm>({
    values: brandFormInitialValues
  });

  const [activeSectionOnMobile, setActionSectionOnMobile] = useState<
    'basic_info' | 'address'
  >('basic_info');

  const isNoUpdate = useMemo(() => {
    return isEqual(brandFormInitialValues, form.values);
  }, [brandFormInitialValues, form.values]);

  const btnSubmitRef = useRef<HTMLButtonElement>();

  const triggerSubmit = () => {
    btnSubmitRef.current.disabled = false;
    btnSubmitRef.current?.click();
  };

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      values: brandFormInitialValues
    }));
  }, [brandFormInitialValues]);

  const setFieldValue = (
    name: keyof BrandProfileFormValues,
    val: string | File
  ) => {
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [name]: val
      }
    }));
  };

  const setFieldValues = (values: Partial<BrandProfileFormValues>) => {
    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...values
      }
    }));
  };

  const setFieldError = (
    name: keyof BrandProfileFormValues,
    message: string
  ) => {
    setFormState((prev) => ({
      ...prev,
      errors: {
        ...prev.errors,
        [name]: message
      }
    }));
  };

  const handleSubmit = useCallback(() => {
    setFormState((form) => {
      if (!isEmpty(Object.values(form.errors || '').join())) {
        logEvent(HUB_BRANDING_EVENT, DID_UPDATE_BRAND_PROFILE_SUCCESSFUL, {
          errors: form.errors
        });
        return form;
      }
      const updateBrandSetup = cloneDeep(brandSetup);
      updateBrandSetup.brandName = form.values.name;
      updateBrandSetup.address = form.values.streetAddress;
      updateBrandSetup.country = form.values.country;
      updateBrandSetup.state = form.values.state;
      updateBrandSetup.city = form.values.city;
      updateBrandSetup.zipCode = form.values.zip;

      const { brand } = mapAccountBrandToUpdatePayload(
        undefined,
        updateBrandSetup
      );
      const { brand: initialBranding } = mapAccountBrandToUpdatePayload(
        undefined,
        brandSetup
      );

      const brandingChanges = Object.entries(brand || {}).reduce(
        (accChanges, [key, val]) => {
          if (brand[key] != initialBranding[key]) {
            return Object.assign(accChanges || {}, { [key]: val });
          }
          return accChanges;
        },
        {}
      );

      updateBrand(brandSetup.id, brandSetup.brandUrl, brandingChanges)
        .then(({ data: updatedBrandingRes }) => {
          const updatedBrandSetup = mapBrandSetupRes(updatedBrandingRes);
          setBrandSetup((prev) => ({
            ...prev,
            brandSetup: updatedBrandSetup
          }));
          logEvent(HUB_BRANDING_EVENT, DID_UPDATE_BRAND_PROFILE_SUCCESSFUL, {
            brandUrl: brandSetup.brandUrl,
            brandId: brandSetup.id
          });
        })
        .catch((e) => {
          logEvent(HUB_BRANDING_EVENT, DID_UPDATE_BRAND_PROFILE_SUCCESSFUL, {
            brandUrl: brandSetup.brandUrl,
            brandId: brandSetup.id,
            errorMessage: e?.message
          });
        })
        .finally(() => {
          setFormState((form) => ({
            ...form,
            state: { ...form.state, submitting: false }
          }));
        });

      return {
        ...form,
        state: { ...form.state, submitting: true, submitted: true }
      };
    });
  }, [brandSetup]);

  const statesOfCountry = useMemo(() => {
    if (!form.values?.country) return [];
    return State.getStatesOfCountry(form.values?.country);
  }, [form.values?.country]);

  const citiesOfCountry = useMemo(() => {
    if (!form.values?.country || !form.values.state) return [];

    const cities = City.getCitiesOfState(
      form.values.country,
      form.values.state
    );
    return cities;
  }, [form.values]);

  const renderBrandInfoInput = () => (
    <>
      {(screenSize !== 'xs' || activeSectionOnMobile === 'basic_info') && (
        <Form.Group className={`form-group text-group`}>
          <Form.Label
            className="font-weight-normal companyName"
            htmlFor="companyName"
          >
            Company Name
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            id="companyName"
            name="companyName"
            placeholder="Enter Company Name"
            onChange={(e) => {
              const { value } = e.target;
              setFieldValue('name', e.target.value);
              if (!value && brandFormInitialValues.name) {
                setFieldError('name', 'Please enter company name.');
                return;
              }
              setFieldError('name', '');
            }}
            onBlur={() => {
              triggerSubmit();
            }}
            value={form.values.name}
            isInvalid={!!form?.errors?.name}
          />
          <Form.Control.Feedback type="invalid">
            {form?.errors?.name}
          </Form.Control.Feedback>
        </Form.Group>
      )}
      {(screenSize !== 'xs' || activeSectionOnMobile === 'address') && (
        <>
          <Form.Group className={`form-group text-group`}>
            <Form.Label className="font-weight-normal" htmlFor="streetAddress">
              Street Address
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="streetAddress"
              name="streetAddress"
              autoComplete="street-address"
              placeholder="Enter Street Address"
              onChange={(e) => {
                setFieldValue('streetAddress', e.target.value);
              }}
              onBlur={() => {
                triggerSubmit();
              }}
              value={form.values.streetAddress}
            />
          </Form.Group>
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 6 }}>
              <Form.Group>
                <Form.Label className="font-weight-normal" htmlFor="country">
                  Country
                </Form.Label>
                <Form.Control
                  value={form.values?.country}
                  as="select"
                  id="country"
                  name="country"
                  autoComplete="country"
                  onChange={async (e) => {
                    await setFieldValues({
                      country: e.target.value,
                      state: '',
                      city: ''
                    });
                    triggerSubmit();
                  }}
                >
                  <option value={''}>Select Country</option>
                  {allCountries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 6 }}>
              <Form.Group>
                <Form.Label className="font-weight-normal" htmlFor="state">
                  State
                </Form.Label>
                <Form.Control
                  as="select"
                  name="state"
                  id="state"
                  autoComplete="address-line1"
                  onChange={async (e) => {
                    await setFieldValues({
                      state: e.target.value,
                      city: ''
                    });
                    triggerSubmit();
                  }}
                  value={form.values.state}
                >
                  <option value={''}>Select state</option>
                  {statesOfCountry.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {capitalize(state.name)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 6 }}>
              <Form.Group>
                <Form.Label className="font-weight-normal" htmlFor="city">
                  City
                </Form.Label>
                <Form.Control
                  as="select"
                  name="city"
                  id="city"
                  autoComplete="address-line2"
                  onChange={async (e) => {
                    await setFieldValue('city', e.target.value);
                    triggerSubmit();
                  }}
                  value={form.values.city}
                >
                  <option value={''}>Select City</option>
                  {citiesOfCountry.map((city) => (
                    <option key={city.name} value={city.name}>
                      {capitalize(city.name)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 6 }}>
              <Form.Group className={`form-group text-group`}>
                <Form.Label className="font-weight-normal" htmlFor="zip">
                  Zip
                </Form.Label>
                <Form.Control
                  type="number"
                  className="form-control"
                  id="zip"
                  placeholder="-"
                  name="zip"
                  autoComplete="postal-code"
                  onChange={(e) => {
                    setFieldValue('zip', e.target.value);
                  }}
                  onBlur={() => {
                    triggerSubmit();
                  }}
                  value={form.values.zip}
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
    </>
  );

  return (
    <div className="brand-profile">
      {form?.state?.submitting && <OverlayLoading />}
      <div className="mobile-navigator d-flex justify-content-center d-sm-none mb-4 px-2">
        <div
          className="nav-item basic_info p-2 mr-5"
          onClick={() => setActionSectionOnMobile('basic_info')}
        >
          <IconDomain
            color={
              activeSectionOnMobile === 'basic_info' ? '#000000' : '#D8D8D8'
            }
          />
        </div>
        <div
          className="nav-item address p-2"
          onClick={() => setActionSectionOnMobile('address')}
        >
          <IconLocation
            color={activeSectionOnMobile === 'address' ? '#000000' : '#D8D8D8'}
          />
        </div>
      </div>
      <form
        className="brand-form d-flex"
        onSubmit={(e) => {
          e.preventDefault();
          if (isNoUpdate) {
            return;
          }
          handleSubmit();
        }}
      >
        <Container fluid>
          {(screenSize !== 'xs' || activeSectionOnMobile === 'basic_info') && (
            <Row>
              <Col>
                <div>
                  <label className="font-weight-normal d-block mb-4">
                    Brand Domain
                  </label>
                  <h5 className="mb-2 brand_domain">{form.values.brandUrl}</h5>
                  <p className="text-muted mt-0">
                    If you want to change your domain, please contact using
                    <span
                      className="support-text"
                      onClick={() => {
                        window.open(JIRA_HELP_CENTER_LINK,'_blank');
                      }}
                    >
                      Support Page
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          )}
          <Row className="inputs">
            <Col
              className="brand-info"
              xs={{ span: 12 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 7 }}
            >
              {renderBrandInfoInput()}
            </Col>
          </Row>
        </Container>
        <Button
          variant="dark"
          type="submit"
          className="d-none"
          disabled={form.state?.submitting || isNoUpdate}
          ref={btnSubmitRef}
        >
          Edit brand profile details
        </Button>
      </form>
      <style jsx>{`
        .nav-item {
          cursor: pointer;
        }
        .support-text {
          color: #8f6599;
          cursor: pointer;
          margin-left: 2px;
          font-weight: 600;
        }
        .brand-profile {
          max-width: 1200px;
        }
      `}</style>
      <style jsx global>{`
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      `}</style>
    </div>
  );
};

export default BrandInfo;
