import React from 'react';
import Layout from '../Layout';
import { IDevice, IDeviceConfiguration } from '../../../interfaces';
import DeviceList from './DeviceList';
import DeviceConfiguration from './DeviceConfiguration';
import DeviceAnalytics from './DeviceInsight';

type DevicePageType = 'list' | 'configuration' | 'insight' | 'insights-space';
type DevicePage = {
  type: DevicePageType;
  config?: IDeviceConfiguration | null | undefined;
  device?: IDevice | null | undefined;
  userId?: string | null | undefined;
};

function DeviceOverview() {
  const [page, setPage] = React.useState<DevicePage>({
    type: 'list'
  });

  const handlePreview = (id: IDevice) => {
    console.log('preview', id);
  };
  const handleInsight = (device: IDevice, userId?: string) => {
    setPage({
      type: 'insight',
      device: device,
      userId: userId
    });
  };
  const handleEdit = (config: IDeviceConfiguration) => {
    setPage({
      type: 'configuration',
      config: config
    });
  };

  const handleInsightsSpace = () => {
    setPage({ type: 'insights-space', config: null });
  };

  return (
    <Layout showNav={false} theme="dark-light" className="Devices">
      {page.type === 'configuration' && page.config && (
        <DeviceConfiguration
          device={page.config}
          onSave={(config) => {
            setPage({ type: 'configuration', config });
          }}
          onBack={() => {
            setPage({ type: 'list', config: null });
          }}
        />
      )}
      {page.type === 'insight' && (
        <DeviceAnalytics
          device={page.device}
          userId={page.userId}
          onBack={() => {
            setPage({ type: 'list', config: null });
          }}
        />
      )}

      {page.type === 'list' && (
        <DeviceList
          onEdit={handleEdit}
          onInsight={handleInsight}
          onPreview={handlePreview}
          onInsightsSpace={handleInsightsSpace}
        />
      )}

      {page.type === 'insights-space' && (
        <DeviceAnalytics
          device={page.device}
          onBack={() => {
            setPage({ type: 'list', config: null });
          }}
          isInsightsSpace
        />
      )}
    </Layout>
  );
}

export default DeviceOverview;
