import React from 'react';
import { Spinner } from 'react-bootstrap';
import {
  IMainState,
  ISocialMedia,
  SocialMediaType
} from '../../../../interfaces';
import { getFacebookPageList, IFacebookPage } from '../../../utils/facebook';
import FacebookProfile from './FacebookProfile';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { mobileBreakPoint } from '../../BrandStyle';
import { INSPIFY_DARK_LOGO } from '../../../../config';
export const FacebookPage = () => {
  return (
    <div className="d-flex justify-content-between">
      <div className="info"></div>
    </div>
  );
};

const CheckBox = ({
  page,
  onCheck,
  onUnCheck
}: {
  page?: IFacebookPage;
  onCheck: (page?: IFacebookPage) => void;
  onUnCheck: (page?: IFacebookPage) => void;
}) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const handleClick = () => {
    setIsChecked(!isChecked);
  };

  React.useEffect(() => {
    if (isChecked) onCheck(page);
    else onUnCheck(page);
  }, [isChecked]);

  return (
    <span className="checkbox" onClick={handleClick}>
      {isChecked ? (
        <MdCheckBox size={30} />
      ) : (
        <MdCheckBoxOutlineBlank size={30} />
      )}
    </span>
  );
};
function FacebookPages({
  token,
  id,
  onNext,
  onCancel
}: {
  token: string;
  id: string;
  onNext: (media: ISocialMedia[]) => Promise<void>;
  onCancel: () => void;
}) {
  const [pages, setPages] = React.useState<IFacebookPage[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedPages, setSelectedPages] = React.useState<ISocialMedia[]>([]);
  const [adding, setAdding] = React.useState(false);
  const contacts = useSelector(
    (state: IMainState) => state.clientState?.hub?.user?.socialMedia
  );
  const isAlreadyConnected = React.useCallback((id: string) => {
    return contacts.some((contact) => contact.id === id);
  }, []);
  const isUserConnected = React.useMemo(() => isAlreadyConnected(id), [id]);
  React.useEffect(() => {
    getFacebookPageList(token)
      .then((pages) => {
        const notConnectedPages = pages.filter(
          (page) => !isAlreadyConnected(page.id)
        );
        setPages(notConnectedPages);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (!loading && isUserConnected && pages.length === 0) {
      onNext([]);
    }
  }, [isUserConnected, pages, loading]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  const onSelect = (page: IFacebookPage) => {
    const newPage: ISocialMedia = {
      id: page.id,
      type: SocialMediaType.PAGE,
      token: page.access_token
    };
    const newSelectedPages: ISocialMedia[] = [...selectedPages, newPage];
    setSelectedPages(newSelectedPages);
  };

  const onUnSelect = (page: IFacebookPage) => {
    const filterId = page ? page.id : id;
    const newSelectedPages: ISocialMedia[] = selectedPages.filter(
      (p) => p.id !== filterId
    );
    setSelectedPages(newSelectedPages);
  };

  const onSelectUser = () => {
    const user: ISocialMedia = {
      id,
      type: SocialMediaType.USER,
      token
    };
    setSelectedPages([...selectedPages, user]);
  };

  const shouldDisableNext = selectedPages.length === 0 || adding;

  const handleOnNext = () => {
    setAdding(true);
    onNext(selectedPages).finally(() => {
      setLoading(false);
    });
  };
  return (
    <>
      <div className="FacebookPages">
        <div className="back-drop"></div>
        <div className="content">
          <div className="inspify-logo">
            <img src={INSPIFY_DARK_LOGO} alt="inspify-logo" />
          </div>
          <span className="title text-center text-">
            What pages do you want to use with INSPIFY?
          </span>
          <div className="info d-flex justify-content-between">
            <span>All pages ({pages.length})</span>
            <span>Select {selectedPages.length} of your accounts</span>
          </div>
          <div className="pages">
            {!isUserConnected && (
              <div className="page">
                <div className="profile">
                  <FacebookProfile token={token} type={SocialMediaType.USER} />
                </div>
                <CheckBox onCheck={onSelectUser} onUnCheck={onUnSelect} />
              </div>
            )}
            {pages.map((page, index) => (
              <div className="page">
                <div className="profile">
                  <FacebookProfile
                    token={page.access_token}
                    key={index}
                    type={SocialMediaType.PAGE}
                  />
                </div>
                <CheckBox
                  page={page}
                  onCheck={onSelect}
                  onUnCheck={onUnSelect}
                />
              </div>
            ))}
          </div>
          <div className="control d-flex justify-content-between">
            <button className="btn btn-round btn-light" onClick={onCancel}>
              Cancel
            </button>
            <button
              disabled={shouldDisableNext}
              className="btn btn-round btn-dark"
              onClick={handleOnNext}
            >
              {adding ? <Spinner animation="border" size="sm" /> : 'Next'}
            </button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .inspify-logo {
          text-align: center;
        }
        .content {
          overflow-y: auto;
        }
        .inspify-logo img {
          width: 100px;
        }
        .control button {
          min-width: 90px;
        }
        .FacebookPages {
          position: fixed;
          top: 70px;
          left: 0;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @media (max-width: ${mobileBreakPoint}px) {
          .FacebookPages {
            top: 50px;
          }
        }
        div.pages {
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: 400px;
          min-height: 100px;
          max-height: 90vh;
          overflow-y: scroll;
          list-style: none;
        }
        .page {
          padding: 20px 0;
          color: #000;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.5px solid rgba(3, 3, 3, 0.3);
        }
        .page:first-child {
          border-top: 0.5px solid rgba(3, 3, 3, 0.3);
        }
        .page:last-child {
          border-bottom: none;
        }
        .title {
          color: #464646;
          font-size: 1.1em;
          font-weight: 600;
        }
        .back-drop {
          position: absolute;
          top: 0px;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: -1;
        }
        .content {
          display: flex;
          flex-direction: column;
          gap: 25px;
          max-height: 100%;
          max-width: 100vw;
          background-color: #fff;
          padding: 1.5rem;
          border-radius: 0.5rem;
          width: 600px;
          max-width: 95vw;
        }
        input[type='checkbox'] {
          background-color: #fff;
          margin: 0;
          font: inherit;
          color: currentColor;
          width: 1.15em;
          height: 1.15em;
          border: 0.15em solid currentColor;
          border-radius: 0.15em;
          transform: translateY(-0.075em);
        }
        .info span {
          color: #464646;
        }
        .info span:nth-child(1) {
          font-size: 1.1rem;
          font-weight: 600;
        }
      `}</style>
    </>
  );
}

export default FacebookPages;
