import {
  CloudSearchFilter,
  ProductFamily,
  ProductSearchFilter
} from './../../../interfaces';
import React from 'react';
import { HubContext } from '../HubContext';
import { chopardHighJewelleryId } from '../../../config';
import { hjProductSearchFilters } from '../../../utils/productSearchFilters';

interface Props {
  productFamilies: ProductFamily[];
  productSearchFilters: ProductSearchFilter[];
  selectedProductFamilies: ProductFamily[];
  selectedProductSearchFilters: CloudSearchFilter[];
  onSelectProductFamily: (family: ProductFamily) => void;
  onSelectProductSearchFilter: (
    filter: CloudSearchFilter,
    field: string
  ) => void;
  onClose: () => void;
  onReset: () => void;
  darkMode?: boolean;
}

const ProductFilters = ({
  productFamilies,
  productSearchFilters,
  selectedProductFamilies,
  selectedProductSearchFilters,
  onSelectProductFamily,
  onSelectProductSearchFilter,
  onClose,
  onReset,
  darkMode = false
}: Props) => {
  const { storeId } = React.useContext(HubContext);
  const isHj = storeId === chopardHighJewelleryId;
  const [showAll, setShowAll] = React.useState<boolean>(!isHj);
  const languageKey = 'en';
  return (
    <div className="ProductFilters">
      <div className="filter-items text-left">
        {showAll && productFamilies.length ? (
          <div className="filter-group">
            <h4>FAMILY:</h4>
            {productFamilies.map((family, i) => (
              <button
                key={i + family.product}
                className={`btn btn-sm btn-filter btn-outline-secondary ${
                  selectedProductFamilies.some(
                    (f) => f.family === family.family
                  )
                    ? 'active'
                    : ''
                }`}
                onClick={() => onSelectProductFamily(family)}
              >
                {family.displayName[languageKey]}
              </button>
            ))}
          </div>
        ) : null}

        {productSearchFilters
          .filter((filter) => {
            if (showAll) {
              return true;
            }
            return hjProductSearchFilters.includes(filter);
          })
          .map((filter) => (
            <div
              className="filter-group"
              key={filter.filterName.displayName[languageKey]}
            >
              <h4>{filter.filterName.displayName[languageKey]}:</h4>
              {filter.filterOptions.map((option) => (
                <button
                  key={option.displayName[languageKey]}
                  className={`btn btn-sm btn-filter btn-outline-secondary ${
                    selectedProductSearchFilters.some(
                      (f) => f.cloudSearchField === option.cloudSearchField
                    )
                      ? 'active'
                      : ''
                  }`}
                  onClick={() =>
                    onSelectProductSearchFilter(
                      option,
                      filter.filterName.cloudSearchField
                    )
                  }
                >
                  {option.displayName[languageKey]}
                </button>
              ))}
            </div>
          ))}
      </div>
      <div className="control">
        {isHj && (
          <button
            className="btn btn-sm"
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? 'SHOW LESS' : 'SHOW MORE'}
          </button>
        )}
        <button className="btn btn-sm" onClick={onReset}>
          RESET FILTER
        </button>
        <button className="btn btn-sm" onClick={onClose}>
          CLOSE
        </button>
      </div>
      <style jsx>{`
        h4 {
          font-size: 0.8em;
          letter-spacing: 1px;
          text-transform: uppercase;
          opacity: 0.5;
        }
        :global(.ssp) h4 {
          font-size: 1em;
        }
        .filter-items {
          padding: 20px;
          max-height: 280px;
          overflow: auto;
        }
        .filter-items button {
          margin: 0 5px 5px 0;
        }
        .control {
          border-top: 1px solid ${darkMode ? '#444' : '#efefef'};
          text-align: right;
          padding: 5px;
        }
        .control button {
          color: ${darkMode ? '#fff' : '#000'};
          margin-left: 5px;
        }
        :global(.ssp) .filter-items button,
        :global(.ssp) .control button {
          font-size: 1.3em;
          padding: 10px;
        }
        .filter-group {
          margin-bottom: 10px;
        }
        .filter-group:last-child {
          margin-bottom: 0;
        }
        @media (min-width: 768px) {
          .filter-items {
            max-height: none;
          }
        }
      `}</style>
    </div>
  );
};

export default ProductFilters;
