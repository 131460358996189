import React from 'react';
import { logEvent } from '../../../analytics';
import { DID_CLICK_PAGINATION } from '../../../utils/constants';

interface Props {
  totalItems: number;
  itemsPerPage?: number;
  currentPage: number;
  onPageChange: (index: number, shouldFetch: boolean) => void;
  buttonsPerPage?: number;
}

const Pagination = ({
  totalItems,
  currentPage = 5,
  itemsPerPage = 10,
  onPageChange,
  buttonsPerPage = 20
}: Props) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  if (totalPages === 1) return null;

  const totalBtnGroup = Math.ceil(totalPages / buttonsPerPage);
  const currentBtnGroup = Math.ceil(currentPage / buttonsPerPage);
  const currentStartPage =
    currentBtnGroup * buttonsPerPage - buttonsPerPage + 1;
  const isLastBtnGroup = currentBtnGroup === totalBtnGroup;
  const currentBtnLength = isLastBtnGroup
    ? totalPages % buttonsPerPage || buttonsPerPage
    : buttonsPerPage;

  const pages = Array.from(
    { length: currentBtnLength },
    (_, i) => i + currentStartPage
  );

  const handlePageChange = (num: number, shouldFetch: boolean) => {
    onPageChange(num, shouldFetch);
    window.scrollTo(0, 0);
    logEvent(DID_CLICK_PAGINATION, DID_CLICK_PAGINATION, {
      currentPage,
      navToPage: num,
      itemsPerPage
    });
  };

  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <button
            className="page-link arrow-btn"
            aria-label="First"
            disabled={!(currentPage > buttonsPerPage)}
            onClick={() => handlePageChange(1, false)}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>

        <li className="page-item">
          <button
            className="page-link arrow-btn"
            aria-label="Next"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1, false)}
          >
            <span aria-hidden="true">&lsaquo;</span>
          </button>
        </li>

        {pages.map((page) => (
          <li className="page-item" key={page}>
            <button
              className="page-link"
              disabled={currentPage === page}
              onClick={() => handlePageChange(page, page === totalPages)}
            >
              {page}
            </button>
          </li>
        ))}

        <li className="page-item">
          <button
            className="page-link arrow-btn"
            aria-label="Next"
            disabled={currentPage === totalPages}
            onClick={() =>
              handlePageChange(currentPage + 1, currentPage + 1 === totalPages)
            }
          >
            <span aria-hidden="true">&rsaquo;</span>
          </button>
        </li>

        <li className="page-item">
          <button
            className="page-link arrow-btn"
            aria-label="Last"
            onClick={() => handlePageChange(totalPages, true)}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
      <style jsx>{`
        .page-link {
          color: #666;
        }
        .page-link:disabled {
          background: #efefef;
        }
        .arrow-btn:disabled {
          background: #fff;
          color: #ccc;
        }
      `}</style>
    </nav>
  );
};

export default Pagination;
