import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import React from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState, LoadingStatus, ProgressStatus } from '../../../interfaces';
import { logEvent } from '../../../analytics';
import {
  actionHubLoadSessionList,
  actionHubUpdateSessionDate
} from '../../../redux/actions';
import { actionGetSessionsByDateAsync } from '../../../redux/advisorHubAsyncActions';
import {
  getSessionFileStatus,
  SessionFileStatus
} from '../../../utils/session';
import { HubContext } from '../HubContext';
import CalenderHeader from './CalenderHeader';
import SessionContainer from './SessionContainer';
import SessionList from './SessionList';
import { generateV4UUID } from '../../../utils/identityGenerator';
import {
  chopardHighJewelleryId,
  getLoftUrlByStoreId,
  getVBOnboardingUrl,
  getVBUrl,
  getVBUrlByStoreId
} from '../../../config';
import { DID_START_LIVE_SESSION } from '../../../utils/constants';
import ShareWrapper from '../../../components/Common/ShareWrapper';
import SimpleShareModal from '../../../components/Sharing/SimpleShareModal';
import {
  isUserOnMobile,
  isUserOnMobileSafari
} from '../../../utils/deviceDetector';
import { getSimpleShortenedUrl } from '../../../utils/shareUrl';
import { Spinner } from 'react-bootstrap';
import { isOnboardingBrand } from '../../../utils/window';
import BrandSetupReminder from '../BrandSettings/BrandSetupReminder';
import { getCorrectHubHost } from '../../utils/hubPagesRoute';
import { saveSession } from '../../clientSideServices/session';
import { mapSessionFormDataToPayload } from '../../../mappers/session';
import { getFormattedFullName } from '../../../utils/shoppingCart';
const SessionOverView = () => {
  const dispatch = useDispatch();
  const { color, isEmbeddedInSsp, brandSetupStep, brandId, user } =
    React.useContext(HubContext);
  const hubState = useSelector((state: IMainState) => state.clientState.hub);
  const userState = hubState?.user;
  const progressStatus = hubState?.sessions?.progressStatus;
  const date = hubState?.sessions?.date || new Date();
  const sessionList = hubState?.sessions?.sessionList || [];
  const [sessionPopup, setSessionPopup] = React.useState(false);
  const storeId = hubState?.user?.storeId;
  const isMobileOrTab = isUserOnMobile();
  const isMobileSafari = isUserOnMobileSafari();

  const [meetingUrl, setMeetingUrl] = React.useState({
    advisorURL: '',
    clientURL: '',
    microSiteURL: ''
  });
  const [loading, setLoading] = React.useState(LoadingStatus.INITIAL);
  const isLoading = loading === LoadingStatus.LOADING;
  const isError = loading === LoadingStatus.FAILED;
  const host = `https://${getCorrectHubHost(hubState?.user?.brandId)}`;
  const getAdvisorURLForMobile = () => {
    if (isOnboardingBrand()) {
      return getVBOnboardingUrl(storeId, host);
    }
    const uuid = generateV4UUID();
    const storeUrl = getVBUrlByStoreId(storeId) || getLoftUrlByStoreId(storeId);
    return `${storeUrl}?mode=cockpit&role=advisor&meeting=${uuid}`;
  };

  const generateShareUrl = async () => {
    const currentDate = new Date();
    const uuid = generateV4UUID();

    const storeUrl = getVBUrl(storeId, host);
    let clientURL = `${storeUrl}?mode=cockpit&role=client&meeting=${uuid}`;
    let advisorURL = `${storeUrl}?mode=cockpit&role=advisor&meeting=${uuid}`;
    let microSiteURL = `${host}/meet?id=${uuid}&store=${storeId}`;
    const sessionPayload = mapSessionFormDataToPayload(
      {
        id: uuid,
        type: 'live',
        topic: '',
        startDate: currentDate,
        participants: [
          {
            name:
              userState?.name ||
              getFormattedFullName(user.first_name, user.last_name),
            email: userState?.email,
            role: 'HOST',
            noWaitingLounge: 1
          }
        ]
      },
      userState.id,
      storeId,
      brandId,
      false,
      false
    );
    await saveSession(sessionPayload);
    clientURL = `${clientURL}&source=live_session`;
    advisorURL = `${advisorURL}&source=live_session`;
    microSiteURL = `${microSiteURL}&source=live_session`;

    if (typeof getSimpleShortenedUrl === 'function') {
      return getSimpleShortenedUrl(clientURL)
        .then((_clientURL) =>
          getSimpleShortenedUrl(microSiteURL).then((_microSiteURL) => ({
            advisorURL: advisorURL,
            clientURL: _clientURL,
            microSiteURL: _microSiteURL
          }))
        )
        .catch(() => ({
          advisorURL: advisorURL,
          clientURL: '',
          microSiteURL: ''
        }));
    } else {
      return {
        advisorURL: advisorURL,
        clientURL: '',
        microSiteURL: ''
      };
    }
  };

  const preparingFiles = sessionList.some(
    (session) => getSessionFileStatus(session) === SessionFileStatus.PREPARE
  );

  const handleFetchSessions = () => {
    const dateString = format(date, 'MM-dd-yyyy');
    dispatch(actionGetSessionsByDateAsync(userState?.id, dateString));
  };

  const handleDateSelect = (date: Date) => {
    dispatch(actionHubUpdateSessionDate(date));
    dispatch(actionHubLoadSessionList([]));
  };

  React.useEffect(() => {
    dispatch(actionHubUpdateSessionDate(date));
  }, []);

  React.useEffect(() => {
    if (progressStatus !== ProgressStatus.LOADING) {
      handleFetchSessions();
    }
  }, [date]);

  React.useEffect(() => {
    let fetching;
    if (preparingFiles) {
      handleFetchSessions();
      fetching = setInterval(() => {
        handleFetchSessions();
      }, 10000);
    }

    return () => {
      clearInterval(fetching);
    };
  }, [preparingFiles]);

  const getInviteMessage = () => {
    const { first_name, last_name, alias } = user || {};
    const name = alias ? alias : `${first_name || ''} ${last_name || ''}`;
    return `${name} is inviting you to a virtual meeting.`;
  };

  const sharePopup = (
    <ShareWrapper
      onClose={() => setSessionPopup(false)}
      meetingUrl={meetingUrl.advisorURL}
    >
      <div className="control-menu text-left">
        <SimpleShareModal
          show={true}
          position="bottom"
          url={meetingUrl.clientURL}
          onShare={() => null}
          title={getInviteMessage()}
          type="newSession"
          qrUrl={meetingUrl.microSiteURL}
          darkMode={true}
        />
      </div>
      <style jsx global>{`
        .content-wrapper {
          z-index: 4 !important;
        }
      `}</style>
    </ShareWrapper>
  );

  const liveSessionButton = (
    <button
      onClick={() => {
        if (isMobileOrTab && !isOnboardingBrand()) {
          window.open(getAdvisorURLForMobile(), '_blank');
        } else {
          setLoading(LoadingStatus.LOADING);
          //Reason: ios safari block window.open called on async functions
          let windowReference = window;
          if (isMobileSafari) {
            windowReference = window.open() as any;
          }
          generateShareUrl()
            .then((url) => {
              if (isMobileOrTab) {
                setLoading(LoadingStatus.INITIAL);
                if (isMobileSafari) {
                  windowReference.location.href = url.advisorURL;
                  return;
                }
                windowReference.open(url.advisorURL, '_blank');
              }
              setMeetingUrl(url);
              setSessionPopup(true);
              setLoading(LoadingStatus.INITIAL);
              return;
            })
            .catch(() => setLoading(LoadingStatus.FAILED));
          logEvent(DID_START_LIVE_SESSION, DID_START_LIVE_SESSION);
        }
      }}
      className="btn start-session-btn btn-round btn-dark btn-sm"
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner animation="border" size="sm" />
      ) : isError ? (
        'Try Again'
      ) : (
        'Start Live Session'
      )}
      <style jsx>{`
        .start-session-btn {
          min-width: 210px;
          font-size: 18px;
          margin-bottom: 30px;
        }
      `}</style>
    </button>
  );

  return (
    <SessionContainer title="CALENDAR">
      {brandSetupStep === 1 && <BrandSetupReminder />}

      <div className="row content  content-overview flex-grow-1">
        <div className="col-md-12 col-lg-7">
          {storeId !== chopardHighJewelleryId &&
            !isEmbeddedInSsp &&
            liveSessionButton}
          <div className="calender-container">
            <DatePicker
              selected={date}
              onChange={handleDateSelect}
              inline
              locale={enGB}
              className="calender"
              useWeekdaysShort={true}
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <CalenderHeader
                  date={date}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-5 d-flex flex-column h-100">
          <SessionList date={date} host={host} />
        </div>
      </div>
      {sessionPopup && sharePopup}
      <style jsx>
        {`
          .content-overview {
            height: calc(100% - 100px);
          }

          h1 {
            margin-bottom: 0 !important;
          }

          .back-btn {
            padding-left: 0;
          }
          :global(.back-btn > svg) {
            width: 28px;
            height: auto;
          }
          .overview-text {
            font-weight: 500;
          }
          .content {
            margin-top: 40px;
          }
          :global(.ssp) .content {
            margin-top: 30px;
          }

          .btn:focus {
            outline: none;
            box-shadow: none;
          }
          .bold {
            font-weight: bold;
          }

          .calender-container {
            margin-right: 30px;
          }

          @media (max-width: 992px) {
            .calender-container {
              margin-right: 0;
              margin-bottom: 30px;
            }
          }

          @media (max-width: 480px) {
            .sessions-container {
              padding: 30px 30px 0;
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }

          .react-datepicker,
          .react-datepicker__month-container {
            width: 100%;
            box-shadow: 0 0 4px 3px #f0f0f0;
            border: none;
          }

          .react-datepicker__month-container {
            padding: 30px;
          }

          .calender,
          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            width: 50px;
            height: 50px;
          }

          .ssp .calender,
          .ssp .react-datepicker__day-name,
          .ssp .react-datepicker__day,
          .ssp .react-datepicker__time-name {
            width: 62px;
            height: 62px;
            font-size: 1.4em;
          }
          .react-datepicker__day-name {
            color: ${color?.accent} !important;
          }
          .react-datepicker__day--today {
            background: ${color?.accent} !important;
            color: ${color?.light} !important;
          }
          .react-datepicker__day {
            font-weight: 400 !important;
          }

          .react-datepicker__day-names,
          .react-datepicker__week {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .react-datepicker__day--outside-month {
            font-weight: normal !important;
            opacity: 0.3 !important;
          }

          .react-datepicker__header {
            background-color: #fff;
            border-bottom: none;
          }

          .react-datepicker__day-name {
            text-transform: uppercase;
            text-transform: uppercase;
            color: #888888;
          }

          .react-datepicker__day {
            display: inline-flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            border-radius: 100%;
          }

          .react-datepicker__day--selected,
          .react-datepicker__day--selected:hover {
            background-color: #f0f0f0;
            color: #000;
          }

          .react-datepicker__day:hover {
            border-radius: 100%;
          }

          .react-datepicker__day:focus {
            outline: none;
          }

          @media (max-width: 480px) {
            .calender,
            .react-datepicker__day-name,
            .react-datepicker__day,
            .react-datepicker__time-name {
              width: 30px;
              height: 30px;
            }
          }
        `}
      </style>
    </SessionContainer>
  );
};

export default SessionOverView;
