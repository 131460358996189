import React from 'react';
import { IconArrow } from './HubIcons';

type MenuItem = {
  label: string;
  id: string;
  onClick?: (id?: string) => void;
  icon?: React.ReactNode;
  panel?: React.ReactNode;
  disabled?: boolean;
};

const SideMenuItem = ({
  label,
  id,
  onClick,
  icon,
  active
}: MenuItem & { active?: boolean }) => (
  <div
    className={`item ${active ? 'active' : ''} ${!onClick ? 'disabled' : ''}`}
    onClick={() => onClick?.(id)}
    id={`side-menu-item-${id}`}
  >
    <div className="item-container">
      {icon && <div className="icon">{icon}</div>}
      <div className="text-medium">{label}</div>
    </div>
    <style jsx>{`
      .item {
        height: 72px;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
      }
      .item.disabled {
        opacity: 0.3;
        cursor: not-allowed;
        pointer-events: none;
      }
      .item:hover,
      .item.active {
        background-color: rgba(19, 124, 189, 0.2);
      }
      .icon {
        margin-bottom: 2px;
      }
      .item-container {
        margin: auto;
        text-align: center;
      }
    `}</style>
  </div>
);

const SideMenuPanel = ({
  defaultMenu,
  width = 372,
  menuItems
}: {
  defaultMenu?: string;
  width?: number;
  menuItems: MenuItem[];
}) => {
  const [activeMenu, setActiveMenu] = React.useState(defaultMenu);
  return (
    <div className={`SideMenuPanel h-100`}>
      <div className="tab">
        {menuItems.map((item) => (
          <SideMenuItem
            {...item}
            onClick={
              item.disabled
                ? undefined
                : (id) => {
                    setActiveMenu(id);
                    item.onClick?.(id);
                  }
            }
            key={item.id}
            active={activeMenu === item.id && !!item.panel}
          />
        ))}
      </div>

      {menuItems.map(
        (item) =>
          activeMenu === item.id &&
          item.panel && (
            <div key={item.id} className="panel">
              <div className="panel-content w-100 h-100">{item.panel}</div>
              <div className="close-btn" onClick={() => setActiveMenu(null)}>
                <IconArrow direction="left" />
              </div>
            </div>
          )
      )}

      <style jsx>{`
        .tab {
          height: 100%;
          font-size: 10px;
          font-weight: 500;
          width: 72px;
        }
        .panel {
          border-left: 1px solid #e1e1e1;
          width: calc(${width}px - 72px);
          height: 100%;
        }
        .panel-content {
          overflow: hidden;
          position: relative;
          background: #fff;
          z-index: 1;
          height: 100%;
        }
        .SideMenuPanel {
          display: flex;
          position: relative;
          flex-wrap: wrap;
          height: 100%;
        }
        .SideMenuPanel.open {
          width: ${width};
        }
        .close-btn {
          text-align: right;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -15px;
          background: #ccc;
          border: 1px solid #ccc;
          border-radius: 0 100px 100px 0;
          width: 20px;
          padding: 40px 0;
          line-height: 100px;
          cursor: pointer;
        }
        .close-btn:hover {
          background: #fff;
        }
        .close-btn :global(svg) {
          width: 15px;
          height: auto;
        }
      `}</style>
    </div>
  );
};

export default SideMenuPanel;
