import React from 'react';
import { format } from 'date-fns';
import { FaChevronCircleRight, FaChevronCircleLeft } from 'react-icons/fa';
import { HubContext } from '../HubContext';

interface ICalenderHeader {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const CalenderHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}: ICalenderHeader) => {
  const { color } = React.useContext(HubContext);
  const monthAndYear = format(date, 'MMMM yyyy');
  return (
    <div className="calender__header-wrapper">
      <button
        className="btn control-button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <FaChevronCircleLeft />
      </button>
      <div className="month-and-year">{monthAndYear}</div>
      <button
        className="btn control-button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <FaChevronCircleRight />
      </button>
      <style jsx>
        {`
          .calender__header-wrapper {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .month-and-year {
            font-weight: 400;
            text-transform: uppercase;
            color: ${color?.accent};
            font-size: 1.2em;
          }
          :global(.ssp) .month-and-year{
            font-size:2em;
          }
          .control-button {
            margin: 0 30px;
            font-size:2em;
          }

          .control-button:focus {
            outline: none;
            box-shadow: none;
          }

          :global(.control-btn svg) {
            color: white;
          }
        `}
      </style>
    </div>
  );
};

export default CalenderHeader;
