import { isEmpty } from 'lodash';
import { BrowsingHistoryEventType, IBrowsingHistory } from '../../../interfaces';
import { apigClient } from '../aws';
import { clone } from 'lodash'
export interface BrowsingHistoryPayload {
  totalRows: number;
  browsingHistory: IBrowsingHistory[];
}

export const browsingHistoryByIdentityId = async (
  userId: string,
  startRow: number,
  limit = 50
): Promise<BrowsingHistoryPayload> => {
  const client = await apigClient();
  const body = {
    userId,
    limit,
    startRow
  };
  const path = `/browsingHistory/v1/byUserId`;
  const result = await client.invokeApi({}, path, 'POST', {}, body);
  return result.data;
};


export const mapProductBrowsingHistories = (
  browsingHistories: IBrowsingHistory[]
): IBrowsingHistory[] => {
  let prevIndex = 0;
  return browsingHistories
    .map((history, index) => {
      const isValidProduct = (!isEmpty(history?.modelCode) || !isEmpty(history?.productId));
      if (isValidProduct && history?.pageType === 'PRODUCT') {
        if (history?.event_type === BrowsingHistoryEventType.EYEBALL_TIME) {
          prevIndex = index;
          return history;
        }
        if (
          history?.event_type === BrowsingHistoryEventType.DID_VIEW_PAGE &&
          (browsingHistories[prevIndex]?.productId !==
            history?.productId ||
            (browsingHistories[prevIndex]?.productId ===
              history?.productId && browsingHistories[prevIndex]?.event_type ===
              BrowsingHistoryEventType.DID_VIEW_PAGE))
        ) {
          prevIndex = index;
          return history;
        }
      }
      prevIndex = index;
    })
    .filter((h) => h);
};

export const aggregateLiveBrowsingHistoriesByProductCode = (browsingHistories: IBrowsingHistory[]): IBrowsingHistory[] => {
  const _browsingHistories = clone(browsingHistories);
  return _browsingHistories.map((history, index) => {
    const productCode = history?.modelCode;
    let durationInMs: number = +history?.durationInMs || 0;
    for (let i = index + 1; i < browsingHistories.length; i++) {
      if (_browsingHistories[i]?.modelCode === productCode) {
        durationInMs += +(_browsingHistories[i]?.durationInMs) || 0;
        _browsingHistories.splice(i, 1);
        i--;
      }
    }
    return { ...history, durationInMs: durationInMs + "" };
  }).filter(h => h);
}

export const mapAnalyticsEventHistory = (histories): IBrowsingHistory[] => {
  return histories.map((event) => {
    const payload = event.payload;
    return {
      ...payload,
      cognito_id: event.identityId,
      event_type: event.type,
      modelCode: payload?.modelCode?.toUpperCase(),
      event_datetime: new Date(event?.event_timestamp)?.toISOString(),
    }
  })
}

export const pushEventsToCurrentHistory = (currentHistories: IBrowsingHistory[], histories) => {
  let current = [...currentHistories];
  const events = mapAnalyticsEventHistory(histories);
  const index = current?.findIndex((history) => history?.sessionId === events[0]?.sessionId);
  if (index !== -1) {
    current?.splice(index, 1, { rank: index + 1, ...events[0] });
  }
  else current = [{ rank: 1, ...events[0] }, ...currentHistories];
  return current;
}