import React from 'react';
import ChatActions from './ChatActions';
import ChatFooter from './ChatFooter';
import ChatMessages from './ChatMessages';
import { HubChatContext } from './HubChatContext';

const ChatDetails = () => {
  const { conversations, currentConversation, chatUsers } =
    React.useContext(HubChatContext);

  const details = conversations.find(
    (conversation) => conversation?.conversationId === currentConversation
  );

  const customerName =
    chatUsers?.[details?.conversationCreatedBy]?.alias || 'Customer';

  return (
    <div className="chat-details-wrapper">
      {details ? (
        <>
          <div className="chat-header">
            <div className="chat-header-left">
              <div className="customer-name">{customerName}</div>
            </div>
            <ChatActions />
          </div>
          <ChatMessages conversationId={details?.conversationId} />
          <ChatFooter conversationId={details?.conversationId} />
        </>
      ) : (
        <div className="empty-message">
          <h1>No messages</h1>
        </div>
      )}

      <style jsx>{`
        .chat-details-wrapper {
          border: 1px solid #c4c4c4;
          height: 75vh;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }

        .empty-message {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .chat-header {
          padding: 16px 24px;
          border-bottom: 1px solid #c4c4c4;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .chat-header-left {
          display: flex;
          align-items: center;
          max-width: 65%;
        }

        :global(.btn-dark svg) {
          margin-right: 15px;
        }

        .customer-name {
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          text-transform: uppercase;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        @media (max-width: 480px) {
          .chat-details-wrapper {
            border-right: none;
            border-left: none;
            border-bottom: none;
            height: 0;
          }

          .sessions-container {
            padding-top: 0;
            padding-bottom: 20px;
          }

          .customer-name {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
          }

          .chat-header {
            padding: 10px;
          }

          .chat-header-left {
            width: 90%;
            max-width: 90%;
          }

          :global(.btn-dark svg) {
            margin-right: 0;
          }
        }

        @media (max-width: 1024px) {
          .chat-header {
            padding: 16px 10px;
          }
          .chat-header-left {
            width: 52%;
            max-width: 52%;
          }
        }
      `}</style>
    </div>
  );
};

export default ChatDetails;
