import React from 'react';
import { useSelector } from 'react-redux';
import {
  IMainState,
  ISocialMedia,
  SocialMediaType
} from '../../../../interfaces';
import { isEmbeddedInStreamingStudio } from '../../../../utils/streamingstudio';
import { getCurrentHost } from '../../../../utils/window';
import {
  facebookAuthUrl,
  FacebookConnectionStatus,
  IFacebookLoginResponse
} from '../../../utils/facebook';
import { HubUserContext } from '../HubUserContext';
import FacebookAccount from './FacebookAccount';
import FacebookNotification from './FacebookNotification';
import FacebookPages from './FacebookPages';

interface INotification {
  open: boolean;
  status: FacebookConnectionStatus;
}
function FacebookAccounts() {
  const contacts =
    useSelector(
      (state: IMainState) => state?.clientState?.hub?.user?.socialMedia
    ) || [];
  const [notification, setNotification] = React.useState<INotification>();
  const { addSocialMediaContact, removeSocialMediaContact } =
    React.useContext(HubUserContext);
  const [addingConnection, setAddingConnection] =
    React.useState<ISocialMedia>();
  const [readyToConnect, setReadyToConnect] = React.useState(false);
  const isEmbeddedInSSP = isEmbeddedInStreamingStudio();

  const shouldShowConnectButton = !isEmbeddedInSSP;
  const handleLoginSuccess = (res: IFacebookLoginResponse) => {
    const authResponse = res.authResponse;
    const token = authResponse?.accessToken;
    const id = authResponse?.userID;
    setNotification({
      open: false,
      status: null
    });
    setAddingConnection({
      type: SocialMediaType.USER,
      token,
      id
    });
  };

  const handleLoginFailed = (error) => {
    setNotification({
      open: true,
      status: error.status
    });
  };
  React.useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const { origin, data } = event;
      if (origin !== facebookAuthUrl || !data.status) return;
      if (data.status === 'success') handleLoginSuccess(data.response);
      else handleLoginFailed(data.response);
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  const onNext = (medias: ISocialMedia[]) => {
    if (medias.length === 0) {
      setAddingConnection(null);
      setNotification({
        open: true,
        status: FacebookConnectionStatus.ALREADY_CONNECTED
      });
      return Promise.resolve();
    }

    return addSocialMediaContact(medias)
      .then(() => {
        setNotification({
          open: true,
          status: FacebookConnectionStatus.SUCCESS
        });
      })
      .catch(() => {
        setNotification({
          open: true,
          status: FacebookConnectionStatus.ERROR
        });
      })
      .finally(() => {
        setAddingConnection(null);
      });
  };

  const authUrl = `${facebookAuthUrl}/facebook?target=${getCurrentHost()}`;
  const retryUrl = `${authUrl}&retry=true`;
  return (
    <>
      <div className="ConnectFacebook">
        <div className="connect-head d-flex justify-content-between pt-3 pb-3">
          <span>Connected Account</span>
          {shouldShowConnectButton && (
            <button className="btn-add btn btn-dark btn-round flex align-items-center justify-content-center">
              <iframe
                allow="web-share"
                src={authUrl}
                onLoad={() => setReadyToConnect(true)}
              />
            </button>
          )}
        </div>
        <div className="connect-body">
          {contacts.map((socialMedia) => (
            <FacebookAccount
              socialMedia={socialMedia}
              key={socialMedia.id}
              onRemove={removeSocialMediaContact}
            />
          ))}
        </div>
      </div>
      {addingConnection && (
        <FacebookPages
          token={addingConnection.token}
          id={addingConnection.id}
          onNext={onNext}
          onCancel={() => {
            setAddingConnection(null);
          }}
        />
      )}
      <FacebookNotification
        open={notification?.open}
        onClose={() => {
          setNotification({ open: false, status: null });
        }}
        type={notification?.status}
        retryUrl={retryUrl}
      />
      <style jsx>{`
        .connect-head {
          height: 100px;
          align-items: center;
        }
        .connect-body :global(hr:last-child) {
          display: none;
        }
        .btn-add {
          padding: 0 0;
          height: 40px;
          display: ${readyToConnect ? 'block' : 'none'};
        }
        button iframe {
          border: none;
          width: 230px;
          height: 100%;
          border-radius: 50px;
        }
      `}</style>
    </>
  );
}

export default FacebookAccounts;
