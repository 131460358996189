import React, { useMemo } from 'react';
import { ITableColumn, SortTable } from '../../Common/Table';
import { interactionStyles } from './helpers';
import { ISLSelectItem } from '.';
import ImageWithWebp from '../../Common/ImageWithWebp';

const SLAnalyticsInteractions = (props: {
  handleSelectItem: (item: ISLSelectItem) => void;
  data: any;
  loading: boolean;
}) => {
  const interactions = props.data;

  const interactionData = useMemo(() => {
    const data = interactions
      ?.filter((i) => i?.storybook?.id)
      .map((item) => {
        return {
          ...item,
          storybook: {
            thumbnail: item.storybook?.featuredImage,
            id: item.storybook.id,
            title: item.storybook.title,
            subtitle: item.storybook.subtitle
          },
          title: item.storybook.title,
          address: {
            type: item.type,
            address: item.title
          }
        };
      });
    return data;
  }, [interactions]);

  const topInteractionColumns: ITableColumn[] = [
    {
      key: 'storybook',
      title: 'Storybook',
      align: 'left',
      width: '50%',
      custom: (value, rowIndex) => {
        return (
          <div className="scenes_order">
            <span>{rowIndex + 1}</span>
            {value?.thumbnail ? (
              <ImageWithWebp src={value.thumbnail} />
            ) : (
              <div className="notfound-thumbnail" />
            )}
            <p>
              <span title={value.title || ''}>{value.title}</span>
              <span title={value.title || ''}>{value.subtitle}</span>
            </p>
          </div>
        );
      }
    },
    {
      key: 'address',
      title: 'Type',
      align: 'left',
      custom: (value) => {
        const vType = value.type?.toLocaleLowerCase?.();
        const color = interactionStyles[vType]?.color || 'black';
        return (
          <div className="custom-interaction">
            <div className="value">
              <span>{value.address}</span>
              <span className="type" style={{ color }}>
                {vType}
              </span>
            </div>
          </div>
        );
      }
    },
    {
      key: 'totalClicks',
      title: 'Clicks',
      align: 'center'
    },
    {
      key: 'userCount',
      title: 'Viewers',
      align: 'center'
    }
  ];

  const handleSelectRow = (row) => {
    props.handleSelectItem({
      id: row.storybook.id,
      name: row.storybook.title,
      thumbnail: row.storybook.thumbnail,
      type: 'storybook'
    });
  };

  return (
    <>
      <div className="analytics_scenes" data-isloading={props.loading === true}>
        <SortTable
          columns={topInteractionColumns}
          sorts={['order', 'totalClicks', 'userCount']}
          data={interactionData}
          onRowSelect={handleSelectRow}
          onlyActiveSortBy
          noData={{
            text: `No Interactions Found`,
            align: 'center',
            verticalAlign: 'middle'
          }}
          stripped
        />
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>
      <style jsx>{`
        :global(.type-container) {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
        :global(.badge-icon) {
          background: purple;
          width: 12px;
          height: 12px;
          border-radius: 100%;
        }
        :global(.analytics_scenes .sort-table td) {
          font-weight: 500;
          color: #252525;
        }
        .analytics_scenes[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }

        .analytics_scenes[data-isloading='true'] .overlay {
          display: flex;
        }
      `}</style>
    </>
  );
};

export default SLAnalyticsInteractions;
