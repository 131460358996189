import React from 'react';
import timeZones from './timezones.json';
import Select from 'react-select';
import { HubContext } from '../HubContext';
const SessionTimezone = ({
  timezone,
  onChange
}: {
  timezone: string;
  onChange: (value: string) => void;
}) => {
  const { color } = React.useContext(HubContext);
  const [showList, toggleShowList] = React.useState<boolean>(false);

  const onSelectItem = (tz) => {
    onChange(tz?.tzCode);
    toggleShowList(false);
  };

  const selectedOption = timeZones.find((item) => item?.tzCode === timezone);
  return (
    <div className="wrapper inline-block">
      <span
        className="btn select-timezone-btn"
        onClick={() => toggleShowList(!showList)}
      >
        <Select
          value={selectedOption}
          options={timeZones}
          placeholder="Select timezone"
          data-lpignore="true"
          classNamePrefix="select-timezone"
          menuPortalTarget={document.body}
          controlShouldRenderValue={true}
          formatOptionLabel={(option) => option.label}
          onChange={(option) => onSelectItem(option)}
          styles={{
            singleValue: (base) => ({
              ...base,
              color: color?.accent
            }),
            menu: (base) => ({
              ...base,
              width: '300px',
              maxWidth: '100vw',
              textAlign: 'left'
            }),
            option: (base) => ({
              ...base,
              background: '#fff',
              color: 'black',
              ':hover': {
                backgroundColor: '#e2e2e2'
              }
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: color?.accent
            }),
            control: (base) => ({
              ...base,
              borderWidth: 0
            }),
            indicatorSeparator: (base) => ({
              ...base,
              display: 'none'
            })
          }}
        />
      </span>

      <style jsx>{`
        .wrapper {
          display: inline-block;
        }
      `}</style>
    </div>
  );
};

export default SessionTimezone;
