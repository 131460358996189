import { isEmpty } from 'lodash';
import React from 'react';
import ChatDetails from './ChatDetails';
import ChatList from './ChatList';
import ChatListWrapper from './ChatListWrapper';
import { HubChatContext } from './HubChatContext';

const HubChatContainer = () => {
  const { conversations, conversationRequests, isMobile, searchText } =
    React.useContext(HubChatContext);

  if (isEmpty(conversations) && isEmpty(conversationRequests)) {
    return (
      <div className="empty-queue-wrapper">
        <h1>Your chat queue is empty...</h1>
        <style jsx>{`
          .empty-queue-wrapper {
            padding-top: 100px;
            position: relative;
          }
        `}</style>
      </div>
    );
  }

  return (
    <>
      {isMobile ? (
        <div className="content">
          <div className="chat-list">
            <ChatList keywords={searchText} />
          </div>
        </div>
      ) : (
        <div className="row content">
          <div className="col-md-5 col-lg-4 chat-list">
            <ChatListWrapper keywords={searchText} />
          </div>
          <div className="col-md-7 col-lg-8 pl-0 chat-details">
            <ChatDetails />
          </div>
        </div>
      )}
      <style jsx>{`
        .search-box {
          position: relative;
        }
        .search-box > :global(svg) {
          position: absolute;
          z-index: 5;
          top: 12px;
          left: 10px;
        }

        .search-input {
          border-radius: 20px !important;
          padding-left: 30px;
        }
        .content {
          margin-top: 2rem;
        }

        :is(.chat-search, .chat-list) {
          padding-right: 0;
        }
        .chat-details {
          padding-left: 0;
        }
        @media (max-width: 480px) {
          .chat-search {
            padding: 0 15px;
          }
          .chat-list {
            padding-right: 0;
          }
          .chat-details {
            display: none;
            padding-left: 15px;
          }
        }
      `}</style>
    </>
  );
};

export default HubChatContainer;
