import moment from 'moment';
import { formatDurationFromMiliSeconds } from '../../../../utils/number';

export const getAreaChartTooltip = (params, currentTab) => {
  const { series, dataPointIndex, w } = params;
  const seriesValue = series.map((s, index) => {
    const label = w.globals.seriesNames[index];
    const color = w.globals.colors[index];
    const value = s[dataPointIndex];
    return {
      label,
      value,
      color
    };
  });

  const label = w.globals.categoryLabels[dataPointIndex];
  const isDurationTab =
    currentTab.id === 'averageEyeballTime' ||
    currentTab.id === 'totalEyeballTime';
  return `
        <div class="area-custom-tooltip">
        <div class="header">
        <div class="date">${label}</div>
        <div class="tab">${currentTab.label}</div>
        </div>
        <ul>
        ${seriesValue
          .map(
            (series) => `
            <li>
        <div class="tooltip-label">
        <span class="color" style="background-color: ${series.color}"></span>
        <span class="label">${series.label}</span>
        </div>
        <div class="tooltip-value">${
          !isDurationTab
            ? series.value
            : formatDurationFromMiliSeconds(series.value)
        }</div>`
          )
          .join('')}
        </ul>
        </div>
        </li>
      `;
};

export const CHART_COLORS = {
  Gray: '#767676',
  Red: '#B63B3B',
  Orange: '#E89B55',
  Yellow: '#E2C115',
  LightGreen: '#6AC773',
  DarkGreen: '#093F0E',
  Blue: '#203DA5',
  Indigo: '#281E5D',
  Purple: '#9039B7',
  Pink: '#B82AA1',
  Teal: '#B82AA1',
  Brown: '#864F27',
  Maroon: '#3C0008',
  LightPink: '#E77CD6',
  PinkOrange: '#F89880',
  Cerulean: '#0492C2',
  BabyBlue: '#A2CFFE',
  Amethyst: '#A45EE5',
  Lilac: '#EBA4FF',
  DarkLilac: '#8F6599'
};
export const CHART_TOTAL_COLOR = CHART_COLORS.Gray;

export const getChartColorByIndex = (index: number) => {
  const colors = Object.values(CHART_COLORS);
  return colors[(index + 1) % colors.length];
};

export const mapAndSortViewsDensity = (
  viewsDensity: Record<string, number[]> = {},
  startDate: string,
  endDate: string,
  range: number
) => {
  //add empty 0 array for missing date in range
  const fromDate =
    range > 0
      ? moment().subtract(range, 'days').format('YYYY-MM-DD')
      : startDate;
  const toDate = range > 0 ? moment().format('YYYY-MM-DD') : endDate;
  const length = moment(toDate).diff(moment(fromDate), 'days') + 1;
  const density: Record<string, number[]> = new Array(length)
    .fill(0)
    .reduce((acc, _, currentIndex) => {
      const dateStr = moment(fromDate)
        .add(currentIndex, 'days')
        .format('YYYY-MM-DD');
      const views = viewsDensity[dateStr];
      acc[dateStr] = views ? views : new Array(24).fill(0);
      return acc;
    }, {});

  const densityArray = Object.entries(density);
  densityArray.sort(([date1], [date2]) => {
    return moment(date1).unix() - moment(date2).unix();
  });
  return densityArray.map(([date, views]) => {
    return {
      data: views,
      name: moment(date).format('DD MMM')
    };
  });
};

const aggregateValues = (
  target: { [date: string]: number },
  source: { [date: string]: number }
) => {
  for (const date in source) {
    if (target[date]) {
      target[date] += source[date];
    } else {
      target[date] = source[date];
    }
  }
};

export const flattenData = (nestedData) => {
  const result = {
    viewed: {
      Products: {},
      Story: {}
    },
    shared: {
      Products: {},
      Story: {}
    }
  };

  for (const category in nestedData.viewed) {
    for (const locale in nestedData.viewed[
      category as keyof typeof nestedData.viewed
    ]) {
      aggregateValues(
        result.viewed[category as keyof typeof result.viewed],
        nestedData.viewed[category as keyof typeof nestedData.viewed][locale]
      );
    }
  }

  for (const category in nestedData.shared) {
    for (const locale in nestedData.shared[
      category as keyof typeof nestedData.shared
    ]) {
      aggregateValues(
        result.shared[category as keyof typeof result.shared],
        nestedData.shared[category as keyof typeof nestedData.shared][locale]
      );
    }
  }

  return result;
};
