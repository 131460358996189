import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentUserInfo,
  getUserByIdentityId,
  saveUser,
  updateUserInfor,
  updateUserStatus
} from '../../../clientSideServices/users';
import { IMainState, LoginStatus } from '../../../interfaces';
import { actionHubUpdateLoginStatus } from '../../../redux/actions';
import { signOut } from '../../clientSideServices/login';
import { completeNewPassword } from '../../clientSideServices/user';
import { getThemeColor, mobileBreakPoint } from '../BrandStyle';
import LoaderButton from '../Common/LoaderButton';

interface ErrorField {
  field: 'first_name' | 'last_name' | 'password' | 'confirm_password',
  type: 'empty' | 'letter' | 'length' | 'valid-password' | 'match-password',
  msg: string
}
interface IField {
  first_name : any,
  last_name: any,
  password?: any
  confirm_password?: any
}
const FieldToText:IField = {
  first_name: {
    capitalize: "First Name",
    lowercase: "first name"
  },
  last_name: {
    capitalize: "Last Name",
    lowercase: "last name"
  },
  password: {
    capitalize: "Password",
    lowercase: "password"
  },
  confirm_password: {
    capitalize: "Confirm Password",
    lowercase: "confirm password"
  }
}
type ObjectKey = keyof typeof FieldToText;

function CompletePwd({ brandId }: { brandId: string }) {
  const color = getThemeColor(brandId);
  const [error, setError] = React.useState('');
  const hub = useSelector((state: IMainState) => state.clientState.hub) || {};
  const { loginErrorMessage } = hub;
  const [fields, setFields] = React.useState({
    first_name: "",
    last_name: "",
    password: "",
    confirm_password: "",
    loading: false,
    completeUpdatePwd: false,
  });
  const [validateMsgs, setValidateMsgs] = React.useState<ErrorField[]>([]);

  const [visiblePassword, setVisiblePassword] = React.useState({
    password: false,
    confirm_password: false
  });
  const dispatch = useDispatch();

  const isOnlyLetters = (text: string) => /^[a-zA-Z\s]*$/.test(text);
  const isValidPasswordText = (password: string) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).*$/.test(
      password
    );
  const isValidPasswordLength = (password: string) => password.length >= 8;
  const isNotMatchPassword = () =>
    !!(
      fields.password &&
      fields.confirm_password &&
      fields.confirm_password !== fields.password
    );

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const field = e.target.id;
    const fieldValue = e.target.value;
    setFields({ ...fields, [field]: fieldValue });
    validateField(field, fieldValue.trim());
  };

  const validateField = (fieldName: string, fieldValue: string) => {
    const field = fieldName as ObjectKey;
    const msgs: ErrorField[] = [...validateMsgs].filter(
      (v) => v.field !== fieldName
    );

    if (!fieldValue) {
      msgs.push({
        field,
        type: "empty",
        msg: `${FieldToText[field].capitalize} field cannot be empty. Please enter your ${FieldToText[field].lowercase}.`,
      });
    } else {
      if (field === "first_name" || field === "last_name") {
        if (!isOnlyLetters(fieldValue)) {
          msgs.push({
            field,
            type: "letter",
            msg: `Invalid characters detected in ${FieldToText[field].capitalize}. Please use only letters.`,
          });
        }
      } else if (field === "password") {
        if (!isValidPasswordLength(fieldValue)) {
          msgs.push({
            field,
            type: "length",
            msg: "Your password must be at least 8 characters long.",
          });
        } else {
          if (!isValidPasswordText(fieldValue)) {
            msgs.push({
              field,
              type: "valid-password",
              msg: "Your password must include at least one uppercase letter, one lowercase letter, one number, and one special character.",
            });
          }
        }
      }
    }
    setValidateMsgs([...msgs]);
  };

  const validateData = () => {
    const {first_name, last_name, password, confirm_password} = fields;
    if (!first_name || !last_name || !password || !confirm_password) return false;
    if (!isOnlyLetters(first_name) || !isOnlyLetters(last_name)) return false;
    if (!isValidPasswordLength(password)) return false;
    if (!isValidPasswordText(password)) return false;
    if (isNotMatchPassword()) return false;
    return true;
  }

  const handleUpdatePwdSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setError("");
    e?.preventDefault();
    if(!validateData()) return;
    handleCompletePassword();
  };

  const handleCompletePassword = () => {
    if (loginErrorMessage) {
      setFields({ ...fields, loading: true });
      const userSignin = JSON.parse(loginErrorMessage);
      completeNewPassword(
        userSignin.username,
        userSignin.password,
        fields.password
      )
      .then(() => {
        setError("");
        getCurrentUserInfo()
          .then((user) => {
            updateUserInfor({
              given_name: fields.first_name.trim(),
              family_name: fields.last_name.trim()
            })
            updateUserStatus("accepted");
            return user
          })
          .then((user) =>getUserByIdentityId(user.id))
          .then((res) => saveUser({ ...res.data, alias: fields.first_name.trim() }))
          .then(() => signOut())
          .then(() =>
            setFields({ ...fields, loading: false, completeUpdatePwd: true })
          );
      })
        .catch((e) => {
          setFields({ ...fields, loading: false });
          console.error(e);
          setError(e.message);
        });
    }
  };
  const handleBackToLogin = () => {
    dispatch(actionHubUpdateLoginStatus(LoginStatus.CONFIRMED_PASSWORD));
  };
  const getEyeIcon = (field: string) => {
    if (!fields[field]) return null;
    const visible = visiblePassword[field];
    const onClick = () =>
      setVisiblePassword({
        ...visiblePassword,
        [field]: !visible
      });
    const configIcon = {
      className: 'ml-1',
      size: 20,
      fill: '#343434',
      onClick
    };
    return visible ? (
      <AiOutlineEye {...configIcon} />
    ) : (
      <AiOutlineEyeInvisible {...configIcon} />
    );
  };
  return (
    <>
      <div className="page-body ResetPwd">
        <div className="container">
          <h1>
            User Setup
            <i className="separator" />
          </h1>
          <p className="accent">
            Please complete your profile by entering your first name, last name,
            and creating a new password.
          </p>
          <div className="form-container">
            {error && <div className="alert alert-danger">{error}</div>}
            {fields.completeUpdatePwd && (
              <div className="alert alert-success">
                Reset password successfully, login now
              </div>
            )}
            {!fields.completeUpdatePwd && (
              <React.Fragment>
                <Container>
                  <Row>
                    <Col className="form-first-name">
                      <div
                        className={`form-group text-group ${
                          validateMsgs.find((v) => v.field === 'first_name')
                            ? 'form-error'
                            : ''
                        }`}
                      >
                        <label htmlFor="first_name">First Name</label>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            name="first_name"
                            data-lpignore="true"
                            autoComplete="first_name"
                            value={fields.first_name}
                            onChange={handleFormChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div
                        className={`form-group text-group ${
                          validateMsgs.find((v) => v.field === 'last_name')
                            ? 'form-error'
                            : ''
                        }`}
                      >
                        <label htmlFor="last_name">Last Name</label>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="last_name"
                            data-lpignore="true"
                            autoComplete="last_name"
                            value={fields.last_name}
                            onChange={handleFormChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <div
                  className={`form-group text-group ${
                    validateMsgs.find((v) => v.field === 'password')
                      ? 'form-error'
                      : ''
                  }`}
                >
                  <label htmlFor="password">New Password</label>
                  <div className="d-flex align-items-center">
                    <input
                      type={visiblePassword.password ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      autoComplete="off"
                      value={fields.password}
                      onChange={handleFormChange}
                    />
                    {getEyeIcon('password')}
                  </div>
                </div>
                <div
                  className={`form-group text-group ${
                    validateMsgs.find((v) => v.field === 'confirm_password') ||
                    isNotMatchPassword()
                      ? 'form-error'
                      : ''
                  }`}
                >
                  <label htmlFor="password">Confirm New Password</label>
                  <div className="d-flex align-item-center">
                    <input
                      type={
                        visiblePassword.confirm_password ? 'text' : 'password'
                      }
                      className="form-control"
                      id="confirm_password"
                      autoComplete="off"
                      value={fields.confirm_password}
                      onChange={handleFormChange}
                    />
                    {getEyeIcon('confirm_password')}
                  </div>
                </div>
              </React.Fragment>
            )}
            {(!!validateMsgs.length || isNotMatchPassword()) && (
              <div className="text-error">
                {validateMsgs.map((v, i) => (
                  <div key={i}>{v.msg}</div>
                ))}
                {isNotMatchPassword() && (
                  <div>Password do not match. Please confirm your password.</div>
                )}
              </div>
            )}
            <div className="form-group">
              {!fields.completeUpdatePwd ? (
                <LoaderButton
                  isLoading={fields.loading}
                  disabled={!validateData() || fields.loading}
                  className="btn btn-dark"
                  onClick={handleUpdatePwdSubmit}
                >
                  Complete Setup
                </LoaderButton>
              ) : (
                <button className="btn btn-dark" onClick={handleBackToLogin}>
                  Back to login
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <style>{`
        h1 {
          color: #fff;
          text-align: left !important;
        }
        h1 .separator {
          margin: 10px 0 !important;
        }
        .accent {
          text-align: left;
        }
        p {
          color: #fff;
        }
        .content {
          padding: 0 30px;
        }
        .form-label {
          display: flex;
          justify-content: space-between;
        }
        .text-error {
          color: #FF0000;
        }
        .text-error {
          color: #FF0000;
          font-size: 12px;
          text-align: left;
          margin-bottom: 5px;
        }

        .form-error {
          border-color: #FF0000 !important;
        }
        .form-error label{
          color: #FF0000 !important;
        }
        .form-error input{
          color: #FF0000 !important;
        }

        .col {
          padding: 0;
        }
        .form-first-name {
          margin-right: 20px;
        }
        .row {
          margin-right: 0;
          margin-left: 0;
        }
        .container {
          max-width: 500px;
          margin: auto;
          padding: 0;
        }
        .form-container {
        }
        :global(.ssp) .form-container{
          max-width:700px;
        }
        .text-group {
          text-align: left;
          border: 1px solid #dedede;
          background: #fff;
          padding: 0 10px 5px;
          margin-bottom: 20px;
        }
        .btn {
          border-radius: 0;
          width: 100%;
          padding: 13px 0;
        }
        :global(.ssp) .btn{
          font-size:1.4em;
        }
        .text-group label {
          padding: 0;
          margin: 0;
          font-size: 0.6em;
          font-weight: 400;
          letter-spacing: 1px;
          color: ${color.accent};
        }
        :global(.ssp) .text-group label{
          font-size:.8em;
        }
        .text-group input {
          box-shadow: none;
          outline: none;
          border: none;
          padding: 0;
          margin: 0;
          height: auto;
        }
        
        .input-error {
          color: red;
        }
        :global(.kbActive) h1,
        :global(.kbActive) p{
          display:none;
        }
        @media (min-width: ${mobileBreakPoint}px) {
          h1 {
            color: #000;
          }
          p {
            color:  ${color.accent}};
            margin-bottom: 30px;
          }
        }
      `}</style>
    </>
  );
}

export default CompletePwd;
