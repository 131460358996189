import React from 'react';
import { BsX } from 'react-icons/bs';
import { MdSearch } from 'react-icons/md';
import { logEvent } from '../../../analytics';
import { IMainState, ITeam } from '../../../interfaces';
import {
  DID_CLICK_BUTTON,
  DID_CLICK_CREATE_TEAM
} from '../../../utils/constants';
import { TeamPageType } from './TeamPage';
import { mobileBreakPoint } from '../BrandStyle';
import { HubUserContext } from './HubUserContext';
import { defaultAvatarUrl } from '../../config';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { HubContext } from '../HubContext';
import format from 'date-fns/format';
import { assetBaseUrl } from '../../../config';
import UserLabel from '../Common/UserLabel';
import { isImage } from '../../../utils/file';
import { Spinner } from 'react-bootstrap';
import { orderBy, uniqBy, debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { useBrandTeams } from '../hooks/teams';
export const MessageDisplay = ({ msg }: { msg: string }) => {
  return (
    <>
      <div className="ErrorMessage">
        <div className="child">
          <img
            className="team-empty-img"
            src={`${assetBaseUrl}/icons/no-teams.gif`}
          ></img>
          <div className="text-container">
            <p className="message-text">{msg}</p>
          </div>
        </div>
      </div>

      <style jsx>{`
        .ErrorMessage {
          padding-top: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        .ErrorMessage .child {
          text-align: center;
        }
        .team-empty-img {
          width: 15rem;
        }
        .message-text {
          font-size: 1em;
        }
        .text-container {
          padding-top: 2rem;
          padding-bottom: 5rem;
          color: gray;
        }
        @media (max-width: 769px) {
          .text-container {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
          .message-text {
            font-size: 1em;
          }
          .team-empty-img {
            width: 9rem;
          }
        }
      `}</style>
    </>
  );
};
const HubTeams = ({
  setTeamPage,
  setTeam
}: {
  setTeamPage: (teamPage: TeamPageType) => void;
  setTeam: (team: ITeam) => void;
}) => {
  const batchSize = 21;
  const isMobile = isUserOnMobileOnly();
  const { fetchUsersByBrand, usersByBrand } = React.useContext(HubUserContext);
  const { brandId, user, addUpdateDeleteTeam } = React.useContext(HubContext);
  const [teams, setTeams] = React.useState<ITeam[]>([]);
  const [keyword, setKeyword] = React.useState('');
  const hubLabelState = useSelector(
    (state: IMainState) => state.clientState?.hub?.userInfo || {}
  );
  const [teamState, setTeamState] = React.useState({
    keyword: '',
    startIndex: 0,
    totalCount: 0
  });
  const { isLoading, data, error } = useBrandTeams(
    brandId,
    keyword,
    teamState.startIndex,
    batchSize
  );
  const debouncedChangeHandler = React.useMemo(
    () =>
      debounce((e) => {
        setKeyword(e.target.value);
      }, 300),
    []
  );
  const loadMore = () => {
    if (teamState.startIndex < teamState.totalCount)
      setTeamState({
        ...teamState,
        startIndex: teamState.startIndex + batchSize
      });
  };
  const getUrl = (memberId) => {
    const url = usersByBrand?.find((user) => user.id === memberId);
    return isImage(url?.avatar_picture) ? url.avatar_picture : defaultAvatarUrl;
  };
  const onError = (ev) => {
    if (ev?.target?.src) ev.target.src = defaultAvatarUrl;
  };

  const noSearchedTeamsFound = () => {
    return (
      <>
        <div className="noSearchedTeamsFound">
          <div className="child">
            <img
              className="team-empty-img"
              src={`${assetBaseUrl}/icons/no-results.gif`}
            />
            <div className="text-container">
              <p className="message-text">
                No results found for <strong>{teamState.keyword}</strong>
              </p>
              <button
                onClick={() => {
                  setTeamState({ ...teamState, keyword: '', startIndex: 0 });
                  setKeyword('');
                }}
                className="btn border border-dark clear-btn"
              >
                <strong>Clear Search</strong>
              </button>
            </div>
          </div>
        </div>
        <style jsx>{`
          .noSearchedTeamsFound {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          .noSearchedTeamsFound .child {
            text-align: center;
          }
          .team-empty-img {
            width: 8rem;
            padding-top: 100px;
          }
          .message-text {
            font-size: 1.25em;
            color: gray;
          }
          .text-container {
            padding-top: 5rem;
            padding-bottom: 5rem;
          }
          .clear-btn {
            border-radius: 100px;
          }
          @media (max-width: 769px) {
            .text-container {
              padding-top: 1rem;
              padding-bottom: 1rem;
            }
            .message-text {
              font-size: 1em;
            }
            .team-empty-img {
              width: 9rem;
            }
          }
        `}</style>
      </>
    );
  };
  const createdDate = (date: string) =>
    new Date(date).toString() !== 'Invalid Date'
      ? format(new Date(date), 'MMM dd, yyyy - p')
      : 'unknown';
  const getTeam = (team: ITeam) => (
    <div key={team.id}>
      <hr className="solid" />
      <div
        className="row team-row"
        onClick={() => {
          setTeam(team);
          setTeamPage(
            user.id === team.createdBy ? TeamPageType.EDIT : TeamPageType.DETAIL
          );
          logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
            button: 'show team detail',
            teamID: team.id
          });
        }}
      >
        <div className="col-sm-9">
          <h6 className="team-name">{team.name}</h6>
          <span className="created-text">
            Created by{' '}
            <strong>
              <UserLabel id={team.createdBy} emptyName="" />
            </strong>{' '}
            on {createdDate(team.createdAt)}
          </span>
        </div>
        <div className="col-sm-3">
          <div className="image-border float-right">
            {team.members?.map(
              (memberId, index) =>
                index < 3 && (
                  <img
                    className="member-img rounded-circle"
                    data-holder-rendered="true"
                    src={getUrl(memberId)}
                    onError={onError}
                    key={memberId}
                  />
                )
            )}
            <span className="member-count">{team.members?.length}</span>
          </div>
        </div>
      </div>

      <style jsx>{`
        .solid {
          margin: 0px;
          transform: translateY(-2px);
        }
        .member-count {
          font-weight: bold;
          padding-right: 10px;
          padding-top: 4px;
          color: #888;
          float: right;
        }
        .image-border {
          border: 3px solid #e0e0e0;
          border-radius: 100px;
          padding: 5px;
          padding-left: 20px;
          width: 140px;
        }
        .member-img {
          border: 3px solid #e0e0e0;
          width: 32px;
          height: 32px;
          margin-left: -10px;
        }
        .team-row {
          background-color: #fefefe;
          padding: 10px;
          cursor: pointer;
        }
        .created-text {
          color: gray;
          font-size: 0.85rem;
        }
      `}</style>
    </div>
  );
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom && !isLoading) {
      loadMore();
    }
  };
  const showFooterOfTeam = () => {
    if (error && !isLoading) {
      return 'An error occurred, please try again later';
    }
    const _teams = teams?.filter((team) => {
      const { firstName, lastName } = hubLabelState[team?.createdBy] || {};
      const keyword = teamState?.keyword?.toLowerCase();
      return (
        `${firstName} ${lastName}`?.toLowerCase().includes(keyword) ||
        team?.name?.toLowerCase().includes(keyword)
      );
    });
    if (!isLoading && !_teams?.length) {
      if (teamState.keyword?.length > 0) {
        return noSearchedTeamsFound();
      } else {
        return (
          <MessageDisplay msg="No teams to display. Please create a team." />
        );
      }
    }
    if (isLoading) {
      return (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      );
    }
    return null;
  };
  React.useEffect(() => {
    const _teams: ITeam[] = data?.teams?.filter((team) => team !== null);
    if (_teams) {
      const teamsOrder = orderBy(
        uniqBy(
          [
            ...(addUpdateDeleteTeam?.team ? [addUpdateDeleteTeam?.team] : []),
            ..._teams,
            ...teams
          ],
          (p) => p?.id
        ),
        [(team) => team.name.toLowerCase()],
        ['asc']
      );
      if (addUpdateDeleteTeam?.type === 'delete') {
        setTeams(
          teamsOrder.filter((team) => team.id !== addUpdateDeleteTeam.team.id)
        );
      } else {
        setTeams(teamsOrder);
      }
      setTeamState({ ...teamState, totalCount: data?.totalCount });
    }
  }, [data]);
  React.useEffect(() => {
    console.log('addUpdateDeleteTeam ', addUpdateDeleteTeam);
    if (!usersByBrand) fetchUsersByBrand();
  }, []);
  if (isMobile) {
    return <MessageDisplay msg="Please use desktop for teams." />;
  }
  return (
    <>
      <div className="teams-page h-100">
        <div className="container h-100">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 team-title">
            <h1 className="mb-4">TEAMS</h1>
          </div>
          <div className="search-bar col-md-12 col-sm-12 col-12">
            <div className="team-search row">
              <div className="col-xl-9 col-md-8 col-12">
                <input
                  type="text"
                  className="form-control ssp-ignore search-input"
                  placeholder="Search"
                  value={teamState.keyword}
                  onChange={(e) => {
                    setTeamState({
                      ...teamState,
                      keyword: e.target.value,
                      startIndex: 0
                    });
                    debouncedChangeHandler(e);
                  }}
                />
                {teamState.keyword && (
                  <div
                    className="btn btn-sm btn-clear"
                    onClick={() => {
                      setTeamState({
                        ...teamState,
                        keyword: '',
                        startIndex: 0
                      });
                      setKeyword('');
                    }}
                  >
                    <BsX />
                  </div>
                )}
                <div className="btn btn-search ssp-ignore">
                  <MdSearch />
                </div>
              </div>
              <div className="col-xl-3 col-md-4 col-12 create-btn ">
                <button
                  onClick={() => {
                    const newTeam: ITeam = {
                      id: generateV4UUID(),
                      name: '',
                      brand: brandId,
                      createdBy: user.id,
                      createdAt: new Date().toISOString(),
                      members: [user.id],
                      modifiedBy: user.id,
                      modifiedAt: new Date().toISOString(),
                      description: ''
                    };
                    setTeam(newTeam);
                    setTeamPage(TeamPageType.CREATE);
                    logEvent(DID_CLICK_CREATE_TEAM);
                  }}
                  className="btn btn-dark create-team"
                  disabled={!user}
                >
                  {user ? (
                    'Create New Team'
                  ) : (
                    <Spinner animation="border" size="sm" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div
            className="overflow-auto team-user-list col"
            onScroll={handleScroll}
          >
            {teams
              ?.filter((team) => {
                const { firstName, lastName } =
                  hubLabelState[team?.createdBy] || {};
                const keyword = teamState?.keyword?.toLowerCase();
                return (
                  `${firstName} ${lastName}`.toLowerCase().includes(keyword) ||
                  team?.name?.toLowerCase().includes(keyword)
                );
              })
              ?.map((team) => getTeam(team))}

            {showFooterOfTeam()}
          </div>
        </div>
      </div>
      <style jsx global>{`
        .content-wrapper {
          height: 100% !important;
          min-height: auto !important;
          display: flex;
          flex-direction: column;
          align-content: stretch;
          overflow: hidden;
          padding-top: 0 !important;
        }
        .layout {
          min-height: 100vh !important;
        }
      `}</style>
      <style jsx>
        {`
          .search-bar {
            height: 50px;
          }
          .team-title {
            height: 80px;
            padding-top: 20px;
          }
          .team-user-list {
            margin-top: 16px;
            height: calc(100% - 200px);
            overflow: auto;
          }
          hr.solid {
            border-top: 1px solid #bbb;
          }
          .team-search .form-control {
            border: #c4c4c4 solid 0.3px;
            border-radius: 50px;
          }
          .team-search .btn {
            border-radius: 50px;
            min-width: 80px;
          }
          .teams-page {
          }
          .btn-search,
          .btn-clear {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .btn-search {
            left: 0;
          }
          .btn-clear {
            right: 0px;
          }
          input.form-control {
            padding: 0 40px 0 40px;
          }
          .form-control {
            border-radius: 50px;
            border: #ccc solid 1px;
            max-width: 100%;
            padding: 0 5px;
            font-size: 1rem;
          }
          .create-btn {
            padding-right: 0px;
          }
          .create-btn > button {
            width: 100%;
          }
          @media (max-width: ${mobileBreakPoint}px) {
            .create-btn {
              padding-right: 0px;
              margin-top: 1rem;
              padding-right: 1rem;
            }
            .team-title {
              height: 80px;
              padding-top: 20px;
            }
            .search-bar {
              height: 100px;
            }
            .team-user-list {
              margin-top: 16px;
              height: calc(100% - 230px);
              overflow: auto;
            }
          }
        `}
      </style>
    </>
  );
};

export default HubTeams;
