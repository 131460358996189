import React from 'react';
import { AiOutlineDownload, AiOutlineMail } from 'react-icons/ai';
import { logEvent } from '../../analytics';
import {
  iwcWW2021Id,
  loubiAirwaysJpBoutiqueId,
  louboutinBrandId
} from '../../config';
import {
  DID_CLICK_DOWNLOAD_EMAIL_TEMPLATE,
  DID_CLICK_CONFIRM_SESSION_BUTTON,
  DID_FAIL_TO_CONFIRM_NATIVELY,
  DID_CLICK_SESSION_CONFIRM_BUTTON
} from '../../utils/constants';
import { DropdownButton, Dropdown, Spinner } from 'react-bootstrap';
import { LineIcon, LineShareButton } from 'react-share';
import { ISessionDetails } from '../../interfaces';
import { performNativeShare } from '../../utils/window';
import { format, parse } from 'date-fns';
import { getInviteConfirmationContent } from './PreConfiguredInvites/config/loubiairwaysjp';
import { first } from 'lodash';
import StoryShareButton from './ContactCenter/StoryShareButton';
import { getSessionFileStatus, SessionFileStatus } from '../../utils/session';
import { MdAttachFile, MdWarning } from 'react-icons/md';

export const getSessionCTA = (
  session: ISessionDetails,
  sessionUrl: { host: string; mc: string; guest: string },
  isMobile?: boolean
) => {
  const onSuccessfulNativeShare = () => {
    logEvent(
      DID_CLICK_CONFIRM_SESSION_BUTTON,
      DID_CLICK_CONFIRM_SESSION_BUTTON,
      {
        type: 'native',
        sessionId: session.id
      }
    );
  };
  const onFailedNativeShare = () => {
    logEvent(DID_FAIL_TO_CONFIRM_NATIVELY, DID_FAIL_TO_CONFIRM_NATIVELY, {
      type: 'native',
      sessionId: session.id
    });
  };

  const logEmailTemplateDownload = () => {
    logEvent(
      DID_CLICK_DOWNLOAD_EMAIL_TEMPLATE,
      DID_CLICK_DOWNLOAD_EMAIL_TEMPLATE,
      { sessionId: session.id }
    );
  };
  if (session.storeId === iwcWW2021Id) {
    return (
      <button className="btn download-btn">
        <AiOutlineDownload />
        <a
          href={`/api/downloadEmail/${session.id}/iwcww2021`}
          target="_blank"
          className="download-email-cta"
          onClick={(e) => {
            e.stopPropagation();
            logEmailTemplateDownload();
          }}
        >
          Download Email
        </a>
      </button>
    );
  }

  if (session.storeId === loubiAirwaysJpBoutiqueId) {
    const recipient = session.topic?.replace('Private appointment with ', '');
    const date = format(
      parse(session.startDate, 'MM-dd-yyyy', new Date()),
      'dd MMM yyy'
    );

    const confirmationContent = getInviteConfirmationContent(
      recipient,
      date,
      session.startTime,
      sessionUrl?.guest
    );

    const getClientEmail = () => {
      const guests = session.participants?.filter(
        (p) => p.role?.toLowerCase() === 'guest'
      );

      if (guests?.length === 1) {
        return first(guests).email;
      }
      return '';
    };

    return (
      <DropdownButton variant="outline-dark" title="Confirmation">
        <Dropdown.Item
          onClick={(event) => {
            event.stopPropagation();
            logEmailTemplateDownload();
            window.open(
              `/api/getEmailTemplate?templateId=loubiairwaysjpconfirmation&recipient=${
                recipient || ''
              }&landingUrl=${sessionUrl?.guest}&date=${date}&time=${
                session.startTime
              }&email=${getClientEmail()}`
            );
          }}
        >
          <AiOutlineMail color="#343740" />
          Confirm via Email
        </Dropdown.Item>
        <Dropdown.Item>
          {isMobile ? (
            <button
              className="share-btn btnShare"
              onClick={() => {
                performNativeShare(
                  {
                    title: 'LoubiAirways Japan Trunk Show 2021',
                    text: confirmationContent,
                    url: sessionUrl?.guest
                  },
                  onSuccessfulNativeShare,
                  onFailedNativeShare
                );
              }}
            >
              <LineIcon
                bgStyle={{ fill: '#343740' }}
                round
                crossOrigin="anonymous"
              />
              <span className="btnShareSpan">Confirm via LINE</span>
            </button>
          ) : (
            <LineShareButton
              url={sessionUrl?.guest}
              title={confirmationContent}
              className="btnShare"
              onClick={() => {
                logEvent(
                  DID_CLICK_SESSION_CONFIRM_BUTTON,
                  DID_CLICK_SESSION_CONFIRM_BUTTON,
                  {
                    type: 'LINE',
                    sessionId: session.id
                  }
                );
              }}
            >
              <LineIcon
                bgStyle={{ fill: '#343740' }}
                round
                crossOrigin="anonymous"
              />
              <span className="btnShareSpan">Confirm via LINE</span>
            </LineShareButton>
          )}
        </Dropdown.Item>
      </DropdownButton>
    );
  }
  return null;
};

export const getChatStoryShareComponent = (
  brandId: string,
  onClick?: () => void
) => {
  if (brandId !== louboutinBrandId) {
    return <StoryShareButton onClick={onClick} />;
  }
  return null;
};

export const getSessionUploadFileStatusIcon = (session: ISessionDetails) => {
  const fileStatus = getSessionFileStatus(session);
  if (fileStatus === SessionFileStatus.READY)
    return <MdAttachFile color="#666" />;
  if (fileStatus === SessionFileStatus.ERROR) return <MdWarning color="red" />;
  if (fileStatus === SessionFileStatus.PREPARE)
    return <Spinner animation="border" size="sm" />;
  return null;
};
