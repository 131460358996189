import React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { ISessionConvertedFile, ISessionFile } from '../../../interfaces';
import {
  actionHubSessionUpdateFiles,
  actionHubSessionUpdateFileUploadProgress
} from '../../../redux/actions';
import {
  DID_DELETE_FILE,
  DID_FAIL_TO_UPLOAD_FILE,
  DID_UPLOAD_FILE
} from '../../../utils/constants';
import { uploadToS3 } from '../../clientSideServices/session';
import FileUpload from '../Common/FileUploader/FileUpload';
import SessionFileList from './SessionFileList';

const bucket = 'storiez-data';
const filePath = 'content/uploaded';

const SessionFileUpload = ({
  sessionID,
  uploadedFiles,
  isUploadingFile,
  onView
}: {
  sessionID: string;
  uploadedFiles: ISessionFile[];
  isUploadingFile: boolean;
  onView: (file: ISessionConvertedFile) => void;
}) => {
  const [filesInUpload, setFilesInUpload] = React.useState<string[]>([]);
  const [showFileUpload, setFileUpload] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const updateFileUploadProgress = (
    name: string,
    progress: number,
    error?: Error
  ) => {
    dispatch(actionHubSessionUpdateFileUploadProgress(name, progress, error));
  };

  const updateFormData = (files: ISessionFile[]) => {
    const reArranged = files.map((file, index) =>
      Object.assign({}, file, {
        order: index + 1
      })
    );

    dispatch(actionHubSessionUpdateFiles(reArranged));
  };

  const uploadFilesTos3 = async (file: File, fileName: string) => {
    const key = `${filePath}/${sessionID}/${fileName}`;
    setFilesInUpload([...filesInUpload, fileName]);
    uploadToS3(file, bucket, key, (progressPercentage) => {
      updateFileUploadProgress(fileName, progressPercentage);
    })
      .then((data) => {
        const s3File = {
          order: uploadedFiles.length + 1,
          title: fileName,
          bucket: data.Bucket,
          key: data.Key
        };
        logEvent(DID_UPLOAD_FILE, DID_UPLOAD_FILE, {
          sessionId: sessionID
        });
        updateFormData([...uploadedFiles, s3File]);
        setFilesInUpload(filesInUpload.filter((name) => fileName !== name));
      })
      .catch((error) => {
        console.log('Uploading file to S3 failed with error', error);
        logEvent(DID_FAIL_TO_UPLOAD_FILE, DID_FAIL_TO_UPLOAD_FILE, {
          sessionId: sessionID
        });
        updateFileUploadProgress(fileName, 0, error);
      });
  };

  const onRemove = (index: number) => {
    logEvent(DID_DELETE_FILE, DID_DELETE_FILE, {
      sessionId: sessionID
    });

    const updatedFiles = [
      ...uploadedFiles.slice(0, index),
      ...uploadedFiles.slice(index + 1)
    ];

    setFilesInUpload(
      filesInUpload.filter((file) => file !== uploadedFiles[index]?.title)
    );

    updateFormData(updatedFiles);
  };

  const allFileNames = [
    ...uploadedFiles.map((file) => file.title),
    ...filesInUpload
  ];

  return (
    <>
      <label className="input-label file-upload-label">
        Files
        <div
          className={`upload-file-wrapper ${isUploadingFile && `disabled`}`}
          onClick={() => {
            setFileUpload(true);
          }}
        >
          <AiOutlineCloudUpload />
          Upload file
        </div>
      </label>
      <div className="uploaded-file-placeholder">
        <SessionFileList
          files={allFileNames}
          onRemove={onRemove}
          updateFormData={updateFormData}
          onView={onView}
        />
      </div>
      {showFileUpload && (
        <FileUpload
          uploadedFiles={allFileNames}
          onClose={() => setFileUpload(false)}
          onUpload={(files) => uploadFilesTos3(files[0].file, files[0].name)}
          rejectDuplicates={true}
          multipleUpload={false}
        />
      )}

      <style jsx>
        {`
          .file-upload-label {
            position: relative;
            width: 100%;
          }
          .upload-file-wrapper {
            position: absolute;
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            top: 0;
            right: 0;
            letter-spacing: 2px;
          }

          .disabled {
            pointer-events: none;
            opacity: 0.5;
          }

          :global(.upload-file-wrapper > svg) {
            margin-right: 5px;
          }

          .uploaded-file-placeholder {
            width: 100%;
            min-height: 50px;
            border: 1px dotted;
            border-radius: 0.25rem;
            padding: 10px;
          }
        `}
      </style>
    </>
  );
};

export default SessionFileUpload;
