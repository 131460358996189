import React from 'react';
import { IconStorybook } from '../Common/HubIcons';
import SessionStorybookList from './SessionStorybookList';

const SessionStorybook = ({
  storybooks,
  onOpenStorybook,
  updateFormData,
  onView
}: {
  storybooks: string[];
  onOpenStorybook: () => void;
  updateFormData: (storybooks: string[]) => void;
  onView: (id: string) => void;
}) => {

  return (
    <>
      <label className="input-label file-upload-label">
        Storybook
        <div className={`upload-file-wrapper`} onClick={onOpenStorybook}>
          <IconStorybook color="#333" />
          Add Storybook
        </div>
      </label>
      <div className="uploaded-file-placeholder">
        <SessionStorybookList
          storybooks={storybooks}
          updateFormData={updateFormData}
          onView={onView}
        />
      </div>

      <style jsx>
        {`
          .file-upload-label {
            position: relative;
            width: 100%;
          }
          .upload-file-wrapper {
            position: absolute;
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            top: 0;
            right: 0;
            letter-spacing: 2px;
          }

          .disabled {
            pointer-events: none;
            opacity: 0.5;
          }

          .upload-file-wrapper :global(svg) {
            margin-right: 5px;
            width: 13px;
            height: auto;
          }

          .uploaded-file-placeholder {
            width: 100%;
            min-height: 50px;
            border: 1px dotted;
            border-radius: 0.25rem;
            padding: 10px;
          }
        `}
      </style>
    </>
  );
};

export default SessionStorybook;
