import { Auth } from 'aws-amplify';
import React from 'react';
import { useSelector } from 'react-redux';
import { IMainState } from '../../../interfaces';
import { getThemeColor, mobileBreakPoint } from '../BrandStyle';

function VerifyForm({ brandId }: { brandId: string }) {
  const color = getThemeColor(brandId);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const hub = useSelector((state: IMainState) => state.clientState.hub) || {};
  const { loginErrorMessage } = hub;
  const codeRef = React.useRef<HTMLInputElement>();
  React.useEffect(() => {
    const username = loginErrorMessage;
    if (username) {
      Auth.resendSignUp(username)
        .then((res) => setEmail(res.CodeDeliveryDetails?.Destination))
        .catch((err) => console.log(err));
    }
  }, [loginErrorMessage]);
  const handleVerify = () => {
    Auth.confirmSignUp(loginErrorMessage, codeRef.current.value)
      .then((res) => console.log(res))
      .catch((err: any) => setError(err.message));
  };
  const handleResendCode = () => {
    const username = loginErrorMessage;
    if (username) {
      Auth.resendSignUp(username)
        .then((res) => {
          console.log(res);
          setEmail(res.CodeDeliveryDetails?.Destination);
        })
        .catch((err: any) => setError(err.message));
    }
  };
  return (
    <>
      <div className="page-body VefiryForm">
        <div className="container">
          <h1>
            VERIFY YOUR ACCOUNT
            <i className="separator" />
          </h1>
          <p className="accent">
            Please check your email {email ? `(${email}) ` : ''}for the code
          </p>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="form-container">
            <div className={`form-group text-group`}>
              <label htmlFor="code">CODE</label>
              <input
                type="text"
                ref={codeRef}
                className="form-control"
                id="code"
                placeholder="Enter code"
              />
            </div>
            <div className="form-group">
              <button className="btn btn-dark" onClick={handleVerify}>
                VERIFY
              </button>
              <button className="btn btn-dark" onClick={handleResendCode}>
                RE-SEND CODE
              </button>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        h1 {
          color: #fff;
          text-align: left !important;
        }
        h1 .separator {
          margin: 10px 0 !important;
        }
        p {
          color: #fff;
        }
        .content {
          padding: 0 30px;
        }

        .accent {
          text-align: left;
        }
        .container {
          max-width: 500px;
        }

        .form-container {
          margin: auto;
        }
        :global(.ssp) .form-container {
          max-width: 700px;
        }
        .text-group {
          text-align: left;
          border: 1px solid #dedede;
          background: #fff;
          padding: 0 10px 5px;
          margin-bottom: 20px;
        }
        .btn {
          border-radius: 0;
          width: 100%;
          padding: 13px 0;
        }
        :global(.ssp) .btn {
          font-size: 1.4em;
        }
        .text-group label {
          padding: 0;
          margin: 0;
          font-size: 0.5em;
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: 1px;
          color: ${color.accent};
        }
        :global(.ssp) .text-group label {
          font-size: 0.8em;
        }
        .text-group input {
          box-shadow: none;
          outline: none;
          border: none;
          padding: 0;
          margin: 0;
          height: auto;
        }

        .input-error {
          color: red;
        }
        :global(.kbActive) h1,
        :global(.kbActive) p {
          display: none;
        }
        @media (min-width: ${mobileBreakPoint}px) {
          h1 {
            color: #000;
          }
          p {
            color: ${color.accent};
            margin-bottom: 30px;
          }
        }
      `}</style>
    </>
  );
}

export default VerifyForm;
