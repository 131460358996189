import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsX } from 'react-icons/bs';
import {
  sortConversationChronologically,
  sortConversationRequestChronologically
} from '../../../utils/chat';
import ChatContact from './ChatContact';
import { HubChatContext } from './HubChatContext';
import IncomingChatContact from './IncomingChatContact';

const ChatList = ({ keywords }: { keywords: string }) => {
  const {
    conversations,
    conversationRequests,
    isMobile,
    setSearchText,
    searchText
  } = React.useContext(HubChatContext);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchText(value);
  };

  return (
    <>
      <div className="search-wrapper">
        <div className="input-group search-box">
          <AiOutlineSearch color="#495057" className="search-icon" />
          <input
            type="text"
            className="form-control search-input"
            placeholder="Search"
            value={searchText}
            onChange={onChangeInput}
          />
          {searchText && (
            <BsX onClick={() => setSearchText('')} className="clear" />
          )}
        </div>
      </div>
      <div className="list-wrapper">
        {sortConversationRequestChronologically(conversationRequests)?.map(
          (request, index) => (
            <IncomingChatContact
              key={index}
              contact={request}
              index={index}
              keywords={keywords}
            />
          )
        )}
        {sortConversationChronologically(conversations)?.map((chat, index) => (
          <ChatContact
            key={index}
            index={index}
            contact={chat}
            isMobile={isMobile}
            keywords={keywords}
          />
        ))}
      </div>
      <style jsx>
        {`
          .list-wrapper {
            overflow: auto;
            flex-grow: 1;
            border-top: 1px solid rgb(196, 196, 196);
          }
          .search-wrapper {
            padding: 10px 8px;
          }
          .search-box {
            position: relative;
          }
          .search-box :global(.search-icon) {
            position: absolute;
            z-index: 5;
            top: 12px;
            left: 10px;
          }
          .search-input {
            border-radius: 20px !important;
            padding-left: 30px;
            padding-right: 30px;
          }
          .search-box :global(.clear) {
            position: absolute;
            z-index: 5;
            top: 12px;
            right: 10px;
          }
          @media (max-width: 480px) {
            .list-wrapper {
              border-top: 1px solid rgb(196, 196, 196);
              padding-bottom: 20px;
            }

            .search-wrapper {
              display: none;
            }
          }

          @media (orientation: landscape) and (max-width: 900px) {
            .search-wrapper {
              display: none;
            }

            .list-wrapper {
              padding-bottom: 24px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ChatList;
