import { uniqBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { getUserByIdentityId } from '../../../clientSideServices/users';
import {
  ChatConnectionContext,
  ChatMessageType,
  ChatUserType,
  IChatMessage,
  WSAcknowledgementStatusType,
  WSChatUserAvailability,
  WSMessageType
} from '../../../components/ChatConnectionContext';
import { useStateRef } from '../../../components/hooks/common';
import {
  BrowsingHistoryEventType,
  IBrowsingHistory,
  IMainState,
  IUser
} from '../../../interfaces';
import {
  decryptMessage,
  mapConversationRequests,
  sortConversationChronologically
} from '../../../utils/chat';
import {
  DID_FAIL_TO_FETCH_BROWSING_HISTORY,
  DID_FETCH_BROWSING_HISTORY_SUCCESSFULLY,
  DID_REQUEST_BROWSING_HISTORY
} from '../../../utils/constants';
import { isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { generateV4UUID } from '../../../utils/identityGenerator';
import {
  browsingHistoryByIdentityId,
  pushEventsToCurrentHistory
} from '../../clientSideServices/analytics/browsingHistory';
import { StartKey } from '../../clientSideServices/nudge';
import { getListUsers } from '../../clientSideServices/user';

export interface IChatUsers {
  [id: string]: IUser;
}

export enum IncommingMessageType {
  SINGLE = 'SINGLE',
  MANY = 'MANY'
}

export const HubChatContext = React.createContext<{
  conversations: any;
  messages: any[];
  isOnline: boolean;
  conversationRequests: any[];
  currentConversation: string;
  setCurrentConversation: (id: string) => void;
  onSendHubMessage: (payload: IChatMessage) => void;
  chatUsers: IChatUsers;
  addChatUsers: (userId: string, data: any) => void;
  incomingChatUsers: IChatUsers;
  addIncomingChatUsers: (userId: string, data: any) => void;
  isFetchingConversations: boolean;
  setIsFetchingConversations: (c: boolean) => void;
  isFetchingMessages: boolean;
  setIsFetchingMessages: (c: boolean) => void;
  isMobile: boolean;
  messageStartKey: StartKey;
  setMessageStartkey: (key: StartKey) => void;
  incommingMessageType: IncommingMessageType;
  searchText: string;
  setSearchText: (keyword: string) => void;
  fetchHistories: (startRow?: number) => void;
  isFetchingHistory: boolean;
  startHistoryRow: number;
  totalHistoryRows: number;
  browsingHistories: IBrowsingHistory[];
  listSA: IUser[];
  transferConversationTo: (to: string) => void;
  listAvailableSA: any[];
  fetchAvailableSaleAdvisors: () => void;
}>(undefined);

const HubChatContextContainer = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    connected,
    connect,
    disconnect,
    addMessageListener,
    removeMessageListener,
    addOnConnectAction,
    removeOnConnectAction,
    fetchConversations,
    onSendMessage,
    checkChatUserAvailability,
    joinChatSession,
    transferConversation,
    fetchAvailableAdvisors,
    fetchUnsubscribeConversations
  } = React.useContext(ChatConnectionContext);
  const isMobile = isUserOnMobileOnly();
  const [chatUsers, setChatUsers, chatUsersRef] = useStateRef(null);
  const [incomingChatUsers, setIncomingChatUsers, incomingChatUsersRef] =
    useStateRef(null);
  const [conversations, setConversations, conversationsRef] = useStateRef([]);
  const [isOnline, setIsOnline] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState('');

  // eslint-disable-next-line
  const [messagePayload, setMessagePayload, messagePayloadRef] =
    useStateRef(null);

  const [incommingMessageType, setIncomingMessageType] =
    React.useState<IncommingMessageType>(null);
  const [
    conversationRequests,
    setConversationRequests,
    conversationsRequestsRef
  ] = useStateRef([]);
  const [messages, setMessages, messagesRef] = useStateRef({});
  const [messageStartKey, setMessageStartkey, messageStartKeyRef] =
    useStateRef(null);
  const [currentConversation, setCurrentConversation, currentConversationRef] =
    useStateRef(null);
  const [isFetchingConversations, setIsFetchingConversations] =
    React.useState<boolean>(false);
  const [isFetchingMessages, setIsFetchingMessages] =
    React.useState<boolean>(false);
  const details = conversations.find(
    (conversation) => conversation?.conversationId === currentConversation
  );

  const [isFetchingHistory, setIsFetchingHistory] =
    React.useState<boolean>(false);
  const [startHistoryRow, setStartHistoryRow] = React.useState<number>(0);
  const [totalHistoryRows, setTotalHistoryRows] = React.useState<number>(0);
  const [browsingHistories, setBrowsingHistories, browsingHistoriesRef] =
    useStateRef([]);
  const hubUser = useSelector(
    (state: IMainState) => state.clientState?.hub?.user
  );
  const [listSA, setListSA] = React.useState<IUser[]>([]);
  const [listAvailableSA, setListAvailableSA] = React.useState<any[]>([]);
  const addChatUsers = (userId: string, data: any) => {
    setChatUsers((prevUsers) => {
      return { ...prevUsers, [userId]: data };
    });
  };

  const addIncomingChatUsers = (userId: string, data: any) => {
    setIncomingChatUsers((prevUsers) => {
      return { ...prevUsers, [userId]: data };
    });
  };

  const updateUserDetails = (userId: string) => {
    const isAcceptedUser = chatUsersRef.current?.[userId];
    getUserByIdentityId(userId)
      .then(({ data }) => {
        if (isAcceptedUser) {
          addChatUsers(userId, {
            ...data
          });
          return;
        }
        addIncomingChatUsers(userId, {
          ...data
        });
      })
      .catch((error) => {
        console.log('Error is', error);
      });
  };

  const updateUserConnectedStatus = (userId: string, isOnline = true) => {
    const isAcceptedUser = chatUsersRef.current?.[userId];
    if (isAcceptedUser) {
      addChatUsers(userId, {
        ...chatUsersRef?.current?.[userId],
        isOnline: isOnline
      });
      return;
    }
    addIncomingChatUsers(userId, {
      ...incomingChatUsersRef?.current?.[userId],
      isOnline: isOnline
    });
  };

  const onSendHubMessage = (payload: IChatMessage) => {
    onSendMessage(payload);
    setMessagePayload(payload);
  };

  const updateStatesOnMessage = (conversationId: string, message: any) => {
    setIncomingMessageType(IncommingMessageType.SINGLE);
    const earlierMessages = messagesRef.current?.[conversationId] || [];
    const updatedMessages = [...earlierMessages, message];
    setMessages({
      ...messagesRef.current,
      [conversationId]: updatedMessages
    });
    const conversationIndex = conversationsRef.current.findIndex(
      (con) => con?.conversationId === conversationId
    );

    if (conversationIndex > -1) {
      const newConversationItem = {
        ...conversationsRef.current?.[conversationIndex],
        lastMessage: {
          ...conversationsRef.current?.[conversationIndex]?.lastMessage,
          messageBody: message?.messageBody
        }
      };
      const updatedConversations = [
        ...conversationsRef?.current.slice(0, conversationIndex),
        newConversationItem,
        ...conversationsRef?.current.slice(conversationIndex + 1)
      ];
      updateConversationList(updatedConversations);
    }
  };

  const receiveAck = (data) => {
    if (data.status === WSAcknowledgementStatusType.MESSAGE_SENT) {
      const formattedMessage = {
        ...messagePayloadRef.current,
        userId: undefined,
        senderId: hubUser?.id
      };
      handleConversationMessage(formattedMessage, false);
      setMessagePayload(null);
      return;
    }

    if (data.status === WSAcknowledgementStatusType.USER_DISCONNECTED) {
      const senderId = data?.senderId;

      updateUserConnectedStatus(senderId, false);
    }

    if (data?.availability) {
      const isOnline = data?.availability === WSChatUserAvailability.AVAILABLE;

      if (
        (data?.messageType === WSMessageType.BROADCAST &&
          data?.userId === hubUser?.id) ||
        data?.messageType === WSMessageType.QUERY_RESULT
      ) {
        setIsOnline(isOnline);
      }
    }
    //List available advisors
    const availableAdvisors = data?.availableAdvisors;
    if (availableAdvisors) {
      setListAvailableSA(availableAdvisors);
    }

    //list unsubscribe conversation
    const conversations = data?.conversations;
    if (conversations?.length) {
      setConversationRequests(mapConversationRequests(conversations));
    }
    onSubscribed(data);
  };

  const onSubscribed = (data, me = true) => {
    if (data.status === WSAcknowledgementStatusType.SUBSCRIBED) {
      setConversationRequests(
        conversationsRequestsRef.current?.filter?.(
          (req) => req?.conversationId !== data?.conversationId
        )
      );
      me && fetchConversations(hubUser?.id);
    }
  };

  const handleConversationMessage = (
    conversation,
    requestConversation = true
  ) => {
    const conversationExists = conversationsRef.current.some(
      (c) => c?.conversationId === conversation?.conversationId
    );

    if (conversationExists) {
      updateStatesOnMessage(conversation?.conversationId, conversation);
      return;
    }
    if (requestConversation) {
      const conversationRequestIndex =
        conversationsRequestsRef.current.findIndex(
          (con) => con?.conversationId === conversation?.conversationId
        );

      if (conversationRequestIndex > -1) {
        const updatedConversationRequests = [
          ...conversationsRequestsRef?.current.slice(
            0,
            conversationRequestIndex
          ),
          conversation,
          ...conversationsRequestsRef?.current.slice(
            conversationRequestIndex + 1
          )
        ];
        setConversationRequests(updatedConversationRequests);
        return;
      }
      setConversationRequests([
        conversation,
        ...conversationsRequestsRef.current
      ]);
    }
  };

  /**
   * Sort and updates the conversations list
   * and optionally updates the currentConversation
   * */
  const updateConversationList = (
    conversations: any[],
    updateCurrentConversation?: boolean
  ) => {
    const sortedConversations = sortConversationChronologically(
      uniqBy(conversations, 'conversationId')
    );
    setConversations(sortedConversations);
    if (!isMobile && updateCurrentConversation) {
      setCurrentConversation(sortedConversations[0]?.conversationId);
    }
    setIsFetchingConversations(false);
  };

  const onMessageListener = (event) => {
    const parsedData = JSON.parse(event.data);

    if (
      parsedData?.messageType === WSMessageType.ACK ||
      parsedData?.messageType === WSMessageType.BROADCAST ||
      parsedData?.messageType === WSMessageType.QUERY_RESULT
    ) {
      receiveAck(parsedData);
      return;
    }

    if (parsedData?.messageType === WSMessageType.SUBSCRIPTION_EVENT) {
      onSubscribed(parsedData, false);
      fetchConversations(hubUser?.id);
    }

    // Incomming conversation message from other users
    if (
      parsedData?.conversationId &&
      parsedData?.messageBody &&
      parsedData?.senderId !== hubUser?.id
    ) {
      const messageBody = decryptMessage(parsedData?.messageBody);

      // When user is updated by client
      if (messageBody?.type === ChatMessageType.USER_UPDATED) {
        updateUserDetails(messageBody?.content);
        return;
      }

      // When customer is online
      if (messageBody?.type === ChatMessageType.USER_ONLINE) {
        updateUserConnectedStatus(messageBody?.content);
        return;
      }
      handleConversationMessage(parsedData);
      return;
      //
    }

    // List of messages
    if (parsedData?.messages) {
      updateStatesOnMessageList(parsedData);
      return;
    }

    // List of conversations
    if (parsedData?.conversations) {
      updateConversationList(parsedData?.conversations, true);
      return;
    }
    if (
      [
        BrowsingHistoryEventType.DID_VIEW_PAGE,
        BrowsingHistoryEventType.EYEBALL_TIME
      ].includes(parsedData?.[0]?.type)
    ) {
      const currentBrowsing =
        browsingHistoriesRef?.current as IBrowsingHistory[];
      const userId = conversationsRef?.current?.find(
        (conversation) =>
          conversation?.conversationId === currentConversationRef?.current
      )?.conversationCreatedBy;
      if (userId === parsedData?.[0]?.identityId)
        setBrowsingHistories(
          pushEventsToCurrentHistory(currentBrowsing, parsedData)
        );
    }
  };

  const updateStatesOnMessageList = (parsedData) => {
    setIncomingMessageType(IncommingMessageType.MANY);
    const messagesMap = messagesRef.current;
    const conversationId = currentConversationRef.current;
    const currentStartKey = messageStartKeyRef.current;
    const prevMessages = currentStartKey
      ? messagesMap?.[conversationId] || []
      : [];
    const messagesForConversationId = [
      ...parsedData?.messages?.reverse(),
      ...prevMessages
    ];
    setMessages({
      ...messagesMap,
      [conversationId]: messagesForConversationId
    });
    setMessageStartkey(parsedData?.resultPage?.lastEvaluatedKey);
    setIsFetchingMessages(false);
  };

  const fetchConversationsForAgent = () => {
    if (!isFetchingConversations) {
      setIsFetchingConversations(true);
      fetchConversations(hubUser?.id);
    }
  };

  const joinAgentChatSession = () => {
    joinChatSession(
      hubUser?.id,
      hubUser?.associatedStoreId || hubUser?.storeId,
      ChatUserType.AGENT
    );
  };

  const checkAgentAvailability = () => {
    checkChatUserAvailability(
      hubUser?.id,
      hubUser?.associatedStoreId || hubUser?.storeId
    );
  };

  const transferConversationTo = (to: string) => {
    transferConversation(hubUser?.id, to, currentConversation);
    setConversations((conversations) =>
      conversations.filter((con) => con.conversationId !== currentConversation)
    );
  };

  React.useEffect(() => {
    const messageListenerId = generateV4UUID();
    addMessageListener({
      id: messageListenerId,
      listener: onMessageListener
    });
    addOnConnectAction({
      id: messageListenerId,
      action: joinAgentChatSession
    });
    connect();
    return () => {
      removeMessageListener(messageListenerId);
      removeOnConnectAction(messageListenerId);
      disconnect();
    };
  }, []);

  React.useEffect(() => {
    if (isOnline) {
      fetchConversationsForAgent();
    }
  }, [isOnline]);

  React.useEffect(() => {
    if (connected) {
      checkAgentAvailability();
      fetchConversationsForAgent();
      fetchUnsubscribeConversations(hubUser?.storeId);
      getListUsers(hubUser?.storeId)
        .then((_users) => {
          setListSA(_users?.filter((user) => user.id !== hubUser?.id));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [connected]);

  const fetchHistories = React.useCallback(
    (startRow = 0) => {
      const userId = details?.conversationCreatedBy;
      logEvent(DID_REQUEST_BROWSING_HISTORY, DID_REQUEST_BROWSING_HISTORY, {
        userId
      });
      setIsFetchingHistory(true);
      browsingHistoryByIdentityId(userId, startRow)
        .then((result) => {
          logEvent(
            DID_FETCH_BROWSING_HISTORY_SUCCESSFULLY,
            DID_FETCH_BROWSING_HISTORY_SUCCESSFULLY,
            {
              userId
            }
          );
          const nextStartRow =
            startRow + result?.browsingHistory.length > result?.totalRows
              ? null
              : startRow + result?.browsingHistory.length;
          setBrowsingHistories((browsingHistories) => {
            if (startRow === 0) return result?.browsingHistory;
            return [...browsingHistories, ...result?.browsingHistory];
          });
          setTotalHistoryRows(result?.totalRows);
          setIsFetchingHistory(false);
          setStartHistoryRow(nextStartRow);
        })
        .catch((error) => {
          logEvent(
            DID_FAIL_TO_FETCH_BROWSING_HISTORY,
            DID_FAIL_TO_FETCH_BROWSING_HISTORY,
            {
              userId,
              error
            }
          );
          setIsFetchingHistory(false);
        });
    },
    [details?.conversationCreatedBy]
  );

  const fetchAvailableSaleAdvisors = () => {
    fetchAvailableAdvisors(hubUser?.storeId);
  };

  React.useEffect(() => {
    if (details?.conversationCreatedBy) {
      setBrowsingHistories([]);
      fetchHistories();
    }
  }, [details?.conversationCreatedBy]);

  return (
    <HubChatContext.Provider
      value={{
        conversations,
        messages,
        isOnline,
        conversationRequests,
        currentConversation,
        setCurrentConversation,
        onSendHubMessage,
        chatUsers,
        addChatUsers,
        isFetchingConversations,
        setIsFetchingConversations,
        isFetchingMessages,
        setIsFetchingMessages,
        incomingChatUsers,
        addIncomingChatUsers,
        isMobile,
        messageStartKey,
        setMessageStartkey,
        incommingMessageType,
        searchText,
        setSearchText,
        fetchHistories,
        isFetchingHistory,
        startHistoryRow,
        totalHistoryRows,
        browsingHistories,
        listSA,
        transferConversationTo,
        listAvailableSA,
        fetchAvailableSaleAdvisors
      }}
    >
      {children}
    </HubChatContext.Provider>
  );
};

export default HubChatContextContainer;
