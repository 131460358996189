import React, { useEffect, useState } from 'react';
import { formattedNumber } from '../../../../utils/number';
import { IStore } from '../../../../interfaces';
import { getStoreIdFromUrl } from '../../../../analytics';
import { getStore } from './service';

function DeviceAnalyticsRough({
  device,
  selectedItem,
  setSelectedItem,
  listItemTab,
  setListItemTab,
  deviceInsightsOverview,
  isInsightsSpace
}: {
  device;
  selectedItem: any;
  setSelectedItem: any;
  listItemTab: any;
  setListItemTab: any;
  deviceInsightsOverview: any;
  isInsightsSpace?: boolean;
}) {
  const [roughState, setRoughState] = useState({
    productViewed: 0,
    productScanned: 0,
    productShared: 0,
    storyViewed: 0,
    storyScanned: 0,
    storyShared: 0
  });
  const [store, setStore] = useState<IStore | null>(null);

  useEffect(() => {
    if (!deviceInsightsOverview) return;

    setRoughState({
      ...roughState,
      productViewed: deviceInsightsOverview.productViewed.currentPhase,
      productScanned: deviceInsightsOverview.productScanned.currentPhase,
      productShared: deviceInsightsOverview.productShared.currentPhase,
      storyViewed: deviceInsightsOverview.storyViewed.currentPhase,
      storyScanned: deviceInsightsOverview.storyScanned.currentPhase,
      storyShared: deviceInsightsOverview.storyShared.currentPhase
    });
  }, [deviceInsightsOverview]);

  useEffect(() => {
    if (!isInsightsSpace) return;
    const store = window['store'] as IStore | undefined;
    const _storeId = getStoreIdFromUrl() || store?.id || window['storeId'];
    const getStorePromise = getStore(_storeId);
    Promise.all([getStorePromise]).then((result) => {
      if (!result[0]) return;
      setStore(result[0]);
    });
  }, [isInsightsSpace]);

  return (
    <>
      <div className="analytics-rough">
        {listItemTab.length > 0 ? (
          <>
            <div
              className="back-icon"
              onClick={() => {
                setListItemTab([]);
                setSelectedItem(null);
              }}
            >
              <svg
                width="12"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 2L3 9.5L10 17" stroke="#7F7F7F" strokeWidth="3" />
              </svg>
              <span>Back</span>
            </div>
            <div className="item-list__container">
              {listItemTab.map((l) => (
                <div
                  className={
                    'item-container ' +
                    (selectedItem.id === l.id ? 'selected-image' : '')
                  }
                  onClick={() =>
                    setSelectedItem({ ...l, type: selectedItem.type })
                  }
                  key={l.id}
                >
                  <div className="img-container">
                    <img
                      src={l.product?.url || l.story?.url}
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <b className="title">{l.product?.name || l.story?.name}</b>
                  {l.product?.code && (
                    <b className="subtitle">{l.product.code}</b>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="analytics-rough__main">
            <div className="analytics-rough__basic">
              {device && device.name && !isInsightsSpace && (
                <div className="group-basic-info">
                  <p>Name</p>
                  <div className="basic-info title">{device.name}</div>
                </div>
              )}
              {device && device.description && !isInsightsSpace && (
                <div className="group-basic-info">
                  <p>Description</p>
                  <div className="basic-info title">{device.description}</div>
                </div>
              )}
              {isInsightsSpace && store?.name && (
                <div className="group-basic-info">
                  <p>{store.name}</p>
                </div>
              )}
            </div>
            <div className="cards">
              <div className="card-basic">
                <h3>Product(s) Viewed</h3>
                <div>{formattedNumber(roughState.productViewed || 0)}</div>
              </div>
              <div className="card-basic">
                <h3>Product(s) Scanned</h3>
                <div>{formattedNumber(roughState.productScanned || 0)}</div>
              </div>
              <div className="card-basic">
                <h3>Product(s) Shared</h3>
                <div>{formattedNumber(roughState.productShared || 0)}</div>
              </div>
              <div className="card-basic">
                <h3>Story Viewed</h3>
                <div>{formattedNumber(roughState.storyViewed || 0)}</div>
              </div>
              <div className="card-basic">
                <h3>Story Scanned</h3>
                <div>{formattedNumber(roughState.storyScanned || 0)}</div>
              </div>
              <div className="card-basic">
                <h3>Story Shared</h3>
                <div>{formattedNumber(roughState.storyShared || 0)}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .back-icon {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px 10px 0 10px;
          font-size: 22px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.5);
          cursor: pointer;
        }
        .back-icon span {
          font-size: 18px;
          font-weight: 500;
        }
        .analytics-rough__main {
          padding: 20px;
          overflow: auto;
          max-height: calc(100vh - 150px);
        }
        .item-list__container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 15px;
          max-height: calc(100vh - 185px);
          overflow-x: scroll;
        }
        .item-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          cursor: pointer;
          border: 2px solid transparent;

          border-radius: 5px;
          padding: 10px;
        }
        .img-container {
          width: 100%;
          padding: 5px;
          text-align: center;
        }
        .item-container .subtitle {
          color: #484848;
          text-align: left;
          font-size: 12px;
          font-weight: 400;
        }
        .item-container .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 220px;
        }
        .img-container img {
          width: 120px;
          height: auto;
        }

        .selected-image {
          border: 2px solid #8f6599;
        }

        .basic-info {
          color: rgba(0, 0, 0, 0.5);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 10px 0;
        }
        .analytics-rough__basic {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .group-basic-info {
          display: flex;
          flex-direction: column;
        }
        .group-basic-info p {
          margin: 0;
          color: #000;
          font-weight: 500;
          font-size: 16px;
        }

        .title {
          font-weight: 500;
          margin: 0;
          font-size: 14px;
        }
        .cards {
          margin-top: 30px;
        }
        .card-basic {
          border-radius: 7.043px;
          background: #fff;
          box-shadow: 3.521px 3.521px 7.043px 0px rgba(0, 0, 0, 0.2);
          padding: 15px 20px;
          margin: 20px 0;
        }

        .card-basic h3 {
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .card-basic > div {
          color: #252525;
          text-align: right;
          font-size: 25.354px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      `}</style>
    </>
  );
}

export default DeviceAnalyticsRough;
