import React from 'react';
import { ITeam } from '../../../interfaces';
import CreateTeam from './CreateTeam';
import HubTeams from './HubTeams';

export enum TeamPageType {
  TEAMS = 'TEAMS',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DETAIL = 'DETAIL'
}

const TeamPage = () => {
  const [teamPage, setTeamPage] = React.useState<TeamPageType>();
  const [team, setTeam] = React.useState<ITeam>();

  React.useEffect(() => {
    setTeamPage(TeamPageType.TEAMS);
  }, []);

  const getTeamPage = () => {
    switch (teamPage) {
      case TeamPageType.TEAMS:
        return <HubTeams setTeamPage={setTeamPage} setTeam={setTeam} />;
      case TeamPageType.CREATE:
      case TeamPageType.EDIT:
      case TeamPageType.DETAIL:
        return (
          <CreateTeam
            team={team}
            teamPage={teamPage}
            setTeam={setTeam}
            setTeamPage={setTeamPage}
          />
        );
    }
  };
  return <>{getTeamPage()}</>;
};

export default TeamPage;
