import React from 'react';
import { ILibraryScene, LibrarySceneType } from '../../../interfaces';
import Layout from '../Layout';
import SLOverview from './Overview/SLOverview';
import { HubContext } from '../HubContext';
import FileUploadProgressPanel from '../Common/FileUploader/FileUploadProgressPanel';
import SLDesignerBasic from './Builder/SLDesignerBasic';
import SLDesigner from './Builder/SLDesigner';
import { getSceneContentType } from '../../utils/library';
import SceneInsight from './Insight';
export enum SLPage {
  OVERVIEW = 'SCENE_LIBRARY.OVERVIEW',
  BUILDER = 'SCENE_LIBRARY.BUILDER',
  INSIGHT = 'SCENE_LIBRARY.INSIGHT'
}

export interface IConfirmationPopup {
  children: React.ReactNode;
  okLabel?: string;
  cancelLabel?: string;
  onOk?: () => void;
}

const SceneLibrary = ({
  userId,
  context = 'scene'
}: {
  userId: string;
  context?: LibrarySceneType;
}) => {
  const { setSubPage } = React.useContext(HubContext);

  const [page, setPage] = React.useState<{
    page: SLPage;
    payload?: any;
  }>({ page: SLPage.OVERVIEW });
  React.useEffect(() => {
    setSubPage(page.page);
  }, [page]);

  const [newSavedScenes, setNewSavedScenes] = React.useState<ILibraryScene[]>(
    []
  );

  const [keywords, setKeywords] = React.useState('');
  const handleUpdateSavedScenes = (scene: ILibraryScene) => {
    const copy = newSavedScenes.filter((s) => s.id !== scene.id);
    setNewSavedScenes([scene, ...copy]);
  };

  const handleRemoveDeletedScenes = (ids: string[]) => {
    const filteredScenes = newSavedScenes.filter((s) => !ids.includes(s.id));
    setNewSavedScenes([...filteredScenes]);
  };

  const sceneContentType = getSceneContentType(page?.payload);

  return (
    <Layout showNav={false} theme="dark-light" className="SceneLibrary">
      <>
        {page.page === SLPage.OVERVIEW && (
          <SLOverview
            userId={userId}
            onOpenBuilder={(initData) => {
              setPage({ page: SLPage.BUILDER, payload: initData });
            }}
            onOpenInsight={(scene) => {
              setPage({ page: SLPage.INSIGHT, payload: scene });
            }}
            initScenes={newSavedScenes}
            onUpdateInitScenes={handleRemoveDeletedScenes}
            keywords={keywords}
            setKeywords={setKeywords}
            context={context}
          />
        )}
        {page.page === SLPage.BUILDER && (
          <>
            {sceneContentType === 'video' ? (
              <SLDesignerBasic
                onClose={() => setPage({ page: SLPage.OVERVIEW })}
                userId={userId}
                initialScene={page.payload}
                onSaveCallback={handleUpdateSavedScenes}
                context={context}
              />
            ) : (
              <SLDesigner
                onClose={() => setPage({ page: SLPage.OVERVIEW })}
                userId={userId}
                initialScene={page.payload}
                onSaveCallback={handleUpdateSavedScenes}
                context={context}
              />
            )}
          </>
        )}
        {page.page === SLPage.INSIGHT && (
          <SceneInsight
            scene={page.payload}
            onBack={() => setPage({ page: SLPage.OVERVIEW })}
          />
        )}
      </>
      <FileUploadProgressPanel />
      <style jsx global>{`
        * {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        input,
        textarea {
          -webkit-touch-callout: all;
          -webkit-user-select: all;
          -khtml-user-select: all;
          -moz-user-select: all;
          -ms-user-select: all;
          user-select: all;
        }
        .SceneLibrary h2 {
          font-size: 1.4em;
          margin-bottom: 0;
        }
        .SceneLibrary .form-control:disabled {
          background: #fff;
          border-color: #efefef;
        }
        .SceneLibrary input[type='color'] {
          padding: 0 !important;
        }

        .SceneLibrary-Popup {
          padding: 0 20px;
        }

        @media (max-width: 1400px) {
          .SceneLibrary h2 {
            font-size: 1em;
          }
        }
      `}</style>
    </Layout>
  );
};

export default SceneLibrary;
