import React from 'react';
import ChatContainer from './ChatContainer';
import HubChatContainer from './HubChatContainer';
import { HubChatContext } from './HubChatContext';

const ConnectChat = () => {
  const { isFetchingConversations } = React.useContext(HubChatContext);

  return (
    <ChatContainer title="CHAT">
      {isFetchingConversations ? (
        <div className="loading">Loading....</div>
      ) : (
        <React.Fragment>
          <HubChatContainer />
        </React.Fragment>
      )}
    </ChatContainer>
  );
};

export default ConnectChat;
