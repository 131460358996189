import { useRouter } from 'next/router';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import HTMLHeader from '../../components/HTMLHeader';
import { loubiAirwaysJpBoutiqueId } from '../../config';
import { useTranslation } from '../../i18n';
import {
  IBrandSetup,
  IHubSearchFilters,
  IHubUser,
  IMainState,
  LoginStatus
} from '../../interfaces';
import {
  actionLoginFromSspAsync,
  actionSignOutAsync
} from '../../redux/advisorHubAsyncActions';
import { createNewSessionIfNeeded } from '../../utils/analyticsSession';
import { getSspControls } from '../../utils/window';
import { hasValidSession } from '../clientSideServices/login';
import { hubNavToByString } from './../utils/hubPagesRoute';
import { initFacebookSdk } from '../utils/facebook';
import HubContextContainer from './HubContext';
import HubPages from './HubPages';
import Login from './Login';
import SBContextContainer from './StoryBook/SBContextContainer';
import HubTour from './Tour';
import HubUserContextContainer from './UserManagement/HubUserContext';

const HubContainer = ({
  brandSetup,
  brandId,
  searchFilters
}: {
  brandId: string;
  brandSetup?: IBrandSetup;
  searchFilters: IHubSearchFilters;
}) => {
  const loginStatus = useSelector(
    (state: IMainState) => state.clientState.hub?.loginStatus
  );
  const user: IHubUser = useSelector(
    (state: IMainState) => state.clientState.hub?.user
  );

  const router = useRouter();
  const preferredBrandId = user?.brandId || brandId;
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const hubDefaultLanguage =
    user?.storeId === loubiAirwaysJpBoutiqueId ? 'ja' : 'en';
  const [isValidSession, setIsValidSession] = React.useState(true);
  React.useEffect(() => {
    window['brandId'] = preferredBrandId;
    window['parentId'] = brandSetup?.parentBrandId;
    createNewSessionIfNeeded();
    const sspControls = getSspControls();
    if (sspControls?.provideCredentials?.()) {
      dispatch(actionLoginFromSspAsync());
    }
  }, []);

  React.useEffect(() => {
    if (i18n.language !== hubDefaultLanguage)
      i18n.changeLanguage(hubDefaultLanguage);
  }, [i18n.language, hubDefaultLanguage]);

  React.useEffect(() => {
    let intervalId;
    if (loginStatus === LoginStatus.LOGIN_FAILED) {
      setIsValidSession(false);
    } else {
      hasValidSession().then((isValid) => {
        setIsValidSession(isValid);
      });
    }
    if (loginStatus === LoginStatus.LOGGED_IN) {
      const hubPage = router?.asPath?.split('/');
      try {
        window.opener?.onLoginSuccess?.();
      } catch (error) {
        console.log('error calling onLoginSuccess');
      }
      if (['nudges', 'sessions', 'templates'].includes(hubPage?.[3]))
        hubNavToByString(hubPage?.[3]);
      intervalId = setInterval(() => {
        hasValidSession().then((isValid) => {
          if (isValid) {
            return;
          }
          dispatch(actionSignOutAsync(true));
        });
      }, 10000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [loginStatus]);

  //init facbook sdk
  React.useEffect(() => {
    initFacebookSdk();
  }, []);

  return (
    <HubContextContainer
      brandId={preferredBrandId}
      brandSetup={brandSetup}
      searchFilters={searchFilters}
      storeId={user?.storeId}
    >
      <HTMLHeader
        title={
          brandSetup?.brandName ? `${brandSetup?.brandName} Hub` : 'INSPIFY Hub'
        }
        brandId={brandId}
        primaryColor={brandSetup?.theme?.color?.primary}
      />
      {loginStatus === LoginStatus.LOGGED_IN ? (
        <SBContextContainer brandId={preferredBrandId}>
          <HubUserContextContainer>
            <HubPages />
            <HubTour />
          </HubUserContextContainer>
        </SBContextContainer>
      ) : isValidSession ? (
        <div className="w-100 text-center p-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <Login />
      )}
    </HubContextContainer>
  );
};

export default HubContainer;
