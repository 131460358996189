import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { BiError } from 'react-icons/bi';
import { useDropzone } from 'react-dropzone';
import { HubContext } from '../HubContext';
import { IoMdArrowBack } from 'react-icons/io';

const fileName = 'avatar.jpg';

const ImageUploadModal = ({
  defaultImg,
  show,
  onToggle,
  onUpload
}: {
  defaultImg: string;
  show: boolean;
  onToggle: (val: boolean) => void;
  onUpload: (file: File, name: string) => Promise<any>;
}) => {
  const { color } = React.useContext(HubContext);
  const [file, setFile] = React.useState<File>(null);
  const [error, setError] = React.useState<string>(null);
  const [previewSrc, setPreviewSrc] = React.useState<string>(defaultImg);
  const [isUploadingFile, setIsUploadingFile] = React.useState(false);

  const onDrop = (files) => {
    const file = files[0];
    if (file) {
      setFile(file);
      setPreviewSrc(URL.createObjectURL(file));
      setError(null);
    }
  };

  const onConfirm = () => {
    setIsUploadingFile(true);
    onUpload(file, fileName)
      .then(() => {
        onToggle(false);
      })
      .catch(() => {
        setError('Fail to upload the photo, please try again!');
      })
      .finally(() => {
        setIsUploadingFile(false);
        setFile(null);
      });
  };

  const { isDragAccept, isDragReject, getRootProps, getInputProps } =
    useDropzone({
      accept: 'image/jpeg',
      multiple: false,
      onDrop
    });

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setFile(null);
          onToggle(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body className={file && 'modal-preview'}>
          {!file && (
            <>
              <div className="container">
                <div
                  className={`dropzone ${isDragAccept && 'accepted'} ${
                    isDragReject && 'rejected'
                  }`}
                  {...getRootProps({ className: 'dropzone' })}
                >
                  <input {...getInputProps()} />
                  <div className="info">
                    {isDragReject ? (
                      <p className="text-danger upload-error-message">
                        <BiError />
                        Sorry file type is not supported
                      </p>
                    ) : (
                      <>
                        <p>
                          Drag 'n' drop files here, or click to select images
                        </p>
                        <em>(Only *.jpg formart supported)</em>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {error && (
                <div className="text-danger mt-3 text-center">{error}</div>
              )}
            </>
          )}
          {file && (
            <div className="preview">
              <img src={previewSrc} alt="preview" />
            </div>
          )}
          {isUploadingFile && (
            <div className="loading">
              <Spinner animation="border" size="sm" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {file && (
            <button
              className="btn footer-btn btn-round btn-back mr-auto"
              onClick={() => setFile(null)}
            >
              <IoMdArrowBack size={16} />
            </button>
          )}
          <button
            className="btn footer-btn btn-round"
            onClick={() => {
              setFile(null);
              onToggle(false);
            }}
          >
            Close
          </button>
          <button
            disabled={!file || isUploadingFile}
            className="btn footer-btn save-btn btn-round btn-dark ml-2"
            onClick={() => onConfirm()}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <style jsx>{`
        :global(.modal-dialog) {
          max-width: 500px;
        }
        :global(.modal-header),
        :global(.modal-footer) {
          border-color: ${color?.accent};
        }
        :global(.modal-title) {
          font-weight: 500;
          text-transform: uppercase;
        }
        :global(.modal-preview) {
          padding: 20px;
        }
        .dropzone {
          border: 1px dashed grey;
          padding: 10px;
        }

        .dropzone.accepted {
          border-color: green;
        }
        .dropzone.rejected {
          border-color: red;
        }

        .upload-error-message {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .dropzone .info {
          text-align: center;
          margin: 20px auto;
        }

        .info p {
          margin-bottom: 0;
        }

        .file-upload-modal {
          width: 500px;
        }
        .preview img {
          width: 100%;
        }
        .btn-back:hover {
          background: #343740;
          color: white;
        }
        .footer-btn {
          border: 1px solid;
          padding: 5px 20px;
        }
        .btn-round {
          border-radius: 100px;
          border: 1px solid rgba(0, 0, 0, 0.5);
        }
        .loading {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.6);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @media (min-width: 992px) {
          :global(.modal-dialog) {
            max-width: 500px;
          }
        }
      `}</style>
    </>
  );
};

export default ImageUploadModal;
