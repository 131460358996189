import { format } from 'date-fns';
import { last } from 'lodash';
import React from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Table
} from 'react-bootstrap';
import { AiOutlineMail, AiOutlineUserAdd } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import LineIcon from 'react-share/lib/LineIcon';
import LineShareButton from 'react-share/lib/LineShareButton';
import { logEvent } from '../../../analytics';
import { loubiAirwaysJpBoutiqueId } from '../../../config';
import { useTranslation } from '../../../i18n';
import {
  IMainState,
  INudge,
  INudgePayload,
  NudgeStatus
} from '../../../interfaces';
import { actionGetNudgeByUserIdAsync } from '../../../redux/advisorHubAsyncActions';
import { actionUpdateNudgeStatusForClientAsync } from '../../../redux/asyncActions';
import {
  DID_CLICK_ADD_INVITE,
  DID_CLICK_INVITE_SEND_BUTTON,
  DID_CREATE_NEW_INVITE,
  DID_FAIL_TO_CREATE_NEW_INVITE,
  DID_FAIL_TO_SHARE_NATIVELY,
  DID_TRY_TO_CREATE_NEW_INVITE
} from '../../../utils/constants';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import { getIdentityId } from '../../../utils/identity';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { getSimpleShortenedUrl } from '../../../utils/shareUrl';
import { performNativeShare } from '../../../utils/window';
import { saveNudge } from '../../clientSideServices/nudge';
import { paginate } from '../../utils/common';
import { getStatusDisplay } from '../../utils/nudge';
import { getAssetPath, getCoverImg, getThemeColor } from '../BrandStyle';
import Pagination from '../Common/Pagination';
import { HubContext } from '../HubContext';
import TwoColLayout from '../TwoColLayout';
import { ecomStoreId, getInviteContent } from './config/loubiairwaysjp';

interface CellProps {
  isMobile: boolean;
  nudge: INudgePayload;
}

export const DataCell = ({ isMobile, nudge }: CellProps) => {
  const modifiedDate = nudge.modifiedAt
    ? format(new Date(nudge.modifiedAt), 'MMM dd, yyyy - p')
    : 'unknown';
  const inviteContent = getInviteContent(nudge.name);
  const inviteLink = `https://loubiairways.christianlouboutin.com/jp?utm_source=invite&utm_campaign=${nudge.id}`;
  const [shortenedUrl, setShortenedUrl] = React.useState(inviteLink);
  const dispatch = useDispatch();
  const onSuccessfulNativeShare = () => {
    logEvent(DID_CLICK_INVITE_SEND_BUTTON, DID_CLICK_INVITE_SEND_BUTTON, {
      type: 'native',
      inviteId: nudge.id
    });
  };
  const onFailedNativeShare = () => {
    logEvent(DID_FAIL_TO_SHARE_NATIVELY, DID_FAIL_TO_SHARE_NATIVELY, {
      type: 'native',
      inviteId: nudge.id
    });
  };

  const isEcom = nudge.storeId === ecomStoreId;
  React.useEffect(() => {
    getSimpleShortenedUrl(inviteLink).then((url) => {
      setShortenedUrl(url);
    });
  }, [nudge.id]);
  return (
    <>
      <tr className="tr-item">
        <td className="td-main">
          {nudge.name}
          <div>
            <span className="text-sm show-m">
              {getStatusDisplay(nudge.status)}
              <br />
              at {modifiedDate}
            </span>
          </div>
        </td>
        <td>{nudge.customerEmail}</td>
        <td className="text-right hide-m">
          <span className="text-sm">
            {getStatusDisplay(nudge.status)} at {modifiedDate}
          </span>
        </td>
        <td>
          <DropdownButton variant="dark" title="SEND">
            <Dropdown.Item
              onClick={() => {
                logEvent(
                  DID_CLICK_INVITE_SEND_BUTTON,
                  DID_CLICK_INVITE_SEND_BUTTON,
                  {
                    type: 'EMAIL TEMPLATE',
                    inviteId: nudge.id
                  }
                );
                dispatch(
                  actionUpdateNudgeStatusForClientAsync(
                    nudge.id,
                    NudgeStatus.INVITATION_SENT
                  )
                );
                window.open(
                  `/api/getEmailTemplate?templateId=${
                    isEcom ? 'loubiairwaysjpecomm' : 'loubiairwaysjp'
                  }&recipient=${
                    nudge.name || ''
                  }&landingUrl=${shortenedUrl}&email=${
                    nudge.customerEmail || ''
                  }`
                );
              }}
            >
              <AiOutlineMail color="#343740" />
              Share via Email
            </Dropdown.Item>
            <Dropdown.Item>
              {isMobile ? (
                <button
                  className="share-btn"
                  onClick={() => {
                    performNativeShare(
                      {
                        title: 'LoubiAirways Japan Trunk Show 2021',
                        text: inviteContent,
                        url: shortenedUrl
                      },
                      onSuccessfulNativeShare,
                      onFailedNativeShare
                    );
                  }}
                >
                  <LineIcon
                    bgStyle={{ fill: '#343740' }}
                    round
                    crossOrigin="anonymous"
                  />
                  <span className="btnShareSpan">Share via LINE</span>
                </button>
              ) : (
                <LineShareButton
                  url={shortenedUrl}
                  title={inviteContent}
                  className="btnShare"
                  onClick={() => {
                    logEvent(
                      DID_CLICK_INVITE_SEND_BUTTON,
                      DID_CLICK_INVITE_SEND_BUTTON,
                      {
                        type: 'LINE',
                        inviteId: nudge.id
                      }
                    );
                    dispatch(
                      actionUpdateNudgeStatusForClientAsync(
                        nudge.id,
                        NudgeStatus.INVITATION_SENT
                      )
                    );
                  }}
                >
                  <LineIcon
                    bgStyle={{ fill: '#343740' }}
                    round
                    crossOrigin="anonymous"
                  />
                  <span className="btnShareSpan">Share via LINE</span>
                </LineShareButton>
              )}
            </Dropdown.Item>
          </DropdownButton>
        </td>
      </tr>
      <tr>
        <td className="td-space" />
      </tr>
      <style jsx>
        {`
          .td-main {
            width: 25%;
          }

          td:last-child {
            position: relative;
            width: 70px;
            padding: 0 !important;
          }
          .tr-item :global(.dropdown) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
          }
          .tr-item :global(.dropdown button) {
            font-size: 14px;
            padding: 5px;
          }

          .tr-item :global(svg) {
            display: inline-block;
            margin-right: 10px;
            height: 25px;
            width: 25px;
          }

          .tr-item .share-btn {
            border: 0;
            background: transparent;
            font-size: 16px;
            padding: 0;
          }
        `}
      </style>
    </>
  );
};

const PreConfiguredInvitesOverview = () => {
  const dispatch = useDispatch();
  const [keywords, setKeywords] = React.useState('');
  const [isMobile, setIsMobile] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const newInviteNameRef = React.createRef<HTMLInputElement>();
  const newInviteEmailRef = React.createRef<HTMLInputElement>();
  const newInviteCountryCodeRef = React.createRef<HTMLInputElement>();
  const newInviteMobileNumberRef = React.createRef<HTMLInputElement>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const { brandId, storeId: siteId, brandSetup } = React.useContext(HubContext);
  const coverImg = getCoverImg(brandId, brandSetup);
  const color = getThemeColor(brandId);
  const itemsPerPage = 10;
  const { t } = useTranslation();

  const hubState = useSelector((state: IMainState) => state.clientState.hub);
  const userState = hubState?.user;
  const invites = hubState?.nudgeList || [];

  const filteredInvites = invites.filter((nudge) =>
    nudge.name.toLowerCase().includes(keywords.toLowerCase())
  );

  const listToRender: INudgePayload[] = paginate(
    filteredInvites,
    currentPage,
    itemsPerPage
  );

  const onPageChange = (index: number, shouldFetch: boolean) => {
    if (shouldFetch) {
      const lastItem = last(invites);
      dispatch(
        actionGetNudgeByUserIdAsync(userState?.id, {
          createdBy: {
            S: lastItem?.createdBy
          },
          id: {
            S: lastItem?.id
          },
          modifiedAt: {
            S: lastItem?.modifiedAt
          }
        })
      );
    }
    setCurrentPage(index);
  };

  const createNewInvite = (
    name: string,
    customerEmail?: string,
    customerPhoneCountryCode?: string,
    customerPhoneNumber?: string
  ) => {
    const invite: INudge = {
      id: generateV4UUID(),
      name,
      customerEmail,
      customerPhoneCountryCode,
      customerPhoneNumber,
      storeId: userState?.associatedStoreId || userState?.storeId,
      content: [],
      advisorId: getIdentityId(),
      languageCode: siteId === loubiAirwaysJpBoutiqueId ? 'JP' : 'EN'
    };
    logEvent(DID_TRY_TO_CREATE_NEW_INVITE, DID_TRY_TO_CREATE_NEW_INVITE, {
      id: invite.id
    });
    saveNudge(invite, getIdentityId())
      .then(() => {
        dispatch(actionGetNudgeByUserIdAsync(userState.id));
        logEvent(DID_CREATE_NEW_INVITE, DID_CREATE_NEW_INVITE, {
          id: invite.id
        });
      })
      .catch((error) => {
        logEvent(DID_FAIL_TO_CREATE_NEW_INVITE, DID_FAIL_TO_CREATE_NEW_INVITE, {
          id: invite.id,
          error
        });
      });
  };

  React.useEffect(() => {
    setIsMobile(isUserOnMobile());
  }, []);

  React.useEffect(() => {
    let fetching;
    if (userState?.id) {
      dispatch(actionGetNudgeByUserIdAsync(userState.id));
      fetching = setInterval(() => {
        dispatch(actionGetNudgeByUserIdAsync(userState.id));
      }, 30000);
    }
    return () => {
      clearInterval(fetching);
    };
  }, [userState?.id]);

  return (
    <TwoColLayout
      theme="dark"
      background={{
        mobile: coverImg?.mobile || `${getAssetPath(brandId)}/invite.jpg`,
        desktop: coverImg?.desktop || `${getAssetPath(brandId)}/invite.jpg`
      }}
      brandId={brandId}
      showNav={false}
      className="Invites"
    >
      <div className="page-body">
        <h1>
          INVITE LIST{' '}
          <AiOutlineUserAdd
            className="add"
            onClick={() => {
              logEvent(DID_CLICK_ADD_INVITE);
              setShowModal(true);
            }}
          />
        </h1>
        <div className="container list-container">
          <input
            type="text"
            className="form-control search"
            placeholder="Search by name"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
          <Table className="table" responsive="sm">
            <tbody>
              {listToRender.map((nudge) => (
                <DataCell key={nudge.id} nudge={nudge} isMobile={isMobile} />
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          onPageChange={(index, shouldFetch) =>
            onPageChange(index, shouldFetch)
          }
          totalItems={filteredInvites.length}
          itemsPerPage={itemsPerPage}
          buttonsPerPage={5}
          currentPage={currentPage}
        />
        <Modal
          show={showModal}
          centered
          onHide={() => {
            setShowModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('add_invite')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-group name">
              <label htmlFor="name">{t('name')}</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder={t('please_enter_name')}
                ref={newInviteNameRef}
              />
            </div>
            <div className="input-group email">
              <label htmlFor="email">{t('email')}</label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder={t('please_enter_email_address')}
                ref={newInviteEmailRef}
              />
            </div>
            <div className="input-group phone">
              <label htmlFor="phone">{t('phone_number')}</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  id="country-code"
                  placeholder="Enter recipient country code"
                  defaultValue={
                    siteId === loubiAirwaysJpBoutiqueId ? '+81' : undefined
                  }
                  ref={newInviteCountryCodeRef}
                />
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder={t('please_enter_phone_number')}
                  ref={newInviteMobileNumberRef}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              {t('close')}
            </Button>
            <Button
              variant="dark"
              onClick={() => {
                const hasValidInput = (() => {
                  if (!newInviteNameRef.current?.value) {
                    return false;
                  }
                  if (newInviteEmailRef.current?.value) {
                    return true;
                  }
                  if (
                    newInviteCountryCodeRef.current?.value &&
                    newInviteMobileNumberRef.current?.value
                  ) {
                    return true;
                  }
                  return false;
                })();
                if (hasValidInput) {
                  createNewInvite(
                    newInviteNameRef.current.value,
                    newInviteEmailRef.current?.value,
                    newInviteCountryCodeRef.current?.value,
                    newInviteMobileNumberRef.current?.value
                  );
                  setShowModal(false);
                }
              }}
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <style jsx>{`
        :global(.Invites .layout .content-wrapper) {
          display: block !important;
        }
        :global(.TwoColLayout .layout .page-body) {
          max-width: 800px !important;
        }
        :global(.input-group) {
          flex-direction: column;
          margin: 20px 0;
        }
        :global(.input-group.name) {
          margin-top: 0;
        }
        :global(.input-group.email) {
          margin-bottom: 0;
        }
        :global(.input-group.phone > div) {
          display: flex;
          flex-direction: row;
        }

        :global(#country-code) {
          width: 80px;
          margin-right: 10px;
        }

        :global(.modal-dialog) {
          max-width: 500px !important;
        }
        .page-body {
          padding-bottom: 90px !important;
        }

        h1 {
          position: relative;
        }
        h1 :global(.add) {
          cursor: pointer;
          position: absolute;
          right: 15px;
        }
        .search {
          margin: 20px 0 30px 0;
        }

        :global(.Invites .text-sm) {
          font-size: 0.8em;
          color: #999;
          line-height: 1.2;
          display: block;
        }
        .spinner-container {
          height: 40px;
        }
        .btn-filter {
          color: ${color.btnPrimary};
          border-bottom: 2px solid #efefef;
          box-shadow: none;
          outline: none;
        }
        :global(.ssp) .btn-filter {
          font-size: 1.2em;
        }
        .filter-active {
          color: ${color.btnPrimary};
          border-bottom: 2px solid ${color.btnPrimary};
          opacity: 1;
        }
      `}</style>
    </TwoColLayout>
  );
};

export default PreConfiguredInvitesOverview;
