import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { changeContentOrder } from '../../utils/nudge';
import StorybookItemInSession from './StorybookItemInSession';

const SortableItem = SortableElement(
  ({ value: index, onView, storybooks, updateFormData }) => {
    const id = storybooks[Number(index)];
    return (
      <div className="uploaded-file">
        <StorybookItemInSession
          key={id}
          id={id}
          onView={() => onView(id)}
          onDelete={() => {
            updateFormData(storybooks.filter((s) => s !== id));
          }}
        />

        <style jsx>
          {`
            .uploaded-file {
              display: flex;
              padding: 0.275rem 0 0.275rem 0.75rem;
              margin: 0.275rem;
              align-items: center;
              background: #efefef;
              width: 100%;
              position: relative;
              justify-content: space-between;
            }

            .uploaded-file > :global(div:last-child) {
              position: relative;
            }

            .uploaded-file:last-child {
              border-bottom: none;
            }
            .uploaded-file :global(svg) {
              pointer-events: none;
              margin-left: 10px;
            }
          `}
        </style>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ storybooks, onView, updateFormData }) => {
    return (
      <div>
        {storybooks.map((id, index) => (
          <SortableItem
            {...{ storybooks, onView, updateFormData }}
            key={`item-${id}-index`}
            index={index}
            {...({ value: index } as any)}
          />
        ))}
      </div>
    );
  }
);

const SessionStorybookList = ({
  storybooks,
  updateFormData,
  onView
}: {
  storybooks: string[];
  updateFormData: (storybooks: string[]) => void;
  onView: (id: string) => void;
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = changeContentOrder(storybooks, oldIndex, newIndex);
    updateFormData(sorted);
  };

  return (
    <div>
      <SortableList
        helperClass="sortItem sortActive"
        onView={onView}
        updateFormData={updateFormData}
        onSortEnd={onSortEnd}
        lockAxis="y"
        {...({ storybooks } as any)}
      />
    </div>
  );
};

export default SessionStorybookList;
