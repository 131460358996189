import { format } from 'date-fns';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { BsFillEyeFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
  IMainState,
  INudgePayload,
  NudgeStatus,
  ProgressStatus
} from '../../../interfaces';
import {
  actionGetNudgeByUserIdAsync,
  actionGetNudgeFavoriteItemsAsync,
  actionGetNudgeFromPayloadAsync
} from '../../../redux/advisorHubAsyncActions';
import {
  DID_CLICK_BUTTON,
  DID_CLICK_CURATE_YOUR_NUDGE,
  DID_CLICK_FILTER_NUDGE,
  DID_CLICK_JOIN_BUTTON,
  DID_CLICK_VIEW_BUTTON
} from '../../../utils/constants';
import { StartKey } from '../../clientSideServices/nudge';
import { hubNavTo } from '../../utils/hubPagesRoute';
import { getStatusDisplay } from '../../utils/nudge';
import { getAssetPath, getCoverImg, getThemeColor } from '../BrandStyle';
import Pagination from '../Common/Pagination';
import { HubContext } from '../HubContext';
import TwoColLayout from '../TwoColLayout';
import { logEvent } from './../../../analytics/index';
import { paginate } from './../../utils/common';
import { last } from 'lodash';
import { PageView } from '../../../utils/pageView';

interface CellProps {
  nudge: INudgePayload;
  onJoin: (nudge: INudgePayload) => void;
  onView: (nudge: INudgePayload) => void;
}

export const DataCell = ({ nudge, onJoin, onView }: CellProps) => {
  const modifiedDate = nudge.modifiedAt
    ? format(new Date(nudge.modifiedAt), 'MMM dd, yyyy - p')
    : 'unknown';
  return (
    <>
      <tr className="tr-item">
        <td className="td-main">
          {nudge.name}
          <div>
            <span className="text-sm show-m">
              {getStatusDisplay(nudge.status)}
              <br />
              at {modifiedDate}
            </span>
          </div>
        </td>
        <td className="text-right hide-m">
          <span className="text-sm">
            {getStatusDisplay(nudge.status)} at {modifiedDate}
          </span>
        </td>
        <td className="td-btn">
          <div className="invisible">
            <button className="btn btn-dark btn-icon">
              <BsFillEyeFill />
            </button>
            <button className="btn btn-dark">JOIN</button>
          </div>
          <div className="btn-group">
            <button
              className="btn btn-dark btn-icon btn-view-nudge"
              onClick={() => onView(nudge)}
              title="Preview your nudge"
            >
              <BsFillEyeFill title="Preview your nudge" />
            </button>
            <button
              className="btn btn-dark btn-join-nudge"
              onClick={() => onJoin(nudge)}
              title="Join the virtual walkthrough lounge"
            >
              JOIN
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <td className="td-space" />
      </tr>
    </>
  );
};

const Nudge = () => {
  const dispatch = useDispatch();
  const { brandId, brandSetup } = React.useContext(HubContext);
  const coverImg = getCoverImg(brandId, brandSetup);
  const color = getThemeColor(brandId);
  const itemsPerPage = 10;

  const hubState = useSelector((state: IMainState) => state.clientState.hub);
  const userState = hubState?.user;
  const nudgeList = hubState?.nudgeList || [];
  const progress = hubState?.progressStatus;

  const [statusFilter, setStatusFilter] = React.useState<NudgeStatus>(
    NudgeStatus.AWAITING_WALKTHROUGH
  );
  const [currentPage, setCurrentPage] = React.useState(1);

  const filteredList = statusFilter
    ? nudgeList.filter((nudge) => nudge.status === statusFilter)
    : nudgeList;

  const listToRender: INudgePayload[] = paginate(
    filteredList,
    currentPage,
    itemsPerPage
  );
  const handleFilter = (filter: NudgeStatus) => {
    setStatusFilter(filter);
    setCurrentPage(1);
    logEvent(DID_CLICK_FILTER_NUDGE, DID_CLICK_FILTER_NUDGE, {
      filterBy: filter
    });
  };
  const onView = (nudge: INudgePayload) => {
    dispatch(actionGetNudgeFromPayloadAsync(nudge));
    logEvent(DID_CLICK_VIEW_BUTTON, DID_CLICK_VIEW_BUTTON, {
      nudgeId: nudge.id
    });
  };

  const onJoin = (nudge: INudgePayload) => {
    window.open(nudge?.meetingUrlAdvisor, '_blank');
    logEvent(DID_CLICK_JOIN_BUTTON, DID_CLICK_JOIN_BUTTON, {
      nudgeId: nudge.id
    });
  };

  const handleFetchNudge = (startKey?: StartKey) => {
    dispatch(actionGetNudgeByUserIdAsync(userState.id, startKey));
  };

  const onPageChange = (index: number, shouldFetch: boolean) => {
    if (shouldFetch) {
      const lastNudge = last(nudgeList);
      handleFetchNudge({
        createdBy: {
          S: lastNudge.createdBy
        },
        id: {
          S: lastNudge.id
        },
        modifiedAt: {
          S: lastNudge.modifiedAt
        }
      });
    }
    setCurrentPage(index);
  };

  const getCurateFromTemplatesButton = () => {
    return (
      <button
        className="btn btn-round btn-secondary"
        onClick={() => {
          hubNavTo(PageView.HUB_NUDGE_TEMPLATES_SELECT);
          logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
            button: 'CURATE FROM TEMPLATES'
          });
        }}
      >
        CURATE FROM TEMPLATES
      </button>
    );
  };

  React.useEffect(() => {
    let fetching;
    if (userState?.id) {
      handleFetchNudge();
      dispatch(actionGetNudgeFavoriteItemsAsync(userState));
      fetching = setInterval(() => {
        handleFetchNudge();
      }, 30000);
    }
    return () => {
      clearInterval(fetching);
    };
  }, [userState]);
  return (
    <TwoColLayout
      theme="dark"
      background={{
        mobile: coverImg?.mobile || `${getAssetPath(brandId)}/invite.jpg`,
        desktop: coverImg?.desktop || `${getAssetPath(brandId)}/invite.jpg`
      }}
      brandId={brandId}
      showNav={false}
      className="Nudge"
    >
      <div className="page-body ">
        <h1>INVITE LIST</h1>
        <div className="btn-group btn-group-sm">
          <button
            className={`btn btn-filter ${
              statusFilter === NudgeStatus.AWAITING_WALKTHROUGH &&
              'filter-active'
            }`}
            onClick={() => handleFilter(NudgeStatus.AWAITING_WALKTHROUGH)}
          >
            In the Lounge
          </button>
          <button
            className={`btn btn-filter ${!statusFilter && 'filter-active'}`}
            onClick={() => handleFilter(null)}
          >
            All
          </button>
          <button
            className={`btn btn-filter ${
              statusFilter === NudgeStatus.INVITATION_OPENED && 'filter-active'
            }`}
            onClick={() => handleFilter(NudgeStatus.INVITATION_OPENED)}
          >
            Opened
          </button>
          <button
            className={`btn btn-filter ${
              statusFilter === NudgeStatus.JOINED_WALKTHROUGH && 'filter-active'
            }`}
            onClick={() => handleFilter(NudgeStatus.JOINED_WALKTHROUGH)}
          >
            Joined
          </button>
        </div>
        <div className="spinner-container">
          {progress === ProgressStatus.LOADING && (
            <Spinner animation="border" />
          )}
        </div>
        {!nudgeList.length && progress === ProgressStatus.SUCCESSFUL && (
          <div className="nudge-empty-placeholder">
            <p className="text-accent">No Nudge Yet</p>
            <button
              className="btn btn-round btn-dark"
              onClick={() => hubNavTo(PageView.HUB_NUDGES_CREATE)}
            >
              CURATE YOUR FIRST NUDGE
            </button>
            {getCurateFromTemplatesButton()}
          </div>
        )}
        {progress === ProgressStatus.FAILED && (
          <p className="text-accent">
            We are experiencing technical difficulties. Please refresh your
            browser.
          </p>
        )}
        {!!nudgeList.length && (
          <>
            {filteredList.length ? (
              <>
                <div className="container list-container">
                  <table className="table">
                    <tbody>
                      {listToRender.map((nudge) => (
                        <DataCell
                          key={nudge.id}
                          nudge={nudge}
                          onJoin={onJoin}
                          onView={onView}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  onPageChange={(index, shouldFetch) =>
                    onPageChange(index, shouldFetch)
                  }
                  totalItems={filteredList.length}
                  itemsPerPage={itemsPerPage}
                  buttonsPerPage={5}
                  currentPage={currentPage}
                />
              </>
            ) : (
              <p className="text-accent">
                Nudge not found with selected filter.
              </p>
            )}
            <div className="btn-control">
              <button
                className="btn btn-round btn-dark"
                onClick={() => {
                  hubNavTo(PageView.HUB_NUDGES_CREATE);
                  logEvent(
                    DID_CLICK_CURATE_YOUR_NUDGE,
                    DID_CLICK_CURATE_YOUR_NUDGE
                  );
                }}
              >
                CURATE YOUR NUDGE
              </button>
              {getCurateFromTemplatesButton()}
            </div>
          </>
        )}
      </div>
      <style jsx>{`
        :global(.Nudge .layout .content-wrapper) {
          display: block !important;
        }
        .page-body {
          padding-bottom: 90px !important;
        }

        :global(.Nudge .text-sm) {
          font-size: 0.8em;
          color: #999;
          line-height: 1.2;
          display: block;
        }
        .spinner-container {
          height: 40px;
        }
        .btn-filter {
          color: ${color.btnPrimary};
          border-bottom: 2px solid #efefef;
          box-shadow: none;
          outline: none;
        }
        :global(.ssp) .btn-filter {
          font-size: 1.2em;
        }
        .filter-active {
          color: ${color.btnPrimary};
          border-bottom: 2px solid ${color.btnPrimary};
          opacity: 1;
        }
        .btn-control .btn {
          margin: 4px;
        }
        @media (max-width: 490px) {
          .btn-control .btn {
            width: 100%;
            max-width: 300px;
          }
        }
      `}</style>
    </TwoColLayout>
  );
};

export default Nudge;
