import { isEmpty } from 'lodash';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import {
  aggregateLiveBrowsingHistoriesByProductCode,
  mapProductBrowsingHistories
} from '../../../clientSideServices/analytics/browsingHistory';
import { HubChatContext } from '../HubChatContext';
import InsightItem from './InsightItem';

export enum InsightType {
  PRODUCT_VIEW = 'PRODUCT_VIEW',
  STARTED_CHAT = 'STARTED_CHAT'
}

const PageViewInsights = () => {
  const {
    isFetchingHistory,
    startHistoryRow,
    totalHistoryRows,
    fetchHistories,
    browsingHistories
  } = React.useContext(HubChatContext);
  const insightsContainer: React.RefObject<HTMLDivElement> = React.createRef();
  const productBrowsingHistories = React.useMemo(() => {
    const mappedHistories = mapProductBrowsingHistories(browsingHistories);
    return aggregateLiveBrowsingHistoriesByProductCode(mappedHistories);
  }, [browsingHistories]);

  const isFetchingInitially = isFetchingHistory && isEmpty(browsingHistories);
  const hasMoreItems =
    startHistoryRow < totalHistoryRows &&
    !isFetchingHistory &&
    !isEmpty(browsingHistories) &&
    !isEmpty(productBrowsingHistories);

  const onLoadMore = () => {
    if (startHistoryRow) {
      fetchHistories(startHistoryRow);
    }
  };

  const onScrolledToBottom = () => {
    if (
      insightsContainer?.current?.scrollTop +
        insightsContainer?.current?.clientHeight >=
        insightsContainer?.current?.scrollHeight &&
      hasMoreItems
    ) {
      onLoadMore();
    }
  };

  const loadingMore = (
    <div style={{ width: '100%', padding: '10px 0', textAlign: 'center' }}>
      <Spinner animation="border" size="sm" />
    </div>
  );

  return (
    <div
      className="list-wrapper"
      ref={insightsContainer}
      onScroll={() => onScrolledToBottom()}
    >
      {isFetchingInitially && <div className="loading">Loading....</div>}
      <div className="item-container">
        {productBrowsingHistories.map((history, index) => (
          <InsightItem key={index} history={history} />
        ))}
      </div>
      {!isFetchingHistory && isEmpty(productBrowsingHistories) && (
        <span>No browsing histories</span>
      )}
      {isFetchingHistory && !isEmpty(browsingHistories) && loadingMore}
      <style jsx>
        {`
          .list-wrapper {
            overflow: auto;
            flex-grow: 1;
            padding: 10px 15px;
          }

          .load-more-button {
            margin: 10px auto;
            text-align: center;
            cursor: pointer;
          }
          .item-container {
            position: relative;
          }
          .item-container:before {
            content: '';
            display: block;
            width: 1px;
            background: #c4c4c4;
            position: absolute;
            z-index: 1;
            top: 77px;
            bottom: 70px;
            left: 17px;
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            .list-wrapper {
              padding: 0 15px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PageViewInsights;
