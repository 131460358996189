import React from 'react';
import { IEntitlement, IUser, RecordingEntitlement } from '../../../interfaces';
import ChangePwdForm from './ChangePwdForm';
import EditProfileForm from './EditProfileForm';
import { HubUserContext } from './HubUserContext';

function EditProfile({
  user,
  closeEditor
}: {
  user: IUser;
  closeEditor?: () => void;
}) {
  const [editedUser, setEditedUser] = React.useState<IUser>(user);

  React.useEffect(() => {
    setEditedUser(user);
  }, [user]);

  const { editMode, changePwdView, isAdminPage } =
    React.useContext(HubUserContext);

  const handleFormChange = (e) => {
    const field = e.target.id;
    if (field === 'recordingOption') {
      const value: RecordingEntitlement = e.target.value;
      const currentEntitlementsWithoutRecording =
        editedUser.entitlements?.filter(
          (ent) => ent.entitlementType !== 'RECORDING_VISIBILITY'
        );
      const recordingEntitlement: IEntitlement = {
        entitlementType: 'RECORDING_VISIBILITY',
        uuid: value
      };
      setEditedUser({
        ...editedUser,
        entitlements: [
          ...currentEntitlementsWithoutRecording,
          recordingEntitlement
        ]
      });
    } else if (field !== 'storeId') {
      setEditedUser({ ...editedUser, [field]: e.target.value });
    } else {
      const _editedUser = { ...editedUser };
      const store = _editedUser.entitlements?.find(
        (ent) => ent.entitlementType === 'STORE_ID'
      );
      if (store) store.uuid = e.target.value;
      else {
        const newEntitlement: IEntitlement = {
          entitlementType: 'STORE_ID',
          uuid: e.target.value
        };
        _editedUser.entitlements.push(newEntitlement);
      }
      setEditedUser(_editedUser);
    }
  };
  const revertEdit = () => {
    setEditedUser(user);
  };
  return (
    <div className="account-settings">
      <div className="row gutters">
        {!changePwdView || isAdminPage ? (
          <EditProfileForm
            editMode={editMode}
            editedUser={editedUser}
            handleFormChange={handleFormChange}
            revertEdit={revertEdit}
            closeEditor={closeEditor}
          />
        ) : (
          <ChangePwdForm />
        )}
      </div>
      <style jsx global>{`
        .account-settings .form-control {
          border: #c4c4c4 solid 0.3px;
        }
        .account-settings .btn {
          border-radius: 50px;
          min-width: 80px;
        }
      `}</style>
    </div>
  );
}

export default EditProfile;
