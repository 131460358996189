import React from 'react';
import {
  SortEnd,
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import { changeContentOrder } from '../../../utils/nudge';
import { ISceneDetail, RuleId, SceneRule } from '../../../../interfaces';
import { Button, Spinner } from 'react-bootstrap';
import {
  CloseRulePopupIcon,
  FlagIcon,
  HamburgerIcon,
  InfoIcon,
  PurpleFlagIcon,
  TrashIcon
} from '../../Common/HubIcons';
import PopupContainer from '../../Common/PopupContainer';
import { isEqual, sortBy } from 'lodash';

const SortableItem = SortableElement(
  ({ value, scenes, onRemove, onDefault, onChangeDuration, onChangeRules }) => (
    <li className="d-flex align-items-center w-full showcased-scene-item">
      <span className="showcased-scene-index">{value + 1}.</span>
      <SceneEntry
        scene={scenes[value]}
        onRemove={onRemove}
        onDefault={onDefault}
        onChangeDuration={onChangeDuration}
        onChangeRules={onChangeRules}
      />
      <Handle />
    </li>
  )
);

const Handle = SortableHandle(() => (
  <button className="btn btn-handler">
    <HamburgerIcon />
  </button>
));

const SortableList = SortableContainer(
  ({
    items,
    scenes,
    onRemove,
    onDefault,
    onChangeDuration,
    onChangeRules
  }: {
    items: string[];
    scenes: ISceneDetail[];
    onRemove: (sceneId: string) => void;
    onDefault: (sceneId: string) => void;
    onChangeDuration: (sceneId: string, duration: string) => void;
    onChangeRules: (sceneId: string, rule: string) => void;
  }) => {
    return (
      <ul className="showcased-scene-list">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            scenes={scenes}
            onDefault={onDefault}
            onRemove={onRemove}
            onChangeDuration={onChangeDuration}
            onChangeRules={onChangeRules}
            {...({ value: index } as any)}
          />
        ))}
      </ul>
    );
  }
);

const Rules = {
  GENDER: [
    {
      title: 'Male'
    },
    {
      title: 'Female'
    }
  ],
  DISTANCE: [
    {
      title: 'Near'
    },
    {
      title: 'Mid'
    },
    {
      title: 'Far'
    }
  ],
  'AGE GROUP': [
    {
      title: 'Kids',
      subTitle: '< 8 Years Old'
    },
    {
      title: 'Tweens',
      subTitle: '8-12 Years Old'
    },
    {
      title: 'Teenegers',
      subTitle: '13 - 18 Years Old'
    },
    {
      title: 'Young Adults',
      subTitle: '19 - 26 Years Old'
    },
    {
      title: 'Adults',
      subTitle: '27 - 49 Years Old'
    },
    {
      title: 'Mature Adults',
      subTitle: '50 - 59 Years Old'
    },
    {
      title: 'Seniors',
      subTitle: '> 60 Years'
    }
  ],
  EMOTION: [
    {
      title: 'Neutral'
    },
    {
      title: 'Happy'
    },
    {
      title: 'Surprised'
    },
    {
      title: 'Sad'
    }
  ]
};

function SceneEntry({
  scene,
  onRemove,
  onDefault,
  onChangeDuration,
  onChangeRules
}: {
  scene: ISceneDetail;
  onRemove: (sceneId: string) => void;
  onDefault: (sceneId: string) => void;
  onChangeDuration: (sceneId: string, duration: string) => void;
  onChangeRules: (sceneId: string, rule: string) => void;
}) {
  const { name } = scene?.content || {};
  const [error, setError] = React.useState(false);
  const [isOpenRules, setIsOpenRules] = React.useState(false);
  const [rules, setRules] = React.useState<SceneRule[]>(() =>
    scene.rule === 'NA' ? [] : JSON.parse(scene.rule)
  );

  const thumbnail = React.useMemo(() => {
    if (!scene?.content?.thumbnail) return '';
    try {
      const thumbnailObj = JSON.parse(scene.content.thumbnail);
      return thumbnailObj['en'] || Object.values(thumbnailObj)[0];
    } catch {
      return scene.content.thumbnail;
    }
  }, [scene?.content?.thumbnail]);

  React.useEffect(() => {
    if (isOpenRules) {
      const initialRules = scene?.rule === 'NA' ? [] : JSON.parse(scene?.rule);
      const sortedArray = (arr) => sortBy(arr, ['id', 'values']);
      const isRulesEqual = isEqual(
        sortedArray(initialRules),
        sortedArray(rules)
      );

      if (!isRulesEqual) {
        setRules(initialRules);
      }
    }
  }, [isOpenRules]);

  const handleOpenRules = () => {
    setIsOpenRules(true);
  };

  const handleCloseRules = () => {
    setIsOpenRules(false);
  };

  const handleResetRules = () => {
    setRules([]);
  };

  const handleUpdateRules = (key: RuleId, value: string) => {
    const newRules = [...rules];
    const existingRule = newRules.find((rule) => rule.id === key);

    if (existingRule) {
      existingRule.values = existingRule.values.includes(value)
        ? existingRule.values.filter((val) => val !== value)
        : existingRule.values.concat(value);
      if (existingRule.values.length === 0) {
        newRules.splice(newRules.indexOf(existingRule), 1);
      }
    } else {
      newRules.push({ id: key, values: [value] });
    }
    setRules(newRules);
  };

  const handleConfirmRules = () => {
    onChangeRules(scene?.id, rules.length > 0 ? JSON.stringify(rules) : 'NA');
    handleCloseRules();
  };

  return (
    <div className="flex-grow-1 d-flex align-items-center gap-1">
      <button
        className="btn btn-rounded btn-menu"
        onClick={() => onDefault(scene?.id)}
      >
        {scene?.isDefaultScene ? <PurpleFlagIcon /> : <FlagIcon />}
      </button>
      <div className="scene-wrapper">
        <img src={thumbnail} alt="" />
      </div>
      <span>{name}</span>
      <div className="scene-duration-wrapper">
        <span>Duration</span>
        <div className="scene-info mx-2">
          <InfoIcon size="12" />
        </div>
        <input
          value={scene?.durationInSec || ''}
          type="number"
          className={`scene-input-duration form-control-sm form-control ${
            error ? 'is-invalid' : ''
          }`}
          id="duration"
          min={0}
          onChange={(e) => {
            const value = e.target?.value;
            const parsedValue = parseInt(value);

            setError(isNaN(parsedValue) || parsedValue < 0);
            onChangeDuration(scene?.id, e.target.value);
          }}
        />
      </div>
      <button
        className="btn btn-round btn-outline btn-rules"
        onClick={handleOpenRules}
      >
        Rules
      </button>
      <button className="btn btn-rounded" onClick={() => onRemove(scene?.id)}>
        <TrashIcon />
      </button>
      {isOpenRules && (
        <PopupContainer maxWidth="840px">
          <div className="d-flex flex-column w-100 popup-container">
            <span className="custom-close-btn" onClick={handleCloseRules}>
              <CloseRulePopupIcon />
            </span>
            <div className="rules-wrapper">
              {Object.entries(Rules).map(([key, value]: [RuleId, any]) => (
                <div
                  className={`rule rule-${key
                    .replace(/\s+/g, '')
                    .toLocaleLowerCase()}`}
                  key={key}
                >
                  <div className="label">
                    {key === 'AGE GROUP' ? 'Age Group' : key}
                  </div>
                  <div className="rule-options">
                    {value.map((rule, index) => {
                      const isActive =
                        rules
                          .find((rule) => rule.id === key)
                          ?.values.includes(rule.title.toUpperCase()) || false;
                      return (
                        <div
                          className={`option ${isActive ? 'active' : ''}`}
                          onClick={() =>
                            handleUpdateRules(key, rule.title.toUpperCase())
                          }
                          key={index}
                        >
                          {rule.title}
                          {rule.subTitle && <span>{rule.subTitle}</span>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="d-flex justify-content-between w-100"
              style={{
                marginTop: '44px'
              }}
            >
              <Button
                className="rounded-pill btn-clear"
                variant="outline-dark"
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  height: 36,
                  width: 132,
                  background: '#ffffff'
                }}
                onClick={handleResetRules}
              >
                Clear
              </Button>
              <Button
                className="rounded-pill"
                variant="dark"
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  height: 36,
                  width: 132
                }}
                onClick={handleConfirmRules}
              >
                Confirm
              </Button>
            </div>
          </div>
        </PopupContainer>
      )}
      <style jsx>{`
        .scene-wrapper {
          width: 100px;
          height: calc(100px * 0.5625);
          flex-basis: 100px;
          flex-shrink: 0;
        }
        .scene-wrapper img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        span {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #777777;
          font-size: 12px;
          font-weight: 400;
        }
        .gap-1 {
          gap: 1rem;
          overflow: hidden;
        }
        .scene-duration-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .scene-duration-wrapper > span {
          flex: unset;
          cursor: pointer;
        }
        .scene-info {
          width: 12px;
        }
        .scene-input-duration {
          width: 50%;
          border: 1px solid #777777;
          text-align: center;
          color: #777777;
        }
        .popup-container {
          padding: 12px 17px 9px 40px;
        }
        .btn-rules {
          color: #777777;
          border: 1px solid #777777;
          text-align: center;
          font-weight: 400;
          font-size: 12px;
          padding: 3px 24px;
        }
        .custom-close-btn {
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
        }
        .rules-wrapper .rule {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }
        .rules-wrapper .rule:not(:last-child) {
          margin-bottom: 30px;
        }
        .rules-wrapper .rule-gender {
          width: 30%;
          display: inline-block;
        }
        .rules-wrapper .rule-distance {
          width: 60%;
          display: inline-block;
          margin-left: 60px;
        }
        .rules-wrapper .label {
          color: #000000;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-transform: capitalize;
          margin-bottom: 10px;
        }
        .rules-wrapper .rule-options {
          display: flex;
          align-items: center;
          gap: 15px;
          flex-wrap: wrap;
        }
        .rules-wrapper .option {
          color: #252525;
          background-color: #ffffff;
          box-shadow: 3.52px 3.52px 7.04px 0px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          border-radius: 7px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-weight: 600;
          text-transform: capitalize;
        }
        .rules-wrapper .option > span {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          text-align: center;
          color: #a4a4a4;
          flex: none;
        }
        .rules-wrapper .option:hover {
          background-color: #000000;
          color: #ffffff;
        }
        .rule-distance .option,
        .rule-gender .option {
          width: 97px;
          height: 42px;
        }
        .rule-agegroup .option {
          width: 167px;
          height: 64px;
        }
        .rule-emotion .option {
          width: 129px;
          height: 42px;
        }
        .rules-wrapper .option.active {
          background-color: #000000;
          color: #ffffff;
        }
        :global(.btn-clear:hover) {
          color: #212529;
        }
      `}</style>
    </div>
  );
}

function ShowCasedScenes({
  scenes,
  onChange,
  loading
}: {
  scenes: ISceneDetail[];
  onChange: (scenes: ISceneDetail[]) => void;
  loading?: boolean;
}) {
  const onRemove = (id: string) => {
    const updated = scenes
      .filter((v) => v.id !== id)
      .map((scene, index) => ({
        ...scene,
        isDefaultScene: !index
      }));
    onChange(updated);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newOrder = changeContentOrder(scenes, oldIndex, newIndex);
    onChange(newOrder);
  };

  const onDefault = (id: string) => {
    const updatedScenes = scenes.map((scene) => ({
      ...scene,
      isDefaultScene: scene.id === id
    }));
    onChange(updatedScenes);
  };

  const onChangeDuration = (id: string, duration: string) => {
    const updated = scenes.map((v) => ({
      ...v,
      durationInSec: v.id === id ? duration : v.durationInSec || '0'
    }));
    onChange(updated);
  };

  const onChangeRules = (id: string, rule: string) => {
    const updated = scenes.map((v) => ({
      ...v,
      rule: v.id === id ? rule : v.rule || 'NA'
    }));
    onChange(updated);
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center flex-grow-1 justify-content-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  return (
    <React.Fragment>
      <SortableList
        helperClass="showcased-scene-list"
        onSortEnd={onSortEnd}
        lockAxis="y"
        useDragHandle
        {...{
          items: scenes,
          scenes: scenes,
          onRemove,
          onDefault,
          onChangeDuration,
          onChangeRules
        }}
      />
      <style jsx global>{`
        .showcased-scene-list {
          list-style: none;
          padding: 0;
          margin: 0;
          flex: 1;
          padding: 0;
          margin: 0;
          overflow-y: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 99999;
        }

        ul.showcased-scene-list li:nth-child(odd) {
          background-color: #f7f7f750;
        }
        .showcased-scene-item {
          gap: 5px;
          font-size: 14px;
          font-weight: 500;
          padding: 5px;
        }
        .showcased-scene-index {
          width: 18px;
          color: #777777;
        }
      `}</style>
    </React.Fragment>
  );
}

export default ShowCasedScenes;
