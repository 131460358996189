import React from 'react';
import { getProductDisplayName } from '../../../../utils/product';

function ProductEntry({
  product,
  actionBtn
}: {
  product: any;
  actionBtn: React.ReactNode | null;
}) {
  const name = getProductDisplayName(product, 'en');

  return (
    <div className="product-item">
      <div className="info">
        <div className="image-container">
          <img src={product.previewImageUrl} alt={name} />
        </div>
        <div className="product-details">
          <span className="product-code">{product.modelCode}</span>
          <span className="product-name" title={name}>{name}</span>
        </div>
      </div>
      <div className="action-container">{actionBtn}</div>
      <style jsx>{`
        .product-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          border-bottom: 1px solid #eee;
        }
        .info {
          display: flex;
          align-items: center;
          flex: 1;
          min-width: 0;
        }
        .image-container {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          flex-shrink: 0;
        }
        .image-container img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .product-details {
          display: flex;
          flex-direction: column;
          min-width: 0;
        }
        .product-code {
          font-size: 12px;
          color: #666;
        }
        .product-name {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .action-container {
          flex-shrink: 0;
          margin-left: 10px;
        }
      `}</style>
    </div>
  );
}

export default ProductEntry;
