import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import SearchInput from '../../Common/SearchInput';
import { cloneDeep, throttle, uniq } from 'lodash';
import {
  useAssetLibrary,
  useAssetLibraryFolder
} from '../../AssetLibrary/hook';
import { HubContext } from '../../HubContext';
import { IAsset, IMainState } from '../../../../interfaces';
import { SearchAssetOptions } from '../../../clientSideServices/assetLibrary';
import { AssetCategoriesDropdown } from '../../Polotno/AssetCategoriesDropDown';
import AssetThumbnail from '../../AssetLibrary/AssetThumbnail';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const DefaultSearchAssetOptions: SearchAssetOptions = {
  userId: '',
  size: 10,
  startIndex: 0,
  page: 1,
  sortKey: 'createdat',
  sortOrder: 'desc',
  assetTypes: ['video/mp4', 'video/quicktime']
};

interface AssetLibraryVideosProps {
  handleClick: (item: IAsset) => void;
  selectedAssets?: IAsset[];
}
export const AssetLibraryVideos = (props: AssetLibraryVideosProps) => {
  const { brandId, user } = useContext(HubContext);
  const [column, setColumn] = useState(3);
  const [searchOptions, setSearchOptions] = useState<SearchAssetOptions>({
    ...DefaultSearchAssetOptions
  });

  const { width } = useSelector(
    (state: IMainState) => state.clientState?.viewport
  );

  const [arr, setArr] = useState<IAsset[][]>([]);
  const [videos, setVideos] = useState([]);
  const { assetLibrary, searchLibAssets } = useAssetLibrary(
    brandId,
    user.id,
    true,
    searchOptions
  );

  const loading = assetLibrary?.loading;
  const hasMore = assetLibrary?.hasMore;
  const { folder: assetLibraryFolder } = useAssetLibraryFolder(
    brandId,
    user?.id,
    true
  );

  const handleLoadMore = useCallback(
    throttle((options: SearchAssetOptions) => {
      console.log('load more', options);
      options.startIndex = (options.page - 1) * options.size;
      searchLibAssets(options, 'end');
      setSearchOptions(options);
    }, 2000),
    []
  );
  const scrollContainerRef = useInfiniteScroll<HTMLDivElement>({
    loading,
    hasNextPage: hasMore,
    onLoadMore: () => {
      handleLoadMore({ ...searchOptions, page: searchOptions.page + 1 });
    }
  });

  useEffect(() => {
    if (width < 1024) {
      setColumn(2);
    } else {
      setColumn(3);
    }
  }, [width]);

  useEffect(() => {
    if (!videos?.length && !arr?.length) return;
    const divided = divideArray(cloneDeep(videos), column);
    setArr(divided);
  }, [videos, column]);

  const divideArray = (array, length) => {
    const newArray = cloneDeep(array);
    const results = [];
    for (let i = 0; i < length; i++) {
      const column = [];
      for (let y = i; y < newArray.length; y += length) {
        column.push(newArray[y]);
      }
      results.push(column);
    }
    return results;
  };

  useEffect(() => {
    const filteredVideos = assetLibrary?.data?.filter((asset) =>
      ['video/mp4', 'video/quicktime'].includes(asset.assetType)
    );
    setVideos(filteredVideos);
  }, [assetLibrary?.data]);

  const selectedAssetIds = props.selectedAssets?.map((a) => a.id);

  const containerRef = useRef<HTMLDivElement>();
  return (
    <div className="videos-section">
      <AssetCategoriesDropdown
        value={searchOptions.categories?.[0]}
        onChange={(cate) => {
          if (cate.id === 'ALL') {
            searchOptions.categories = undefined;
          } else {
            const cateFolder = assetLibraryFolder?.keys?.find(
              (k) => k.id === cate.id
            );
            const relevantCategories = uniq(
              assetLibraryFolder.keys
                ?.filter((k) => k.key.startsWith(cateFolder?.key))
                .map((k) => k.id)
            ) || [cate.id];

            searchOptions.categories = relevantCategories;
          }
          searchOptions.page = 1;
          searchOptions.startIndex = 0;
          searchLibAssets(searchOptions);
          setSearchOptions(searchOptions);
        }}
      />
      <div className="mb-3">
        <SearchInput
          onSearch={(val) => {
            searchOptions.keywords = [val];
            searchOptions.page = 1;
            searchOptions.startIndex = 0;
            searchLibAssets(searchOptions);
            setSearchOptions(searchOptions);
          }}
          rounded
          placeHolder="Search..."
          className="search-library-asset d-flex align-items-center"
        />
      </div>
      <div className="grid" ref={containerRef}>
        {!videos?.length ? (
          loading ? (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="empty-text">No results</div>
          )
        ) : (
          <div
            className="d-flex justify-content-center"
            ref={scrollContainerRef}
          >
            {arr.map((itm, i) => {
              return (
                <div key={i} className="masonry-column">
                  {itm?.map((item, idx) => (
                    <AssetThumbnail
                      key={item.id || idx}
                      item={item}
                      selected={selectedAssetIds?.includes(item.id)}
                      selectedAssets={selectedAssetIds}
                      onClick={() => props.handleClick(item)}
                      viewable={false}
                    />
                  ))}
                  {(loading || hasMore) && (
                    <div className="text-center">
                      <Spinner animation="border" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <style jsx>
        {`
          .videos-section {
            height: 100%;
            display: flex;
            flex-direction: column;
            max-width: 100%;
            flex: 1;
            overflow: hidden;
          }

          .grid {
            height: 100%;
            position: relative;
            overflow: auto;
            overscroll-behavior: none;
          }

          @media screen and (max-width: 950px) {
            :global(.search-library-asset) {
              min-width: 180px !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default AssetLibraryVideos;
