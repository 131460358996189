import React from 'react';
import { useDispatch } from 'react-redux';
import { NudgeCreationType } from '../../../interfaces';
import {
  actionHubClearNudge,
  actionHubCopyAsNewNudge,
  actionHubCreateNewNudge
} from '../../../redux/actions';
import { PageView } from '../../../utils/pageView';
import { hubNavTo } from '../../utils/hubPagesRoute';
import { getThemeColor } from '../BrandStyle';
import { HubContext } from '../HubContext';
import Layout from '../Layout';
import NudgeItemReorder from './NudgeItemReorder';
import NudgePreview, { TemplatePreviewAction } from './NudgePreview';
import NudgeSearch from './NudgeSearch';

export enum NudgeCreationState {
  PICK_ITEMS = 'PICK_ITEMS',
  REORDER = 'REORDER',
  PREVIEW = 'PREVIEW'
}

const NudgeCurator = ({
  creationType = NudgeCreationType.NEW
}: {
  creationType?: NudgeCreationType;
}) => {
  const dispatch = useDispatch();
  const { brandId } = React.useContext(HubContext);
  const color = getThemeColor(brandId);

  const [creationState, setCreationState] = React.useState<NudgeCreationState>(
    NudgeCreationState.PICK_ITEMS
  );

  const newNudge = creationState === NudgeCreationState.PICK_ITEMS;
  const reorder = creationState === NudgeCreationState.REORDER;
  const preview = creationState === NudgeCreationState.PREVIEW;

  const getSearchTitle = () => {
    switch (creationType) {
      case NudgeCreationType.EDIT_TEMPLATE:
        return 'EDIT YOUR TEMPLATE';
      case NudgeCreationType.COPY_TEMPLATE:
      case NudgeCreationType.NEW_TEMPLATE:
        return 'CREATE NUDGE TEMPLATE';
      default:
        return 'CURATE YOUR NUDGE';
    }
  };

  const isTemplate = [
    NudgeCreationType.NEW_TEMPLATE,
    NudgeCreationType.COPY_TEMPLATE,
    NudgeCreationType.EDIT_TEMPLATE
  ].includes(creationType);

  React.useEffect(() => {
    switch (creationType) {
      case NudgeCreationType.COPY_TEMPLATE:
      case NudgeCreationType.COPY: {
        dispatch(actionHubCopyAsNewNudge());
        break;
      }
      case NudgeCreationType.CREATE_FROM_TEMPLATE: {
        dispatch(actionHubCopyAsNewNudge());
        setCreationState(NudgeCreationState.REORDER);
        break;
      }
      case NudgeCreationType.NEW_TEMPLATE:
      case NudgeCreationType.NEW: {
        dispatch(actionHubCreateNewNudge());
        break;
      }
    }
  }, [creationType]);

  return (
    <>
      {(newNudge || reorder) && (
        <Layout
          showNav={false}
          theme={color.nudgeLightMode ? 'light' : 'dark'}
          className="layoutNudgeCurator"
        >
          <div className="page-body">
            <div className={`${reorder && 'hide'}`}>
              <NudgeSearch
                onNext={() => setCreationState(NudgeCreationState.REORDER)}
                onPrev={() => {
                  hubNavTo(
                    isTemplate
                      ? PageView.HUB_NUDGE_TEMPLATES
                      : PageView.HUB_NUDGES
                  );
                  dispatch(actionHubClearNudge());
                }}
                title={getSearchTitle()}
              />
            </div>
            {reorder && (
              <NudgeItemReorder
                onBack={() => setCreationState(NudgeCreationState.PICK_ITEMS)}
                onNext={() => {
                  setCreationState(NudgeCreationState.PREVIEW);
                }}
                isTemplate={isTemplate}
              />
            )}
          </div>
        </Layout>
      )}
      {preview && (
        <NudgePreview
          onBack={() => {
            setCreationState(NudgeCreationState.REORDER);
          }}
          templateAction={isTemplate ? TemplatePreviewAction.SAVE : undefined}
        />
      )}
      <style jsx>{`
        .hide {
          display: none;
        }

        .NudgeCurator.hide,
        :global(.layoutNudgeCurator.hide),
        :global(.layoutNudgeCurator .footer) {
          display: none;
        }

        :global(.layout.layoutNudgeCurator .page-body) {
          padding-bottom: 0 !important;
        }
        .container {
          max-width: 1800px;
        }

        :global(.layout.layoutNudgeCurator h1) {
          font-size: 1em !important;
          letter-spacing: 1px;
          margin-bottom: 20px;
          font-weight: 300 !important;
          text-transform: uppercase;
          color: ${color.accent};
        }
        @media (min-width: 768px) {
          :global(.layout.layoutNudgeCurator h1) {
            font-size: 1.4em !important;
          }
        }
      `}</style>
    </>
  );
};

export default NudgeCurator;
