import React from 'react';

export const useUndoRedo = <T>({
  dataToListen,
  onUndoRedo,
  shouldAddNewHistory,
  historyLimit = 20
}: {
  dataToListen?: T;
  onUndoRedo?: (arg: T) => void;
  shouldAddNewHistory?: boolean;
  historyLimit?: number;
}) => {
  const [history, setHistory] = React.useState(
    dataToListen ? [dataToListen] : []
  );
  const [currentPointer, setCurrentPointer] = React.useState(0);

  const updateHistory = (data: T) => {
    const copy = [...history];

    const shouldCreateNewHistory = copy.length === 1 || shouldAddNewHistory;

    const shouldRemoveFirstHistory = copy.length === historyLimit;

    if (shouldRemoveFirstHistory) {
      copy.shift();
    }
    const newPointer =
      shouldCreateNewHistory && !shouldRemoveFirstHistory
        ? currentPointer + 1
        : currentPointer;

    copy[newPointer] = data;
    setCurrentPointer(newPointer);
    setHistory(copy.filter((_, index) => index <= newPointer));
  };

  const updateHistoryPointer = (pointer: number) => {
    if (!dataToListen) return;
    setCurrentPointer(pointer);
    onUndoRedo(history[pointer]);
  };

  const handleUndo = (callback?: () => void) => {
    const newPointer = currentPointer - 1;
    if (newPointer >= 0) {
      updateHistoryPointer(newPointer);
      callback?.();
    }
  };

  const handleRedo = (callback?: () => void) => {
    const newPointer = currentPointer + 1;
    if (newPointer < history.length) {
      callback?.();
      updateHistoryPointer(newPointer);
    }
  };

  const resetUndoRedo = () => {
    setHistory([]);
    setCurrentPointer(0);
  };

  return {
    updateHistory,
    undo: currentPointer > 0 ? handleUndo : undefined,
    redo: currentPointer < history.length - 1 ? handleRedo : undefined,
    resetUndoRedo,
    currentPointer,
    historyLength: history.length
  };
};
