import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { HubContext } from '../HubContext';
import { getSimpleShortenedUrl } from '../../../utils/shareUrl';
import { logEvent } from '../../../analytics';
import { DID_CLICK_COPY_LINK } from '../../../utils/constants';

const SessionLink = ({
  role,
  sessionURL
}: {
  role: string;
  sessionURL: string;
}) => {
  const { color } = React.useContext(HubContext);
  const [copied, setCopied] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState(null);
  const ctaText = copied ? `Copied` : `Copy`;

  React.useEffect(() => {
    let isSubscribed = true;
    getSimpleShortenedUrl(sessionURL).then(
      (url) => isSubscribed && setShareUrl(url)
    );

    return () => {
      isSubscribed = false;
    };
  }, [sessionURL]);

  return (
    <div className="link-container">
      <div className="session-link">
        <label className="link-label">{role}: </label>
        <span className="share-url">{shareUrl}</span>
      </div>
      <CopyToClipboard text={shareUrl}>
        <button
          onClick={() => {
            setCopied(true);
            logEvent(DID_CLICK_COPY_LINK, DID_CLICK_COPY_LINK, {
              url: shareUrl
            });
          }}
          className="btn copy-btn btn-round btn-dark btn-sm"
        >
          {ctaText}
        </button>
      </CopyToClipboard>

      <style jsx>
        {`
          .link-container:fist-child {
            border-top: 1px solid #efefef;
          }
          .link-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            align-items: center;
            border-top: 1px solid #efefef;
          }

          .session-link {
            width: 100%;
            overflow: hidden;
            display: flex;
          }

          .share-url {
            width: calc(100% - 120px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .link-label {
            font-weight: 500;
            width: 120px;
            color: ${color?.accent};
          }

          @media (max-width: 480px) {
            .share-url {
              width: calc(100% - 80px);
            }

            .link-label {
              width: 80px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SessionLink;
