import { format } from 'date-fns';
import React from 'react';
import { RiAddCircleLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { getSessionUrl } from '../../../config';
import { IHubUser, IMainState, ISessionDetails } from '../../../interfaces';
import {
  DID_CLICK_ADD_TO_CALENDAR,
  DID_CLICK_JOIN_SESSION
} from '../../../utils/constants';
import { getSessionStartEndTime } from '../../../utils/session';
import { getSimpleShortenedUrl } from '../../../utils/shareUrl';
import {
  isEmbeddedInStreamingStudio,
  joinMeetingFromSsp
} from '../../../utils/streamingstudio';
import {
  getSessionCTA,
  getSessionUploadFileStatusIcon
} from '../componentFactory';

const SessionItem = ({
  session,
  storeId,
  onViewSession,
  isMobile,
  onRemoveSession,
  host
}: {
  session: ISessionDetails;
  storeId: string;
  onViewSession: (s: ISessionDetails) => void;
  isMobile?: boolean;
  onRemoveSession: (sessionId: string) => void;
  host?: string;
}) => {
  const [parsedStartTime, parsedEndTime] = getSessionStartEndTime(session);
  const [shortenedUrl, setShortenedUrl] = React.useState<any>();
  const sessionURL = getSessionUrl({
    storeId: storeId,
    sessionId: session?.id,
    region: session?.region,
    host
  });

  const user: IHubUser = useSelector(
    (state: IMainState) => state.clientState.hub?.user
  );

  const statusIcon = getSessionUploadFileStatusIcon(session);

  React.useEffect(() => {
    const getShortenedSessionUrl = async () => {
      return await Promise.all(
        Object.keys(sessionURL).map((role) =>
          getSimpleShortenedUrl(sessionURL[role]).then((url) => {
            return { [role]: url };
          })
        )
      );
    };
    getShortenedSessionUrl()
      .then((result) => {
        setShortenedUrl({ ...result[0], ...result[1], ...result[2] });
      })
      .catch((_) => setShortenedUrl(sessionURL));
  }, [session]);

  return (
    <div className="session-card">
      {statusIcon && <div className="status-icon">{statusIcon}</div>}
      <div className="session-time-wrapper">
        <div className="session-time">{format(parsedStartTime, 'HH:mm')}</div>
        <div className="session-time">{format(parsedEndTime, 'HH:mm')}</div>
      </div>
      <div className="session-details">
        <div className="session-title" onClick={() => onViewSession(session)}>
          {session?.topic}
        </div>
        <div className="session-desc">{session?.description}</div>
        <div className="join-btn">
          <div className="article">
            <a
              className="section join"
              onClick={(event) => {
                event.stopPropagation();
                logEvent(DID_CLICK_JOIN_SESSION, DID_CLICK_JOIN_SESSION, {
                  joinSessionId: session.id,
                  joinSessionUrl: sessionURL?.host
                });
                if (isEmbeddedInStreamingStudio()) {
                  joinMeetingFromSsp(
                    session,
                    user?.name || 'Sales Associate',
                    sessionURL?.host
                  );
                } else {
                  window.open(sessionURL?.host, '_blank');
                }
              }}
            >
              <span className="btn">Join</span>
            </a>
            <a className="section">
              <span className="vl"></span>
            </a>
            <a
              className="cross section"
              onClick={(event) => {
                event?.stopPropagation();
                onRemoveSession(session.id);
              }}
            >
              <span className="btn">X</span>
            </a>
          </div>
        </div>
        <button className="btn add-btn">
          <RiAddCircleLine />
          <a
            href={`/api/addToCalendar/${session.id}`}
            target="_blank"
            className="add-to-calendar-cta"
            onClick={(event) => {
              event.stopPropagation();
              logEvent(DID_CLICK_ADD_TO_CALENDAR, DID_CLICK_ADD_TO_CALENDAR, {
                meeting: session.id
              });
            }}
          >
            Add to Calendar
          </a>
        </button>
        {getSessionCTA(session, shortenedUrl, isMobile)}
      </div>
    </div>
  );
};

export default SessionItem;
