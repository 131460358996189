import React from 'react';
import PopupContainer from '../../Common/PopupContainer';
import { Modal, Spinner } from 'react-bootstrap';
import AssetLibraryVideos from './AssetLibraryVideos';
import ShowCasedVideoDropzone from './ShowCasedVideoDropzone';
import { IAsset } from '../../../../interfaces';
import { uploadDeviceVideo } from '../../../clientSideServices/device';
import {
  decodeS3FileName,
  getObjectFromS3
} from '../../../clientSideServices/aws';
import mime from 'mime';
import { HubContext } from '../../HubContext';

export type ILocalAsset = IAsset & { key: string; file: File };

function ShowCasedVideoPopup({
  onClose,
  deviceId,
  onChange
}: {
  onClose: () => void;
  deviceId: string;
  onChange: (videoUrls: string[]) => void;
}) {
  const { brandId } = React.useContext(HubContext);
  const [selectedAssets, setSelectedAssets] = React.useState<IAsset[]>([]);
  const [uploadedVideos, setUploadedVideos] = React.useState<ILocalAsset[]>([]);
  const [uploading, setUploading] = React.useState(false);

  const toggleSelectAsset = (asset: IAsset) => {
    setSelectedAssets((prev) => {
      if (prev.some((a) => a.id === asset.id)) {
        return prev.filter((a) => a.id !== asset.id);
      }
      return [...prev, asset];
    });
  };

  const downloadAndUploadToS3 = React.useCallback((asset: IAsset) => {
    const originBucket = 'storiez-data';
    const urlComponent = new URL(asset.originalURL);
    const assetKey = decodeS3FileName(
      asset.originalURL.replace(`${urlComponent.origin}/`, '')
    );
    const fileName = `${asset.title}.${mime.getExtension(
      mime.getType(assetKey)
    )}`;
    return getObjectFromS3(originBucket, assetKey)
      .then((data) => {
        return new File([data.Body as Blob], fileName, {
          type: asset.assetType
        });
      })
      .then((file) => {
        return uploadDeviceVideo({
          file,
          deviceId,
          brandId: brandId
        });
      });
  }, []);

  const uploadVideosToS3 = React.useCallback(
    async (videos: Array<ILocalAsset | IAsset>) => {
      const promises = videos.map((video) => {
        if ('file' in video) {
          console.log('upload file');
          return uploadDeviceVideo({
            file: video.file,
            deviceId,
            brandId: brandId
          });
        }
        return downloadAndUploadToS3(video);
      });
      const results = await Promise.all(promises);
      return results.map((r) => r.data?.url);
    },
    []
  );

  const onConfirm = () => {
    setUploading(true);
    uploadVideosToS3([...selectedAssets, ...uploadedVideos])
      .then((urls) => {
        onChange(urls);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setUploading(false);
        onClose();
      });
    console.log('selectedAssets', selectedAssets);
    console.log('uploadedVideos', uploadedVideos);
  };

  const saveable =
    (selectedAssets.length > 0 || uploadedVideos.length > 0) && !uploading;

  return (
    <Modal show={true} onHide={onClose} size="lg" animation={false}>
      <PopupContainer
        maxWidth="1280px"
        maxHeight="calc(100vh - 90px)"
        zIndex={9999}
      >
        <div className="popup-header d-flex w-100 mx-0 px-0">
          <h4>Upload Video</h4>
        </div>
        <hr className="w-100 p-0 m-0" />
        <div className="popup-body upload-container">
          <div className="upload">
            <ShowCasedVideoDropzone
              uploadAssets={uploadedVideos}
              onChange={setUploadedVideos}
              deviceId={deviceId}
            />
          </div>
          <div className="asset-lib">
            <AssetLibraryVideos
              handleClick={toggleSelectAsset}
              selectedAssets={selectedAssets}
            />
          </div>
        </div>
        <hr className="w-100 p-0 m-0" />
        <div className="popup-footer d-flex w-100">
          <button
            className="btn btn-round btn-outline"
            onClick={onClose}
            disabled={uploading}
          >
            Cancel
          </button>
          <button
            disabled={!saveable}
            className="btn btn-round btn-dark"
            onClick={onConfirm}
          >
            {uploading ? <Spinner animation="border" size="sm" /> : 'Confirm'}
          </button>
        </div>
        <style jsx>{`
          .upload-container {
            display: flex;
            gap: 10px;
            width: 100%;
            height: calc(100vh - 223px);
          }
          .popup-body {
            padding: 15px 0;
          }
          .popup-header {
            padding: 15px 10px 10px 20px;
          }
          .upload {
            width: 400px;
            display: flex;
            flex-direction: column;
          }
          .asset-lib {
            flex: 1;
            display: flex;
            flex-direction: column;
          }
          .popup-footer {
            justify-content: flex-end;
            gap: 10px;
            padding: 10px 0;
          }
          .btn-round {
            border-radius: 100px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            font-weight: 500;
          }
          .btn-dark {
            background: #000;
          }
        `}</style>
      </PopupContainer>
    </Modal>
  );
}

export default ShowCasedVideoPopup;
