import { isToday, format } from 'date-fns';
import React from 'react';

const ChatContactTimestamp = ({ timestamp }: { timestamp: string }) => {
  const timestampDate = new Date(timestamp);
  const isDateToday = isToday(timestampDate);
  return (
    <div>
      {isDateToday
        ? format(timestampDate, 'hh:mm aaa')
        : format(new Date(timestamp), 'd LLL')}
    </div>
  );
};

export default ChatContactTimestamp;
