import { getStoreIdFromUrl } from '../../../../analytics';
import { getInspirationsByIds } from '../../../../clientSideServices/inspirations';
import { getProductsByIds } from '../../../../clientSideServices/products';
import { getStoreById } from '../../../../clientSideServices/store';
import { IStore } from '../../../../interfaces';
import { apigClient } from '../../../clientSideServices/aws';

export type DeviceInsightOverviewFromSpaceQuery = {
  startDate: string;
  endDate: string;
  timeZone: string;
};

export type DeviceInsightOverviewQuery = {
  userId: string;
  startDate: string;
  endDate: string;
  timeZone: string;
};

export type ProductInsightFromDeviceQuery = {
  startDate: string;
  endDate: string;
  timeZone: string;
  productId: string;
  storeId?: string;
  deviceId?: string;
};

export type StoreInsightFromDeviceQuery = {
  userId: string;
  startDate: string;
  endDate: string;
  timeZone: string;
  storeId: string;
};

const insightApiUrl =
  'https://vvi8sqrrbk.execute-api.ap-southeast-1.amazonaws.com/prod';

export const mapDeviceProductOverview = async (data: any[]) => {
  const productIds = data.map((d) => d.productId);
  if (!productIds) return data;

  const products = await getProductsByIds(productIds);
  if (!products.data) return data;

  return data.map((d) => {
    const id = d.productId;
    if (!id) return d;

    const product = products.data.find((p) => p.id === id);
    return product ? { ...d, product } : d;
  });
};

export const mapDeviceStoriesOverview = async (data: any[]) => {
  const storiesIds = data.map((d) => d.storyId);
  if (!storiesIds.length) return data;

  const inspirations = (await getInspirationsByIds(storiesIds)).data;
  if (!inspirations) return data;

  return data.map((d) => {
    const id = d.storyId;
    if (!id) return d;

    const inspiration = inspirations.find((i) => i.id === id);
    return inspiration ? { ...d, store: inspiration } : d;
  });
};

export const getDeviceInsightOverview = async ({
  userId,
  startDate,
  endDate,
  timeZone
}: DeviceInsightOverviewQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getInsightsFromDevice';
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        userId,
        startDate,
        endDate,
        timeZone
      }
    },
    {}
  );
  const topProducts = result.data?.topProducts || [];
  const products = await mapDeviceProductOverview(topProducts);
  const stories = await mapDeviceStoriesOverview(result.data?.topStories || []);
  return {
    ...result.data,
    topProducts: products,
    topStories: stories
  };
};

export const getProductInsightsFromDeviceOverview = async ({
  userId,
  startDate,
  endDate,
  timeZone
}: DeviceInsightOverviewQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getProductInsightsFromDeviceOverview';
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        userId,
        startDate,
        endDate,
        timeZone
      }
    },
    {}
  );
  const products = result.data?.products || [];
  const mappedProducts = await mapDeviceProductOverview(products);
  return {
    ...result.data,
    products: mappedProducts
  };
};

export const getStoriesInsightsFromDeviceOverview = async ({
  userId,
  startDate,
  endDate,
  timeZone
}: DeviceInsightOverviewQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getStoriesInsightsFromDeviceOverview';
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        userId,
        startDate,
        endDate,
        timeZone
      }
    },
    {}
  );
  const mappedStories = await mapDeviceStoriesOverview(result.data?.stories);
  return {
    ...result.data,
    stories: mappedStories
  };
};

export const getProductsInsightsFromDevice = async ({
  startDate,
  endDate,
  timeZone,
  productId,
  storeId,
  deviceId
}: ProductInsightFromDeviceQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getProductsInsightsFromDevice';
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        timeZone,
        productId,
        storeId,
        deviceId
      }
    },
    {}
  );
  return result.data;
};

export const getStoriesInsightsFromDevice = async ({
  userId,
  startDate,
  endDate,
  timeZone,
  storeId
}: StoreInsightFromDeviceQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getStoriesInsightsFromDevice';
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        userId,
        startDate,
        endDate,
        timeZone,
        storeId
      }
    },
    {}
  );
  return result.data;
};

export const getUserIdFromDevice = async (deviceId: string) => {
  try {
    const path = `/users/v3/search/${encodeURIComponent(deviceId)}`;
    const client = await apigClient();
    const response = await client.invokeApi({}, path, 'GET', {});
    return response.data;
  } catch (error) {
    console.log(error.response);
    return Promise.reject(error?.response?.data);
  }
};

export const getInsightsFromSpace = async ({
  startDate,
  endDate,
  timeZone
}) => {
  const store = window['store'] as IStore | undefined;
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getInsightsFromSpace';
  const _storeId = getStoreIdFromUrl() || store?.id || window['storeId'];
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        timeZone,
        storeId: _storeId
      }
    },
    {}
  );
  const topProducts = result.data?.topProducts || [];
  const products = await mapDeviceProductOverview(topProducts);
  const stories = await mapDeviceStoriesOverview(result.data?.topStories || []);
  return {
    ...result.data,
    topProducts: products,
    topStories: stories
  };
};

export const getProductsInsightsFromSpaceOverview = async ({
  startDate,
  endDate,
  timeZone
}: DeviceInsightOverviewFromSpaceQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getProductsInsightsFromSpaceOverview';
  const store = window['store'] as IStore | undefined;
  const _storeId = getStoreIdFromUrl() || store?.id || window['storeId'];
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        timeZone,
        storeId: _storeId
      }
    },
    {}
  );
  const products = result.data?.products || [];
  const mappedProducts = await mapDeviceProductOverview(products);
  return {
    ...result.data,
    products: mappedProducts
  };
};

export const getProductsInsightsFromSpace = async ({
  startDate,
  endDate,
  timeZone,
  productId
}) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getProductsInsightsFromSpace';
  const store = window['store'] as IStore | undefined;
  const _storeId = getStoreIdFromUrl() || store?.id || window['storeId'];
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        timeZone,
        storeId: _storeId,
        productId
      }
    },
    {}
  );
  return result.data;
};

export const getStoriesInsightFromSpaceOverview = async ({
  startDate,
  endDate,
  timeZone
}: DeviceInsightOverviewFromSpaceQuery) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getStoriesInsightFromSpaceOverview';
  const store = window['store'] as IStore | undefined;
  const _storeId = getStoreIdFromUrl() || store?.id || window['storeId'];
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        timeZone,
        storeId: _storeId
      }
    },
    {}
  );
  const stories = result.data?.stories || [];
  const mappedStories = await mapDeviceStoriesOverview(stories);
  return {
    ...result.data,
    stories: mappedStories
  };
};

export const getStoriesInsightFromSpace = async ({
  startDate,
  endDate,
  timeZone,
  storyId
}) => {
  const client = await apigClient(insightApiUrl);
  const path = '/deviceAnalytics/v1/getStoriesInsightFromSpace';
  const store = window['store'] as IStore | undefined;
  const _storeId = getStoreIdFromUrl() || store?.id || window['storeId'];
  const result = await client.invokeApi(
    {},
    path,
    'GET',
    {
      queryParams: {
        startDate,
        endDate,
        timeZone,
        storeId: _storeId,
        storyId
      }
    },
    {}
  );
  return result.data;
};

export const getStore = (storeId?: string): Promise<IStore | null> => {
  if (window['store']) return Promise.resolve(window['store']);
  if (!storeId) return Promise.resolve(null);
  return new Promise((resolve, _) => {
    getStoreById(storeId)
      .then((result) => resolve(result.data))
      .catch((_) => resolve(null));
  });
};
