import { isEmpty } from 'lodash';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsX } from 'react-icons/bs';
import { PageView } from '../../../utils/pageView';
import { HubContext } from '../HubContext';
import Layout from '../Layout';
import ChatAvailabilityButton from './ChatAvailabilityButton';
import { HubChatContext } from './HubChatContext';

const ChatContainer = ({
  children,
  title
}: {
  children: React.ReactNode;
  title?: string;
}) => {
  const { page } = React.useContext(HubContext);
  const {
    isMobile,
    setSearchText,
    searchText,
    isFetchingConversations,
    conversations,
    conversationRequests
  } = React.useContext(HubChatContext);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchText(value);
  };

  const isChatDetailsPage = page === PageView.HUB_CHAT_DETAILS;
  const showSearchBar =
    !isChatDetailsPage &&
    !isFetchingConversations &&
    isMobile &&
    (!isEmpty(conversations) || !isEmpty(conversationRequests));
  return (
    <Layout showNav={false} theme="dark-light" className="layoutSessions">
      <div className="chat-container">
        <div className="top row">
          {!isChatDetailsPage && (
            <div className="col-12 chat-header-div">
              {title && <h1>{title}</h1>}
              <ChatAvailabilityButton theme="light" />
            </div>
          )}
          {showSearchBar && (
            <div
              className={`col-sm-12 col-md-4 col-lg-4 pr-0 search-wrapper ${
                isMobile && 'pl-0'
              }`}
            >
              <div className="input-group mt-3 search-box">
                <AiOutlineSearch color="#495057" className="search-icon" />
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search"
                  value={searchText}
                  onChange={onChangeInput}
                />
                {searchText && (
                  <BsX onClick={() => setSearchText('')} className="clear" />
                )}
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
      <style jsx>{`
        h1 {
          margin-bottom: 0 !important;
          text-align: left !important;
        }
        .chat-container {
          padding: 50px 20px;
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          min-height: inherit;
        }
        :global(.top) {
          position: relative;
        }

        .chat-header-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        :global(.ssp) .back-btn :global(svg) {
          width: 40px;
          height: auto;
        }

        :global(.chat-details-header) {
          justify-content: flex-end;
          margin-bottom: 20px;
        }

        .search-box {
          position: relative;
        }
        .search-box :global(.search-icon) {
          position: absolute;
          z-index: 5;
          top: 12px;
          left: 10px;
        }
        .search-input {
          border-radius: 20px !important;
          padding-left: 30px;
          padding-right: 30px;
        }
        .search-box :global(.clear) {
          position: absolute;
          z-index: 5;
          top: 12px;
          right: 10px;
        }

        @media (max-width: 480px) {
          .chat-container {
            padding: 30px 0;
            margin: 0;
            min-height: 0;
          }

          .chat-header-div {
            padding: 0;
          }

          :global(.top) {
            margin: 0;
            padding: 0 15px;
          }

          .search-wrapper {
            margin: 0;
            padding: 0 15px;
          }
        }

        @media (orientation: landscape) and (max-height: 376px) {
          .chat-container {
            padding-top: 0;
            padding-bottom: 20px;
          }

          .chat-container > .row {
            margin: 0;
          }

          .chat-header-div {
            padding: 0;
          }
        }
      `}</style>
    </Layout>
  );
};

export default ChatContainer;
