import React, { useContext } from 'react';
import { getCurrentUserInfo } from '../../../../clientSideServices/users';
import { PageView } from '../../../../utils/pageView';
import { HubContext } from '../../HubContext';
import TeamTour from './TeamTour';

const TeamGuid = () => {

  const [showDesignerTour, setShowDesignerTour] = React.useState<boolean>();
  const { setGuidedTour } = useContext(HubContext) || {};

  React.useEffect(() => {
    getCurrentUserInfo().then((userInfo) => {
      if (userInfo?.attributes?.['custom:gt_teams'] !== 'skipped') {
        setShowDesignerTour(true);
        setGuidedTour(PageView.HUB_TEAMS);
      }
    });
  }, []);
  return showDesignerTour ? <TeamTour onClose={() => setGuidedTour('')} /> : null;
};

export default TeamGuid;
