import { useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  getProductsByIds,
  performProductSearch
} from '../../../clientSideServices/products';
import { loubiAirwaysJpBoutiqueId } from '../../../config';
import {
  CloudSearchFilter,
  IMainState,
  INudgeItem,
  IProduct,
  NudgeItemType,
  ProductFamily,
  ProductType
} from '../../../interfaces';
import {
  getProductDisplayName,
  getProductsFilterByBlackAndWhitelist,
  getProductSpecificationsByLang
} from '../../../utils/product';
import { isProduct } from '../../utils/nudge';
import { getThemeColor } from '../BrandStyle';
import { HubContext } from '../HubContext';

interface Props {
  onSelect?: (item: INudgeItem) => void;
  keywords: string;
  brandId: string;
  selectedItems: INudgeItem[];
  onOpenPage: (path: string) => void;
  onSearchStart: () => void;
  onSearchLoaded: () => void;
  selectedProductFamilies: ProductFamily[];
  selectedProductSearchFilters: CloudSearchFilter[];
  language?: string;
  embeddedComponent?: (item: INudgeItem) => React.ReactNode;
}

const batchSize = 24;

const ProductSearch = ({
  onSelect,
  keywords,
  brandId,
  selectedItems,
  onOpenPage,
  onSearchStart,
  onSearchLoaded,
  selectedProductFamilies,
  selectedProductSearchFilters,
  language,
  embeddedComponent
}: Props) => {
  const color = getThemeColor(brandId);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const { storeId } = useContext(HubContext);

  const { productsFilterList } = useContext(HubContext);
  const showingOnlyFavorites = useSelector(
    (state: IMainState) => state.clientState.hub?.showingOnlyFavorites
  );
  const favoriteProductIds = useSelector((state: IMainState) =>
    state.clientState.hub?.favorites
      ?.filter((f) => f.itemType?.toLowerCase() === NudgeItemType.PRODUCT)
      ?.map((f) => f.itemId)
  );

  const isSelected = (id: string) => {
    return !!selectedItems.find((s) => s.id === id);
  };

  const selectedOrderNumber = useMemo(() => {
    const items = [];
    selectedItems.map((s, i) => (items[s.id] = i + 1));
    return items;
  }, [selectedItems]);

  const handleSelect = (item: INudgeItem) => {
    if (onSelect) onSelect(item);
  };

  const search = async (keepResult = false, _start?: number) => {
    if (keepResult) {
      setIsLoadingMore(true);
    } else {
      onSearchStart();
      setIsEmpty(false);
    }
    try {
      const start = keepResult ? _start || startIndex : _start || 0;
      const result = await performProductSearch(
        keywords,
        start,
        batchSize,
        brandId,
        selectedProductFamilies,
        selectedProductSearchFilters,
        storeId
      );
      if (!result.data.length) {
        setIsEmpty(true);
      }
      const hasMore = result.data.length === batchSize;
      const nextIndex = start + result.data.length;
      setHasNextPage(hasMore);
      setStartIndex(nextIndex);
      const productsFilter = getProductsFilterByBlackAndWhitelist(
        productsFilterList?.productBlacklist,
        productsFilterList?.productWhitelist
      );
      const newProducts = productsFilter(result.data || []);
      if (newProducts.length === 0 && hasMore) {
        return search(keepResult, nextIndex);
      }
      if (keepResult) {
        setSearchResult([...searchResult, ...newProducts]);
      } else {
        setSearchResult(newProducts);
      }
    } catch (e) {
      console.log(e);
    }
    onSearchLoaded();
    setIsLoadingMore(false);
  };

  useEffect(() => {
    if (showingOnlyFavorites) {
      setIsLoadingMore(true);
      getProductsByIds(favoriteProductIds)
        .then((result) => {
          setSearchResult(result.data);
          setIsLoadingMore(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoadingMore(false);
        });
      setIsLoadingMore;
    }
  }, [showingOnlyFavorites]);

  useEffect(() => {
    if (!showingOnlyFavorites) {
      search();
      return () => {
        setSearchResult([]);
      };
    }
  }, [
    keywords,
    selectedProductFamilies,
    selectedProductSearchFilters,
    showingOnlyFavorites
  ]);

  const getProductDescription = (item: IProduct) => {
    if (item.type === ProductType.HIGH_JEWELLERY) {
      const specifications = getProductSpecificationsByLang(item, language);
      return (
        <>
          <p className="main-shape">{specifications['mainStoneShape']}</p>
          <p className="main-carat">{specifications['mainStoneCarat']}</p>
          {getProductDisplayName(item, language)}
          <p className="modelCode">{item.modelCode}</p>
          <p className="prod-carat">{specifications['carat']}</p>
        </>
      );
    }
    return (
      <>
        <span className="modelCode">{item.modelCode}</span>
        {getProductDisplayName(item, language)}
      </>
    );
  };

  const itemComponent = (item: IProduct) => (
    <div
      key={item.id}
      className={`item col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 no-cursor`}
    >
      {embeddedComponent?.(item)}
      <img
        className={`${storeId === loubiAirwaysJpBoutiqueId ? 'light-bg' : ''}`}
        onClick={() => handleSelect(item)}
        src={item.previewImageUrl.replace('.jpeg', '.png')}
        alt={item.modelName}
      />
      <span
        onClick={() => onOpenPage(`/products/${item.id}`)}
        className="with-shadow desc"
      >
        {getProductDescription(item)}
      </span>
      <span
        className={`with-shadow selectIcon ${isSelected(item.id) && 'active'}`}
        onClick={() => handleSelect(item)}
      >
        {isSelected(item.id) && selectedOrderNumber[item.id]}
      </span>
      <style jsx>{`
        .item {
          position: relative;
          margin-bottom: 30px;
        }
        .selectIcon {
          position: absolute;
          top: 5px;
          right: 20px;
          font-size: 25px;
          min-width: 25px;
          height: 25px;
          line-height: 23px;
          font-size: 12px;
          font-family: Arial;
          border-radius: 100%;
          border: 2px solid ${color.nudgeLightMode ? '#ccc' : '#fff'};
        }

        .selectIcon.active {
          background: ${color.nudgeLightMode ? '#000' : '#fff'};
          color: ${color.nudgeLightMode ? color.light : color.dark};
        }
        img {
          width: 100%;
        }

        img.light-bg {
          background: #fff;
        }

        .desc {
          font-size: 0.7em;
          text-transform: uppercase;
          padding: 5px;
          color: ${color.nudgeLightMode ? color.dark : color.light};
          display: block;
          text-align: center;
          cursor: pointer;
        }

        :global(.desc > p) {
          margin-bottom: 10px;
        }
        :global(.desc > p.main-shape) {
          margin: 0;
        }

        :global(.desc .modelCode) {
          display: block;
          opacity: 0.8;
          font-size: 0.9em;
          margin-bottom: 5px;
          letter-spacing: 1px;
        }
      `}</style>
    </div>
  );

  const buttonMore = (
    <div style={{ width: '100%', padding: '10px 0 50px', textAlign: 'center' }}>
      <button className="load-more btn-dark" onClick={() => search(true)}>
        Load More
      </button>
    </div>
  );

  const loadingMore = (
    <div style={{ width: '100%', padding: '10px 0 50px', textAlign: 'center' }}>
      <Spinner animation="border" />
    </div>
  );

  return (
    <div className="ProductSearch">
      <div className="items">
        <div className="container">
          <div className="row">
            {searchResult.length !== 0 &&
              searchResult.map(
                (item) => isProduct(item) && itemComponent(item)
              )}
            {isEmpty && (
              <div className="search-empty">
                <span>Product not found.</span>
              </div>
            )}
            {searchResult.length !== 0 &&
              hasNextPage &&
              !isLoadingMore &&
              !showingOnlyFavorites &&
              buttonMore}
            {isLoadingMore && loadingMore}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSearch;
