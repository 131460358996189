import React from 'react';
import { SocialMediaType } from '../../../../interfaces';
import {
  FBProfile,
  getFacebookProfileByAccessToken
} from '../../../utils/facebook';
import { FacebookIcon } from '../../Common/Icons';

function FacebookProfile({
  token,
  onError,
  type = SocialMediaType.USER
}: {
  token: string;
  onError?: (error) => void;
  type?: SocialMediaType;
}) {
  const [facebookUser, setFacebookUser] = React.useState<FBProfile>();
  // const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const getProfile = () => {
    setLoading(true);
    return getFacebookProfileByAccessToken(token)
      .then((response) => {
        setFacebookUser(response);
      })
      .catch((err) => {
        onError?.(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (!token) return null;
  React.useEffect(() => {
    getProfile();
  }, []);

  const picture = facebookUser?.picture?.data?.url || '';
  if (loading) return <div>Loading...</div>;
  return (
    <>
      <div className="d-flex align-items-center flex-wrap">
        <div className="profile-picture">
          <img className="picture" src={picture} title="profile picture" />
          <FacebookIcon />
        </div>
        <div className="profile-name" title={facebookUser?.name}>
          {facebookUser?.name}
        </div>
        {type === SocialMediaType.PAGE && <div className="type">PAGE</div>}
      </div>
      <style jsx>
        {`
          .profile-picture {
            width: 50px;
            height: 50px;
            position: relative;
          }
          .type {
            margin-left: 10px;
            padding: 0.1em 0.5em;
            background-color: #d9d9d9;
            border-radius: 1em;
            font-size: 0.7em;
            font-weight: 700;
          }
          .profile-picture img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
          .profile-name {
            margin-left: 10px;
            font-size: 1.1em;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            flex: 1;
            text-overflow: ellipsis;
          }
          .profile-picture :global(svg) {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        `}
      </style>
    </>
  );
}

export default FacebookProfile;
