import React from 'react';
import UserEditButton from './UserEditButton';
import SelectCountryDialCode from './SelectCountryDialCode';
import { IUser, RecordingEntitlement } from '../../../interfaces';
import { checkIfNumber } from '../../../utils/string';
import {
  isAllowedForName,
  isValidEmail,
  isValidName,
  isValidPhoneNumber
} from '../../../components/VirtualBoutique/Appointment/inputValidator';
import { useTranslation } from '../../../i18n';
import { HubUserContext } from './HubUserContext';
import { HubContext } from '../HubContext';
import { MEET_ADMIN_USER, reqMeetUserType } from '../../utils/hubEntitlements';
import { isOnboardingBrand } from '../../../utils/window';

interface IEditProfileFormPristine {
  alias: boolean;
  email: boolean;
  first_name: boolean;
  last_name: boolean;
  mobileNumber: boolean;
}

function EditProfileForm({
  editedUser,
  handleFormChange,
  editMode,
  revertEdit,
  closeEditor
}: {
  editedUser: IUser;
  handleFormChange: (e) => void;
  editMode: boolean;
  revertEdit?: () => void;
  closeEditor?: () => void;
}) {
  const { t } = useTranslation();
  const { stores } = React.useContext(HubUserContext);
  const { isAdmin, user } = React.useContext(HubContext);
  const [pristine, setPristine] = React.useState<IEditProfileFormPristine>({
    alias: true,
    email: true,
    first_name: true,
    last_name: true,
    mobileNumber: true
  });
  const recordingOptions = [
    RecordingEntitlement.PRIVATE,
    RecordingEntitlement.SITE,
    RecordingEntitlement.BRAND
  ];
  const handleChange = (e) => {
    const field = e.target.id;
    setPristine({ ...pristine, [field]: false });
    handleFormChange(e);
  };
  const storeId = editedUser?.entitlements?.find(
    (ent) => ent.entitlementType === 'STORE_ID'
  );
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    if (value && !checkIfNumber(value)) {
      e.preventDefault();
      return;
    }

    setPristine({ ...pristine, mobileNumber: false });
    handleFormChange(e);
  };

  const handlePhoneNumberKeyPress = (e) => {
    if (!checkIfNumber(e.key)) {
      e.preventDefault();
      return;
    }
  };
  const invalidEmail = !pristine.email && !isValidEmail(editedUser?.email);
  const invalidAlias = !pristine.alias && !isValidName(editedUser.alias);
  const invalidPhoneCountry =
    editedUser.mobileNumber && !editedUser.mobilePhoneCountryCode;
  const invalidPhone =
    !pristine.mobileNumber &&
    editedUser.mobileNumber &&
    !isValidPhoneNumber(editedUser?.mobileNumber);
  const invalidFirstName =
    !pristine.first_name &&
    (!isValidName(editedUser.first_name) ||
      !isAllowedForName(editedUser?.first_name));
  const invalidLastName =
    !pristine.last_name &&
    (!isValidName(editedUser.last_name) ||
      !isAllowedForName(editedUser?.last_name));
  const isSaveAble =
    !invalidAlias &&
    !invalidEmail &&
    !invalidFirstName &&
    !invalidLastName &&
    !invalidPhoneCountry &&
    !invalidPhone;

  return (
    <React.Fragment>
      <div className="col-12">
        <div className="form-group">
          <label htmlFor="email">Space</label>
          {stores.length ? (
            <select
              disabled={!isAdmin || !editMode}
              className="form-control"
              defaultValue={storeId?.uuid || ''}
              onChange={handleChange}
              id="storeId"
            >
              {!storeId?.uuid && <option value="">SPACE</option>}
              {stores.map((store, index) => (
                <option key={store.id + index} value={store.id}>
                  {store.name}
                </option>
              ))}
            </select>
          ) : (
            <div className="form-control">Loading...</div>
          )}
        </div>
      </div>

      {!reqMeetUserType.includes(editedUser.userType) &&
        !isOnboardingBrand() &&
        user.userType !== MEET_ADMIN_USER && (
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="recordingOption">Recording Visibility</label>
              {stores.length ? (
                <select
                  disabled={!isAdmin || !editMode}
                  className="form-control"
                  defaultValue={
                    editedUser?.entitlements.find(
                      (obj) => obj.entitlementType === 'RECORDING_VISIBILITY'
                    )?.uuid || recordingOptions[0]
                  }
                  onChange={handleChange}
                  id="recordingOption"
                >
                  {recordingOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="form-control">Loading...</div>
              )}
            </div>
          </div>
        )}
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="alias">User Name</label>
          <input
            type="text"
            value={editedUser.user_name}
            className="form-control"
            id="user_name"
            placeholder="User Name"
            disabled={true}
          />
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="alias">Alias</label>
          <input
            type="text"
            value={editedUser.alias}
            className="form-control"
            id="alias"
            placeholder="Enter alias"
            disabled={!editMode}
            onChange={handleChange}
          />
          {invalidAlias && <span className="error"> Invalid alias</span>}
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="first_name">First Name</label>
          <input
            value={editedUser.first_name}
            type="text"
            className="form-control"
            id="first_name"
            disabled={!editMode}
            placeholder="Enter first name"
            onChange={handleChange}
          />
          {invalidFirstName && (
            <span className="error"> Invalid First Name</span>
          )}
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="last_name">Last Name</label>
          <input
            type="url"
            value={editedUser.last_name}
            className="form-control"
            id="last_name"
            placeholder="Enter last name"
            disabled={!editMode}
            onChange={handleChange}
          />
          {invalidLastName && <span className="error"> Invalid Last Name</span>}
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="mobilePhoneCountryCode">Mobile Phone</label>
          <div className="row">
            <div className="pr-0 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
              <SelectCountryDialCode
                disabled={!editMode}
                initalValue={editedUser.mobilePhoneCountryCode}
                onSelectChange={handleChange}
              />
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
              <input
                type="text"
                value={editedUser.mobileNumber}
                className="form-control"
                id="mobileNumber"
                placeholder="Enter mobile phone"
                disabled={!editMode}
                onChange={handlePhoneNumberChange}
                onKeyPress={handlePhoneNumberKeyPress}
              />
            </div>
          </div>
          {(invalidPhone || invalidPhoneCountry) && (
            <span className="error">
              Incorrect format / Country code is missing
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={editedUser.email}
            className="form-control"
            id="email"
            placeholder="Enter email"
            disabled={!editMode || isOnboardingBrand()}
            onChange={handleChange}
          />
          {invalidEmail && (
            <span className="error"> {t('vb:please_enter_valid_email')}</span>
          )}
        </div>
      </div>

      <div className="col-12">
        <UserEditButton
          user={editedUser}
          revertEdit={revertEdit}
          isSaveAble={isSaveAble}
          closeEditor={closeEditor}
        />
      </div>
      <style jsx>{`
        .form-group {
          position: relative;
        }
        .error {
          font-size: 0.56em;
          position: absolute;
          right: 0px;
          left: auto;
          top: 1px;
          color: rgb(177, 0, 0);
        }
        .input-checkbox-label {
          padding-left: 10px;
        }

        @media (min-width: 1024px) {
          .error {
            font-size: 0.625em;
          }
        }
      `}</style>
    </React.Fragment>
  );
}

export default EditProfileForm;
