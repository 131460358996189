import React from 'react';
import countries from './countrycodejson';
import { Form } from 'react-bootstrap';
function SelectCountryDialCode({
  selectId = 'mobilePhoneCountryCode',
  onSelectChange,
  disabled = false,
  initalValue
}: {
  selectId?: string;
  onSelectChange: (e) => void;
  disabled?: boolean;
  initalValue: string;
}) {
  return (
    <Form.Control
      disabled={disabled}
      as="select"
      id={selectId}
      defaultValue={initalValue}
      onChange={onSelectChange}
    >
      <option value="">CODE</option>
      {countries
        .sort((a, b) => {
          const sort =
            +a.dial_code?.substring(1)?.replace(/\s+/g, '') -
            +b.dial_code?.substring(1)?.replace(/\s+/g, '');
          return sort || -1;
        })
        .map((country, i) => {
          const dialCodeFormated = country.dial_code
            ?.substring(1)
            ?.replace(/\s+/g, '');
          return (
            <option key={i} value={dialCodeFormated}>
              {`+${dialCodeFormated}`}
            </option>
          );
        })}
    </Form.Control>
  );
}

export default SelectCountryDialCode;
