import React from 'react';
import DatePicker from 'react-datepicker';
import { enGB } from 'date-fns/locale';

import { ISessionFormData } from '../../../interfaces';
import addMinutes from 'date-fns/addMinutes';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

const DateTimeRangeSelector = ({
  setFormData,
  formData,
  invalidDateTime
}: {
  setFormData: (data: ISessionFormData) => void;
  formData: ISessionFormData;
  invalidDateTime: boolean;
}) => {
  const startDate = formData?.startDate;
  const endDate = formData?.endDate;
  const startTime = formData?.startTime;
  const endTime = formData?.endTime;
  const minStartTime = setHours(setMinutes(startTime, 0), 0);
  const maxStartTime = setHours(setMinutes(startTime, 30), 23);
  const minEndTime = addMinutes(startTime, 15);
  const maxEndTime = setHours(setMinutes(startTime, 45), 23);

  const handleDateChange = (date: Date) => {
    setFormData({
      ...formData,
      startDate: date,
      endDate: date
    });
  };

  const handleStartTime = (date: Date) => {
    setFormData({
      ...formData,
      startTime: date,
      endTime: addMinutes(date, 15)
    });
  };

  const handleEndTime = (date: Date) => {
    setFormData({
      ...formData,
      endTime: date
    });
  };

  return (
    <div className="wrapper">
      <div className="date-picker-wrapper mr-10">
        <DatePicker
          selected={startDate}
          onChange={(date) => handleDateChange(date)}
          className="form-control"
          dateFormat="dd/MMM/yyyy"
          locale={enGB}
        />
      </div>
      <div className="date-picker-wrapper">
        <DatePicker
          selected={startTime}
          onChange={(date) => handleStartTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          className="form-control time-picker"
          minTime={minStartTime}
          maxTime={maxStartTime}
        />
      </div>

      <span className="to-text">to</span>
      <div className="date-picker-wrapper mr-10">
        <DatePicker
          selected={endDate}
          onChange={(date) => handleDateChange(date)}
          className="form-control"
          dateFormat="dd/MMM/yyyy"
          locale={enGB}
        />
      </div>

      <div className="date-picker-wrapper">
        <DatePicker
          selected={endTime}
          onChange={(date) => handleEndTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          className="form-control time-picker"
          minTime={minEndTime}
          maxTime={maxEndTime}
        />
      </div>
      {invalidDateTime && (
        <span className="text-danger invalid-message">
          Invalid Date and Time
        </span>
      )}
      <style jsx>
        {`
          .wrapper {
            display: block;
            width: 100%;
          }

          .date-picker-wrapper {
            display: inline-block;
          }

          :global(.ssp) .date-picker-wrapper :global(input) {
            font-size: 0.8em;
          }

          .mr-10 {
            margin-right: 10px;
          }
          .to-text {
            margin: 0 10px;
          }
          .invalid-message {
            display: block;
          }
          @media (max-width: 768px) {
            .to-text {
              display: block;
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          .form-control.time-picker{
            width: 100px;
          }
          .react-datepicker__input-container input {
            width: 130px;
            text-transform: uppercase;
          }
        `}
      </style>
    </div>
  );
};

export default DateTimeRangeSelector;
