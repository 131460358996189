import { useTranslation } from 'next-i18next';
import React from 'react';
import { BiShoppingBag } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';
import { RiMessage3Fill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import ProductChatBubble from '../../../components/Chat/ProductChatBubble';
import ProductCompareBubble from '../../../components/Chat/ProductCompareBubble';
import ProductPersonalizeBubble from '../../../components/Chat/ProductPersonalizeBubble';
import StoryChatBubble from '../../../components/Chat/StoryChatBubble';
import {
  ChatMessageType,
  IChatMessage,
  IChatMessageBody
} from '../../../components/ChatConnectionContext';
import {
  IMainState,
  ISharingEntity,
  ProductType,
  Source
} from '../../../interfaces';
import { actionCloseVBPopup, actionOpenVBPopup } from '../../../redux/actions';
import {
  DID_CLICK_CHAT_SEND_MESSAGE,
  DID_CLICK_CHAT_SHARE,
  DID_CLICK_CHAT_SHARE_ITEM,
  DID_CLICK_PRODUCT_SEARCH_BUTTON,
  DID_CLICK_STORY_SEARCH_BUTTON,
  DID_SELECT_CHAT_AUTOFILL_MESSAGE,
  INS_SEND_TO_CHAT
} from '../../../utils/constants';
import { enc } from '../../../utils/crypt';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { getCurrentHost } from '../../../utils/window';
import { getChatStoryShareComponent } from '../componentFactory';
import { HubContext } from '../HubContext';
import ChatSnippet from './ChatSnippet';
import { HubChatContext } from './HubChatContext';

const ChatFooter = ({ conversationId }: { conversationId: string }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = `${i18n?.language || 'en'}`;
  const { brandId, storeId } = React.useContext(HubContext);
  const [messageText, setMessageText] = React.useState<string>('');
  const [showAutofill, setShowAutofill] = React.useState(false);
  const [sharingEntity, setSharingEntity] =
    React.useState<ISharingEntity>(null);
  const { onSendHubMessage, isMobile } = React.useContext(HubChatContext);
  const hubUser = useSelector(
    (state: IMainState) => state.clientState?.hub?.user
  );

  const handleTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setMessageText(value);
  };

  const sendMessage = (messageBody: IChatMessageBody) => {
    const encryptionId = generateV4UUID();
    messageBody.storeId = storeId;
    const encrypted = {
      id: encryptionId,
      content: enc(JSON.stringify(messageBody), encryptionId)
    };

    const payload: IChatMessage = {
      requestId: generateV4UUID(),
      userId: hubUser?.id,
      conversationId: conversationId,
      messageBody: JSON.stringify(encrypted)
    };
    onSendHubMessage(payload);
  };

  const handleEntityClick = (entity?: ISharingEntity) => {
    console.log(entity);
    logEvent(DID_CLICK_CHAT_SHARE_ITEM, DID_CLICK_CHAT_SHARE_ITEM, entity);
    setSharingEntity(entity);
  };

  const handleItemSend = (sharingEntity: ISharingEntity) => {
    logEvent(DID_CLICK_CHAT_SHARE, DID_CLICK_CHAT_SHARE, {
      type: sharingEntity.type,
      id: sharingEntity.content
    });

    const messageBody: IChatMessageBody = {
      content: sharingEntity.content,
      type: sharingEntity.type,
      timestamp: new Date()
    };

    sendMessage(messageBody);
    setSharingEntity(null);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSend();
      e.preventDefault();
      return;
    }
  };

  const onSelectChatSnippet = (snippet: { label: string; message: string }) => {
    setMessageText(snippet.message);
    logEvent(
      DID_SELECT_CHAT_AUTOFILL_MESSAGE,
      DID_SELECT_CHAT_AUTOFILL_MESSAGE,
      snippet
    );
  };

  const onSend = () => {
    if (messageText === '' && !sharingEntity) {
      return;
    }

    if (sharingEntity) {
      handleItemSend(sharingEntity);
      return;
    }

    logEvent(DID_CLICK_CHAT_SEND_MESSAGE, DID_CLICK_CHAT_SEND_MESSAGE, {
      type: ChatMessageType.PLAIN_TEXT
    });

    const messageBody: IChatMessageBody = {
      content: messageText,
      type: ChatMessageType.PLAIN_TEXT,
      timestamp: new Date()
    };

    sendMessage(messageBody);
    setMessageText('');
  };

  const onClickProduct = (productId: string) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/products/${productId}?source=${Source.Chat}${
          storeId ? `&store=${storeId}` : ''
        }&language=${language}`,
        true
      )
    );
  };

  const onClickStory = (storyId: string) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/inspirations/${storyId}?source=${Source.Chat}${
          storeId ? `&store=${storeId}` : ''
        }&language=${language}`,
        true
      )
    );
  };

  const openProductSearch = () => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/productsearch/${brandId}?source=${
          Source.Chat
        }&store=${storeId}&language=${language}`,
        true
      )
    );
  };

  const openStorySearch = () => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/storysearch/${brandId}?source=${
          Source.Chat
        }&store=${storeId}&language=${language}`,
        true
      )
    );
  };

  const openProductCompare = (productIds: string, compareType: ProductType) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/compare/${brandId}?source=${
          Source.Chat
        }&store=${storeId}&compareProducts=${productIds}&compareType=${compareType}&language=${language}`,
        true
      )
    );
  };

  const openProductPersonalize = (
    productId: string,
    strap: string,
    buckle: string
  ) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/personalize/${productId}?source=${
          Source.Chat
        }&store=${storeId}&strap=${strap}&buckle=${buckle}&language=${language}`,
        true
      )
    );
  };

  const closePreviewButton = (
    <div
      className="close"
      onClick={(e) => {
        setSharingEntity(null);
        e.stopPropagation();
      }}
    >
      <CgClose color="#fff" />
    </div>
  );

  const getSharingEntityComponent = () => {
    switch (sharingEntity?.type) {
      case ChatMessageType.PRODUCT:
        return (
          <div className="sharing-preview-wrapper">
            <ProductChatBubble
              left={false}
              id={sharingEntity?.content}
              onClickProduct={onClickProduct}
            />
            {closePreviewButton}
          </div>
        );
      case ChatMessageType.STORY:
        return (
          <div className="sharing-preview-wrapper">
            <StoryChatBubble
              left={false}
              id={sharingEntity?.content}
              onClickStory={onClickStory}
            />
            {closePreviewButton}
          </div>
        );
      case ChatMessageType.PRODUCT_COMPARE: {
        const [productType, joinedProductIds] = (
          sharingEntity?.content || ''
        ).split('::'); // FIXME: Hack, need to change type of `content`
        return (
          <div className="sharing-preview-wrapper">
            <ProductCompareBubble
              left={false}
              products={joinedProductIds?.split(',')}
              onClick={() => {
                openProductCompare(
                  joinedProductIds,
                  productType as ProductType
                );
              }}
            />
            {closePreviewButton}
          </div>
        );
      }
      case ChatMessageType.PRODUCT_PERSONALIZE: {
        const [productId, brandId, strap, buckle] = (
          sharingEntity?.content || ''
        ).split('::'); // FIXME: Hack, need to change type of `content`
        return (
          <div className="sharing-preview-wrapper personalize">
            <ProductPersonalizeBubble
              left={false}
              brandId={brandId}
              productId={productId}
              strap={strap}
              buckle={buckle !== 'undefined' ? buckle : undefined}
              onClick={() => {
                openProductPersonalize(productId, strap, buckle);
              }}
            />
            {closePreviewButton}
          </div>
        );
      }
      default:
        return null;
    }
  };

  React.useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      if (event.data?.messageType === INS_SEND_TO_CHAT) {
        handleEntityClick(event.data?.payload);
        dispatch(actionCloseVBPopup());
      }
    };
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  return (
    <>
      <div className="footer">
        {!sharingEntity && (
          <textarea
            className="message-input"
            value={messageText}
            placeholder="Write your message..."
            onChange={handleTextarea}
            onKeyPress={handleKeyPress}
          />
        )}
        {getSharingEntityComponent()}
        <div className={`action-bar  ${sharingEntity && 'shift-right'}`}>
          {!sharingEntity && (
            <div className="chat-options">
              <div className="action-btn-wrapper">
                <button
                  className="btn action-btn"
                  onClick={() => {
                    openProductSearch();
                    logEvent(DID_CLICK_PRODUCT_SEARCH_BUTTON);
                  }}
                >
                  <BiShoppingBag size={isMobile ? `1.2rem` : `1.4rem`} />
                </button>
                <span className="action-btn-label">Share Product</span>
              </div>
              {getChatStoryShareComponent(hubUser?.brandId, () => {
                openStorySearch();
                logEvent(DID_CLICK_STORY_SEARCH_BUTTON);
              })}

              <div
                className="action-btn-wrapper"
                onMouseLeave={() => setShowAutofill(false)}
              >
                <button
                  className={`btn action-btn ${showAutofill ? 'active' : ''}`}
                  onClick={() => {
                    setShowAutofill(!showAutofill);
                    logEvent(
                      DID_SELECT_CHAT_AUTOFILL_MESSAGE,
                      DID_SELECT_CHAT_AUTOFILL_MESSAGE,
                      { openSnippetPopup: !showAutofill }
                    );
                  }}
                >
                  <RiMessage3Fill size={isMobile ? `1.2rem` : `1.4rem`} />
                </button>
                <span className="action-btn-label">Snippets</span>

                {showAutofill && (
                  <div className="snippet-popup">
                    {/* TO-DO: assign language props */}
                    <ChatSnippet
                      onSelect={onSelectChatSnippet}
                      onClose={() => setShowAutofill(false)}
                      currentMessage={messageText}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <button onClick={() => onSend()} className="btn btn-dark">
            SEND
          </button>
        </div>
      </div>
      <style jsx>
        {`
          .btn.focus,
          .btn:focus {
            box-shadow: none;
          }

          .footer {
            padding: 18px 20px;
          }

          .message-input {
            width: 100%;
            border: none;
            outline: none;
            resize: none;
          }

          .action-bar {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
          }

          .action-bar.shift-right {
            margin-top: 5px;
            justify-content: flex-end;
            position: absolute;
            bottom: 18px;
            right: 35px;
          }

          .chat-options {
            display: flex;
            align-items: center;
          }

          :global(.action-btn-wrapper) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: 13px;
            position: relative;
          }

          :global(.sharing-preview-wrapper) {
            position: relative;
          }

          :global(.sharing-preview-wrapper .close) {
            position: absolute;
            left: 206px;
            top: -8px;
            background: #000000;
            z-index: 1;
            border-radius: 100%;
            cursor: pointer;
            font-size: 1.2rem;
          }

          .snippet-popup {
            position: absolute;
            bottom: 0;
            width: 320px;
            left: 50%;
            transform: translateX(-50%);
          }

          :global(.action-btn-label) {
            color: #c4c4c4;
            font-size: 8px;
            line-height: 10px;
            font-weight: normal;
            margin-top: 5px;
          }

          :global(.action-btn) {
            border: 1px solid #c4c4c4;
            border-radius: 10px;
            color: #c4c4c4;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: inherit;
            padding: 8px;
          }
          :global(.action-btn.active) {
            border: 1px solid #000;
            border-radius: 10px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: inherit;
            padding: 8px;
          }
          @media (max-width: 480px) {
            .btn {
              font-size: 12px;
              line-height: 15px;
            }

            .footer {
              padding: 10px;
            }

            :global(.action-btn-label) {
              font-size: 6px;
              line-height: 7px;
            }

            .action-bar {
              align-items: center;
            }

            :global(.sharing-preview-wrapper .close) {
              left: 168px;
              top: -6px;
            }

            :global(.sharing-preview-wrapper.personalize .close) {
              left: 206px;
              top: -8px;
            }

            .action-bar.shift-right {
              right: 10px;
            }

            :global(.action-btn-wrapper) {
              position: initial;
            }

            :global(.snippet-popup) {
              bottom: 20px !important;
            }
          }

          @media (orientation: landscape) and (max-height: 376px) {
            .footer {
              overflow-y: auto;
            }
          }
        `}
      </style>
    </>
  );
};

export default ChatFooter;
