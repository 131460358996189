import React, { useMemo } from 'react';
import { ITableColumn, SortTable } from '../../Common/Table';
import { formatDurationFromMiliSeconds } from '../../../../utils/number';
import ImageWithWebp from '../../Common/ImageWithWebp';
import { ISLSelectItem } from '.';
import { uniqueId } from 'lodash';

type SLAnalyticsStorybookProps = {
  handleSelectItem: (item: ISLSelectItem) => void;
  data: any;
  loading: boolean;
};
const SLAnalyticsStorybook = ({
  handleSelectItem,
  data = [],
  loading
}: SLAnalyticsStorybookProps) => {
  const isLoading = loading === true;
  const storybookCol: ITableColumn[] = [
    {
      key: 'storybook',
      title: 'Storybook',
      align: 'left',
      width: '40%',
      custom: (value, rowIndex) => {
        return (
          <div className="scenes_order">
            <span>{rowIndex + 1}</span>
            {value?.thumbnail ? (
              <ImageWithWebp src={value.thumbnail} />
            ) : (
              <div className="notfound-thumbnail" />
            )}
            <p>
              <span title={value.title || ''}>{value.title}</span>
              <span title={value.title || ''}>{value.subtitle}</span>
            </p>
          </div>
        );
      }
    },
    {
      key: 'uniqueViews',
      title: 'Viewers',
      align: 'center'
    },
    {
      key: 'totalViews',
      title: 'Views',
      align: 'center'
    },
    {
      key: 'averageEyeballTime',
      title: 'Average Time Spent',
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    },
    {
      key: 'clicks',
      title: 'Clicks',
      align: 'center'
    },
    {
      key: 'shares',
      title: 'Share',
      align: 'center'
    }
  ];

  const handleSelectRow = (row) => {
    handleSelectItem({
      type: 'storybook',
      id: row.storybook.id,
      name: row.storybook.title,
      thumbnail: row.storybook.thumbnail
    });
  };

  const memoData = useMemo(() => {
    return data.map((d) => {
      return {
        storybook: {
          title: d.storybook?.title || 'Untitled',
          subtitle: d.storybook?.subtitle,
          thumbnail: d.storybook?.featuredImage,
          id: d.storybook?.id || uniqueId()
        },
        uniqueViews: d.uniqueViews,
        totalViews: d.totalViews,
        averageEyeballTime: d.averageEyeballTime,
        clicks: d.clicks,
        shares: d.shares
      };
    });
  }, [data]);
  return (
    <>
      <div className="analytics_scenes" data-isloading={isLoading}>
        <SortTable
          columns={storybookCol}
          sorts={[
            'uniqueViews',
            'totalViews',
            'averageEyeballTime',
            'clicks',
            'shares'
          ]}
          data={memoData || []}
          onlyActiveSortBy
          onRowSelect={handleSelectRow}
          stripped
        />
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>
      <style jsx>{`
        .analytics_scenes[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }

        .analytics_scenes[data-isloading='true'] .overlay {
          display: flex;
        }

        :global(.analytics_scenes .sort-table td) {
          font-weight: 500;
          color: #252525;
        }
      `}</style>
    </>
  );
};

export default SLAnalyticsStorybook;
