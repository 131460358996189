
import { IEntitlement } from '../../interfaces';
import { UserEntitlementType } from '../components/HubContext';

export type RecordingEntitlement = 'BRAND' | 'SITE' | 'PRIVATE';

export const getUserEntitlementByType = (
  entitlements?: IEntitlement[],
  type?: UserEntitlementType
): string[] | RecordingEntitlement => {
  const entitlementList = entitlements
    ?.filter((entitlement) => entitlement?.entitlementType === type)
    ?.map((entries) => entries.uuid);

  if (type === UserEntitlementType.RECORDING_VISIBILITY) {
    if (['BRAND', 'SITE', 'PRIVATE'].includes(entitlementList?.[0])) {
      return entitlementList[0] as RecordingEntitlement;
    } else {
      return 'PRIVATE' as RecordingEntitlement;
    }
  }
  return entitlements
    ?.filter((entitlement) => entitlement?.entitlementType === type)
    ?.map((entries) => entries.uuid);
};