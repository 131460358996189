import React from 'react';
import { Table, Modal, Pagination } from 'react-bootstrap';
import { CgMathPlus } from 'react-icons/cg';
import { FcSettings } from 'react-icons/fc';
import { MdSearch } from 'react-icons/md';
import { logEvent } from '../../../analytics';
import { IUser } from '../../../interfaces';
import {
  DID_CLICK_CREATE_USER,
  DID_CLICK_PAGINATION
} from '../../../utils/constants';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import { mobileBreakPoint } from '../BrandStyle';
import CreateUserForm from './CreateUserForm';
import EditProfile from './EditProfile';
import { HubUserContext } from './HubUserContext';
interface Editor {
  edit: boolean;
  user?: IUser;
}
const resultOnPage = 10;

function AdminPage() {
  const { fetchUsersByAdmin, users, setIsAdminPage } =
    React.useContext(HubUserContext);
  const [filterUser, setFilterUser] = React.useState<IUser[]>(users);
  const [page, setPage] = React.useState(1);
  const searchRef = React.useRef<HTMLInputElement>();
  const [createUser, setCreateUser] = React.useState(false);
  const [editor, setEditor] = React.useState<Editor>({
    edit: false
  });
  const closeEditor = () => {
    setEditor({
      ...editor,
      edit: false
    });
  };
  const [usingMobile, setUsingMobile] = React.useState(false);
  const openEditor = (user: IUser) => {
    setEditor({
      edit: true,
      user: user
    });
  };
  React.useEffect(() => {
    fetchUsersByAdmin();
    setUsingMobile(isUserOnMobile());
    setIsAdminPage(true);
  }, []);
  React.useEffect(() => {
    setFilterUser(users);
  }, [users]);
  const onSearchUser = () => {
    const keyword = searchRef.current?.value?.toLowerCase();
    if (keyword) {
      const filter = (user: IUser) => {
        return (
          user.email?.toLowerCase().includes(keyword) ||
          user.alias?.toLowerCase().includes(keyword) ||
          user.first_name?.toLowerCase().includes(keyword) ||
          user.last_name?.toLowerCase().includes(keyword) ||
          user.mobileNumber?.toLowerCase().includes(keyword) ||
          user.user_name?.toLowerCase().includes(keyword) ||
          user.mobilePhoneCountryCode?.toLowerCase().includes(keyword)
        );
      };
      setFilterUser(users.filter(filter));
      setPage(1);
    } else setFilterUser(users);
  };
  const getPaginations = () => {
    const paginations = [];
    const maxPage = Math.ceil(filterUser.length / resultOnPage);
    const start = page < 2 ? page : page - 1;
    const end =
      page + 1 > maxPage ? page : page === 1 && maxPage >= 3 ? 3 : page + 1;
    for (let number = start; number <= end; number++)
      paginations.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => {
            logEvent(DID_CLICK_PAGINATION, DID_CLICK_PAGINATION, {
              list: 'User list',
              page: number
            });
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    const isFirstPage = page === 1;
    const isLastPage = page === maxPage || filterUser.length === 0;
    const prevPage = () => {
      logEvent(DID_CLICK_PAGINATION, DID_CLICK_PAGINATION, {
        list: 'User list',
        page: page - 1
      });
      if (!isFirstPage) setPage(page - 1);
    };
    const nextPage = () => {
      logEvent(DID_CLICK_PAGINATION, DID_CLICK_PAGINATION, {
        list: 'User list',
        page: page + 1
      });
      if (!isLastPage) setPage(page + 1);
    };
    const from = Math.min((page - 1) * resultOnPage + 1, filterUser.length);
    const to = Math.min(
      (page - 1) * resultOnPage + resultOnPage,
      filterUser.length
    );
    return (
      <div className="users-pagination mt-2 d-flex justify-content-between align-items-center">
        <span className="paginate-info">{`Showing ${from} to ${to} of ${filterUser.length} users`}</span>
        <Pagination>
          <Pagination.First
            disabled={isFirstPage}
            onClick={() => {
              logEvent(DID_CLICK_PAGINATION, DID_CLICK_PAGINATION, {
                list: 'User list',
                page: 1
              });
              setPage(1);
            }}
          />
          <Pagination.Prev disabled={isFirstPage} onClick={prevPage} />
          {paginations}
          <Pagination.Next disabled={isLastPage} onClick={nextPage} />
          <Pagination.Last
            disabled={isLastPage}
            onClick={() => {
              logEvent(DID_CLICK_PAGINATION, DID_CLICK_PAGINATION, {
                list: 'User list',
                page: maxPage
              });
              setPage(maxPage);
            }}
          />
        </Pagination>
      </div>
    );
  };
  if (!filterUser) return <div className="text-center">Fetching user...</div>;
  const settingField = (user?: IUser) => {
    const column = (
      <td className="text-center">
        <span className="edit-user" onClick={() => openEditor(user)}>
          <FcSettings size={20} />
        </span>
      </td>
    );
    const head = <th>SETTINGS</th>;
    const headMobile = <th> </th>;
    return {
      column,
      head,
      headMobile
    };
  };
  const onCloseCreateModal = () => {
    setCreateUser(false);
  };

  return (
    <>
      <div className="admin-page container">
        <div>
          <h5 className="mb-4">USERS MANAGEMENT</h5>
        </div>
        <div className="search-bar pt-2 pb-2 row">
          <div className="search-user col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 d-flex align-items-center">
            <label htmlFor="search-user" className="mr-2 mb-0">
              <MdSearch size={24} />
            </label>
            <input
              id="search-user"
              ref={searchRef}
              className="form-control"
              placeholder="Search user"
              onChange={onSearchUser}
            />
          </div>
          <div className="d-flex justify-content-end col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <button
              className="btn btn-dark btn-create-user"
              onClick={() => {
                setCreateUser(true);
                logEvent(DID_CLICK_CREATE_USER);
              }}
            >
              <CgMathPlus />
              CREATE USER
            </button>
          </div>
        </div>
        <div className="table-wrapper mt-3">
          <Table hover responsive>
            <thead className="table-head mb-2">
              <tr className="text-center ">
                {usingMobile && settingField().headMobile}
                <th>ALIAS</th>
                <th>EMAIL</th>
                <th>USER NAME</th>
                {!usingMobile && settingField().head}
              </tr>
            </thead>
            <tbody>
              {filterUser
                .slice(
                  (page - 1) * resultOnPage,
                  (page - 1) * resultOnPage + resultOnPage
                )
                .map((user) => (
                  <tr key={user.id} className="user-tr text-center">
                    {usingMobile && settingField(user).column}
                    <td title={user.alias}>{user.alias}</td>
                    <td title={user.email}>{user.email}</td>
                    <td title={user.user_name}>{user.user_name}</td>
                    {!usingMobile && settingField(user).column}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        {filterUser.length > resultOnPage && getPaginations()}
      </div>
      {users.length && (
        <Modal
          backdrop="static"
          centered
          dialogClassName="modal-edit-user"
          show={editor.edit}
          onHide={closeEditor}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h4 className="mb-4">ACCOUNT DETAILS</h4>
            <EditProfile user={editor.user} closeEditor={closeEditor} />
          </Modal.Body>
        </Modal>
      )}
      <Modal
        backdrop="static"
        centered
        dialogClassName="modal-create-user"
        show={createUser}
        onHide={onCloseCreateModal}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CreateUserForm onClose={onCloseCreateModal} />
        </Modal.Body>
      </Modal>
      <style jsx global>
        {`
          .admin-page {
            padding-top: 60px;
          }
          .search-user {
            position: relative;
          }
          .search-user label {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
          .search-user input {
            border-radius: 50px;
            padding-left: 40px;
          }
          .admin-page .edit-user {
            padding: 8px;
            cursor: pointer;
          }
          .admin-page .user-tr td {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            background: #f8f8f8;
          }
          .modal-create-user input.form-control,
          .modal-edit-user input.form-control,
          .modal-create-user select.form-control {
            border-radius: 50px;
            border: #c4c4c4 solid 0.3px;
          }
          .modal-edit-user,
          .modal-create-user {
            width: 750px;
            max-width: 100% !important;
            border-radius: 50px;
          }
          .modal-header {
            border-bottom: none;
          }
          .layout .admin-page tr:nth-child(even) td {
            background: #f8f8f8;
          }
          .layout .admin-page tr:nth-child(odd) td {
            background: #ebebeb;
          }
          .admin-page .table-wrapper {
            min-height: 530px;
          }
          .admin-page .page-link {
            border: 0;
          }
          .admin-page .page-item.active .page-link {
            background: #c4c4c4;
            color: black;
            font-weight: 600;
          }
          .admin-page .table-wrapper .table-head tr th {
            border-top: 2px solid #000;
            border-bottom: 2px solid #000;
          }
          .admin-page .btn.btn-dark.btn-create-user {
            border-radius: 50px;
            line-height: 100%;
          }
          @media (max-width: ${mobileBreakPoint}px) {
            .admin-page .users-pagination {
              flex-direction: column;
            }
            .admin-page .search-bar .justify-content-end {
              justify-content: center !important;
              margin-top: 10px;
            }
          }
        `}
      </style>
    </>
  );
}

export default AdminPage;
