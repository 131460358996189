import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LoginStatus } from '../../../interfaces';
import { actionHubUpdateLoginStatus } from '../../../redux/actions';
import { getThemeColor, mobileBreakPoint } from '../BrandStyle';
import LoaderButton from '../Common/LoaderButton';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { isOnboardingBrand } from '../../../utils/window';
import { isValidEmailId } from '../../../components/VirtualBoutique/Appointment/inputValidator';
import { EXPIRY } from '../../../utils/constants';

function ResetPwdForm({ brandId }: { brandId: string }) {
  const color = getThemeColor(brandId);
  const [error, setError] = React.useState('');
  const [fields, setFields] = React.useState({
    username: '',
    email: '',
    password: '',
    confirmpassword: '',
    code: '',
    completeUserName: false,
    loading: false,
    resending: false,
    completeResetPwd: false
  });
  const [visiblePassword, setVisiblePassword] = React.useState({
    password: false,
    confirmpassword: false
  });
  const dispatch = useDispatch();
  const handleFormChange = (e) => {
    setError('');
    const field = e.target.id;
    setFields({ ...fields, [field]: e.target.value });
  };
  const [pristine, setPristine] = React.useState(true);

  const handleSendRequest = (e) => {
    e?.preventDefault();
    setFields({ ...fields, loading: true });
    const isValidated = userNameInput.validate(fields.username);
    if (isValidated) {
      Auth.forgotPassword(fields.username)
        .then((res) => {
          setFields({
            ...fields,
            email: res.CodeDeliveryDetails.Destination,
            completeUserName: true,
            loading: false
          });
        })
        .catch(() => {
          setError('Incorrect user name');
          setFields({ ...fields, loading: false });
        });
    } else {
      setError(
        `Please enter a valid ${userNameInput.lable.toLocaleLowerCase()}`
      );
      setFields({ ...fields, loading: false });
    }
    setPristine(false);
  };
  const validateForm = () => {
    const { code, password, confirmpassword } = fields;
    return (
      code.length > 0 && password.length > 0 && password === confirmpassword
    );
  };
  const checkUserName = (name: string) => {
    const spacing = name.includes(' ');
    return !spacing && name.length > 3;
  };
  const handleResetPwdSubmit = (e) => {
    setError('');
    e?.preventDefault();
    const { username, code, password } = fields;
    setFields({ ...fields, loading: true });
    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        setFields({
          ...fields,
          loading: false,

          completeResetPwd: true
        });
        setError('');
      })
      .catch((err) => {
        setError(err.message);
        setFields({ ...fields, loading: false });
      });
  };
  const handleBackToLogin = () => {
    dispatch(actionHubUpdateLoginStatus(LoginStatus.LOG_IN));
  };

  const userNameInput = React.useMemo(
    () =>
      isOnboardingBrand()
        ? {
            lable: 'Email',
            placeholder: 'Enter your email',
            type: 'email',
            validate: isValidEmailId
          }
        : {
            lable: 'User name',
            placeholder: 'Enter your user name',
            type: 'text',
            validate: checkUserName
          },
    []
  );

  const isValidUserName = React.useMemo(() => {
    return pristine || userNameInput.validate(fields.username);
  }, [fields.username, userNameInput, pristine]);

  useEffect(() => {
    localStorage.removeItem(EXPIRY);
  }, []);

  const renderUserNameForm = () => {
    return (
      <div className="page-body ResetPwd">
        <div className="container">
          <h1>
            RESET PASSWORD
            <i className="separator" />
          </h1>
          <p className="accent">
            Please type in your {userNameInput.lable} to reset password
          </p>
          <div className="form-container">
            {error && <div className="alert alert-danger">{error}</div>}
            <div className={`form-group text-group`}>
              <label htmlFor="username">
                {userNameInput.lable}
                {!isValidUserName && (
                  <span className="input-error">- Incorrect format</span>
                )}
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={fields.username}
                placeholder={userNameInput.placeholder}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group">
              <LoaderButton
                isLoading={fields.loading}
                disabled={!isValidUserName}
                className="btn btn-dark btn-send-rq"
                onClick={handleSendRequest}
              >
                SEND REQUEST PASSWORD TO EMAIL
              </LoaderButton>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleResendCode = () => {
    setFields({ ...fields, resending: true });
    Auth.forgotPassword(fields.username)
      .then((res) => {
        setFields({
          ...fields,
          email: res.CodeDeliveryDetails.Destination,
          completeUserName: true,
          resending: false
        });
        setError('');
      })
      .catch((err) => {
        setError(err.message);
        setFields({ ...fields, resending: false });
      });
  };
  const getEyeIcon = (field: string) => {
    if (!fields[field]) return null;
    const visible = visiblePassword[field];
    const onClick = () =>
      setVisiblePassword({
        ...visiblePassword,
        [field]: !visible
      });
    const configIcon = {
      className: 'ml-1',
      size: 20,
      fill: '#343434',
      onClick
    };
    return visible ? (
      <AiOutlineEye {...configIcon} />
    ) : (
      <AiOutlineEyeInvisible {...configIcon} />
    );
  };
  const renderCodeForm = () => {
    return (
      <div className="page-body ResetPwd">
        <div className="container">
          <h1>
            EMAIL SENT
            <i className="separator" />
          </h1>
          <p className="accent">Email has been sent to {fields.email}</p>
          <div className="form-container">
            {error && <div className="alert alert-danger">{error}</div>}
            {fields.completeResetPwd && (
              <div className="alert alert-success">
                Reset password successfully
              </div>
            )}
            {!fields.completeResetPwd && (
              <form autoComplete="off">
                <div className={`form-group text-group`}>
                  <label htmlFor="code">CODE</label>
                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    name="verify-code"
                    data-lpignore="true"
                    autoComplete="verify-code"
                    value={fields.code}
                    placeholder="Enter code"
                    onChange={handleFormChange}
                  />
                </div>
                <div className={`form-group text-group`}>
                  <label htmlFor="password">NEW PASSWORD</label>
                  <div className="d-flex align-items-center">
                    <input
                      type={visiblePassword.password ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      name="new-password"
                      data-lpignore="true"
                      autoComplete="new-password"
                      value={fields.password}
                      placeholder="New password"
                      onChange={handleFormChange}
                    />
                    {getEyeIcon('password')}
                  </div>
                </div>
                <div className={`form-group text-group`}>
                  <label htmlFor="password" className="w-100">
                    <div className="form-label">
                      CONFIRM NEW PASSWORD
                      {fields.password &&
                        fields.confirmpassword &&
                        fields.password !== fields.confirmpassword && (
                          <span className="text-error">
                            Password does not match
                          </span>
                        )}
                    </div>
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      type={
                        visiblePassword.confirmpassword ? 'text' : 'password'
                      }
                      className="form-control"
                      id="confirmpassword"
                      autoComplete="off"
                      value={fields.confirmpassword}
                      placeholder="New password"
                      onChange={handleFormChange}
                    />
                    {getEyeIcon('confirmpassword')}
                  </div>
                </div>
              </form>
            )}
            <div className="form-group">
              {!fields.completeResetPwd ? (
                <React.Fragment>
                  <LoaderButton
                    isLoading={fields.loading}
                    disabled={!validateForm()}
                    className="btn btn-dark btn-reset-pwd-submit"
                    onClick={handleResetPwdSubmit}
                  >
                    RESET PASSWORD
                  </LoaderButton>
                  <div className="p-3">
                    If you do not receive any email, click the button below
                  </div>
                  <LoaderButton
                    isLoading={fields.resending}
                    className="btn btn-dark btn-resend-code"
                    onClick={handleResendCode}
                  >
                    RESEND CODE
                  </LoaderButton>
                </React.Fragment>
              ) : (
                <button
                  className="btn btn-dark btn-back-login"
                  onClick={handleBackToLogin}
                >
                  Back to login
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {fields.completeUserName ? renderCodeForm() : renderUserNameForm()}
      <style>{`
        h1 {
          color: #fff;
          text-align: left !important;
        }
        h1 .separator {
          margin: 10px 0 !important;
        }
        p {
          color: #fff;
        }
        .content {
          padding: 0 30px;
        }

        .form-label {
          display: flex;
          justify-content: space-between;
        }
        .text-error {
          color: #FF0000;
        }
        .accent {
          text-align: left;
        }
        .container {
          max-width: 500px;
        }

        .form-container {
          margin: auto;
        }
        :global(.ssp) .form-container{
          max-width:700px;
        }
        .text-group {
          text-align: left;
          border: 1px solid #dedede;
          background: #fff;
          padding: 0 10px 5px;
          margin-bottom: 20px;
        }
        .btn {
          border-radius: 0;
          width: 100%;
          padding: 13px 0;
        }
        :global(.ssp) .btn{
          font-size:1.4em;
        }
        .text-group label {
          padding: 0;
          margin: 0;
          font-size: 0.5em;
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: 1px;
          color: ${color.accent};
        }
        :global(.ssp) .text-group label{
          font-size:.8em;
        }
        .text-group input {
          box-shadow: none;
          outline: none;
          border: none;
          padding: 0;
          margin: 0;
          height: auto;
        }
        
        .input-error {
          color: red;
        }
        :global(.kbActive) h1,
        :global(.kbActive) p{
          display:none;
        }
        @media (min-width: ${mobileBreakPoint}px) {
          h1 {
            color: #000;
          }
          p {
            color:  ${color.accent}};
            margin-bottom: 30px;
          }
        }
      `}</style>
    </>
  );
}

export default ResetPwdForm;
