import React, { useEffect, useMemo, useState } from 'react';
import SLAnalyticsRough from './SLAnalyticsRough';
import { RiLayoutGridFill } from 'react-icons/ri';
import { BsChevronRight } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import DateRange from '../../../../components/Common/DateRange';
import SearchInput from '../../Common/SearchInput';
import moment from 'moment';
import { ILibraryScene } from '../../../../interfaces';
import SLAnalyticsTabs from './SLAnalyticsTab';
import {
  getSceneInsightInteractionOverview,
  getSceneInsightOverview,
  getSceneInsightStorybookOverview
} from './service';
import { tz } from 'moment-timezone';
import SLAnalyticsOverview from './SLAnalyticsOverview';
import SLAnalyticsStorybook from './SLAnalyticsStorybooks';
import SLAnalyticsStorybookDetails from './SLAnalyticsStorybookDetails';
import SLAnalyticsInteractions from './SLAnalyticsInteractions';
import SLAnalyticsInteractionDetails from './SLAnalyticsInteractionDetails';

export type SLInsightData = {
  data: any;
  isLoading: boolean;
};

export type SLInsightState = {
  overview: SLInsightData;
  storybooks: SLInsightData;
  interactions: SLInsightData;
};

export type ISLSelectItem = {
  name: string;
  type: 'interaction' | 'storybook';
  thumbnail: string;
  id: string;
};
const tabs = [
  { id: 'overview', label: 'Overview' },
  { id: 'storybook', label: 'Storybooks' },
  { id: 'interactions', label: 'Interactions' }
];
const timeZone = tz.guess();

function SBAnalytics({
  onBack,
  scene
}: {
  onBack: () => void;
  scene: ILibraryScene;
}) {
  const sceneId = scene?.id;
  const content = scene?.content || {};
  const [breadcrumbs, setBreadcrumbs] = useState(
    content?.title
      ? [`${content?.title}${content?.title ? ` - ${content?.subtitle}` : ''}`]
      : []
  );
  const [campaigns, _] = useState([]);
  const campaignOptions = useMemo(() => {
    return [
      {
        value: '',
        label: 'All campaigns'
      },
      ...campaigns.map((c) => ({
        value: c,
        label: c
      }))
    ];
  }, [campaigns]);

  const dateRangeOptions = [7, 28, 90, 365];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [selectedItem, setSelectedItem] = useState<ISLSelectItem>(null);
  const [listItemTab, setListItemTab] = useState([]);
  const [dateRange, setDateRange] = useState(dateRangeOptions[0]);
  const [customStartDate, setCustomStartDate] = useState<number | null>(null);
  const [customEndDate, setCustomEndDate] = useState<number | null>(null);
  const [campaignSearch, setCampaignSearch] = useState<string>('');
  const [campaign, setCampaign] = useState(campaignOptions[0]);
  const [overview, setOverview] = useState<SLInsightData>();
  const [storybooks, setStorybooks] = useState<SLInsightData>();
  const [interactions, setInteractions] = useState<SLInsightData>();

  const [startDate, endDate] = useMemo(() => {
    const fromDate =
      dateRange > 0
        ? moment().subtract(dateRange, 'days').format('YYYY-MM-DD')
        : startDate;
    const toDate = dateRange > 0 ? moment().format('YYYY-MM-DD') : endDate;
    return [fromDate, toDate];
  }, [dateRange, customStartDate, customEndDate]);

  const getOverview = () => {
    setOverview({ data: {}, isLoading: true });
    getSceneInsightOverview({
      startDate,
      endDate,
      sceneId,
      timeZone,
      campaign: campaign.value || ''
    })
      .then((res) => {
        setOverview({ data: res, isLoading: false });
      })
      .catch(() => {
        setOverview({ data: {}, isLoading: false });
      });
  };

  const getStorybooks = () => {
    setStorybooks({ data: [], isLoading: true });
    getSceneInsightStorybookOverview({
      startDate,
      endDate,
      sceneId,
      timeZone,
      campaign: campaign.value || ''
    })
      .then((res) => {
        setStorybooks({ data: res || [], isLoading: false });
      })
      .catch(() => {
        setStorybooks({ data: [], isLoading: false });
      });
  };

  const getInteractions = () => {
    setInteractions({ data: [], isLoading: true });
    getSceneInsightInteractionOverview({
      startDate,
      endDate,
      sceneId,
      timeZone,
      campaign: campaign.value || ''
    })
      .then((res) => {
        setInteractions({ data: res, isLoading: false });
      })
      .catch(() => {
        setInteractions({ data: [], isLoading: false });
      });
  };

  React.useEffect(() => {
    getOverview();
    getStorybooks();
    getInteractions();
  }, [campaign, startDate, endDate, timeZone, sceneId]);

  const listStorybooks = useMemo(() => {
    const data = storybooks?.data || [];
    return data.map((item) => ({
      id: item.storybook?.id,
      name: item.storybook?.title || 'Untitled',
      subtitle: item.storybook?.subtitle || '',
      type: 'storybook',
      thumbnail: item.storybook?.featuredImage
    }));
  }, [storybooks]);

  const handleSelectItem = (item: ISLSelectItem) => {
    if (!item) {
      setSelectedItem(null);
      setListItemTab([]);
      return;
    }
    if (!item?.name) {
      const storybook = listStorybooks.find((sb) => sb.id === item.id);
      if (storybook) {
        item = {
          ...storybook,
          type: 'storybook'
        };
      }
    }
    setSelectedItem(item);
    setListItemTab(listStorybooks);
  };

  useEffect(() => {
    if (selectedItem)
      setBreadcrumbs([
        ...(content.name || '' ? [content.name] : []),
        selectedItem.name || selectedItem.id
      ]);
    else setBreadcrumbs([...(content.name || '' ? [content.name] : [])]);
  }, [selectedItem]);

  const onClickBreadcrumbs = (index) => {
    switch (index) {
      case 0:
        onBack();
        break;

      case 1:
        setSelectedItem(null);
        setListItemTab([]);
        break;

      default:
        break;
    }
  };

  if (!scene) return <div>Empty</div>;

  return (
    <>
      <div className="analytics">
        <div className="analytics-header">
          <div className="title">Analytics</div>
          <div className="analytics-header-bar">
            <div className="header-breadcrumbs">
              <div
                className="breadcrumbs__item"
                onClick={() => onClickBreadcrumbs(0)}
              >
                <RiLayoutGridFill style={{ width: 20, height: 20 }} />
                Scene
              </div>
              {breadcrumbs.map((bc, index) => (
                <div
                  className="breadcrumbs__item"
                  key={index}
                  onClick={() => onClickBreadcrumbs(index + 1)}
                >
                  <BsChevronRight />
                  <div className="text-ellipsis">{bc}</div>
                </div>
              ))}
            </div>
            <div className="analytics-header__actions">
              <Dropdown className="analytics-dropdown">
                <Dropdown.Toggle id="dropdown-campaigns">
                  {campaign?.label}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <label>Show:</label>
                  <SearchInput
                    onSearch={setCampaignSearch}
                    searchOnTyping={true}
                    placeHolder={'Search'}
                    defaultKeyword={campaignSearch || ''}
                    clear={false}
                  />
                  {campaignOptions
                    .filter((opt) =>
                      opt.label
                        .trim()
                        .toLocaleLowerCase()
                        .includes(campaignSearch.trim().toLocaleLowerCase())
                    )
                    .map((campaign) => (
                      <Dropdown.Item
                        key={campaign.value}
                        onClick={() => setCampaign(campaign)}
                      >
                        {campaign.label}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <div
                className={`dateRange ${
                  dateRange === -1 ? 'dateRange__custom' : ''
                }`}
              >
                <Dropdown className="analytics-dropdown">
                  <Dropdown.Toggle id="dropdown-date-range">
                    {dateRange == -1 ? 'Custom' : `Last ${dateRange} Days`}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <label>Show:</label>
                    {dateRangeOptions.map((range) => {
                      return (
                        <Dropdown.Item
                          key={range}
                          onClick={() => setDateRange(range)}
                        >
                          Last {range} Days
                        </Dropdown.Item>
                      );
                    })}
                    <Dropdown.Item
                      onClick={() => {
                        setDateRange(-1);
                        const currentDate = new Date();
                        const startDate = new Date(currentDate);
                        startDate.setDate(currentDate.getDate() - 7);
                        setCustomStartDate(startDate.getTime());
                        setCustomEndDate(currentDate.getTime());
                      }}
                    >
                      Custom
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {dateRange === -1 && (
                  <DateRange
                    startDate={customStartDate}
                    endDate={customEndDate}
                    onDateChange={(start, end) => {
                      setCustomStartDate(start);
                      setCustomEndDate(end);
                    }}
                    defaultOpen={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="analytics-main">
          <div className={`content-col side-col`}>
            <SLAnalyticsRough
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              scene={scene}
              data={overview?.data}
              listItemTab={listItemTab}
              setListItemTab={setListItemTab}
            />
          </div>
          <div className="content-col main-col">
            {selectedItem ? (
              <div className="SBAnalytics h-100">
                {selectedItem?.type === 'storybook' ? (
                  <SLAnalyticsStorybookDetails
                    storybook={selectedItem}
                    campaign={campaign.value}
                    startDate={
                      customStartDate &&
                      moment(customStartDate).format('YYYY-MM-DD')
                    }
                    endDate={
                      customEndDate &&
                      moment(customEndDate).format('YYYY-MM-DD')
                    }
                    scene={scene}
                    range={dateRange}
                  />
                ) : (
                  <SLAnalyticsInteractionDetails item={selectedItem} />
                )}
              </div>
            ) : (
              <div className="SBAnalytics h-100">
                <SLAnalyticsTabs
                  active={activeTab}
                  tabs={tabs}
                  onSelectTab={setActiveTab}
                />
                <div className="SBAnalytics-content">
                  {activeTab.id === 'overview' ? (
                    <SLAnalyticsOverview
                      range={dateRange}
                      startDate={
                        customStartDate &&
                        moment(customStartDate).format('YYYY-MM-DD')
                      }
                      endDate={
                        customEndDate &&
                        moment(customEndDate).format('YYYY-MM-DD')
                      }
                      handleSelectItem={handleSelectItem}
                      data={overview?.data}
                      loading={overview?.isLoading}
                      storybooks={listStorybooks}
                    />
                  ) : activeTab.id === 'storybook' ? (
                    <SLAnalyticsStorybook
                      handleSelectItem={handleSelectItem}
                      data={storybooks?.data}
                      loading={storybooks?.isLoading}
                    />
                  ) : activeTab.id === 'interactions' ? (
                    <SLAnalyticsInteractions
                      data={interactions?.data}
                      loading={interactions?.isLoading}
                      handleSelectItem={handleSelectItem}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .analytics-main {
          display: flex;
        }

        .content-col {
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 20px;
        }

        .text-ellipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 400px;
        }
        .main-col {
          height: 100%;
          width: calc(100% - 230px);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-content: baseline;
          position: relative;
          padding: 0;
        }

        .side-col {
          width: 270px;
          min-width: 270px;
          transition: min-width 0.1s;
          position: relative;
          padding: 0;
          background: #fdfdfd;
        }

        .analytics-header {
          display: flex;
          border-bottom: 1px solid #d8d8d8;
          min-height: 60px;
          justify-content: space-between;
        }

        .analytics-header .title {
          color: var(--Black, #000);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 270px;
          padding: 15px 20px;
        }
        .analytics-header-bar {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 270px);
        }

        .header-breadcrumbs {
          padding: 0 20px;
          color: var(--Black, #000);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        .breadcrumbs__item {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .breadcrumbs__item:hover {
          text-decoration: underline;
        }

        :global(.analytics-header-bar .breadcrumbs__item svg) {
          margin-right: 7px;
        }

        :global(.analytics-dropdown button) {
          background: transparent !important;
          color: #707070 !important;
          font-size: 12px;
          border: transparent;
          box-shadow: none !important;
          min-width: 8rem;
        }

        :global(.analytics-dropdown button::after) {
          margin-left: 15px;
        }

        :global(.analytics-dropdown) {
          display: flex;
          align-items: center;
        }

        :global(.analytics-dropdown .dropdown-menu) {
          font-size: 12px;
          color: #666;
          font-weight: 600;
          min-width: 8rem;
          border-radius: 20px;
          background: #fff;
          box-shadow: 0px 2.679px 6.698px 0px rgba(0, 0, 0, 0.25);
          overflow: hidden;
        }

        :global(.analytics-dropdown .dropdown-menu label) {
          color: #c1c1c1;
          padding: 0.25rem 1rem;
          margin: 0;
        }

        :global(.analytics-dropdown .dropdown-menu .dropdown-item) {
          padding: 0.25rem 1rem;
        }

        :global(.content-wrapper:has(.analytics)) {
          padding: 0 !important;
        }

        .action {
          display: flex;
          align-items: center;
          height: 50px;
          padding: 10px;
        }
        .btn-back {
          font-size: 14px;
        }
        :global(.SBAnalytics .h-100) {
          height: calc(100% - 15px) !important;
        }
        .action h5 {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
        }
        .SBAnalytics {
          position: relative;
          overflow: hidden;
          padding: 20px;
        }
        .SBAnalytics-content {
          overflow: auto;
          max-height: calc(100vh - 235px);
        }

        .row .col-4:first-child {
          padding-left: 15px;
        }
        .row .col-4:last-child {
          padding-right: 15px;
        }
        .row .col-4,
        .col-8 {
          padding-left: 5px;
          padding-right: 5px;
        }
        .sb-pages {
          max-width: 285px;
        }
        .sb-analytics-details {
          min-width: calc(100% - 285px);
        }
        .sb-view-count {
          background-color: #f3f3f3;
          border-radius: 5px;
        }
        .dateRange {
          align-items: center;
          display: flex;
        }

        .dateRange__custom {
          display: flex;
          gap: 10px;
        }

        .analytics-header__actions {
          display: flex;
          flex-wrap: wrap;
          min-width: 265px;
          padding: 3px 20px;
        }

        :global(.analytics-dropdown .SearchInput) {
          margin-bottom: 10px;
        }
        :global(.analytics-dropdown .SearchInput input) {
          font-size: 12px;
          border-top: none;
          border-left: none;
          border-right: none;
          border-radius: 0;
          margin: 0 16px;
          padding: 0;
          color: #a8a8a8;
        }

        :global(.analytics-dropdown .SearchInput .btn-search) {
          right: 0;
          left: unset;
          color: #a8a8a8;
        }

        @media (max-width: 768px) {
          .analytics-header {
            display: grid;
            grid-template-columns: 1fr;
            height: auto;
          }
          .title,
          .analytics-header-bar {
            width: 100% !important;
          }

          .analytics-header-bar {
            display: block;
          }

          .title {
            margin-bottom: 1rem;
          }
          .analytics-main {
            display: block;
          }
          .side-col,
          .main-col {
            width: 100%;
          }
          :global(.analytics-rough__main) {
            max-height: unset;
          }
          :global(.analytics-rough__main .cards) {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
          }
          :global(.analytics-rough__main .cards .card-basic) {
            min-width: 150px;
          }
          :global(.SBAnalytics-content) {
            max-height: unset !important;
          }
          :global(.item-list__container) {
            flex-direction: row !important;
          }
          :global(.analytics-main) {
            max-height: unset !important;
          }
        }
      `}</style>
      <style jsx global>{`
        .analytics .notfound-thumbnail {
          background-color: black;
          width: 120px;
          aspect-ratio: 16/9;
          margin-left: 15px;
        }
        .analytics .scenes_order {
          position: absolute;
          top: 15px;
          left: 10px;
          right: 10px;
          bottom: 15px;
        }
        .analytics .scenes_order img {
          width: 120px;
          height: auto;
          border: 1px solid #ccc;
          margin-left: 20px;
        }
        .analytics tbody td {
          height: 105px;
          position: relative;
        }

        .analytics .scenes_order p {
          flex: 1;
          display: flex;
          gap: 5px;
          flex-direction: column;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .analytics .scenes_order p span {
          font-size: 12px;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          font-weight: bold;
        }

        .analytics .scenes_order p span:nth-child(2) {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: unset;
          color: #a8a8a8;
        }

        .area-custom-tooltip {
          padding: 10px;
          font-size: 12px;
          border: 1px solid #8f6599;
          overflow: hidden;
          border-radius: 5px;
        }
        .area-custom-tooltip .header .date {
          color: #615e83;
          margin-bottom: 6px;
        }
        .area-custom-tooltip .header .tab {
          font-weight: 700;
          text-transform: capitalize;
          margin-bottom: 6px;
        }
        .area-custom-tooltip ul {
          padding: 0;
          margin: 0;
          list-style: none;
        }
        .area-custom-tooltip ul li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 3px 0;
          gap: 20px;
        }
        .area-custom-tooltip li .tooltip-label {
          display: flex;
          align-items: center;
        }
        .area-custom-tooltip li .tooltip-label span.color {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 5px;
        }
        .area-custom-tooltip li .tooltip-value {
          font-weight: 700;
          font-size: 16px;
          color: #4c2d5a;
        }
        :global(.custom-interaction) {
          display: flex;
          gap: 5px;
        }
        :global(.custom-interaction .value) {
          display: flex;
          flex-direction: column;
        }
        :global(.custom-interaction .value .type) {
          position: relative;
          padding-left: 15px;
          text-transform: capitalize;
          font-size: 12px;
          font-weight: 600;
        }
        :global(.custom-interaction .value .type::before) {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: currentColor;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
        }

        .analytics .scenes_order {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .analytics .scenes_order span {
          display: inline-block;
          width: 20px;
        }
      `}</style>
    </>
  );
}

export default SBAnalytics;
