import httpClient from './http';

type CreateIssueInput = {
  name?: string;
  author: string;
  phone?: string;
  countryCode?: string;
  topic: string;
  question: string;
  questionDetail: string;
  brandId: string;
  spaceId: string;
  attachments: Blob[];
  giveAccessPermission?: 'Yes' | 'No';
};

export const createContactSupportIssue = (data: CreateIssueInput) => {
  const form = new FormData();
  form.append('author', data.author);
  form.append('authorName', data.name || '');
  form.append('phone', data.phone || '');
  form.append('countryCode', data.countryCode || '');
  form.append('topic', data.topic);
  form.append('question', data.question);
  form.append('questionDetail', data.questionDetail);
  form.append('spaceId', data.spaceId);
  form.append('brandId', data.brandId);
  form.append('giveAccessPermission', data?.giveAccessPermission || '');
  data.attachments.forEach((file) => {
    form.append('attachments', file);
  });
  return httpClient.post('/api/contactSupport', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
