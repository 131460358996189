import React, { useContext, useState } from 'react';
import { getCurrentUserInfo } from '../../../../clientSideServices/users';
import { HubContext } from '../../HubContext';
import StoriezPopup from './StoriezPopup';

const StoriezTour = ({page}) => {
  const [showTour, setShowTour] = useState<boolean>();
  const { setGuidedTour, setIsGuidedTourReplay } = useContext(HubContext) || {};

  React.useEffect(() => {
    getCurrentUserInfo().then((userInfo) => {
      if (userInfo?.attributes?.['custom:gt_storiez'] !== 'skipped') {
        setShowTour(true);
        setGuidedTour(page);
      }
    });
  }, []);
  
  return showTour ? (
    <StoriezPopup
      onClose={() => {
        setGuidedTour('');
        setIsGuidedTourReplay(true);
      }}
    />
  ) : null;
};

export default StoriezTour;
