import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { IMainState } from '../../../interfaces';
import { mapSessionPayloadToFormData } from '../../../mappers/session';
import {
  actionHubSessionUpdateFileUploadStatus,
  actionHubUpdateSessionFormData
} from '../../../redux/actions';
import {
  DID_CLICK_SESSION_CREATE,
  DID_CLICK_SESSION_EDIT,
  DID_DELETE_SESSION
} from '../../../utils/constants';
import { PageView } from '../../../utils/pageView';
import { hubNavTo } from '../../utils/hubPagesRoute';
import SearchInput from '../Common/SearchInput';
import { useUserMeetingRooms } from '../hooks/meetingRoom';
import Layout from '../Layout';
import MeetingRoomTile from './MeetingRoomTile';

export const MeetingRoomsOverview = () => {
  const [keywords, setKeywords] = React.useState('');
  const user = useSelector((state: IMainState) => state.clientState.hub?.user);
  const dispatch = useDispatch();
  const { isLoading, data, refetch } = useUserMeetingRooms(user?.id);
  const filteredMeetings = data?.filter((session) =>
    session.topic?.toLowerCase()?.includes(keywords.toLowerCase())
  );

  return (
    <>
      <div className="header-container">
        <h2>Meeting Rooms</h2>
        <div className="action-container">
          <SearchInput
            onSearch={(value) => setKeywords(value)}
            searchOnTyping={true}
            rounded={true}
          />
          <button
            className="btn btn-round btn-outline-dark ml-3"
            onClick={() => {
              dispatch(actionHubUpdateSessionFormData(null));
              dispatch(actionHubSessionUpdateFileUploadStatus(null));
              logEvent(DID_CLICK_SESSION_CREATE);
              hubNavTo(PageView.HUB_MEETING_ROOM_CREATE);
            }}
          >
            Create
          </button>
        </div>
      </div>
      <div className="content-container">
        {isLoading ? (
          <div className="w-100 text-center p-4">
            <Spinner animation="border" />
          </div>
        ) : null}
        {data?.length === 0 ? (
          <p className="text-center w-100 p-4">No Meeting Room Found</p>
        ) : null}
        {filteredMeetings?.map((session) => {
          return (
            <div
              className="thumbnail-container"
              key={session.id}
              onClick={() => {
                dispatch(
                  actionHubUpdateSessionFormData(
                    mapSessionPayloadToFormData(session)
                  )
                );
                hubNavTo(PageView.HUB_MEETING_ROOM_EDIT);
                logEvent(DID_CLICK_SESSION_EDIT, DID_CLICK_SESSION_EDIT, {
                  session_id: session?.id
                });
              }}
            >
              <MeetingRoomTile
                session={session}
                storeId={user?.storeId}
                didDeleteSession={() => {
                  refetch();
                  logEvent(DID_DELETE_SESSION, DID_DELETE_SESSION, {
                    session_id: session?.id
                  });
                }}
              />
            </div>
          );
        })}
      </div>
      <style jsx global>{`
        .MeetingRoomOverview .content-wrapper {
          padding: 0 20px 20px !important;
          background: #fff;
        }
      `}</style>
      <style jsx>{`
        h2 {
          font-size: 1.4em;
          margin: 0;
        }
        .header-container {
          border-bottom: 1px solid #ccc;
          padding: 15px 0;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .action-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .content-container {
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: baseline;
        }

        .thumbnail-container {
          cursor: pointer;
          width: 100%;
          padding: 10px;
        }

        @media (max-width: 768px) {
          h2 {
            font-size: 1.2em;
          }
          .header-container {
            flex-direction: column;
            align-items: flex-start;
          }

          .action-container {
            width: 100%;
            margin-top: 10px;
          }

          :global(.SearchInput) {
            flex-grow: 1;
          }
        }
        @media (min-width: 480px) {
          .thumbnail-container {
            width: 50%;
          }
        }
        @media (min-width: 768px) {
          .thumbnail-container {
            width: 33%;
          }
        }

        @media (min-width: 1000px) {
          .thumbnail-container {
            width: 25%;
          }
        }
        @media (min-width: 1400px) {
          .thumbnail-container {
            width: 20%;
            padding: 15px;
          }
        }
        @media (min-width: 1600px) {
          .thumbnail-container {
            width: 16.66%;
            max-width: 320px;
          }
        }
        @media (min-width: 2000px) {
          .thumbnail-container {
            width: 14.1%;
            max-width: 320px;
          }
        }
      `}</style>
    </>
  );
};

export default () => (
  <Layout showNav={false} theme="dark-light" className="MeetingRoomOverview">
    <MeetingRoomsOverview />
  </Layout>
);
