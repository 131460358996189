import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BsX } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import StoryFilters from '../../../components/Inspiration/StoryFilters';
import {
  actionHubAlertError,
  actionHubUpdateNudgeContent,
  actionOpenVBPopup,
  actionToggleOnlyShowFavorites
} from '../../../redux/actions';
import { actionUpdateNudgeFavoriteItemsAsync } from '../../../redux/advisorHubAsyncActions';
import {
  DID_ADD_TO_FAVORITE,
  DID_CLICK_PRODUCTS_FILTER_BUTTON,
  DID_CLICK_STORIES_FILTER_BUTTON,
  DID_REMOVE_FROM_FAVORITE,
  DID_SELECT_NUDGE_ITEM,
  DID_TOGGLE_ONLY_SHOW_FAVORITES,
  DID_UNSELECT_ALL_NUDGE_PRODUCTS,
  DID_UNSELECT_ALL_NUDGE_STORIES,
  DID_UNSELECT_NUDGE_ITEM
} from '../../../utils/constants';
import { getIdentityId } from '../../../utils/identity';
import { PageView } from '../../../utils/pageView';
import { getAdditionalStoreProductSearchFilters } from '../../../utils/stores';
import { getCurrentHost } from '../../../utils/window';
import { isInspiration, isProduct } from '../../utils/nudge';
import { getThemeColor } from '../BrandStyle';
import { WizardButton } from '../Common/Buttons';
import { HubContext } from '../HubContext';
import ProductSearch from '../Search/ProductSearch';
import StorySearch from '../Search/StorySearch';
import {
  CloudSearchFilter,
  IMainState,
  INudgeItem,
  NudgeItemType,
  ProductFamily,
  Source
} from './../../../interfaces';
import ProductFilters from './../Search/ProductFilters';
import { NudgeCreationState } from './NudgeCurator';
import NudgeLanguageSelect from './NudgeLanguageSelect';

interface Props {
  onNext: () => void;
  onPrev: () => void;
  title?: string;
}

const NudgeSearch = ({
  onNext,
  onPrev,
  title = 'CURATE YOUR NUDGE'
}: Props) => {
  const dispatch = useDispatch();
  const { brandId, searchFilters, storeId, brandSetup } =
    React.useContext(HubContext);
  const color = getThemeColor(brandId);
  const productSearchFilters = [
    ...(searchFilters?.productSearchFilters || []),
    ...getAdditionalStoreProductSearchFilters(storeId)
  ];
  const [inputValue, setInputValue] = useState('');
  const [keywords, setKeywords] = useState('');
  const [tab, setTab] = useState(0);
  const [showStoryFilter, setShowStoryFilter] = useState(false);
  const [showProductFilter, setShowProductFilter] = useState(false);
  const [selectedContext, setSelectedContext] = useState([]);
  const [selectedProductFamilies, setSelectedProductFamilies] = useState([]);
  const [selectedProductSearchFilters, setSelectedProductSearchFilters] =
    useState([]);
  const favorites = useSelector(
    (state: IMainState) => state.clientState.hub?.favorites
  );
  const showingOnlyFavorites = useSelector(
    (state: IMainState) => state.clientState.hub?.showingOnlyFavorites
  );

  const [storyLanguage, setStoryLanguage] = useState('EN');

  const [productSearchLoading, setProductSearchLoading] = useState(false);
  const [storySearchLoading, setStorySearchLoading] = useState(false);

  const nudgeState = useSelector(
    (state: IMainState) => state.clientState.hub?.nudge
  );
  const currentStoreId = useSelector(
    (state: IMainState) => state.clientState.hub?.user?.storeId
  );
  const selectedItems = nudgeState?.content || [];

  const setSelectedItems = (items: INudgeItem[]) => {
    dispatch(actionHubUpdateNudgeContent(items));
  };

  const onSelectLanguage = (lang: string) => {
    setStoryLanguage(lang);
  };

  const selectedProducts =
    selectedItems.filter((item) => isProduct(item)) || [];
  const selectedStories =
    selectedItems.filter((item) => isInspiration(item)) || [];

  const handleSelectItem = (item: INudgeItem) => {
    const existedItem = selectedItems.find((s) => s.id === item.id);
    if (existedItem) {
      const filteredItems = selectedItems.filter((s) => s !== existedItem);
      setSelectedItems(filteredItems);
      logEvent(DID_UNSELECT_NUDGE_ITEM, DID_UNSELECT_NUDGE_ITEM, {
        id: item.id,
        type: isProduct(item) ? 'product' : 'story'
      });
    } else {
      setSelectedItems([...selectedItems, item]);
      logEvent(DID_SELECT_NUDGE_ITEM, DID_SELECT_NUDGE_ITEM, {
        id: item.id,
        type: isProduct(item) ? 'product' : 'story'
      });
    }
  };

  const handleOpenPage = (path: string) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}${path}?source=${Source.AdvisorHub}${
          currentStoreId ? `&store=${currentStoreId}` : ''
        }${
          nudgeState?.languageCode
            ? `&language=${nudgeState.languageCode.toLowerCase()}`
            : ''
        }`,
        true
      )
    );
  };

  const clearKeywords = () => {
    setInputValue('');
    setKeywords('');
  };

  const onSelectContext = (str: string) => {
    const isExist = selectedContext.indexOf(str) > -1;
    let context = [];
    if (isExist) {
      context = selectedContext.filter((c) => c !== str);
    } else {
      context = [...selectedContext, str];
    }
    setSelectedContext(context);
  };

  const onSelectProductFamily = (family: ProductFamily) => {
    if (selectedProductFamilies.some((f) => f.family === family.family)) {
      setSelectedProductFamilies(
        selectedProductFamilies.filter((f) => f.family !== family.family)
      );
    } else {
      setSelectedProductFamilies([...selectedProductFamilies, family]);
    }
  };

  const onSelectProductSearchFilter = (filter: CloudSearchFilter) => {
    if (
      selectedProductSearchFilters.some(
        (f) => f.cloudSearchField === filter.cloudSearchField
      )
    ) {
      setSelectedProductSearchFilters(
        selectedProductSearchFilters.filter(
          (f) => f.cloudSearchField !== filter.cloudSearchField
        )
      );
    } else {
      setSelectedProductSearchFilters([
        ...selectedProductSearchFilters,
        filter
      ]);
    }
  };

  const onResetStoryFilter = () => {
    setSelectedContext([]);
    setStoryLanguage('');
  };

  const onResetProductFilter = () => {
    setSelectedProductFamilies([]);
    setSelectedProductSearchFilters([]);
  };

  const searchInput = (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        placeholder="SEARCH"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setKeywords(inputValue);
          }
        }}
      />
      {!!inputValue && <BsX onClick={clearKeywords} className="clear" />}
      <div className="input-group-append">
        <button
          className="btn btn-dark"
          type="button"
          id="button-addon2"
          onClick={() => setKeywords(inputValue)}
        >
          <FiSearch />
        </button>
      </div>
      <style jsx>{`
        input,
        .btn-dark {
          background: ${color.nudgeLightMode
            ? color.light
            : color.dark} !important;
          color: ${color.nudgeLightMode ? color.dark : color.light} !important;
        }
        .input-group :global(.clear) {
          overflow: hidden;
          vertical-align: middle;
          position: absolute;
          cursor: pointer;
          right: 55px;
          top: 11px;
          z-index: 9;
        }
        input,
        .btn {
          border-radius: 0;
        }
        input,
        .btn,
        :global(.layout) .btn-dark,
        input:focus,
        input:active {
          border-color: ${color.accent} !important;
        }

        input:focus,
        input:active {
          color: #000;
        }
        ::-webkit-input-placeholder {
          /* Edge */
          color: #bbb;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #bbb;
        }

        ::placeholder {
          color: #bbb;
        }

        @media (max-width: 768px) {
          .input-group {
            margin-bottom: 10px !important;
          }
        }
      `}</style>
    </div>
  );

  const tabs = (
    <div className="col-12 btn-group btn-tab">
      <button
        className={`btn btn-sm col-6 ${
          color.nudgeLightMode ? 'btn-light' : 'btn-dark'
        } ${tab === 0 && 'active'}`}
        onClick={() => setTab(0)}
      >
        STORIES{' '}
        {selectedStories.length !== 0 && <sup>{selectedStories.length}</sup>}{' '}
        {storySearchLoading && <Spinner size="sm" animation="border" />}
      </button>
      <button
        className={`btn btn-sm col-6 ${
          color.nudgeLightMode ? 'btn-light' : 'btn-dark'
        } ${tab === 1 && 'active'}`}
        onClick={() => setTab(1)}
      >
        PRODUCTS{' '}
        {selectedProducts.length !== 0 && <sup>{selectedProducts.length}</sup>}{' '}
        {productSearchLoading && <Spinner size="sm" animation="border" />}
      </button>
      <style jsx>{`
        .btn-tab .btn {
          border-radius: 0;
        }
        .btn-light {
          border-color: #fff !important;
        }
        @media (min-width: 768px) {
          .btn-tab {
            display: none;
          }
        }
      `}</style>
    </div>
  );

  const getFavoriteButton = (item: INudgeItem) => {
    const type = isProduct(item) ? NudgeItemType.PRODUCT : NudgeItemType.STORY;
    const isExistingFavorite = favorites?.some((f) => f.itemId === item.id);
    return (
      <>
        {isExistingFavorite ? (
          <AiFillHeart
            className="nudge-item-favorite-btn with-shadow"
            onClick={() => {
              dispatch(
                actionUpdateNudgeFavoriteItemsAsync(
                  storeId,
                  brandId,
                  getIdentityId(),
                  favorites?.filter((f) => f.itemId !== item.id)
                )
              );
              logEvent(DID_REMOVE_FROM_FAVORITE, DID_REMOVE_FROM_FAVORITE, {
                type,
                id: item.id
              });
            }}
          />
        ) : (
          <AiOutlineHeart
            className="nudge-item-favorite-btn with-shadow"
            onClick={() => {
              dispatch(
                actionUpdateNudgeFavoriteItemsAsync(
                  storeId,
                  brandId,
                  getIdentityId(),
                  [
                    ...(favorites || []),
                    { itemType: type.toUpperCase(), itemId: item.id }
                  ]
                )
              );
              logEvent(DID_ADD_TO_FAVORITE, DID_ADD_TO_FAVORITE, {
                type,
                id: item.id
              });
            }}
          />
        )}
        <style jsx global>
          {`
            .nudge-item-favorite-btn {
              cursor: pointer;
              position: absolute;
              top: 3px;
              left: 20px;
              width: 30px;
              height: 30px;
            }
            .with-shadow {
              -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
              filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
            }
          `}
        </style>
      </>
    );
  };

  const favoriteToggleButton = (
    <>
      <button
        className="toggle-favorite"
        onClick={() => {
          dispatch(actionToggleOnlyShowFavorites(!showingOnlyFavorites));
          logEvent(DID_TOGGLE_ONLY_SHOW_FAVORITES);
        }}
      >
        {showingOnlyFavorites ? 'Show All Content' : 'Show Favorites Only'}
      </button>
      <style jsx>
        {`
          .toggle-favorite {
            border: 1px solid ${color.accent};
            background: ${color.nudgeLightMode ? color.light : color.dark};
            color: ${color.nudgeLightMode ? color.dark : color.light};
            padding: 1px 10px;
          }

          @media (max-width: 768px) {
            .toggle-favorite {
              width: calc(100% - 30px);
              margin: 5px 0 10px 0;
              padding: 6px 12px;
            }
          }
        `}
      </style>
    </>
  );

  return (
    <div className="container NudgeSearch">
      <div className="sticky-container">
        <h1>{title}</h1>
        <div className="row justify-content-center search-container">
          <div className="col-8 col-sm-9 col-lg-4 search-col">
            {searchInput}
          </div>
          <div className="col-4 col-sm-3 col-lg-2 lang-col">
            <NudgeLanguageSelect brandId={brandId} />
          </div>
          {favoriteToggleButton}
        </div>
        <div className="row">{tabs}</div>
        <div className="row">
          <div className={`col col-12 col-md-6 ${tab === 0 && 'active'}`}>
            <div className="heading">
              <h2>
                STORIES{' '}
                {selectedStories.length !== 0 && (
                  <sup>{selectedStories.length}</sup>
                )}{' '}
                {storySearchLoading && <Spinner size="sm" animation="border" />}
              </h2>
              <div className="btn-group btn-filter">
                <button
                  className={`btn btn-sm ${
                    color.nudgeLightMode ? 'btn-light' : 'btn-secondary'
                  }`}
                  onClick={() => {
                    setShowStoryFilter(!showStoryFilter);
                    logEvent(
                      DID_CLICK_STORIES_FILTER_BUTTON,
                      DID_CLICK_STORIES_FILTER_BUTTON,
                      { open: !showStoryFilter }
                    );
                  }}
                >
                  Story Filter <RiArrowDownSLine />
                </button>
                <button
                  className={`btn btn-sm ${
                    color.nudgeLightMode ? 'btn-light' : 'btn-secondary'
                  }`}
                  onClick={() => {
                    setSelectedItems(selectedProducts);
                    logEvent(
                      DID_UNSELECT_ALL_NUDGE_STORIES,
                      DID_UNSELECT_ALL_NUDGE_STORIES,
                      {}
                    );
                  }}
                >
                  Unselect All
                </button>
              </div>
              {showStoryFilter && (
                <div className="filters-container">
                  <StoryFilters
                    contextFilters={searchFilters.storyContext || []}
                    onClose={() => setShowStoryFilter(!showStoryFilter)}
                    onSelectContext={onSelectContext}
                    selectedContext={selectedContext}
                    onReset={onResetStoryFilter}
                    onSelectLanguage={onSelectLanguage}
                    languageFilters={searchFilters.storyLanguages || []}
                    selectedLanguage={storyLanguage}
                    darkMode={!color?.nudgeLightMode}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={`col col-12 col-md-6 ${tab === 1 && 'active'}`}>
            <div className="heading">
              <h2>
                PRODUCTS{' '}
                {selectedProducts.length !== 0 && (
                  <sup>{selectedProducts.length}</sup>
                )}{' '}
                {productSearchLoading && (
                  <Spinner size="sm" animation="border" />
                )}
              </h2>
              <div className="btn-group btn-filter">
                <button
                  className={`btn btn-sm ${
                    color.nudgeLightMode ? 'btn-light' : 'btn-secondary'
                  }`}
                  onClick={() => {
                    setShowProductFilter(!showProductFilter);
                    logEvent(
                      DID_CLICK_PRODUCTS_FILTER_BUTTON,
                      DID_CLICK_PRODUCTS_FILTER_BUTTON,
                      { open: !showProductFilter }
                    );
                  }}
                >
                  Product Filter <RiArrowDownSLine />
                </button>
                <button
                  className={`btn btn-sm ${
                    color.nudgeLightMode ? 'btn-light' : 'btn-secondary'
                  }`}
                  onClick={() => {
                    setSelectedItems(selectedStories);
                    logEvent(
                      DID_UNSELECT_ALL_NUDGE_PRODUCTS,
                      DID_UNSELECT_ALL_NUDGE_PRODUCTS,
                      {}
                    );
                  }}
                >
                  Unselect All
                </button>
              </div>
              {showProductFilter && (
                <div className="filters-container">
                  <ProductFilters
                    productFamilies={searchFilters.productFamilies || []}
                    productSearchFilters={productSearchFilters}
                    selectedProductFamilies={selectedProductFamilies}
                    selectedProductSearchFilters={selectedProductSearchFilters}
                    onSelectProductFamily={onSelectProductFamily}
                    onSelectProductSearchFilter={onSelectProductSearchFilter}
                    onClose={() => setShowProductFilter(!showProductFilter)}
                    onReset={onResetProductFilter}
                    darkMode={!color?.nudgeLightMode}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={`col col-12 col-md-6 items-container  ${
            tab === 0 && 'active'
          }`}
        >
          <StorySearch
            onSelect={handleSelectItem}
            keywords={keywords}
            brandId={brandSetup?.parentBrandId || brandId}
            selectedItems={selectedItems}
            onOpenPage={handleOpenPage}
            onSearchStart={() => setStorySearchLoading(true)}
            onSearchLoaded={() => setStorySearchLoading(false)}
            language={storyLanguage}
            storyContext={selectedContext}
            embeddedComponent={getFavoriteButton}
          />
        </div>
        <div
          className={`col col-12 col-md-6 items-container  ${
            tab === 1 && 'active'
          }`}
        >
          <ProductSearch
            onSelect={handleSelectItem}
            keywords={keywords}
            brandId={brandSetup?.parentBrandId || brandId}
            selectedItems={selectedItems}
            onOpenPage={handleOpenPage}
            onSearchStart={() => setProductSearchLoading(true)}
            onSearchLoaded={() => setProductSearchLoading(false)}
            selectedProductFamilies={selectedProductFamilies}
            selectedProductSearchFilters={selectedProductSearchFilters}
            language={nudgeState?.languageCode}
            embeddedComponent={getFavoriteButton}
          />
        </div>
      </div>
      <WizardButton
        onNext={() => {
          if (!selectedItems.length) {
            dispatch(actionHubAlertError('Please select items'));
          } else {
            onNext();
          }
        }}
        onPrev={onPrev}
        currentPage={NudgeCreationState.PICK_ITEMS}
        nextPage={NudgeCreationState.REORDER}
        prevPage={PageView.HUB_NUDGES}
      />
      <style jsx>{`
        .search-col {
          padding-right: 0;
        }
        .lang-col {
          padding-left: 5px;
        }
        .container {
          max-width: 1800px;
          padding: 0;
        }
        .NudgeSearch {
          position: relative;
        }
        .NudgeSearch :global(h2) {
          display: none;
        }
        .search-container {
          margin-bottom: 0;
        }
        .NudgeSearch :global(sup) {
          border-radius: 10px;
          font-size: 0.6em;
          font-family: Arial;
          letter-spacing: 0;
          padding: 2px 5px;
          color: #000;
          background: ${color.accent};
        }
        .btn-outline-dark {
        }
        .NudgeSearch :global(.items .container) {
          padding: 0;
          max-width: none;
        }

        .NudgeSearch :global(.selectIcon) {
          text-align: center;
        }

        .NudgeSearch :global(.search-empty) {
          text-align: center;
          color: ${color.accent};
          width: 100%;
          height: calc(100vh - 300px);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .NudgeSearch :global(.btn-outline-secondary) {
          background: ${color.nudgeLightMode ? '#fff' : '#000'};
          color: ${color.nudgeLightMode ? '#000' : '#fff'};
        }

        .NudgeSearch :global(.items) {
          overflow: auto;
          overflow-x: hidden;
          position: relative;
          padding-top: 20px;
          padding-bottom: 80px;
        }
        .NudgeSearch :global(.footer) {
          display: none;
        }

        .heading {
          position: relative;
          z-index: 20;
        }
        .filters-container {
          position: absolute;
          left: 0;
          right: 0;
          top: 28px;
          border: 1px solid
            ${color.nudgeLightMode ? '#efefef' : 'rgb(179 179 179)'};
          border-top: none;
          background: ${color.nudgeLightMode ? color.light : color.dark};
        }

        .btn-filter {
          position: relative;
          z-index: 20;
        }
        .drop-item {
          position: absolute;
          background: #000;
          top: 30px;
          border: 1px solid rgb(179 179 179);
        }

        @media (max-width: 768px) {
          .btn-filter {
            width: 100%;
            margin-top: -1px;
            border: 1px solid
              ${color.nudgeLightMode ? '#fff' : 'rgb(179 179 179)'};
          }
          .btn-filter button {
            border-top: none !important;
            border-radius: 0;
            background: ${color.nudgeLightMode ? '#dae0e5' : '#666'};
            border: none;
          }
          .sticky-container {
            background: ${color.nudgeLightMode ? color.light : color.dark};
            padding-top: 20px;
            top: 0;
            z-index: 40;
            position: sticky;
          }
          .items-container,
          .heading {
            display: none;
          }
          .items-container.active,
          .active .heading {
            display: block;
          }
        }
        @media (min-width: 768px) {
          .NudgeSearch .heading {
            border-bottom: 1px solid ${color.accent};
            padding-bottom: 3px;
            display: flex;
            justify-content: space-between;
          }
          .filters-container {
            top: 33px;
          }
          .NudgeSearch :global(h2) {
            display: block;
            font-size: 1.2em;
            letter-spacing: 2px;
            margin-bottom: 0;
            text-align: left;
          }
          .NudgeSearch {
            text-align: left;
          }

          .NudgeSearch :global(.spinner-border-sm) {
            width: 1.4rem;
            height: 1.4rem;
          }

          .NudgeSearch :global(.items),
          .NudgeSearch :global(.placeholder-container) {
            height: calc(100vh - 370px);
          }
          .NudgeSearch :global(.search-empty) {
            height: calc(100vh - 520px);
          }
          .col-md-6:first-child {
            padding-right: 30px;
          }
          .col-md-6:last-child {
            padding-left: 30px;
          }
          .btn-tab {
            display: none;
          }
          .btn-filter button {
            background: transparent;
            border: none;
          }
          :global(.ssp) .btn-filter button {
            font-size: 1em;
          }
          .search-container {
            margin-bottom: 30px;
          }
        }
        @media (min-width: 992px) {
          .NudgeSearch :global(.items),
          .NudgeSearch :global(.placeholder-container) {
            height: calc(100vh - 390px);
          }
        }
      `}</style>
    </div>
  );
};

export default NudgeSearch;
