import React, { useContext } from 'react';
import { getCurrentUserInfo } from '../../../../clientSideServices/users';
import { PageView } from '../../../../utils/pageView';
import { HubContext } from '../../HubContext';
import UserManagementTour from './UserManagementTour';

const UserManagementGuid = () => {
  const [showDesignerTour, setShowDesignerTour] = React.useState<boolean>();
  const { setGuidedTour } = useContext(HubContext) || {};
  
  React.useEffect(() => {
    getCurrentUserInfo().then((userInfo) => {
      if (userInfo?.attributes?.['custom:gt_user_management'] !== 'skipped') {
        setShowDesignerTour(true);
        setGuidedTour(PageView.HUB_USERMANAGEMENT);
      }
    });
  }, []);

  return showDesignerTour ? <UserManagementTour onClose={() => setGuidedTour('')} /> : null;
};

export default UserManagementGuid;
