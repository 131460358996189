import React from 'react';

const UploadProgressIndicator = ({
  progressPercentage,
  color
}: {
  progressPercentage: number;
  color: string;
}) => {
  return (
    <div>
      <style jsx>
        {`
          div {
            width: ${`${progressPercentage}%`};
            height: 2px;
            background: ${color};
          }
        `}
      </style>
    </div>
  );
};

export default UploadProgressIndicator;
