import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { RiAddCircleLine, RiCloseFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  isAllowedForName,
  isValidEmail
} from '../../../components/VirtualBoutique/Appointment/inputValidator';
import {
  IMainState,
  ISessionFormData,
  ISessionParticipant
} from '../../../interfaces';
import { actionHubUpdateSessionFormData } from '../../../redux/actions';
import {
  DID_ADD_PARTICIPANT,
  DID_REMOVE_PARTICIPANT
} from '../../../utils/constants';

interface IFormPristine {
  name: boolean;
  email: boolean;
  role: boolean;
}

const mandatoryFields: (keyof ISessionParticipant)[] = ['name', 'email'];

const SessionParticipants = ({
  invalidParticipants,
  sessionFormData
}: {
  invalidParticipants: boolean;
  sessionFormData: ISessionFormData;
}) => {
  const dispatch = useDispatch();
  const [showPopup, togglePopup] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<ISessionParticipant>();

  const sessionsState = useSelector(
    (state: IMainState) => state.clientState?.hub?.sessions
  );

  const participants = sessionsState?.formData?.participants || [];
  const moreThanOneHost =
    participants.filter((participant) => participant.role === 'Host').length >
    1;

  const [pristine, setPristine] = React.useState<IFormPristine>({
    name: true,
    role: true,
    email: true
  });

  const invalidName = !pristine.name && !formData?.name;
  const noEmail = !pristine.email && !formData?.email;
  const invalidEmail =
    !pristine.email && formData?.email && !isValidEmail(formData?.email);

  const resetPristine = () => {
    const initialPristine: IFormPristine = {
      name: true,
      role: true,
      email: true
    };
    setPristine(initialPristine);
  };

  const removePristine = () => {
    const nonPristine: IFormPristine = {
      name: false,
      role: false,
      email: false
    };
    setPristine(nonPristine);
  };

  const setSessionFormData = (data: ISessionFormData) => {
    dispatch(actionHubUpdateSessionFormData(data));
  };

  const onRemove = (index: number) => {
    logEvent(DID_REMOVE_PARTICIPANT, DID_REMOVE_PARTICIPANT, {
      sessionId: sessionFormData?.id
    });

    const updatedParticipants = [
      ...participants.slice(0, index),
      ...participants.slice(index + 1)
    ];

    setSessionFormData({
      ...sessionFormData,
      participants: [...updatedParticipants]
    });
  };

  const onRemoveAll = () => {
    setSessionFormData({
      ...sessionFormData,
      participants: []
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event?.target;
    setPristine({ ...pristine, [name]: false });
    if (isAllowedForName(value)) {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event?.target;
    setPristine({ ...pristine, [name]: false });
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event?.target;
    setPristine({ ...pristine, role: false });
    setFormData({
      ...formData,
      role: value
    });
  };

  const handleWaitingLoungeChange = (val: boolean) => {
    setFormData({
      ...formData,
      noWaitingLounge: val ? 1 : 0
    });
  };

  const onSave = () => {
    removePristine();
    if (mandatoryFields.some((field) => !formData?.[field])) {
      return;
    }

    if (invalidEmail || invalidName || noEmail) {
      return;
    }

    const role = formData?.role || 'Guest';
    const noWaitingLounge = formData?.noWaitingLounge || 0;

    logEvent(DID_ADD_PARTICIPANT, DID_ADD_PARTICIPANT, {
      sessionId: sessionFormData?.id
    });

    setSessionFormData({
      ...sessionsState.formData,
      participants: [
        ...participants,
        {
          ...formData,
          role,
          noWaitingLounge
        }
      ]
    });
    clearForms();
    togglePopup(false);
  };

  const clearForms = () => {
    setFormData(null);
    resetPristine();
  };

  return (
    <div className="input-container">
      <label className="input-label">
        Participants
        <button
          onClick={(e) => {
            e.preventDefault();
            togglePopup(true);
          }}
          className="btn add-btn"
        >
          <RiAddCircleLine />
          Add
        </button>
      </label>
      <div className="participants-wrapper">
        {participants.map((participant, index) => (
          <div key={index} className="participant-item">
            <p className="participant-name">
              {participant?.name} <span>({participant?.role})</span>
            </p>
            <p className="participant-email">{participant?.email}</p>
            <div onClick={() => onRemove(index)} className="btn remove-btn">
              <RiCloseFill />
            </div>
          </div>
        ))}
      </div>
      {participants.length > 0 && (
        <div onClick={onRemoveAll} className="remove-all-btn btn">
          Remove all
        </div>
      )}
      {moreThanOneHost && (
        <span className="text-danger">More than one host</span>
      )}
      {invalidParticipants && <span className="text-danger"> Required</span>}
      <Modal
        show={showPopup}
        onHide={() => {
          clearForms();
          togglePopup(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Participant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-container">
            <label className="input-label">Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={formData?.name || ''}
              placeholder="Participant name"
              onChange={handleNameChange}
            />
            {invalidName && <span className="text-danger"> Required</span>}
          </div>
          <div className="input-container">
            <label className="input-label">Email</label>
            <input
              className="form-control"
              type="text"
              name="email"
              value={formData?.email || ''}
              placeholder="Participant email"
              onChange={handleInputChange}
            />
            {noEmail && <span className="text-danger"> Required</span>}
            {invalidEmail && (
              <span className="text-danger"> Invalid email</span>
            )}
          </div>
          <div className="input-container">
            <label className="input-label">Role</label>
            <select
              id="role"
              value={formData?.role}
              onChange={handleRoleChange}
              className="role-select"
            >
              <option value="" disabled defaultValue="">
                Select a role
              </option>
              <option value="Guest">Guest</option>
              <option value="Host">Host</option>
              <option value="MC">MC</option>
            </select>
          </div>
          <div className="input-container">
            <label className="input-label">
              Join session without waiting lounge
            </label>
            <span className="waiting-checkbox">
              <input
                type="checkbox"
                checked={!!formData?.noWaitingLounge || false}
                onChange={() => handleWaitingLoungeChange(true)}
              />{' '}
              Yes
            </span>
            <span className="waiting-checkbox">
              <input
                type="checkbox"
                checked={!formData?.noWaitingLounge}
                onChange={() => handleWaitingLoungeChange(false)}
              />{' '}
              No
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              clearForms();
              togglePopup(false);
            }}
          >
            Close
          </Button>
          <Button variant="dark" onClick={() => onSave()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <style jsx>
        {`
          .participants-wrapper {
            width: 100%;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            padding: 0.75rem 1.2rem;
            min-height: 295px;
          }
          .participant-item {
            border-bottom: 1px solid #ced4da;
            margin-bottom: 0.75rem;
            position: relative;
          }
          .participant-name {
            margin-bottom: 0;
            font-weight: 500;
          }
          .participant-email {
            margin-bottom: 0.75rem;
          }
          .participant-name span {
            font-weight: normal;
          }
          .add-btn {
            padding: 0;
            position: absolute;
            right: 15px;
            top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 2px;
            font-weight: 500;
          }
          :global(.ssp) .add-btn {
            font-size: 1em;
          }

          :global(.add-btn > svg) {
            margin-right: 5px;
          }

          .remove-btn {
            padding: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          .input-container {
            margin-bottom: 10px;
          }
          .role-select {
            width: 100%;
          }

          .remove-all-btn {
            float: right;
            padding-right: 0;
          }

          .waiting-checkbox {
            display: block;
          }
        `}
      </style>
    </div>
  );
};

export default SessionParticipants;
