import React from 'react';
import { INSPIFY_DARK_LOGO, INSPIFY_LIGHT_LOGO } from '../../../config';
import { getFontName } from '../BrandStyle/BrandCustomStyle';

interface BrandThemePreviewer {
  colors?: {
    button?: string;
    accent?: string;
    link?: string;
  };
  logoUrl?: string;
  logoFile?: File;
  dark?: boolean;
  font?: {
    headerFontUrl: string;
    bodyFontUrl: string;
    headerWeight?: string;
    bodyWeight?: string;
  };
  brandUrl?: string;
}

const inspifyLogoDark = INSPIFY_DARK_LOGO;
const inspifyLogoLight = INSPIFY_LIGHT_LOGO;
const BrandThemePreviewer: React.FC<BrandThemePreviewer> = (props) => {
  const defaultLogo = props.dark ? inspifyLogoLight : inspifyLogoDark;

  const accentColor = React.useMemo(
    () => props.colors?.accent || '#4B2953',
    [props.colors?.accent]
  );

  const headerFontName = props.font.headerFontUrl
    ? getFontName(props.font.headerFontUrl)
    : 'Montserrat';

  const bodyFontName = props.font.bodyFontUrl
    ? getFontName(props.font.bodyFontUrl)
    : 'Montserrat';

  return (
    <>
      <div className="wrapper px-4 border">
        <div className="text-left logo-preview">
          <img
            src={
              props.logoFile
                ? URL.createObjectURL(props.logoFile)
                : props.logoUrl || defaultLogo
            }
            alt="preview logo"
            className="mw-100 mh-100"
          />
        </div>

        <div className="text mt-1 py-2">
          <span className="header">
            This is a preview in {props.dark ? 'Dark' : 'Light'} background
          </span>
          <p className="text-base mb-1">
            This is an example of brief description.
          </p>
        </div>
        <div className="accent-line"></div>
      </div>

      <style jsx>
        {`
          .wrapper {
            background-color: ${props.dark ? '#000000' : '#FFFFFF'};
            color: ${props?.dark ? '#FFF' : '#000'};
            font-family: ${bodyFontName};
            max-width: 405px;
            padding-top: 3rem;
          }
          .logo-preview {
            height: 24px;
            max-width: 100%;
            width: auto;
          }
          .header {
            font-family: ${headerFontName}, sans-serif;
            font-size: 18px;
            font-weight: bold;
          }
          .progress {
            height: 0.25rem;
            justify-content: space-between;
          }

          .progress div.not-in {
            background-color: #c4c4c4;
          }
          .accent-line {
            border-bottom: ${accentColor || '#4B2953'} solid 1px;
            margin-bottom: 60px;
          }
          .text-base {
            font-size: 14px;
          }
          @media (max-width: 575.98px) {
            .header {
              font-size: 14px;
            }
          }
        `}
      </style>
    </>
  );
};

export default BrandThemePreviewer;
