import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  IMainState,
  ISessionDetails,
  ProgressStatus
} from '../../../interfaces';
import { mapSessionPayloadToFormData } from '../../../mappers/session';
import {
  actionHubLoadSessionList,
  actionHubSessionUpdateFileUploadStatus,
  actionHubUpdateSessionFormData
} from '../../../redux/actions';
import {
  DID_CLICK_BUTTON,
  DID_CLICK_SESSION_CREATE,
  DID_CLICK_SESSION_EDIT
} from '../../../utils/constants';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import { PageView } from '../../../utils/pageView';
import { deleteSession } from '../../clientSideServices/session';
import { sortSessionsChronologically } from '../../utils/common';
import { hubNavTo } from '../../utils/hubPagesRoute';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import { HubContext } from '../HubContext';
import FacebookEvent from '../UserManagement/FacebookAccounts/FacebookEvent';
import SessionItem from './SessionItem';

const SessionList = ({ date, host }: { date: Date; host?: string }) => {
  const dispatch = useDispatch();
  const [deleteSessionID, setDeleteSessionID] = React.useState(undefined);
  const [isMobile, setIsMobile] = React.useState(true);
  const hubState = useSelector((state: IMainState) => state.clientState?.hub);
  const { color } = React.useContext(HubContext);
  const storeId = hubState?.user?.storeId;
  const progressStatus = hubState?.sessions?.progressStatus;
  const isLoading = progressStatus === ProgressStatus.LOADING;
  const sessionList = hubState?.sessions?.sessionList || [];

  const dayOfMonth = format(date, 'd');
  const month = format(date, 'MMMM');
  const year = format(date, 'yyyy');

  const onViewSession = (session: ISessionDetails) => {
    const formattedFormData = mapSessionPayloadToFormData(session);
    dispatch(actionHubUpdateSessionFormData(formattedFormData));
    hubNavTo(PageView.HUB_SESSION_EDIT);
    logEvent(DID_CLICK_SESSION_EDIT, DID_CLICK_SESSION_EDIT, {
      sessionId: session?.id
    });
  };
  const onRemoveSession = (sessionID: string) => {
    deleteSession(sessionID).then(() => {
      setDeleteSessionID(undefined);
      const _sessionList = sessionList.filter(
        (session) => session.id !== sessionID
      );
      dispatch(actionHubLoadSessionList(_sessionList || []));
    });
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: 'session delete button',
      sessionId: sessionID
    });
  };
  const getContent = (onMobile?: boolean) => {
    if (isLoading) {
      return <div className="loading">Loading....</div>;
    }

    if (isEmpty(sessionList)) {
      return <p>No sessions available</p>;
    }

    return sortSessionsChronologically(sessionList).map((session) =>
      session.sessionType === 'facebook' ? (
        <FacebookEvent event={session} key={session.id} />
      ) : (
        <SessionItem
          session={session}
          storeId={storeId}
          onViewSession={onViewSession}
          key={session?.id}
          isMobile={onMobile}
          onRemoveSession={setDeleteSessionID}
          host={host}
        />
      )
    );
  };

  React.useEffect(() => {
    setIsMobile(isUserOnMobile());
  }, []);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="heading">
        <h6 className="title">
          Sessions on {month} {dayOfMonth} {year}
        </h6>
        <button
          onClick={() => {
            dispatch(actionHubUpdateSessionFormData(null));
            dispatch(actionHubSessionUpdateFileUploadStatus(null));
            hubNavTo(PageView.HUB_SESSION_CREATE);
            logEvent(DID_CLICK_SESSION_CREATE);
          }}
          className="btn create-btn btn-round btn-dark btn-sm session-btn"
        >
          Create
        </button>
      </div>
      <div className="sessions-container ">{getContent(isMobile)}</div>
      {deleteSessionID && (
        <ConfirmationPopup
          onClose={() => setDeleteSessionID(undefined)}
          onOk={() => onRemoveSession(deleteSessionID)}
        >
          <p className="text-semibold">
            Are you sure you want to
            <br />
            delete this session?
          </p>
        </ConfirmationPopup>
      )}
      <style jsx global>{`
        .dropdown {
          display: inline-block;
        }
        .dropdown-toggle {
          font-size: 12px;
          line-height: 24px;
          border: 1px solid #000;
        }
        .dropdown :global(svg) {
          display: inline-block;
          margin-right: 10px;
          height: 25px;
          width: 25px;
        }
      `}</style>
      <style jsx>
        {`
          .session-btn {
            min-width: 110px;
          }
          h6 {
            text-transform: uppercase;
            padding: 0;
            margin: 0;
          }
          :global(.ssp) h6 {
            font-size: 0.9em;
          }
          h6 > span {
            text-transform: none;
          }
          .heading {
            border-bottom: 2px solid ${color?.accent};
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
          }
          .sessions-container {
            margin: 20px 0;
            padding: 10px 5px;
            height: 100%;
            overflow: auto;
            min-height: 200px;
          }
          :global(.ssp) .sessions-container {
            max-height: 490px;
          }
          :global(.session-card) {
            padding: 10px 15px;
            display: flex;
            align-items: center;
            box-shadow: 0 0 4px 3px #f0f0f0;
            margin-bottom: 20px;
            border-radius: 5px;
            background: #fff;
            position: relative;
          }
          :global(.session-time) {
            text-align: right;
            font-weight: bold;
            font-size: 14px;
          }
          :global(.ssp .session-time) {
            font-size: 18px;
          }
          :global(.session-details) {
            border-left: 1px solid lightgray;
            margin-left: 15px;
            padding-left: 15px;
            max-width: calc(100% - 118px);
          }
          :global(.session-title) {
            font-weight: bold;
            font-size: 0.9em;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          :global(.session-title > svg) {
            margin-right: 10px;
            width: 16px;
            height: auto;
          }
          :global(.session-desc) {
            font-size: 12px;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          :global(.session-details .btn) {
            padding: 0 10px;
            margin-right: 5px;
          }
          :global(.join-btn) {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0 !important;
            display: flex;
            flex-direction: column;
          }
          :global(.article) {
            justify-content: center;
            display: inline-grid;
            grid-template-columns: 50px 1px 30px;
            background-color: #000;
            border-radius: 4px;
            height: 100%;
          }
          :global(.vl) {
            border-left: 1px solid #aaa;
            height: 50px;
          }
          :global(.join-btn .section) {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.8em;
            color: #fff;
          }
          :global(.join-btn .cross:hover) {
            background-color: #9e1414;
            border-radius: 4px;
          }
          :global(.add-btn),
          :global(.download-btn) {
            border: 1px solid black;
          }
          :global(.add-btn a),
          :global(.download-btn a) {
            color: inherit;
            font-size: 12px;
          }
          :global(.session-details .btn span) {
            font-size: 12px;
            color: black;
            padding-left: 5px;
          }

          :global(.ssp .session-details .btn span) {
            font-size: 20px;
          }
          :global(.session-details .join-btn span) {
            font-size: 14px;
            color: #fff;
            padding: 0;
            text-transform: uppercase;
          }
          :global(.session-time-wrapper) {
            position: relative;
          }
          :global(.status-icon) {
            position: absolute;
            left: 4px;
            top: 4px;
            text-align: center;
            border-radius: 100%;
          }
        `}
      </style>
    </div>
  );
};

export default SessionList;
