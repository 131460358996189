import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { getProductsByIds } from '../../../../clientSideServices/products';
import {
  BrowsingHistoryEventType,
  IBrowsingHistory,
  IProduct,
  Source
} from '../../../../interfaces';
import { actionOpenVBPopup } from '../../../../redux/actions';
import { DID_CLICK_ON_CHAT_MESSAGE } from '../../../../utils/constants';
import { getCurrentHost } from '../../../../utils/window';
import { getProductByModelCode } from '../../../clientSideServices/product';
import { HubContext } from '../../HubContext';

const ProductViewActivity = ({ history }: { history: IBrowsingHistory }) => {
  const { i18n } = useTranslation();
  const language = `${i18n?.language || 'en'}`;

  const [product, setProduct] = React.useState<IProduct>();
  const [loading, setLoading] = React.useState(false);

  const { storeId } = React.useContext(HubContext);
  const dispatch = useDispatch();

  const millisToMinutesAndSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000);
    if (minutes > 10) return '> 10 mins';
    const seconds = +((millis % 60000) / 1000).toFixed(0);
    const _min = minutes > 0 ? `0${minutes}`.slice(-2) + 'm ' : '';
    const _sec = `0${seconds}`.slice(-2) + 's';
    return `${_min}${_sec}`;
  };

  const durationInSec = millisToMinutesAndSeconds(history?.durationInMs);
  const eyeballTime =
    history?.event_type === BrowsingHistoryEventType.EYEBALL_TIME;

  const onClickProduct = (productId: string) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/products/${productId}?source=${Source.Chat}${
          storeId ? `&store=${storeId}` : ''
        }&language=${language}`,
        true
      )
    );
  };
  const resolveProduct = (promise: Promise<any>) => {
    setLoading(true);
    promise
      .then(([result]) => {
        setProduct(result);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  };
  React.useEffect(() => {
    if (history?.productId)
      resolveProduct(
        getProductsByIds([history.productId]).then((res) => res.data)
      );
    else if (history?.modelCode)
      resolveProduct(getProductByModelCode(history.modelCode));
  }, [history?.productId, history?.modelCode]);

  if (loading) {
    return <span>Loading product...</span>;
  }

  if (!loading && !product) return <span>Product not found</span>;

  return (
    <div
      className="item-card"
      onClick={() => {
        onClickProduct(product.id);
        logEvent(DID_CLICK_ON_CHAT_MESSAGE, DID_CLICK_ON_CHAT_MESSAGE, {
          id: product.id
        });
      }}
    >
      <div className="item-image-wrapper">
        <img className="item-image" src={product?.previewImageUrl} />
      </div>
      <div className="item-details">
        <div className="code">{product?.modelCode}</div>
        <div className="title">{product?.modelName}</div>
        {eyeballTime && (
          <div className="activity-duration">
            Viewed for <span>{durationInSec}</span>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .item-card {
            background: #efefef;
            border-radius: 10px;
            padding: 12px;
            padding-left: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            position: relative;
          }

          .item-image-wrapper {
            display: flex;
            flex: 0.3;
          }
          .item-image {
            width: auto;
            height: 113px;
            transform: scale(1.2);
          }
          .item-details {
            flex: 0.7;
            justify-content: center;
            display: flex;
            flex-direction: column;
            color: #666363;
          }
          .code {
            font-size: 10px;
          }
          .title {
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            color: #000000;
            text-transform: uppercase;
          }

          .activity-duration {
            font-size: 10px;
            margin-top: 10px;
          }
          .activity-duration span {
            color: #000000;
            font-weight: 600;
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            .wrapper {
              height: 72vh;
            }
            .tabs {
              padding: 0.844em 0;
            }
            .tab-item {
              padding: 10px;
              font-size: 14px;
              line-height: 24px;
            }

            .item-image {
              height: 80px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ProductViewActivity;
