import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { logEvent } from '../../../analytics';
import { createUser, saveUser } from '../../../clientSideServices/users';
import { isValidName } from '../../../components/VirtualBoutique/Appointment/inputValidator';
import { IUser } from '../../../interfaces';
import {
  DID_CLICK_UPDATE_CLIENT_NAME,
  DID_FAIL_TO_RENAME_CLIENT_SUCCESSFULLY,
  DID_RENAME_CLIENT_SUCCESSFULLY
} from '../../../utils/constants';
import { HubChatContext } from './HubChatContext';

const EditContactNameModal = ({ name, show, onToggle, user, identityId }) => {
  const [contactName, setContactName] = React.useState<string>(name);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const { addChatUsers } = React.useContext(HubChatContext);

  const invalidName = !isValidName(contactName);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target;
    setContactName(value);
  };

  const onUpdateName = () => {
    logEvent(DID_CLICK_UPDATE_CLIENT_NAME);

    if (user?.id) {
      updateUser({ ...user, alias: contactName.trim() });
      return;
    }

    createNewUser();
  };

  const updateUser = (_user: IUser) => {
    setUpdating(true);
    return saveUser(_user)
      .then(({ data }) => {
        if (data) {
          onToggle(false);
          addChatUsers(identityId, {
            ..._user
          });
          setUpdating(false);
          logEvent(DID_RENAME_CLIENT_SUCCESSFULLY);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setUpdating(false);
        logEvent(DID_FAIL_TO_RENAME_CLIENT_SUCCESSFULLY);
      });
  };

  const createNewUser = () => {
    const user = createUser(identityId, contactName.trim());
    updateUser(user);
  };

  React.useEffect(() => {
    setContactName(name);
  }, [name]);

  return (
    <Modal
      show={show}
      onHide={() => {
        onToggle(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="input-container">
          <label className="input-label">Name</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={contactName || ''}
            placeholder="Contact name"
            onChange={handleNameChange}
          />
          {invalidName && <span className="text-danger"> Invalid Name</span>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="save-button"
          disabled={invalidName || contactName.length === 0 || updating}
          variant="dark"
          onClick={() => onUpdateName()}
        >
          {updating ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditContactNameModal;
