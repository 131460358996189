import React from 'react';
import { BsX } from 'react-icons/bs';
import { MdSearch } from 'react-icons/md';
import { logEvent } from '../../../analytics';
import { ITeam, IUser } from '../../../interfaces';
import { DID_CLICK_BUTTON } from '../../../utils/constants';
import { TeamPageType } from './TeamPage';

import { mobileBreakPoint } from '../BrandStyle';
import { HubUserContext } from './HubUserContext';
import { defaultAvatarUrl } from '../../config';
import {
  createTeam,
  deleteTeamByID,
  updateTeam
} from '../../clientSideServices/user';
import format from 'date-fns/format';
import { HubContext } from '../HubContext';
import DeleteButton from '../Common/DeleteButton';
import UserLabel from '../Common/UserLabel';
import { Spinner } from 'react-bootstrap';
import {
  assetBaseUrl,
  inspifyBrandId,
  inspifyTestBrandId
} from '../../../config';
import { isImage } from '../../../utils/file';
import { isOnBoardingBrandByBrandId } from '../../utils/hubPagesRoute';

const CreateTeam = ({
  team,
  teamPage,
  setTeam,
  setTeamPage
}: {
  team: ITeam;
  teamPage: TeamPageType;
  setTeam: (team: ITeam) => void;
  setTeamPage: (teamPage: TeamPageType) => void;
}) => {
  const { usersByBrand, fetchUsersByBrand } = React.useContext(HubUserContext);
  const { user, setAddUpdateDeleteTeam, brandId } =
    React.useContext(HubContext);
  const [teamUsers, setTeamUsers] = React.useState<IUser[]>();
  const [teamState, setTeamState] = React.useState({
    keyword: '',
    teamName: team?.name,
    pristine: true,
    loading: false,
    selectedTeamDivHeight: 35
  });

  React.useEffect(() => {
    fetchUsersByBrand()
  }, []);

  React.useEffect(() => {
    if (!usersByBrand) {
      return;
    }
    setTeamUsers(
      usersByBrand?.filter((user) => {
        return team.members?.indexOf(user.id) > -1;
      })
    );
  }, [usersByBrand]);

  const handleMembers = (id) => {
    const members = teamUsers?.filter((user) => user.id != id);
    if (teamUsers?.find((user) => id === user.id)?.id) {
      setTeamUsers(members);
    } else {
      const user = usersByBrand?.find((user) => id === user.id);
      setTeamUsers([...teamUsers, user]);
    }
    setTimeout(
      () =>
        setTeamState({
          ...teamState,
          selectedTeamDivHeight:
            document.getElementById('selected-members')?.clientHeight
        }),
      5
    );
  };
  const createdDate = (date: string) =>
    new Date(date).toString() !== 'Invalid Date'
      ? format(new Date(date), 'MMM dd, yyyy - p')
      : 'unknown';
  const handleSave = () => {
    if (teamState.teamName.length === 0 || teamUsers.length === 0) {
      setTeamState({ ...teamState, pristine: false });
      return;
    }
    setTeamState({ ...teamState, loading: true });
    const userIds = teamUsers.map((user) => user.id);
    if (teamPage === TeamPageType.CREATE) {
      const _team = {
        ...team,
        name: teamState.teamName,
        createdAt: new Date().toISOString(),
        modifiedAt: new Date().toISOString(),
        members: userIds
      };
      createTeam(_team)
        .then(() => {
          setAddUpdateDeleteTeam({ team: _team, type: 'create' });
          setTeam(_team);
          setTeamPage(TeamPageType.TEAMS);
          setTeamState({ ...teamState, keyword: '', loading: false });
        })
        .catch((e) => console.error(e));
    } else {
      const _team = {
        ...team,
        name: teamState.teamName,
        modifiedAt: new Date().toISOString(),
        members: userIds
      };
      updateTeam(_team)
        .then(() => {
          setAddUpdateDeleteTeam({ team: _team, type: 'update' });
          setTeam(_team);
          setTeamPage(TeamPageType.TEAMS);
          setTeamState({ ...teamState, keyword: '', loading: false });
        })
        .catch((e) => console.error(e));
    }
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: 'team save button',
      teamID: team.id
    });
  };
  const getCreateHeader = () => {
    return (
      <>
        <div className="row create-team">
          <div className="col-xl-9 col-md-8 col-12">
            <input
              type="text"
              className="form-control create-team-input"
              placeholder="Team Name"
              value={teamState.teamName || ''}
              onChange={(e) => {
                setTeamState({
                  ...teamState,
                  teamName: e.target.value,
                  pristine: true
                });
              }}
            />
          </div>
          <div className="col-xl-3 col-md-4 col-12 create-btn ">
            <button
              type="button"
              name="submit"
              className="btn border border-dark  btn-round create-team-cancel"
              onClick={() => {
                setTeamUsers(
                  usersByBrand?.filter((user) => {
                    return team.members?.indexOf(user.id) > -1;
                  })
                );
                setTeamPage(TeamPageType.TEAMS);
                logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                  button: 'team cancel button',
                  teamID: team.id
                });
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              onClick={handleSave}
              disabled={teamState.loading}
              className="btn btn-dark  btn-round btn-save-btn"
            >
              {teamState.loading ? (
                <div className="load-container">
                  <Spinner size="sm" animation="border" />
                </div>
              ) : (
                'Save'
              )}
            </button>
            {team.members.length > 0 && teamPage === TeamPageType.EDIT && (
              <div className="delete-btn">
                <DeleteButton
                  onClick={() => {
                    setTeamState({ ...teamState, loading: true });
                    deleteTeamByID(team.id).then(() => {
                      setAddUpdateDeleteTeam({ team: team, type: 'delete' });
                      setTeamPage(TeamPageType.TEAMS);
                      setTeamState({ ...teamState, loading: false });
                    });
                    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                      button: 'team delete button',
                      teamID: team.id
                    });
                  }}
                  confirmation="Are you sure want to delete this Team?"
                  isLoading={teamState.loading}
                />
              </div>
            )}
          </div>
        </div>
        <style jsx>{`
          ::placeholder {
            color: #aaa;
          }
          .load-container {
          }
          .btn-save-btn {
            width: 100%;
          }
          .btn-save {
            margin-left: 0px;
          }
          input.form-control {
            padding: 0 5px 0 20px;
          }
          .form-control {
            border-radius: 5px;
            border: solid 1px
              ${!teamState.pristine && teamState.teamName.length === 0
                ? '#ff0000'
                : '#c4c4c4'};
            max-width: 100%;
            padding: 0 5px;
            font-size: 1rem;
          }
          .create-btn {
            padding-right: 0px;
            flex-direction: row;
            display: flex;
            transform: translateX(-10px);
          }
          .create-btn > button {
            width: 100%;
          }
          .create-team {
            height: 40px;
          }
          .delete-btn {
            transform: translate(0, 6px);
            margin-left: 3px;
          }
          @media (max-width: ${mobileBreakPoint}px) {
            .create-btn {
              padding-right: 0px;
              margin-top: 1rem;
              padding-right: 1rem;
              transform: translateX(-0px);
            }
            .create-team {
              height: 90px;
            }
          }
        `}</style>
      </>
    );
  };
  const getUrl = (memberId) => {
    const url = usersByBrand?.find((user) => user.id === memberId);
    return isImage(url?.avatar_picture) ? url.avatar_picture : defaultAvatarUrl;
  };
  const getDetailHeader = () => {
    return (
      <div className="header-search row">
        <div className="col-sm-9">
          <h6 className="detail-title-text">{team.name}</h6>
          <label className="created-text">
            Created by{' '}
            <strong>
              <UserLabel id={team.createdBy} emptyName="" />
            </strong>{' '}
            on {createdDate(team.createdAt)}
          </label>
        </div>
        <div className="col-sm-3 ">
          {user.id === team.modifiedBy && (
            <div className="header-button">
              <button
                className="btn border border-dark mr-2  col-5 edit-btn-detail"
                onClick={() => {
                  setTeamPage(TeamPageType.EDIT);
                  logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                    button: 'team edit button',
                    teamID: team.id
                  });
                }}
              >
                Edit
              </button>
              <DeleteButton
                onClick={() => {
                  setTeamState({ ...teamState, loading: true });
                  deleteTeamByID(team.id).then(() => {
                    setTeamPage(TeamPageType.TEAMS);
                    setTeamState({ ...teamState, loading: false });
                  });
                  logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                    button: 'team delete button',
                    teamID: team.id
                  });
                }}
                confirmation="Are you sure want to delete this Team?"
                isLoading={teamState.loading}
              />
            </div>
          )}
        </div>

        <style jsx>{`
          .detail-title-text {
            max-height: 35px;
            overflow: hidden;
          }
          .created-text {
            color: gray;
            font-size: 0.85rem;
            max-height: 35px;
            overflow: hidden;
          }
          .header-button {
            float: right;
          }
          .edit-btn-detail {
            border-radius: 50px;
            min-width: 80px;
          }
          .header-search {
            padding: 0px;
            height: 50px;
          }
          @media (max-width: ${mobileBreakPoint}px) {
            .header-search {
              padding: 0px;
              height: ${user.id === team.modifiedBy ? '120px' : '70px'};
            }
          }
        `}</style>
      </div>
    );
  };
  const getSelectedTeamMembers = () => {
    return (
      <>
        <div className="overflow-auto selected-members" id="selected-members">
          <div className="card-group">
            {teamUsers?.map((user, i) => (
              <div
                key={user.id}
                className="selected-member"
                id={`sel-member-${i}`}
              >
                <img
                  className="selected-member-img rounded-circle"
                  src={getUrl(user.id)}
                  onError={onError}
                />
                <span className="selected-username">{`${user.first_name} ${user.last_name}`}</span>
                {teamPage !== TeamPageType.DETAIL && (
                  <span
                    className="remove-user"
                    onClick={() => handleMembers(user.id)}
                  >
                    <BsX size={16} />
                  </span>
                )}
              </div>
            ))}
            {teamUsers?.length == 0 && (
              <div className="center">No members added.</div>
            )}
          </div>
        </div>
        <p />
        <style jsx>{`
          .center {
            text-align: center;
            width: 100%;
            color: #aaa;
          }
          .selected-username {
            padding: 0px 10px;
            font-weight: 500;
          }
          .remove-user {
            border: 1px solid #000;
            border-radius: 100px;
            padding: 0px 2px 1px 2px;
          }
          .selected-member {
            background: #f2f2f2;
            padding: 5px 10px;
            margin: 4px 5px;
            border-radius: 5px;
            width: fit-content;
            height: fit-content;
            cursor: pointer;
          }
          .selected-member-img {
            width: 24px;
            height: 24px;
          }
          .selected-card {
            background: gray;
          }
          .selected-members {
            border-radius: 5px;
            border: 1px solid
              ${!teamState.pristine && teamUsers?.length == 0
                ? '#ff0000'
                : '#ccc'};
            padding: 4px;
            max-height: 451px;
            overflow: hidden;
          }
          @media (max-width: 568px) {
            .selected-members {
              max-height: 150px;
            }
          }
        `}</style>
      </>
    );
  };

  const onError = (ev) => {
    if (ev?.target?.src) ev.target.src = defaultAvatarUrl;
  };
  const noSearchedResultsFound = () => {
    const noOfSearchUsers =
      teamPage === TeamPageType.DETAIL
        ? userSearchFilter(teamUsers)?.length
        : userSearchFilter(
            usersByBrand?.filter((user) => {
              return !teamUsers?.some((teamUser) => {
                return user.id === teamUser.id;
              });
            })
          )?.length;
    if (noOfSearchUsers === 0 && teamState.keyword.length === 0) {
      return (
        <>
          <p className="no-accounts-available">
            There are no accounts available.
          </p>
          <style jsx>{`
            .no-accounts-available {
              text-align: center;
              color: gray;
            }
          `}</style>
        </>
      );
    }
    if (noOfSearchUsers > 0 || teamState.keyword.length === 0) {
      return null;
    }
    return (
      <>
        <div className="noSearchedTeamsFound">
          <div className="child">
            <div>
              <img
                className="team-empty-img"
                src={`${assetBaseUrl}/icons/no-results.gif`}
              ></img>
            </div>
            <div className="text-container">
              <p className="message-text">
                No results found for <b>{teamState.keyword}</b>
              </p>
              <button
                onClick={() => setTeamState({ ...teamState, keyword: '' })}
                className="btn border border-dark  clear-btn"
              >
                <b>Clear Search</b>
              </button>
            </div>
          </div>
        </div>

        <style jsx>{`
          .noSearchedTeamsFound {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          .noSearchedTeamsFound .child {
            text-align: center;
          }
          .team-empty-img {
            width: 8rem;
            padding-top: 100px;
          }
          .message-text {
            font-size: 1.25em;
            color: gray;
          }
          .text-container {
            padding-top: 5rem;
            padding-bottom: 5rem;
          }
          .clear-btn {
            border-radius: 100px;
          }
          @media (max-width: 769px) {
            .text-container {
              padding-top: 0rem;
              padding-bottom: 1rem;
            }
            .message-text {
              font-size: 1em;
            }
            .team-empty-img {
              width: 6rem;
              padding-top: 0px;
            }
          }
        `}</style>
      </>
    );
  };
  const userSearchFilter = (users: IUser[]) =>
    brandId === inspifyBrandId ||
    brandId === inspifyTestBrandId ||
    isOnBoardingBrandByBrandId(brandId)
      ? users?.filter((user) => {
          const { first_name, last_name, user_name, alias, storeName } =
            user || {};
          const keyword = teamState.keyword?.toLowerCase();
          return (
            `${first_name} ${last_name}`?.toLowerCase().includes(keyword) ||
            user_name?.toLowerCase().includes(keyword) ||
            storeName?.toLowerCase().includes(keyword) ||
            alias?.toLowerCase().includes(keyword)
          );
        })
      : users?.filter((user) => {
          const { first_name, last_name, user_name, alias, email, storeName } =
            user || {};
          const keyword = teamState.keyword?.toLowerCase();
          return (
            (`${first_name} ${last_name}`?.toLowerCase().includes(keyword) ||
              storeName?.toLowerCase().includes(keyword) ||
              user_name?.toLowerCase().includes(keyword) ||
              alias?.toLowerCase().includes(keyword)) &&
            !email?.toLowerCase().includes('@inspify.com')
          );
        });
  const usersDetail = (user: IUser) => (
    <div className="team-row" key={user.id}>
      <hr className="solid" />
      <div className="row">
        <div className="col-2 col-md-1 col-xl-1 .col-lg-1 image-row ">
          <img
            className="member-img rounded-circle"
            src={getUrl(user.id)}
            onError={onError}
          />
        </div>
        <div className="col-8 col-md-9 col-xl-9 .col-lg-9">
          <h6>
            <UserLabel id={user.id} emptyName="" />
          </h6>
          <label className="email-text">{user.email}</label>
          {user?.storeName && (
            <p className="email-text storeName-text">{user.storeName}</p>
          )}
        </div>
        {teamPage !== TeamPageType.DETAIL && (
          <div className="col-2 col-md-2 col-xl-2 .col-lg-2  image-row ">
            <button
              type="button"
              name="submit"
              className="btn border border-dark btn-round float-right"
              onClick={() => {
                handleMembers(user.id);
              }}
            >
              Add
            </button>
          </div>
        )}
      </div>

      <style jsx>{`
        .solid {
          margin: 0px;
          transform: translateY(-12px);
        }
        .storeName-text {
          line-height: 14px;
        }
        .email-text {
          color: gray;
          font-size: 0.85rem;
        }
        .image-border {
          border: 3px solid #e0e0e0;
          border-radius: 100px;
          padding: 10px;
          padding-left: 20px;
        }
        .member-img {
          width: 48px;
          height: 48px;
        }
        .image-row {
          float: right;
        }
        .team-row {
          background-color: #fefefe;
          padding: 5px;
        }
      `}</style>
    </div>
  );
  return (
    <>
      <div className="teams-page h-100">
        <div className="container h-100">
          <div className="col-12">
            <div className="row team-title">
              <div className="col-4">
                {teamPage === TeamPageType.DETAIL && (
                  <button
                    onClick={() => {
                      setTeamPage(TeamPageType.TEAMS);
                    }}
                    className="btn border border-dark back-btn"
                  >
                    Back
                  </button>
                )}
              </div>
              <h1 className="col-4">TEAM</h1>
            </div>
            {teamPage === TeamPageType.DETAIL
              ? getDetailHeader()
              : getCreateHeader()}
            <hr className="solid" />
            <span className="number-members">{`${teamUsers?.length} Member${
              teamUsers?.length === 1 ? '' : 's'
            }`}</span>
            {getSelectedTeamMembers()}

            {teamPage !== TeamPageType.DETAIL && (
              <div className="search-box">
                <div className="team-search row">
                  <div className="col-xl-12 ">
                    <input
                      type="text"
                      className="form-control search"
                      placeholder="Search"
                      value={teamState.keyword}
                      onChange={(e) => {
                        setTeamState({ ...teamState, keyword: e.target.value });
                      }}
                    />
                    {teamState.keyword && (
                      <div
                        className="btn btn-sm btn-clear"
                        onClick={() => {
                          setTeamState({ ...teamState, keyword: '' });
                        }}
                      >
                        <BsX />
                      </div>
                    )}
                    <div className="btn btn-search ssp-ignore">
                      <MdSearch />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="overflow-auto team-user-list col">
            {teamPage !== TeamPageType.DETAIL &&
              userSearchFilter(
                usersByBrand?.filter((user) => {
                  return !teamUsers?.some((teamUser) => {
                    return user.id === teamUser.id;
                  });
                })
              )?.map((user) => usersDetail(user))}
            {noSearchedResultsFound()}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          ::placeholder {
            color: #aaa;
          }
          .container {
            padding: 0px 30px;
          }
          .team-title {
            height: 50px;
          }
          .number-members {
            height: 10px;
            font-size: 0.85rem;
          }
          .search-box {
            height: 40px;
          }
          .input-round {
            border-radius: 100px;
            border: 2px solid #609;
          }
          .back-btn {
            float: left;
            border-radius: 20px;
            margin-left: 0px;
          }
          .team-user-list {
            margin-top: 16px;
            height: calc(100% - ${260 + teamState.selectedTeamDivHeight}px);
            overflow: hidden;
          }
          .userList {
            display: flex;
            flex-wrap: wrap;
          }
          hr.solid {
            border-top: 1px solid #bbb;
          }
          .team-search .btn {
            border-radius: 50px;
            min-width: 80px;
          }
          .teams-page {
            padding-top: 5px;
            margin: 0 auto;
            max-width: 1400px;
            width: 100%;
            height: 100%;
            font-size: 16px;
          }
          .btn-search,
          .btn-clear {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .btn-search {
            left: 0;
          }
          .btn-clear {
            right: 0px;
          }
          input.form-control {
            padding: 0 30px 0 15px;
          }
          .search.form-control {
            padding: 0 35px 0 35px;
          }
          .form-control {
            border-radius: 5px;
            border: #ccc solid 1px;
            max-width: 100%;
            padding: 0 5px;
            font-size: 1rem;
          }
          .card {
            background: transparent;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            border: 0;
            margin-bottom: 1rem;
          }
          .card-body {
            padding: 0;
          }
          .invalid-message {
            color: red;
            font-size: 0.8rem;
          }
          @media (max-width: 768px) {
            .team-user-list {
              margin-top: 16px;
              height: calc(100% - ${300 + teamState.selectedTeamDivHeight}px);
              overflow: hidden;
            }
            .container {
              padding: 0px;
            }
          }
        `}
      </style>
    </>
  );
};

export default CreateTeam;
