import { truncate } from 'lodash';
import { Spinner } from 'react-bootstrap';
import { BsImages } from 'react-icons/bs';
import { getInspirationIcon } from '../../../components/Inspiration/common';
import { IInspiration } from '../../../interfaces';
import UserLabel from '../Common/UserLabel';

const GalleryItemContainer = ({
  galleryItem,
  onClick,
  isSelected
}: {
  galleryItem: IInspiration;
  onClick: () => void;
  isSelected?: boolean;
}) => (
  <div
    className={`galleryItem gallery-${galleryItem.id} ${
      isSelected ? 'active' : ''
    }`}
    onClick={onClick}
  >
    <div className="row">
      <div className="thumbnail-container col-6">
        <img
          src="/asset/video_ratio_maintainer.png"
          className="ratio-maintainer"
          alt=""
        />
        {!!galleryItem.duration && (
          <span className="duration">{galleryItem.duration}</span>
        )}
        {getInspirationIcon(galleryItem)}
      </div>
      <div className="content-container col-6">
        <strong>{truncate(galleryItem.title, { length: 40 })}</strong>
        <div className="desc">
          <span><UserLabel id={galleryItem.createdBy} /></span>
          <span>{galleryItem.submittedAt}</span>
        </div>
      </div>
    </div>

    <style jsx>{`
      .visibility {
        text-transform: capitalize;
      }
      .galleryItem {
        padding: 5px 20px;
        display:flex;
        flex-direction:column;
      }
      .row {
        border-radius: 15px;
        overflow: hidden;
        background: #f3f3f3;
        border: 2px solid #fff;
        box-shadow: 0 0 5px #efefef;
        font-size: 13px;
        cursor: pointer;
        margin: 0;
        height:100%;
      }
      .row:hover {
        box-shadow: 0 0 10px #ccc;
      }

      .thumbnail-container {
        position: relative;
        width: 200px;
        background: url(${galleryItem.flipImageUrl}) center center no-repeat;
        background-size: cover;
        overflow: hidden;
        padding: 0;
      }
      .thumbnail-container :global(.video-icon) {
        transform: translate(-50%, -50%) !important;
        width: 40% !important;
      }
      .duration {
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #fff;
        font-size: 11px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 3px;
        display: inline-block;
        padding: 2px;
        letter-spacing: 0.3px;
      }
      .content-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .desc {
        font-size: 0.7em;
      }
      .desc span {
        display: block;
      }
      .ratio-maintainer {
        width: 100%;
        height: auto;
      }
      @media (min-width: 600px) {
        .desc {
          font-size: 0.8em;
        }
        :global(.fullScreen) .galleryItem {
          padding: 15px !important;
        }
        :global(.fullScreen) .row {
          display: block;
        }
        :global(.fullScreen) .desc {
          display: inline-block;
          margin-right: 20px;
        }
        :global(.fullScreen) .col-6 {
          width: 100%;
          flex: none;
          max-width: none;
        }

        :global(.fullScreen) .content-container {
          padding: 20px !important;
        }
      }
    `}</style>
  </div>
);

const HubGalleryItemList = ({
  gallery,
  onSelectGalleryItem,
  selectedGalleryItem,
  loading,
  reference,
  onClearFilter
}: {
  gallery: IInspiration[];
  onSelectGalleryItem: (id: IInspiration | undefined) => void;
  selectedGalleryItem?: IInspiration;
  loading?: boolean;
  reference: any;
  onClearFilter?: () => void;
}) => {
  return (
    <div
      key={'hubGalleryItemList'}
      className={`HubGalleryList ${
        selectedGalleryItem ? 'hasSelected' : 'fullScreen'
      }`}
    >
      <div className="galleryList" ref={reference}>
        {gallery?.map((item) => (
          <GalleryItemContainer
            key={item.id}
            onClick={() => onSelectGalleryItem(item)}
            isSelected={selectedGalleryItem?.id === item.id}
            galleryItem={item}
          />
        ))}
      </div>

      {loading && (
        <div className="text-center load-container">
          <Spinner animation="border" />
        </div>
      )}

      {!loading && !gallery.length && (
        <div className="not-found">
          <p className="text-center">
            <BsImages color="#d2d2d1" />
            <br />
            There are no videos or photos at the moment
            <br />
            <br />
            {onClearFilter && (
              <button
                className="btn btn-sm btn-outline-dark"
                onClick={onClearFilter}
              >
                Clear Filter
              </button>
            )}
          </p>
        </div>
      )}

      <style jsx>{`
        .not-found {
          height: 100%;
          display: flex;
          align-items: center;
        }
        .not-found > * {
          margin: auto;
        }
        .not-found :global(svg) {
          width: 100%;
          max-width: 200px;
          height: auto;
        }
        .load-container {
          margin-top: 60px;
        }
        .HubGalleryList {
          overflow: auto;
          height: 100%;
        }
        .galleryList {
          display: flex;
          flex-wrap: wrap;
        }
        .galleryList :global(.galleryItem) {
          width: 100%;
        }

        @media (min-width: 600px) {
          .fullScreen .galleryList :global(.galleryItem) {
            width: 50%;
          }
        }

        @media (min-width: 800px) {
          .fullScreen .galleryList :global(.galleryItem) {
            width: 33.33%;
          }
        }

        @media (min-width: 1280px) {
          .fullScreen .galleryList :global(.galleryItem) {
            width: 25%;
          }
        }

        @media (min-width: 1800px) {
          .fullScreen .galleryList :global(.galleryItem) {
            width: 20%;
          }
        }
        @media (min-width: 1920px) {
          .fullScreen .galleryList :global(.galleryItem) {
            width: 16.666%;
          }
        }
      `}</style>
    </div>
  );
};

export default HubGalleryItemList;
