import React from 'react';
import { GiPlainCircle } from 'react-icons/gi';

export interface ITab {
  id: string;
  label: string;
}

function SLAnalyticsTabs({
  tabs = [],
  active,
  onSelectTab,
  dot
}: {
  tabs?: ITab[];
  active?: ITab;
  onSelectTab: Function;
  dot?: boolean;
}) {
  return (
    <>
      <div className="analytics-tabs">
        {tabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => onSelectTab(tab)}
            className={`analytics-tab ${
              tab.id === active.id ? 'active-tab' : ''
            }`}
          >
            {dot && <GiPlainCircle width={15} height={15} />}
            {tab.label}
          </div>
        ))}
      </div>
      <style jsx>{`
        .analytics-tabs {
          width: 100%;
          border-bottom: 1px solid #d8d8d8;
          display: flex;
          flex-wrap: wrap;
        }
        .analytics-tab {
          color: rgba(0, 0, 0, 0.5);
          font-size: 18px;
          font-weight: 500;
          padding: 15px;
          cursor: pointer;
        }
        :global(.analytics-tab svg) {
          margin-right: 5px;
          min-width: 15px;
          max-width: 15px;
        }
        .active-tab {
          color: #8f6599;
          border-bottom: 2px solid #8f6599;
        }
      `}</style>
    </>
  );
}

export default SLAnalyticsTabs;
