import React from 'react';
import { ITableColumn, SortTable } from '../../Common/Table';
import { formatDurationFromMiliSeconds } from '../../../../utils/number';

const DeviceAnalyticsStories = ({
  setSelectedItem,
  setListItemTab,
  loading,
  stories
}) => {
  const storyColumns: ITableColumn[] = [
    {
      key: 'story',
      title: 'Story',
      align: 'left',
      custom: (value, rowIndex) => {
        return (
          <div className="story">
            <span>{rowIndex + 1}</span>
            <img src={value.url} />
            <div>{value.title}</div>
          </div>
        );
      }
    },
    {
      key: 'views',
      title: 'Views',
      align: 'center'
    },
    {
      key: 'ats',
      title: 'Average Time Spent (min)',
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    },
    {
      key: 'qr',
      title: 'QR Scans',
      align: 'center'
    }
  ];

  const handleSelectRow = (row) => {
    setSelectedItem({ ...row, type: 'story' });
    setListItemTab(stories);
  };
  return (
    <>
      <div className="analytics_scenes" data-isloading={loading}>
        <SortTable
          columns={storyColumns}
          sorts={['view', 'ats', 'qr']}
          data={stories}
          onRowSelect={handleSelectRow}
          onlyActiveSortBy
        />
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>
      <style jsx>{`
        .analytics_scenes[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }

        .analytics_scenes[data-isloading='true'] .overlay {
          display: flex;
        }
        :global(.type-container) {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
        :global(.badge-icon) {
          background: purple;
          width: 12px;
          height: 12px;
          border-radius: 100%;
        }
        :global(.story) {
          display: flex;
          align-items: center;
        }

        :global(.story img) {
          width: 55px;
          height: auto;
          margin-left: 50px;
          margin-right: 30px;
        }
        :global(.analytics_scenes .sort-table td) {
          font-weight: 500;
          color: #252525;
          cursor: default;
        }
      `}</style>
    </>
  );
};

export default DeviceAnalyticsStories;
