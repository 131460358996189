import React, { useContext } from 'react';
import { loubiAirwaysJpBoutiqueId } from '../../config';
import { NudgeCreationType, HUB_USER_PERMISSION } from '../../interfaces';
import { PageView } from '../../utils/pageView';
import { hubNavTo } from '../utils/hubPagesRoute';
import HubChat from './ContactCenter/HubChat';
import HubChatDetails from './ContactCenter/HubChatDetails';
import HubGallery from './Gallery/HubGallery';
import { HubContext } from './HubContext';
import Nudge from './Nudge';
import NudgeCurator from './Nudge/NudgeCurator';
import NudgePreview, { TemplatePreviewAction } from './Nudge/NudgePreview';
import NudgeTemplates from './Nudge/NudgeTemplates';
import PreConfiguredInvitesOverview from './PreConfiguredInvites/Overview';
import SessionCreateEdit from './SessionManagement/SessionCreateEdit';
import SessionOverView from './SessionManagement/SessionOverView';
import StoryBook from './StoryBook';
import HubUser from './UserManagement/HubUser';
import {
  isInspifyTestBrand,
  reqMeetUserType,
  shouldShowStorybooks
} from '../utils/hubEntitlements';
import {
  shouldShowAdmin,
  shouldShowGallery,
  shouldShowInvites,
  shouldShowProfile,
  shouldShowTeams,
  shouldShowTemplates
} from '../../utils/entitlements';
import MeetingRoomsOverView from './MeetingRooms/MeetingRoomsOverview';
import SceneLibrary from './SceneLibrary';
import BrandSettingsPage from './BrandSettings/BrandSettingsPage';
import { ContactSupport } from './ContractSupport';
import AssetLibraryPage from './AssetLibrary';
import { throttle } from 'lodash';
import { getViewportSize } from '../../utils/window';
import { isUserOnMobile, isUserOnMobileOnly } from '../../utils/deviceDetector';
import { getViewportType } from '../../utils/meeting';
import { actionUpdateViewportInfo } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import AccountPage from './Account';
import DeviceOverview from './Devices';
import DeviceMonitoring from './DevicesMonitoring';

const HubPages = React.memo(() => {
  const {
    page,
    storeId,
    brandId,
    user,
    meetUser,
    isAdmin,
    entitlements,
    userRoles,
    isStudioOwnerUser,
    checkUserPermissions,
    chooseCheckPermissionResult,
    checkUserHasEntitlement
  } = useContext(HubContext);
  const getPreferredComponent = (component) => {
    return !reqMeetUserType.includes(user?.userType) ? (
      component
    ) : (
      <SessionOverView />
    );
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    const onViewPortResize = throttle(() => {
      const { vh, vw } = getViewportSize();
      const viewportInfo = {
        width: vw,
        height: vh,
        onPhone: isUserOnMobileOnly(),
        onMobile: isUserOnMobile(),
        type: getViewportType({ width: vw, height: vh })
      };
      dispatch(actionUpdateViewportInfo(viewportInfo));
    }, 200);
    onViewPortResize();
    window.addEventListener('resize', onViewPortResize);
    return () => {
      window.removeEventListener('resize', onViewPortResize);
    };
  }, []);

  switch (page) {
    case PageView.HUB_NUDGES: {
      const hasPermission = chooseCheckPermissionResult(
        shouldShowInvites(user?.userType, entitlements?.meetOnly),
        checkUserPermissions(HUB_USER_PERMISSION.NUDGE)
      );

      const NudgePage =
        storeId === loubiAirwaysJpBoutiqueId ? (
          <PreConfiguredInvitesOverview />
        ) : (
          <Nudge />
        );

      return hasPermission ? NudgePage : <SessionOverView />;
    }
    case PageView.HUB_NUDGES_CREATE:
      return getPreferredComponent(<NudgeCurator />);

    case PageView.HUB_NUDGES_CREATE_FROM_TEMPLATE:
      return getPreferredComponent(
        <NudgeCurator creationType={NudgeCreationType.CREATE_FROM_TEMPLATE} />
      );

    case PageView.HUB_NUDGES_COPY:
      return getPreferredComponent(
        <NudgeCurator creationType={NudgeCreationType.COPY} />
      );

    case PageView.HUB_NUDGES_VIEW:
      return getPreferredComponent(<NudgePreview onBack={() => null} />);

    case PageView.HUB_SESSION_OVERVIEW:
      return <SessionOverView />;

    case PageView.HUB_MEETING_ROOMS:
      return <MeetingRoomsOverView />;

    case PageView.HUB_NUDGE_TEMPLATES: {
      const hasPermission = chooseCheckPermissionResult(
        shouldShowTemplates(user?.userType, entitlements?.meetOnly),
        checkUserPermissions(HUB_USER_PERMISSION.NUDGE_TEMPLATE)
      );
      return hasPermission ? <NudgeTemplates /> : <SessionOverView />;
    }

    case PageView.HUB_NUDGE_TEMPLATES_SELECT:
      return getPreferredComponent(
        <NudgeTemplates
          selectMode={true}
          onBack={() => {
            hubNavTo(PageView.NUDGE);
          }}
        />
      );

    case PageView.HUB_NUDGE_TEMPLATE_EDIT:
      return getPreferredComponent(
        <NudgeCurator creationType={NudgeCreationType.EDIT_TEMPLATE} />
      );

    case PageView.HUB_NUDGES_TEMPLATE_CREATE:
      return getPreferredComponent(
        <NudgeCurator creationType={NudgeCreationType.NEW_TEMPLATE} />
      );

    case PageView.HUB_NUDGE_TEMPLATE_COPY:
      return getPreferredComponent(
        <NudgeCurator creationType={NudgeCreationType.COPY_TEMPLATE} />
      );

    case PageView.HUB_NUDGE_TEMPLATE_VIEW:
      return getPreferredComponent(
        <NudgePreview
          onBack={() => null}
          templateAction={TemplatePreviewAction.VIEW}
        />
      );

    case PageView.HUB_NUDGE_TEMPLATE_MANAGE:
      return getPreferredComponent(
        <NudgePreview
          onBack={() => null}
          templateAction={TemplatePreviewAction.EDIT}
        />
      );

    case PageView.HUB_SESSION_CREATE:
      return <SessionCreateEdit />;
    case PageView.HUB_SESSION_EDIT:
      return <SessionCreateEdit isEdit />;

    case PageView.HUB_MEETING_ROOM_CREATE:
      return <SessionCreateEdit isMeetingRoom />;
    case PageView.HUB_MEETING_ROOM_EDIT:
      return <SessionCreateEdit isMeetingRoom isEdit />;

    case PageView.HUB_CONTACT_CENTER:
      return getPreferredComponent(<HubChat />);

    case PageView.HUB_CHAT_DETAILS:
      return getPreferredComponent(<HubChatDetails />);

    case PageView.HUB_USERMANAGEMENT: {
      const hasPermission = chooseCheckPermissionResult(
        shouldShowAdmin(meetUser?.permissions, user?.userType, isAdmin),
        checkUserPermissions(HUB_USER_PERMISSION.USER_MANAGEMENT)
      );
      return hasPermission ? <HubUser /> : <SessionOverView />;
    }
    case PageView.HUB_BRANDING: {
      const hasPermission = checkUserPermissions(HUB_USER_PERMISSION.BRANDING);

      return hasPermission ? <BrandSettingsPage /> : <SessionOverView />;
    }
    case PageView.HUB_ACCOUNT: {
      const hasPermission =
        isAdmin ||
        userRoles?.some(
          (r) => r.name.includes('ADMIN') || r.name.includes('OWNER')
        ) ||
        isStudioOwnerUser();

      return hasPermission ? <AccountPage /> : <SessionOverView />;
    }

    case PageView.HUB_TEAMS: {
      const hasPermission = chooseCheckPermissionResult(
        shouldShowTeams({
          brandId,
          permissions: meetUser?.permissions,
          userType: user?.userType,
          storeId
        }),
        checkUserPermissions(HUB_USER_PERMISSION.TEAM)
      );

      return hasPermission ? <HubUser /> : <SessionOverView />;
    }

    case PageView.HUB_ACCOUNTSETTINGS: {
      const hasPermission = chooseCheckPermissionResult(
        shouldShowProfile(meetUser?.permissions, user?.userType),
        checkUserPermissions(HUB_USER_PERMISSION.PROFILE)
      );

      return hasPermission ? <HubUser /> : <SessionOverView />;
    }

    case PageView.HUB_GALLERY: {
      const hastPermission = chooseCheckPermissionResult(
        shouldShowGallery(user?.userType, entitlements?.meetOnly),
        checkUserPermissions(HUB_USER_PERMISSION.GALLERY)
      );
      return hastPermission ? (
        <HubGallery storeId={storeId} brandId={brandId} user={user} />
      ) : (
        <SessionOverView />
      );
    }

    case PageView.HUB_STORYBOOK: {
      const hasPermission = chooseCheckPermissionResult(
        shouldShowStorybooks({
          storeId,
          brandId,
          permissions: meetUser?.permissions,
          userType: user?.userType
        }),
        checkUserPermissions(HUB_USER_PERMISSION.STORYBOOK)
      );
      return hasPermission ? <StoryBook /> : <SessionOverView />;
    }

    case PageView.HUB_SCENE: {
      const hasPermission = chooseCheckPermissionResult(
        shouldShowStorybooks({
          storeId,
          brandId,
          permissions: meetUser?.permissions,
          userType: user?.userType
        }),
        checkUserPermissions(HUB_USER_PERMISSION.SCENE)
      );

      return hasPermission ? (
        <SceneLibrary userId={user?.id} />
      ) : (
        <SessionOverView />
      );
    }
    case PageView.HUB_SCENE_TEMPLATE: {
      const hasPermission =
        isInspifyTestBrand(brandId) ||
        checkUserPermissions(HUB_USER_PERMISSION.SCENE_TEMPLATE);

      return hasPermission ? (
        <SceneLibrary userId={user?.id} context="template" key="template" />
      ) : (
        <SessionOverView />
      );
    }
    case PageView.HUB_CONTACT_SUPPORT:
      return <ContactSupport />;

    case PageView.HUB_ASSET_LIBRARY: {
      const hasPermission = checkUserPermissions(
        HUB_USER_PERMISSION.ASSET_LIBRARY
      );
      return hasPermission ? <AssetLibraryPage /> : <SessionOverView />;
    }

    case PageView.HUB_DEVICES_MANAGEMENT: {
      const hasPermission = checkUserHasEntitlement(
        HUB_USER_PERMISSION.DEVICES_MANAGEMENT
      );
      return hasPermission ? <DeviceOverview /> : <SessionOverView />;
    }
    case PageView.HUB_DEVIVES_MONITORING: {
      const hasPermission = checkUserHasEntitlement(
        HUB_USER_PERMISSION.DEVICES_MONITORING
      );
      return hasPermission ? <DeviceMonitoring /> : <SessionOverView />;
    }
    default:
      return <SessionOverView />;
  }
});

export default HubPages;
