import React, { useContext, useEffect, useState } from 'react';
import {
  getBrandingSetup,
  setupBrand
} from '../../../clientSideServices/brand';
import {
  mapAccountBrandToUpdatePayload,
  mapBrandSetupRes
} from '../../utils/mapper';
import FolderButton from '../Common/Folder/FolderButton';
import { IconBrandProfile, IconTheme, IconAsset } from '../Common/HubIcons';
import { HubContext } from '../HubContext';
import Layout from '../Layout';
import BrandAssets from './BrandAssets';
import BrandDetails from './BrandProfile';
import BrandTheme from './BrandTheme';
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';
import { logClickButton } from '../../../analytics';
import {
  DID_CLICK_BRAND_ASSET_MENU,
  DID_CLICK_BRAND_PROFILE_MENU,
  DID_CLICK_BRAND_THEME_MENU
} from '../../../utils/constants';
import BrandVirtualBackgrounds from './BrandVirtualBackgrounds';

const BrandSettingsPage: React.FC = () => {
  const { brandSetup, setBrandSetup, brandSetupStep, account } =
    useContext(HubContext);

  const [activeMenu, setActiveMenu] = useState<
    'brand_profile' | 'brand_assets' | 'brand_theme' | 'brand_vb'
  >('brand_profile');
  const [mobileMenuViewIndex, setMobileViewIndex] = useState(0);

  useEffect(() => {
    getBrandingSetup(brandSetup?.brandUrl).then(({ data }) => {
      const brandingData = mapBrandSetupRes(data);
      setBrandSetup((prev) => ({
        ...prev,
        brandSetup: brandingData
      }));
    });

    if (brandSetupStep === 1) {
      const updatePayload = mapAccountBrandToUpdatePayload(
        account,
        brandSetup,
        2
      );
      setBrandSetup((prev) => ({ ...prev, step: 2 }));
      setupBrand(updatePayload);
    }
  }, [brandSetupStep]);

  return (
    <Layout showNav={true} theme="dark-light" className="layoutSessions">
      <div className="brand-settings">
        <div className="heading border-bottom d-none d-sm-block">
          <h2 className="text-left mb-2">Branding</h2>
        </div>
        <div className="main">
          <div className="d-sm-block d-none menu">
            <div
              className={`menu-item ${
                activeMenu === 'brand_profile' ? 'active' : ''
              }`}
            >
              <FolderButton
                label="Brand Profile"
                id="brand_profile"
                icon={<IconBrandProfile />}
                onClick={() => {
                  setActiveMenu('brand_profile');
                  logClickButton(DID_CLICK_BRAND_PROFILE_MENU);
                }}
              />
            </div>
            <div
              className={`menu-item ${
                activeMenu === 'brand_theme' ? 'active' : ''
              }`}
            >
              <FolderButton
                label="Brand Theme"
                id="brand_theme"
                icon={<IconTheme />}
                onClick={() => {
                  setActiveMenu('brand_theme');
                  logClickButton(DID_CLICK_BRAND_THEME_MENU);
                }}
              />
            </div>
            <div
              className={`menu-item ${
                activeMenu === 'brand_assets' ? 'active' : ''
              }`}
            >
              <FolderButton
                label="Brand Assets"
                id="brand_assets"
                icon={<IconAsset />}
                onClick={() => {
                  setActiveMenu('brand_assets');
                  logClickButton(DID_CLICK_BRAND_ASSET_MENU);
                }}
              />
            </div>
            <div
              className={`menu-item ${
                activeMenu === 'brand_vb' ? 'active' : ''
              }`}
            >
              <FolderButton
                label="Brand V. Backgrounds"
                id="brand_vb"
                icon={<IconAsset />}
                onClick={() => {
                  setActiveMenu('brand_vb');
                  logClickButton(DID_CLICK_BRAND_ASSET_MENU);
                }}
              />
            </div>
          </div>
          <div className="mobile-menu d-sm-none ">
            <div
              className={`menu d-sm-none view-0 ${
                mobileMenuViewIndex === 0 ? 'show' : 'hide'
              } `}
            >
              <div
                className={`menu-item ${
                  activeMenu === 'brand_profile' ? 'active' : ''
                }`}
              >
                <FolderButton
                  label="Brand Profile"
                  id="brand_profile"
                  icon={<IconBrandProfile />}
                  onClick={() => {
                    setActiveMenu('brand_profile');
                    logClickButton(DID_CLICK_BRAND_PROFILE_MENU);
                  }}
                />
              </div>
              <div
                className={`menu-item ${
                  activeMenu === 'brand_theme' ? 'active' : ''
                }`}
              >
                <FolderButton
                  label="Brand Theme"
                  id="brand_theme"
                  icon={<IconTheme />}
                  onClick={() => {
                    setActiveMenu('brand_theme');
                    logClickButton(DID_CLICK_BRAND_THEME_MENU);
                  }}
                />
                {mobileMenuViewIndex === 0 && (
                  <AiOutlineCaretRight onClick={() => setMobileViewIndex(1)} />
                )}
              </div>
              <div
                className={`menu-item ${
                  activeMenu === 'brand_vb' ? 'active' : ''
                }`}
              >
                <FolderButton
                  label="Brand V. Backgrounds"
                  id="brand_vb"
                  icon={<IconTheme />}
                  onClick={() => {
                    setActiveMenu('brand_vb');
                    logClickButton(DID_CLICK_BRAND_THEME_MENU);
                  }}
                />
                {mobileMenuViewIndex === 0 && (
                  <AiOutlineCaretRight onClick={() => setMobileViewIndex(1)} />
                )}
              </div>
            </div>

            <div
              className={`menu d-sm-none  view-1 ${
                mobileMenuViewIndex === 1 ? 'show' : 'hide'
              } `}
            >
              {' '}
              <div
                className={`menu-item ${
                  activeMenu === 'brand_theme' ? 'active' : ''
                }`}
              >
                {mobileMenuViewIndex === 1 && (
                  <AiOutlineCaretLeft onClick={() => setMobileViewIndex(0)} />
                )}
                <FolderButton
                  label="Brand Theme"
                  id="brand_theme"
                  icon={<IconTheme />}
                  onClick={() => {
                    setActiveMenu('brand_theme');
                    logClickButton(DID_CLICK_BRAND_THEME_MENU);
                  }}
                />
              </div>
              <div
                className={`menu-item ${
                  activeMenu === 'brand_assets' ? 'active' : ''
                }`}
              >
                <FolderButton
                  label="Brand Assets"
                  id="brand_assets"
                  icon={<IconAsset />}
                  onClick={() => {
                    setActiveMenu('brand_assets');
                    logClickButton(DID_CLICK_BRAND_ASSET_MENU);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="content mb-4">
            {activeMenu === 'brand_profile' && <BrandDetails />}
            {activeMenu === 'brand_assets' && (
              <BrandAssets account={account} brandSetup={brandSetup} />
            )}

            {activeMenu === 'brand_theme' && <BrandTheme />}
            {activeMenu === 'brand_vb' && <BrandVirtualBackgrounds />}
          </div>
        </div>
        <style global jsx>{`
          :global(.content-wrapper) {
            min-height: unset !important;
          }
          h5 {
            font-size: 18px;
            font-weight: 600;
          }
          .text-muted {
            color: #999999 !important;
            font-weight: 400;
          }
          .text-small {
            font-size: 12px;
          }
          .font-weight-bold {
            font-weight: 600 !important;
          }
          .PopupContainer .content-container {
            max-height: 100vh;
          }
          .PopupContainer {
            max-height: 100vh;
          }
          .FolderButton {
            width: 100% !important;
          }
          @media (max-width: 375px) {
            .brand-settings .menu-item .FolderButton .spacer {
              display: none !important;
            }
            .brand-settings .menu-item .FolderButton {
              padding-left: 2px !important;
              font-size: 12px;
            }
          }

          @media (min-width: 576px) {
            .popup-preview .PopupContainer .content-container {
              border-radius: 0;
              border: none !important;
              background-color: unset !important;
            }
            .popup-preview .PopupContainer {
              border-radius: 0;
              border: none !important;
              background-color: unset !important;
            }

            .popup-preview .PopupContainer .close-container {
              position: fixed !important;
              top: 90px !important;
              right: 40px !important;
            }
            .PopupContainer {
              overflow-y: auto;
            }
          }

          @media (max-width: 575px) {
            .popup-preview .PopupContainer .content-container {
              border-radius: 0;
              border: none !important;
              background-color: unset !important;
              width: 90% !important;
              padding: 6px !important;
              margin-top: 10vh !important;
              justify-content: center;
            }
            .popup-preview .PopupContainer {
              border-radius: 0;
              border: none !important;
              background-color: unset !important;
              padding-top: 40px !important;
            }

            .PopupContainer .close-container {
              position: fixed !important;
              top: 60px !important;
              right: 16px !important;
            }
            .form-label,
            .form-control {
              font-size: 16px;
            }
          }
        `}</style>
        <style jsx>{`
          .brand-settings {
            min-width: 300px;
            min-height: 500px;
          }
          .brand-settings .heading h2 {
            padding-left: 28px;
            font-size: 24px;
            font-weight: 600 !important;
            line-height: 29.26px;
            font-style: normal;
          }
          .brand-settings .main {
            display: flex;
          }
          .brand-settings .main .menu {
            width: 250px;
            border-right: 1px solid #d8d8d8;
          }
          .brand-settings .main .content {
            width: calc(100% - 250px);
            padding-top: 1.5rem;
            padding-left: 1.5rem;
          }

          .brand-settings .main .menu > .menu-item.active {
            background-color: #d8d8d8;
          }
          .brand-settings .main .menu > .menu-item {
            background-color: #ffffff;
          }

          @media (min-width: 576px) {
            .menu {
              min-height: calc(100vh - 130px);
            }
          }

          @media (max-width: 575.98px) {
            :global(.content-wrapper) {
              padding-top: 0 !important;
            }
            :global(.FolderButton:hover) {
              background-color: unset !important;
            }

            .mobile-menu {
              position: relative;
              height: 70px;
            }
            .menu.view-0.show {
              transform: translateX(100%);
            }
            .menu.view-0.hide {
              transform: translateX(0);
            }
            .menu.view-0 {
              margin-left: -100%;
            }
            .menu.view-1.show {
              width: 100% !important;
              left: 0;
            }
            .menu.view-1.hide {
              transform: translateX(0);
              right: 0;
              left: 100%;
              width: 0 !important;
              overflow: hidden;
              border-right: none !important;
            }

            .menu {
              width: 100%;
              position: absolute;
              transition: all 0.2s ease-in-out;
            }

            .brand-settings .main .menu {
              width: 100%;
              justify-content: space-between;
              display: flex;
              margin-bottom: 1rem;
            }
            .brand-settings .content {
              width: 100% !important;
              display: block;
              padding: 0rem !important;
            }
            .brand-settings .main {
              display: block;
            }
            .brand-settings .main .menu > .menu-item {
              width: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: space-between;
            }

            .brand-settings .main .menu > .menu-item.active {
              background-color: #d8d8d8;
            }
            .brand-settings .main .menu > .menu-item {
              background-color: #fff;
              padding: 0.5rem;
            }
            .brand-settings .main .content {
              padding-top: 0;
            }
          }
        `}</style>
      </div>
    </Layout>
  );
};

export default BrandSettingsPage;
