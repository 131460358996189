import React, { ReactNode } from 'react';
import { logEvent } from '../../../analytics';
import { DID_CHANGE_CHAT_TAB } from '../../../utils/constants';
import ChatList from './ChatList';
import PageViewInsights from './PageInsights/PageViewInsights';

export enum Tabs {
  CONVERSATIONS = 'CONVERSATIONS',
  CHAT = 'CHAT',
  INSIGHTS = 'INSIGHTS'
}

const ChatListWrapper = ({ keywords }: { keywords: string }) => {
  const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.CONVERSATIONS);
  let tabContent: ReactNode = null;

  switch (activeTab) {
    case Tabs.CONVERSATIONS:
      tabContent = <ChatList keywords={keywords} />;
      break;
    case Tabs.INSIGHTS:
      tabContent = <PageViewInsights />;
  }

  return (
    <div className="wrapper">
      <div
        className={`tabs ${
          activeTab === Tabs.CONVERSATIONS && 'no-border-bottom'
        }`}
      >
        <div className="tabs-wrapper">
          <div
            onClick={() => {
              logEvent(DID_CHANGE_CHAT_TAB, DID_CHANGE_CHAT_TAB, {
                activeTab
              });
              setActiveTab(Tabs.CONVERSATIONS);
            }}
            className={`tab-item ${
              activeTab === Tabs.CONVERSATIONS && 'active'
            }`}
          >
            Conversations
          </div>
          <div
            onClick={() => {
              logEvent(DID_CHANGE_CHAT_TAB, DID_CHANGE_CHAT_TAB, {
                activeTab
              });
              setActiveTab(Tabs.INSIGHTS);
            }}
            className={`tab-item ${activeTab === Tabs.INSIGHTS && 'active'}`}
          >
            Insights
          </div>
        </div>
      </div>
      {tabContent}
      <style jsx>
        {`
          .wrapper {
            height: 75vh;
            border: 1px solid #c4c4c4;
            border-right: none;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }
          .tabs {
            padding: 0.85em 1em;
            border-bottom: 1px solid #c4c4c4;
          }

          .tabs.no-border-bottom {
            border-bottom: none;
          }

          .tabs-wrapper {
            background: #efefef;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 55px;
          }

          .tab-item {
            width: 50%;
            padding: 10px 20px;
            text-align: center;
            color: #040404;
            cursor: pointer;
          }
          .tab-item.active {
            background: #000000;
            color: #ffffff;
            border-radius: 55px;
          }

          @media (max-width: 480px) {
            .wrapper {
              border-right: none;
              border-left: none;
              border-bottom: none;
              height: auto;
              padding-bottom: 20px;
            }
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            .tab-item {
              padding: 10px;
              font-size: 14px;
              line-height: 24px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ChatListWrapper;
