import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  ChatMessageType,
  IChatMessage,
  IChatMessageBody
} from '../../../components/ChatConnectionContext';
import { getSessionUrl, staticBaseUrl } from '../../../config';
import { IMainState } from '../../../interfaces';
import {
  DID_CLICK_CHAT_SHARE,
  DID_CLICK_JOIN_CHAT
} from '../../../utils/constants';
import { enc } from '../../../utils/crypt';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { isEmbeddedInStreamingStudio } from '../../../utils/streamingstudio';
import { getCorrectHubHost } from '../../utils/hubPagesRoute';
import { HubContext } from '../HubContext';
import ChatUserInsight from './ChatUserInsight';
import { HubChatContext } from './HubChatContext';
import TransferDropdown from './TransferDropdown';

const ChatActions = () => {
  const { storeId, brandId } = React.useContext(HubContext);
  const { conversations, currentConversation, onSendHubMessage, chatUsers } =
    React.useContext(HubChatContext);
  const hubUser = useSelector(
    (state: IMainState) => state.clientState?.hub?.user
  );
  const details = conversations.find(
    (conversation) => conversation?.conversationId === currentConversation
  );
  const [showInsights, setShowInsights] = React.useState(false);
  const meetingUrl = getSessionUrl({
    storeId: hubUser?.storeId,
    sessionId: details?.conversationId,
    region: null,
    source: 'chat',
    host: `https://${getCorrectHubHost(brandId)}`
  });

  const onShareMeetingLink = () => {
    logEvent(DID_CLICK_CHAT_SHARE, DID_CLICK_CHAT_SHARE, {
      type: ChatMessageType.MEETING_LINK,
      meetingUrl
    });

    const messageBody: IChatMessageBody = {
      content: meetingUrl?.guest,
      type: ChatMessageType.MEETING_LINK,
      timestamp: new Date()
    };
    sendMessage(messageBody);
  };

  const sendMessage = (messageBody: IChatMessageBody) => {
    const encryptionId = generateV4UUID();
    messageBody.storeId = storeId;
    const encrypted = {
      id: encryptionId,
      content: enc(JSON.stringify(messageBody), encryptionId)
    };

    const payload: IChatMessage = {
      requestId: generateV4UUID(),
      userId: hubUser?.id,
      conversationId: details?.conversationId,
      messageBody: JSON.stringify(encrypted)
    };
    onSendHubMessage(payload);
  };

  const onShareAppointmentForm = () => {
    logEvent(DID_CLICK_CHAT_SHARE, DID_CLICK_CHAT_SHARE, {
      type: ChatMessageType.APPOINTMENT_FORM
    });

    const messageBody: IChatMessageBody = {
      content: '',
      type: ChatMessageType.APPOINTMENT_FORM,
      timestamp: new Date()
    };
    sendMessage(messageBody);
  };

  return (
    <div className="chat-actions">
      <button
        className="no-background"
        onClick={() => {
          setShowInsights(true);
        }}
      >
        <img
          src={`${staticBaseUrl}/connect/assets/insights.svg`}
          alt="Insights"
        />
      </button>
      <a
        href={meetingUrl?.host}
        target={isEmbeddedInStreamingStudio() ? '_self' : '_blank'}
        onClick={() => {
          logEvent(DID_CLICK_JOIN_CHAT, DID_CLICK_JOIN_CHAT, {
            url: meetingUrl?.host
          });
          onShareMeetingLink();
        }}
        className="btn join-btn"
      >
        <img
          src={`${staticBaseUrl}/connect/assets/video.svg`}
          alt="Video chat"
        />
      </a>
      <button
        className="appointment"
        onClick={() => {
          onShareAppointmentForm();
        }}
      >
        <AiOutlineCalendar color="#fff" size={21} />
      </button>
      <TransferDropdown />
      <ChatUserInsight
        user={chatUsers?.[details?.conversationCreatedBy]}
        show={showInsights}
        onClose={() => {
          setShowInsights(false);
        }}
      />
      <style jsx>
        {`
          .chat-actions {
            padding: 8px 16px;
            padding-bottom: 12px;
          }
          .chat-actions .no-background {
            border: none;
            background: transparent;
            padding: 0;
            margin-right: 5px;
          }
          .chat-actions .btn:focus {
            box-shadow: none;
          }

          button.appointment {
            border: none;
            background: black;
            border-radius: 50%;
            height: 37px;
            width: 37px;
            display: inline-block;
            margin-left: 5px;
            padding: 0;
          }

          :global(.btn-dark svg) {
            margin-right: 15px;
          }

          .join-btn {
            padding: 0;
            border-radius: 100%;
          }

          @media (min-width: 768px) and (min-height: 376px) {
            .chat-actions {
              padding: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ChatActions;
