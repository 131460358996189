import React from 'react';
import { Step } from 'react-joyride';
import { isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { PageView } from '../../../utils/pageView';
import { tryRemoveFromLocalStorage } from '../../../utils/window';
import { isOnBoardingBrandByBrandId } from '../../utils/hubPagesRoute';
import { useTextContent } from '../hooks/tour';
import { HubContext } from '../HubContext';
import BrandingTour from './BrandingTour';
import AssetLibraryTour from './AssetLibraryTour';
import StoriezTour from './StoriezTour';
import TeamGuid from './Team';
import UserManagementGuid from './UserManagement';
export interface IHubTourState {
  run: boolean;
  steps: Array<Step>;
}
const HubTour = () => {
  const { page, brandId, isShowingSessionPage } = React.useContext(HubContext);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { tc } = useTextContent();
  const isMobile = isUserOnMobileOnly();
  const isOnBoardingBrand = isOnBoardingBrandByBrandId(brandId);

  if(isShowingSessionPage()) {
    return <StoriezTour page={PageView.HUB_HOME}/>;
  }
  else switch (page) {
    case PageView.HUB_BRANDING:
      return !isMobile && <BrandingTour />;
    case PageView.HUB_ASSET_LIBRARY:
      return !isMobile && <AssetLibraryTour />;
    case PageView.HUB_HOME:
    return <StoriezTour page={PageView.HUB_HOME}/>;
    case PageView.HUB_SESSION_OVERVIEW:
    return <StoriezTour page={PageView.HUB_SESSION_OVERVIEW}/>;
    case PageView.HUB_USERMANAGEMENT:
      return !isMobile && isOnBoardingBrand ? <UserManagementGuid /> : null;
    case PageView.HUB_TEAMS:
      return !isMobile ? <TeamGuid /> : null;
    default:
      return null;
  }
};
export const setGuidedTour = () => {
  tryRemoveFromLocalStorage('DesignerTour');
  tryRemoveFromLocalStorage('SLDesignerTour');
  tryRemoveFromLocalStorage('BrandingSettingTour');
  tryRemoveFromLocalStorage('UserManagementTour');
  tryRemoveFromLocalStorage('TeamTour');
};
export default HubTour;
