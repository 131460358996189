import dynamic from 'next/dynamic';
// import CUSTOM_SECTIONS from '../../Polotno/CustomSection';
import { StoreType } from 'polotno/model/store';
import React, { Suspense, useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import CSRComponentWrapper from '../../../../components/CSRComponentWrapper';
import { useLibraryAction } from '../../../../components/hooks/library';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import {
  ILibraryScene,
  LibrarySceneType,
  LoadingStatus
} from '../../../../interfaces';
import { mapGeneratedImageToSceneContent } from '../../../../mappers/library';
import { TLanguage } from '../../../../mappers/polotno';
import {
  appendNewValueIntoSetting,
  mapSceneToStorybook
} from '../../../../mappers/storybook';
import { actionHubAlertError } from '../../../../redux/actions';
import { DID_SAVE_POLOTNO_JSON } from '../../../../utils/constants';
import { isVideo } from '../../../../utils/file';
import { generateV4UUID } from '../../../../utils/identityGenerator';
import { removeUnusedFontsInScene } from '../../../../utils/polotno';
import {
  extractPageId,
  getPolotnoStore,
  scrollPolotnoPageToCenter
} from '../../../../utils/window';
import {
  savePageLayers,
  saveSceneImage
} from '../../../clientSideServices/library';
import { PageConfigContext } from '../../../clientSideServices/pageConfig';
import { DID_SELECT_SCENE_FROM_THUMBNAIL } from '../../../utils/analyticsEvents/library';
import {
  generateScenePreview,
  getAvailableLanguageInScene,
  getCustomFontsInScene,
  getPolotnoJsonInScene,
  mapCurrentSceneWithNewLanguagePages,
  mapCurrentSceneWithStorePages,
  removeLanguagePageInScene
} from '../../../utils/library';
import {
  getScenesLocalStorage,
  removeItemFromLocalStorage,
  removeLanguageFromLocalStorage,
  removeSceneFromLocalStorage
} from '../../../utils/scene';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import { TDeviceView } from '../../Common/DeviceViewToggle';
import { IconArrow } from '../../Common/HubIcons';
import { HubContext } from '../../HubContext';
import PolotnoSaveWarning from '../../Polotno/PolotnoSaveWarning';
import ProgressModal from '../../ProgressModal';
import SBPreview from '../../StoryBook/Preview';
import SLBuilderActions from './SLBuilderActions';
import SLDescriptionPopup from './SLDescriptionPopup';
import SLSceneDetails from './SLSceneDetails';
import SLSceneViewer from './SLSceneViewer';
const PolotnoWrapper = dynamic(() => import('../../Polotno/PolotnoWrapper'), {
  ssr: false
});

let saveTimeout;

enum DescriptionPopupType {
  INIT = 'INIT',
  ACTIVATE_SCENE = 'ACTIVATE_SCENE',
  CLOSE_PAGE = 'CLOSE_PAGE',
  GENERATE = 'GENERATE'
}

const SLDesigner = ({
  onClose,
  userId,
  initialScene,
  onSaveCallback,
  context
}: {
  onClose: () => void;
  userId: string;
  initialScene?: ILibraryScene;
  onSaveCallback?: (scenes: ILibraryScene) => void;
  context?: LibrarySceneType;
}) => {
  const dispatch = useDispatch();
  const isTemplate = context === 'template';
  const { brandId, brandSetup } = useContext(HubContext);
  const [previewScene, setPreviewScene] = React.useState<ILibraryScene>(null);
  const [loadingPreview, setLoadingPreview] = React.useState(false);
  const [isVersionEdit, setIsVersionEdit] = React.useState(false);
  const [activeLanguage, setActiveLanguage] = React.useState<TLanguage>('en');
  const [availableLanguage, setAvailableLanguage] = React.useState<TLanguage[]>(
    ['en']
  );
  const [showDetailsPanel, setShowDetailsPanel] = React.useState(true);

  const [shouldGenerateBeforeClose, setShouldGenerateBeforeClose] =
    React.useState(false);

  const [activateWarning, setActivateWarning] =
    React.useState<TDeviceView>(null);

  const { isLoading, setLoadingStatus } = useLoadingStatus();

  const { activateScene, saveScene, getSceneById, editScene } =
    useLibraryAction({ userId, context });

  const [dataHasChanged, setDataHasChanged] = React.useState(false);
  const [currentScene, setCurrentScene] =
    React.useState<ILibraryScene>(initialScene);

  const currentSceneRef = useRef(initialScene);
  React.useEffect(() => {
    currentSceneRef.current = currentScene;
  }, [currentScene]);

  const [descriptionPopup, setDescriptionPopup] =
    React.useState<DescriptionPopupType>(
      !initialScene.content.name ? DescriptionPopupType.INIT : null
    );

  const [disableSaving, setDisableSaving] = React.useState(false);

  const isVideoPage = isVideo(currentScene?.content?.url);

  const onUpdateScene = async (
    scene: ILibraryScene,
    dontMarkChange?: boolean
  ) => {
    setCurrentScene(scene);
    setDataHasChanged(!dontMarkChange);
    setShouldGenerateBeforeClose(true);
  };

  const handleCallback = (scene: ILibraryScene) => {
    onSaveCallback?.({
      ...scene,
      createdBy: scene.createdBy || userId,
      modifiedBy: userId,
      createdAt: scene.createdAt || new Date().toISOString(),
      modifiedAt: new Date().toISOString()
    });
  };

  const onSaveScene = async (fresh?: boolean, loadEditor?: boolean) => {
    if (!currentScene) return; // Exit if no current scene exists

    const store = getPolotnoStore(); // Get the Polotno store instance

    // Get the current state of the editor (this was the missing line)
    // const updatedEditorState = store?.toJSON();

    // Map the current scene with the latest editor state and other scene data
    const updatedScene: ILibraryScene = mapCurrentSceneWithStorePages(
      // {
      //   ...currentScene,
      //   content: {
      //     ...currentScene.content,
      //     // Store the editor state in a property that exists in ILibrarySceneContent
      //     // For example, if there's an 'overlay' property, you could use that
      //     overlay: JSON.stringify(updatedEditorState)
      //   }
      // },
      currentScene,
      fresh
    );
    // Generate settings layer for the current page (if necessary) and update the scene content
    updatedScene.content.settings = await generateCurrentPageSettingLayer(
      updatedScene
    );

    // Save the updated scene with the new content and settings
    saveScene({
      scene: removeUnusedFontsInScene(updatedScene), // Remove unused fonts before saving
      dontReloadAfterSave: true, // Avoid reloading unless specified
      onSuccess: (res) => {
        setDataHasChanged(false); // Reset change flag after successful save
        setShouldGenerateBeforeClose(false); // Prevent generating again before close
        handleCallback(updatedScene); // Handle post-save operations (if any)

        // Log the event for analytics
        logEvent(DID_SAVE_POLOTNO_JSON, DID_SAVE_POLOTNO_JSON, {
          sceneId: res.id,
          organisationId: brandSetup?.parentBrandId || brandId
        });

        if (loadEditor) {
          store?.loadJSON(getPolotnoJsonInScene(updatedScene));
        }
      },
      onError: (err) => {
        console.error('Error while saving the scene:', err);
      }
    });
  };
  const onEditScene = () => {
    setDisableSaving(false);
    setIsVersionEdit(true);
    editScene({
      id: currentScene.id,
      version: currentScene.version,
      onSuccess: (res) => {
        onUpdateScene(res, true);
        if (res.editor) {
          const store = getPolotnoStore();
          store?.loadJSON(res.editor);
        }
      }
    });
  };

  const [showActivateConfirmation, setShowActivateConfirmation] =
    useState(false);

  const onActivate = async () => {
    if (!currentScene) return;

    // Check if the scene has a valid name
    if (!currentScene?.content?.name?.trim()) {
      setDescriptionPopup(DescriptionPopupType.ACTIVATE_SCENE);
      return;
    }

    // Show the activation confirmation popup
    setShowActivateConfirmation(true);
  };

  const handleConfirmActivate = async () => {
    setShowActivateConfirmation(false);
    // Start the progress bar
    setShowProgressModal(true);
    setProgressPercentage(0);
    setProgressMessage('Starting activation...');

    // const json = getPolotnoJson();
    const existingLanguages = getAvailableLanguageInScene(currentScene);

    const uniqueLanguages = Array.from(new Set(existingLanguages));
    setAvailableLanguage(uniqueLanguages);

    setLoadingStatus(`activating-${currentScene.id}`, LoadingStatus.LOADING);
    setDisableSaving(true);

    const version = currentScene?.version || 1;
    const mappedScene: ILibraryScene =
      mapCurrentSceneWithStorePages(currentScene);
    setProgressPercentage(20);
    setProgressMessage('Assembling Scene ...');

    try {
      const images = await saveSceneImage({
        key: `${mappedScene.id}/${version}`,
        scene: mappedScene
      });

      setProgressPercentage(50);
      setProgressMessage('Prepareing Layers ...');

      const updatedScene = (await isVideoPage)
        ? mappedScene
        : {
            ...mappedScene,
            content: mapGeneratedImageToSceneContent(
              { ...mappedScene?.content },
              images
            )
          };
      saveScene({
        scene: removeUnusedFontsInScene(updatedScene),
        dontReloadAfterSave: true,
        onSuccess: () => {
          setProgressPercentage(80);
          setProgressMessage('Preparing Deployment ...');

          activateScene({
            id: updatedScene.id,
            delay: 2700,
            onSuccess: (res) => {
              setProgressPercentage(100);
              setProgressMessage('Activation complete!');
              // Prevent language duplication during activation
              setAvailableLanguage(
                Array.from(new Set(getAvailableLanguageInScene(res)))
              );
              onUpdateScene(res, true);
              handleCallback(res);
              setShouldGenerateBeforeClose(false);
              setActiveLanguage('en');
            }
          });
        }
      });
      setShowProgressModal(false);
    } catch (e) {
      setProgressMessage('Error during activation');
      setShowProgressModal(false);
      setLoadingStatus(`activating-${mappedScene.id}`, LoadingStatus.FAILED);
      dispatch(
        actionHubAlertError(
          'Something went wrong during activation. Please check your internet connection.'
        )
      );
    } finally {
      setDisableSaving(false);
      setShowProgressModal(false);
    }
  };

  const handleBack = async () => {
    // Only trigger save if the scene has unsaved changes
    if (dataHasChanged && currentScene?.status !== 'activated') {
      setLoadingStatus('closing', LoadingStatus.LOADED);

      // Save the scene with fresh data
      await onSaveScene(true);

      // Reset the dataHasChanged flag after save
      setDataHasChanged(false);
    }

    const updatedScene: ILibraryScene =
      mapCurrentSceneWithStorePages(currentScene);
    const isActive = updatedScene.status === 'activated';

    // Skip saving or updating if scene is already active and no changes made
    if (isActive || shouldGenerateBeforeClose) {
      onClose();
      return;
    }

    const hasTitle = !!updatedScene?.content?.name?.trim();
    if (!hasTitle) {
      setDescriptionPopup(DescriptionPopupType.CLOSE_PAGE);
      return;
    }

    const handleSuccess = (scene: ILibraryScene) => {
      handleCallback(scene);
      onClose();
    };

    const handleSave = (scene: ILibraryScene) => {
      saveScene({
        scene: removeUnusedFontsInScene(scene),
        dontReloadAfterSave: true,
        onSuccess: () => {
          handleSuccess(scene);
          removeSceneFromLocalStorage(scene.id);
          removeItemFromLocalStorage(scene.id)
          setLoadingStatus('closing', LoadingStatus.INITIAL);
        },
        onError: () => {
          setLoadingStatus('closing', LoadingStatus.INITIAL);
          onClose();
        }
      });
    };

    if (!isVideoPage) {
      handleSave(updatedScene);
    } else {
      onClose();
    }
  };
  const getActionFromDescriptionPopup = (actionType: DescriptionPopupType) => {
    switch (actionType) {
      case DescriptionPopupType.ACTIVATE_SCENE:
        return onActivate();
      case DescriptionPopupType.CLOSE_PAGE:
        return handleBack();
      case DescriptionPopupType.GENERATE:
        return onSaveScene(true);
    }
  };

  const onSelectVersion = (version: number) => {
    if (!currentScene) return;
    getSceneById({
      id: currentScene.id,
      version,
      onSuccess: onUpdateScene
    });
  };

  const shouldAutoSave =
    currentScene?.status === 'draft' && dataHasChanged && !disableSaving;

  React.useEffect(() => {
    const clearSaveTimeout = () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
    if (shouldAutoSave) {
      clearSaveTimeout();
      saveTimeout = setTimeout(() => {
        onSaveScene();
      }, 3000);
    }

    return () => {
      clearSaveTimeout();
    };
  }, [currentScene, shouldAutoSave]);

  React.useEffect(() => {
    const json = getPolotnoJsonInScene(currentScene);
    if (!json) return;
    const store = getPolotnoStore();
    store?.loadJSON(json);
    setAvailableLanguage(getAvailableLanguageInScene(currentScene));
  }, [initialScene, currentScene?.id, currentScene?.version]);

  const onTogglePreview = async () => {
    if (previewScene) {
      setPreviewScene(null);
    } else {
      setLoadingPreview(true);
      const currentSceneWithEditor =
        mapCurrentSceneWithStorePages(currentScene);
      generateScenePreview({
        scene: currentSceneWithEditor,
        onLoading: setLoadingPreview,
        onSuccess: setPreviewScene,
        onFailed: (error) => {
          setLoadingPreview(false);
          dispatch(actionHubAlertError(error));
        }
      });
    }
  };

  const generateCurrentPageSettingLayer = async (
    savedScene: ILibraryScene,
    upload = true
  ) => {
    const store = getPolotnoStore();
    const generatedKey = generateV4UUID();

    const key = `${savedScene.id}/${savedScene.version}`;
    const keyBase = `content/generated/${key}/${generatedKey}`;

    const generatedLayers = await savePageLayers(
      store,
      keyBase,
      savedScene,
      true,
      upload
    );
    return appendNewValueIntoSetting(savedScene.content.settings, {
      layer: generatedLayers
    });
  };

  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [progressMessage, setProgressMessage] = useState('');

  const handleAddLanguage = async (store: StoreType, language: TLanguage) => {
    if (store) {
      setShowProgressModal(true);
      setProgressPercentage(0);
      setProgressMessage('Translating Scene ...');
      setProgressPercentage(10);
      const mappedScene = mapCurrentSceneWithStorePages(currentScene);

      setProgressPercentage(20);
      const updatedScene: ILibraryScene =
        await mapCurrentSceneWithNewLanguagePages(mappedScene, language);
      setCurrentScene(updatedScene);
      setAvailableLanguage([...availableLanguage, language]);
      setActiveLanguage(language);
      setShowProgressModal(false);
    }
  };


  const handleRemoveLanguage = (store: StoreType, language: TLanguage) => {
    if (store) {
      const currentSceneWithEditor =
        mapCurrentSceneWithStorePages(currentScene);
      const updatedScene: ILibraryScene = removeLanguagePageInScene(
        currentSceneWithEditor,
        language
      );
      setCurrentScene(updatedScene);
      setAvailableLanguage(availableLanguage.filter((l) => l !== language));
      if (language === activeLanguage) setActiveLanguage(availableLanguage[0]);

      // Remove the language from local storage
      removeLanguageFromLocalStorage(currentScene.id, language);
    }
  };

  const handleSelectLanguage = (store: StoreType, language: TLanguage) => {
    if (store) {
      const currentSceneWithEditor =
        mapCurrentSceneWithStorePages(currentScene);
      setCurrentScene(currentSceneWithEditor);
      setActiveLanguage(language);
    }
  };

  React.useEffect(() => {
    // Console log removed
  }, [availableLanguage]);

  const { user } = React.useContext(HubContext);

  const { getSceneById: getTemplateSceneById } = useLibraryAction({
    userId: user?.id,
    context: 'template'
  });
  const handleLoadTemplateToEditor = (templateId, version, callback) => {
    getTemplateSceneById({
      id: templateId,
      version,
      preventUpdateStatus: true,
      onSuccess: (res) => {
        let overlay = JSON.parse(res.content.overlay);
        const store = getPolotnoStore();
        const latestPages = overlay?.pages?.map((items, index) => {
          if (index === 0 && overlay?.pages?.length === 2)
            return {
              ...items,
              id: currentSceneRef.current?.id
            };
          return items;
        });
        if(overlay?.pages?.length === 2)
        overlay = {
          ...overlay,
          pages: latestPages
        };
        const pages = overlay?.pages || [];
        const scenePages =
          JSON.parse(currentSceneRef.current?.content.overlay || '{}')?.pages ||
          [];
        store.loadJSON(overlay, true);

        pages.map((page) => {
          const { language, isPortrait } = extractPageId(page.id);
          const endsWith = `${language === 'en' ? '' : `-${language}`}${
            isPortrait ? '-portrait' : ''
          }`;
          const pageInSceneIdx = !endsWith
            ? 0
            : scenePages.findIndex((p) => p.id?.includes(endsWith));
          if (pageInSceneIdx !== -1)
            scenePages[pageInSceneIdx] = {
              ...page,
              id: scenePages[pageInSceneIdx]?.id || currentSceneRef?.current?.id
            };
          else
            scenePages.push({
              ...page,
              id: `${currentSceneRef.current?.id}${
                language === 'en' ? '' : `-${language}`
              }${isPortrait ? '-portrait' : ''}`
            });
        });

        const updatedScene = {
          ...currentSceneRef.current,
          content: {
            ...currentSceneRef.current.content,
            overlay: JSON.stringify({
              ...JSON.parse(currentSceneRef.current?.content?.overlay || '{}'),
              pages: scenePages
            })
          }
          // id: templateId
        };
        setCurrentScene({ ...updatedScene, isTemplate: true });
        setAvailableLanguage(getAvailableLanguageInScene(updatedScene));
        const canvasEl = document.getElementsByClassName(
          'polotno-page-container'
        );
        if (canvasEl) {
          setTimeout(() => scrollPolotnoPageToCenter(canvasEl), 100);
        }
        callback?.();
      },
      onError: () => {
        callback?.();
      }
    });
    logEvent(DID_SELECT_SCENE_FROM_THUMBNAIL, DID_SELECT_SCENE_FROM_THUMBNAIL, {
      sceneId: templateId,
      sceneVersion: version,
      sceneEditor: true,
      organisationId: brandSetup?.parentBrandId || brandId
    });
  };

  const disableEditor =
    isLoading(`activating-${currentScene?.id}`) || isLoading(`closing`);

  const initJson = React.useMemo(() => {
    if (!isVersionEdit) {
      const scenesBackup = JSON.parse(getScenesLocalStorage());

      if (Object.keys(scenesBackup).length > 0) {
        const selectedSceneFromLS = scenesBackup[currentScene?.id];
        const convertedCurrentSceneTs =
          new Date(currentScene?.modifiedAt).getTime() || 0;

        if (
          selectedSceneFromLS &&
          scenesBackup[currentScene?.id][activeLanguage]
        ) {
          if (selectedSceneFromLS['ts'] < convertedCurrentSceneTs) {
            removeSceneFromLocalStorage(currentScene?.id);
          } else {
            return selectedSceneFromLS[activeLanguage];
          }
        }
      }
      return getPolotnoJsonInScene(currentScene);
    } else {
      setIsVersionEdit(false);
    }
  }, [currentScene]);

  const pageViewer = currentScene && (
    <>
      <CSRComponentWrapper renderDelay={100}>
        <Suspense fallback={null}>
          <PolotnoWrapper
            id={currentScene.id}
            isMultiLanguage={true}
            activeLanguage={activeLanguage}
            customFonts={getCustomFontsInScene(currentScene)}
            availableLanguage={availableLanguage}
            initJson={initJson}
            onChange={disableSaving ? undefined : () => setDataHasChanged(true)}
            handleLoadTemplateToEditor={handleLoadTemplateToEditor}
            onAddLanguage={handleAddLanguage}
            onRemoveLanguage={handleRemoveLanguage}
            onSelectLanguage={handleSelectLanguage}
            viewAs={activateWarning}
            context={currentScene.id}
            type={PageConfigContext.SCENE_DESIGNER}
          />
        </Suspense>
      </CSRComponentWrapper>
      {!previewScene &&
        (currentScene?.status === 'activated' || isVideoPage) && (
          <SLSceneViewer
            scene={currentScene}
            noPadding={!!previewScene}
            activateLanguage={activeLanguage}
          />
        )}

      {previewScene && (
        <SBPreview
          onClose={() => setPreviewScene(null)}
          storybook={mapSceneToStorybook(previewScene)}
          isForScene={true}
          previewMode="microsite"
        />
      )}
    </>
  );

  return (
    <div
      className={`SLDesigner ${
        disableEditor || loadingPreview ? 'activating' : ''
      }`}
    >
      {!previewScene && (
        <SLBuilderActions
          onBack={handleBack}
          scene={currentScene}
          onSave={(fresh, loadEditor) => {
            if (fresh && !currentScene?.content?.name?.trim()) {
              setDescriptionPopup(DescriptionPopupType.GENERATE);
            } else {
              onSaveScene(true, loadEditor);
            }
          }}
          onEdit={onEditScene}
          onActivate={onActivate}
          activeLanguage={activeLanguage}
          listLanguages={availableLanguage}
          setActiveLanguage={setActiveLanguage}
          onSelectVersion={onSelectVersion}
          preview={{
            loading: loadingPreview,
            onToggle: onTogglePreview
          }}
          isTemplate={isTemplate}
        />
      )}

      <div className="col-container">
        <div className="content-col main-col flex-grow-1">
          <div className="viewer-col flex-grow-1">
            {currentScene && pageViewer}
          </div>
        </div>
        {!previewScene && (
          <div
            className={`content-col side-col ${
              !showDetailsPanel ? 'minimize' : ''
            }`}
          >
            {showDetailsPanel && currentScene && (
              <SLSceneDetails
                onUpdate={onUpdateScene}
                scene={currentScene}
                context={context}
                onHide={() => setShowDetailsPanel(false)}
              />
            )}
            {!showDetailsPanel && (
              <div
                className="open-container"
                onClick={() => setShowDetailsPanel(true)}
              >
                <IconArrow direction="left" />
              </div>
            )}
          </div>
        )}
      </div>

      {descriptionPopup && currentScene && (
        <SLDescriptionPopup
          popupTitle={`Please enter ${
            isTemplate ? 'Template' : 'Scene'
          } Details`}
          name={currentScene?.content?.name || ''}
          description={currentScene?.content?.description || ''}
          onClose={() => setDescriptionPopup(null)}
          okLabel={
            currentScene?.status === 'activated' ? 'Save as Draft' : 'Save'
          }
          onUpdate={(data) =>
            onUpdateScene({
              ...currentScene,
              content: { ...currentScene.content, ...data }
            })
          }
          loading={isLoading(currentScene.id)}
          onSave={() => {
            getActionFromDescriptionPopup(descriptionPopup);
            setDisableSaving(false);
            editScene({
              id: currentScene.id,
              version: currentScene.version,
              onSuccess: (res) => {
                res.content = currentScene.content;
                onUpdateScene(res, false);
                if (res.editor) {
                  const store = getPolotnoStore();
                  store?.loadJSON(res.editor);
                }
              }
            });
            setDescriptionPopup(null);
          }}
        />
      )}

      {activateWarning && (
        <PolotnoSaveWarning
          onOk={() => {
            onActivate();
            setActivateWarning(null);
          }}
          onClose={() => setActivateWarning(null)}
          missingItem={activateWarning}
          okLabel="Activate without Portrait"
          context="activate"
        />
      )}

      <style jsx global>{`
        .SceneLibrary .content-wrapper {
          padding: 0 !important;
        }

        .header > *:not(.logo-container) {
          visibility: hidden !important;
        }
        .SBPreview {
          z-index: 20;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: #fff;
        }
      `}</style>

      <style jsx>{`
        .SLDesigner {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-content: stretch;
        }
        .SLDesigner :global(.panel-label) {
          font-weight: bold;
          font-size: 14px;
        }

        .SLDesigner :global(label) {
          font-weight: bold;
          font-size: 12px;
        }

        .SLDesigner :global(.panel-header .form-control) {
          margin-top: 7px;
        }

        .col-container {
          height: 100%;
          overflow: hidden;
          display: flex;
        }
        .content-col {
          height: 100%;
          overflow-y: auto;
          position: relative;
          overflow-x: hidden;
        }
        .main-col {
          height: 100%;
          display: flex;
          justify-content: flex-start;
          position: relative;
          flex-direction: column;
        }

        .activating .col-container {
          opacity: 0.5;
          pointer-events: none;
        }

        .viewer-col {
          overflow: hidden;
          position: relative;
        }

        .thumbnail-col {
          height: 170px;
          min-height: 170px;
          overflow: hidden;
          border-top: 1px solid #ccc;
        }

        .side-col {
          width: 300px;
          min-width: 300px;
          position: relative;
        }
        .side-col.minimize {
          width: 24px;
          min-width: 24px;
          overflow: hidden;
        }
        .side-col.minimize > :global(div:first-child:not(.open-container)) {
          opacity: 0;
        }
        .disable {
          opacity: 0.8;
          background: #fff;
          position: absolute;
          inset: 0;
        }
        .hide {
          display: none !important;
        }

        .side-col:first-child {
          border-right: 1px solid #ccc;
          overflow: hidden;
        }
        .side-col:last-child {
          border-left: 1px solid #ccc;
        }
        .close-container {
          position: absolute;
          right: 5px;
          top: 16px;
        }
        .open-container {
          position: absolute;
          inset: 0;
          background: #ccc;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          opacity: 0.3;
        }
        .open-container > :global(*) {
          margin: auto;
        }
        .open-container:hover {
          opacity: 1;
        }
        :global(.bp5-navbar .device-view-toggle) {
          padding: 8px 0;
        }
        :global(.language-switcher) {
          display: none;
        }
      `}</style>

      <ProgressModal
        show={showProgressModal}
        progress={progressPercentage}
        message={progressMessage}
        onCancel={() => setShowProgressModal(false)}
      />

      {showActivateConfirmation && (
        <ConfirmationPopup
          title="Confirm Activation"
          onOk={handleConfirmActivate}
          onClose={() => setShowActivateConfirmation(false)}
          okLabel="Activate"
          closeLabel="Cancel"
          zIndex={1050}
        >
          <p>
            Are you sure you want to activate this Scene? Any presentations
            linked to this Scene will be automatically updated.
          </p>
        </ConfirmationPopup>
      )}
    </div>
  );
};

export default SLDesigner;
