import React from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { getRegionForStore } from '../../../config';

import { HubContext } from '../HubContext';

const REGIONS = [
  {
    name: 'Asia Pacific',
    code: 'ap'
  },
  {
    name: 'Europe',
    code: 'eu'
  },
  {
    name: 'US',
    code: 'us'
  }
];

const SessionRegionSelector = ({ region, onChange, storeId }: {
  region: string;
  onChange: (code: string) => void;
  storeId: string;
}) => {
  const { color } = React.useContext(HubContext);
  const [showList, toggleShowList] = React.useState<boolean>(false);

  const onSelectItem = (region) => {
    onChange(region?.code);
    toggleShowList(false);
  };

  React.useEffect(() => {
    if (!region || region === "") {
      onChange(getRegionForStore(storeId))
    }
  }, []);

  return (
    <div className="wrapper">
      <span
        className="btn select-timezone-btn"
        onClick={() => toggleShowList(!showList)}
      >
        {region
          ? REGIONS.find((r) => r.code === region)?.name
          : 'Select a region'}
        {!showList ? <BiChevronDown /> : <BiChevronUp />}
      </span>
      {showList && (
        <div className="dropdown">
          {REGIONS.map((item, index) => (
            <div
              key={index}
              className={`dropdown-item ${region === item?.code && 'selected'}`}
              onClick={() => onSelectItem(item)}
            >
              {item?.name}
            </div>
          ))}
        </div>
      )}

      <style jsx>{`
        .wrapper {
          position: relative;
          display: inline-block;
        }
        .dropdown {
          position: absolute;
          left: 0;
          top: 30px;
          border: 1px solid rgb(223, 223, 223);
          background: #fff;
          z-index: 1;
        }
        .selected {
          background: rgba(0, 0, 0, 0.2);
        }

        .select-timezone-btn {
          color: ${color?.accent};
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        :global(.ssp) .select-timezone-btn{
          font-size:1em;
        }
        :global(.select-timezone-btn > svg) {
          width: 24px;
          height: auto;
          margin-left: 10px;
        }
      `}</style>
    </div>
  );
};

export default SessionRegionSelector;
