import React from 'react';
import VideoPlayerInline from '../../advisorHub/components/StoryBook/Common/VideoPlayerInline';
import {
  ArrowNextIcon,
  ArrowPreviousIcon
} from '../../advisorHub/components/Common/HubIcons';

function VideoPlayers({ videoUrls }: { videoUrls: string[] }) {
  const [currentPlaying, setCurrentPlaying] = React.useState(0);

  const currentUrl = videoUrls[currentPlaying];
  const handleEnded = () => {
    if (currentPlaying < videoUrls.length - 1) {
      setCurrentPlaying(currentPlaying + 1);
    }
  };

  const hasNext = currentPlaying < videoUrls.length - 1;
  const hasPrev = currentPlaying > 0;

  const next = () => {
    if (hasNext) {
      setCurrentPlaying(currentPlaying + 1);
    }
  };
  const prev = () => {
    if (hasPrev) {
      setCurrentPlaying(currentPlaying - 1);
    }
  };

  if (videoUrls.length === 0) return null;

  return (
    <div className="video-players">
      <button
        className="btn-navigation btn btn-prev"
        data-enable={hasPrev}
        onClick={prev}
      >
        <ArrowPreviousIcon />
      </button>
      <button
        className="btn-navigation btn btn-next"
        data-enable={hasNext}
        onClick={next}
      >
        <ArrowNextIcon />
      </button>
      <VideoPlayerInline
        url={currentUrl}
        playing={true}
        controls={true}
        audio={true}
        onEnded={handleEnded}
        canPlay={true}
      />
      <style jsx>{`
        .video-players {
          position: relative;
          width: 100%;
        }
        .video-players ::global(.video-container) {
          position: relative;
          width: 100%;
          height: auto;
          max-height: 100%;
        }
        .btn-navigation {
          position: absolute;
          z-index: 2;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: space-between;
        }
        .btn-prev {
          left: 0;
        }
        .btn-next {
          right: 0;
        }
        .btn[data-enable='false'] {
          visibility: hidden;
        }
        .btn.btn-prev ::global(svg) {
          animation: btn-move-backward 4s infinite;
        }
        .btn.btn-next ::global(svg) {
          animation: btn-move 4s infinite;
        }
        @keyframes btn-move {
          0% {
            transform: translateX(0px);
          }
          50% {
            transform: translateX(20px);
          }
          100% {
            transform: translateX(0px);
          }
        }
        @keyframes btn-move-backward {
          0% {
            transform: translateX(0px);
          }
          50% {
            transform: translateX(-20px);
          }
          100% {
            transform: translateX(0px);
          }
        }
      `}</style>
    </div>
  );
}

export default VideoPlayers;
