import { isEmpty } from 'lodash';
import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ChatConnectionContext } from '../../../components/ChatConnectionContext';
import { IMainState, ProductType, Source } from '../../../interfaces';
import { actionOpenVBPopup } from '../../../redux/actions';
import { decryptMessage } from '../../../utils/chat';
import { getCurrentHost } from '../../../utils/window';
import { HubContext } from '../HubContext';
import ChatBubbleContainer from './ChatBubbleContainer';
import { HubChatContext, IncommingMessageType } from './HubChatContext';

const MESSAGE_PER_PAGE = 20;

const ChatMessages = ({ conversationId }) => {
  const messagesContainer: React.RefObject<HTMLDivElement> = React.createRef();
  const startKeyMessage: React.RefObject<HTMLDivElement> = React.createRef();
  const {
    messages: messagesMap,
    currentConversation,
    isFetchingMessages,
    setIsFetchingMessages,
    messageStartKey,
    setMessageStartkey,
    incommingMessageType,
    listSA
  } = React.useContext(HubChatContext);
  const { fetchChatMessagesByConversationId } = React.useContext(
    ChatConnectionContext
  );
  const messages = messagesMap?.[currentConversation] || [];
  const { i18n } = useTranslation();
  const language = `${i18n?.language || 'en'}`;
  const hubUser = useSelector(
    (state: IMainState) => state.clientState?.hub?.user
  );
  const dispatch = useDispatch();
  const { brandId, storeId } = useContext(HubContext);

  const scrollToBottom = () => {
    if (messageStartKey && incommingMessageType === IncommingMessageType.MANY) {
      scrollToStartKey();
      return;
    }

    if (messagesContainer?.current) {
      const scroll =
        messagesContainer?.current?.scrollHeight -
        messagesContainer?.current?.clientHeight;
      messagesContainer?.current?.scrollTo(0, scroll);
    }
  };

  const scrollToStartKey = () => {
    if (messagesContainer?.current && startKeyMessage?.current) {
      const scroll =
        startKeyMessage?.current?.offsetTop -
        messagesContainer?.current?.offsetTop -
        startKeyMessage?.current?.clientHeight;
      messagesContainer?.current?.scrollTo(0, scroll);
    }
  };

  const onClickProduct = (productId: string) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/products/${productId}?source=${Source.Chat}${
          storeId ? `&store=${storeId}` : ''
        }&language=${language}`,
        true
      )
    );
  };

  const onClickStory = (storyId: string) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/inspirations/${storyId}?source=${Source.Chat}${
          storeId ? `&store=${storeId}` : ''
        }&language=${language}`,
        true
      )
    );
  };

  const openProductCompare = (productIds: string, compareType: ProductType) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/compare/${brandId}?source=${
          Source.Chat
        }&store=${storeId}&compareProducts=${productIds}&compareType=${compareType}&language=${language}`,
        true
      )
    );
  };

  const openProductPersonalize = (
    productId: string,
    strap: string,
    buckle: string
  ) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/personalize/${productId}?source=${
          Source.Chat
        }&store=${storeId}&strap=${strap}&buckle=${buckle}&language=${language}`,
        true
      )
    );
  };

  const onLoadMoreMessages = () => {
    setIsFetchingMessages(true);
    fetchChatMessagesByConversationId(
      conversationId,
      MESSAGE_PER_PAGE,
      messageStartKey
    );
  };

  const onScrolledToTop = () => {
    if (messageStartKey && messagesContainer?.current?.scrollTop === 0) {
      onLoadMoreMessages();
    }
  };

  const fetchInitialMessages = () => {
    setMessageStartkey(null);
    setIsFetchingMessages(true);
    fetchChatMessagesByConversationId(conversationId, MESSAGE_PER_PAGE);
  };

  React.useEffect(() => {
    if (!isEmpty(messages)) {
      scrollToBottom();
    }
  }, [messages]);

  React.useEffect(() => {
    if (conversationId) {
      fetchInitialMessages();
    }
  }, [conversationId]);

  const loadingMore = (
    <div style={{ width: '100%', padding: '10px 0', textAlign: 'center' }}>
      <Spinner animation="border" size="sm" />
    </div>
  );
  const isAnotherSalesAdvisor = (id: string) =>
    listSA?.find((sa) => sa.id === id);
  return (
    <div
      className="message-list"
      ref={messagesContainer}
      onScroll={() => onScrolledToTop()}
    >
      {isFetchingMessages && isEmpty(messages) && (
        <div className="loading">Loading....</div>
      )}
      {isFetchingMessages && !isEmpty(messages) && loadingMore}
      {messages?.map((message, index) => {
        const isAnotherSA = !!isAnotherSalesAdvisor(message?.senderId);
        const left = message?.senderId !== hubUser.id && !isAnotherSA;
        const messageBody = decryptMessage(message?.messageBody);
        const messageString = message?.messageBody;
        const messageWrapperRef =
          message?.id === messageStartKey?.id?.S ? startKeyMessage : null;

        return (
          <div ref={messageWrapperRef} key={index}>
            <ChatBubbleContainer
              messageBody={messageBody}
              messageString={messageString}
              left={left}
              isAnotherSA={isAnotherSA}
              onClickProduct={onClickProduct}
              onClickStory={onClickStory}
              openProductCompare={openProductCompare}
              openProductPersonalize={openProductPersonalize}
              message={message}
              onImageLoaded={() => {
                scrollToBottom();
              }}
            />
          </div>
        );
      })}
      <style jsx>{`
        .message-list {
          flex-grow: 1;
          border-bottom: 1px solid #c4c4c4;
          padding: 18px 20px;
          overflow: auto;
          height: 0;
        }
        :global(.message-item) {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        :global(.message-item.left) {
          align-items: flex-start;
        }
        :global(.message-item.anotherSA .message-content) {
          border: 1px black solid;
        }
        :global(.message-content) {
          background: #f0f0f0;
          padding: 10px 22px;
          font-size: 0.9rem;
          line-height: 1.6;
          color: #010101;
          max-width: 450px;
          overflow-wrap: break-word;
          border-radius: 10px;
        }
        :global(.message-content.left) {
          background: #fff;
          border: 1px solid #f0f0f0;
          color: #000;
        }
        :global(.message-timestamp) {
          margin-top: 5px;
          margin-right: 5px;
          font-size: 11px;
          line-height: 1.6;
          color: #666363;
          font-style: italic;
        }
        :global(.message-timestamp.left) {
          margin-right: 0;
          margin-left: 5px;
        }
        @media (max-width: 480px) {
          :global(.message-timestamp) {
            font-size: 8px;
            line-height: 10px;
          }
          :global(.message-content) {
            font-size: 10px;
            line-height: 12px;
            max-width: 250px;
            padding: 10px 12px;
          }

          .message-list {
            padding: 10px;
            padding-bottom: 0;
            overflow: auto;
          }
        }
      `}</style>
    </div>
  );
};

export default ChatMessages;
